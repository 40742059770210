import React, { Component } from 'react';
import { Col, Container, Row, Card, CardBody, Progress, ButtonToolbar } from 'reactstrap';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import ReactGA from 'react-ga';
import Picker from 'react-month-picker';
import 'react-month-picker/css/month-picker.css';

import SelectDropdown from 'shared/components/SelectDropdown';
import SelectDropdown2 from 'shared/components/SelectDropdown2';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import NotificationSystem from 'rc-notification';

import workhoursApi from 'api/workhours';
import employeesApi from 'api/employees';
import { getItem, setItem } from 'helpers/cookies';

import { setWorkers } from 'redux/actions/hoursActions';

import Table from './components/Table';
import UreGradbiscaModal from './components/UreGradbiscaModal';
import { PICKER_DATE_OW, TIMEZONE } from '../../../constants/config';
import {
  HoursProps,
  AppStatusProps,
  EmployeesProps,
  OrderersProps,
  WorksitesProps,
} from '../../../shared/prop-types/ReducerProps';
import { setRouterBlock, setRouterBlockNotif } from '../../../redux/actions/appActions';
import { FullWideNotification } from '../../../shared/components/Notification';
import Legend from '../../Boards/components/Legend';

const pickerLang = {
  months: ['Jan', 'Feb', 'Mar', 'Apr', 'Maj', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dec'],
  from: 'From',
  to: 'To',
};

let notificationTC = null;

const showNotification = notification => {
  notificationTC.notice({
    content: notification,
    duration: 5,
    closable: true,
    style: { top: 0, left: 0 },
    className: 'full',
  });
};

class UreDelavciPage extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    hours: HoursProps.isRequired,
    appStatus: AppStatusProps.isRequired,
    workersState: EmployeesProps.isRequired,
    orderersState: OrderersProps.isRequired,
    worksitesState: WorksitesProps.isRequired,
    t: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    const storedDate = getItem(PICKER_DATE_OW);
    let month =
      moment()
        .tz(TIMEZONE)
        .month() + 1;
    let year = moment()
      .tz(TIMEZONE)
      .year();
    if (storedDate) {
      month = Number(storedDate.split('/')[0]);
      year = Number(storedDate.split('/')[1]);
    }
    this.state = {
      titlesOrderers: [],
      titlesWorksites: [],
      monthPickerValue: {
        year,
        month,
      },
      selectedOrderer: null,
      selectedWorksite: null,
      progressNum: 0,
      employeeTitles: [],
      worksiteLoaded: false,
      updatingTable: false,
    };
    this.monthPickerRef = null;
    this.onMonthPickerClick = this.onMonthPickerClick.bind(this);
    this.handleWorksitesSubmit = this.handleWorksitesSubmit.bind(this);
    this.handleOrderersSubmit = this.handleOrderersSubmit.bind(this);
    this.handleTableChange = this.handleTableChange.bind(this);
    this.addWorkerToTable = this.addWorkerToTable.bind(this);
    this.handleMonthChange = this.handleMonthChange.bind(this);
    this.handleAMonthDissmis = this.handleAMonthDissmis.bind(this);
  }

  componentDidMount() {
    this._mounted = true;
    NotificationSystem.newInstance({}, n => (notificationTC = n));

    const { appStatus } = this.props;
    if (appStatus.requiredDataLoaded) {
      console.log('DELOVNE URE - PO GRADBIŠČU -> Data loaded.. continue...');
      this.fetchData();
    }
  }

  componentDidUpdate(prevProps) {
    const { appStatus } = this.props;
    if (appStatus.requiredDataLoaded && !prevProps.appStatus.requiredDataLoaded) {
      console.log('DELOVNE URE - PO GRADBIŠČU -> Data finished loading.. continue...');
      this.fetchData();
    }

    if (!appStatus.appUpdating && prevProps.appStatus.appUpdating) {
      console.log('DELOVNE URE - PO GRADBIŠČU -> Data finished UPDATING.. refresh table...');
      this.fetchData();
    }
  }

  componentWillUnmount() {
    this._mounted = false;
    notificationTC.destroy();
  }

  onMonthPickerClick() {
    this.monthPickerRef.show();
  }

  showNotif = ({ message, success }) => {
    const color = success ? 'success' : 'danger';
    showNotification(<FullWideNotification color={color} message={message} />);
  };

  _mounted = false;

  async blockCheck() {
    let saveFirst;

    if (this.props.appStatus.blockRouter) {
      saveFirst = window.confirm('Ali želite shraniti ure, predno zamenjate delavca?');
    }

    if (saveFirst) {
      await workhoursApi.postHours();
    } else {
      workhoursApi.clearSchedule();
    }

    this.props.dispatch(setRouterBlock(false));
    this.props.dispatch(setRouterBlockNotif(null));
  }

  async handleMonthChange(year, month) {
    await this.blockCheck();

    setItem(PICKER_DATE_OW, `${month}/${year}`);
    const { selectedOrderer, selectedWorksite } = this.state;
    this.setState({
      monthPickerValue: { year, month },
      worksiteLoaded: false,
      progressNum: selectedOrderer || selectedWorksite ? 10 : 0,
    });

    ReactGA.event({
      category: 'Delovne Ure - Gradbišča',
      action: `Filter Datum: ${month}/${year}`,
    });
    this.setState(await employeesApi.getNamesForWorksites({ year, month }));

    this.submitSearch(selectedOrderer, selectedWorksite, { year, month });
  }

  async handleTableChange() {
    const { selectedOrderer, monthPickerValue, selectedWorksite } = this.state;
    await this.submitSearch(selectedOrderer, selectedWorksite, monthPickerValue);
    this.setState({ updatingTable: true });
    setTimeout(() => {
      this.setState({ updatingTable: false });
    }, 100);
  }

  handleProgress(addProgress) {
    const { progressNum } = this.state;
    this.setState({ progressNum: progressNum + addProgress });
    const that = this;
    if (progressNum + addProgress >= 100) {
      setTimeout(() => {
        that.handleProgress(-(progressNum + addProgress));
      }, 1000);
    }
  }

  handleAMonthDissmis(year, month) {
    if (year && month) {
      setItem(PICKER_DATE_OW, `${month}/${year}`);
      this.setState({
        monthPickerValue: { year, month },
      });
    }
  }

  async handleWorksitesSubmit(event) {
    await this.blockCheck();

    if (event.selected_dropdown_2) {
      const selectedWorksite = event.selected_dropdown_2;
      this.setState({
        selectedWorksite,
        worksiteLoaded: false,
      });

      ReactGA.event({
        category: 'Delovne Ure - Gradbišča',
        action: `Filter Gradbišča: ${selectedWorksite.label}`,
      });
      const { selectedOrderer, monthPickerValue } = this.state;
      this.submitSearch(selectedOrderer, selectedWorksite, monthPickerValue);
    }
  }

  async handleOrderersSubmit(event) {
    await this.blockCheck();

    if (event.selected_dropdown) {
      const selectedOrderer = event.selected_dropdown;
      this.setState({
        selectedOrderer,
        worksiteLoaded: false,
      });
      ReactGA.event({
        category: 'Delovne Ure - Gradbišča',
        action: `Filter Naročniki: ${selectedOrderer.label}`,
      });
      const { selectedWorksite, monthPickerValue } = this.state;
      this.submitSearch(selectedOrderer, selectedWorksite, monthPickerValue);
    }
  }

  async submitSearch(selectedOrderer, selectedWorksite, monthPickerValue, workerToAdd = null) {
    if (monthPickerValue) {
      const { month, year } = monthPickerValue;
      if (selectedOrderer && selectedWorksite) {
        this.setState({ progressNum: 20 });
        if (workerToAdd) {
          this.setState({
            addingWorker: true,
          });
        }
        const data = await workhoursApi.getbyworksite({
          ordererId: selectedOrderer.value,
          worksiteId: selectedWorksite.value,
          month,
          year,
          workerToAdd,
        });

        this.setState({ progressNum: 100 });
        if (data.workers) {
          this.props.dispatch(setWorkers(data));
          this.setState({ progressNum: 0, worksiteLoaded: true });
          if (workerToAdd) {
            this.setState({
              addingWorker: false,
            });
          }
        } else {
          this.setState({
            workers: [],
          });
          this.props.dispatch(setWorkers([]));
          this.setState({ progressNum: 0, addingWorker: false });
        }
      }
    }
  }

  async fetchData() {
    const {
      workersState: { employeesNames },
      orderersState: { titlesOrderers },
      worksitesState: { worksitesWithAddress },
    } = this.props;

    this.setState({
      titlesOrderers,
      titlesWorksites: worksitesWithAddress,
      employeeTitles: employeesNames,
    });
    /* this.setState(await companiesApi.getall());
    this.setState(await professionsApi.getall());
    this.setState(await countriesApi.getall());
    this.setState(await worksitesApi.getall(true));
    this.setState(await orderersApi.getall());
    this.setState(await employeesApi.getNamesForWorksites(this.state.monthPickerValue)); */
  }

  makeText(m) {
    if (m && m.year && m.month) return `${pickerLang.months[m.month - 1]}. ${m.year}`;
    return '?';
  }

  addWorkerToTable(data) {
    if (data) {
      // console.log('addWorkerToTable', data);
      const { label, value, name, surname } = data.workerId;
      const workerToAdd = {
        _id: value,
        label,
        color: '#ffffff',
        workdays: [],
        name,
        surname,
      };
      const { selectedWorksite, monthPickerValue, selectedOrderer } = this.state;
      this.submitSearch(selectedOrderer, selectedWorksite, monthPickerValue, workerToAdd);
    }
  }

  render() {
    const {
      titlesOrderers,
      monthPickerValue,
      titlesWorksites,
      selectedWorksite,
      selectedOrderer,
      progressNum,
      employeeTitles,
      addingWorker,
      updatingTable,
      worksiteLoaded,
    } = this.state;
    const { hours, t } = this.props;
    return (
      <Container className="dashboard">
        <Row>
          <Col md="2">
            <h3 className="page-title">Delovne ure po gradbiščih</h3>
          </Col>
          <Col md="3">
            <Card>
              <CardBody>
                <SelectDropdown title="Išči Naročnika" items={titlesOrderers} onSubmit={this.handleOrderersSubmit} />
              </CardBody>
            </Card>
          </Col>
          <Col md="3">
            <Card>
              <CardBody>
                <SelectDropdown2 title="Išči Gradbišče" items={titlesWorksites} onSubmit={this.handleWorksitesSubmit} />
              </CardBody>
            </Card>
          </Col>
          <Col md="4">
            <Card>
              <CardBody>
                <div className="date-picker">
                  <div className="form__form-group-label">Izberi mesec:</div>
                  <Picker
                    ref={ref => (this.monthPickerRef = ref)}
                    years={[2008, 2009, 2010, 2011, 2012, 2014, 2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030]}
                    value={monthPickerValue}
                    lang={pickerLang.months}
                    onChange={this.handleMonthChange}
                    onDismiss={this.handleAMonthDissmis}
                  >
                    <div
                      role="button"
                      tabIndex={0}
                      onKeyDown={this.onMonthPickerClick}
                      onClick={this.onMonthPickerClick}
                    >
                      {this.makeText(monthPickerValue)}
                    </div>
                  </Picker>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col lg={6}>
            <Legend title={t('board.legend')} lettercodes />
          </Col>
        </Row>
        <Row>
          <Col md="12">
            {progressNum > 0 ? (
              <Col lg={12}>
                <div className="progress-wrap progress-wrap--middle progress--align">
                  <Progress animated value={progressNum} />
                </div>
              </Col>
            ) : null}
            {worksiteLoaded && hours.workers && hours.workersLoaded && !addingWorker && !updatingTable && (
              <Table
                date={monthPickerValue}
                orderer={selectedOrderer}
                worksite={selectedWorksite}
                onUpdate={this.handleTableChange}
                onHoursPosted={this.showNotif}
              />
            )}
          </Col>
        </Row>
        <Row>
          <Col lg={3}>
            {worksiteLoaded && hours.workers && hours.workersLoaded && !addingWorker && !updatingTable && (
              <ButtonToolbar>
                <UreGradbiscaModal
                  btn="Dodaj delavca"
                  icon="add"
                  title="Dodaj delavca"
                  workers={employeeTitles}
                  hasBtn
                  show={false}
                  onClose={this.addWorkerToTable}
                />
              </ButtonToolbar>
            )}
          </Col>
          {/* <Col lg={{ offset: 6, size: 3 }}>
            {worksiteLoaded && hours.workers && hours.workersLoaded && !addingWorker && <h1>SKUPAJ: 1000</h1>}
          </Col> */}
        </Row>
        <Row>
          <Col lg={12}>&nbsp;</Col>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  hours: state.hours,
  appStatus: state.appStatus,
  workersState: state.employees,
  orderersState: state.orderers,
  worksitesState: state.worksites,
});

const wr = connect(mapStateToProps)(UreDelavciPage);

export default translate('common')(wr);
