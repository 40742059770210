import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Col, Container, Row, ButtonToolbar, Card, CardBody, Progress } from 'reactstrap';
import getRoles from 'constants/roles';
import PropTypes from 'prop-types';
import { translate } from 'react-i18next';
import ReactGA from 'react-ga';
import NotificationSystem from 'rc-notification';
import { API_LINK } from '../../constants/config';
import { POST } from '../../helpers/agent';
import PrenocitveTable from './components/PrenocitveTable';
import { BasicNotification } from '../../shared/components/Notification';
import SelectDropdown from '../../shared/components/SelectDropdown';
import PrenocitveModal from './components/PrenocitveModal';
import PrenocitveSheet from './components/PrenocitveSheet';
import { DataListProps, AccommodationsProps, AppStatusProps } from '../../shared/prop-types/ReducerProps';

const API_ACCOMMODATIONS_GET_ONE = '/acm/getOneById';

let notificationLU = null;
let notificationRU = null;
let notificationTC = null;

const showNotification = ({ notification, position }) => {
  switch (position) {
    case 'left-up':
      notificationLU.notice({
        content: notification,
        duration: 5,
        closable: true,
        style: { top: 0, left: 240 },
        className: position,
      });
      break;
    case 'right-up':
      notificationRU.notice({
        content: notification,
        duration: 5,
        closable: true,
        style: { top: 0, left: 'calc(100vw - 100%)' },
        className: position,
      });
      break;
    default:
      notificationTC.notice({
        content: notification,
        duration: 5,
        closable: true,
        style: { top: 0, left: 0 },
        className: position,
      });
      break;
  }
};

class PrenocitvePage extends Component {
  static propTypes = {
    dataListState: DataListProps.isRequired,
    accommodationsState: AccommodationsProps.isRequired,
    appStatus: AppStatusProps.isRequired,
    t: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      accommodations: [],
      accommodationsFull: [],
      titles: [],
      countries: [],
      providers: [],
      showEdit: false,
      progressNum: 0,
      editAccommodation: null,
      ROLES: getRoles(),
    };

    this.onRowSelect = this.onRowSelect.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.showNotif = this.showNotif.bind(this);
    this.fetchData = this.fetchData.bind(this);
    this.handleProgress = this.handleProgress.bind(this);
    this.handleDropdownSubmit = this.handleDropdownSubmit.bind(this);
  }

  componentDidMount() {
    NotificationSystem.newInstance({}, n => (notificationLU = n));
    NotificationSystem.newInstance({}, n => (notificationRU = n));
    NotificationSystem.newInstance({}, n => (notificationTC = n));

    const { appStatus } = this.props;
    if (appStatus.requiredDataLoaded) {
      console.log('ACCOMMODATIONS -> Data loaded.. continue...');
      this.fetchData();
    }
  }

  componentDidUpdate(prevProps) {
    const { appStatus } = this.props;
    if (appStatus.requiredDataLoaded && !prevProps.appStatus.requiredDataLoaded) {
      console.log('ACCOMMODATIONS -> Data finished loading.. continue...');
      this.fetchData();
    }

    if (!appStatus.appUpdating && prevProps.appStatus.appUpdating) {
      console.log('ACCOMMODATIONS -> Data finished UPDATING.. refresh table...');
      this.fetchData();
    }
  }

  componentWillUnmount() {
    notificationLU.destroy();
    notificationRU.destroy();
    notificationTC.destroy();
  }

  async onRowSelect(values) {
    const { ROLES } = this.state;
    const { t } = this.props;
    if (ROLES.accommodations && ROLES.accommodations.locations && ROLES.accommodations.locations.readonly) {
      ReactGA.event({
        category: 'Prenočitve',
        action: `Urejanje prenočitve - ${values} - Ni dostopa`,
      });
      this.showNotif({
        title: `${t('misc.forbidden')}!`,
        message: `${t('misc.noright')}!`,
        success: false,
      });
      return;
    }
    const that = this;
    const body = JSON.stringify({ id: values });
    ReactGA.event({
      category: 'Prenočitve',
      action: `Urejanje prenočitve - ${values} - Ni dostopa`,
    });
    const data = await POST(API_LINK + API_ACCOMMODATIONS_GET_ONE, body);
    if (data.success) {
      that.setState({ editAccommodation: data.accommodation, showEdit: true });
    }
  }

  fetchData() {
    const { dataListState, accommodationsState } = this.props;

    this.setState({
      accommodations: accommodationsState.accommodations,
      accommodationsFull: accommodationsState.accommodations,
      titles: accommodationsState.accommodationTitles,
      countries: dataListState.countries,
      providers: accommodationsState.providerTitles,
    });
  }

  handleProgress(addProgress) {
    const { progressNum } = this.state;
    this.setState({ progressNum: progressNum + addProgress });
    const that = this;
    if (progressNum + addProgress >= 100) {
      setTimeout(() => {
        that.handleProgress(-(progressNum + addProgress));
      }, 1000);
    }
  }

  showNotif = ({ title, message, success }) => {
    const color = success ? 'success' : 'danger';
    showNotification({
      notification: <BasicNotification color={color} title={title} message={message} />,
      position: 'right-up',
    });
  };

  handleCloseModal(data) {
    if (data) this.showNotif(data);
    this.setState({ showEdit: false });
    if (data) this.fetchData();
  }

  handleDropdownSubmit(values) {
    try {
      if (values.selected_dropdown === null) {
        this.fetchData();
        return;
      }
      ReactGA.event({
        category: 'Prenočiitve',
        action: `Filtriranje Prenočitev - ${values.selected_dropdown.label}`,
      });
      const { accommodationsFull } = this.state;
      const id = values.selected_dropdown.value;
      const filtered = [];
      accommodationsFull.forEach(acm => {
        if (acm._id === id) {
          filtered.push(acm);
        }
      });
      this.setState({ accommodations: filtered });
    } catch (error) {
      console.log(error);
    }
  }

  render() {
    const {
      accommodations,
      progressNum,
      titles,
      countries,
      showEdit,
      editAccommodation,
      providers,
      ROLES,
    } = this.state;

    const { t } = this.props;

    let showBtn = ROLES.full;

    if (!showBtn) {
      showBtn = !(ROLES.accommodations && ROLES.accommodations.locations && ROLES.accommodations.locations.readonly);
    }

    return (
      <Container className="dashboard">
        <Row>
          <Col lg={12}>
            <h3 className="page-title">
              {t('sidebar_content.accommodations.title')} - {t('sidebar_content.accommodations.locations')}
            </h3>
          </Col>
          <Col lg={12}>
            <Row>
              <Col lg={6}>
                <Card>
                  <CardBody>
                    <SelectDropdown
                      title={`${t('vehicles.find')}:`}
                      items={titles}
                      onSubmit={this.handleDropdownSubmit}
                    />
                  </CardBody>
                </Card>
              </Col>
              <Col lg={{ size: 4, offset: 2 }}>
                <ButtonToolbar className="button-toolbar">
                  <PrenocitveModal
                    btn={t('messages.accommodation.add.title')}
                    icon="add"
                    title={t('messages.accommodation.add.title')}
                    countries={countries}
                    providers={providers}
                    hasBtn={showBtn}
                    show={false}
                    onClose={this.handleCloseModal}
                  />

                  <PrenocitveSheet
                    btn={t('form.exportExcel')}
                    disabled={!(progressNum === 0 && accommodations.length > 0)}
                    data={accommodations}
                  />
                </ButtonToolbar>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          {progressNum > 0 ? (
            <div className="progress-wrap progress-wrap--middle progress--align">
              <Progress animated value={progressNum} />
            </div>
          ) : null}
          <PrenocitveTable
            title={t('sidebar_content.accommodations.title')}
            accommodations={accommodations}
            onRowSelect={this.onRowSelect}
          />
        </Row>

        <PrenocitveModal
          btn={t('messages.accommodation.edit.title')}
          icon="edit"
          title={t('messages.accommodation.edit.title')}
          countries={countries}
          providers={providers}
          hasBtn={false}
          show={showEdit}
          onClose={this.handleCloseModal}
          editData={editAccommodation}
        />
      </Container>
    );
  }
}

const wr = connect(state => ({
  dataListState: state.dataList,
  accommodationsState: state.accommodations,
  appStatus: state.appStatus,
}))(PrenocitvePage);

export default translate('common')(wr);
