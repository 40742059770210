import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Container } from 'reactstrap';
import SingleOWTable from './SingleOWTable';

class MultiOWTable extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { title, selectedCompanyName, selectedCompany, dataList } = this.props;
    const allTables = dataList.map(item => {
      const { workers, month, year } = item;
      const tag = item.tag || 'normal';
      return (
        <Row>
          <Col lg={12} md={12} sm={12} className="mb-3">
            <SingleOWTable
              data={workers}
              month={month}
              year={year}
              title={title}
              subtitle={selectedCompanyName}
              showCompanyName={selectedCompany === 'all'}
              tag={tag}
            />
          </Col>
        </Row>
      );
    });
    return <Container style={{ margin: 0, maxWidth: '3000px' }}>{allTables}</Container>;
  }
}

MultiOWTable.propTypes = {
  title: PropTypes.string,
  selectedCompanyName: PropTypes.string,
  selectedCompany: PropTypes.string,
  dataList: PropTypes.arrayOf(PropTypes.object),
};

MultiOWTable.defaultProps = {
  title: '',
  selectedCompanyName: '',
  selectedCompany: '',
  dataList: [],
};

export default MultiOWTable;
