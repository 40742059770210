import payload from 'helpers/jwt';
import { getItem } from 'helpers/cookies';

export default function getRoles() {
  const token = getItem('jwt');
  let roles = null;
  if (token) {
    const { role } = payload(token);
    roles = role;
  }
  return roles;
}
