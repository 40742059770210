import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import classNames from 'classnames';
import NotificationSystem from 'rc-notification';
import PropTypes from 'prop-types';
import { getItem, setItem } from 'helpers/cookies';
import Topbar from './topbar/Topbar';
import Sidebar from './sidebar/Sidebar';
import { BasicNotification } from '../../shared/components/Notification';
import { changeThemeToDark, changeThemeToLight } from '../../redux/actions/themeActions';
import { changeMobileSidebarVisibility, changeSidebarVisibility } from '../../redux/actions/sidebarActions';
import { SidebarProps, UserProps } from '../../shared/prop-types/ReducerProps';

let notification = null;

const showNotification = user => {
  const name = user ? user.name : '';
  notification.notice({
    content: (
      <BasicNotification title={`👋 Pozdravljen/a ${name}!`} message="Program HURES je pripravljen, uživajte!" />
    ),
    duration: 5,
    closable: true,
    style: { top: 0, left: 'calc(100vw - 100%)' },
    className: 'right-up',
  });
};

class Layout extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    sidebar: SidebarProps.isRequired,
    user: UserProps,
  };

  static defaultProps = {
    user: null,
  };

  componentDidMount() {
    NotificationSystem.newInstance({}, n => (notification = n));
    const shouldShow = getItem('notif');

    if (shouldShow === 'show') {
      setItem('notif', 'dont');
      setTimeout(() => showNotification(this.props.user), 1000);
    }
  }

  componentWillUnmount() {
    notification.destroy();
  }

  changeSidebarVisibility = () => {
    this.props.dispatch(changeSidebarVisibility());
  };

  changeMobileSidebarVisibility = () => {
    this.props.dispatch(changeMobileSidebarVisibility());
  };

  changeToDark = () => {
    this.props.dispatch(changeThemeToDark());
  };

  changeToLight = () => {
    this.props.dispatch(changeThemeToLight());
  };

  render() {
    const layoutClass = classNames({
      layout: true,
      'layout--collapse': this.props.sidebar.collapse,
    });

    // const { user } = this.props;
    // console.log('user layout:', user);
    return (
      <div className={layoutClass}>
        <Topbar
          changeMobileSidebarVisibility={this.changeMobileSidebarVisibility}
          changeSidebarVisibility={this.changeSidebarVisibility}
        />
        <Sidebar
          sidebar={this.props.sidebar}
          changeToDark={this.changeToDark}
          changeToLight={this.changeToLight}
          changeMobileSidebarVisibility={this.changeMobileSidebarVisibility}
        />
      </div>
    );
  }
}

export default withRouter(
  connect(state => ({
    sidebar: state.sidebar,
    user: state.user.user,
  }))(Layout),
);
