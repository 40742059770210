import React from 'react';
import { Card, CardBody, Col, Table } from 'reactstrap';
import PropTypes from 'prop-types';
import { translate } from 'react-i18next';
import { countrify } from '../../../helpers/functions';

const renderTable = (accommodations, onRowSelect) =>
  accommodations.map(accommodation => (
    <tr key={accommodation._id} id={accommodation._id} onClick={() => onRowSelect(accommodation._id)}>
      <td>{accommodation.title}</td>
      <td>{accommodation.address}</td>
      <td>{accommodation.zip}</td>
      <td>{accommodation.city}</td>
      <td>{countrify(accommodation.countryId)}</td>
      <td>{accommodation.capacity}</td>
    </tr>
  ));

const PrenocitveTable = ({ title, accommodations, onRowSelect, t }) => (
  <Col md={12} lg={12} xl={12}>
    <Card>
      <CardBody>
        <div className="card__title">
          <h5 className="bold-text">{title}</h5>
          {/* <h5 className="subhead">lol</h5> */}
        </div>
        <Table hover className="table--bordered" responsive>
          <thead>
            <tr>
              <th>{t('board.ow.provider')}</th>
              <th>{t('form.address')}</th>
              <th>{t('form.zip')}</th>
              <th>{t('form.city')}</th>
              <th>{t('form.country')}</th>
              <th>{t('form.capacity')}</th>
            </tr>
          </thead>
          <tbody>{renderTable(accommodations, onRowSelect)}</tbody>
        </Table>
      </CardBody>
    </Card>
  </Col>
);

PrenocitveTable.propTypes = {
  title: PropTypes.string.isRequired,
  accommodations: PropTypes.arrayOf(PropTypes.object).isRequired,
  onRowSelect: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default translate('common')(PrenocitveTable);
