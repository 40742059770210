import React from 'react';
import { Card, CardBody, Col, ButtonToolbar, Button } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import renderSelectField from '../../../../shared/components/form/Select';

const allKW = () => {
  const all = [];
  for (let i = 1; i <= 52; i += 1) {
    all.push({
      value: i.toString(),
      label: i.toString(),
    });
  }
  return all;
};

const years = () => {
  const yrs = [2018, 2019, 2020, 2021, 2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030];
  const mapped = yrs.map(year => ({
    value: year.toString(),
    label: year.toString(),
  }));
  return mapped;
};

const SelectAnyWeekForm = ({ handleSubmit, handleCancel }) => (
  <Col md={12} lg={12}>
    <Card>
      <CardBody>
        <form className="form" onSubmit={handleSubmit}>
          <div className="form__half">
            <div className="form__form-group">
              <span className="form__form-group-label">KW</span>
              <div className="form__form-group-field">
                <Field name="kw" component={renderSelectField} options={allKW()} />
              </div>
            </div>
          </div>
          <div className="form__half">
            <div className="form__form-group">
              <span className="form__form-group-label">Leto</span>
              <div className="form__form-group-field">
                <Field name="year" component={renderSelectField} options={years()} />
              </div>
            </div>
          </div>
          <ButtonToolbar className="form__button-toolbar">
            <Button type="button" onClick={handleCancel}>
              Prekliči
            </Button>
            <Button color="primary" type="submit">
              Potrdi
            </Button>
          </ButtonToolbar>
        </form>
      </CardBody>
    </Card>
  </Col>
);

SelectAnyWeekForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
};

export default reduxForm({
  form: 'select_any_week_form', // a unique identifier for this form
})(SelectAnyWeekForm);
