import { API_LINK } from 'constants/config';
import { POST } from 'helpers/agent';

export default {
  getOne: async data => {
    const response = await POST(`${API_LINK}/a/getOneById`, JSON.stringify(data));
    return response.assignment;
  },
  getOneStaged: async data => {
    const response = await POST(`${API_LINK}/a/getStagedById`, JSON.stringify(data));
    return response.assignment;
  },
};
