import React from 'react';
import PropTypes from 'prop-types';
import ReactGA from 'react-ga';
import ReactExport from 'react-data-export';
import { Button } from 'reactstrap';

/* eslint-disable */
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
/* eslint-enable */

const gaLogEvent = () => {
  ReactGA.event({
    category: 'Delavci',
    action: 'Prenos Excel',
  });
};

const DownloadButton = title => (
  <Button size="sm" onClick={gaLogEvent} color="primary">
    {title}
  </Button>
);

const generateHeaders = () => {
  const firstPart = [
    {
      title: '#',
    },
    {
      title: 'Firma',
    },
    {
      title: 'Priimek',
    },
    {
      title: 'Ime',
    },
    {
      title: 'Datum rojstva',
    },
    {
      title: 'Prijava od',
    },
    {
      title: 'Odjava',
    },
    {
      title: 'Rezidenstvo',
    },
    {
      title: 'Davčna',
    },
    {
      title: 'EMŠO',
    },
    {
      title: 'Poklic',
    },
    {
      title: 'Naslov',
    },
    {
      title: 'Poštna',
    },
    {
      title: 'Kraj',
    },
    {
      title: 'Država',
    },
    {
      title: 'Državljanstvo',
    },
    {
      title: 'Kraj rojstva',
    },
    {
      title: 'Država rojstva',
    },
    {
      title: 'TRR',
    },
    {
      title: 'Telefon',
    },
    {
      title: 'Številka obleke',
    },
    {
      title: 'Številka čevljev',
    },
    {
      title: 'Številka zav.',
    },
    {
      title: 'Zavarovalnica',
    },
    {
      title: 'Potni list',
    },
  ];

  const multiDataSet = [
    {
      columns: firstPart,
      data: [],
    },
  ];
  return multiDataSet;
};

const generateData = dataAll => {
  // console.log('TCL: generateData -> dataAll', dataAll);
  const data = [];
  for (let i = 0; i < dataAll.length; i += 1) {
    const worker = dataAll[i];
    const workerRow = [
      {
        value: Number(`${i + 1}`),
      },
      {
        value: worker.company,
      },
      {
        value: worker.surname.toUpperCase(),
      },
      {
        value: worker.name,
      },
      {
        value: worker.birthDateString,
      },
      {
        value: worker.registeredAt,
      },
      {
        value: worker.unregisteredAt,
      },
      {
        value: worker.residency,
      },
      {
        value: worker.taxID,
      },
      {
        value: worker.emso,
      },
      {
        value: worker.profession,
      },
      {
        value: worker.address,
      },
      {
        value: worker.zip,
      },
      {
        value: worker.city,
      },
      {
        value: worker.country,
      },
      {
        value: worker.citizenship,
      },
      {
        value: worker.birthPlace,
      },
      {
        value: worker.birthCountry,
      },
      {
        value: worker.trr || ' ',
      },
      {
        value: worker.tel,
      },
      {
        value: worker.clothesSize,
      },
      {
        value: worker.shoeSize,
      },
      {
        value: worker.insNum,
      },
      {
        value: worker.insProvider,
      },
      {
        value: worker.passportNum,
      },
    ];
    data.push(workerRow);
  }

  return data;
};

class DelavciSheet extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { data, btn } = this.props;

    const headers = generateHeaders();
    headers[0].data = generateData(data);
    return (
      <ExcelFile element={DownloadButton(btn)} filename="Delavci">
        <ExcelSheet dataSet={headers} name="Delavci" />
      </ExcelFile>
    );
  }
}

DelavciSheet.propTypes = {
  btn: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default DelavciSheet;
