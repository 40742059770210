import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-i18next';
import { connect } from 'react-redux';
import { Table, ButtonToolbar, Button, UncontrolledTooltip, Progress } from 'reactstrap';
import { CompaniesProps } from 'shared/prop-types/ReducerProps';
import { getItem, setItem } from 'helpers/cookies';
import { TMZFY } from 'helpers/functions';
import financeApi from 'api/finance';
import payload from 'helpers/jwt';
import moment from 'moment-timezone';
import { LockIcon, LockOpenIcon, DebugStepOverIcon, DownloadIcon } from 'mdi-react';
import Dropdown from '../../../Filters/Dropdown';
import getRoles from '../../../../../constants/roles';
import constants from '../../../helpers/index';

const TAB_ID = 'wages-satnice';

const {
  AMCompanies,
  MONTHS,
  YEARS,
  SATNICE_FILTER_MONTH,
  SATNICE_FILTER_YEAR,
  SATNICE_FILTER_COMPANY,
  SATNICE_FILTER_COMPANY_NAME,
  handleFocus,
} = constants;

class SatniceTable extends Component {
  static propTypes = {
    companiesStore: CompaniesProps.isRequired,
    t: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      satnice: [],
      selectedMonth: getItem(SATNICE_FILTER_MONTH) || moment(new Date()).month() + 1,
      selectedYear: getItem(SATNICE_FILTER_YEAR) || moment(new Date()).year(),
      selectedCompany: getItem(SATNICE_FILTER_COMPANY) || this.handleProperCompanies()[0].value,
      selectedCompanyName: getItem(SATNICE_FILTER_COMPANY_NAME) || this.handleProperCompanies()[0].label,
      selectedColor: this.handleProperCompanies()[0].color,
      locked: false,
      ROLES: getRoles(),
      progress: 0,
    };
  }

  componentDidMount() {
    const { selectedMonth, selectedYear, selectedCompany } = this.state;
    this.fetchEmployeeSatnice(selectedMonth, selectedYear, selectedCompany);
  }

  dismissProgress = () => {
    this.setState({ progress: 100 });
    setTimeout(() => {
      this.setState({ progress: 0 });
    }, 1000);
  };

  handleProperCompanies = () => {
    const {
      companiesStore: { companiesTitles },
    } = this.props;

    const token = getItem('jwt');
    if (token) {
      const { customerId } = payload(token);
      //a.m.
      if (customerId === 'NZ3YJkYx') {
        return companiesTitles.filter(ct => AMCompanies.includes(ct.value));
      }
    }

    return companiesTitles;
  };

  //handleFocus = event => event.target.select();

  tableInput = (id, value, type, index, className = 'form-control', step = 1) => {
    if (type === 'number') {
      return (
        <input
          onFocus={e => handleFocus(e)}
          onChange={e => this.handleChange(index, id, e.target.value)}
          onBlur={e => this.handleSubmit(index, id, e.target.value)}
          value={value}
          type={type}
          className={className}
          step={step}
        />
      );
    }
    return (
      <textarea
        rows={1}
        onChange={e => this.handleChange(index, id, e.target.value)}
        onBlur={e => this.handleSubmit(index, id, e.target.value)}
        value={value}
        className={className}
      />
    );
  };

  renderDropdown = () => {
    return (
      <Fragment>
        <Dropdown
          defaultOption={this.state.selectedCompanyName}
          options={this.handleProperCompanies()}
          onItemSelect={this.handleCompanySelect}
        />
        <Dropdown
          defaultOption={this.state.selectedYear}
          options={YEARS.map(m => {
            return { value: m, label: m.toString(), color: 'white' };
          })}
          onItemSelect={this.handleYearSelect}
        />
        <Dropdown
          defaultOption={this.state.selectedMonth}
          options={MONTHS.map(m => {
            return { value: m, label: m.toString(), color: 'white' };
          })}
          onItemSelect={this.handleMonthSelect}
        />
      </Fragment>
    );
  };

  renderLockButton = () => {
    const { locked } = this.state;
    const { t } = this.props;
    return (
      <Fragment>
        <Button id="sat-lock-btn" size="sm" color="danger" className="sat-lock-btn" onClick={this.handleLockdown}>
          {locked ? <LockIcon /> : <LockOpenIcon />}
        </Button>
        <UncontrolledTooltip placement="top" target="sat-lock-btn">
          {!locked ? t('finance.lock') : t('finance.unlock')}
        </UncontrolledTooltip>
      </Fragment>
    );
  };

  renderTransferButton = () => {
    const { t } = this.props;
    return (
      <Fragment>
        <Button
          id="trans-month-btn"
          size="sm"
          color="warning"
          className="trans-month-btn"
          onClick={this.handleTransfer}
        >
          <DebugStepOverIcon />
        </Button>
        <UncontrolledTooltip placement="top" target="trans-month-btn">
          {t('finance.transferFromPrev')}
        </UncontrolledTooltip>
      </Fragment>
    );
  };

  renderDownloadButton = () => {
    const { t } = this.props;
    return (
      <Fragment>
        <Button
          id="sat-download-btn"
          size="sm"
          color="success"
          className="sat-download-btn"
          onClick={this.handleDownload}
        >
          <DownloadIcon />
        </Button>
        <UncontrolledTooltip placement="top" target="sat-download-btn">
          {t('form.exportExcel')}
        </UncontrolledTooltip>
      </Fragment>
    );
  };

  renderProgress = () => {
    const { progress } = this.state;
    return progress === 0 ? (
      <div style={{ width: '100%', height: '5px', backgroundColor: '#ECECEC', marginBottom: '30px' }}></div>
    ) : (
      <div className="progress-wrap progress-wrap--blue">
        <Progress value={progress} />
      </div>
    );
  };

  handleChange = (index, dataType, _value) => {
    let value = _value;
    if (!_value && dataType !== 'notes') value = 0;

    const { satnice } = this.state;
    // if (satnice[index][dataType].toString() === value.toString()) {
    //   return;
    // }
    let modifiedBy = null;
    const token = getItem('jwt');
    if (token) {
      const { uid, name, surname, username } = payload(token);
      modifiedBy = { uid, name, surname, username, updatedAt: TMZFY(new Date(Date.now())) };
    }

    const newState = satnice.map((item, i) => {
      if (i === index) {
        return { ...item, [dataType]: value, modifiedBy };
      }
      return item;
    });

    this.setState({
      satnice: newState,
    });
  };

  handleSubmit = (index, dataType, _value) => {
    let value = _value;
    if (!_value && dataType !== 'notes') value = 0;
    const { satnice, selectedMonth, selectedYear } = this.state;
    const { _id, notes, satnica } = satnice[index];
    // if (satnice[index][dataType].toString() !== value.toString()) {
    //   return;
    // }
    if (dataType === 'satnica') {
      financeApi.upsertEmployeeSatnica(_id, selectedMonth, selectedYear, value, notes, TAB_ID);
    } else {
      financeApi.upsertEmployeeSatnica(_id, selectedMonth, selectedYear, satnica, value, TAB_ID);
    }
  };

  fetchEmployeeSatnice = async (month, year, companyId) => {
    this.setState({ progress: 15 });
    const { employeeSatnice, locked } = await financeApi.getAllEmployeeSatnicas(month, year, companyId, TAB_ID);
    const { ROLES } = this.state;
    const isLocked = (ROLES.satnice && ROLES.satnice.readonly) || locked;
    this.setState({ satnice: employeeSatnice, locked: isLocked });
    this.dismissProgress();
  };

  handleCompanySelect = (companyId, color, name) => {
    const { selectedMonth, selectedYear } = this.state;
    this.setState({ selectedCompany: companyId, selectedColor: color, selectedCompanyName: name });
    setItem(SATNICE_FILTER_COMPANY, companyId);
    setItem(SATNICE_FILTER_COMPANY_NAME, name);

    this.fetchEmployeeSatnice(selectedMonth, selectedYear, companyId);
  };

  handleMonthSelect = month => {
    const { selectedCompany, selectedYear } = this.state;
    this.setState({ selectedMonth: month });
    setItem(SATNICE_FILTER_MONTH, month);
    this.fetchEmployeeSatnice(month, selectedYear, selectedCompany);
  };

  handleYearSelect = year => {
    const { selectedCompany, selectedMonth } = this.state;
    this.setState({ selectedYear: year });
    setItem(SATNICE_FILTER_YEAR, year);
    this.fetchEmployeeSatnice(selectedMonth, year, selectedCompany);
  };

  handleLockdown = async () => {
    const { selectedMonth, selectedYear } = this.state;
    await financeApi.setLockdown(selectedMonth, selectedYear, TAB_ID);

    this.setState(prevState => ({
      locked: !prevState.locked,
    }));
  };

  handleTransfer = async () => {
    const { selectedMonth, selectedYear, selectedCompany } = this.state;
    await financeApi.transferSatniceFromPrevMonth(selectedMonth, selectedYear);
    this.fetchEmployeeSatnice(selectedMonth, selectedYear, selectedCompany);
  };

  handleDownload = () => {
    const { selectedMonth, selectedYear, selectedCompany, selectedCompanyName } = this.state;
    financeApi.downloadSatnice(selectedMonth, selectedYear, selectedCompany, selectedCompanyName);
  };

  renderModified = modifiedBy => {
    const style = { color: 'lightgray' };
    if (!modifiedBy) return <span>/</span>;
    if (modifiedBy.uid === 'import')
      return (
        <span>
          <strong style={style}>IMPORT</strong>
        </span>
      );
    return (
      <Fragment>
        <div>
          <span style={style}>{modifiedBy.name}</span> <span style={style}>{modifiedBy.surname}</span>
        </div>
        <div>
          <span style={style}>{modifiedBy.updatedAt}</span>
        </div>
      </Fragment>
    );
  };

  render() {
    const { t } = this.props;
    const { satnice, progress, locked, ROLES, selectedColor } = this.state;
    const largeGray = { color: 'gray', fontSize: 'large' };
    //console.log('TCL: SatniceTable -> render -> satnice', satnice);
    return (
      <Fragment>
        <ButtonToolbar style={{ position: 'relative' }}>
          {this.renderDropdown()}
          {(ROLES.super || ROLES.sistem) && this.renderLockButton()}
          {(ROLES.super || ROLES.sistem) && this.renderTransferButton()}
          {this.renderDownloadButton()}
        </ButtonToolbar>
        {this.renderProgress()}
        {locked && (
          <h4 style={{ color: 'red', textAlign: 'center', marginBottom: '30px' }}>
            <strong>{t('finance.locked').toUpperCase()}</strong>
          </h4>
        )}
        <Table hover className="table--bordered" responsive>
          <thead>
            <tr>
              <th>#</th>
              <th>{t('form.surname')}</th>
              <th>{t('form.name')}</th>
              <th>{t('form.registeredAt')}</th>
              <th>{t('form.registeredTo')}</th>
              <th>
                {t('finance.satnica')} ({t('finance.prevMonth')})
              </th>
              <th>{t('finance.satnica')}</th>
              <th>{t('form.notes')}</th>
              <th>{t('finance.modifiedBy')}</th>
            </tr>
          </thead>
          <tbody>
            {satnice.map((row, index) => {
              return (
                <tr key={`satniceTR${row._id}`}>
                  <td>{index + 1}</td>
                  <td>{row.surname}</td>
                  <td>{row.name}</td>
                  <td>{row.company.registeredAt}</td>
                  <td>{row.company.unregisteredAt}</td>
                  <td>
                    <strong style={largeGray}>{row.satnicaPrev}</strong>
                  </td>
                  {!locked ? (
                    <Fragment>
                      <td style={{ width: '100px' }}>
                        {this.tableInput('satnica', satnice[index].satnica, 'number', index)}
                      </td>
                      <td>{this.tableInput('notes', satnice[index].notes, 'text', index)}</td>
                    </Fragment>
                  ) : (
                    <Fragment>
                      <td>
                        <strong style={largeGray}>{row.satnica}</strong>
                      </td>
                      <td>
                        <strong style={largeGray}>{row.notes}</strong>
                      </td>
                    </Fragment>
                  )}
                  <td>{this.renderModified(row.modifiedBy)}</td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </Fragment>
    );
  }
}

const wr = connect(state => ({
  companiesStore: state.companies,
}))(SatniceTable);

export default translate('common')(wr);
