import React from 'react';
import PropTypes from 'prop-types';
import getDaysInMonth from 'helpers/getDaysInMonth';
import ReactExport from 'react-data-export';
import { Button } from 'reactstrap';
import moment from 'moment-timezone';
import fix from 'helpers/floatParser';
import { TIMEZONE } from 'constants/config';
import { translate } from 'react-i18next';

/* eslint-disable */
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
/* eslint-enable */

const DownloadButton = title => (
  <Button size="sm" color="primary" style={{ marginRight: '10px' }}>
    {title}
  </Button>
);

const generateHeaders = (month, year, t) => {
  const daysInMonths = getDaysInMonth(month, year);
  const firstPart = [
    {
      title: t('form.num'),
    },
    {
      title: t('board.ow.orderer'),
    },
    {
      title: t('board.filters.worksite'),
    },
  ];
  const daysPart = daysInMonths.map(day => ({
    title: `${moment(day)
      .tz(TIMEZONE)
      .format('ddd')} ${moment(day)
      .tz(TIMEZONE)
      .format('D.M.YY')}`,
  }));
  const lastPart = [
    {
      title: `${t('calcs.sum')}  (${t('calcs.d')})`,
    },
    {
      title: `${t('calcs.sum')}  (${t('calcs.n')})`,
    },
    {
      title: `${t('calcs.sum')}  (${t('calcs.p')})`,
    },
    {
      title: t('calcs.sum'),
    },
  ];
  const columns = firstPart.concat(daysPart.concat(lastPart));
  const multiDataSet = [
    {
      columns,
      data: [],
    },
  ];
  return multiDataSet;
};

const generateData = dataAll => {
  // console.log('TCL: generateData -> dataAll', dataAll);
  const data = [];
  for (let i = 0; i < dataAll.list.length; i += 1) {
    const ow = dataAll.list[i];
    const owRow = [
      {
        value: Number(`${i + 1}`),
      },
      {
        value: ow.ordererTitle,
      },
      {
        value: ow.worksiteTitle,
      },
    ];

    for (let j = 0; j < ow.workdays.length; j += 1) {
      const workday = ow.workdays[j];
      owRow.push({
        value: Number(fix(workday.daySum + workday.nightSum)),
      });
    }

    owRow.push({
      value: Number(fix(ow.totalDaySum)),
    });
    owRow.push({
      value: Number(fix(ow.totalNightSum)),
    });
    owRow.push({
      value: Number(fix(ow.totalTransferSum)),
    });
    owRow.push({
      value: Number(fix(ow.totalSum)),
    });

    data.push(owRow);
  }
  return data;
};

class OWSheet extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { data, btn, month, year, t } = this.props;

    const headers = generateHeaders(month, year, t);
    headers[0].data = generateData(data);
    return (
      <ExcelFile element={DownloadButton(btn)} filename={t('calcs.owpairs')}>
        <ExcelSheet dataSet={headers} name={t('calcs.owpairs')} />
      </ExcelFile>
    );
  }
}

OWSheet.propTypes = {
  btn: PropTypes.string.isRequired,
  data: PropTypes.PropTypes.object.isRequired,
  month: PropTypes.number.isRequired,
  year: PropTypes.number.isRequired,
  t: PropTypes.func.isRequired,
};

export default translate('common')(OWSheet);
