import React from 'react';

// import PropTypes from 'prop-types';
// import { Redirect } from 'react-router-dom';
import { removeItem } from '../../helpers/cookies';
import { persistor } from '../App/store';
import { FRONTEND_LINK } from '../../constants/config';

class LogOutPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    removeItem('jwt');
    persistor.purge();
    window.location.replace(`${FRONTEND_LINK}/prijava`);
  }

  render() {
    return null; // <Redirect to="/prijava" />;
  }
}

export default LogOutPage;
