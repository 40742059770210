import React from 'react';
import { Card, CardBody, Col, ButtonToolbar, Button } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';
import renderSelectField from '../../../shared/components/form/Select';
import Modal from '../../../shared/components/Modal';
import validate from './validate';
import renderTextField from '../../../shared/components/form/TextField';

const PrenocitveForm = ({ handleSubmit, handleCancel, handleDelete, countries, editData, pristine, providers, t }) => (
  <Col md={12} lg={12}>
    <Card>
      <CardBody>
        <form className="form" onSubmit={handleSubmit}>
          <div className="form__form-group">
            <span className="form__form-group-label">{t('board.ow.provider')}</span>
            <div className="form__form-group-field">
              <Field name="providerId" component={renderSelectField} options={providers} />
            </div>
          </div>

          <div className="form__form-group">
            <span className="form__form-group-label">{t('form.address')}</span>
            <div className="form__form-group-field">
              <Field name="address" component={renderTextField} type="text" />
            </div>
          </div>

          <div className="form__form-group">
            <span className="form__form-group-label">{t('form.zip')}</span>
            <div className="form__form-group-field">
              <Field name="zip" component={renderTextField} type="text" />
            </div>
          </div>

          <div className="form__form-group">
            <span className="form__form-group-label">{t('form.city')}</span>
            <div className="form__form-group-field">
              <Field name="city" component={renderTextField} type="text" />
            </div>
          </div>

          <div className="form__form-group">
            <span className="form__form-group-label">{t('form.country')}</span>
            <div className="form__form-group-field">
              <Field name="countryId" component={renderSelectField} options={countries} />
            </div>
          </div>

          <ButtonToolbar className="form__button-toolbar">
            {editData ? (
              <Modal
                color="danger"
                title={t('form.warn')}
                colored
                btn={t('form.delete')}
                message={t('messages.accommodation.delete.confirm')}
                onConfirm={() => handleDelete(editData)}
              />
            ) : null}
            <Button type="button" onClick={handleCancel}>
              {t('form.cancel')}
            </Button>
            <Button color="primary" type="submit" disabled={pristine}>
              {editData ? t('form.save') : t('form.add')}
            </Button>
          </ButtonToolbar>
        </form>
      </CardBody>
    </Card>
  </Col>
);

PrenocitveForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
  countries: PropTypes.arrayOf(PropTypes.object).isRequired,
  providers: PropTypes.arrayOf(PropTypes.object).isRequired,
  editData: PropTypes.object,
  pristine: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
};

PrenocitveForm.defaultProps = {
  editData: null,
};

const wr = reduxForm({
  form: 'prenocitve_form', // a unique identifier for this form
  validate,
})(PrenocitveForm);

export default translate('common')(wr);
