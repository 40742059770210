import React, { Component } from 'react';
import { translate } from 'react-i18next';
import {
  ToiletIcon,
  KitchenIcon,
  ParkingIcon,
  BusIcon,
  NoSmokingIcon,
  TvIcon,
  WifiIcon,
  FamilyIcon,
  WashingMachineIcon,
  BedIcon,
  RoomIcon,
  CarIcon,
  ShoppingBasketIcon,
  DogIcon,
  RestaurantIcon,
  TruckIcon,
  FreeBreakfastIcon,
  SausageIcon,
  GarageIcon,
} from 'mdi-react';
import PropTypes from 'prop-types';

class Amenity extends Component {
  static propTypes = {
    amenity: PropTypes.string.isRequired,
    t: PropTypes.func.isRequired,
  };

  icon = () => {
    const { amenity } = this.props;
    console.log('amenity', amenity);
    switch (amenity.name) {
      case 'toilet':
        return <ToiletIcon color="#818181" />;
      case 'publicToilet':
        return <ToiletIcon color="#818181" />;
      case 'kitchen':
        return <KitchenIcon color="#818181" />;
      case 'publicKitchen':
        return <KitchenIcon color="#818181" />;
      case 'publicParking':
        return <ParkingIcon color="#818181" />;
      case 'parking':
        return <ParkingIcon color="#818181" />;
      case 'station':
        return <BusIcon color="#818181" />;
      case 'noSmoking':
        return <NoSmokingIcon color="#818181" />;
      case 'tv':
        return <TvIcon color="#818181" />;
      case 'wifi':
        return <WifiIcon color="#818181" />;
      case 'familyFriendly':
        return <FamilyIcon color="#818181" />;
      case 'washingMachine':
        return <WashingMachineIcon color="#818181" />;
      case 'singleBedroom':
        return <BedIcon color="#818181" />;
      case 'sharedRooms':
        return <RoomIcon color="#818181" />;
      case 'terrace':
        return <RoomIcon color="#818181" />;
      case 'seperateBeds':
        return <BedIcon color="#818181" />;
      case 'vanParking':
        return <CarIcon color="#818181" />;
      case 'storageFacility':
        return <GarageIcon color="#818181" />;
      case 'doubleRoom':
        return <RoomIcon color="#818181" />;
      case 'supermarket':
        return <ShoppingBasketIcon color="#818181" />;
      case 'apartment':
        return <RoomIcon color="#818181" />;
      case 'petsAllowed':
        return <DogIcon color="#818181" />;
      case 'dryer':
        return <WashingMachineIcon color="#818181" />;
      case 'restaurant':
        return <RestaurantIcon color="#818181" />;
      case 'truckParking':
        return <TruckIcon color="#818181" />;
      case 'breakfast':
        return <FreeBreakfastIcon color="#818181" />;
      case 'smoker':
        return <SausageIcon color="#818181" />;
      default:
        return <ToiletIcon color="#818181" />;
    }
  };

  text = () => {
    const { amenity, t } = this.props;
    console.log('amenity', amenity);
    switch (amenity.name) {
      case 'toilet':
        return t('places.amenities.toilet');
      case 'publicToilet':
        return t('places.amenities.publicToilet');
      case 'kitchen':
        return t('places.amenities.kitchen');
      case 'publicKitchen':
        return t('places.amenities.publicKitchen');
      case 'publicParking':
        return t('places.amenities.publicParking');
      case 'parking':
        return t('places.amenities.parking');
      case 'station':
        return t('places.amenities.station');
      case 'noSmoking':
        return t('places.amenities.noSmoking');
      case 'tv':
        return t('places.amenities.tv');
      case 'wifi':
        return t('places.amenities.wifi');
      case 'familyFriendly':
        return t('places.amenities.familyFriendly');
      case 'washingMachine':
        return t('places.amenities.washingMachine');
      case 'singleBedroom':
        return t('places.amenities.singleBedroom');
      case 'sharedRooms':
        return t('places.amenities.sharedRooms');
      case 'terrace':
        return t('places.amenities.terrace');
      case 'seperateBeds':
        return t('places.amenities.seperateBeds');
      case 'vanParking':
        return t('places.amenities.vanParking');
      case 'storageFacility':
        return t('places.amenities.storageFacility');
      case 'doubleRoom':
        return t('places.amenities.doubleRoom');
      case 'supermarket':
        return t('places.amenities.supermarket');
      case 'apartment':
        return t('places.amenities.apartment');
      case 'petsAllowed':
        return t('places.amenities.petsAllowed');
      case 'dryer':
        return t('places.amenities.dryer');
      case 'restaurant':
        return t('places.amenities.restaurant');
      case 'truckParking':
        return t('places.amenities.truckParking');
      case 'breakfast':
        return t('places.amenities.breakfast');
      case 'smoker':
        return t('places.amenities.smoker');
      default:
        return 'Not found';
    }
  };

  render() {
    return (
      <div className="mr-4 mb-2 place-amenity">
        {this.icon()}
        <span className="ml-2">{this.text()}</span>
      </div>
    );
  }
}

export default translate('common')(Amenity);
