import React from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-i18next';
import { Card, CardBody, CardTitle } from 'reactstrap';
import { connect } from 'react-redux';
import { DatePickerField } from 'shared/components/form/DatePicker';
import { getOws } from 'redux/actions/owActions';

class DateFilter extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      value: new Date(),
    };
  }

  handleDateChange = value => {
    this.setState({
      value: new Date(value),
    });
    this.props.dispatch(getOws(new Date(value)));
  };

  render() {
    const { value } = this.state;
    const { t } = this.props;
    return (
      <Card>
        <CardBody className="pb-4">
          <CardTitle>{t('board.filters.date.title')}:</CardTitle>
          <DatePickerField onChange={this.handleDateChange} value={value} />
        </CardBody>
      </Card>
    );
  }
}

const wr = connect()(DateFilter);

export default translate('common')(wr);
