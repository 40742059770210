import React from 'react';
import PropTypes from 'prop-types';

import ReactExport from 'react-data-export';
import { Button } from 'reactstrap';

/* eslint-disable */
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
/* eslint-enable */

const DownloadButton = (title, disabled) => (
  <Button size="sm" color="primary" disabled={disabled}>
    {title}
  </Button>
);

const generateHeaders = () => {
  const firstPart = [
    {
      title: '#',
    },
    {
      title: 'Registrska',
    },
    {
      title: 'Znamka',
    },
    {
      title: 'Dobavitelj',
    },
    {
      title: 'Tip',
    },
    {
      title: 'Voznik',
    },
    {
      title: 'Vozi od (datum)',
    },
    {
      title: 'Tehnični (Številka)',
    },
    {
      title: 'Tehnični (Začetek)',
    },
    {
      title: 'Tehnični (Potek)',
    },
    {
      title: 'Zavarovanje (Številka)',
    },
    {
      title: 'Zavarovanje (Začetek)',
    },
    {
      title: 'Zavarovanje (Potek)',
    },
    {
      title: 'Registracija (Prva)',
    },
    {
      title: 'Registracija (Začetek)',
    },
    {
      title: 'Registracija (Potek)',
    },
  ];

  const multiDataSet = [
    {
      columns: firstPart,
      data: [],
    },
  ];
  return multiDataSet;
};

const generateData = dataAll => {
  // console.log('TCL: generateData -> dataAll', dataAll);
  const data = [];
  for (let i = 0; i < dataAll.length; i += 1) {
    const vehicle = dataAll[i];
    const workerRow = [
      {
        value: Number(`${i + 1}`),
      },
      {
        value: vehicle.licensePlate,
      },
      {
        value: vehicle.brand,
      },
      {
        value: vehicle.provider,
      },
      {
        value: vehicle.type,
      },
      {
        value: vehicle.driverData
          ? `${vehicle.driverData.driver.surname.toUpperCase()} ${vehicle.driverData.driver.name}`
          : 'DOMA',
      },
      {
        value: vehicle.driverData ? vehicle.driverData.date : '',
      },
      {
        value: vehicle.tech ? vehicle.tech.num : '',
      },
      {
        value: vehicle.tech ? vehicle.tech.start : '',
      },
      {
        value: vehicle.tech ? vehicle.tech.end : '',
      },
      {
        value: vehicle.ins ? vehicle.ins.num : '',
      },
      {
        value: vehicle.ins ? vehicle.ins.start : '',
      },
      {
        value: vehicle.ins ? vehicle.ins.end : '',
      },
      {
        value: vehicle.reg ? vehicle.reg.first : '',
      },
      {
        value: vehicle.reg ? vehicle.reg.start : '',
      },
      {
        value: vehicle.reg ? vehicle.reg.end : '',
      },
    ];
    data.push(workerRow);
  }

  return data;
};

class VozilaSheet extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { data, btn, disabled } = this.props;

    const headers = generateHeaders();
    headers[0].data = generateData(data);
    return (
      <ExcelFile element={DownloadButton(btn, disabled)} filename="Vozila">
        <ExcelSheet dataSet={headers} name="Vozila" />
      </ExcelFile>
    );
  }
}

VozilaSheet.propTypes = {
  btn: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  disabled: PropTypes.bool.isRequired,
};

export default VozilaSheet;
