import React, { Component } from 'react';
import { translate } from 'react-i18next';
import { Card, CardBody, Row, Col, ButtonToolbar, ButtonGroup, Button } from 'reactstrap';
import PropTypes from 'prop-types';

class DocOptionsFilter extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    defaultValues: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      docStateSelectedButton: props.defaultValues.docStateSelectedButton,
      docVisibilitySelectedButton: props.defaultValues.docVisibilitySelectedButton,
      docTypeSelectedButton: props.defaultValues.docTypeSelectedButton,
    };
  }

  toggleStateButtons = buttonId => {
    this.setState({ docStateSelectedButton: buttonId });
    this.props.onSubmit({
      ...this.state,
      docStateSelectedButton: buttonId,
    });
  };

  toggleVisibilityButtons = buttonId => {
    this.setState({ docVisibilitySelectedButton: buttonId });
    this.props.onSubmit({
      ...this.state,
      docVisibilitySelectedButton: buttonId,
    });
  };

  toggleTypeButtons = buttonId => {
    this.setState({ docTypeSelectedButton: buttonId });
    this.props.onSubmit({
      ...this.state,
      docTypeSelectedButton: buttonId,
    });
  };

  render() {
    const { t } = this.props;
    const { docStateSelectedButton, docVisibilitySelectedButton, docTypeSelectedButton } = this.state;

    return (
      <Card>
        <CardBody className="pb-4">
          <div className="card__title">
            <h5 className="bold-text">{t('docsexp.view')}</h5>
          </div>
          <Row>
            <Col lg={2} className="mb-2 mt-3">
              <div style={{ height: '100%' }}>
                <span className="center-vetically">{t('docsexp.state')}:</span>
              </div>
            </Col>
            <Col lg={10} className="mb-2">
              <div style={{ width: '100%' }}>
                <ButtonToolbar>
                  <ButtonGroup className="btn-group--icons btn-group--justified">
                    <Button
                      size="sm"
                      outline={docStateSelectedButton !== '1'}
                      onClick={() => this.toggleStateButtons('1')}
                    >
                      {t('docsexp.all')}
                    </Button>
                    <Button
                      size="sm"
                      outline={docStateSelectedButton !== '2'}
                      onClick={() => this.toggleStateButtons('2')}
                    >
                      {t('docsexp.expired')}
                    </Button>
                    <Button
                      size="sm"
                      outline={docStateSelectedButton !== '3'}
                      onClick={() => this.toggleStateButtons('3')}
                    >
                      {t('docsexp.beforeExp')}
                    </Button>
                  </ButtonGroup>
                </ButtonToolbar>
              </div>
            </Col>
          </Row>
          <Row className="mt-4">
            <Col lg={2} className="mb-2 mt-3">
              <div style={{ height: '100%' }}>
                <span className="center-vetically">{t('docsexp.showOptions')}:</span>
              </div>
            </Col>
            <Col lg={10}>
              <div style={{ width: '100%' }}>
                <ButtonToolbar>
                  <ButtonGroup className="btn-group--icons btn-group--justified">
                    <Button
                      size="sm"
                      outline={docVisibilitySelectedButton !== '1'}
                      onClick={() => this.toggleVisibilityButtons('1')}
                    >
                      {t('docsexp.all2')}
                    </Button>
                    <Button
                      size="sm"
                      outline={docVisibilitySelectedButton !== '2'}
                      onClick={() => this.toggleVisibilityButtons('2')}
                    >
                      {t('docsexp.unhidden')}
                    </Button>
                    <Button
                      size="sm"
                      outline={docVisibilitySelectedButton !== '3'}
                      onClick={() => this.toggleVisibilityButtons('3')}
                    >
                      {t('docsexp.hidden')}
                    </Button>
                  </ButtonGroup>
                </ButtonToolbar>
              </div>
            </Col>
          </Row>
          <Row className="mt-4">
            <Col lg={2} className="mb-2 mt-3">
              <div style={{ height: '100%' }}>
                <span className="center-vetically">{t('vehicles.type')}:</span>
              </div>
            </Col>
            <Col lg={10}>
              <div style={{ width: '100%' }}>
                <ButtonToolbar>
                  <ButtonGroup className="btn-group--icons btn-group--justified">
                    <Button
                      size="sm"
                      outline={docTypeSelectedButton !== '1'}
                      onClick={() => this.toggleTypeButtons('1')}
                    >
                      {t('docsexp.all')}
                    </Button>
                    <Button
                      size="sm"
                      outline={docTypeSelectedButton !== '2'}
                      onClick={() => this.toggleTypeButtons('2')}
                    >
                      {t('sidebar_content.workers.title')}
                    </Button>
                    <Button
                      size="sm"
                      outline={docTypeSelectedButton !== '3'}
                      onClick={() => this.toggleTypeButtons('3')}
                    >
                      {t('sidebar_content.vehicles.title')}
                    </Button>
                  </ButtonGroup>
                </ButtonToolbar>
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
    );
  }
}

export default translate('common')(DocOptionsFilter);
