import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { translate } from 'react-i18next';
import { Modal } from 'reactstrap';
import { connect } from 'react-redux';
import AddIcon from 'mdi-react/AccountMultiplePlusOutlineIcon';
import EditIcon from 'mdi-react/AccountEditIcon';
import { getItem } from 'helpers/cookies';
import { API_LINK } from 'constants/config';
import { POST } from 'helpers/agent';
import payload from 'helpers/jwt';
import { AccommodationsProps, WorksitesProps, VehiclesProps, OrderersProps } from 'shared/prop-types/ReducerProps';
import SpremembeForm from '../../../Spremembe/components/SpremembeForm';

const API_EDIT_BOARD_STATE = '/b/updateState';
const parseFields = fields => {
  const allFields = {};
  const { uid } = payload(getItem('jwt'));

  allFields.workerId = fields.workerId;
  allFields.date = fields.date;
  allFields.ordererId = fields.ordererId;
  allFields.worksiteId = fields.worksiteId;
  allFields.accommodationId = fields.accommodationId;
  allFields.vehicleId = fields.vehicleId;
  allFields.isPristine = fields.isPristine;

  allFields.leaves = [];
  for (let i = 0; i < 200; i += 1) {
    const { extra } = fields;
    if (extra.includes(i)) continue;

    if (fields[`leaveStartsAt${i}`]) {
      const leaveData = {
        startsAt: fields[`leaveStartsAt${i}`],
        modifiedBy: uid,
        createdAt: Date.now(),
        updatedAt: Date.now(),
      };
      if (fields[`leaveEndsAt${i}`]) {
        leaveData.endsAt = fields[`leaveEndsAt${i}`];
      }

      allFields.leaves.push(leaveData);
    }
  }

  return allFields;
};

class SpremembeModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
    };

    this.toggle = this.toggle.bind(this);
    this.cancel = this.cancel.bind(this);

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleESC = this.handleESC.bind(this);
  }

  componentDidMount() {
    document.addEventListener('keydown', this.handleESC, false);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleESC, false);
  }

  handleESC(event) {
    if (event.keyCode === 27) {
      this.setState({ modal: false });
      this.cancel(null);
    }
  }

  toggle() {
    this.setState({
      modal: !this.state.modal,
    });
  }

  cancel(data) {
    this.setState({
      modal: false,
    });
    this.props.onClose(data);
  }

  async handleSubmit(values) {
    try {
      console.log('TCL: SpremembeModal -> handleSubmit -> values', values);
      const { t } = this.props;
      const body = JSON.stringify(parseFields(values));
      console.log('body', body);
      const data = await POST(API_LINK + API_EDIT_BOARD_STATE, body);
      if (data.success) {
        this.cancel({
          title: t('messages.changes.title'),
          message: t('messages.changes.success'),
          success: true,
        });
      } else {
        this.cancel({
          title: t('messages.changes.title'),
          message: t('messages.changes.error'),
          success: false,
        });
      }
    } catch (error) {
      console.log(error);
      this.cancel(null);
    }
  }

  render() {
    const {
      icon,
      title,
      colored,
      header,
      show,
      editData,
      orderersStore: { titlesOrderers },
      worksitesStore: { titlesWorksites },
      accommodationsStore: { accommodationTitles },
      vehiclesStore: { licenses },
      t,
    } = this.props;

    let Icon;

    switch (icon) {
      case 'add':
        Icon = <AddIcon />;
        break;
      case 'edit':
        Icon = <EditIcon />;
        break;
      default:
        break;
    }
    const modalClass = classNames({
      'modal-dialog--colored': colored,
      'modal-dialog--header': header,
    });

    return (
      <div style={{ width: '100%' }}>
        <Modal isOpen={this.state.modal || show} toggle={this.toggle} className={`modal-dialog--primary ${modalClass}`}>
          <div className="modal__header">
            <button className="lnr lnr-cross modal__close-btn" onClick={this.toggle} />
            {header ? '' : Icon}
            <h4 className="bold-text  modal__title">{title}</h4>
          </div>
          <div className="modal__body">
            <div className="theme-light">
              {editData ? (
                <SpremembeForm
                  onSubmit={this.handleSubmit}
                  orderers={titlesOrderers}
                  worksites={titlesWorksites}
                  vehicles={licenses}
                  accommodations={accommodationTitles}
                  handleCancel={() => this.cancel(null)}
                  initialValues={editData}
                  editData={editData}
                  initialFieldCount={editData.leavesCount}
                />
              ) : (
                <SpremembeForm
                  onSubmit={this.handleSubmit}
                  orderers={titlesOrderers}
                  worksites={titlesWorksites}
                  vehicles={licenses}
                  accommodations={accommodationTitles}
                  handleCancel={() => this.cancel(null)}
                />
              )}
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

SpremembeModal.propTypes = {
  title: PropTypes.string,
  icon: PropTypes.string.isRequired,
  colored: PropTypes.bool,
  header: PropTypes.bool,
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  editData: PropTypes.object,

  orderersStore: OrderersProps.isRequired,
  worksitesStore: WorksitesProps.isRequired,
  vehiclesStore: VehiclesProps.isRequired,
  accommodationsStore: AccommodationsProps.isRequired,

  t: PropTypes.func.isRequired,
};

SpremembeModal.defaultProps = {
  title: '',
  colored: false,
  header: false,
  editData: null,
};

const wr = connect(state => ({
  orderersStore: state.orderers,
  worksitesStore: state.worksites,
  vehiclesStore: state.vehicles,
  accommodationsStore: state.accommodations,
}))(SpremembeModal);

export default translate('common')(wr);
