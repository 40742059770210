export const SET_WORKSITES = 'SET_WORKSITES';
export const SET_WORKSITES_TITLES = 'SET_WORKSITES_TITLES';
export const SET_WORKSITES_TITLES_WITH_ADDRESS = 'SET_WORKSITES_TITLES_WITH_ADDRESS';

export function setWorksites(worksites) {
  return {
    type: SET_WORKSITES,
    worksites,
  };
}

export function setWorksitesTitles(titlesWorksites) {
  return {
    type: SET_WORKSITES_TITLES,
    titlesWorksites,
  };
}
export function setWorksitesTitlesWithAddress(worksitesWithAddress) {
  return {
    type: SET_WORKSITES_TITLES_WITH_ADDRESS,
    worksitesWithAddress,
  };
}
