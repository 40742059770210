import React from 'react';
import { Card, CardBody, Col, ButtonToolbar, Button } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import CalendarBlankIcon from 'mdi-react/CalendarBlankIcon';
import vehiclesApi from 'api/vehicles';
import { connect } from 'react-redux';
import Modal from 'shared/components/Modal';
import renderTextField from 'shared/components/form/TextField';
import validate from './validate';
//import renderCheckBoxField from '../../../../shared/components/form/CheckBox';
import renderSelectField from '../../../../shared/components/form/Select';
import renderDatePickerField from '../../../../shared/components/form/DatePicker';

//import constants from '../../../Finance/helpers/index';
import ReturnedReasonsTable from './ReturnedReasonsTable';

//const { TRAVEL_ORDER_OPTIONS } = constants;

class VozilaIzpisiForm extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      clashMsg: null,
      reasons: [],
    };
  }

  handleCheckVehicleLogsClash = () => {
    const { type } = this.props;
    if (type !== 'vehicle') return;
    setTimeout(async () => {
      /*eslint-disable*/
      const {
        form: {
          vozila_izpisi_form: { values },
        },
        vehicleId,
      } = this.props;
      console.log('TCL: VozilaIzpisiForm -> handleCheckVehicleLogsClash -> values', values);
      /* eslint-enable */

      if (vehicleId && values.date && values.workerId) {
        const { clashMsg } = await vehiclesApi.checkVehicleLogsClash({
          vehicleId,
          date: values.date,
          workerId: values.workerId.id,
        });
        console.log('TCL: VozilaIzpisiForm -> handleCheckVehicleLogsClash -> clashMsg', clashMsg);
        if (clashMsg) {
          this.setState({
            clashMsg,
          });
        } else {
          this.setState({
            clashMsg: null,
          });
        }
      }
    }, 100);
  };

  handleReasonsTableChange = values => {
    //console.log('handleReasonsTableChange -> values', values);
    this.setState({ reasons: values });
  };

  hijackSubmit = e => {
    e.preventDefault();

    const { reasons } = this.state;
    const { handleSubmit, onSubmit } = this.props;

    handleSubmit(values => {
      onSubmit(values, reasons);
    })();
  };

  render() {
    const {
      //handleSubmit,
      //handleReasonsSubmit,
      handleCancel,
      editData,
      pristine,
      workers,
      handleDelete,
      vehicles,
      type,
    } = this.props;

    const ridelogId = editData ? editData._id : null;

    const { clashMsg, reasons } = this.state;

    let returnedAtField = 'returnedAt';
    if (editData && editData.orRetAt) returnedAtField = 'orRetAt';

    const isFake = editData && editData.fake;

    return (
      <Col md={12} lg={12}>
        <Card>
          <CardBody>
            <form
              className="form"
              onSubmit={e => this.hijackSubmit(e)}
              //onSubmit={handleSubmit}
            >
              <div className="form__form-group">
                <span className="form__form-group-label">{isFake ? 'Vmesni datum' : 'Datum prevzema'}</span>
                <div className="form__form-group-field">
                  {isFake ? (
                    <Field name="dateString" component="input" type="text" disabled />
                  ) : (
                    <Field name="date" component={renderDatePickerField} onChange={this.handleCheckVehicleLogsClash} />
                  )}
                  <div className="form__form-group-icon">
                    <CalendarBlankIcon />
                  </div>
                </div>
              </div>

              <div className="form__form-group">
                <span className="form__form-group-label">Vrnil domov (datum)</span>
                <div className="form__form-group-field">
                  {/* {isFake ? (
                    <Field name={`${returnedAtField}String`} component="input" type="text" disabled />
                  ) : ( */}
                  <Field name={returnedAtField} component={renderDatePickerField} />
                  {/* )} */}
                  <div className="form__form-group-icon">
                    <CalendarBlankIcon />
                  </div>
                </div>
              </div>

              <div className="form__form-group">
                <span className="form__form-group-label">{type === 'vehicle' ? 'Delavec' : 'Vozilo'}</span>
                <div className="form__form-group-field">
                  {isFake ? (
                    <Field name={type === 'vehicle' ? 'employee' : 'vehicle'} component="input" type="text" disabled />
                  ) : (
                    <Field
                      name={type === 'vehicle' ? 'workerId' : 'vehicleId'}
                      component={renderSelectField}
                      options={type === 'vehicle' ? workers : vehicles}
                      onChange={this.handleCheckVehicleLogsClash}
                    />
                  )}
                </div>
              </div>

              {clashMsg && (
                <React.Fragment>
                  <span style={{ color: 'red' }}>
                    <strong>{clashMsg}</strong>
                  </span>
                  <br />
                </React.Fragment>
              )}

              <div className="form__form-group">
                <span className="form__form-group-label">Kilometri</span>
                <div className="form__form-group-field">
                  <Field name="kilometers" component={renderTextField} type="text" />
                </div>
              </div>

              {/* <div className="form__form-group">
                <span className="form__form-group-label">Znesek (€)</span>
                <div className="form__form-group-field">
                  <Field name="cost" component={renderTextField} type="number" />
                </div>
              </div>

              <div className="form__form-group">
                <div className="form__form-group-field">
                  <Field
                    name="receipt"
                    component={renderCheckBoxField}
                    label="Prinesel račun"
                    // defaultChecked={false}
                    className="colored"
                  />
                </div>
              </div>

              <div key="TravelOrderOptions" className="form__form-group">
                <span className="form__form-group-label">Razlog</span>
                <div className="form__form-group-field bigger-text">
                  <Field name="travelOrderOption" component={renderSelectField} options={TRAVEL_ORDER_OPTIONS} />
                </div>
              </div> */}

              <ReturnedReasonsTable ridelogId={ridelogId} onChange={this.handleReasonsTableChange} />

              <div className="form__form-group">
                <span className="form__form-group-label">Opombe</span>
                <div className="form__form-group-field">
                  <Field name="notes" component="textarea" type="text" />
                </div>
              </div>

              <ButtonToolbar className="form__button-toolbar">
                <Button type="button" onClick={handleCancel}>
                  Prekliči
                </Button>
                <Button color="primary" type="submit" disabled={pristine && reasons.length === 0}>
                  {editData ? 'Shrani spremembe' : 'Dodaj'}
                </Button>
                {editData && !editData.fake && (
                  <Modal
                    color="danger"
                    title="Pozor!"
                    colored
                    btn="IZBRIŠI"
                    message="Ste prepričani, da želite izbrisati ta vnos?"
                    onConfirm={() => handleDelete(editData)}
                  />
                )}
              </ButtonToolbar>
            </form>
          </CardBody>
        </Card>
      </Col>
    );
  }
}

VozilaIzpisiForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  //handleReasonsSubmit: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
  editData: PropTypes.object,
  workers: PropTypes.arrayOf(PropTypes.object).isRequired,
  vehicles: PropTypes.arrayOf(PropTypes.object).isRequired,
  type: PropTypes.string.isRequired,
  pristine: PropTypes.bool.isRequired,
  vehicleId: PropTypes.string.isRequired,
};

VozilaIzpisiForm.defaultProps = {
  editData: null,
};

export default reduxForm({
  form: 'vozila_izpisi_form', // a unique identifier for this form
  validate,
})(
  connect(state => ({
    form: state.form,
  }))(VozilaIzpisiForm),
);
