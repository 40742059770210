import React, { Component } from 'react';
import { Col, Container, Row, ButtonToolbar, Card, CardBody } from 'reactstrap';
import ReactLoading from 'react-loading';
import ReactGA from 'react-ga';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';
import NotificationSystem from 'rc-notification';
import { BasicNotification } from 'shared/components/Notification';
import SelectDropdown from 'shared/components/SelectDropdown';
import companiesApi from 'api/companies';
import worksitesApi from 'api/worksites';
import accommodationsApi from 'api/accommodations';
import ReservationFilters from './components/Filters';
import RezervacijeModal from './components/RezervacijeModal';
import RezervacijeTable from './components/RezervacijeTable';
import RezervacijeSheet from './components/RezervacijeSheet';
import getRoles from '../../../constants/roles';
import PaginationComponent from '../../../shared/components/pagination/Pagination';
import fix, { dottify } from '../../../helpers/floatParser';

let notificationLU = null;
let notificationRU = null;
let notificationTC = null;

const RESERVATION_ITEMS_KEY = 'RESERVATION_ITEMS_KEY';

const showNotification = ({ notification, position }) => {
  switch (position) {
    case 'left-up':
      notificationLU.notice({
        content: notification,
        duration: 5,
        closable: true,
        style: { top: 0, left: 240 },
        className: position,
      });
      break;
    case 'right-up':
      notificationRU.notice({
        content: notification,
        duration: 5,
        closable: true,
        style: { top: 0, left: 'calc(100vw - 100%)' },
        className: position,
      });
      break;
    default:
      notificationTC.notice({
        content: notification,
        duration: 5,
        closable: true,
        style: { top: 0, left: 0 },
        className: position,
      });
      break;
  }
};

class RezervacijePage extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    const itemsToShow = localStorage.getItem(RESERVATION_ITEMS_KEY)
      ? Number(localStorage.getItem(RESERVATION_ITEMS_KEY))
      : 10;
    this.state = {
      companies: [],
      providers: [],
      accommodations: [],
      accommodationsFull: [],
      reservationsPaged: [],
      worksites: [],
      reservations: [],
      showEdit: false,
      progressNum: 0,
      editReservation: null,
      selectedCompany: null,
      selectedCompanyTitle: null,
      pageOfItems: 1,
      itemsToShow,
      ROLES: getRoles(),
      filterValues: this.defaultFilterValues(),
      sumPaid: 0,
    };

    this.onRowSelect = this.onRowSelect.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.showNotif = this.showNotif.bind(this);
    this.fetchData = this.fetchData.bind(this);
    this.handleProgress = this.handleProgress.bind(this);
    this.handleDropdownSubmit = this.handleDropdownSubmit.bind(this);
  }

  componentDidMount() {
    NotificationSystem.newInstance({}, n => (notificationLU = n));
    NotificationSystem.newInstance({}, n => (notificationRU = n));
    NotificationSystem.newInstance({}, n => (notificationTC = n));

    this.fetchData();
  }

  componentWillUnmount() {
    notificationLU.destroy();
    notificationRU.destroy();
    notificationTC.destroy();
  }

  async onRowSelect(id) {
    try {
      const { ROLES } = this.state;
      const { t } = this.props;
      if (ROLES.accommodations && ROLES.accommodations.reservations && ROLES.accommodations.reservations.readonly) {
        ReactGA.event({
          category: 'Prenočitve - Rezervacije',
          action: `Urejanje Prenočitve - Rezervacije - ${id} - Ni dostopa`,
        });
        this.showNotif({
          title: `${t('misc.forbidden')}!`,
          message: `${t('misc.noright')}!`,
          success: false,
        });
        return;
      }

      ReactGA.event({
        category: 'Prenočitve - Rezervacije',
        action: `Urejanje Prenočitve - Rezervacije - ${id}`,
      });

      //console.log('TCL: RezervacijePage -> onRowSelect -> id', id);
      const { reservation } = await accommodationsApi.getReservationById({
        reservationId: id,
      });
      if (reservation) {
        // console.log(
        //   'TCL: RezervacijePage -> onRowSelect -> reservation',
        //   reservation,
        // );
        this.setState({ editReservation: reservation, showEdit: true });
      }
    } catch (error) {
      console.log('TCL: RezervacijePage -> onRowSelect -> error', error);
    }
  }

  defaultFilterValues = () => {
    return {
      dropdown: {
        selectedCompanySearchItem: [],
        selectedWorkerSearchItem: [],
        selectedAccommodationSearchItem: [],
        selectedCompanyWorkerSearchItem: [],
        selectedWorksiteSearchItem: [],
        selectedProviderSearchItem: [],
      },
    };
  };

  showNotif = ({ title, message, success }) => {
    const color = success ? 'success' : 'danger';
    showNotification({
      notification: <BasicNotification color={color} title={title} message={message} />,
      position: 'right-up',
    });
  };

  handleProgress = addProgress => {
    const { progressNum } = this.state;
    this.setState({ progressNum: progressNum + addProgress });
    const that = this;
    if (progressNum + addProgress >= 100) {
      setTimeout(() => {
        that.setState({ progressNum: 0 });
      }, 1000);
    }
  };

  fetchData = async () => {
    this.handleProgress(10);
    const { companies } = await companiesApi.getfull();
    const { titlesWorksites } = await worksitesApi.getall();
    const acm = await accommodationsApi.getall();
    //console.log('TCL: fetchData -> acm', acm);
    const { providerTitles, accommodations, accommodationTitles } = acm;
    const titlesList = [];
    companies.forEach(ord => {
      if (!ord.fake) {
        const ordData = {
          value: ord._id,
          label: ord.name,
        };
        titlesList.push(ordData);
      }
    });

    this.setState({
      companies: titlesList,
      worksites: titlesWorksites,
      providers: providerTitles,
      accommodationsFull: accommodations,
      accommodations: accommodationTitles,
    });
    this.handleProgress(100);
  };

  handleCloseModal = data => {
    if (data) this.showNotif(data);
    this.setState({ showEdit: false });
    if (data) {
      this.fetchData();
      if (this.state.selectedCompany) {
        this.handleDropdownSubmit({
          selected_dropdown: {
            value: this.state.selectedCompany,
            label: this.state.selectedCompanyTitle,
          },
        });
      }
    }
  };

  handleDropdownSubmit = async values => {
    //console.log('TCL: handleDropdownSubmit -> values', values);

    try {
      const { itemsToShow } = this.state;
      // console.log('TCL: itemsToShow', itemsToShow);
      // if (values.selected_dropdown === null) {
      //   this.setState({
      //     selectedCompany: null,
      //     selectedCompanyTitle: null,
      //     reservations: [],
      //   });
      //   return;
      // }

      this.handleProgress(10);
      ReactGA.event({
        category: 'Prenočitve - Rezervacije',
        action: `Filtriranje`,
      });
      const { reservations } = await accommodationsApi.getReservations(values);
      //console.log('TCL: reservations', reservations);
      const sum = reservations.reduce((total, reservation) => {
        if (reservation.isPaid) {
          const thePrice = reservation.price ? Number(dottify(reservation.price)) : 0;
          return total + thePrice;
        }
        return total;
      }, 0);
      console.log('TCL: sum', sum);

      this.setState({
        reservations,
        selectedCompany: true, //values.selected_dropdown.value,
        selectedCompanyTitle: `Seštevek plačanih: ${fix(sum)}€`, // values.selected_dropdown.label,
        sumPaid: fix(sum),
      });
      const totalPages = Math.ceil(reservations.length / itemsToShow);
      const reservationsPaged = this.filterRows(reservations, totalPages, itemsToShow);
      this.setState({ reservationsPaged, pageOfItems: totalPages });
      this.handleProgress(100);
    } catch (error) {
      console.log(error);
    }
  };

  filterRows = (originalRows, pageNumber, rowsOnPage) => {
    const rowsFrom = rowsOnPage * (pageNumber - 1);
    const rowsTo = rowsFrom + rowsOnPage;
    return originalRows.slice(rowsFrom, rowsTo);
  };

  onChangePage = pageOfItems => {
    const { reservations, itemsToShow } = this.state;
    if (pageOfItems) {
      const reservationsPaged = this.filterRows(reservations, pageOfItems, itemsToShow);
      this.setState({ reservationsPaged, pageOfItems });
    }
  };

  handleSelectOptionsChange = e => {
    const itemsToShow = Number(e.target.value);
    localStorage.setItem(RESERVATION_ITEMS_KEY, itemsToShow);
    const { reservations } = this.state;
    const totalPages = Math.ceil(reservations.length / itemsToShow);
    const reservationsPaged = this.filterRows(reservations, totalPages, itemsToShow);
    this.setState({ reservationsPaged, pageOfItems: totalPages, itemsToShow });
  };

  handleFilterSubmit = filterValues => {
    console.log('TCL: filterValues', filterValues);
    try {
      this.handleDropdownSubmit(filterValues);
    } catch (error) {
      console.log('TCL: DokumentiPage -> handleFilterSubmit -> error', error);
    }
  };

  render() {
    const {
      companies,
      providers,
      accommodations,
      accommodationsFull,
      worksites,
      showEdit,
      editReservation,
      progressNum,
      reservations,
      selectedCompany,
      selectedCompanyTitle,
      reservationsPaged,
      pageOfItems,
      itemsToShow,
      ROLES,
      sumPaid,
    } = this.state;
    const { t } = this.props;
    let showBtn = ROLES.full;

    if (!showBtn) {
      showBtn = !(
        ROLES.accommodations &&
        ROLES.accommodations.reservations &&
        ROLES.accommodations.reservations.readonly
      );
    }

    const defaultFilter = this.defaultFilterValues();
    return (
      <Container className="dashboard">
        <Row>
          <Col lg={12}>
            <h3 className="page-title">
              {t('sidebar_content.accommodations.title')} - {t('sidebar_content.accommodations.reservations')}
            </h3>
          </Col>

          <Col lg={{ size: 12, offset: 0 }}>
            <ButtonToolbar className="button-toolbar">
              <RezervacijeModal
                btn={t('messages.reservations.add.title')}
                icon="add"
                title={t('messages.reservations.add.title')}
                companies={companies}
                providers={providers}
                accommodations={accommodations}
                accommodationsFull={accommodationsFull}
                worksites={worksites}
                hasBtn={showBtn}
                show={false}
                onClose={this.handleCloseModal}
              />
              {progressNum === 0 && reservations.length > 0 && (
                <RezervacijeSheet btn={t('form.exportExcel')} data={reservations} sum={sumPaid} />
              )}
            </ButtonToolbar>
          </Col>
        </Row>

        <Col lg={12}>
          <ReservationFilters defaultValues={defaultFilter} onSubmit={this.handleFilterSubmit} />
        </Col>

        <Row>
          {/* {!selectedCompany && (
            <div style={{ width: '100%', textAlign: 'center', marginTop: '200px' }}>
              <h3 style={{ margin: '0 auto', color: '#f6da6e' }}>NOBENO PODJETJE NI IZBRANO...</h3>
            </div>
          )} */}
          {selectedCompany && progressNum > 0 && (
            <ReactLoading type="bars" color="gray" height={128} width={128} className="react-loader" />
          )}
          {selectedCompany && progressNum === 0 && (
            <Col md={12} lg={12} xl={12}>
              <Card>
                <CardBody>
                  <RezervacijeTable
                    title={t('sidebar_content.accommodations.reservations')}
                    subtitle={selectedCompanyTitle}
                    reservations={reservationsPaged.length > 0 ? reservationsPaged : reservations}
                    onRowSelect={this.onRowSelect}
                    onSelectOptions={this.handleSelectOptionsChange}
                    initialSelectValue={itemsToShow.toString()}
                  />
                  <PaginationComponent
                    itemsCount={reservations.length}
                    itemsToShow={itemsToShow}
                    pageOfItems={pageOfItems}
                    onChangePage={this.onChangePage}
                  />
                </CardBody>
              </Card>
            </Col>
          )}
        </Row>

        <RezervacijeModal
          btn="Uredi rezervacijo"
          icon="edit"
          title="Uredi rezervacijo"
          companies={companies}
          providers={providers}
          accommodations={accommodations}
          accommodationsFull={accommodationsFull}
          worksites={worksites}
          hasBtn={false}
          show={showEdit}
          onClose={this.handleCloseModal}
          editData={editReservation}
        />
      </Container>
    );
  }
}

export default translate('common')(RezervacijePage);
