import { SET_WORKDAYS, SET_WORKERS } from '../actions/hoursActions';

const initialState = {
  workdays: null,
  workdaysLoaded: false,
  workers: null,
  workersLoaded: false,
};

export default function(state = initialState, action) {
  switch (action.type) {
    case SET_WORKDAYS:
      return { ...state, workdays: action.workdays, workdaysLoaded: true };
    case SET_WORKERS:
      return { ...state, workers: action.workers, workersLoaded: true };
    default:
      return state;
  }
}
