import { API_LINK } from 'constants/config';
import { GET, POST } from 'helpers/agent';

export default {
  getExpired: async () => {
    const data = await GET(`${API_LINK}/doc/getExpired`);
    if (data.success) {
      return data.alerts;
    }
    return [];
  },
  getTypes: async () => {
    const data = await GET(`${API_LINK}/doc/getTypes`);
    if (data.success) {
      return data.types;
    }
    return [];
  },
  remove: async file => {
    const data = await POST(`${API_LINK}/f/remove`, JSON.stringify({ file }));
    return data;
  },
  getAll: async () => {
    //const dataAll = await GET(`${API_LINK}/doc/getAll`);
    localStorage.setItem('docAlertOverrride', false);
    const dataTypes = await GET(`${API_LINK}/doc/getTypes`);
    const dataExpTimetable = await GET(`${API_LINK}/doc/getExpTimetable`);
    const dataAlert = await GET(`${API_LINK}/doc/checkExpAlert`);
    if (dataTypes.success && dataExpTimetable.success && dataAlert.success) {
      return {
        docsData: {
          docsExpTimetable: dataExpTimetable.docsExpTimetable,
          docTypes: dataTypes.types,
          showAlert: dataAlert.showAlert,
        },
      };
    }
    return null;
  },
  getFiltered: async body => {
    const data = await POST(`${API_LINK}/doc/getFiltered`, JSON.stringify(body));
    if (data.success) {
      return data.docs;
    }
    return [];
  },
  hideDocument: async body => {
    const data = await POST(`${API_LINK}/doc/hideDocument`, JSON.stringify(body));
    return data.success;
  },
  unhideDocument: async body => {
    const data = await POST(`${API_LINK}/doc/unhideDocument`, JSON.stringify(body));
    return data.success;
  },
};
