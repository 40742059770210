import React from 'react';
import PropTypes from 'prop-types';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';
import FallbackImage from 'shared/img/fallback.png';

const BasicCarouselWithoutCaption = ({ images, onImageClick }) => (
  <Carousel showThumbs={images.length > 1} className="mb-3">
    {images.length === 0 ? (
      <div>
        <img src={FallbackImage} alt="place p" className="place-image" />
      </div>
    ) : (
      images.map(image => (
        <div onClick={onImageClick} role="presentation" onKeyDown={onImageClick}>
          <img src={image} alt="place p" className="place-image" />
        </div>
      ))
    )}
  </Carousel>
);

BasicCarouselWithoutCaption.propTypes = {
  onImageClick: PropTypes.func.isRequired,
  images: PropTypes.array.isRequired,
};

export default BasicCarouselWithoutCaption;
