import React, { PureComponent } from 'react';
import DownIcon from 'mdi-react/ChevronDownIcon';
import { connect } from 'react-redux';
import { Collapse } from 'reactstrap';
import { PropTypes } from 'prop-types';
import { translate } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { SERVER_LINK } from 'constants/config';
import { getItem } from 'helpers/cookies';
import payload from 'helpers/jwt';
import TopbarMenuLink from './TopbarMenuLink';
import { UserProps } from '../../../shared/prop-types/ReducerProps';

const Ava = `${process.env.PUBLIC_URL}/img/ava.png`;

const portParser = link => {
  const a = document.createElement('a');
  a.href = link;
  console.log('SERVER LINK TRENUTNI PORT', a.port);

  const token = getItem('jwt');
  if (!token) {
    console.log('SERVER LINK TOKEN MISSING ERROR!!');
    console.log('TCL: portParser -> link', link);
    return link;
  }
  const { port } = payload(token);
  if (!port) {
    console.log('SERVER LINK PORT MISSING ERROR!!');
    console.log('TCL: portParser -> link', link);
    return link;
  }

  a.port = port;
  console.log('SERVER LINK NOVI HREF', a.href);
  return a.href;
};

const AVA_ROUTE = `${portParser(SERVER_LINK)}/ava/`;

class TopbarProfile extends PureComponent {
  static propTypes = {
    // dispatch: PropTypes.func.isRequired,
    user: UserProps,
    t: PropTypes.func.isRequired,
  };

  static defaultProps = {
    user: null,
  };

  constructor(props) {
    super(props);
    this.state = {
      collapse: false,
      user: props.user,
      // ime: null,
    };
  }

  /* componentDidMount() {
    fetch('http://146.148.21.254:5000/api/c/getcountries')
      .then(response => response.json())
      .then((data) => {
        this.setState({ ime: data.countries[0].country });
        // console.log(data.countries[0].country);
      });
  } */
  UNSAFE_componentWillReceiveProps(nextProps) {
    // console.log('nextProps', nextProps);
    if (nextProps.user && !this.props.user) {
      this.setState({ user: nextProps.user });
    }
  }

  toggle = () => {
    this.setState({ collapse: !this.state.collapse });
  };

  render() {
    const { user } = this.state;
    const { t } = this.props;
    const name = user ? user.name : '';
    const surname = user ? user.surname : '';
    const avatar = user ? AVA_ROUTE + user.avatar : Ava;
    /*  const { ime } = this.state;
    console.log(ime); */
    return (
      <div className="topbar__profile">
        <button className="topbar__avatar" onClick={this.toggle}>
          <img className="topbar__avatar-img" src={avatar} alt="avatar" />
          <p className="topbar__avatar-name">{`${name} ${surname}`}</p>
          <DownIcon className="topbar__icon" />
        </button>
        {this.state.collapse && <button className="topbar__back" onClick={this.toggle} />}
        <Collapse isOpen={this.state.collapse} className="topbar__menu-wrap">
          <div className="topbar__menu">
            {/* <TopbarMenuLink title="Page one" icon="list" path="/pages/one" />
            <TopbarMenuLink title="Page two" icon="inbox" path="/pages/two" />
            <div className="topbar__menu-divider" /> */}
            <TopbarMenuLink title={t('topbar_profile.logout')} icon="exit" path="/odjava" />
          </div>
        </Collapse>
      </div>
    );
  }
}

const wr = withRouter(
  connect(state => ({
    sidebar: state.sidebar,
    user: state.user.user,
  }))(TopbarProfile),
);

export default translate('common')(wr);
