import React, { Component } from 'react';
import { translate } from 'react-i18next';
import { Card, CardBody, Row, Col } from 'reactstrap';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { MultiSelectField } from 'shared/components/form/MultiSelect';
import { EmployeesProps, DataListProps, VehiclesProps } from 'shared/prop-types/ReducerProps';

class DocDropdownFilter extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    workersState: EmployeesProps.isRequired,
    dataListStore: DataListProps.isRequired,
    vehiclesState: VehiclesProps.isRequired,
    onSubmit: PropTypes.func.isRequired,
    defaultValues: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      selectedDocTypeSearchItem: props.defaultValues.selectedDocTypeSearchItem,
      selectedWorkerSearchItem: props.defaultValues.selectedWorkerSearchItem,
      selectedVehicleSearchItem: props.defaultValues.selectedVehicleSearchItem,
    };
  }

  handleDocTypeChange = value => {
    this.setState({
      selectedDocTypeSearchItem: value,
    });
    this.props.onSubmit({
      ...this.state,
      selectedDocTypeSearchItem: value,
    });
  };

  handleWorkerChange = value => {
    this.setState({
      selectedWorkerSearchItem: value,
    });
    this.props.onSubmit({
      ...this.state,
      selectedWorkerSearchItem: value,
    });
  };

  handleVehicleChange = value => {
    this.setState({
      selectedVehicleSearchItem: value,
    });
    this.props.onSubmit({
      ...this.state,
      selectedVehicleSearchItem: value,
    });
  };

  render() {
    const { t, workersState, dataListStore, vehiclesState } = this.props;
    const { selectedDocTypeSearchItem, selectedWorkerSearchItem, selectedVehicleSearchItem } = this.state;
    return (
      <Card>
        <CardBody className="pb-4">
          <div className="card__title">
            <h5 className="bold-text">{t('board.filters.title')}</h5>
          </div>

          <Row>
            <Col lg={3} className="mb-2">
              <div style={{ height: '100%' }}>
                <span className="center-vetically">{t('docsexp.type')}:</span>
              </div>
            </Col>
            <Col lg={9} className="mb-2">
              <div className="display-table">
                <MultiSelectField
                  name="doc-exp-doctypes-filter-search"
                  onChange={this.handleDocTypeChange}
                  placeholder={`${t('board.filters.choose')}`}
                  options={dataListStore.docTypes}
                  value={selectedDocTypeSearchItem}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg={3} className="mb-2">
              <div style={{ height: '100%' }}>
                <span className="center-vetically">{t('board.ow.worker')}:</span>
              </div>
            </Col>
            <Col lg={9} className="mb-2">
              <div className="display-table">
                <MultiSelectField
                  name="doc-exp-workers-filter-search"
                  onChange={this.handleWorkerChange}
                  placeholder={`${t('board.filters.choose')}`}
                  options={workersState.employeesNames}
                  value={selectedWorkerSearchItem}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg={3} className="mb-2">
              <div style={{ height: '100%' }}>
                <span className="center-vetically">{t('board.ow.vehicle')}:</span>
              </div>
            </Col>
            <Col lg={9} className="mb-2">
              <div className="display-table">
                <MultiSelectField
                  name="doc-exp-workers-filter-search"
                  onChange={this.handleVehicleChange}
                  placeholder={`${t('board.filters.choose')}`}
                  options={vehiclesState.licenses}
                  value={selectedVehicleSearchItem}
                />
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
    );
  }
}

const cn = connect(state => ({
  workersState: state.employees,
  vehiclesState: state.vehicles,
  dataListStore: state.dataList,
}))(DocDropdownFilter);

export default translate('common')(cn);
