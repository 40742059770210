import moment from 'moment-timezone';
import { TIMEZONE } from 'constants/config';
import countries from 'i18n-iso-countries';

export default function trimmify(raw) {
  const obj = raw;
  Object.keys(obj).map(k => {
    try {
      obj[k] = obj[k].trim();
    } catch (e) {
      return obj[k];
    }
    return obj[k];
  });
  return obj;
}

export function shortify(longString, maxlen) {
  if (!longString) return '';
  let shortstring = longString;
  if (longString.length > maxlen) {
    shortstring = longString.substring(0, maxlen);
    shortstring += '...';
  }

  return shortstring;
}

export function TMZFY(date, format = 'DD. MM. YY') {
  return moment(date)
    .tz(TIMEZONE)
    .format(format);
}

export function clone(original) {
  return JSON.parse(JSON.stringify(original));
}

export function countrify(countryId) {
  const lang = localStorage.getItem('lang') || 'sl';
  return countries.getName(countryId, lang);
}

export function onlyUnique(value, index, self) {
  return self.indexOf(value) === index;
}

/* export function compareDates(key, order = 'asc') {
  // eslint-disable-next-line func-names
  return function (a, b) {
    let comparison = 0;
    if (a.date > b.date) {
      comparison = 1;
    } else if (a.date < b.date) {
      comparison = -1;
    }
    return order === 'desc' ? comparison * -1 : comparison;
  };
} */
