import React from 'react';
import { Card, CardBody, Col, Table, ButtonToolbar, Button, UncontrolledTooltip } from 'reactstrap';
import PropTypes from 'prop-types';
import { translate } from 'react-i18next';
import translateProfession from 'translations/dynamicTranslator';
import fix, { commify } from '../../../../helpers/floatParser';

// const hoursSum = sums => sums.hoursSum + sums.nightHoursSum + sums.internalDaySum + sums.internalNightSum;
const renderTable = (workers, t) =>
  workers.map((worker, index) => (
    <tr key={worker._id} id={worker._id}>
      <td>{worker.name}</td>
      <td>{translateProfession(worker.profession, localStorage.getItem('lang') || 'sl')}</td>
      <td>{commify(fix(worker.sum.hoursSum))}</td>
      <td>{commify(fix(worker.sum.nightHoursSum))}</td>
      <td>{commify(fix(worker.sum.internalDaySum))}</td>
      <td>{commify(fix(worker.sum.internalNightSum))}</td>
      <td>{commify(fix(worker.sum.totalSum))}</td>
      <td>
        {worker.sum.notes.length > 0 ? (
          <ButtonToolbar className="btn-toolbar--center">
            <Button id={`A${worker._id}${index}`} size="sm" color="link" className="accommodations-table-btn">
              <strong style={{ color: 'red' }}>{t('form.notes')}!</strong>
            </Button>
            <UncontrolledTooltip placement="right" target={`A${worker._id}${index}`}>
              {worker.sum.notes.map(note => (
                <p>{note}</p>
              ))}
            </UncontrolledTooltip>
          </ButtonToolbar>
        ) : (
          '/'
        )}
      </td>
    </tr>
  ));

const WorkersTable = ({ title, workers, t }) => (
  <Col md={12} lg={12} xl={12}>
    <Card>
      <CardBody>
        <div className="card__title">
          <h5 className="bold-text">{title}</h5>
          <h5 style={{ color: 'coral' }}>
            {t('workerstable.sum')}: <strong>{commify(fix(workers.totalSum || 0))}</strong>
          </h5>
        </div>
        <Table className="table--bordered" responsive>
          <thead>
            <tr>
              <th>{t('board.ow.worker')}</th>
              <th>{t('form.profession')}</th>
              <th>{t('workerstable.dayHours')}</th>
              <th>{t('workerstable.nightHours')}</th>
              <th>
                {t('workerstable.intHours')} ({t('workerstable.day')})
              </th>
              <th>
                {t('workerstable.intHours')} ({t('workerstable.night')})
              </th>
              <th>{t('workerstable.sum')}</th>
              <th>{t('form.notes')}</th>
            </tr>
          </thead>
          <tbody>{renderTable(workers.workers, t)}</tbody>
        </Table>
      </CardBody>
    </Card>
  </Col>
);

WorkersTable.propTypes = {
  title: PropTypes.string.isRequired,
  workers: PropTypes.PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
};

WorkersTable.defaultProps = {};

export default translate('common')(WorkersTable);
