import React, { Component } from 'react';
import { Card, CardBody, CardHeader, Badge, Container, Button } from 'reactstrap';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Editors } from 'react-data-grid-addons';
import EditableTable from 'shared/components/table/EditableTable';
import ReactLoading from 'react-loading';
import { Row } from 'react-data-grid';
import getDaysInMonth from 'helpers/getDaysInMonth';
import NotificationSystem from 'rc-notification';
import moment from 'moment-timezone';
import workhoursApi from 'api/workhours';
import lockdownsApi from 'api/lockdowns';

import { connect } from 'react-redux';
import fix, { commify } from 'helpers/floatParser';
import _ from 'lodash';

import { HoursProps, AppStatusProps } from 'shared/prop-types/ReducerProps';
// import AddDay from './AddDay';
import Modal from '../../../../shared/components/Modal';
import { BasicNotification } from '../../../../shared/components/Notification';
import { setRouterBlock, setRouterBlockNotif } from '../../../../redux/actions/appActions';

const { DropDownEditor } = Editors;

function sortNumber(a, b) {
  return a - b;
}

let LOCKED = false;

let notificationLU = null;
let notificationRU = null;
let notificationTC = null;

const showNotification = ({ notification, position }) => {
  switch (position) {
    case 'left-up':
      notificationLU.notice({
        content: notification,
        duration: 5,
        closable: true,
        style: { top: 0, left: 240 },
        className: position,
      });
      break;
    case 'right-up':
      notificationRU.notice({
        content: notification,
        duration: 5,
        closable: true,
        style: { top: 0, left: 'calc(100vw - 100%)' },
        className: position,
      });
      break;
    default:
      notificationTC.notice({
        content: notification,
        duration: 5,
        closable: true,
        style: { top: 0, left: 0 },
        className: position,
      });
      break;
  }
};

const WorksitesEditor = worksites => <DropDownEditor options={worksites} />;

const parseAdditionalDaysList = (workdays, additionalDays = []) => {
  try {
    const parsedAdditional = additionalDays;
    workdays.forEach(workday => {
      let parsedAdditionalId =
        workday.day.indexOf('(') > -1
          ? workday.day.substring(workday.day.indexOf('(') + 1, workday.day.indexOf(')'))
          : null;

      if (Number.isNaN(Number(parsedAdditionalId))) parsedAdditionalId = null;
      if (parsedAdditionalId) {
        const parsedDateNum =
          workday.day.indexOf('(') > -1 ? workday.day.substring(0, workday.day.indexOf('(')) : workday.day;

        const theDate = new Date(Number(parsedDateNum));
        parsedAdditional.push(theDate.getDate());
      }
    });

    parsedAdditional.sort(sortNumber);
    return parsedAdditional;
  } catch (err) {
    return additionalDays;
  }
};

const getTransfers = (workdays, date) => {
  console.log('workdays', workdays);
  const transfers = [
    {
      day: date.getTime().toString(),
    },
  ];
  const filtered = workdays.filter(w => w.transfer);

  return filtered.concat(transfers);
};

const RowRenderer = passedProps => {
  const day = moment.unix(passedProps.row.key).day();
  let theClass = '';
  if (day === 0 || day === 6) theClass = 'weekend-row';
  if (Number.isNaN(day)) theClass = 'additional-row';
  return (
    <div className={`${theClass}`}>
      <Row {...passedProps} />
    </div>
  );
};

const RowRenderer_Transfer = props => (
  <div className="transfer-row">
    <Row {...props} />
  </div>
);

class Table extends Component {
  constructor(props) {
    super(props);
    this.state = {
      heads: [],
      rows: [],
      updating: false,
      // transfer
      heads_Transfer: [],
      rows_Transfer: [],
      updating_Transfer: false,
      totalDaySum: 0,
      totalNightSum: 0,
      totalTransferDaySum: 0,
      totalTransferNightSum: 0,
      unsaved: false,
      routeTo: null,
      postingHours: false,
      wasClash: false,
      forceUpdate: false,
    };
    this.handleAddDay = this.handleAddDay.bind(this);
    this.generateRows = this.generateRows.bind(this);
    this.generateHeads = this.generateHeads.bind(this);
    this.onTableUpdate = this.onTableUpdate.bind(this);

    // transfer
    this.generateHeads_Transfer = this.generateHeads_Transfer.bind(this);
    this.generateRows_Transfer = this.generateRows_Transfer.bind(this);
    this.onTableUpdate_Transfer = this.onTableUpdate_Transfer.bind(this);
    this.addTranferRow = this.addTranferRow.bind(this);
    // this.startUpdateTimeout = this.startUpdateTimeout.bind(this);
    this.lockdown = this.lockdown.bind(this);

    this.handleWindowBeforeUnload = this.handleWindowBeforeUnload.bind(this);
    this.postHoursAndUpdate = this.postHoursAndUpdate.bind(this);

    this.handleLeavePage = this.handleLeavePage.bind(this);
  }

  async componentDidMount() {
    const { dispatch } = this.props;
    dispatch(setRouterBlockNotif(null));
    dispatch(setRouterBlock(false));
    workhoursApi.clearSchedule();

    NotificationSystem.newInstance({}, n => (notificationLU = n));
    NotificationSystem.newInstance({}, n => (notificationRU = n));
    NotificationSystem.newInstance({}, n => (notificationTC = n));

    LOCKED = await lockdownsApi.check({
      lockId: this.props.employee.value,
      lockDate: `${this.props.date.month}/${this.props.date.year}`,
      lockType: 'worker',
    });

    this.generateHeads();
    this.generateHeads_Transfer();
    window.addEventListener('beforeunload', this.handleWindowBeforeUnload);
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.employee.value !== prevProps.employee.value ||
      this.props.date.month !== prevProps.date.month ||
      this.props.date.year !== prevProps.date.year || // ||
      // @Konrad je kul da sem to zakomentiral?
      !_.isEqual(prevProps.hours, this.props.hours) ||
      this.state.forceUpdate
    ) {
      console.log('componentDidUpdate kliče generateRows ');
      this.setState({ forceUpdate: false });
      this.generateRows();
      this.generateRows_Transfer();
    }

    const { appStatus, dispatch } = this.props;
    if (appStatus.showRouterBlockNotif && !prevProps.appStatus.showRouterBlockNotif) {
      const saveFirst = window.confirm('Ali želite shraniti ure, predno zapustite to stran?');
      dispatch(setRouterBlockNotif(null));
      this.handleLeavePage(saveFirst, appStatus.showRouterBlockNotif);
    }
  }

  componentWillUnmount() {
    notificationLU.destroy();
    notificationRU.destroy();
    notificationTC.destroy();

    /* if (this.state.unsaved) {
      this.postHoursAndUpdate(true);
    } */
    window.removeEventListener('beforeunload', this.handleWindowBeforeUnload);
  }

  async onTableUpdate(event) {
    // clearTimeout(this.UPDATE_TIMEOUT);
    const { orderers, worksites, employee } = this.props;
    const worksitesWithInternal = [{ value: 'Interno', id: 'internal' }, ...worksites];
    const orderersWithInternal = [{ value: 'Interno', id: 'internal' }, ...orderers];
    const currentRow = event.rowGetter(event.row);
    const orderer = event.updated.orderer
      ? orderersWithInternal.find(ord => ord.value === event.updated.orderer)
      : orderersWithInternal.find(ord => ord.value === currentRow.orderer);
    const worksite = event.updated.worksite
      ? worksitesWithInternal.find(ord => ord.value === event.updated.worksite)
      : worksitesWithInternal.find(ord => ord.value === currentRow.worksite);
    const updatedKeys = Object.keys(event.updated)[0];
    console.log('TCL: Table -> onTableUpdate -> event.updated', event.updated);
    console.log('updatedKeys', updatedKeys);
    const hours = updatedKeys === 'hours' ? event.updated.hours : currentRow.hours;
    const nightHours = updatedKeys === 'nightHours' ? event.updated.nightHours : currentRow.nightHours;
    const allHours = { hours, nightHours };
    const notes = updatedKeys === 'notes' ? event.updated.notes : currentRow.notes;

    const allowed = this.checkIfLetterCodeAllowed({
      hours: allHours.hours,
      key: currentRow.key,
    });

    if (!allowed) {
      allHours.hours = 'N';
      allHours.nightHours = 'N';
      this.setState({ wasClash: true });
    }

    const postData = {
      workerId: employee.value,
      ordererId: orderer.id,
      worksiteId: worksite.id,
      selfReported: true,
      notes,
      date: currentRow.key,
      transfer: false,
      ...allHours,
    };

    this.setState({ unsaved: true });
    this.props.dispatch(setRouterBlock(true));
    workhoursApi.addhours(postData);
    // this.startUpdateTimeout();
    // onChange();
  }

  async onTableUpdate_Transfer(event) {
    // clearTimeout(this.UPDATE_TIMEOUT);
    const { orderers, worksites, employee } = this.props;
    const worksitesWithInternal = [{ value: 'Interno', id: 'internal' }, ...worksites];
    const orderersWithInternal = [{ value: 'Interno', id: 'internal' }, ...orderers];
    const currentRow = event.rowGetter(event.row);
    const orderer = event.updated.orderer
      ? orderersWithInternal.find(ord => ord.value === event.updated.orderer)
      : orderersWithInternal.find(ord => ord.value === currentRow.orderer);
    const worksite = event.updated.worksite
      ? worksitesWithInternal.find(ord => ord.value === event.updated.worksite)
      : worksitesWithInternal.find(ord => ord.value === currentRow.worksite);
    const updatedKeys = Object.keys(event.updated)[0];
    console.log('updatedKeys', updatedKeys);
    const hours = updatedKeys === 'hours' ? event.updated.hours : currentRow.hours;
    const nightHours = updatedKeys === 'nightHours' ? event.updated.nightHours : currentRow.nightHours;
    const allHours = { hours, nightHours };
    const notes = updatedKeys === 'notes' ? event.updated.notes : currentRow.notes;

    const postData = {
      workerId: employee.value,
      ordererId: orderer.id,
      worksiteId: worksite.id,
      selfReported: true,
      notes,
      date: currentRow.key,
      transfer: true,
      ...allHours,
    };
    this.setState({ unsaved: true });
    this.props.dispatch(setRouterBlock(true));
    workhoursApi.addhours(postData);

    // this.startUpdateTimeout();
    // onChange();
  }

  async handleLeavePage(saveFirst, route) {
    if (saveFirst) {
      await this.postHoursAndUpdate();
    } else {
      this.props.dispatch(setRouterBlock(false));
      this.props.dispatch(setRouterBlockNotif(null));
    }

    this.setState({ routeTo: route });
  }

  handleWindowBeforeUnload = e => {
    if (!this.state.unsaved) return '';
    // Chrome requires returnValue to be set.
    // window.alert('HALOO');
    // console.log('UNLOOOOAD');
    // Cancel the event as stated by the standard.
    e.preventDefault();
    e.returnValue = 'unloading';

    // return something to trigger a dialog
    return 'Do something';
  };

  showNotif = ({ title, message, success }) => {
    const color = success ? 'success' : 'danger';
    showNotification({
      notification: <BasicNotification color={color} title={title} message={message} />,
      position: 'right-up',
    });
  };

  isReservedLetterCode = value => {
    if (
      value &&
      (value.toUpperCase() === 'D' ||
        value.toUpperCase() === 'B' ||
        value.toUpperCase() === 'P' ||
        value.toUpperCase() === 'C')
    ) {
      return true;
    }
    return false;
  };

  checkIfLetterCodeAllowed = dependentValues => {
    if (this.isReservedLetterCode(dependentValues.hours)) {
      if (this.props) {
        const { workdays } = this.props.hours;
        const splitAdditional = dependentValues.key.split('(');
        const additionalId = splitAdditional.length > 1 ? splitAdditional[1].split(')')[0] : null;
        const date = `${splitAdditional[0]}000`;
        const sameDateWDays = workdays.filter(w => w.day.split('(')[0] === date);

        const otherSameDays = [];
        for (let i = 0; i < sameDateWDays.length; i += 1) {
          const split1 = sameDateWDays[i].day.split('(');

          if (split1.length === 1 && additionalId) {
            otherSameDays.push(sameDateWDays[i]);
          } else if (split1.length > 1 && split1[1].split(')')[0] !== additionalId) {
            otherSameDays.push(sameDateWDays[i]);
          }
        }

        if (otherSameDays.length > 0) {
          const nonZeroHours = otherSameDays.filter(
            o =>
              o.workhours.dayHours > 0 ||
              o.workhours.dayHours < 0 ||
              o.workhours.nightHours > 0 ||
              o.workhours.nightHours < 0,
          );

          if (nonZeroHours.length > 0) {
            //console.log('CHECK NOT OK');
            return false;
          }
        }
      }
    }

    //console.log('CHECK OK');
    return true;
  };

  // eslint-disable-next-line react/prop-types
  LetterCodeFormatter = ({ value, dependentValues }) => {
    if (this.isReservedLetterCode(value)) {
      const allowed = this.checkIfLetterCodeAllowed(dependentValues);
      if (!allowed) {
        return <div className="N-letter-code">{value.toUpperCase()}</div>;
      }
      let classname;
      switch (value) {
        case 'D':
        case 'd':
          classname = 'D-letter-code';
          break;
        case 'B':
        case 'b':
          classname = 'B-letter-code';
          break;
        case 'C':
        case 'c':
          classname = 'C-letter-code';
          break;
        case 'P':
        case 'p':
          classname = 'P-letter-code';
          break;
        default:
          classname = '';
          break;
      }
      // eslint-disable-next-line react/prop-types
      return <div className={classname}>{value.toUpperCase()}</div>;
    }
    return value;
  };

  // UPDATE_TIMEOUT = null;
  // startUpdateTimeout() {
  //   this.UPDATE_TIMEOUT = setTimeout(() => {
  //     const doUpdate = workhoursApi.shouldUpdateTable();
  //     if (doUpdate) {
  //       console.log('DELAM UPDATE');
  //       this.props.onChange();
  //     } else {
  //       console.log('NE DELAM UPDATEA');
  //     }
  //   }, 5000);
  // }

  async lockdown(lock) {
    const body = {
      lockId: this.props.employee.value,
      lockDate: `${this.props.date.month}/${this.props.date.year}`,
      lockType: 'worker',
    };

    const title = lock ? 'ZAKLEPANJE' : 'ODKLEPANJE';

    let success = false;
    if (lock) success = await lockdownsApi.lock(body);
    else success = await lockdownsApi.unlock(body);
    if (success) {
      this.setState({ updating: true });
      setTimeout(() => {
        LOCKED = lock;
        this.generateHeads();
        this.generateHeads_Transfer();
        this.setState({ updating: false });
        const message = lock ? 'List je uspešno zaklenjen!' : 'List je uspešno odklenjen!';
        this.showNotif({ title, message, success });
      }, 250);
    } else {
      const message = lock
        ? 'List NI uspešno zaklenjen!'
        : 'List NI uspešno odklenjen (Odklene ga lahko samo uporabnik, ki ga je zaklenil!)';
      this.showNotif({ title, message, success });
    }
    // console.log('poslal bi lock:', body);
  }

  handleAddDay(value) {
    // console.log('handleAddDay', event);
    this.setState({ updating: true });
    setTimeout(() => {
      this.generateRows([value]);
      this.setState({ updating: false });
    }, 250);
  }

  addTranferRow() {
    this.setState({ updating_Transfer: true });
    setTimeout(() => {
      this.generateRows_Transfer();
      this.setState({ updating_Transfer: false });
    }, 250);
  }

  async postHoursAndUpdate(noUpdate = false) {
    this.setState({ postingHours: true });
    await workhoursApi.postHours();
    this.props.dispatch(setRouterBlock(false));
    this.props.dispatch(setRouterBlockNotif(null));
    this.setState({ unsaved: false, postingHours: false });

    const { employee, date } = this.props;
    this.props.onHoursPosted({
      success: true,
      message: `Ure za ${employee.label} - ${date.month} ${date.year} so USPEŠNO shranjene!`,
    });
    if (noUpdate) {
      console.log('DELAM UPDATE');
      if (this.state.wasClash) {
        this.setState({ wasClash: false, forceUpdate: true });
      }
      this.props.onChange();
    }
  }

  generateHeads_Transfer() {
    const { worksites, orderers } = this.props;
    const worksitesWithInternal = [{ value: 'Interno', id: 'internal' }, ...worksites];
    const orderersWithInternal = [{ value: 'Interno', id: 'internal' }, ...orderers];
    if (!worksites) return;
    const heads_Transfer = [
      {
        key: 'num',
        name: '#',
        width: 50,
      },
      {
        key: 'hours',
        name: 'Ure',
        editable: !LOCKED,
        width: 100,
      },
      {
        key: 'nightHours',
        name: 'Nočne Ure',
        editable: !LOCKED,
        width: 100,
      },
      {
        key: 'worksite',
        name: 'Gradbišče',
        editor: !LOCKED ? WorksitesEditor(worksitesWithInternal) : null,
      },
      {
        key: 'orderer',
        name: 'Naročnik',
        editor: !LOCKED ? WorksitesEditor(orderersWithInternal) : null,
      },
      {
        key: 'notes',
        name: 'Opombe',
        editable: !LOCKED,
      },
    ];
    this.setState({ heads_Transfer });
    this.generateRows_Transfer();
  }

  generateRows_Transfer() {
    const {
      date: { month, year },
      worksites,
      // workdays,
      hours,
    } = this.props;
    let totalTransferDaySum = 0;
    let totalTransferNightSum = 0;
    if (!hours.workdays) return;
    if (!worksites) return;
    const { workdays } = hours;
    // console.log('generateRows_Transfer dobil workdays', workdays);
    const transfers = getTransfers(workdays, new Date(year, month - 1, 1));
    // console.log('generateRows_Transfer getTransfers sparsal transferje', transfers);
    const rows_Transfer = transfers.map((workday, index) => {
      const dailyHours =
        workday.workhours && workday.workhours.dayHours ? `${commify(workday.workhours.dayHours)}` : '';
      const nightlyHours =
        workday.workhours && workday.workhours.nightHours ? `${commify(workday.workhours.nightHours)}` : '';

      totalTransferDaySum += workday.workhours && workday.workhours.dayHours ? workday.workhours.dayHours : 0;
      totalTransferNightSum += workday.workhours && workday.workhours.nightHours ? workday.workhours.nightHours : 0;

      const theDayNumeric =
        workday.day.indexOf('(') > -1 ? workday.day.substring(0, workday.day.indexOf('(')) : workday.day;
      const theKey = String(`${moment(new Date(Number(theDayNumeric))).unix()}(T${index + 1})`);
      console.log('generateRows_Transfer THEKEY', theKey);
      return {
        key: theKey,
        num: `${index + 1}`,
        hours: dailyHours,
        nightHours: nightlyHours,
        orderer: workday && workday.orderer ? workday.orderer.title : 'Interno',
        worksite:
          workday && workday.worksite && workday.worksite.address
            ? `${workday.worksite.title} - ${workday.worksite.address}`
            : 'Interno',
        notes: workday ? workday.notes : '',
        width: 45,
      };
    });
    console.log('rows_Transfer.length', rows_Transfer.length);
    this.setState({
      rows_Transfer,
      totalTransferDaySum,
      totalTransferNightSum /* indexMap */ /* updating_Transfer: true */,
    });
    /* setTimeout(() => {
      this.setState({ updating_Transfer: false });
    }, 250); */
  }

  generateHeads() {
    const { worksites, orderers } = this.props;
    const worksitesWithInternal = [{ value: 'Interno', id: 'internal' }, ...worksites];
    const orderersWithInternal = [{ value: 'Interno', id: 'internal' }, ...orderers];
    if (!worksites) return;
    const heads = [
      {
        key: 'company',
        name: 'Firma',
        width: 160,
      },
      {
        key: 'date',
        name: 'Datum',
        width: 110,
      },
      {
        key: 'hours',
        name: 'Ure',
        getRowMetaData: row => row,
        formatter: this.LetterCodeFormatter,
        editable: !LOCKED,
        width: 100,
      },
      /* {
        key: 'dayDiff',
        name: 'Neskladje (Dnevni)',
        editable: false,
        width: 80,
      }, */
      {
        key: 'nightHours',
        name: 'Nočne Ure',
        editable: !LOCKED,
        width: 100,
      },
      /* {
        key: 'nightDiff',
        name: 'Neskladje  (Nočni)',
        editsble: false,
        width: 80,
      }, */
      {
        key: 'worksite',
        name: 'Gradbišče',
        editor: !LOCKED ? WorksitesEditor(worksitesWithInternal) : null,
      },
      /* {
        key: 'vehicle',
        name: 'Prevoz',
      },
      {
        key: 'accommodation',
        name: 'Prenočitev',
      }, */
      {
        key: 'orderer',
        name: 'Naročnik',
        editor: !LOCKED ? WorksitesEditor(orderersWithInternal) : null,
      },
      {
        key: 'notes',
        name: 'Opombe',
        editable: !LOCKED,
      },
    ];
    this.setState({ heads });
    this.generateRows();
  }

  generateRows(additionalDays = []) {
    const {
      date: { month, year },
      worksites,
      // workdays,
      hours,
    } = this.props;
    // console.log('generating rows');
    if (!hours.workdays) return;
    if (!worksites) return;
    const { workdays } = hours;
    console.log('TCL: generateRows -> workdays', workdays);

    // console.log('hours rerender', hours);
    const daysInMonths = getDaysInMonth(month, year, parseAdditionalDaysList(workdays, additionalDays));
    // console.log('daysInMonths.length', daysInMonths.length);
    let lastMappedDay = moment(new Date(Date.now()))
      .subtract(500, 'years')
      .toDate();
    let repeatCounter = 0;
    let totalDaySum = 0;
    let totalNightSum = 0;
    const rows = daysInMonths.map((day, index) => {
      const isDayRepeat = day.getTime() === lastMappedDay.getTime();
      if (isDayRepeat) {
        repeatCounter += 1;
      } else {
        repeatCounter = 0;
      }
      lastMappedDay = day;

      const theKey = isDayRepeat ? String(`${moment(day).unix()}(${repeatCounter})`) : String(`${moment(day).unix()}`);
      const searchString = isDayRepeat
        ? String(`${moment(day).unix()}000(${repeatCounter})`)
        : String(`${moment(day).unix()}000`);

      const workday = workdays.find(searchDay => searchDay.day.toString() === searchString);
      // console.log('workday', workday);
      let dayDiff = '';
      let nightDiff = '';
      let dailyHours = '';
      let nightlyHours = '';
      let theCompany = '';

      // console.log('TCL: generateRows -> searchString', searchString);
      if (workday) {
        dayDiff =
          workday.workhours && workday.workhours.worksiteDayDiff ? (
            <Badge color={workday.workhours.worksiteDayDiff > 0 ? 'success' : 'danger'}>
              {workday.workhours.worksiteDayDiff > 0
                ? `+${commify(fix(workday.workhours.worksiteDayDiff))}`
                : commify(fix(workday.workhours.worksiteDayDiff))}
            </Badge>
          ) : (
            ''
          );
        nightDiff =
          workday.workhours && workday.workhours.worksiteNightDiff ? (
            <Badge color={workday.workhours.worksiteNightDiff > 0 ? 'success' : 'danger'}>
              {workday.workhours.worksiteNightDiff > 0
                ? `+${commify(fix(workday.workhours.worksiteNightDiff))}`
                : commify(fix(workday.workhours.worksiteNightDiff))}
            </Badge>
          ) : (
            ''
          );

        let workdayDayHours =
          workday.workhours && workday.workhours.dayHours ? `${commify(workday.workhours.dayHours)}` : '';
        workdayDayHours = workdayDayHours === '' && workday.workhours.code ? workday.workhours.code : workdayDayHours;
        dailyHours = workdayDayHours;
        nightlyHours =
          workday.workhours && workday.workhours.nightHours ? `${commify(workday.workhours.nightHours)}` : '';

        // if (workday.workhours && workday.workhours.dayHours) {
        // eslint-disable-next-line
        //if (workday.workhours.dayHours !== 1) {
        // console.log('totalDaySum', totalDaySum, 'dayHours', workday.workhours.dayHours, 'day', day.getDate());
        // }
        // }
        totalDaySum += workday.workhours && workday.workhours.dayHours ? workday.workhours.dayHours : 0;
        totalNightSum += workday.workhours && workday.workhours.nightHours ? workday.workhours.nightHours : 0;
        // if (workday.workhours && workday.workhours.dayHours) {
        //   console.log('totalDaySum', totalDaySum);
        // }

        theCompany = workday.company ? (
          <Badge
            style={{
              backgroundColor: `${workday.company.color}`,
              color: '#646777',
            }}
          >
            {workday.company.name}
          </Badge>
        ) : (
          ''
        );
      } else {
        console.log('NI WORKDAYA:::::::');
      }

      const addRowButton = (
        <Button
          size="sm"
          outline
          style={{ padding: '3px', marginLeft: '10px' }}
          onClick={() => this.handleAddDay(day.getDate())}
        >
          {`${day.getDate()}. ${month}. ${year}`}
        </Button>
      );

      return {
        key: theKey,
        company: theCompany,
        date: addRowButton,
        hours: dailyHours,
        dayDiff,
        nightHours: nightlyHours,
        nightDiff,
        // vehicle: workday && workday.vehicleData ? workday.vehicleData.licensePlate : '',
        // accommodation: workday && workday.accommodationData ? workday.accommodationData.title : '',
        orderer: workday && workday.orderer ? workday.orderer.title : 'Interno',
        worksite:
          workday && workday.worksite && workday.worksite.address
            ? `${workday.worksite.title} - ${workday.worksite.address}`
            : 'Interno',
        notes: workday ? workday.notes : '',
        width: 45,
      };
    });
    console.log('rows.length', rows.length);
    /* const indexMap = rows.map((row, index) => ({
      day: row.key,
      row: index,
    })); */
    this.setState({ rows, totalDaySum, totalNightSum /* indexMap */ });
  }

  // addRow() {}

  render() {
    const { routeTo } = this.state;

    if (routeTo) {
      return <Redirect to={routeTo} />;
    }

    const { employee, worksites, date } = this.props;
    if (!employee || !worksites) {
      return '';
    }
    const {
      heads,
      rows,
      updating,
      heads_Transfer,
      rows_Transfer,
      updating_Transfer,
      unsaved,
      postingHours,
    } = this.state;
    if (!heads.length || !rows.length) {
      return '';
    }
    const btnMessage = postingHours ? (
      <p>
        OBDELJUEM URE
        <ReactLoading type="spin" color="cyan" height={16} width={16} className="react-loader" />
      </p>
    ) : (
      'POTRDI VNEŠENE URE'
    );

    return (
      <Container>
        <Card className="delavci-table">
          <CardHeader>
            <strong>{`${employee.label} - ${date.month} ${date.year}`}</strong>
            {LOCKED && <strong style={{ color: 'red' }}>&nbsp; ZAKLENJENO!</strong>}
            {unsaved && (
              <Button
                className={postingHours ? 'icon icon--right' : ''}
                color={!postingHours ? 'danger' : 'warning'}
                size="sm"
                style={{ marginLeft: '20px' }}
                onClick={() => this.postHoursAndUpdate(true)}
              >
                {btnMessage}
              </Button>
            )}
            {/* <AddDay onSubmit={this.handleAddDay} days={generateDaysList(date.month, date.year)} /> */}
          </CardHeader>
          <CardBody>
            {!updating && (
              <EditableTable rows={rows} heads={heads} onUpdate={this.onTableUpdate} rowRenderer={RowRenderer} />
            )}
            <h2>Skupaj dnevne: {commify(fix(this.state.totalDaySum))}</h2>
            <h2>Skupaj nočne: {commify(fix(this.state.totalNightSum))}</h2>
            <h2>Skupaj: {commify(fix(this.state.totalDaySum + this.state.totalNightSum))}</h2>
          </CardBody>
        </Card>
        <Card className="delavci-table">
          <CardHeader>
            {'PRENOSI IZ PREJŠNJEGA MESECA'}
            {/* <AddDay onSubmit={this.handleAddDay} days={generateDaysList(date.month, date.year)} /> */}
            <Button size="sm" color="primary" style={{ marginLeft: '20px' }} onClick={this.addTranferRow}>
              DODAJ
            </Button>
            {unsaved && (
              <Button
                color={unsaved ? 'danger' : 'primary'}
                size="sm"
                style={{ marginLeft: '20px' }}
                onClick={() => this.postHoursAndUpdate(true)}
              >
                {unsaved ? 'POTRDI VNEŠENE URE' : 'POSODOBI'}
              </Button>
            )}
          </CardHeader>
          <CardBody>
            {!updating_Transfer && (
              <EditableTable
                rows={rows_Transfer}
                heads={heads_Transfer}
                onUpdate={this.onTableUpdate_Transfer}
                rowRenderer={RowRenderer_Transfer}
              />
            )}
            <h2>Skupaj dnevne: {commify(fix(this.state.totalTransferDaySum))}</h2>
            <h2>Skupaj nočne: {commify(fix(this.state.totalTransferNightSum))}</h2>
            <h2>Skupaj: {commify(fix(this.state.totalTransferDaySum + this.state.totalTransferNightSum))}</h2>
          </CardBody>
        </Card>
        <Card>
          <h2 style={{ marginBottom: '20px' }}>
            Skupaj vse:{' '}
            {commify(
              fix(
                this.state.totalDaySum +
                  this.state.totalNightSum +
                  this.state.totalTransferDaySum +
                  this.state.totalTransferNightSum,
              ),
            )}
          </h2>
          {!LOCKED ? (
            <Modal
              color="danger"
              title="Pozor!"
              colored
              btn="ZAKLENI"
              message="Ste prepričani da želite ZAKLENITI ta list?"
              outline={false}
              onConfirm={() => this.lockdown(true)}
            />
          ) : (
            <Modal
              color="primary"
              title="Pozor!"
              colored
              btn="ODKLENI"
              message="Ste prepričani da želite ODKLENITI ta list?"
              outline={false}
              onConfirm={() => this.lockdown(false)}
            />
          )}
        </Card>
      </Container>
    );
  }
}

Table.propTypes = {
  dispatch: PropTypes.func.isRequired,
  employee: PropTypes.shape().isRequired,
  worksites: PropTypes.arrayOf(PropTypes.object).isRequired,
  orderers: PropTypes.arrayOf(PropTypes.object).isRequired,
  date: PropTypes.shape({
    month: PropTypes.number,
    year: PropTypes.number,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  onHoursPosted: PropTypes.func.isRequired,
  hours: HoursProps.isRequired,
  appStatus: AppStatusProps.isRequired,
};

const mapStateToProps = state => ({
  hours: state.hours,
  appStatus: state.appStatus,
});

export default connect(mapStateToProps)(Table);
