import React, { Component } from 'react';
import PropTypes from 'prop-types';
import shortid from 'shortid';
import { Card, CardBody, Badge, Table, Button, UncontrolledTooltip } from 'reactstrap';
import employeesApi from 'api/employees';
import { translate } from 'react-i18next';
import { EyeOffIcon, EyeIcon } from 'mdi-react';

import vehiclesApi from 'api/vehicles';
import { translateDocName } from '../../../translations/dynamicTranslator';
import getRoles from '../../../constants/roles';
import EmployeesFormModal from '../../Boards/components/Modals/EmployeesFormModal';
import VozilaModal from '../../Vozila/components/VozilaModal';

class DocumentsExpTable extends Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
    docs: PropTypes.arrayOf(PropTypes.object).isRequired,
    onDocVisibilityChange: PropTypes.func.isRequired,
    onEmployeeEdited: PropTypes.func.isRequired,
    //onVehicleEdited: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      employeesFormModalShown: false,
      vehiclesFormModalShown: false,

      employeeSelectedTab: '1',
      vehicleSelectedTab: '1',
      ROLES: getRoles(),
    };
  }

  renderTable = (docs, onDocVisibilityChange, t) => {
    const { ROLES } = this.state;
    return docs.map(doc => {
      const bgColor = doc.worker ? `${doc.worker.company.color}` : 'honeydew';
      const docButtonId = doc.worker ? `doc${doc.worker._id}${doc._id}` : `doc${doc.vehicle._id}${doc._id}`;
      const docId = doc.worker ? doc.worker._id : doc.vehicle._id;
      const name = doc.worker ? doc.worker.name : doc.vehicle.name;
      return (
        <tr key={doc._id + name} id={doc._id}>
          <td>
            <Badge
              style={{
                backgroundColor: bgColor,
                color: doc.worker ? '#646777' : 'black',
                cursor: ROLES.full ? 'pointer' : 'default',
              }}
              id={`badge${docId}${doc._id}`}
              title={t('board.ow.popover.editWorker')}
              onClick={() => {
                if (ROLES.full && doc.worker) this.showEmployeesFormModal(`3`, doc.worker._id);
                if (ROLES.full && doc.vehicle) this.showVehiclesFormModal(`2`, doc.vehicle._id);
              }}
            >
              {name}
            </Badge>
            {ROLES.full && doc.worker && (
              <UncontrolledTooltip placement="top" target={`badge${docId}${doc._id}`}>
                {t('board.ow.popover.editWorker')}
              </UncontrolledTooltip>
            )}
            {ROLES.full && doc.vehicle && (
              <UncontrolledTooltip placement="top" target={`badge${docId}${doc._id}`}>
                Uredi vozilo
              </UncontrolledTooltip>
            )}
          </td>
          <td>{doc.type === 'employee' ? 'Delavec' : 'Vozilo'}</td>
          <td>{translateDocName(doc.doc.name, localStorage.getItem('lang') || 'sl')}</td>
          <td>{doc.doc.expAt}</td>
          <td>
            {doc.diff <= 0 ? (
              <Badge style={{ backgroundColor: '#ff353d', color: 'white' }}>{doc.diff}</Badge>
            ) : (
              <Badge style={{ backgroundColor: '#f6da6e', color: 'white' }}>{doc.diff}</Badge>
            )}
          </td>
          <td>
            <Button
              id={docButtonId}
              size="sm"
              className="doc-exp-eye-btn"
              onClick={() => onDocVisibilityChange(doc._id, doc.hidden, docId, doc.type)}
            >
              {doc.hidden ? <EyeIcon /> : <EyeOffIcon />}
            </Button>
            <UncontrolledTooltip placement="top" target={`doc${docId}${doc._id}`}>
              {doc.hidden ? t('docsexp.show') : t('docsexp.noShow')}
            </UncontrolledTooltip>
          </td>
        </tr>
      );
    });
  };

  getEditableEmployee = async employeeId => {
    const data = await employeesApi.getOne(JSON.stringify({ id: employeeId }));
    return data;
  };

  showEmployeesFormModal = async (tabNum, employeeId) => {
    const data = await this.getEditableEmployee(employeeId);
    this.setState({
      employeesFormModalShown: true,
      employeeSelectedId: employeeId,
      editableEmployee: data,
      employeeSelectedTab: tabNum,
    });
  };

  hideEmployeesFormModal = () => {
    this.setState({ employeesFormModalShown: false });
    this.props.onEmployeeEdited();
  };

  getEditableVehicle = async vehicleId => {
    const data = await vehiclesApi.getOneById(vehicleId);
    return data;
  };

  showVehiclesFormModal = async (tabNum, vehicleId) => {
    const data = await this.getEditableVehicle(vehicleId);
    this.setState({
      vehiclesFormModalShown: true,
      vehicleSelectedId: vehicleId,
      editableVehicle: data,
      vehicleSelectedTab: tabNum,
    });
  };

  hideVehiclesFormModal = () => {
    this.setState({ vehiclesFormModalShown: false });
    this.props.onEmployeeEdited();
  };

  render() {
    const { title, docs, t, onDocVisibilityChange } = this.props;
    const {
      employeesFormModalShown,
      editableEmployee,
      employeeSelectedTab,
      vehiclesFormModalShown,
      editableVehicle,
      vehicleSelectedTab,
    } = this.state;

    return (
      <Card>
        <CardBody>
          <div className="card__title">
            <h5 className="bold-text">{title}</h5>
            <h5 className="subhead">#{docs.length}</h5>
          </div>
          <Table className="table--bordered" responsive>
            <thead>
              <tr>
                <th>{`${t('board.filters.worker')} / ${t('board.filters.vehicle')}`}</th>
                <th>{t('vehicles.type')}</th>
                <th>{t('misc.doc')}</th>
                <th>{t('sidebar_content.docs.expiry')}</th>
                <th>{t('misc.expiresIn')}</th>
                <th></th>
              </tr>
            </thead>
            <tbody>{this.renderTable(docs, onDocVisibilityChange, t)}</tbody>
          </Table>

          {employeesFormModalShown && (
            <EmployeesFormModal
              title={t('board.ow.popover.editWorker')}
              icon="edit"
              show={employeesFormModalShown}
              onClose={this.hideEmployeesFormModal}
              editData={editableEmployee}
              onDocumentRemoved={() => null}
              initialTab={employeeSelectedTab}
            />
          )}
          {vehiclesFormModalShown && (
            <VozilaModal
              btn="Uredi vozilo"
              icon="edit"
              title="Uredi vozilo"
              hasBtn={false}
              show={vehiclesFormModalShown}
              onClose={this.hideVehiclesFormModal}
              editData={editableVehicle}
              initialTab={vehicleSelectedTab}
              people={[]}
            />
          )}
        </CardBody>
      </Card>
    );
  }
}

export default translate('common')(DocumentsExpTable);
