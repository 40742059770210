export const SET_ACCOMMODATIONS = 'SET_ACCOMMODATIONS';
export const SET_ACCOMMODATIONS_TITLES_LIST = 'SET_ACCOMMODATIONS_TITLES_LIST';
export const SET_ACCOMMODATION_PROVIDERS_TITLES_LIST = 'SET_ACCOMMODATION_PROVIDERS_TITLES_LIST';
export const SET_ACCOMMODATION_PROVIDERS = 'SET_ACCOMMODATION_PROVIDERS';

export function setAccommodations(accommodations) {
  return {
    type: SET_ACCOMMODATIONS,
    accommodations,
  };
}

export function setAccommodationProviders(providers) {
  return {
    type: SET_ACCOMMODATION_PROVIDERS,
    providers,
  };
}

export function setAccommodationsTitlesList(accommodationTitles) {
  return {
    type: SET_ACCOMMODATIONS_TITLES_LIST,
    accommodationTitles,
  };
}

export function setAccommodationProvidersTitlesList(providerTitles) {
  return {
    type: SET_ACCOMMODATION_PROVIDERS_TITLES_LIST,
    providerTitles,
  };
}
