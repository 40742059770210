import React, { Component } from 'react';
import { Container, Row, Col, Card, CardBody } from 'reactstrap';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';
import AddonsTable from './components/Addons/AddonsTable';
import Tabs from './components/Tabs';
import SatniceTable from './components/Satnice/SatniceTable';
import CompensationTable from './components/Compensation/CompansationTable';

const defaults = {
  tab: '1',
};

class WagesIndex extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      currentTab: defaults.tab,
    };
  }

  handleTabChange = tab => this.setState({ currentTab: tab });

  render() {
    const { currentTab } = this.state;
    const { t } = this.props;
    return (
      <Container className="dashboard">
        <Row>
          <Col md={12}>
            <h3 className="page-title">Urne postavke</h3>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <Card>
              <CardBody>
                <Tabs defaultTab={defaults.tab} onTabChange={this.handleTabChange} />
                {currentTab === '1' && <SatniceTable />}
                {currentTab === '2' && <AddonsTable />}
                {/*  {currentTab === '3' && <CompensationTable />} */}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default translate('common')(WagesIndex);
