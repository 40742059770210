import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Select from '../../../shared/components/form/Select';

export default class PlaceholderField extends Component {
  static propTypes = {
    options: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired,
    name: PropTypes.string,
    value: PropTypes.object.isRequired,
  };

  static defaultProps = {
    name: '',
  };

  constructor(props) {
    super(props);

    this.state = {
      touched: false,
      error: null,
    };
  }

  render() {
    const { touched, error } = this.state;
    const { onChange, options, name, value } = this.props;
    return (
      <React.Fragment>
        <Select options={options} meta={{ touched, error }} input={{ onChange, name, value }} />
      </React.Fragment>
    );
  }
}
