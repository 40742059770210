import React from 'react';
import PropTypes from 'prop-types';
import { Button, Modal } from 'reactstrap';
import ReactGA from 'react-ga';
import classNames from 'classnames';
import AddIcon from 'mdi-react/AccountMultiplePlusOutlineIcon';
import EditIcon from 'mdi-react/AccountEditIcon';
import { translate } from 'react-i18next';
import PrenocitveForm from './PrenocitveForm';
import { API_LINK } from '../../../constants/config';
import { POST } from '../../../helpers/agent';

const API_ADD_ACCOMMODATION = '/acm/add';
const API_EDIT_ACCOMMODATION = '/acm/update';
const API_DELETE_ACCOMMODATION = '/acm/delete';

class PrenocitveModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
    };

    this.toggle = this.toggle.bind(this);
    this.cancel = this.cancel.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleESC = this.handleESC.bind(this);
  }

  componentDidMount() {
    document.addEventListener('keydown', this.handleESC, false);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleESC, false);
  }

  handleESC(event) {
    if (event.keyCode === 27) {
      this.setState({ modal: false });
      this.cancel(null);
    }
  }

  toggle() {
    if (!this.state.modal) {
      ReactGA.event({
        category: 'Prenočitve',
        action: `Odprtje modala za Prenočitve - ${this.props.editData ? 'Urejanje' : 'Dodajanje'}`,
      });
    }
    this.setState({
      modal: !this.state.modal,
    });
  }

  cancel(data) {
    this.setState({
      modal: false,
    });
    this.props.onClose(data);
  }

  async handleDelete(data) {
    // const that = this;
    try {
      const { t } = this.props;
      const successMessage = `${t('messages.accommodation.delete.success')}`;
      const errorMessage = `${t('messages.accommodation.delete.error')}`;
      const dataRes = await POST(API_LINK + API_DELETE_ACCOMMODATION, JSON.stringify({ _id: data._id }));
      // console.log('zdaj bi izbrisal', data);
      if (dataRes.success) {
        // console.log('Request success: ', dataRes);
        this.cancel({
          title: t('messages.accommodation.delete.title'),
          message: successMessage,
          success: true,
        });
      } else {
        this.cancel({
          title: t('messages.accommodation.delete.title'),
          message: errorMessage,
          success: false,
        });
      }
    } catch (error) {
      // console.log(error);
      this.cancel(null);
    }
  }

  async handleSubmit(values) {
    // event.preventDefault();
    const { icon, t } = this.props;
    const isEdit = icon !== 'add';
    const apiLink = isEdit ? API_EDIT_ACCOMMODATION : API_ADD_ACCOMMODATION;
    try {
      // console.log(`${icon} prenočišče`, values);
      const body = JSON.parse(JSON.stringify(values));
      body.countryId = values.countryId ? values.countryId.value : '';
      body.providerId = values.providerId ? values.providerId.value : '';
      const notifTitle = isEdit ? t('messages.accommodation.edit.title') : t('messages.accommodation.add.title');
      const successMessage = isEdit
        ? t('messages.accommodation.edit.success')
        : t('messages.accommodation.add.success');
      const errorMessage = isEdit ? t('messages.accommodation.edit.error') : t('messages.accommodation.add.error');

      const data = await POST(API_LINK + apiLink, JSON.stringify(body));
      if (data.success) {
        // console.log('Request success: ', data);
        this.cancel({
          title: notifTitle,
          message: successMessage,
          success: true,
        });
      } else {
        // console.log('Request success: ', data);
        // this.showNotif('error', isEdit, values.name, false);
        this.cancel({
          title: notifTitle,
          message: errorMessage,
          success: false,
        });
      }
      // console.log('POŠILJAM BODY:', body);
    } catch (error) {
      // console.log(error);
      this.cancel(null);
    }
  }

  render() {
    const { icon, btn, title, colored, header, hasBtn, show, editData, countries, providers } = this.props;
    let Icon;

    switch (icon) {
      case 'add':
        Icon = <AddIcon />;
        break;
      case 'edit':
        Icon = <EditIcon />;
        break;
      default:
        break;
    }
    const modalClass = classNames({
      'modal-dialog--colored': colored,
      'modal-dialog--header': header,
    });

    return (
      <div>
        {hasBtn ? (
          <Button size="sm" color="primary" onClick={this.toggle}>
            {btn}
          </Button>
        ) : null}

        <Modal isOpen={this.state.modal || show} toggle={this.toggle} className={`modal-dialog--primary ${modalClass}`}>
          <div className="modal__header">
            <button className="lnr lnr-cross modal__close-btn" onClick={this.toggle} />
            {header ? '' : Icon}
            <h4 className="bold-text  modal__title">{title}</h4>
          </div>
          <div className="modal__body">
            <div className="theme-light">
              {editData ? (
                <PrenocitveForm
                  onSubmit={this.handleSubmit}
                  countries={countries}
                  providers={providers}
                  handleCancel={() => this.cancel(null)}
                  handleDelete={this.handleDelete}
                  initialValues={editData}
                  editData={editData}
                />
              ) : (
                <PrenocitveForm
                  onSubmit={this.handleSubmit}
                  countries={countries}
                  providers={providers}
                  handleCancel={() => this.cancel(null)}
                  handleDelete={this.handleDelete}
                />
              )}
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

PrenocitveModal.propTypes = {
  title: PropTypes.string,
  countries: PropTypes.arrayOf(PropTypes.object).isRequired,
  providers: PropTypes.arrayOf(PropTypes.object).isRequired,
  icon: PropTypes.string.isRequired,
  colored: PropTypes.bool,
  header: PropTypes.bool,
  btn: PropTypes.string.isRequired,
  hasBtn: PropTypes.bool.isRequired,
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  editData: PropTypes.object,
  t: PropTypes.func.isRequired,
};

PrenocitveModal.defaultProps = {
  title: '',
  colored: false,
  header: false,
  editData: null,
};

export default translate('common')(PrenocitveModal);
