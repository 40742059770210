import React from 'react';
import { Card, CardBody, Col } from 'reactstrap';
import CashMultipleIcon from 'mdi-react/CashMultipleIcon';
import { PropTypes } from 'prop-types';
import { translate } from 'react-i18next';
import fix from '../../../../../helpers/floatParser';

const BalanceCard = ({ balance, title, unconfirmedBalance, offset, t }) => {
  const color = balance >= 0 ? 'blue' : 'red';
  return (
    <Col md={12} xl={{ size: 2, offset }} lg={6} xs={12}>
      <Card>
        <CardBody className="dashboard__booking-card">
          <div className="dashboard__booking-total-container">
            <h5 className={`dashboard__booking-total-title dashboard__booking-total-title--${color}`}>
              {fix(balance)}€
            </h5>
            <CashMultipleIcon className="dashboard__trend-icon" />
          </div>
          <h5 className="dashboard__booking-total-description">{title}</h5>
          <br />
          <h5 className="dashboard__booking-total-description">
            {t('board.ow.unconfirmed')}: <span style={{ color: 'crimson' }}>{fix(unconfirmedBalance)}€</span>
          </h5>
        </CardBody>
      </Card>
    </Col>
  );
};

BalanceCard.propTypes = {
  balance: PropTypes.number.isRequired,
  unconfirmedBalance: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  offset: PropTypes.number,
  t: PropTypes.func.isRequired,
};

BalanceCard.defaultProps = {
  offset: 0,
};

export default translate('common')(BalanceCard);
