const validate = values => {
  const errors = {};
  if (!values.workerId) {
    errors.workerId = 'To polje mora biti izpolnjeno!';
  }

  return errors;
};

export default validate;
