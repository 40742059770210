import React from 'react';
import PropTypes from 'prop-types';
import shortid from 'shortid';
import { Card, CardBody, Col, ButtonToolbar, Button, Nav, NavItem, NavLink } from 'reactstrap';
import classnames from 'classnames';
import { Field, reduxForm } from 'redux-form';
import CalendarBlankIcon from 'mdi-react/CalendarBlankIcon';
import renderDatePickerField from '../../../../shared/components/form/DatePicker';
import renderMultiSelectField from '../../../../shared/components/form/MultiSelect';
import validate from './validate';

const DNONE = { display: 'none' };
const SHOW = {};

class DopustiForm extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: '1',
      showAssign: true,
      showDeassign: false,
    };

    this.toggle = this.toggle.bind(this);
    this.renderTabsField = this.renderTabsField.bind(this);
  }

  toggle = tab => {
    if (this.state.activeTab !== tab) {
      switch (tab) {
        case '2':
          this.setState({
            activeTab: tab,
            showAssign: false,
            showDeassign: true,
          });
          break;

        default:
          this.setState({
            activeTab: tab,
            showAssign: true,
            showDeassign: false,
          });
          break;
      }
    }
  };

  renderTabsField(activeTab) {
    return (
      <div className="tabs tabs--justify tabs--bordered-bottom" style={{ marginBottom: '40px' }}>
        <div className="tabs__wrap">
          <Nav tabs>
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === '1' })}
                onClick={() => {
                  this.toggle('1');
                }}
              >
                DODELI DOPUST
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === '2' })}
                onClick={() => {
                  this.toggle('2');
                }}
              >
                ZAKLJUČI DOPUST
              </NavLink>
            </NavItem>
          </Nav>
        </div>
      </div>
    );
  }

  render() {
    const { handleSubmit, handleCancel, workers, editData, pristine } = this.props;

    const { activeTab, showAssign, showDeassign } = this.state;

    return (
      <Col md={12} lg={12}>
        <Card>
          <CardBody>
            {this.renderTabsField(activeTab)}
            <form className="form" onSubmit={handleSubmit}>
              <div className="form__half" style={showAssign ? SHOW : DNONE}>
                <div className="form__form-group">
                  <span className="form__form-group-label">Datum začetka dopusta</span>
                  <div className="form__form-group-field">
                    <Field name="startsAt" component={renderDatePickerField} />
                    <div className="form__form-group-icon">
                      <CalendarBlankIcon />
                    </div>
                  </div>
                </div>
              </div>
              <div className="form__half" style={showAssign ? SHOW : DNONE}>
                <div className="form__form-group">
                  <span className="form__form-group-label">Datum konca dopusta</span>
                  <div className="form__form-group-field">
                    <Field name="endsAt" component={renderDatePickerField} />
                    <div className="form__form-group-icon">
                      <CalendarBlankIcon />
                    </div>
                  </div>
                </div>
              </div>
              <div className="form__form-group leave-select" style={showAssign ? { marginTop: '50px' } : DNONE}>
                <span className="form__form-group-label">DELAVCI</span>
                <div className="form__form-group-field">
                  <Field name="workers" component={renderMultiSelectField} options={workers} />
                </div>
              </div>

              <div className="form__form-group" style={showDeassign ? SHOW : DNONE}>
                <span className="form__form-group-label">Datum konca dopusta</span>
                <div className="form__form-group-field">
                  <Field name="deEndsAt" component={renderDatePickerField} />
                  <div className="form__form-group-icon">
                    <CalendarBlankIcon />
                  </div>
                </div>
              </div>
              <div className="form__form-group leave-select" style={showDeassign ? { marginTop: '50px' } : DNONE}>
                <span className="form__form-group-label">DELAVCI</span>
                <div className="form__form-group-field">
                  <Field name="deWorkers" component={renderMultiSelectField} options={workers} />
                </div>
              </div>

              <ButtonToolbar className="form__button-toolbar" style={{ marginTop: '100px' }}>
                <Button type="button" onClick={handleCancel}>
                  Prekliči
                </Button>
                <Button color="primary" type="submit" disabled={pristine}>
                  Potrdi
                </Button>
              </ButtonToolbar>
            </form>
          </CardBody>
        </Card>
      </Col>
    );
  }
}

DopustiForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
  workers: PropTypes.arrayOf(PropTypes.object).isRequired,
  editData: PropTypes.object,
  pristine: PropTypes.bool.isRequired,
};

DopustiForm.defaultProps = {
  editData: null,
};

export default reduxForm({
  form: 'dopusti_form', // a unique identifier for this form
  validate,
})(DopustiForm);
