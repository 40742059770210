/* eslint-disable no-param-reassign */
import React, { PureComponent } from 'react';
import DatePicker /* , { registerLocale, setDefaultLocale } */ from 'react-datepicker';
import MinusIcon from 'mdi-react/MinusIcon';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
// import sl from 'date-fns/locale/sl';

class IntervalDatePickerField extends PureComponent {
  static propTypes = {
    onChange: PropTypes.func.isRequired,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({
        value: PropTypes.string,
        label: PropTypes.string,
      }),
    ]).isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      startDate: null /* moment(new Date(Date.now()))
        .startOf('day')
        .toDate() */,
      endDate: null /* moment(new Date(Date.now()))
        .startOf('day')
        .add(1, 'week')
        .toDate() */,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleStartDateFormatting = this.handleStartDateFormatting.bind(this);
    this.handleEndDateFormatting = this.handleEndDateFormatting.bind(this);
    /* registerLocale('sl', sl);
     setDefaultLocale('sl'); */
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { value } = this.props;
    if ((value.start || value.end) && nextProps.value === '') {
      this.setState({ startDate: null, endDate: null });
    }
  }

  handleChange({ startDate, endDate }) {
    startDate = startDate || this.state.startDate;
    endDate = endDate || this.state.endDate;

    if (startDate && !endDate) {
      endDate = startDate;
    }

    // console.log(startDate);
    if (startDate) {
      if (startDate > endDate) {
        endDate = startDate;
      }
    }

    this.setState({ startDate, endDate });
    this.props.onChange({ start: startDate, end: endDate });
  }

  handleChangeStart = startDate => this.handleChange({ startDate });

  handleChangeEnd = endDate => this.handleChange({ endDate });

  handleStartDateFormatting = rawValue => {
    // console.log('raw:', rawValue);
    const parsedDate = moment(rawValue, 'd.M.yyyy');
    if (parsedDate.isValid()) {
      this.setState({ startDate: parsedDate });
    }
  };

  handleEndDateFormatting = rawValue => {
    // console.log('raw:', rawValue);
    const parsedDate = moment(rawValue, 'd.M.yyyy');
    if (parsedDate.isValid()) {
      this.setState({ endDate: parsedDate });
    }
  };

  render() {
    const lang = localStorage.getItem('lang') || 'sl';
    return (
      <div className="date-picker date-picker--interval">
        <DatePicker
          selected={this.state.startDate}
          selectsStart
          startDate={this.state.startDate}
          endDate={this.state.endDate}
          onChange={this.handleChangeStart}
          onChangeRaw={this.handleStartDateFormatting}
          dateFormat="d.M.yyyy"
          placeholderText={lang === 'sl' ? 'Od' : 'Von'}
        />
        <MinusIcon className="date-picker__svg" />
        <DatePicker
          selected={this.state.endDate}
          selectsEnd
          startDate={this.state.startDate}
          endDate={this.state.endDate}
          onChange={this.handleChangeEnd}
          onChangeRaw={this.handleEndDateFormatting}
          dateFormat="d.M.yyyy"
          placeholderText={lang === 'sl' ? 'Do' : 'Bis'}
        />
      </div>
    );
  }
}

const renderIntervalDatePickerField = props => <IntervalDatePickerField {...props.input} />;

renderIntervalDatePickerField.propTypes = {
  input: PropTypes.shape({
    onChange: PropTypes.func,
  }).isRequired,
};

export default renderIntervalDatePickerField;
