import React from 'react';
import { Field, reduxForm } from 'redux-form';
// import { UncontrolledTooltip, Button } from 'reactstrap';
import PropTypes from 'prop-types';
import renderSelectField from './form/Select';
import renderSelectFieldKW from './form/SelectKW';

const SelectDropdown = ({ handleSubmit, title, items, renderKW, placeholder }) => (
  <form className="form" onSubmit={handleSubmit}>
    <div className="form__form-group">
      <span className="form__form-group-label">{title}</span>
      <div className="form__form-group-field" /* id="TooltipTop" */>
        <Field
          name="selected_dropdown"
          component={renderKW ? renderSelectFieldKW : renderSelectField}
          type="text"
          options={items}
          placeholder={placeholder}
        />
      </div>
    </div>
  </form>
);

SelectDropdown.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  title: PropTypes.string.isRequired,
  renderKW: PropTypes.bool,
  placeholder: PropTypes.string,
};

SelectDropdown.defaultProps = {
  renderKW: false,
  placeholder: '',
};

export default reduxForm({
  form: 'select_dropdown',
  onChange: (values, dispatch, props) => {
    props.submit();
  },
})(SelectDropdown);
