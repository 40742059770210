const validate = values => {
  // console.log('TCL: validate -> values', values);
  const errors = {};

  // if (!values.workers) {
  //   errors.workers = 'Izberite vsaj enega delavca!';
  //   // console.log('TCL: validate -> errors', errors);
  // }

  // if (!values.test) {
  //   errors.test = 'Izberite vsaj enega delavca!';
  //   errors.date = 'Izberite vsaj enega delavca!';
  //   errors.workers = 'Izberite vsaj enega delavca!';
  //   errors.endsAt = 'Izberite vsaj enega delavca!';
  //   errors.deWorkers = 'Izberite vsaj enega delavca!';
  //   errors.deEndsAt = 'Izberite vsaj enega delavca!';
  //   // console.log('TCL: validate -> errors', errors);
  // }

  const workersCheck = !(!values.workers || values.workers.length === 0);
  const deWorkersCheck = !(!values.deWorkers || values.deWorkers.length === 0);

  if (!workersCheck && !deWorkersCheck) {
    errors.workers = 'Izberite vsaj enega delavca!';
    errors.deWorkers = 'Izberite vsaj enega delavca!';
  }

  if (!values.startsAt && workersCheck) {
    errors.startsAt = 'To polje mora biti izpoljeno!';
  }

  if (!values.deEndsAt && deWorkersCheck) {
    errors.deEndsAt = 'To polje mora biti izpoljeno!';
  }

  return errors;
};

export default validate;
