import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Table, Badge } from 'reactstrap';

import constants from '../../../helpers/index';

const { handleFocus } = constants;

class TemplateTransactionsTable extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      templates: props.templateTransactions,
    };
  }

  tableInput = (id, value, type, index, className = 'form-control', step = 1) => {
    const colorClass = type === 'number' && value < 0 ? ' red-text' : '';
    if (type === 'number') {
      return (
        <input
          onFocus={e => handleFocus(e)}
          onChange={e => this.handleChange(index, id, e.target.value)}
          //onBlur={e => this.handleSubmit(index, id, e.target.value)}
          value={value}
          type={type}
          className={className + colorClass}
          step={step}
        />
      );
    }
    return (
      <textarea
        rows={1}
        onChange={e => this.handleChange(index, id, e.target.value)}
        onBlur={e => this.handleSubmit(index, id, e.target.value)}
        value={value}
        className={className}
      />
    );
  };

  handleChange = (index, dataType, _value) => {
    const value = _value;
    //if (!_value && dataType !== 'notes') value = 0;

    const { templates } = this.state;

    // let modifiedBy = null;
    // const token = getItem('jwt');
    // if (token) {
    //   const { uid, name, surname, username } = payload(token);
    //   modifiedBy = { uid, name, surname, username, updatedAt: TMZFY(new Date(Date.now())) };
    // }

    const newState = templates.map((item, i) => {
      if (i === index) {
        return { ...item, [dataType]: value };
      }
      return item;
    });

    this.setState({
      templates: newState,
    });
  };

  renderTemplates = () => {
    const { templates } = this.state;

    return templates.map((template, index) => {
      const { _id, purpose, type, notes, basis, payment, remaining } = template;
      const tip = type.templateType === 'finite' ? 'OBROČNO' : 'NEDOLOČEN ČAS';

      return (
        <tr key={_id}>
          <td>{index + 1}</td>
          <td>
            <strong>{purpose.toUpperCase()}</strong>
          </td>
          <td>{tip}</td>
          <td>{basis || '/'}</td>
          <td style={{ width: '100px' }}>
            {this.tableInput('payment', templates[index].payment, 'number', index, undefined, 50)}
          </td>
          <td>{remaining}</td>
          <td>{notes}</td>
        </tr>
      );
    });
  };

  render() {
    return (
      <Table hover className="table--bordered" responsive>
        <thead>
          <tr>
            <th>#</th>
            <th>Namen</th>
            <th>Način odplačevanja</th>
            <th>Glavnica (€)</th>
            <th>Obrok (€)</th>
            <th>Neplačano (€)</th>
            <th>Opombe</th>
          </tr>
        </thead>
        <tbody>{this.renderTemplates()}</tbody>
      </Table>
    );
  }
}

TemplateTransactionsTable.propTypes = {
  templateTransactions: PropTypes.arrayOf(PropTypes.object).isRequired,
};

TemplateTransactionsTable.defaultProps = {};

export default TemplateTransactionsTable;
