import React, { Component } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { hot } from 'react-hot-loader';
import ReactGA from 'react-ga';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { BrowserRouter } from 'react-router-dom';
import { I18nextProvider } from 'react-i18next';
import i18next from 'i18next';
import 'bootstrap/dist/css/bootstrap.css';
import { GA_ID } from '../../constants/config';

import '../../scss/app.scss';
import store, { persistor } from './store';
import ScrollToTop from './ScrollToTop';
import App from './App';

import { config as i18nextConfig } from '../../translations';

i18next.init(i18nextConfig);

class Root extends Component {
  constructor() {
    super();
    this.state = {};
    ReactGA.initialize(GA_ID);
  }

  render() {
    return (
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <I18nextProvider i18n={i18next}>
            <BrowserRouter>
              <ScrollToTop>
                <App />
              </ScrollToTop>
            </BrowserRouter>
          </I18nextProvider>
        </PersistGate>
      </Provider>
    );
  }
}

export default hot(module)(Root);
