import { PLACES_API_LINK } from 'constants/config';
import { GET, POST } from 'helpers/agent';

export default {
  all: async page => {
    const data = await GET(`${PLACES_API_LINK}/accommodations?page=${page}`);
    if (data) {
      return { places: data };
    }
    return { places: [] };
  },
  search: async postData => {
    const postBody = JSON.stringify(postData);
    const data = await POST(`${PLACES_API_LINK}/accommodations/search`, postBody);
    if (data) {
      return { searchedPlaces: data };
    }
    return { searchedPlaces: [] };
  },
};
