import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import NotificationSystem from 'rc-notification';
import ReactLoading from 'react-loading';
import workhoursApi from '../../../api/workhours';
import worksitesApi from '../../../api/worksites';
import FixHoursTable from './components/FixHoursTable';
import EditWorkday from './components/EditWorkday';
import { BasicNotification } from '../../../shared/components/Notification';

let notificationLU = null;
let notificationRU = null;
let notificationTC = null;

const showNotification = ({ notification, position }) => {
  switch (position) {
    case 'left-up':
      notificationLU.notice({
        content: notification,
        duration: 5,
        closable: true,
        style: { top: 0, left: 240 },
        className: position,
      });
      break;
    case 'right-up':
      notificationRU.notice({
        content: notification,
        duration: 5,
        closable: true,
        style: { top: 0, left: 'calc(100vw - 100%)' },
        className: position,
      });
      break;
    default:
      notificationTC.notice({
        content: notification,
        duration: 5,
        closable: true,
        style: { top: 0, left: 0 },
        className: position,
      });
      break;
  }
};
class PopraviUre extends Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      employee: {},
      loadingEmployee: true,
      additionalWorkday: null,
      editingWorkday: null,
      worksites: null,
    };
  }

  componentDidMount() {
    NotificationSystem.newInstance({}, n => (notificationLU = n));
    NotificationSystem.newInstance({}, n => (notificationRU = n));
    NotificationSystem.newInstance({}, n => (notificationTC = n));

    this.fetchWorkdays();
    this.fetchWorksites();
  }

  componentWillUnmount() {
    notificationLU.destroy();
    notificationRU.destroy();
    notificationTC.destroy();
  }

  onRowSelect = id => {
    const {
      employee: { workdays },
    } = this.state;
    window.scrollTo(0, 0);
    const editingWorkday = workdays.find(day => day._id === id);
    // eslint-disable-next-line max-len
    const additionalWorkday = workdays.find(
      day => moment(day.date).isSame(editingWorkday.date, 'day') && day.additionalId,
    );
    this.setState({
      editingWorkday,
      additionalWorkday,
    });
  };

  showNotif = ({ title, message, success }) => {
    const color = success ? 'success' : 'danger';
    showNotification({
      notification: <BasicNotification color={color} title={title} message={message} />,
      position: 'right-up',
    });
  };

  fetchWorkdays = async () => {
    const { token } = this.props.match.params;
    const response = await workhoursApi.getWorkdaysForEmployee(token);
    this.setState({
      employee: response.employee,
      name: response.employee.name,
      surname: response.employee.surname,
      loadingEmployee: false,
    });
  };

  fetchWorksites = async () => {
    const response = await worksitesApi.getall();
    this.setState({ worksites: response.worksites });
  };

  updateHours = async (id, data) => {
    const { token } = this.props.match.params;
    try {
      const response = await workhoursApi.updateWorkdayForEmployee(token, id, data);
      console.log('response', response);
      this.setState({
        employee: response,
      });
      this.showNotif({
        success: true,
        title: 'Posodabljanje ur',
        message: 'Uspešno ste posodobili ure',
      });
    } catch (err) {
      console.log('err', err);
      this.showNotif({
        success: false,
        title: 'Posodabljanje ur',
        message: 'Prišlo je do napake',
      });
    }
  };

  render() {
    const { employee, editingWorkday, worksites, additionalWorkday, name, surname, loadingEmployee } = this.state;
    return (
      <div className="fix-workdays">
        <Container>
          <Row>
            <Col xs={12}>
              <h3 className="page-title">{`${name} ${surname} - ${moment().format('MMMM')}`}</h3>
            </Col>
            <Col xs={12}>
              {editingWorkday && (
                <EditWorkday
                  workday={editingWorkday}
                  additionalWorkday={additionalWorkday}
                  updateHours={this.updateHours}
                  worksites={worksites}
                />
              )}
            </Col>
            <Col xs={12}>
              {!loadingEmployee && employee && employee.workdays ? (
                <FixHoursTable workdays={employee.workdays} onRowSelect={this.onRowSelect} />
              ) : (
                <ReactLoading type="spin" color="cyan" height={128} width={128} className="react-loader" />
              )}
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default withRouter(PopraviUre);
