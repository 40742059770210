import React, { PureComponent } from 'react';
import { translate } from 'react-i18next';
import { Collapse } from 'reactstrap';
import DownIcon from 'mdi-react/ChevronDownIcon';
import PropTypes from 'prop-types';

const de = `${process.env.PUBLIC_URL}/img/language/germany.png`;
const sl = `${process.env.PUBLIC_URL}/img/language/slovenia.png`;

const DeLng = () => (
  <span className="topbar__language-btn-title">
    <img src={de} alt="de" />
    <span>DE</span>
  </span>
);

const SlLng = () => (
  <span className="topbar__language-btn-title">
    <img src={sl} alt="sl" />
    <span>SL</span>
  </span>
);

class TopbarLanguage extends PureComponent {
  static propTypes = {
    i18n: PropTypes.shape({ changeLanguage: PropTypes.func }).isRequired,
  };

  constructor() {
    super();
    const lang = localStorage.getItem('lang') || 'sl';
    this.state = {
      collapse: false,
      mainButtonContent: lang === 'sl' ? <SlLng /> : <DeLng />,
    };
  }

  toggle = () => {
    this.setState({ collapse: !this.state.collapse });
  };

  changeLanguage = lng => {
    this.props.i18n.changeLanguage(lng);
    localStorage.setItem('lang', lng);
    switch (lng) {
      case 'sl':
        this.setState({ mainButtonContent: <SlLng /> });
        break;
      case 'de':
        this.setState({ mainButtonContent: <DeLng /> });
        break;
      default:
        this.setState({ mainButtonContent: <SlLng /> });
        break;
    }
  };

  render() {
    return (
      <div className="topbar__collapse topbar__collapse--language">
        <button className="topbar__btn" onClick={this.toggle}>
          {this.state.mainButtonContent}
          <DownIcon className="topbar__icon" />
        </button>
        <Collapse isOpen={this.state.collapse} className="topbar__collapse-content topbar__collapse-content--language">
          <button className="topbar__language-btn" type="button" onClick={() => this.changeLanguage('sl')}>
            <SlLng />
          </button>
          <button className="topbar__language-btn" type="button" onClick={() => this.changeLanguage('de')}>
            <DeLng />
          </button>
        </Collapse>
      </div>
    );
  }
}

export default translate('common')(TopbarLanguage);
