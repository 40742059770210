import { API_LINK } from 'constants/config';
import { POST } from 'helpers/agent';

export default {
  lock: async data => {
    const response = await POST(`${API_LINK}/l/lock`, JSON.stringify(data));
    return response.success;
  },
  check: async data => {
    const response = await POST(`${API_LINK}/l/check`, JSON.stringify(data));
    if (response.success) {
      return response.locked;
    }
    return true;
  },
  unlock: async data => {
    const response = await POST(`${API_LINK}/l/unlock`, JSON.stringify(data));
    return response.success;
  },
};
