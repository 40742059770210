import React, { Component, Fragment } from 'react';
import { Container, Row, Col, Card, CardBody, Button, ButtonToolbar, Progress } from 'reactstrap';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import _ from 'lodash';
import { getItem, setItem } from 'helpers/cookies';
import SuperModal from '../../../shared/components/SuperModal';
import TransactionForm from './components/Forms/TransactionForm';
import cashboxApi from '../../../api/cashbox';
import TransactionsTable from './components/Tables/TransactionsTable';
import BalanceCard from './components/Cards/BalanceCard';
import Dropdown from '../Filters/Dropdown';
import constants from '../helpers/index';
import CashboxFilter from '../Filters/CashboxFilter';
import CalcCard from './components/Cards/CalcCard';
import getRoles from '../../../constants/roles';
import payload from '../../../helpers/jwt';

const {
  MONTHS,
  YEARS,
  CASHBOX_FILTER_MONTH_FROM,
  CASHBOX_FILTER_YEAR_FROM,
  CASHBOX_FILTER_MONTH_TO,
  CASHBOX_FILTER_YEAR_TO,
} = constants;

class CashboxIndex extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      transactionsFull: [],
      transactions: [],
      balances: [],
      calcs: [],
      filters: [],
      filtersDefault: null,
      month: {
        from: getItem(CASHBOX_FILTER_MONTH_FROM) || moment(new Date()).month() + 1,
        to: getItem(CASHBOX_FILTER_MONTH_TO) || moment(new Date()).month() + 1,
      },
      year: {
        from: getItem(CASHBOX_FILTER_YEAR_FROM) || moment(new Date()).year(),
        to: getItem(CASHBOX_FILTER_YEAR_TO) || moment(new Date()).year(),
      },
      progress: 0,
      showModal: false,
      transactionEditValues: undefined,
      ROLES: getRoles(),
    };
  }

  componentDidMount() {
    const { month, year } = this.state;
    this.fetchTransactions(month, year);
  }

  componentDidUpdate(prevProps, prevState) {
    if (!_.isEqual(this.state.filters, prevState.filters)) {
      this.applyFilters(this.state.filters);
    } else if (!_.isEqual(this.state.transactionsFull, prevState.transactionsFull)) {
      this.applyFilters(this.state.filtersDefault);
    }
  }

  dismissProgress = () => {
    this.setState({ progress: 100 });
    setTimeout(() => {
      this.setState({ progress: 0 });
    }, 1000);
  };

  renderProgress = () => {
    const { progress } = this.state;
    return progress === 0 ? (
      <div style={{ width: '100%', height: '5px', backgroundColor: '#ECECEC', marginBottom: '30px' }}></div>
    ) : (
      <div className="progress-wrap progress-wrap--blue">
        <Progress value={progress} />
      </div>
    );
  };

  calcBalancesForItems = (items, transactions, itemName, id = '_id', ignore = null, inverse = false, catName = '') => {
    const balances = items.reduce((list, item, index) => {
      const filteredTransactions = !ignore
        ? transactions.filter(t => t[itemName] && item.value === t[itemName][id] && !t.unconfirmed)
        : transactions.filter(t => !t[ignore] && t[itemName] && item.value === t[itemName][id] && !t.unconfirmed);
      const total = filteredTransactions.reduce((sum, trans) => {
        return sum + trans.amount;
      }, 0);

      list.push({
        key: `calcs-${itemName}${index}`,
        title: item.label,
        [itemName]: item,
        total: inverse ? -total : total,
      });
      return list;
    }, []);

    return {
      key: `bal-${catName}`,
      catName,
      balances,
    };
  };

  calculateFilteredBalances = (filters, transactions) => {
    const total = transactions.reduce((sum, elt) => {
      const _sum = sum + elt.amount;
      return _sum;
    }, 0);

    const bal = {
      key: `calcs-sumsum`,
      title: 'SUM',
      //[itemName]: item,
      total,
    };

    const sumBalance = {
      key: `bal-sum`,
      //catName: 'SKP.',
      balances: [bal],
    };

    const calcs = [sumBalance]; //[sourceBalances, categoryBalances, typeBalances, vehicleBalances, companyBalances, employeeBalances];
    this.setState({ calcs });
  };

  arrayUnique = array => {
    const a = array.concat();
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < a.length; ++i) {
      // eslint-disable-next-line no-plusplus
      for (let j = i + 1; j < a.length; ++j) {
        // eslint-disable-next-line no-plusplus
        if (_.isEqual(a[i], a[j])) a.splice(j--, 1);
      }
    }

    return a;
  };

  isFilterEmpty = filter => {
    const {
      selectedCompanySearchItem,
      selectedWorkerSearchItem,
      selectedVehicleSearchItem,
      selectedTransactionCategorySearchItem,
      selectedTransactionSourceSearchItem,
      selectedTransactionSubtypeSearchItem,
    } = filter;
    return (
      selectedCompanySearchItem.length === 0 &&
      selectedWorkerSearchItem.length === 0 &&
      selectedVehicleSearchItem.length === 0 &&
      selectedTransactionCategorySearchItem.length === 0 &&
      selectedTransactionSourceSearchItem.length === 0 &&
      selectedTransactionSubtypeSearchItem.length === 0
    );
  };

  applyFilters = filters => {
    const { transactionsFull } = this.state;
    const {
      selectedCompanySearchItem,
      selectedWorkerSearchItem,
      selectedVehicleSearchItem,
      selectedTransactionCategorySearchItem,
      selectedTransactionSourceSearchItem,
      selectedTransactionSubtypeSearchItem,
      selectedViewSearchItem,
      selectedTravelOrderOptionSearchItem,
      selectedReturnedReasonSearchItem,
    } = filters;

    // by transaction source
    const filteredByTransactionSource = transactionsFull.filter(
      tf =>
        selectedTransactionSourceSearchItem.length === 0 ||
        selectedTransactionSourceSearchItem.find(s => tf.source && s.value === tf.source._id),
    );

    // by transaction category
    const filteredByTransactionCategory = filteredByTransactionSource.filter(
      fts =>
        selectedTransactionCategorySearchItem.length === 0 ||
        selectedTransactionCategorySearchItem.find(c => fts.category && c.value === fts.category.catId),
    );

    //by transaction subtype
    const filteredByTransactionSubtype = filteredByTransactionCategory.filter(
      ftst =>
        selectedTransactionSubtypeSearchItem.length === 0 ||
        selectedTransactionSubtypeSearchItem.find(st => ftst.type && st.value === ftst.type._id),
    );

    //by vehicle
    const filteredByVehicle = filteredByTransactionSubtype.filter(
      fv =>
        selectedVehicleSearchItem.length === 0 ||
        selectedVehicleSearchItem.find(v => fv.vehicle && v.value === fv.vehicle._id),
    );

    //by company
    const filteredByCompany = filteredByVehicle.filter(
      fc =>
        selectedCompanySearchItem.length === 0 ||
        selectedCompanySearchItem.find(c => fc.company && c.value === fc.company._id),
    );

    //by employee
    const filteredByEmployee = filteredByCompany.filter(
      fe =>
        selectedWorkerSearchItem.length === 0 ||
        selectedWorkerSearchItem.find(c => fe.employee && c.value === fe.employee._id),
    );

    //by travel order options
    const filteredByTravelOrderOptions = filteredByEmployee.filter(
      fe =>
        selectedTravelOrderOptionSearchItem.length === 0 ||
        selectedTravelOrderOptionSearchItem.find(
          c => fe.type && fe.type.travelOrderOption && c.value === fe.type.travelOrderOption.value,
        ),
    );

    //by returned reasons
    const filteredByReturnedReasons = filteredByTravelOrderOptions.filter(
      fe =>
        selectedReturnedReasonSearchItem.length === 0 ||
        selectedReturnedReasonSearchItem.find(c => fe.returned && c.value === fe.returned),
    );

    //by view
    const filteredByView = filteredByReturnedReasons.filter(
      fv =>
        selectedViewSearchItem.length === 0 ||
        selectedViewSearchItem.find(
          c =>
            (c.value && c.type === 'unconfirmed' && fv.unconfirmed && !fv.imported) ||
            (c.value && c.type === 'imported' && fv.imported) ||
            (!c.value && c.type === 'unconfirmed' && !fv.unconfirmed),
        ),
    );

    this.setState({ transactions: filteredByView });

    this.calculateFilteredBalances(filters, filteredByView);

    //! deprecated

    // const merged = [
    //   ...filteredByTransactionSource,
    //   ...filteredByTransactionCategory,
    //   ...filteredByTransactionSubtype,
    //   ...filteredByVehicle,
    //   ...filteredByCompany,
    //   ...filteredByEmployee,
    // ];

    // let uniqueMerged = this.arrayUnique(merged);

    // if (uniqueMerged.length === 0 && this.isFilterEmpty(filters)) uniqueMerged = transactionsFull;
  };

  fetchTransactions = async (month, year) => {
    this.setState({ progress: 15 });
    await this.fetchBalances();
    const { transactions } = await cashboxApi.getTransactions(month.from, year.from, month.to, year.to);

    this.setState({ transactions, transactionsFull: transactions });
    this.dismissProgress();
  };

  fetchBalances = async () => {
    const balances = await cashboxApi.getBalances();
    this.setState({ balances });
  };

  handleSubmit = async values => {
    if (values.transactionId) {
      await cashboxApi.updateTransaction(values);
    } else {
      await cashboxApi.addTransaction(values);
    }
    this.toggleModal(false);
    const { month, year } = this.state;
    this.fetchTransactions(month, year);
  };

  handleRowClick = async value => {
    const transactionEditValues = await cashboxApi.getTransaction(value);

    this.setState({ transactionEditValues });
    this.toggleModal(true);
  };

  handleDelete = async value => {
    await cashboxApi.deleteTransaction(value);
    this.toggleModal(false);
    const { month, year } = this.state;
    this.fetchTransactions(month, year);
  };

  handleConfirm = async value => {
    await cashboxApi.confirmTransaction(value);
    this.toggleModal(false);
    const { month, year } = this.state;
    this.fetchTransactions(month, year);
  };

  handleMonthYearSelect = async (monthYearValue, fromTo, monthYear) => {
    const isMonth = monthYear === 'month';
    const otherMonthYear = monthYear === 'month' ? 'year' : 'month';

    const lKey = `CASHBOX_FILTER_${monthYear.toUpperCase()}_${fromTo.toUpperCase()}`;

    const monthYearState = this.state[monthYear];
    monthYearState[fromTo] = monthYearValue;

    const setter = {
      [monthYear]: {
        ...monthYearState,
      },
    };

    this.setState(setter);

    setItem(lKey, monthYearValue);
    if (isMonth) {
      await this.fetchTransactions(setter[monthYear], this.state[otherMonthYear]);
    } else {
      await this.fetchTransactions(this.state[otherMonthYear], setter[monthYear]);
    }
    this.applyFilters(this.state.filters);
  };

  handleFilterSubmit = values => {
    this.setState({ filters: values });
    if (!this.state.filtersDefault) {
      this.setState({ filtersDefault: values });
    }
  };

  toggleModal = (show, resetInitial = false) => {
    if (!resetInitial) {
      this.setState({ showModal: show });
    } else {
      this.setState({ showModal: show, transactionEditValues: undefined });
    }
  };

  renderCalculatedBalances = () => {
    const { calcs } = this.state;
    const { t } = this.props;

    return calcs.reduce((list, calc) => {
      const { balances, catName, key } = calc;
      if (balances.length > 0) {
        list.push(<CalcCard key={key} calcs={balances} title={t('cashbox.balances_sum')} />);
      }
      return list;
    }, []);
  };

  renderBalanceCards = () => {
    const { balances } = this.state;
    return balances.map((balance, index) => (
      <BalanceCard
        offset={0}
        key={balance._id}
        title={balance.name}
        balance={balance.balance}
        unconfirmedBalance={balance.unconfirmedBalance}
      />
    ));
  };

  renderDropdown = () => {
    return (
      <Fragment>
        <Dropdown
          defaultOption={this.state.year.from}
          options={YEARS.map(m => {
            return { value: m, label: m.toString(), color: 'white' };
          })}
          onItemSelect={value => this.handleMonthYearSelect(value, 'from', 'year')}
        />
        <Dropdown
          defaultOption={this.state.month.from}
          options={MONTHS.map(m => {
            return { value: m, label: m.toString(), color: 'white' };
          })}
          onItemSelect={value => this.handleMonthYearSelect(value, 'from', 'month')}
        />
        <div style={{ padding: 6, paddingRight: 20 }}>---</div>
        <Dropdown
          defaultOption={this.state.year.to}
          options={YEARS.map(m => {
            return { value: m, label: m.toString(), color: 'white' };
          })}
          onItemSelect={value => this.handleMonthYearSelect(value, 'to', 'year')}
        />
        <Dropdown
          defaultOption={this.state.month.to}
          options={MONTHS.map(m => {
            return { value: m, label: m.toString(), color: 'white' };
          })}
          onItemSelect={value => this.handleMonthYearSelect(value, 'to', 'month')}
        />
      </Fragment>
    );
  };

  filterTransationsforRole = transations => {
    //console.log('CashboxIndex -> filterTransationsforRole -> transations', transations);
    const { ROLES } = this.state;
    const token = getItem('jwt');
    //console.log('CashboxIndex -> token', token);
    const { uid } = payload(token);
    //console.log('CashboxIndex -> uid', uid);
    if (!(ROLES.super || ROLES.sistem)) {
      return transations.filter(t => t.modifiedBy.uid === uid);
    }
    return transations;
  };

  render() {
    const { t } = this.props;
    const { showModal, transactions, transactionEditValues, ROLES } = this.state;
    return (
      <Container className="dashboard">
        <Row>
          <Col md={12}>
            <h3 className="page-title">{t('sidebar_content.finance.cashbox')}</h3>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <div style={{ position: 'relative' }}>
              <div className="super-modal-abs">
                <Button color="primary" onClick={() => this.toggleModal(true, true)}>
                  {t('cashbox.trans_add')}
                </Button>
              </div>
              <SuperModal
                title={t('cashbox.trans_new').toUpperCase()}
                show={showModal}
                onClose={() => this.toggleModal(false)}
              >
                <TransactionForm
                  onSubmit={this.handleSubmit}
                  onCancel={() => this.toggleModal(false)}
                  onDelete={this.handleDelete}
                  onConfirm={this.handleConfirm}
                  onFreeze={() => null}
                  initialValues={transactionEditValues}
                />
              </SuperModal>
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <CashboxFilter onSubmit={this.handleFilterSubmit} />
          </Col>
          <Col md={3} style={{ padding: '150px 0px 0px 0px' }}>
            {this.renderCalculatedBalances()}
          </Col>
        </Row>
        {/* <Row>{this.renderCalculatedBalances()}</Row> */}
        {(ROLES.super || ROLES.sistem) && (
          <Row>
            {/* <Col md={12}>
              <h4>TRENUTNO STANJE VIROV</h4>
            </Col> */}
            {this.renderBalanceCards()}
          </Row>
        )}
        <Row className="mt-1">
          <Col md={12}>
            <Card>
              <CardBody>
                <ButtonToolbar style={{ position: 'relative' }}>{this.renderDropdown()}</ButtonToolbar>
                {this.renderProgress()}
                <TransactionsTable
                  transactions={this.filterTransationsforRole(transactions)}
                  onRowClick={this.handleRowClick}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default translate('common')(CashboxIndex);
