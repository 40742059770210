import {
  SET_ACCOMMODATIONS,
  SET_ACCOMMODATIONS_TITLES_LIST,
  SET_ACCOMMODATION_PROVIDERS_TITLES_LIST,
  SET_ACCOMMODATION_PROVIDERS,
} from '../actions/accommodationsActions';

const initialState = {
  accommodations: [],
  accommodationTitles: [],
  providers: [],
  providerTitles: [],
};

export default function(state = initialState, action) {
  const titlesList = [];
  const providersList = [];
  switch (action.type) {
    case SET_ACCOMMODATIONS:
      action.accommodations.forEach(acm => {
        const acmData = {
          value: acm._id,
          label: acm.address,
        };
        titlesList.push(acmData);
      });

      action.accommodations.forEach(prov => {
        const provData = {
          value: prov.providerId,
          label: prov.title,
        };
        if (!providersList.find(p => p.value === prov.providerId)) {
          providersList.push(provData);
        }
      });
      return {
        ...state,
        accommodations: action.accommodations,
        accommodationTitles: titlesList,
        providerTitles: providersList,
      };
    case SET_ACCOMMODATIONS_TITLES_LIST:
      return { ...state, accommodationTitles: action.accommodationTitles };
    case SET_ACCOMMODATION_PROVIDERS_TITLES_LIST:
      return { ...state, providerTitles: action.providerTitles };
    case SET_ACCOMMODATION_PROVIDERS:
      action.providers.forEach(acm => {
        const acmData = {
          value: acm._id,
          label: acm.title,
        };
        titlesList.push(acmData);
      });
      return {
        ...state,
        providers: action.providers,
        providerTitles: titlesList,
      };
    default:
      return state;
  }
}
