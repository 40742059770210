import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody, Col, ButtonToolbar, Button } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import renderSelectField from 'shared/components/form/Select';
import validate from './validate';

const UreGradbiscaForm = ({ handleSubmit, handleCancel, workers, editData, pristine }) => (
  <Col md={12} lg={12}>
    <Card>
      <CardBody>
        <form className="form" onSubmit={handleSubmit}>
          <div className="form__form-group">
            <span className="form__form-group-label">Delavec</span>
            <div className="form__form-group-field">
              <Field name="workerId" component={renderSelectField} options={workers} />
            </div>
          </div>

          <ButtonToolbar className="form__button-toolbar">
            <Button type="button" onClick={handleCancel}>
              Prekliči
            </Button>
            <Button color="primary" type="submit" disabled={pristine}>
              {editData ? 'Shrani spremembe' : 'Dodaj'}
            </Button>
          </ButtonToolbar>
        </form>
      </CardBody>
    </Card>
  </Col>
);

UreGradbiscaForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
  workers: PropTypes.arrayOf(PropTypes.object).isRequired,
  editData: PropTypes.object,
  pristine: PropTypes.bool.isRequired,
};

UreGradbiscaForm.defaultProps = {
  editData: null,
};

export default reduxForm({
  form: 'ure_gradbisca_form', // a unique identifier for this form
  validate,
})(UreGradbiscaForm);
