import { SET_OREDERERS, SET_ORDERERS_TITLES_LIST } from '../actions/orderersActions';

const initialState = {
  orderers: [],
  titlesOrderers: [],
};

export default function(state = initialState, action) {
  const orderersList = [];
  switch (action.type) {
    case SET_OREDERERS:
      return { ...state, orderers: action.orderers };
    case SET_ORDERERS_TITLES_LIST:
      action.titlesOrderers.forEach(ord => {
        const ordData = {
          value: ord._id,
          label: ord.title,
        };
        orderersList.push(ordData);
      });
      return { ...state, titlesOrderers: orderersList };
    default:
      return state;
  }
}
