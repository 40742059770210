import React, { Fragment } from 'react';
import ReactToPrint from 'react-to-print';
import PropTypes from 'prop-types';
import { Button, ButtonToolbar, Modal, Row, Col } from 'reactstrap';
import { translate } from 'react-i18next';
import classNames from 'classnames';
import AddIcon from 'mdi-react/AccountMultiplePlusOutlineIcon';
import { OfficeIcon } from 'mdi-react';
import ReactLoading from 'react-loading';
import workhoursApi from 'api/workhours';
import { getItem, setItem } from 'helpers/cookies';
import moment from 'moment-timezone';
import SingleOWTable from './SingleOWTable';
import SingleOWSheet from '../sheets/SingleOWSheet';
import Dropdown from '../../../../Finance/Filters/Dropdown';
import constants from '../../../../Finance/helpers';
import { TIMEZONE } from '../../../../../constants/config';
import MultiOWTable from './MultiOWTable';

const { MONTHS, YEARS } = constants;

const GET_BY_WORKSITE_MONTH_FROM = 'GET_BY_WORKSITE_MONTH_FROM';
const GET_BY_WORKSITE_MONTH_TO = 'GET_BY_WORKSITE_MONTH_TO';
const GET_BY_WORKSITE_YEAR_FROM = 'GET_BY_WORKSITE_YEAR_FROM';
const GET_BY_WORKSITE_YEAR_TO = 'GET_BY_WORKSITE_YEAR_TO';

class SingleOWModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      workersData: [],
      workersDataList: [],
      month: {
        from: /* getItem(GET_BY_WORKSITE_MONTH_FROM) ||  */ props.month,
        to: /* getItem(GET_BY_WORKSITE_MONTH_TO) ||  */ props.month,
      },
      year: {
        from: /* getItem(GET_BY_WORKSITE_YEAR_FROM) ||  */ props.year,
        to: /* getItem(GET_BY_WORKSITE_YEAR_TO) ||  */ props.year,
      },
      dateError: null,
    };

    this.toggle = this.toggle.bind(this);
    this.cancel = this.cancel.bind(this);
    this.handleESC = this.handleESC.bind(this);
    this.getOWData = this.getOWData.bind(this);
  }

  componentDidMount() {
    document.addEventListener('keydown', this.handleESC, false);
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.show && this.props.show) {
      const { month, year } = this.state;
      this.getOWData(month, year);
    }
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleESC, false);
  }

  calculateListSizeFromSpan = (month, year) => {
    const yearFrom = Number(year.from);
    const yearTo = Number(year.to);
    const monthFrom = Number(month.from);
    const monthTo = Number(month.to);
    const from = moment([yearFrom, monthFrom - 1, 1]);
    const to = moment([yearTo, monthTo - 1, 1]);
    return to.diff(from, 'month');
  };

  isDateSpanValid = (month, year) => {
    const yearFrom = Number(year.from);
    const yearTo = Number(year.to);
    const monthFrom = Number(month.from);
    const monthTo = Number(month.to);
    const from = moment([yearFrom, monthFrom - 1, 1]);
    const to = moment([yearTo, monthTo - 1, 1]);
    return from <= to;
  };

  calculateTotalSum = workersDataList => {
    return workersDataList.reduce(
      (total, _elt) => {
        const elt = _elt.workers;
        const sums = elt.find(e => e._id === 'sums');

        if (sums.workdays.length === 0) {
          return total; //{ ...total, workdays: elt.workdays, transfers: elt.transfers };
        }

        const totWorkdays = [];
        sums.workdays.forEach(wday => {
          const dayData = total.workdays.find(
            w =>
              moment(w.date)
                .tz(TIMEZONE)
                .date() ===
              moment(wday.date)
                .tz(TIMEZONE)
                .date(),
          );
          if (dayData) {
            const dHours = dayData.dayHours + wday.dayHours;
            const nHours = dayData.nightHours + wday.nightHours;
            totWorkdays.push({
              ...dayData,
              dayHours: dHours,
              nighHours: nHours,
            });
          } else {
            totWorkdays.push(wday);
          }
        });

        const transDay = total.transfers.daySum + sums.transfers.daySum;
        const transNight = total.transfers.nightSum + sums.transfers.nightSum;
        const transfers = {
          daySum: transDay,
          nightSum: transNight,
        };
        return {
          ...total,
          transfers,
          workdays: totWorkdays,
        };
      },
      {
        color: 'gold',
        label: 'SKUPNI SEŠTEVEK',
        name: '',
        shortCompanyName: 'Skp.',
        surname: '',
        transfers: { daySum: 0, nightSum: 0 },
        workdays: [],
      },
    );
  };

  getOWData = async (month, year) => {
    const { ordererId, worksiteId, selectedCompany } = this.props;
    const isDateValid = this.isDateSpanValid(month, year);
    if (!isDateValid) {
      this.setState({ dateError: 'Končni datum ne sme biti večji od začetnega!' });
      return;
    }
    const listSize = this.calculateListSizeFromSpan(month, year);
    if (listSize > 6) {
      this.setState({ dateError: 'Razpon ne sme biti večji od 6 mesecev!' });
      return;
    }
    this.setState({ workersData: [], workersDataList: [], dateError: null });

    const yearFrom = Number(year.from);
    const monthFrom = Number(month.from);
    const workersDataList = [];
    for (let i = 0; i < listSize + 1; i += 1) {
      const date = moment([yearFrom, monthFrom + i - 1, 1]);
      const currMonth = date.month() + 1;
      const currYear = date.year();
      // eslint-disable-next-line no-await-in-loop
      const data = await workhoursApi.getbyworksite({
        ordererId,
        worksiteId,
        month: currMonth,
        year: currYear,
        readonly: true,
        selectedCompany,
      });
      if (data.workers) {
        workersDataList.push({ workers: data.workers, month: currMonth, year: currYear });
      }
    }

    const totSums = this.calculateTotalSum(workersDataList);
    const totals = {
      workers: [{ ...totSums, _id: 'sums' }],
      month: month.from,
      year: year.from,
      tag: 'totSums',
    };
    if (workersDataList.length > 1) workersDataList.push(totals);
    this.setState({ workersDataList });
  };

  handleESC = event => {
    if (event.keyCode === 27) {
      this.setState({
        modal: false,
        month: {
          from: this.props.month,
          to: this.props.month,
        },
        year: {
          from: this.props.year,
          to: this.props.year,
        },
      });
      this.cancel();
    }
  };

  toggle = () => {
    this.setState({
      modal: !this.state.modal,
      month: {
        from: this.props.month,
        to: this.props.month,
      },
      year: {
        from: this.props.year,
        to: this.props.year,
      },
    });
  };

  cancel = () => {
    this.setState({
      modal: false,
      month: {
        from: this.props.month,
        to: this.props.month,
      },
      year: {
        from: this.props.year,
        to: this.props.year,
      },
    });
    this.props.onClose();
  };

  handleMonthYearSelect = async (monthYearValue, fromTo, monthYear) => {
    const isMonth = monthYear === 'month';
    const otherMonthYear = monthYear === 'month' ? 'year' : 'month';

    const lKey = `GET_BY_WORKSITE_${monthYear.toUpperCase()}_${fromTo.toUpperCase()}`;

    const monthYearState = this.state[monthYear];
    monthYearState[fromTo] = monthYearValue;

    const setter = {
      [monthYear]: {
        ...monthYearState,
      },
    };

    this.setState(setter);

    setItem(lKey, monthYearValue);
    if (isMonth) {
      //this.calculateListSizeFromSpan(setter[monthYear], this.state[otherMonthYear]);
      this.getOWData(setter[monthYear], this.state[otherMonthYear]);
    } else {
      this.getOWData(this.state[otherMonthYear], setter[monthYear]);
      //this.calculateListSizeFromSpan(this.state[otherMonthYear], setter[monthYear]);
    }
    //this.applyFilters(this.state.filters);
  };

  renderDropdown = () => {
    return (
      <Fragment>
        <Dropdown
          defaultOption={this.state.year.from}
          options={YEARS.map(m => {
            return { value: m, label: m.toString(), color: 'white' };
          })}
          onItemSelect={value => this.handleMonthYearSelect(value, 'from', 'year')}
        />
        <Dropdown
          defaultOption={this.state.month.from}
          options={MONTHS.map(m => {
            return { value: m, label: m.toString(), color: 'white' };
          })}
          onItemSelect={value => this.handleMonthYearSelect(value, 'from', 'month')}
        />
        <div style={{ padding: 6, paddingRight: 20 }}>---</div>
        <Dropdown
          defaultOption={this.state.year.to}
          options={YEARS.map(m => {
            return { value: m, label: m.toString(), color: 'white' };
          })}
          onItemSelect={value => this.handleMonthYearSelect(value, 'to', 'year')}
        />
        <Dropdown
          defaultOption={this.state.month.to}
          options={MONTHS.map(m => {
            return { value: m, label: m.toString(), color: 'white' };
          })}
          onItemSelect={value => this.handleMonthYearSelect(value, 'to', 'month')}
        />
      </Fragment>
    );
  };

  // renderSingleOWTables = () => {
  //   const { title, selectedCompanyName, selectedCompany } = this.props;
  //   const { workersDataList } = this.state;
  //   //console.log('SingleOWModal -> renderSingleOWTables -> workersDataList', workersDataList);
  //   return workersDataList.map(item => {
  //     const { workers, month, year } = item;
  //     const tag = item.tag || 'normal';
  //     return (
  //       <Col lg={12} md={12} sm={12} className="mb-3">
  //         <SingleOWTable
  //           data={workers}
  //           month={month}
  //           year={year}
  //           title={title}
  //           subtitle={selectedCompanyName}
  //           showCompanyName={selectedCompany === 'all'}
  //           tag={tag}
  //         />
  //       </Col>
  //     );
  //   });
  // };

  // handleBeforePrint = () => {
  //   console.log('SingleOWModal -> handleBeforePrint');
  // };

  // handleAfterPrint = () => {
  //   console.log('SingleOWModal -> handleAfterPrint');
  // };

  // handlePrintError = (errorLocation, error) => {
  //   console.log('SingleOWModal -> handlePrintError -> errorLocation', errorLocation);
  //   console.log('SingleOWModal -> handlePrintError -> error', error);
  // };

  render() {
    const {
      icon,
      btn,
      title,
      colored,
      header,
      hasBtn,
      show,
      month,
      year,
      selectedCompanyName,
      selectedCompany,
      t,
    } = this.props;

    const { workersData, workersDataList, dateError } = this.state;
    /* console.log('TCL: SingleOWModal -> render -> workersData', workersData.length);
    const top = fix(threePointLerp(workersData.length));
    console.log('TCL: SingleOWModal -> render -> top', top); */

    /* eslint-disable */
    const portrait =
      '@page { size: auto;  margin: 2mm; } .switchToolTip { display: none !important; } .night-switch { display: none !important; } @media print { body { -webkit-print-color-adjust: exact !important; } .custom-style td span { font-size: 12px !important; } .custom-style td strong { font-size: 12px !important; } .custom-style .badge { font-size: 12px !important; } .printable-title {display: inline !important;} .printable-table .card {height:100%;} }';
    const landscape =
      '@page { size: auto;  margin: 2mm; } .switchToolTip { display: none !important; } .night-switch { display: none !important; } @media print { body { -webkit-print-color-adjust: exact !important; } .custom-style td span { font-size: 12px !important; } .custom-style td strong { font-size: 12px !important; } .custom-style .badge { font-size: 12px !important; } .printable-table {-ms-transform: rotate(90deg); -webkit-transform: rotate(90deg); transform: rotate(90deg); position: absolute; right: 41%; width: 140% !important; top: 0%; } .printable-title {display: inline !important;} .printable-table .card {height:100%;} }';
    /* eslint-enable */

    let orientation = portrait; //workersData.length > 25 ? portrait : landscape;

    if (workersDataList.length === 1 && workersDataList[0].workers.length <= 25) orientation = landscape;

    let Icon;

    switch (icon) {
      case 'add':
        Icon = <AddIcon />;
        break;
      case 'edit':
        Icon = <OfficeIcon />;
        break;
      default:
        break;
    }
    const modalClass = classNames({
      'modal-dialog--colored': colored,
      'modal-dialog--header': header,
    });

    return (
      <div style={{ width: '100%' }}>
        {hasBtn ? (
          <Button className="icon large-button" color="primary" onClick={this.toggle}>
            <p>
              {icon === 'add' ? <AddIcon /> : <OfficeIcon />}
              {btn}
            </p>
          </Button>
        ) : null}

        <Modal
          isOpen={this.state.modal || show}
          toggle={this.toggle}
          className={`modal-dialog--primary ${modalClass} ow-modal`}
        >
          <div className="modal__header">
            <button className="lnr lnr-cross modal__close-btn" onClick={this.toggle} />
            {header ? '' : Icon}
            <h4 className="bold-text  modal__title">{`${title}`}</h4>
            <h4 className="bold-text  modal__title" style={{ color: '#65B3FD' }}>{`${selectedCompanyName}`}</h4>
          </div>
          <ButtonToolbar style={{ position: 'relative', margin: '0 auto' }}>{this.renderDropdown()}</ButtonToolbar>
          {dateError && (
            <div style={{ marginTop: '20px' }}>
              <strong style={{ color: 'red' }}>{dateError}</strong>
            </div>
          )}
          <div className="modal__body">
            <div className="theme-light">
              <ButtonToolbar>
                {workersDataList.length > 0 && (
                  <div style={{ float: 'left', marginLeft: '25px' }}>
                    <SingleOWSheet
                      data={workersDataList}
                      // month={month}
                      // year={year}
                      btn={t('form.exportExcel')}
                      OWTitle={title}
                      companyTitle={selectedCompanyName}
                    />
                  </div>
                )}

                {workersDataList.length === 1 && (
                  <ReactToPrint
                    trigger={() => (
                      <Button
                        color="warning"
                        size="sm"
                        style={{
                          float: 'left',
                          marginTop: '9px',
                          marginLeft: '10px',
                        }}
                      >
                        {t('calcs.print')}
                      </Button>
                    )}
                    content={() => this.componentRef1}
                    //pageStyle={`@page { size: auto;  margin: 0mm; } .night-switch { display: none !important; } @media print { body { -webkit-print-color-adjust: exact; } .printable-table {-ms-transform: rotate(90deg); -webkit-transform: rotate(90deg); transform: rotate(90deg); position: absolute; top: ${top}%; width: 110% !important; } }`}
                    //?pageStyle={`@page { size: auto;  margin: 0mm; } .night-switch { display: none !important; } @media print { body { -webkit-print-color-adjust: exact !important; } .printable-table {-ms-transform: rotate(90deg); -webkit-transform: rotate(90deg); transform: rotate(90deg); position: absolute; right: 25%; width: 110% !important; top: -50%; } .printable-title {display: inline !important;} .printable-table .card {height:100%;} }`}
                    pageStyle={orientation}
                  />
                )}
                {workersDataList.length > 1 && (
                  <ReactToPrint
                    trigger={() => (
                      <Button
                        color="warning"
                        size="sm"
                        style={{
                          float: 'left',
                          marginTop: '9px',
                          marginLeft: '10px',
                        }}
                      >
                        {t('calcs.print')}
                      </Button>
                    )}
                    content={() => this.componentRef2}
                    pageStyle={orientation}
                    // onBeforePrint={this.handleBeforePrint}
                    // onAfterPrint={this.handleAfterPrint}
                    // onPrintError={this.handlePrintError}
                  />
                )}
                {/* <PrintContextConsumer>
                   {({ handlePrint }) => (
                     <Button
                       color="warning"
                       size="sm"
                       style={{
                         float: 'left',
                         marginTop: '9px',
                         marginLeft: '10px',
                       }}
                       onClick={handlePrint}
                     >
                       {t('calcs.print')}
                     </Button>
                   )}
                 </PrintContextConsumer>
               </ReactToPrint> */}
              </ButtonToolbar>
              {workersDataList.length === 0 && (
                <ReactLoading type="bars" color="gray" height={128} width={128} className="react-loader" />
              )}
              {workersDataList.length === 1 && (
                <SingleOWTable
                  ref={el => (this.componentRef1 = el)}
                  data={workersDataList[0].workers}
                  month={workersDataList[0].month}
                  year={workersDataList[0].year}
                  title={title}
                  subtitle={selectedCompanyName}
                  showCompanyName={selectedCompany === 'all'}
                  tag="normal"
                />
              )}
              {workersDataList.length > 1 && (
                <MultiOWTable
                  ref={el2 => (this.componentRef2 = el2)}
                  title={title}
                  selectedCompanyName={selectedCompanyName}
                  selectedCompany={selectedCompany}
                  dataList={workersDataList}
                />
                //<Row ref={el => (this.componentRef2 = el)}>{this.renderSingleOWTables()}</Row>
              )}
              <Button style={{ marginBottom: '20px' }} onClick={this.cancel}>
                {t('form.cancel')}
              </Button>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

SingleOWModal.propTypes = {
  title: PropTypes.string,
  icon: PropTypes.string.isRequired,
  colored: PropTypes.bool,
  header: PropTypes.bool,
  btn: PropTypes.string.isRequired,
  hasBtn: PropTypes.bool.isRequired,
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  ordererId: PropTypes.string.isRequired,
  worksiteId: PropTypes.string.isRequired,
  month: PropTypes.number.isRequired,
  year: PropTypes.number.isRequired,
  selectedCompany: PropTypes.string.isRequired,
  selectedCompanyName: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
};

SingleOWModal.defaultProps = {
  title: '',
  colored: false,
  header: false,
};

export default translate('common')(SingleOWModal);
