import React, { Component } from 'react';
import { Container, Row, Col, Card, CardBody } from 'reactstrap';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';
import CompensationTable from '../Wages/components/Compensation/CompansationTable';
import CompensationForm from './components/CompensationForm';
import SuperModal from '../../../shared/components/SuperModal';
import KontoTable from './components/Tables/KontoTable';
import HistorySlider from './components/Filters/HistorySlider';

class CompesationIndex extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      showEmployee: false,
      selectedEmployeeId: null,
      selectedMonth: null,
      selectedYear: null,
      selectedEmployeeName: null,
    };
  }

  handleRowClick = async ({ employeeId, month, year, name, surname }) => {
    //const transactionEditValues = await cashboxApi.getRecurringTemplate(value);

    this.setState({
      selectedEmployeeId: employeeId,
      selectedMonth: month.toString(),
      selectedYear: year.toString(),
      selectedEmployeeName: `${surname.toUpperCase()} ${name}`,
      showEmployee: true,
    });
    // /this.toggleModal(true);
  };

  handleEmployeeDismiss = () => {
    this.setState({ showEmployee: false });
  };

  // toggleModal = (show, resetInitial = false) => {
  //   if (!resetInitial) {
  //     this.setState({ showModal: show });
  //   } else {
  //     this.setState({ showModal: show, transactionEditValues: undefined });
  //   }
  // };

  render() {
    const { t } = this.props;
    const {
      showModal,
      selectedEmployeeId,
      selectedMonth,
      selectedYear,
      selectedEmployeeName,
      showEmployee,
    } = this.state;
    return (
      <Container className="dashboard">
        <Row>
          <Col md={12}>
            <h3 className="page-title">{t('sidebar_content.finance.wages')}</h3>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <Card>
              <CardBody>
                {!showEmployee ? (
                  <CompensationTable onRowClick={this.handleRowClick} />
                ) : (
                  <KontoTable
                    employee={{ _id: selectedEmployeeId, name: selectedEmployeeName }}
                    onDismiss={this.handleEmployeeDismiss}
                    month={selectedMonth}
                    year={selectedYear}
                  />
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default translate('common')(CompesationIndex);
