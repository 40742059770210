import placesApi from 'api/places';

export const SET_PLACES_LOADING = 'SET_PLACES_LOADING';
export const SET_SEARCH_PLACES = 'SET_SEARCH_PLACES';
export const SET_PLACES = 'SET_PLACES';
export const CLEAR_SEARCH = 'CLEAR_SEARCH';
export const SET_ERROR = 'SET_ERROR';
export const CLEAR_PLACES = 'CLEAR_PLACES';

export function setPlacesLoading() {
  return {
    type: SET_PLACES_LOADING,
  };
}

export function setSearchPlaces(places) {
  return {
    type: SET_SEARCH_PLACES,
    payload: places,
  };
}

export function setError(error) {
  return {
    type: SET_ERROR,
    payload: error,
  };
}

export function search(data) {
  return async dispatch => {
    try {
      dispatch(setPlacesLoading());
      const places = await placesApi.search(data);
      dispatch(setSearchPlaces(places));
    } catch (err) {
      console.log('there was an error while searching places', err);
      dispatch(setError(err));
    }
  };
}

export function clearSearch() {
  return {
    type: CLEAR_SEARCH,
  };
}

export function clearPlaces() {
  return {
    type: CLEAR_PLACES,
  };
}

export function setPlaces(places) {
  return {
    type: SET_PLACES,
    payload: places,
  };
}

export function getAll(page) {
  return async dispatch => {
    try {
      dispatch(setPlacesLoading());
      const places = await placesApi.all(page);
      dispatch(setPlaces(places));
    } catch (err) {
      console.log('there was an error while searching places', err);
      dispatch(setError(err));
    }
  };
}
