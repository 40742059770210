import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { translate } from 'react-i18next';
import {
  Row,
  Col,
  Card,
  CardBody,
  Dropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
  Button,
  CardTitle,
} from 'reactstrap';
import { SelectField } from 'shared/components/form/Select';
import { EmployeesProps, WorksitesProps, VehiclesProps } from 'shared/prop-types/ReducerProps';
import SEARCH_TYPES from 'constants/search-types';
import { filterOws, clearFiltered } from 'redux/actions/owActions';

class SearchFilter extends Component {
  static propTypes = {
    employeesStore: EmployeesProps.isRequired,
    worksitesStore: WorksitesProps.isRequired,
    vehiclesStore: VehiclesProps.isRequired,
    dispatch: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      dropdownOpen: false,
      searchType: SEARCH_TYPES.WORKSITE,
      selectedSearchItem: null,
    };
  }

  dropdownToggle = () => {
    this.setState(prevState => ({ dropdownOpen: !prevState.dropdownOpen }));
  };

  searchTypeText = () => {
    const { searchType } = this.state;
    const { t } = this.props;
    switch (searchType) {
      case SEARCH_TYPES.WORKSITE:
        return t('board.filters.worksite');
      case SEARCH_TYPES.EMPLOYEE:
        return t('board.filters.worker');
      case SEARCH_TYPES.VEHICLE:
        return t('board.filters.vehicle');
      default:
        return t('board.filters.worksite');
    }
  };

  searchItems = () => {
    const { searchType } = this.state;
    const { worksitesStore, employeesStore, vehiclesStore } = this.props;
    switch (searchType) {
      case SEARCH_TYPES.WORKSITE:
        return worksitesStore.titlesWorksites;
      case SEARCH_TYPES.EMPLOYEE:
        return employeesStore.employeesNames;
      case SEARCH_TYPES.VEHICLE:
        return vehiclesStore.licenses;
      default:
        return worksitesStore.titlesWorksites;
    }
  };

  handleWorksiteSearchTypeSelect = () => {
    this.setState({
      searchType: SEARCH_TYPES.WORKSITE,
      selectedSearchItem: null,
    });
  };

  handleEmployeeSearchTypeSelect = () => {
    this.setState({
      searchType: SEARCH_TYPES.EMPLOYEE,
      selectedSearchItem: null,
    });
  };

  handleVehicleSearchTypeSelect = () => {
    this.setState({
      searchType: SEARCH_TYPES.VEHICLE,
      selectedSearchItem: null,
    });
  };

  handleSearchChange = value => {
    const { searchType } = this.state;
    const { dispatch } = this.props;
    this.setState({
      selectedSearchItem: value,
    });
    dispatch(filterOws({ searchType, value }));
  };

  handleClear = () => {
    const { dispatch } = this.props;
    this.setState({
      selectedSearchItem: null,
    });
    dispatch(clearFiltered());
  };

  render() {
    const { dropdownOpen, selectedSearchItem } = this.state;
    const { t } = this.props;
    return (
      <Card>
        <CardBody className="pb-4">
          <CardTitle>{t('board.filters.title')}:</CardTitle>
          <Row>
            <Col xs={6}>
              <Dropdown isOpen={dropdownOpen} toggle={this.dropdownToggle}>
                <DropdownToggle className="icon icon--right search-type" caret>
                  {this.searchTypeText()}
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem onClick={this.handleWorksiteSearchTypeSelect}>
                    {t('board.filters.worksite')}
                  </DropdownItem>
                  <DropdownItem onClick={this.handleEmployeeSearchTypeSelect}>{t('board.filters.worker')}</DropdownItem>
                  <DropdownItem onClick={this.handleVehicleSearchTypeSelect}>{t('board.filters.vehicle')}</DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </Col>
            <Col xs={6}>
              <SelectField
                name="tabla-filter-search"
                onChange={this.handleSearchChange}
                placeholder={`${t('board.filters.choose')}`}
                options={this.searchItems()}
                value={selectedSearchItem}
              />
            </Col>
            {/* <Col md={{ size: 3, offset: 9 }}>
              <Button
                className="w-100 search-filter__clear"
                color="primary"
                size="sm"
                onClick={this.handleClear}
              >
                {t('board.filters.clear')}
              </Button>
            </Col> */}
          </Row>
        </CardBody>
      </Card>
    );
  }
}

const wr = connect(state => ({
  employeesStore: state.employees,
  worksitesStore: state.worksites,
  vehiclesStore: state.vehicles,
}))(SearchFilter);

export default translate('common')(wr);
