import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Table, Badge } from 'reactstrap';
import { translate } from 'react-i18next';

class TransactionsTable extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  getReturnedReason = returned => {
    //const style = {color:""}
    if (!returned) return null;
    if (returned === 'receipt')
      return (
        <span>
          <strong>PRINESEL RAČUN</strong>
        </span>
      );
    return (
      <span>
        <strong>PRINESEL GOTOVINO</strong>
      </span>
    );
  };

  renderCompany = company => {
    if (!company) return '-';
    return (
      <Badge
        style={{
          backgroundColor: `${company.color}`,
          color: '#646777',
        }}
      >
        {company.name}
      </Badge>
    );
  };

  renderEmployee = employee => {
    if (!employee) return '-';
    return `${employee.surname.toUpperCase()} ${employee.name}`;
  };

  renderModified = (modifiedBy, unconfirmed) => {
    const style = unconfirmed ? { color: 'gray' } : { color: 'lightgray' };
    if (!modifiedBy) return <span>/</span>;
    return (
      <Fragment>
        <div>
          <span style={style}>{modifiedBy.name}</span> <span style={style}>{modifiedBy.surname}</span>
        </div>
        <div>
          <span style={style}>{modifiedBy.updatedAt}</span>
        </div>
        {unconfirmed && (
          <div>
            <span>
              <strong style={{ color: 'red' }}>NEPOTRJENO!</strong>
            </span>
          </div>
        )}
      </Fragment>
    );
  };

  renderTransactions = (transactions, onRowClick) => {
    return transactions.map((transaction, index) => {
      const count = transactions.length - index;
      const {
        _id,
        date,
        source,
        amount,
        category,
        type,
        company,
        employee,
        vehicle,
        notes,
        who,
        modifiedBy,
        unconfirmed,
        key,
        returned,
        imported,
      } = transaction;

      const amountString = Number(amount) > 0 ? `+${amount}` : amount;
      const amountColor = Number(amount) >= 0 ? 'green' : 'red';
      let bgrColor = unconfirmed ? { backgroundColor: 'pink' } : {};
      bgrColor = imported ? { backgroundColor: 'lightskyblue' } : bgrColor;
      const sourceName = source ? source.name : '-';
      const returnedReason = this.getReturnedReason(returned);
      return (
        <tr key={_id + key} style={bgrColor} onClick={() => onRowClick(_id)}>
          <td>{count}</td>
          <td>{date}</td>
          <td>
            <strong>{sourceName}</strong>
          </td>
          <td>
            <strong style={{ fontSize: 'large', color: amountColor }}>{amountString}</strong>
          </td>
          <td>
            <strong style={{ color: 'gray' }}>{who}</strong>
          </td>
          <td>
            <strong>{category.name.toUpperCase()}</strong>
          </td>
          <td>
            {returnedReason ? (
              <Fragment>
                {returnedReason}
                <br />
              </Fragment>
            ) : (
              <Fragment>
                <span>
                  <strong>{type._id === 'trans_type_in' ? 'PORAČUN' : 'AKONTACIJA'}</strong>
                </span>
                <br />
              </Fragment>
            )}
            {type.name.toUpperCase()}
          </td>
          <td>{this.renderEmployee(employee)}</td>
          <td>{this.renderCompany(company)}</td>
          <td>{vehicle.label}</td>
          <td>{notes}</td>
          <td>{this.renderModified(modifiedBy, unconfirmed)}</td>
        </tr>
      );
    });
  };

  render() {
    const { transactions, onRowClick, t } = this.props;

    return (
      <Table hover className="table--bordered" responsive>
        <thead>
          <tr>
            <th>#</th>
            <th>{t('board.ow.date')}</th>
            <th>{t('cashbox.source')}</th>
            <th>{t('cashbox.amount')} (€)</th>
            <th>{t('cashbox.who')}</th>
            <th>{t('cashbox.purpose')}</th>
            <th>{t('cashbox.trans_type')}</th>
            <th>{t('board.ow.worker')}</th>
            <th>{t('form.company')}</th>
            <th>{t('board.ow.vehicle')}</th>
            <th>{t('form.notes')}</th>
            <th>{t('finance.modifiedBy')}</th>
          </tr>
        </thead>
        <tbody>{this.renderTransactions(transactions, onRowClick)}</tbody>
      </Table>
    );
  }
}

TransactionsTable.propTypes = {
  t: PropTypes.func.isRequired,
  transactions: PropTypes.arrayOf(PropTypes.object).isRequired,
  onRowClick: PropTypes.func.isRequired,
};

TransactionsTable.defaultProps = {};

export default translate('common')(TransactionsTable);
