import { SET_TOKEN, SET_TOKEN_FETCH_ERROR, LOGOUT, SET_USER } from '../reducers/userReducer';
import { setItem, removeItem } from '../../helpers/cookies';

export function setToken(token) {
  setItem('jwt', token);
  return {
    type: SET_TOKEN,
    token,
  };
}

export function setTokenFetchError(msg) {
  removeItem('jwt');
  return {
    type: SET_TOKEN_FETCH_ERROR,
    error: msg,
  };
}

export function logout() {
  removeItem('jwt');
  return {
    type: LOGOUT,
  };
}

export function setUser(user) {
  return {
    type: SET_USER,
    user,
  };
}
