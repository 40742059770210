import { API_LINK, CONFIG } from 'constants/config';
import { POST, GET, PATCH } from 'helpers/agent';
import stable from 'stable';

let SCHEDULE = [];
const POSTING_HOURS = false;
let SHOULD_UPDATE = false;

const lexCmpName = (a, b) => CONFIG.COLLATOR.compare(a.name, b.name);
const lexCmpSurname = (a, b) => CONFIG.COLLATOR.compare(a.surname, b.surname);
// const byName = stable(employees, lexCmpName);
// const bySurname = stable(byName, lexCmpSurname);

const addToSchedule = body => {
  SHOULD_UPDATE = false;
  const parsedBody = JSON.parse(body);
  if (parsedBody.hours === '' && parsedBody.nightHours === '') {
    parsedBody.hours = '0';
    parsedBody.nightHours = '0';
  }

  if (!SCHEDULE.includes(body)) {
    console.log('body', body);
    let shouldAdd = true;
    for (let i = 0; i < SCHEDULE.length; i += 1) {
      const parsedCall = JSON.parse(SCHEDULE[i]);
      if (
        parsedBody.date === parsedCall.date &&
        parsedBody.workerId === parsedCall.workerId &&
        /* parsedBody.ordererId === parsedCall.ordererId &&
        parsedBody.worksiteId === parsedCall.worksiteId &&
        parsedBody.selfReported === parsedCall.selfReported &&
        parsedBody.copy === parsedCall.copy && */
        parsedBody.transfer === parsedCall.transfer
      ) {
        console.log('NAŠEL TAKI CALL, ZATO UPDEJTAM');
        parsedCall.hours = parsedBody.hours;
        parsedCall.nightHours = parsedBody.nightHours;
        parsedCall.notes = parsedBody.notes;
        parsedCall.ordererId = parsedBody.ordererId;
        parsedCall.worksiteId = parsedBody.worksiteId;
        SCHEDULE[i] = JSON.stringify(parsedCall);
        shouldAdd = false;
      }
    }

    if (shouldAdd) {
      console.log('NI ZA UPDEJTAT, SAMO PUSHAM');
      SCHEDULE.push(JSON.stringify(parsedBody));
    }
  } else {
    console.log('BLOKIRAM DVOJNI CALL ADD HOURS!!');
  }

  console.log('CURRENT SCHEDULE', SCHEDULE);
};

// const makeCalls = async () => {
//   if (SCHEDULE.length > 0) {
//     SHOULD_UPDATE = false;
//     console.log('KLIČEM ADDHOURS');
//     const body = SCHEDULE.shift();
//     await POST(`${API_LINK}/wh/addhours`, body);
//     makeCalls();
//   } else {
//     console.log('KONČAL Z makeCalls');
//     POSTING_HOURS = false;
//     SHOULD_UPDATE = true;
//   }
// };

/* eslint-disable */
/* const _makeCalls = async () => {
  if (SCHEDULE.length === 0) {
    return;
  }

  for (const CALL of SCHEDULE) {
    console.log('KLIČEM ADDHOURS');
    // const body = SCHEDULE.shift();
    await POST(`${API_LINK}/wh/addhours`, CALL);
  }

  SCHEDULE = [];
  return;
}; */
/* eslint-enable */

function makeCall(call) {
  return new Promise((resolve, reject) => {
    POST(`${API_LINK}/wh/addhours`, call).then(res => {
      if (res.success) {
        resolve('success');
      } else {
        reject(res.error);
      }
    });
  });
}

function makeCalls() {
  return SCHEDULE.reduce(
    (promise, item) =>
      promise
        .then(result => {
          // help you to understand what's result
          console.log(`Kličem z payload ${item}`);
          return makeCall(item).then(response => {
            // print result and item
            console.log(`Dobil rezultat ${response}, item ${item}`);
            // add a new return value
            return `${result} Done`;
          });
        })
        .catch(console.error),
    Promise.resolve(),
  );
}

// const POST_HOURS_CHECKER = setInterval(() => {
//   if (SCHEDULE.length > 0 && !POSTING_HOURS) {
//     // clearInterval(POST_HOURS_CHECKER);
//     POSTING_HOURS = true;
//     SHOULD_UPDATE = false;
//     makeCalls();
//   }
//   // console.log('ne kličem');
// }, 100);

export default {
  getbyworksite: async (data, addWorker = null) => {
    // console.log('TCL: data', data);
    // console.log('getbyworksite', addWorker);

    const response = await POST(`${API_LINK}/wh/getbyworksite`, JSON.stringify(data));
    if (response.success) {
      const workers = JSON.parse(JSON.stringify(response.workers));
      // console.log('TCL: workers', workers);

      // if (addWorker) {
      //   /* console.log('workers', workers);
      //   console.log('pusham workera', addWorker); */
      //   workers.push(addWorker);
      //   // console.log('workers 2', workers);
      // }

      const byName = stable(workers, lexCmpName);
      const bySurname = stable(byName, lexCmpSurname);

      const dailySumsIndex = bySurname.findIndex(e => e._id === 'sums');
      if (dailySumsIndex > -1) {
        const dailySums = bySurname[dailySumsIndex];
        bySurname.splice(dailySumsIndex, 1);
        bySurname.push(dailySums);
      }
      // console.log('TCL: bySurname', bySurname);
      return {
        workers: bySurname,
        whAllOWs: response.whAllOWs,
        owId: response.owId,
      };
    }
    return { workers: [] };
  },
  getbyworker: async data => {
    const response = await POST(`${API_LINK}/workhours/employee`, JSON.stringify(data));

    if (response) {
      return { workdays: response };
    }
    return { workdays: [] };
  },
  addhours: data => {
    /* const response = await POST(`${API_LINK}/wh/addhours`, JSON.stringify(data));

    if (response.success) {
      return { msg: response.msg };
    }
    return { msg: '' }; */
    // console.log('DOBIL ADD HOURS');
    addToSchedule(JSON.stringify(data));
  },
  calculateOrdererHours: async data => {
    const response = await POST(`${API_LINK}/wh/calculateOrdererHours`, JSON.stringify(data));

    if (response.success) {
      return { orderers: response.orderers };
    }
    return { orderers: [] };
  },
  calculateWorkerHours: async data => {
    const response = await POST(`${API_LINK}/wh/calculateWorkerHours`, JSON.stringify(data));

    if (response.success) {
      return { workers: response.workers, workersAll: response.workers };
    }
    return { workers: [], workersAll: [] };
  },
  calculateWorklist: async data => {
    const response = await POST(`${API_LINK}/wh/calculateWorklist`, JSON.stringify(data));

    if (response.success) {
      return { worklist: response.worklist };
    }
    return { worklist: [] };
  },
  calculateFullWorklist: async data => {
    const response = await POST(`${API_LINK}/wh/calculateFullWorklist`, JSON.stringify(data));

    if (response.success) {
      return { fullworklist: response.worklist };
    }
    return { fullworklist: [] };
  },
  calculateBrankaList: async data => {
    const response = await POST(`${API_LINK}/wh/calculateBrankaList`, JSON.stringify(data));

    if (response.success) {
      return { brankalist: response.list };
    }
    return { brankalist: [] };
  },
  calculateOWList: async data => {
    const response = await POST(`${API_LINK}/wh/calculateOWList`, JSON.stringify(data));

    if (response.success) {
      return { owlist: response.list };
    }
    return { owlist: [] };
  },
  calculatePaycheckList: async data => {
    const response = await POST(`${API_LINK}/wh/calculatePaycheckList`, JSON.stringify(data));

    if (response.success) {
      return { paycheckList: response.list };
    }
    return { paycheckList: [] };
  },
  shouldUpdateTable: () => {
    if (SHOULD_UPDATE && !POSTING_HOURS) {
      SHOULD_UPDATE = false;
      return true;
    }
    return false;
  },
  postHours: async () => {
    console.log('GREM POSTAT URE:::');
    await makeCalls();
    console.log('URE POSTANE, CLEARAM SCHEDULE:::');
    SCHEDULE = [];
    return true;
  },
  clearSchedule: () => {
    SCHEDULE = [];
  },
  getWorkdaysForEmployee: async token => {
    const response = await GET(`${API_LINK}/wh/employee?token=${token}`);
    if (response && response.success) {
      return response.data;
    }
    return null;
  },
  updateWorkdayForEmployee: async (token, id, data) => {
    const response = await PATCH(
      `${API_LINK}/wh/employee`,
      JSON.stringify({
        token,
        id,
        ...data,
      }),
    );
    if (response && response.success) {
      return response.data;
    }
    return null;
  },
};
