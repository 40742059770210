export default function fix(number) {
  if (number === undefined || !number) return 0;
  return parseFloat(number.toFixed(2));
}

export function commify(number) {
  // https://www.youtube.com/watch?v=AOAtz8xWM0w&t=3s
  if (!number) return number;
  const commified = number.toString().replace('.', ',');
  return commified;
}

export function dottify(number) {
  if (!number) return '';
  let _number = number;
  const dotIndex = _number.indexOf('.');
  const commaIndex = _number.indexOf(',');

  if (dotIndex >= 0 && commaIndex >= 0) {
    _number = number.substring(0, dotIndex) + number.substring(dotIndex + 1, number.length);
  }

  const dottified = _number.toString().replace(',', '.');
  return dottified;
}
