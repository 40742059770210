import React from 'react';
import PropTypes from 'prop-types';
import getDaysInMonth from 'helpers/getDaysInMonth';
import ReactExport from 'react-data-export';
import { Button } from 'reactstrap';
import moment from 'moment-timezone';
import fix from 'helpers/floatParser';
import { TIMEZONE } from 'constants/config';
import { translate } from 'react-i18next';

/* eslint-disable */
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
/* eslint-enable */

const DownloadButton = title => (
  <Button size="sm" color="primary" style={{ marginBottom: '-30px', zIndex: '100' }}>
    {title}
  </Button>
);

const isWeekend = day => {
  const dayNum = new Date(day).getDay();
  return dayNum === 6 || dayNum === 0;
};

const workerStyle = (isLastRow, whichElement, fontStyle = { bold: false, sz: '8' }, weekend) => {
  const { bold, sz } = fontStyle;
  let border = {};
  if (!isLastRow) {
    if (whichElement === 'first') {
      border = {
        top: {
          style: 'thin',
          color: {
            rgb: '000000',
          },
        },
        bottom: {
          style: 'thin',
          color: {
            rgb: '000000',
          },
        },
        left: {
          style: 'medium',
          color: {
            rgb: '000000',
          },
        },
        right: {
          style: 'thin',
          color: {
            rgb: '000000',
          },
        },
      };
    } else if (whichElement === 'last') {
      border = {
        top: {
          style: 'thin',
          color: {
            rgb: '000000',
          },
        },
        bottom: {
          style: 'thin',
          color: {
            rgb: '000000',
          },
        },
        left: {
          style: 'thin',
          color: {
            rgb: '000000',
          },
        },
        right: {
          style: 'medium',
          color: {
            rgb: '000000',
          },
        },
      };
    } else {
      border = {
        top: {
          style: 'thin',
          color: {
            rgb: '000000',
          },
        },
        bottom: {
          style: 'thin',
          color: {
            rgb: '000000',
          },
        },
        left: {
          style: 'thin',
          color: {
            rgb: '000000',
          },
        },
        right: {
          style: 'thin',
          color: {
            rgb: '000000',
          },
        },
      };
    }
  } else if (whichElement === 'first') {
    border = {
      top: {
        style: 'thin',
        color: {
          rgb: '000000',
        },
      },
      bottom: {
        style: 'medium',
        color: {
          rgb: '000000',
        },
      },
      left: {
        style: 'medium',
        color: {
          rgb: '000000',
        },
      },
      right: {
        style: 'thin',
        color: {
          rgb: '000000',
        },
      },
    };
  } else if (whichElement === 'last') {
    border = {
      top: {
        style: 'thin',
        color: {
          rgb: '000000',
        },
      },
      bottom: {
        style: 'medium',
        color: {
          rgb: '000000',
        },
      },
      left: {
        style: 'thin',
        color: {
          rgb: '000000',
        },
      },
      right: {
        style: 'medium',
        color: {
          rgb: '000000',
        },
      },
    };
  } else {
    border = {
      top: {
        style: 'thin',
        color: {
          rgb: '000000',
        },
      },
      bottom: {
        style: 'medium',
        color: {
          rgb: '000000',
        },
      },
      left: {
        style: 'thin',
        color: {
          rgb: '000000',
        },
      },
      right: {
        style: 'thin',
        color: {
          rgb: '000000',
        },
      },
    };
  }

  const theStyle = {
    font: {
      name: 'Arial',
      sz,
      bold,
    },
    height: { hpx: !isLastRow ? 36 : 100 },
    alignment: {
      vertical: 'center',
      horizontal: 'center',
      wrapText: true,
    },
    border,
  };

  if (weekend) {
    theStyle.fill = {
      patternType: 'solid',
      fgColor: { rgb: 'C0C0C0' },
    };
  }

  return theStyle;
};

/* const headerStyle = () => {

  return {
    font: {
      name: 'Arial',
      sz: '8',
      bold: false,
    },
    height: { hpx: 36 },
    alignment: {
      vertical: 'center',
      horizontal: 'center',
      wrapText: true,
    },
    border,
  }
} */

const generateHeaders = (month, year) => {
  const daysInMonths = getDaysInMonth(month, year);
  const firstPart = [
    {
      title: '',
      width: { wpx: 200 },
    },
    {
      title: '',
      width: { wpx: 200 },
    },
  ];
  const daysPart = daysInMonths.map(() => ({
    title: '',
    width: { wpx: 45 },
  }));
  const lastPart = [
    {
      title: '',
      width: { wpx: 75 },
    },
  ];
  const columns = firstPart.concat(daysPart.concat(lastPart));
  const multiDataSet = [
    {
      columns,
      data: [],
    },
  ];
  return multiDataSet;
};

const calcTotals = (workdays, transfers) => {
  let totalDay = {};
  let totalNight = {};
  /* et totalTransferDay = {}
    let totalTransferNight = {} */
  try {
    totalDay = workdays.reduce((a, b) => ({
      dayHours: a.dayHours + b.dayHours,
    }));
    totalNight = workdays.reduce((a, b) => ({
      nightHours: a.nightHours + b.nightHours,
    }));
  } catch (e) {
    totalDay.dayHours = 0;
    totalNight.nightHours = 0;
  }

  return {
    totalDay: fix(totalDay.dayHours),
    totalNight: fix(totalNight.nightHours),
    totalTransferDay: fix(transfers.daySum),
    totalTransferNight: fix(transfers.nightSum),
    total: fix(totalDay.dayHours + totalNight.nightHours + transfers.daySum + transfers.nightSum),
  };
};

const generateData = (dataAll, month, year, OWTitle, companyTitle, tag) => {
  const allCompanies = companyTitle.toUpperCase() === 'VSA PODJETJA';
  const daysInMonths = getDaysInMonth(month, year);
  const data = [];

  let titleRow = [
    {},
    {
      value: OWTitle,
    },
  ];
  let titledaysPart = daysInMonths.map(() => ({}));
  let titleLast = [{}, {}, {}, {}];
  data.push(titleRow.concat(titledaysPart.concat(titleLast)));
  titleRow = [
    {},
    {
      value: allCompanies ? '' : companyTitle,
    },
  ];
  titledaysPart = daysInMonths.map(() => ({}));
  titleLast = [{}, {}, {}, {}];
  data.push(titleRow.concat(titledaysPart.concat(titleLast)));
  titleRow = [{}, {}];
  titledaysPart = daysInMonths.map(() => ({}));
  titleLast = [{}, {}, {}, {}];
  data.push(titleRow.concat(titledaysPart.concat(titleLast)));

  const firstPart = [
    {
      value: tag === 'normal' ? 'Arbeiter' : '/',
      style: workerStyle(false, 'first', { bold: true, sz: '12' }),
    },
    {
      value: 'Firma',
      style: workerStyle(false, 'mid', { bold: true, sz: '12' }),
    },
  ];
  const daysPart = daysInMonths.map(day => ({
    value:
      tag === 'normal'
        ? `${moment(day)
            .tz(TIMEZONE)
            .format('ddd')} ${moment(day)
            .tz(TIMEZONE)
            .format('D.M.YY')}`
        : '/',
    style: workerStyle(false, 'mid', { bold: true, sz: '9' }, isWeekend(day)),
  }));
  const lastPart = [
    {
      value: 'Gesamt Stunden',
      style: workerStyle(false, 'last', { bold: true, sz: '12' }),
    },
  ];
  data.push(firstPart.concat(daysPart.concat(lastPart)));

  for (let i = 0; i < dataAll.length; i += 1) {
    const isLastRow = i === dataAll.length - 1;
    const ow = dataAll[i];
    const workerName = ow.label;
    const owRow = [
      {
        value: !isLastRow ? workerName : 'Gesamt',
        style: workerStyle(isLastRow, 'first', { bold: isLastRow, sz: '12' }),
      },
      {
        value: ow.fullCompanyName || '',
        style: workerStyle(isLastRow, 'mid', { bold: isLastRow, sz: '12' }),
      },
    ];

    const totals = calcTotals(ow.workdays, ow.transfers);

    for (let j = 0; j < daysInMonths.length; j += 1) {
      let workday = ow.workdays.find(w => w.date === daysInMonths[j].getTime());
      if (!workday) {
        workday = {
          dayHours: 0,
          nightHours: 0,
        };
      }
      const whNumber = Number(fix(workday.dayHours + workday.nightHours));
      const wHour = whNumber === 0 ? '' : whNumber;
      owRow.push({
        value: wHour,
        style: workerStyle(isLastRow, 'mid', { bold: isLastRow, sz: '12' }, isWeekend(daysInMonths[j].getTime())),
      });
    }

    owRow.push({
      value: Number(fix(totals.total)),
      style: workerStyle(isLastRow, 'mid', { bold: true, sz: '12' }),
    });

    data.push(owRow);
  }
  return data;
};
class SingleOWSheet extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  createSheets = () => {
    const { data, OWTitle, companyTitle, t } = this.props;
    return data.map(elt => {
      const { month, year } = elt;
      const tag = elt.tag || 'normal';
      const headers = generateHeaders(Number(month), Number(year));
      headers[0].data = generateData(elt.workers, Number(month), Number(year), OWTitle, companyTitle, tag);
      const name = tag === 'normal' ? `${t('calcs.owpairs')}-${month}-${year}` : 'SKUPNO';
      return <ExcelSheet dataSet={headers} name={name} />;
    });
  };

  render() {
    const { btn, t } = this.props;

    return (
      <ExcelFile element={DownloadButton(btn)} filename={t('calcs.owpairs')}>
        {this.createSheets()}
      </ExcelFile>
    );
  }
}

SingleOWSheet.propTypes = {
  btn: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  // month: PropTypes.number.isRequired,
  // year: PropTypes.number.isRequired,
  OWTitle: PropTypes.string.isRequired,
  companyTitle: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
};

export default translate('common')(SingleOWSheet);
