import store from 'containers/App/store';
// API
import employeesApi from './api/employees';
import worksitesApi from './api/worksites';
import vehiclesApi from './api/vehicles';
import orderersApi from './api/orderers';
import accommodationsApi from './api/accommodations';
import companiesApi from './api/companies';
import countriesApi from './api/countries';
import professionsApi from './api/professions';
import docsApi from './api/docs';
import cashboxApi from './api/cashbox';

// REDUX
import {
  changeAppLoadingStat,
  changeAppLoadedStat,
  setAppLoadingStatus,
  setAppUpdatingStatus,
} from './redux/actions/appActions';
import { setEmployees } from './redux/actions/employeesActions';
import { setOws } from './redux/actions/owActions';
import { setWorksites } from './redux/actions/worksitesActions';
import { setVehicles } from './redux/actions/vehiclesActions';
import { setOrderers, setOrderersTitlesList } from './redux/actions/orderersActions';
import { setAccommodations, setAccommodationProviders } from './redux/actions/accommodationsActions';
import { setCompanies, setCompaniesTitlesList } from './redux/actions/companiesActions';
import {
  setProfessionsDataList,
  setCountriesDataList,
  setDocsExpDataList,
  setTransactionSourcesDataList,
  setTransactionCategoriesDataList,
} from './redux/actions/dataListActions';
import { CONFIG } from './constants/config';

// MISC
const GO_DUMMY = false;

/* eslint-disable */
const DOWNLOAD_CALLS = [
  () => {
    if (!GO_DUMMY) downloadEmployees();
    else dummy();
  },
  () => {
    if (!GO_DUMMY) downloadWorksites();
    else dummy();
  },
  () => {
    if (!GO_DUMMY) downloadVehicles();
    else dummy();
  },
  () => {
    if (!GO_DUMMY) downloadOrderers();
    else dummy();
  },
  () => {
    if (!GO_DUMMY) downloadAccommodations();
    else dummy();
  },
  () => {
    if (!GO_DUMMY) downloadCompanies();
    else dummy();
  },
  () => {
    if (!GO_DUMMY) downloadDataList();
    else dummy();
  },
];
/* eslint-enable */

let DONE_COUNTER = DOWNLOAD_CALLS.length;

const checkLoaded = (caller = 'DUMMY', update = false) => {
  if (!update) {
    const status = `Nalagam ${caller}`;
    store.dispatch(setAppLoadingStatus(status));
    DONE_COUNTER -= 1;
    if (DONE_COUNTER === 0) {
      DONE_COUNTER = DOWNLOAD_CALLS.length;
      store.dispatch(setAppLoadingStatus('Aplikacija je pripravljena&&Dobrodošli v HURES'));
      setTimeout(() => {
        store.dispatch(changeAppLoadedStat(true));
      }, 2000);
    }
  } else {
    store.dispatch(setAppUpdatingStatus(false, ''));
  }
};

const dummy = () => {
  setTimeout(() => {
    checkLoaded();
  }, 500);
};

const downloadEmployees = (update = false) => {
  console.log('DOWNLOADAM EMPLOYEESE');
  Promise.all([employeesApi.getall()]).then(responses => {
    responses.forEach(res => {
      if (res.employees) {
        store.dispatch(setEmployees(res.employees));
      }
    });
    checkLoaded('delavce', update);
  });
};

const downloadWorksites = (update = false) => {
  console.log('DOWNLOADAM GRADBIŠČA');
  Promise.all([worksitesApi.getall()]).then(responses => {
    console.log('TCL: downloadWorksites -> responses', responses);
    responses.forEach(res => {
      if (res.worksites /*  && res.worksitesWithAddress && res.titlesWorksites */) {
        store.dispatch(setWorksites(res.worksites));
        /* store.dispatch(setWorksitesTitles(res.titlesWorksites));
        store.dispatch(setWorksitesTitlesWithAddress(res.worksitesWithAddress)); */
      }
    });
    checkLoaded('gradbišča', update);
  });
};

const downloadVehicles = (update = false) => {
  console.log('DOWNLOADAM VOZILA');
  Promise.all([vehiclesApi.getall()]).then(responses => {
    // console.log('TCL: downloadVehicles -> responses', responses);
    responses.forEach(res => {
      if (res.vehicles) {
        store.dispatch(setVehicles(res.vehicles));
      }
    });
    checkLoaded('vozila', update);
  });
};

const downloadOrderers = (update = false) => {
  console.log('DOWNLOADAM NAROČNIKE');
  Promise.all([orderersApi.getall()]).then(responses => {
    // console.log('TCL: downloadVehicles -> responses', responses);
    responses.forEach(res => {
      if (res.orderers) {
        store.dispatch(setOrderers(res.orderers));
        store.dispatch(setOrderersTitlesList(res.orderers));
      }
    });
    checkLoaded('naročnike', update);
  });
};

const downloadAccommodations = (update = false) => {
  console.log('DOWNLOADAM PRENOČIŠČA');
  Promise.all([accommodationsApi.getall(), accommodationsApi.getproviders()]).then(responses => {
    // console.log('TCL: downloadVehicles -> responses', responses);
    responses.forEach(res => {
      if (res.accommodations /*  && res.accommodationTitles && res.providerTitles */) {
        store.dispatch(setAccommodations(res.accommodations));
        /* store.dispatch(setAccommodationsTitlesList(res.accommodationTitles));
        store.dispatch(setAccommodationProvidersTitlesList(res.providerTitles)); */
      } else if (res.providers) {
        store.dispatch(setAccommodationProviders(res.providers));
      }
    });
    checkLoaded('prenočišča', update);
  });
};

const downloadCompanies = (update = false) => {
  console.log('DOWNLOADAM PODJETJA');
  Promise.all([companiesApi.getfull()]).then(responses => {
    // console.log('TCL: downloadVehicles -> responses', responses);
    responses.forEach(res => {
      if (res.companies) {
        store.dispatch(setCompanies(res.companies));
        store.dispatch(setCompaniesTitlesList(res.companies));
      }
    });
    checkLoaded('podjetja', update);
  });
};

const downloadDataList = (update = false) => {
  console.log('DOWNLOADAM OSTALO');
  Promise.all([
    professionsApi.getall(),
    countriesApi.getall(),
    docsApi.getAll(),
    cashboxApi.getTransactionSources(),
    cashboxApi.getTransactionCategories(),
  ]).then(responses => {
    responses.forEach(res => {
      if (res.professions) {
        store.dispatch(setProfessionsDataList(res.professions));
      } else if (res.countries) {
        store.dispatch(setCountriesDataList(res.countries));
      } else if (res.docsData) {
        store.dispatch(setDocsExpDataList(res.docsData));
      } else if (res.transactionSources) {
        store.dispatch(setTransactionSourcesDataList(res.transactionSources));
      } else if (res.transactionCategories) {
        store.dispatch(setTransactionCategoriesDataList(res.transactionCategories));
      }
    });
    checkLoaded('ostalo', update);
  });
};

const download = () => {
  for (let i = 0; i < DOWNLOAD_CALLS.length; i += 1) {
    DOWNLOAD_CALLS[i]();
  }
};

// downloadamo vse potrebne podatke
export function downloadAllRequiredData() {
  store.dispatch(changeAppLoadingStat(true));
  DONE_COUNTER = DOWNLOAD_CALLS.length;
  download();
}

// ko hočemo updejtat neki state direktno iz serverja
export function updateRequiredState(requiredUpdate) {
  const status = 'Posodabljam...';
  store.dispatch(setAppUpdatingStatus(true, status));
  switch (requiredUpdate) {
    case CONFIG.states.workers:
      downloadEmployees(true);
      break;

    default:
      // downloadEmployees(true);
      break;
  }
  /* setTimeout(() => {
    store.dispatch(setAppUpdatingStatus(false, ''));
  }, 10000); */
}

// ko hočemo samo posodobit state lokalno
export function updateStateFromLocal(localState, action) {
  const status = 'Posodabljam...';
  store.dispatch(setAppUpdatingStatus(true, status));
  console.log('TCL: updateStateFromLocal -> action', action);
  switch (action) {
    case CONFIG.states.workers:
      store.dispatch(setEmployees(localState.employees));
      break;
    case CONFIG.states.companies:
      store.dispatch(setCompanies(localState.companies));
      store.dispatch(setCompaniesTitlesList(localState.companies));
      break;
    case CONFIG.states.orderers:
      store.dispatch(setOrderers(localState.orderers));
      store.dispatch(setOrderersTitlesList(localState.orderers));
      break;
    case CONFIG.states.worksites:
      store.dispatch(setWorksites(localState.worksites));
      break;
    case CONFIG.states.accommodations:
      store.dispatch(setAccommodations(localState.accommodations));
      break;
    case CONFIG.states.providers:
      store.dispatch(setAccommodationProviders(localState.providers));
      break;
    case CONFIG.states.vehicles:
      store.dispatch(setVehicles(localState.vehicles));
      break;
    case CONFIG.states.ows:
      store.dispatch(setOws(localState.ows));
      break;
    default:
      break;
  }

  setTimeout(() => {
    store.dispatch(setAppUpdatingStatus(false, ''));
  }, 300);
}
