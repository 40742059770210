import React from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-i18next';
import { Card, CardBody, Col, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import classnames from 'classnames';

class Tabs extends React.PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    onTabChange: PropTypes.func.isRequired,
    defaultTab: PropTypes.string.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = { activeTab: props.defaultTab };
  }

  toggle = tab => {
    const { activeTab } = this.state;
    if (activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
      this.props.onTabChange(tab);
    }
  };

  render() {
    const { t } = this.props;
    const { activeTab } = this.state;

    return (
      <div className="tabs  tabs--bordered-bottom mb-3">
        <div className="tabs__wrap">
          <Nav tabs>
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === '1' })}
                onClick={() => {
                  this.toggle('1');
                }}
              >
                <strong>{t('finance.tabs.satnice')}</strong>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === '2' })}
                onClick={() => {
                  this.toggle('2');
                }}
              >
                <strong>{t('finance.tabs.overview')}</strong>
              </NavLink>
            </NavItem>
            {/* <NavItem>
              <NavLink
                className={classnames({ active: activeTab === '3' })}
                onClick={() => {
                  this.toggle('3');
                }}
              >
                <strong>{t('finance.tabs.calc')}</strong>
              </NavLink>
            </NavItem> */}
          </Nav>
        </div>
      </div>
    );
  }
}

export default translate('common')(Tabs);
