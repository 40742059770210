import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-i18next';
import countries from 'i18n-iso-countries';
import moment from 'moment-timezone';
import { Table, Card, CardBody, Badge } from 'reactstrap';
import shortid from 'shortid';
import { TIMEZONE } from '../../../../constants/config';

class PayAnaTable extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  renderTableBody() {
    const { data, year, month, t } = this.props;

    return data.map((element, index) => {
      let style = {};

      if (element.unregisteredAt !== '') {
        const lastDayOfMonth = moment()
          .set({ year, month: month - 1, date: 1 })
          .endOf('month')
          .startOf('day')
          .tz(TIMEZONE)
          .toDate();
        const unReg = moment(element.unregisteredAt, 'DD. MM. YYYY')
          .tz(TIMEZONE)
          .toDate();

        if (unReg <= lastDayOfMonth) {
          style = { color: 'red' };
        }
      }
      return (
        <Fragment key={shortid.generate()}>
          <tr key={shortid.generate()}>
            <td>{index + 1}</td>
            <td>
              <Badge
                style={{
                  backgroundColor: `${element.color}`,
                  color: '#646777',
                  fontSize: 'medium',
                }}
              >
                {`${element.surname.toUpperCase()} ${element.name}`}
              </Badge>
            </td>
            <td>{element.registeredAt}</td>
            <td>
              <span style={style}>{element.unregisteredAt}</span>
            </td>
            <td />
            <td />
            <td />
          </tr>
          {this.renderDocs(element.docsList.concat(element.owList))}
          {this.renderAssignments(element.docsList.concat(element.assList), t)}
          {this.renderOWs(element.docsList.concat(element.owList), t)}
          {this.renderEmpty(t)}
          {this.renderInsuranceList(element.docsList.concat(element.owList))}
        </Fragment>
      );
    });
  }

  renderEmpty(t) {
    return (
      <tr key={shortid.generate()}>
        <td />
        <td>
          <strong>{t('form.base').toUpperCase()}</strong>
        </td>
        <td />
        <td />
        <td />
        <td />
        <td />
      </tr>
    );
  }

  renderInsuranceList(list) {
    return list.map(element => {
      if (element.type === 'insurance') {
        let style = element.num === '001' ? { backgroundColor: '#F7C4A3' } : { backgroundColor: '#B5D1EC' };
        if (element.missing) {
          style = { backgroundColor: 'lightgray' };
        }
        return (
          <tr key={shortid.generate()} style={style}>
            <td />
            <td />
            <td className="strong">{element.from}</td>
            <td className="strong">{element.to}</td>
            <td className="strong">{element.num}</td>
            {element.worksite ? <td className="strong">{element.worksite}</td> : <td />}
            {element.country ? <td className="strong">{element.country.toUpperCase()}</td> : <td />}
          </tr>
        );
      }
      return null;
    });
  }

  renderOWs(list, t) {
    return list.map(element => {
      if (element.type === 'OW') {
        return (
          <tr key={shortid.generate()}>
            <td />
            <td />
            <td className="strong">{element.from}</td>
            <td className="strong">{element.to}</td>
            <td>
              <strong style={{ color: 'forestgreen' }}>{t('calcs.work')}</strong>
            </td>
            <td className="strong">{`${element.ordererTitle}: ${element.worksiteTitle}`}</td>
            <td />
          </tr>
        );
      }
      return null;
    });
  }

  renderAssignments(list, t) {
    return list.map(element => {
      if (element.type === 'Ass') {
        return (
          <tr key={shortid.generate()}>
            <td />
            <td />
            <td className="strong">{element.from}</td>
            <td className="strong">{element.to}</td>
            <td>
              <strong style={{ color: 'orange' }}>{t('sidebar_content.assignments').slice(0, 3)}.</strong>
            </td>
            <td className="strong">{`${element.ordererTitle}: ${element.worksiteTitle}`}</td>
            <td />
          </tr>
        );
      }
      return null;
    });
  }

  renderDocs(list) {
    const lang = localStorage.getItem('lang') || 'sl';
    return list.map(element => {
      if (element.type === 'a1') {
        const style = element.expired ? { backgroundColor: 'lightgray' } : {};
        return (
          <tr key={shortid.generate()} style={style}>
            <td />
            <td />
            <td className="strong">{element.from}</td>
            <td className="strong">{element.to}</td>
            <td>
              <strong style={{ color: 'royalblue' }}>A1</strong>
            </td>
            <td className="strong" style={{ color: '#652B96' }}>
              {element.worksite}
            </td>
            <td className="strong">
              {element.countryId ? countries.getName(element.countryId, lang).toUpperCase() : ''}
            </td>
          </tr>
        );
      }
      return null;
    });
  }

  render() {
    const { data, title, t } = this.props;
    if (!data) return '';
    return (
      <Card>
        <CardBody>
          <div className="card__title">
            <h5 className="subhead">{t('calcs.analysis')}</h5>
            <h5 className="bold-text">
              <span style={{ fontSize: '20px' }}>{title}</span>
            </h5>
          </div>
          <Table className="izpis-table analy-table">
            <thead>
              <tr>
                <td>#</td>
                <th>{t('board.ow.worker')}</th>
                <td>{t('form.from').toUpperCase()}</td>
                <td>{t('form.to').toUpperCase()}</td>
                <td />
                <td>{t('board.filters.worksite')}</td>
                <td>{t('form.country')}</td>
              </tr>
            </thead>
            <tbody>{this.renderTableBody()}</tbody>
          </Table>
        </CardBody>
      </Card>
    );
  }
}

PayAnaTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  title: PropTypes.string.isRequired,
  month: PropTypes.number.isRequired,
  year: PropTypes.number.isRequired,
  t: PropTypes.func.isRequired,
};

export default translate('common')(PayAnaTable);
