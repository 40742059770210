// import moment from 'moment-timezone';
import React, { Component } from 'react';
import moment from 'moment-timezone';
import shortid from 'shortid';
import ReactGA from 'react-ga';
import stable from 'stable';
import PropTypes from 'prop-types';
import { translate } from 'react-i18next';
import { Col, Container, Row, ButtonToolbar, Card, CardBody, Progress } from 'reactstrap';
import NotificationSystem from 'rc-notification';
import { connect } from 'react-redux';
import SelectDropdown2 from 'shared/components/SelectDropdown2';
import { API_LINK, TIMEZONE, CONFIG } from '../../constants/config';
import DelavciTable from './components/DelavciTable';
import DelavciModalComponent from '../Boards/components/Modals/EmployeesFormModal';
import DelavciModal from './components/DelavciModal_old';
import { BasicNotification } from '../../shared/components/Notification';
import SelectDropdown from '../../shared/components/SelectDropdown';
import SelectInterval from '../../shared/components/SelectInterval';
import { POST } from '../../helpers/agent';
import getRoles from '../../constants/roles';
import {
  EmployeesProps,
  CompaniesProps,
  DataListProps,
  WorksitesProps,
  AppStatusProps,
} from '../../shared/prop-types/ReducerProps';

import DelavciSheet from './components/DelavciSheet';
import RUNotification from '../../shared/components/RUNotification';

const API_EMPLOYEES_GET_ONE = '/e/getOneById';

let notificationLU = null;
let notificationRU = null;
let notificationTC = null;
let neprijavljenComId = null;

const showNotification = ({ notification, position }) => {
  switch (position) {
    case 'left-up':
      notificationLU.notice({
        content: notification,
        duration: 5,
        closable: true,
        style: { top: 0, left: 240 },
        className: position,
      });
      break;
    case 'right-up':
      notificationRU.notice({
        content: notification,
        duration: 5,
        closable: true,
        style: { top: 0, left: 'calc(100vw - 100%)' },
        className: position,
      });
      break;
    default:
      notificationTC.notice({
        content: notification,
        duration: 5,
        closable: true,
        style: { top: 0, left: 0 },
        className: position,
      });
      break;
  }
};

const tmzfy = date =>
  moment(date)
    .tz(TIMEZONE)
    .toDate();

const formatify = date =>
  date
    ? moment(date)
        .tz(TIMEZONE)
        .format('DD.MM.YYYY')
    : '';

class DelavciPage extends Component {
  static propTypes = {
    workersState: EmployeesProps.isRequired,
    companiesState: CompaniesProps.isRequired,
    dataListState: DataListProps.isRequired,
    worksitesState: WorksitesProps.isRequired,
    appStatus: AppStatusProps.isRequired,
    t: PropTypes.func.isRequired,
  };

  constructor() {
    super();

    this.state = {
      employees: [],
      employeesFull: [],
      companies: [],
      professions: [],
      countries: [],
      worksites: [],
      showEdit: false,
      people: [],
      progressNum: 0,
      editEmployee: null,
      selectedEmployee: null,
      selectedCompany: null,
      selectedInterval: null,
      ROLES: getRoles(),
    };

    this.onRowSelect = this.onRowSelect.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.showNotif = this.showNotif.bind(this);
    this.fetchData = this.fetchData.bind(this);
    this.handleProgress = this.handleProgress.bind(this);
    this.handleDropdownSubmit = this.handleDropdownSubmit.bind(this);
    this.handleCompaniesSubmit = this.handleCompaniesSubmit.bind(this);
    this.handleIntervalSubmit = this.handleIntervalSubmit.bind(this);
    this.applyFilters = this.applyFilters.bind(this);
  }

  _notif;

  componentDidMount() {
    this._notif = new RUNotification();
    this._mounted = true;
    NotificationSystem.newInstance({}, n => (notificationLU = n));
    NotificationSystem.newInstance({}, n => (notificationRU = n));
    NotificationSystem.newInstance({}, n => (notificationTC = n));

    const { appStatus } = this.props;
    if (appStatus.requiredDataLoaded) {
      this.fetchData();
    }
  }

  componentDidUpdate(prevProps) {
    const { appStatus } = this.props;
    if (appStatus.requiredDataLoaded && !prevProps.appStatus.requiredDataLoaded) {
      this.fetchData();
    }

    if (!appStatus.appUpdating && prevProps.appStatus.appUpdating) {
      this.fetchData();
    }
  }

  componentWillUnmount() {
    this._mounted = false;
    notificationLU.destroy();
    notificationRU.destroy();
    notificationTC.destroy();
    if (this._notif) this._notif.destroy();
  }

  async onRowSelect(values) {
    const { ROLES } = this.state;
    if (ROLES.workers && ROLES.workers.list && ROLES.workers.list.readonly) {
      ReactGA.event({
        category: 'Delavci',
        action: `Urejanje Delavca - ${values} - Ni dostopa`,
      });
      this.showNotif({
        title: 'Ni dostopa!',
        message: 'Nimate pravic za urejanje delavcev!',
        success: false,
      });
      return;
    }
    console.log('values', values);
    const body = JSON.stringify({ id: values });

    const data = await POST(API_LINK + API_EMPLOYEES_GET_ONE, body);
    ReactGA.event({
      category: 'Delavci',
      action: `Urejanje Delavca - ${values}`,
    });
    if (data.success) {
      this.setState({ editEmployee: data.employee, showEdit: true });
    }
  }

  _mounted = false;

  fetchData() {
    const { workersState, companiesState, dataListState, worksitesState } = this.props;

    companiesState.companiesTitles.forEach(com => {
      if (com.label === 'NEPRIJAVLJEN') {
        neprijavljenComId = com.value;
      }
    });

    this.setState({
      employees: workersState.employees,
      employeesFull: workersState.employees,
      people: workersState.employeesNames,
      companies: companiesState.companiesTitles,
      professions: dataListState.professions,
      countries: dataListState.countries,
      worksites: worksitesState.titlesWorksites,
    });
  }

  handleProgress(addProgress) {
    const { progressNum } = this.state;
    this.setState({ progressNum: progressNum + addProgress });
    const that = this;
    if (progressNum + addProgress >= 100) {
      setTimeout(() => {
        that.handleProgress(-(progressNum + addProgress));
        that.applyFilters(null, null, { start: null, end: null });
      }, 1000);
    }
  }

  showNotif = ({ title, message, success }) => {
    this._notif.showNotif({ title, message, success });
    // const color = success ? 'success' : 'danger';
    // showNotification({
    //   notification: (
    //     <BasicNotification color={color} title={title} message={message} />
    //   ),
    //   position: 'right-up',
    // });
  };

  handleCloseModal(data) {
    if (data) this.showNotif(data);
    this.setState({ showEdit: false });
  }

  applyFilters(selectedEmployee, selectedCompany, selectedInterval) {
    try {
      const { employeesFull, companies } = this.state;

      // prvo filtriramo po selectedInterval in selectedCompany
      const now = new Date(Date.now());
      let start = moment(now)
        .tz(TIMEZONE)
        .subtract(100, 'years')
        .toDate();
      let end = moment(now)
        .tz(TIMEZONE)
        .add(1000, 'years')
        .toDate();
      if (selectedInterval && selectedInterval.start) {
        // eslint-disable-next-line prefer-destructuring
        start = selectedInterval.start;
      }
      if (selectedInterval && selectedInterval.end) {
        // eslint-disable-next-line prefer-destructuring
        end = selectedInterval.end;
      }
      const filter1 = [];
      for (let i = 0; i < employeesFull.length; i += 1) {
        const employee = employeesFull[i];
        /* let wasAdded = false */
        let wasUnregistered = false;
        const { comlogs } = employee;
        for (let j = 0; j < comlogs.length; j += 1) {
          let shouldAdd = false;
          const clog = comlogs[j];
          const unreg = clog.unregisteredAt
            ? tmzfy(clog.unregisteredAt)
            : moment(new Date(Date.now()))
                .tz(TIMEZONE)
                .add(1000, 'years')
                .toDate();
          // je ta log ta firma
          if (selectedCompany) {
            if (clog.companyId === selectedCompany) {
              // je bil registran na firmi pred koncem tega obdobja in odjavljen po koncu tega obdobja
              if (tmzfy(clog.registeredAt) <= tmzfy(end) && tmzfy(unreg) >= tmzfy(start)) {
                shouldAdd = true;
              }
            }
          } else {
            if (tmzfy(clog.registeredAt) <= tmzfy(end) && tmzfy(unreg) >= tmzfy(start)) {
              shouldAdd = true;
            }
            // je bil v tem obdobju neprijavljen
            if (tmzfy(unreg) < tmzfy(end) || tmzfy(clog.registeredAt) >= tmzfy(start)) {
              wasUnregistered = true;
            }
          }

          if (shouldAdd) {
            const emlCopy = JSON.parse(JSON.stringify(employee));
            const company = companies.find(e => e.value === clog.companyId);
            emlCopy.color = company.color;
            emlCopy.company = company.label;
            emlCopy.companyId = company.value;
            emlCopy.registeredAt = formatify(clog.registeredAt);
            emlCopy.unregisteredAt = formatify(clog.unregisteredAt);

            filter1.push(emlCopy);
          }
        }

        // dodam še če je trenutna firma NEPRIJAVLJEN
        if (employee.company === 'NEPRIJAVLJEN' && (!selectedCompany || selectedCompany === neprijavljenComId)) {
          const emlCopy = JSON.parse(JSON.stringify(employee));
          emlCopy.registeredAt = '';
          filter1.push(emlCopy);
        }

        // dodam še če je bil v izbranem obdobju neprijavljen
        if (
          wasUnregistered &&
          employee.company !== 'NEPRIJAVLJEN' &&
          selectedInterval &&
          (selectedInterval.start || selectedInterval.end)
        ) {
          const emlCopy = JSON.parse(JSON.stringify(employee));
          emlCopy.color = '#EDECEC';
          emlCopy.company = 'NEPRIJAVLJEN';
          emlCopy.companyId = neprijavljenComId;
          emlCopy.registeredAt = '';
          emlCopy.unregisteredAt = '';

          filter1.push(emlCopy);
        }
      }

      // console.log('mam filter1', filter1);

      let filter2 = [];
      // nato filtriramo enega delavca
      if (selectedEmployee) {
        for (let i = 0; i < filter1.length; i += 1) {
          if (filter1[i].id === selectedEmployee) {
            filter2.push(filter1[i]);
          }
        }
      } else {
        filter2 = filter1;
      }

      // če imamo selectanega delavca in ga ni v filter2, pomeni da je neprijavljen
      if (selectedEmployee && filter2.length === 0 && !selectedCompany) {
        for (let i = 0; i < employeesFull.length; i += 1) {
          const employee = employeesFull[i];

          if (employee.id === selectedEmployee) {
            const emlCopy = JSON.parse(JSON.stringify(employee));
            emlCopy.color = '#EDECEC';
            emlCopy.company = 'NEPRIJAVLJEN';
            emlCopy.companyId = neprijavljenComId;
            emlCopy.registeredAt = '';
            emlCopy.unregisteredAt = '';

            filter2.push(emlCopy);
          }
        }
      }

      // sortiramo
      const lexCmpName = (a, b) => CONFIG.COLLATOR.compare(a.name, b.name);
      const lexCmpSurname = (a, b) => CONFIG.COLLATOR.compare(a.surname, b.surname);
      const lexCmpCompany = (a, b) => CONFIG.COLLATOR.compare(a.company, b.company);

      const byName = stable(filter2, lexCmpName);
      const bySurname = stable(byName, lexCmpSurname);
      const byCompany = stable(bySurname, lexCmpCompany);

      this.setState({
        employees: byCompany,
        selectedEmployee,
        selectedCompany,
        selectedInterval,
      });
    } catch (error) {
      console.log('applyFilters ERROR', error);
    }
  }

  // filter za enega delavca
  handleDropdownSubmit(values) {
    if (values.selected_dropdown) {
      ReactGA.event({
        category: 'Delavci',
        action: `Filtriranje po delavcu - ${values.selected_dropdown.label}`,
      });
      if (values.selected_dropdown.value) {
        this.applyFilters(values.selected_dropdown.value, this.state.selectedCompany, this.state.selectedInterval);
      } else {
        this.applyFilters(null, this.state.selectedCompany, this.state.selectedInterval);
      }
    } else {
      this.applyFilters(null, this.state.selectedCompany, this.state.selectedInterval);
    }
  }

  // filter za eno firmo
  handleCompaniesSubmit(values) {
    if (values.selected_dropdown_2) {
      ReactGA.event({
        category: 'Delavci',
        action: 'Filtriranje po podjetjih',
      });
      if (values.selected_dropdown_2.value) {
        this.applyFilters(this.state.selectedEmployee, values.selected_dropdown_2.value, this.state.selectedInterval);
      } else {
        this.applyFilters(this.state.selectedEmployee, null, this.state.selectedInterval);
      }
    } else {
      this.applyFilters(this.state.selectedEmployee, null, this.state.selectedInterval);
    }
  }

  handleIntervalSubmit(values) {
    if (values.interval_date) {
      ReactGA.event({
        category: 'Delavci',
        action: 'Filtriranje obdobij',
      });
      this.applyFilters(this.state.selectedEmployee, this.state.selectedCompany, {
        start: values.interval_date.start,
        end: values.interval_date.end,
      });
    } else {
      this.applyFilters(this.state.selectedEmployee, this.state.selectedCompany, {
        start: null,
        end: null,
      });
    }
  }

  render() {
    const {
      employees,
      companies,
      professions,
      countries,
      worksites,
      showEdit,
      editEmployee,
      people,
      progressNum,
      ROLES,
    } = this.state;
    const { t } = this.props;

    let showBtn = ROLES.full;

    if (!showBtn) {
      showBtn = !(ROLES.workers && ROLES.workers.list && ROLES.workers.list.readonly);
    }
    return (
      <Container className="dashboard">
        <Row>
          <Col lg={12}>
            <h3 className="page-title">{t('sidebar_content.workers.title')}</h3>
          </Col>
        </Row>
        <Row>
          <Col lg={3}>
            <Card>
              <CardBody>
                <SelectDropdown2
                  title={`${t('calcs.chooseCompany')}:`}
                  items={companies}
                  onSubmit={this.handleCompaniesSubmit}
                />
              </CardBody>
            </Card>
          </Col>

          <Col lg={3}>
            <Card>
              <CardBody>
                <SelectInterval title={`${t('form.chooseSpan')}:`} onSubmit={this.handleIntervalSubmit} />
              </CardBody>
            </Card>
          </Col>

          <Col lg={3}>
            <Card>
              <CardBody>
                <SelectDropdown
                  title={`${t('form.chooseWorker')}:`}
                  items={people}
                  onSubmit={this.handleDropdownSubmit}
                />
              </CardBody>
            </Card>
          </Col>
          <Col lg={3}>
            <ButtonToolbar className="delavci__button-toolbar">
              <div className="mb-lg-3">
                <DelavciModal
                  btn={t('form.addWorker')}
                  icon="add"
                  title={t('form.addWorker')}
                  companies={companies}
                  professions={professions}
                  countries={countries}
                  worksites={worksites}
                  hasBtn={showBtn}
                  show={false}
                  onClose={this.handleCloseModal}
                  onDataSubmitted={() => null}
                  notif={this._notif}
                />
              </div>
              {!!employees.length && progressNum === 0 && (
                <DelavciSheet key={shortid.generate()} data={employees} btn={t('form.exportExcel')} />
              )}
            </ButtonToolbar>
          </Col>
        </Row>
        <Row>
          {progressNum > 0 && (
            <Col lg={12}>
              <div className="progress-wrap progress-wrap--middle progress--align">
                <Progress animated value={progressNum} />
              </div>
            </Col>
          )}
          <DelavciTable
            key={shortid.generate()}
            title={t('sidebar_content.workers.title')}
            employees={employees}
            onRowSelect={this.onRowSelect}
            progressNum={progressNum}
          />
        </Row>

        <DelavciModalComponent
          btn="Uredi delavca"
          icon="edit"
          title="Uredi delavca"
          companies={companies}
          professions={professions}
          countries={countries}
          worksites={worksites}
          hasBtn={false}
          show={showEdit}
          onClose={this.handleCloseModal}
          editData={editEmployee}
          onDataSubmitted={this.localUpdate}
          onDocumentRemoved={() => null}
          notif={this._notif}
        />
      </Container>
    );
  }
}

const wr = connect(state => ({
  workersState: state.employees,
  companiesState: state.companies,
  dataListState: state.dataList,
  worksitesState: state.worksites,
  appStatus: state.appStatus,
}))(DelavciPage);

export default translate('common')(wr);
