import React from 'react';
import PropTypes from 'prop-types';
import { Button, Modal } from 'reactstrap';

import classNames from 'classnames';
import AddIcon from 'mdi-react/AccountMultiplePlusOutlineIcon';
// import EditIcon from 'mdi-react/AccountEditIcon';
import { OfficeIcon } from 'mdi-react';
import SelectAnyWeekForm from './SelectAnyWeekForm';

class SelectAnyWeekModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { modal: false };

    this.toggle = this.toggle.bind(this);
    this.cancel = this.cancel.bind(this);
    this.handleESC = this.handleESC.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    document.addEventListener('keydown', this.handleESC, false);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleESC, false);
  }

  handleESC(event) {
    if (event.keyCode === 27) {
      this.setState({ modal: false });
      this.cancel();
    }
  }

  toggle() {
    this.setState({
      modal: !this.state.modal,
    });
  }

  cancel(data) {
    this.setState({
      modal: false,
    });
    this.props.onClose(data);
  }

  handleSubmit(values) {
    if (values.kw && values.year) {
      this.cancel({ kw: values.kw.value, year: values.year.value });
    } else {
      this.cancel(null);
    }
  }

  render() {
    const { icon, btn, title, colored, header, hasBtn, show, month, year } = this.props;
    const modalClass = classNames({
      'modal-dialog--colored': colored,
      'modal-dialog--header': header,
    });

    return (
      <div>
        {hasBtn ? (
          <Button className="icon large-button" color="primary" onClick={this.toggle}>
            <p>
              {icon === 'add' ? <AddIcon /> : <OfficeIcon />}
              {btn}
            </p>
          </Button>
        ) : null}

        <Modal
          isOpen={this.state.modal || show}
          toggle={this.toggle}
          className={`modal-dialog--primary ${modalClass} mini-modal`}
        >
          <div className="modal__header">
            <button className="lnr lnr-cross modal__close-btn" onClick={this.toggle} />

            <h4 className="bold-text  modal__title">{title}</h4>
          </div>
          <div className="modal__body">
            <div className="theme-light">
              <SelectAnyWeekForm onSubmit={this.handleSubmit} handleCancel={() => this.cancel(null)} />
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

SelectAnyWeekModal.propTypes = {
  title: PropTypes.string,
  icon: PropTypes.string.isRequired,
  colored: PropTypes.bool,
  header: PropTypes.bool,
  btn: PropTypes.string.isRequired,
  hasBtn: PropTypes.bool.isRequired,
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  month: PropTypes.number.isRequired,
  year: PropTypes.number.isRequired,
};

SelectAnyWeekModal.defaultProps = {
  title: '',
  colored: false,
  header: false,
};

export default SelectAnyWeekModal;
