import React from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, Col, Progress } from 'reactstrap';

import classNames from 'classnames';
import AddIcon from 'mdi-react/CarSideIcon';
import EditIcon from 'mdi-react/CarIcon';
import shortid from 'shortid';

import Alert from 'shared/components/Alert';
import { getItem } from 'helpers/cookies';
import { API_LINK } from '../../../constants/config';
import VozilaForm from './VozilaForm';
import { POST, UPLOAD } from '../../../helpers/agent';
import payload from '../../../helpers/jwt';

const API_ADD_VEHICLE = '/v/add';
const API_EDIT_VEHICLE = '/v/update';
const API_DELETE_VEHICLE = '/v/delete';

const FILE_INPUT_FIELDS = [
  {
    value: 'techScan',
    type: 'tech',
    msg: 'Nalagam tehnični pregled...',
  },
  {
    value: 'insScan',
    type: 'ins',
    msg: 'Nalagam zavarovanje...',
  },
  {
    value: 'regScan',
    type: 'reg',
    msg: 'Nalagam registracijo...',
  },
];

function timeout(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

class VozilaModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      uploading: false,
      uploadMsg: '',
      uploadPrct: 0,
      showAlert: false,
      uploadedFiles: [],
    };

    this.toggle = this.toggle.bind(this);
    this.cancel = this.cancel.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleESC = this.handleESC.bind(this);
  }

  componentDidMount() {
    document.addEventListener('keydown', this.handleESC, false);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleESC, false);
    this.generatedDocIDs = [];
  }

  generatedDocIDs = [];

  handleESC(event) {
    if (event.keyCode === 27) {
      this.setState({ modal: false });
      this.cancel(null);
    }
  }

  toggle() {
    this.setState({
      modal: !this.state.modal,
    });
  }

  cancel(data) {
    this.setState({
      modal: false,
    });
    this.props.onClose(data);
  }

  parseDocsFields = fields => {
    const allFields = {};
    const { uid } = payload(getItem('jwt'));
    console.log('fields', fields);
    // basic data
    allFields._id = fields._id; // || shortid.generate();
    allFields.licensePlate = fields.licensePlate;
    allFields.brand = fields.brand;
    allFields.type = fields.type ? fields.type.value : '';
    allFields.provider = fields.provider;

    // docs data
    allFields.docs = [];

    for (let index = 0; index < 200; index += 1) {
      const { extraTech } = fields;
      if (extraTech.includes(index)) continue;
      const techData = {
        type: 'tech',
        modifiedBy: uid,
        createdAt: Date.now(),
        updatedAt: Date.now(),
      };
      let shouldAdd = false;

      if (fields[`techNum${index}`]) {
        techData.num = fields[`techNum${index}`];
        shouldAdd = true;
      }

      if (fields[`techStart${index}`]) {
        techData.startsAt = fields[`techStart${index}`];
        shouldAdd = true;
      }

      if (fields[`techEnd${index}`]) {
        techData.expAt = fields[`techEnd${index}`];
        shouldAdd = true;
      }

      if (shouldAdd) {
        if (!techData._id) {
          const GDID = this.generatedDocIDs.find(gdid => gdid.type === `techScan${index}`);
          if (GDID) {
            techData._id = GDID.id;
          } else {
            techData._id = shortid.generate();
          }
          this.generatedDocIDs.push({
            type: `techScan${index}`,
            id: techData._id,
          });
        }
        allFields.docs.push(techData);
      }
    }

    for (let index = 0; index < 200; index += 1) {
      const { extraIns } = fields;
      if (extraIns.includes(index)) continue;
      const insData = {
        type: 'ins',
        modifiedBy: uid,
        createdAt: Date.now(),
        updatedAt: Date.now(),
      };
      let shouldAdd = false;

      if (fields[`insNum${index}`]) {
        insData.num = fields[`insNum${index}`];
        shouldAdd = true;
      }

      if (fields[`insStart${index}`]) {
        insData.startsAt = fields[`insStart${index}`];
        shouldAdd = true;
      }

      if (fields[`insEnd${index}`]) {
        insData.expAt = fields[`insEnd${index}`];
        shouldAdd = true;
      }

      if (shouldAdd) {
        if (!insData._id) {
          const GDID = this.generatedDocIDs.find(gdid => gdid.type === `insScan${index}`);
          if (GDID) {
            insData._id = GDID.id;
          } else {
            insData._id = shortid.generate();
          }
          this.generatedDocIDs.push({
            type: `insScan${index}`,
            id: insData._id,
          });
        }
        allFields.docs.push(insData);
      }
    }

    for (let index = 0; index < 200; index += 1) {
      const { extraReg } = fields;
      if (extraReg.includes(index)) continue;
      const regData = {
        type: 'reg',
        modifiedBy: uid,
        createdAt: Date.now(),
        updatedAt: Date.now(),
      };
      let shouldAdd = false;

      if (fields[`regFirst${index}`]) {
        regData.firstReg = fields[`regFirst${index}`];
        shouldAdd = true;
      }

      if (fields[`regStart${index}`]) {
        regData.startsAt = fields[`regStart${index}`];
        shouldAdd = true;
      }

      if (fields[`regEnd${index}`]) {
        regData.expAt = fields[`regEnd${index}`];
        shouldAdd = true;
      }

      if (shouldAdd) {
        if (!regData._id) {
          const GDID = this.generatedDocIDs.find(gdid => gdid.type === `regScan${index}`);
          if (GDID) {
            regData._id = GDID.id;
          } else {
            regData._id = shortid.generate();
          }
          this.generatedDocIDs.push({
            type: `regScan${index}`,
            id: regData._id,
          });
        }
        allFields.docs.push(regData);
      }
    }

    // if (fields.techNum || fields.techStart || fields.techExp) {
    //   allFields.docs.push({
    //     type: 'tech',
    //     num: fields.techNum,
    //     startsAt: fields.techStart,
    //     expAt: fields.techExp,
    //     modifiedBy: uid,
    //     createdAt: Date.now(),
    //     updatedAt: Date.now(),
    //   });
    // }
    // if (fields.insNum || fields.insStart || fields.insExp) {
    //   allFields.docs.push({
    //     type: 'ins',
    //     num: fields.insNum,
    //     startsAt: fields.insStart,
    //     expAt: fields.insExp,
    //     modifiedBy: uid,
    //     createdAt: Date.now(),
    //     updatedAt: Date.now(),
    //   });
    // }
    // if (fields.regFirst || fields.regStart || fields.regExp) {
    //   allFields.docs.push({
    //     type: 'reg',
    //     num: fields.regFirst,
    //     startsAt: fields.regStart,
    //     expAt: fields.regExp,
    //     modifiedBy: uid,
    //     createdAt: Date.now(),
    //     updatedAt: Date.now(),
    //   });
    // }

    console.log('allFields', allFields);
    return allFields;
  };

  async handleDelete(data) {
    const successMessage = `Vozilo ${`${data.brand} ${data.licensePlate}`} uspešno izbrisano!`;
    const errorMessage = `Vozilo ${`${data.brand} ${data.licensePlate}`} NI uspešno izbrisano!`;
    //const that = this;
    try {
      const dataRes = await POST(API_LINK + API_DELETE_VEHICLE, JSON.stringify({ _id: data._id }));
      //console.log('zdaj bi izbrisal', data);
      if (dataRes.success) {
        //console.log('Request success: ', dataRes);
        this.cancel({ title: 'Izbris vozila', message: successMessage, success: true });
      } else {
        //console.log('Request success: ', dataRes);
        //this.showNotif('error', isEdit, values.name, false);
        this.cancel({ title: 'Izbris vozila', message: errorMessage, success: false });
      }
    } catch (error) {
      console.log(error);
      this.cancel(null);
    }
  }

  async handleSubmit(values) {
    if (this.isUploadWaiting(values) && !this.state.showAlert) {
      this.setState({ showAlert: true });
      return;
    }
    this.setState({ showAlert: false });
    //event.preventDefault();
    const { icon } = this.props;
    const isEdit = icon !== 'add';

    const vehicleObject = this.parseDocsFields(values);
    const body = JSON.stringify(vehicleObject);

    const apiLink = isEdit ? API_EDIT_VEHICLE : API_ADD_VEHICLE;
    try {
      //const that = this;
      //console.log(icon + ' vozilo', values);
      //const body = JSON.parse(JSON.stringify(values));
      const notifTitle = isEdit ? 'Uredi vozilo' : 'Dodaj vozilo';
      const successMessage = isEdit
        ? `Vozilo ${`${values.brand} ${values.licensePlate}`} uspešno posodobljeno!`
        : `Vozilo ${`${values.brand} ${values.licensePlate}`} uspešno dodano!`;
      const errorMessage = isEdit
        ? `Vozilo ${`${values.brand} ${values.licensePlate}`} ni uspešno posodobljeno!`
        : `Vozilo ${`${values.brand} ${values.licensePlate}`} ni uspešno dodano!`;

      //console.log('POŠILJAM BODY:', body);
      const data = await POST(
        API_LINK + apiLink,
        //JSON.stringify({ ...values, type: values.type ? values.type.value : '' }),
        body,
      );
      if (data.success) {
        //console.log('Request success: ', data);
        this.cancel({ title: notifTitle, message: successMessage, success: true });
      } else {
        //console.log('Request success: ', data);
        //this.showNotif('error', isEdit, values.name, false);
        this.cancel({ title: notifTitle, message: errorMessage, success: false });
      }
    } catch (error) {
      console.log(error);
      this.cancel(null);
    }
  }

  handleUpload = async values => {
    try {
      this.setState({ showAlert: false });
      console.log('UPLOAD OF FILE NEEDED!');
      const filecount = values.fileCounter;
      const timeoutMS = 1000;
      let counter = 1;
      console.log('VozilaModal -> handleUpload -> filecount', filecount);

      const prctStep = 100 / filecount;

      for (let i = 0; i < FILE_INPUT_FIELDS.length; i += 1) {
        const IF = FILE_INPUT_FIELDS[i];

        //nalaganje A1 in podlag
        if (IF.value === 'techScan' || IF.value === 'insScan' || IF.value === 'regScan') {
          for (let j = 0; j < 200; j += 1) {
            if (values[`${IF.value}${j}`] && document.getElementById(`${IF.value}${j}`).files.length > 0) {
              let docId = '';
              console.log('TCL: handleUpload -> docId', docId);

              if (IF.value === 'techScan') {
                if (values[`techId${j}`]) {
                  // eslint-disable-next-line prefer-destructuring
                  docId = values[`techId${j}`];
                  console.log('TCL: DelavciModalComponent -> handleUpload -> ', values[`techId${j}`]);
                }
              }
              if (IF.value === 'insScan') {
                if (values[`insId${j}`]) {
                  // eslint-disable-next-line prefer-destructuring
                  docId = values[`insId${j}`];
                  console.log('TCL: DelavciModalComponent -> handleUpload -> ', values[`insId${j}`]);
                }
              }
              if (IF.value === 'regScan') {
                if (values[`regId${j}`]) {
                  // eslint-disable-next-line prefer-destructuring
                  docId = values[`regId${j}`];
                  console.log('TCL: DelavciModalComponent -> handleUpload -> ', values[`regId${j}`]);
                }
              }

              console.log('TCL: handleUpload -> docId', docId);

              if (docId === '' || !docId) {
                const GDID = this.generatedDocIDs.find(gdid => gdid.type === `${IF.value}${j}`);
                console.log('TCL: handleUpload -> GDID', GDID);
                if (GDID) {
                  docId = GDID.id;
                } else {
                  docId = shortid.generate();
                  this.generatedDocIDs.push({
                    type: `${IF.value}${j}`,
                    id: docId,
                  });
                }
              }
              const TYPE = `${IF.type}${docId}`;
              console.log('TCL: handleUpload -> TYPE', TYPE);
              this.setState({
                uploading: true,
                uploadMsg: `${IF.msg} (${j + 1})`,
              });
              const formData = new FormData();
              formData.append('vehicleId', this.props.editData._id);
              formData.append('type', TYPE);
              formData.append('scans', document.getElementById(`${IF.value}${j}`).files[0], TYPE);

              // eslint-disable-next-line no-await-in-loop
              await UPLOAD(`${API_LINK}/f/upload`, formData);
              const { uploadedFiles } = this.state;
              uploadedFiles.push(`${IF.value}${j}`);
              this.setState({ uploadPrct: prctStep * counter, uploadedFiles });
              counter += 1;
              // eslint-disable-next-line no-await-in-loop
              await timeout(timeoutMS);
            }
          }
          // nalaganje vsega ostalega
        } else if (values[IF.value] && document.getElementById(IF.value).files.length > 0) {
          const TYPE = IF.type;
          this.setState({ uploading: true, uploadMsg: IF.msg });
          const formData = new FormData();
          formData.append('vehicleId', this.props.editData._id);
          formData.append('type', TYPE);
          formData.append('scans', document.getElementById(IF.value).files[0], TYPE);
          console.log('TCL: VozilaModal -> handleUpload -> formData', formData);

          // eslint-disable-next-line no-await-in-loop
          await UPLOAD(`${API_LINK}/f/upload`, formData);
          const { uploadedFiles } = this.state;
          uploadedFiles.push(IF.value);
          this.setState({ uploadPrct: prctStep * counter, uploadedFiles });
          counter += 1;
          // eslint-disable-next-line no-await-in-loop
          await timeout(timeoutMS);
        }
      }

      this.setState({ uploading: false, uploadMsg: '', uploadPrct: 0 });
    } catch (error) {
      console.log('VozilaModal -> handleUpload -> error', error);
    }
  };

  dismissAlert = () => {
    this.setState({
      showAlert: false,
    });
  };

  isUploadWaiting = values => {
    const { uploadedFiles } = this.state;
    // console.log('TCL: DelavciModalComponent -> isUploadWaiting -> uploadedFiles', uploadedFiles);
    for (let i = 0; i < FILE_INPUT_FIELDS.length; i += 1) {
      const IF = FILE_INPUT_FIELDS[i];
      if (values[IF.value] && document.getElementById(IF.value).files.length > 0 && !uploadedFiles.includes(IF.value)) {
        return true;
      }
    }

    // console.log('TCL: DelavciModalComponent -> isUploadWaiting -> uploadedFiles -> return', false);
    return false;
  };

  render() {
    const { icon, btn, title, colored, header, hasBtn, show, editData, people } = this.props;
    const { uploadPrct, uploading, uploadMsg, showAlert } = this.state;
    let Icon;

    switch (icon) {
      case 'add':
        Icon = <AddIcon />;
        break;
      case 'edit':
        Icon = <EditIcon />;
        break;
      default:
        break;
    }
    const modalClass = classNames({
      'modal-dialog--colored': colored,
      'modal-dialog--header': header,
    });

    const AlertElt = (
      <Alert
        color="warning"
        className="alert--bordered"
        icon
        handleDismiss={this.dismissAlert}
        width="80%"
        marginTop="-45px"
      >
        <p>
          <span className="bold-text">Prenos datotek na čakanju!</span>
          <br />
          Če želite naložiti izbrane datoteke, najprej kliknite na gumb "Naloži"
        </p>
      </Alert>
    );

    console.log('VozilaModal -> render -> editData', editData);
    return (
      <div>
        {hasBtn ? (
          <Button size="sm" color="primary" onClick={this.toggle}>
            {btn}
          </Button>
        ) : null}

        <Modal
          isOpen={this.state.modal || show}
          toggle={this.toggle}
          className={`modal-dialog--primary wide ${modalClass}`}
        >
          <div className="modal__header">
            <button className="lnr lnr-cross modal__close-btn" onClick={this.toggle} />
            {header ? '' : Icon}
            <h4 className="bold-text  modal__title">{title}</h4>
          </div>
          <div className="modal__body">
            <div className="theme-light">
              {editData ? (
                <VozilaForm
                  onSubmit={this.handleSubmit}
                  handleCancel={() => this.cancel(null)}
                  handleDelete={this.handleDelete}
                  handleUpload={this.handleUpload}
                  initialValues={editData}
                  editData={editData}
                  people={people}
                  initialTab={this.props.initialTab}
                  initialTechFieldCount={editData.techCounter}
                  initialInsFieldCount={editData.insCounter}
                  initialRegFieldCount={editData.regCounter}
                />
              ) : (
                <VozilaForm
                  onSubmit={this.handleSubmit}
                  handleCancel={() => this.cancel(null)}
                  handleDelete={this.handleDelete}
                  handleUpload={this.handleUpload}
                  people={people}
                />
              )}
              {showAlert && AlertElt}
              {uploading && (
                <Col lg={12} style={{ marginTop: '-40px' }}>
                  <div className="progress-wrap progress-wrap--middle progress-wrap--violet progress--align">
                    <Progress animated value={uploadPrct} />
                  </div>
                </Col>
              )}
              {uploading && (
                <Col lg={12} style={{ height: '50px' }}>
                  <span
                    style={{
                      paddingBottom: '30px',
                      height: '50px',
                      color: 'Gray',
                    }}
                  >
                    {uploadMsg}
                  </span>
                </Col>
              )}
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

VozilaModal.propTypes = {
  title: PropTypes.string,
  people: PropTypes.arrayOf(PropTypes.object).isRequired,
  icon: PropTypes.string.isRequired,
  colored: PropTypes.bool,
  header: PropTypes.bool,
  btn: PropTypes.string.isRequired,
  hasBtn: PropTypes.bool.isRequired,
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  editData: PropTypes.object,
  initialTab: PropTypes.string,
};

VozilaModal.defaultProps = {
  title: '',
  colored: false,
  header: false,
  editData: null,
  initialTab: '1',
};

export default VozilaModal;
