import moment from 'moment-timezone';
import stable from 'stable';
import { SET_EMPLOYEES, SET_EMPLOYEES_NAMES } from '../actions/employeesActions';
import { TIMEZONE, CONFIG } from '../../constants/config';

const initialState = {
  employees: [],
  employeesNames: [],
};

const mapEmployees = employees => {
  const mapped = employees.map(e => {
    const birthday = e.birthDate
      ? moment(e.birthDate)
          .tz(TIMEZONE)
          .format('DD. MM. YY')
      : '';
    return {
      value: e.id,
      id: e.id,
      label: `${e.surname.toUpperCase()} ${e.name} - ${birthday}`,
      surname: e.surname,
      name: e.name,
      comlogs: e.comlogs,
      birthDate: e.birthDate,
    };
  });

  const lexCmpName = (a, b) => CONFIG.COLLATOR.compare(a.name, b.name);
  const lexCmpSurname = (a, b) => CONFIG.COLLATOR.compare(a.surname, b.surname);

  const byName = stable(mapped, lexCmpName);
  const bySurname = stable(byName, lexCmpSurname);
  return bySurname;
};

export default function(state = initialState, action) {
  switch (action.type) {
    case SET_EMPLOYEES:
      return {
        ...state,
        employees: action.employees,
        employeesNames: mapEmployees(action.employees),
      };
    case SET_EMPLOYEES_NAMES:
      return { ...state, employeesNames: action.employeesNames };
    default:
      return state;
  }
}
