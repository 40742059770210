/* eslint-disable jsx-a11y/label-has-for */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { ArrowUpBoldCircleOutlineIcon } from 'mdi-react';
import { translate } from 'react-i18next';
import { shortify } from 'helpers/functions';

class FileInputField extends PureComponent {
  static propTypes = {
    onChange: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([
      PropTypes.shape({
        name: PropTypes.string,
      }),
      PropTypes.string,
    ]),
    t: PropTypes.func.isRequired,
  };

  static defaultProps = {
    value: null,
  };

  render() {
    const { onChange, name, value, t } = this.props;

    return (
      <div className="form__form-group-file">
        <label htmlFor={name} style={{ width: '100%' }}>
          <ArrowUpBoldCircleOutlineIcon />{' '}
          {value.name ? (
            <span style={value.name ? { color: 'limegreen', fontSize: 'xx-small' } : {}}>
              {shortify(value.name, 100)}
            </span>
          ) : (
            t('misc.choosefile')
          )}
        </label>
        {/* {value.name && (
          <span style={value.name ? { color: 'limegreen' } : {}}>
            {shortify(value.name, 30)}
          </span>
        )} */}
        <input
          type="file"
          name={name}
          id={name}
          accept=".jpg, .png, .pdf"
          onChange={e => {
            e.preventDefault();
            // convert files to an array
            const files = [...e.target.files];
            if (files.length > 0) onChange({ file: files[0], name: files[0].name });
            else onChange({ file: null, name: null });
          }}
        />
      </div>
    );
  }
}

const Fif = translate('common')(FileInputField);

const renderFileInputField = props => (
  <div className="form__form-group-input-wrap">
    <Fif {...props.input} />
    {props.meta.touched && props.meta.error && <span className="form__form-group-error">{props.meta.error}</span>}
  </div>
);

renderFileInputField.propTypes = {
  input: PropTypes.shape({
    onChange: PropTypes.func,
    name: PropTypes.string,
  }).isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
};

renderFileInputField.defaultProps = {
  meta: null,
};

export default renderFileInputField;
