import moment from 'moment-timezone';

const validate = values => {
  const errors = {};
  if (!values.worksiteId) {
    errors.worksiteId = 'To polje mora biti izpolnjeno!';
  }

  if (
    values.date >
    moment(new Date(Date.now()))
      .add(1, 'day')
      .startOf('day')
  ) {
    errors.date = 'Spremembe v prihodnost niso omogočene';
  }

  return errors;
};

export default validate;
