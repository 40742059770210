import { API_LINK } from 'constants/config';
import { GET } from 'helpers/agent';

export default {
  getall: async () => {
    const data = await GET(`${API_LINK}/p/getall`);
    if (data.success) {
      return { professions: data.professions };
    }
    return { professions: [] };
  },
};
