import React, { Component } from 'react';
import { translate } from 'react-i18next';
import { Card, CardBody, Row, Col, Button } from 'reactstrap';
import PropTypes from 'prop-types';
import { SelectField } from '../../../../../shared/components/form/Select';

const MONTH_COUNT = 6;

class HistorySlider extends React.PureComponent {
  constructor(props) {
    super(props);
    const options = this.mapSelectOptions(props.t);
    this.state = {
      options,
      value: options[0],
    };
  }

  mapSelectOptions = t => {
    const list = [];
    list.push({
      value: 0,
      label: t('wages.showall'),
    });
    for (let i = 0; i < MONTH_COUNT; i += 1) {
      list.push({
        value: i + 1,
        label: `<= ${i + 1} ${t('wages.mon')}.`,
      });
    }
    return list;
  };

  handleFieldChange = values => {
    if (!values) {
      this.setState(prevState => ({ value: prevState.options[0] }));
      this.props.onSubmit(this.state.options[0]);
    } else {
      this.setState({ value: values });
      this.props.onSubmit(values);
    }
  };

  render() {
    const { t } = this.props;
    const { value, options } = this.state;

    return (
      <div className="konto-select-months">
        <div className="display-table">
          <SelectField
            name="month-histo-konto-2"
            onChange={this.handleFieldChange}
            placeholder={t('wages.showall')}
            options={options}
            value={value}
          />
        </div>
      </div>
    );
  }
}

HistorySlider.propTypes = {
  t: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  //start: PropTypes.number.isRequired,
};

HistorySlider.defaultProps = {};

export default translate('common')(HistorySlider);
