import React from 'react';
import shortid from 'shortid';
import { Card, CardBody, Col, Badge, Table } from 'reactstrap';
import PropTypes from 'prop-types';
import { translate } from 'react-i18next';
import countries from 'i18n-iso-countries';
import translateProfession from 'translations/dynamicTranslator';
/* eslint-disable */
const renderTable = (employees, onRowSelect) =>
  employees.map((employee, index) => (
    <tr key={employee.id + '-' + shortid.generate()} id={employee.id} onClick={onRowSelect.bind(this, employee.id)}>
      <td>{index + 1}</td>
      <td>
        <Badge style={{ backgroundColor: `${employee.color}`, color: '#646777' }}>{employee.company}</Badge>
      </td>
      <td>{employee.surname}</td>
      <td>{employee.name}</td>
      <td>{employee.registeredAt}</td>
      <td>{employee.unregisteredAt}</td>
      <td>
        {employee.residencyId ? countries.getName(employee.residencyId, localStorage.getItem('lang') || 'sl') : ''}
      </td>
      <td>
        {employee.citizenshipId ? countries.getName(employee.citizenshipId, localStorage.getItem('lang') || 'sl') : ''}
      </td>
      <td>{employee.taxID}</td>
      <td>{employee.emso}</td>
      <td>{translateProfession(employee.profession, localStorage.getItem('lang') || 'sl')}</td>
      <td>{employee.address}</td>
      <td>{employee.zip}</td>
      <td>{employee.city}</td>
      {/* <td>{employee.birthDateString}</td> */}
      {/* <td>{employee.registeredAt}</td> */}
      {/* <td>{employee.registeredTo}</td>
      <td>{employee.unregisteredAt}</td>
      <td>{employee.insurance}</td> */}
      {/* <td>{employee.taxID}</td>
      <td>{employee.emso}</td>
      <td>{employee.trr}</td> */}
    </tr>
  ));

const DelavciTable = ({ title, employees, onRowSelect, progressNum, t }) => (
  <Col md={12} lg={12} xl={12}>
    <Card>
      <CardBody>
        <div className="card__title delavci-table__title">
          <h5 className="bold-text">{t('sidebar_content.workers.title')}</h5>
        </div>
        <Table hover className="table--bordered" responsive>
          <thead>
            <tr>
              <th>#</th>
              <th>Firma</th>
              <th>{t('form.surname')}</th>
              <th>{t('form.name')}</th>
              <th>{t('form.registeredAt')}</th>
              <th>{t('form.registeredTo')}</th>
              <th>{t('form.residency')}</th>
              <th>{t('form.citizenship')}</th>
              <th>{t('form.taxID')}</th>
              <th>{t('form.emso')}</th>
              <th>{t('form.profession')}</th>
              <th>{t('form.address')}</th>
              <th>{t('form.zip')}</th>
              <th>{t('form.city')}</th>
              {/* <th>Datum Rojstva</th> */}
              {/* <th>Prijavljen od</th> */}
              {/*  <th>Prijavljen do</th>
              <th>Odjava</th> */}
              {/* <th>Podl. za zav.</th> */}
              {/* <th>Davčna</th>
              <th>EMŠO</th>
              <th>TRR</th> */}
            </tr>
          </thead>
          <tbody>{renderTable(employees, onRowSelect)}</tbody>
        </Table>
      </CardBody>
    </Card>
  </Col>
);

DelavciTable.propTypes = {
  title: PropTypes.string.isRequired,
  employees: PropTypes.arrayOf(PropTypes.object).isRequired,
  onRowSelect: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default translate('common')(DelavciTable);
