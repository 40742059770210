import { API_LINK } from 'constants/config';
import { GET, POST, BULK_DOWNLOAD } from 'helpers/agent';

export default {
  getAllEmployeeSatnicas: async (month, year, companyId, tabId) => {
    const data = await GET(
      `${API_LINK}/fin/getAllEmployeeSatnicas?month=${month}&year=${year}&companyId=${companyId}&tabId=${tabId}`,
    );
    if (data) {
      return { employeeSatnice: data.satnice, locked: data.locked };
    }
    return { employeeSatnice: [] };
  },
  getWorklistWithAddons: async (month, year, companyId, tabId) => {
    const data = await GET(
      `${API_LINK}/fin/getWorklistWithAddons?month=${month}&year=${year}&companyId=${companyId}&tabId=${tabId}`,
    );
    if (data) {
      return { addons: data.addons, locked: data.locked };
    }
    return { addons: [] };
  },
  getWagesList: async (month, year, companyId, tabId) => {
    const data = await GET(
      `${API_LINK}/fin/getWagesList?month=${month}&year=${year}&companyId=${companyId}&tabId=${tabId}`,
    );
    if (data) {
      return { wages: data.wages, locked: data.locked };
    }
    return { wages: [] };
  },
  getEmployeeTransactionsForCompensation: async (month, year, employeeId, tabId) => {
    const data = await GET(
      `${API_LINK}/fin/getEmployeeTransactionsForCompensation?month=${month}&year=${year}&employeeId=${employeeId}`,
    );
    if (data) {
      return data;
    }
    return { oldTransactions: [], templateTransactions: [] };
  },
  getEmployeeRecurringTransactionsFromTemplates: async (employeeId, month, year) => {
    const data = await GET(
      `${API_LINK}/fin/getEmployeeRecurringTransactionsFromTemplates?employeeId=${employeeId}&month=${month}&year=${year}`,
    );
    if (data) {
      return { recurring: data.recurring };
    }
    return { recurring: [] };
  },
  upsertEmployeeSatnica: (employeeId, month, year, satnica, notes, tabId) => {
    POST(`${API_LINK}/fin/upsertEmployeeSatnica`, JSON.stringify({ employeeId, month, year, satnica, notes, tabId }));
  },
  upsertOWAddon: (owId, employeeId, month, year, addon, addonNight, notes) => {
    POST(`${API_LINK}/fin/upsertOWAddon`, JSON.stringify({ owId, employeeId, month, year, addon, addonNight, notes }));
  },
  upsertCompensation: async (employeeId, month, year, compensation, notes, kontoId) => {
    await POST(
      `${API_LINK}/fin/upsertCompensation`,
      JSON.stringify({ employeeId, month, year, compensation, notes, kontoId }),
    );
  },
  deleteCompensation: async compensationId => {
    await POST(`${API_LINK}/fin/deleteCompensation`, JSON.stringify({ compensationId }));
  },
  getEmployeeCompensations: async (employeeId, month, year) => {
    const data = await GET(
      `${API_LINK}/fin/getEmployeeCompensations?employeeId=${employeeId}&month=${month}&year=${year}`,
    );
    if (data) {
      return { compensations: data.compensations };
    }
    return { compensations: [] };
  },
  getCompensation: async compensationId => {
    const data = await GET(`${API_LINK}/fin/getCompensation?compensationId=${compensationId}`);
    if (data) {
      return data;
    }
    return null;
  },
  setLockdown: async (month, year, tabId) => {
    await POST(`${API_LINK}/fin/setLockdown`, JSON.stringify({ month, year, tabId }));
  },
  transferSatniceFromPrevMonth: async (month, year) => {
    await POST(`${API_LINK}/fin/transferSatniceFromPrevMonth`, JSON.stringify({ month, year }));
  },
  downloadAddons: async (month, year, companyId, companyName) => {
    await BULK_DOWNLOAD(API_LINK, { month, year, companyId, companyName }, 'ADDONS');
  },
  downloadAddonsW: async (month, year, companyId, companyName, addons, wOnly) => {
    await BULK_DOWNLOAD(API_LINK, { month, year, companyId, companyName, addons, wOnly }, 'ADDONS-W');
  },
  downloadSatnice: async (month, year, companyId, companyName) => {
    await BULK_DOWNLOAD(API_LINK, { month, year, companyId, companyName }, 'SATNICE');
  },
};
