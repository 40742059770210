import PropTypes from 'prop-types';

const { string, shape } = PropTypes;

export const SidebarProps = shape({
  show: PropTypes.bool,
  collapse: PropTypes.bool,
});

export const ThemeProps = shape({
  className: string,
});

export const DocsProps = shape({
  show: PropTypes.bool,
  alerts: PropTypes.arrayOf(PropTypes.object),
});

export const HoursProps = shape({
  workdays: PropTypes.array,
  workdaysLoaded: PropTypes.bool,
  workers: PropTypes.object,
  workersLoaded: PropTypes.bool,
});

export const UserProps = shape({
  user: PropTypes.object,
});

export const EmployeesProps = shape({
  employees: PropTypes.arrayOf(PropTypes.object),
  employeesNames: PropTypes.arrayOf(PropTypes.object),
});

export const AppStatusProps = shape({
  appLoading: PropTypes.bool,
  requiredDataLoaded: PropTypes.bool,
  appLoadingStatus: PropTypes.string,

  appUpdating: PropTypes.bool,
  appUpdatingStatus: PropTypes.string,

  blockRouter: PropTypes.bool,
  showRouterBlockNotif: PropTypes.string,
});

export const WorksitesProps = shape({
  worksites: PropTypes.arrayOf(PropTypes.object),
  titlesWorksites: PropTypes.arrayOf(PropTypes.object),
  worksitesWithAddress: PropTypes.arrayOf(PropTypes.object),
});

export const VehiclesProps = shape({
  vehicles: PropTypes.arrayOf(PropTypes.object),
  licenses: PropTypes.arrayOf(PropTypes.object),
});

export const OrderersProps = shape({
  orderers: PropTypes.arrayOf(PropTypes.object),
  titlesOrderers: PropTypes.arrayOf(PropTypes.object),
});

export const AccommodationsProps = shape({
  accommodations: PropTypes.arrayOf(PropTypes.object),
  accommodationTitles: PropTypes.arrayOf(PropTypes.object),
  providerTitles: PropTypes.arrayOf(PropTypes.object),
});

export const CompaniesProps = shape({
  companies: PropTypes.arrayOf(PropTypes.object),
  companiesTitles: PropTypes.arrayOf(PropTypes.object),
});

export const DataListProps = shape({
  professions: PropTypes.arrayOf(PropTypes.object),
  countries: PropTypes.arrayOf(PropTypes.object),
});

export const PlacesProps = shape({
  places: PropTypes.arrayOf(PropTypes.object),
  searchPlaces: PropTypes.arrayOf(PropTypes.object),
  loading: PropTypes.bool,
});
