import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Modal } from 'reactstrap';
import classNames from 'classnames';

class SuperModalComponent extends PureComponent {
  static propTypes = {
    title: PropTypes.string,
    subtitle: PropTypes.string,
    colored: PropTypes.bool,
    header: PropTypes.bool,
    children: PropTypes.node.isRequired,
    show: PropTypes.bool,
    onClose: PropTypes.func.isRequired,
    wide: PropTypes.bool,
  };

  static defaultProps = {
    title: '',
    subtitle: null,
    colored: false,
    header: false,
    show: false,
    wide: false,
  };

  constructor(props) {
    super(props);
    this.state = {
      modal: props.show,
    };
  }

  UNSAFE_componentWillUpdate = (nextProps, nextState) => {
    if (
      (nextProps.show && !this.props.show && !nextState.modal) ||
      (!nextProps.show && this.props.show && nextState.modal)
    ) {
      this.toggle();
    }
  };

  toggle = () => {
    this.setState(prevState => ({ modal: !prevState.modal }));
    if (this.state.modal) {
      this.props.onClose();
    }
  };

  hide = () => {
    this.setState({ modal: false });
    this.props.onClose();
  };

  render() {
    const { title, subtitle, colored, header, children, wide } = this.props;
    const { modal } = this.state;

    const modalClass = classNames({
      'modal-dialog--colored': colored,
      'modal-dialog--header': header,
    });

    return (
      <Modal
        isOpen={modal}
        toggle={this.toggle}
        className={`modal-dialog--primary ${wide ? 'wide-xl' : ''} ${modalClass}`}
      >
        <div className="modal__header">
          <h4 className="text-modal  modal__title">{title}</h4>
          {subtitle && <h5>{subtitle}</h5>}
        </div>
        <div className="modal__body">
          <div className="theme-light">{children}</div>
        </div>
      </Modal>
    );
  }
}

export default connect(state => ({
  rtl: state.rtl,
}))(SuperModalComponent);
