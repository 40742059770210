import React, { Component } from 'react';
import { translate } from 'react-i18next';
import { Card, CardBody, Row, Col } from 'reactstrap';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { MultiSelectField } from 'shared/components/form/MultiSelect';
import { EmployeesProps, CompaniesProps, AccommodationsProps, WorksitesProps } from 'shared/prop-types/ReducerProps';

class RezDropdownFilter extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    workersState: EmployeesProps.isRequired,
    worksitesState: WorksitesProps.isRequired,
    companies: CompaniesProps.isRequired,
    accommodationsStore: AccommodationsProps.isRequired,
    onSubmit: PropTypes.func.isRequired,
    defaultValues: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      selectedCompanySearchItem: props.defaultValues.selectedCompanySearchItem,
      selectedWorkerSearchItem: props.defaultValues.selectedWorkerSearchItem,
      selectedAccommodationSearchItem: props.defaultValues.selectedAccommodationSearchItem,
      selectedCompanyWorkerSearchItem: props.defaultValues.selectedCompanyWorkerSearchItem,
      selectedWorksiteSearchItem: props.defaultValues.selectedWorksiteSearchItem,
      selectedProviderSearchItem: props.defaultValues.selectedProviderSearchItem,
    };
  }

  handleCompanyChange = value => {
    this.setState({
      selectedCompanySearchItem: value,
    });
    this.props.onSubmit({
      ...this.state,
      selectedCompanySearchItem: value,
      // selectedWorkerSearchItem: this.state.selectedWorkerSearchItem,
      // selectedAccommodationSearchItem: this.state.selectedAccommodationSearchItem,
    });
  };

  handleWorkerChange = value => {
    this.setState({
      selectedWorkerSearchItem: value,
    });
    this.props.onSubmit({
      ...this.state,
      selectedWorkerSearchItem: value,
      // selectedCompanySearchItem: this.state.selectedCompanySearchItem,
      // selectedAccommodationSearchItem: this.state.selectedAccommodationSearchItem,
    });
  };

  handleAccommodationChange = value => {
    this.setState({
      selectedAccommodationSearchItem: value,
    });
    this.props.onSubmit({
      ...this.state,
      selectedAccommodationSearchItem: value,
      // selectedCompanySearchItem: this.state.selectedCompanySearchItem,
      // selectedWorkerSearchItem: this.state.selectedWorkerSearchItem,
    });
  };

  handleCompanyWorkerChange = value => {
    this.setState({
      selectedCompanyWorkerSearchItem: value,
    });
    this.props.onSubmit({
      ...this.state,
      selectedCompanyWorkerSearchItem: value,
    });
  };

  handleWorksiteChange = value => {
    this.setState({
      selectedWorksiteSearchItem: value,
    });
    this.props.onSubmit({
      ...this.state,
      selectedWorksiteSearchItem: value,
    });
  };

  handleProviderChange = value => {
    this.setState({
      selectedProviderSearchItem: value,
    });
    this.props.onSubmit({
      ...this.state,
      selectedProviderSearchItem: value,
    });
  };

  render() {
    const { t, workersState, accommodationsStore } = this.props;
    const {
      companies: { companiesTitles },
      worksitesState: { titlesWorksites },
    } = this.props;
    const {
      selectedCompanySearchItem,
      selectedWorkerSearchItem,
      selectedAccommodationSearchItem,
      selectedCompanyWorkerSearchItem,
      selectedWorksiteSearchItem,
      selectedProviderSearchItem,
    } = this.state;
    return (
      <Card>
        <CardBody className="pb-4">
          <div className="card__title">
            <h5 className="bold-text">{t('board.filters.title')}</h5>
          </div>

          <Row>
            <Col lg={6}>
              <Row>
                <Col lg={3} className="mb-2">
                  <div style={{ height: '100%' }}>
                    <span className="center-vetically">{t('form.company')}:</span>
                  </div>
                </Col>
                <Col lg={9} className="mb-2">
                  <div className="display-table">
                    <MultiSelectField
                      name="rez-doctypes-filter-search-1"
                      onChange={this.handleCompanyChange}
                      placeholder={`${t('board.filters.choose')}`}
                      options={companiesTitles}
                      value={selectedCompanySearchItem}
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg={3} className="mb-2">
                  <div style={{ height: '100%' }}>
                    <span className="center-vetically">{t('board.ow.worker')}:</span>
                  </div>
                </Col>
                <Col lg={9} className="mb-2">
                  <div className="display-table">
                    <MultiSelectField
                      name="rez-workers-filter-search-2"
                      onChange={this.handleWorkerChange}
                      placeholder={`${t('board.filters.choose')}`}
                      options={workersState.employeesNames}
                      value={selectedWorkerSearchItem}
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg={3} className="mb-2">
                  <div style={{ height: '100%' }}>
                    <span className="center-vetically">{t('board.ow.accommodation')}:</span>
                  </div>
                </Col>
                <Col lg={9} className="mb-2">
                  <div className="display-table">
                    <MultiSelectField
                      name="rez-workers-filter-search-3"
                      onChange={this.handleAccommodationChange}
                      placeholder={`${t('board.filters.choose')}`}
                      options={accommodationsStore.accommodationTitles}
                      value={selectedAccommodationSearchItem}
                    />
                  </div>
                </Col>
              </Row>
            </Col>
            <Col lg={6}>
              <Row>
                <Col lg={3} className="mb-2">
                  <div style={{ height: '100%' }}>
                    <span className="center-vetically">{`${t('form.company')}/${t('board.filters.worker')}`}:</span>
                  </div>
                </Col>
                <Col lg={9} className="mb-2">
                  <div className="display-table">
                    <MultiSelectField
                      name="rez-doctypes-filter-search-4"
                      onChange={this.handleCompanyWorkerChange}
                      placeholder={`${t('board.filters.choose')}`}
                      options={companiesTitles}
                      value={selectedCompanyWorkerSearchItem}
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg={3} className="mb-2">
                  <div style={{ height: '100%' }}>
                    <span className="center-vetically">{t('board.filters.worksite')}:</span>
                  </div>
                </Col>
                <Col lg={9} className="mb-2">
                  <div className="display-table">
                    <MultiSelectField
                      name="rez-doctypes-filter-search-5"
                      onChange={this.handleWorksiteChange}
                      placeholder={`${t('board.filters.choose')}`}
                      options={titlesWorksites}
                      value={selectedWorksiteSearchItem}
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg={3} className="mb-2">
                  <div style={{ height: '100%' }}>
                    <span className="center-vetically">{t('board.ow.provider')}:</span>
                  </div>
                </Col>
                <Col lg={9} className="mb-2">
                  <div className="display-table">
                    <MultiSelectField
                      name="rez-doctypes-filter-search-6"
                      onChange={this.handleProviderChange}
                      placeholder={`${t('board.filters.choose')}`}
                      options={accommodationsStore.providerTitles}
                      value={selectedProviderSearchItem}
                    />
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </CardBody>
      </Card>
    );
  }
}

const cn = connect(state => ({
  workersState: state.employees,
  companies: state.companies,
  accommodationsStore: state.accommodations,
  worksitesState: state.worksites,
}))(RezDropdownFilter);

export default translate('common')(cn);
