import { API_LINK } from 'constants/config';
import { PATCH, GET } from 'helpers/agent';

export default {
  index: async date => {
    const data = await GET(`${API_LINK}/ows?date=${date}`);
    if (data) {
      return { ows: data };
    }
    return { data: [] };
  },
  update: async (id, note) => {
    const data = await PATCH(`${API_LINK}/ows/${id}`, JSON.stringify({ note }));
    return data;
  },
};
