import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Button, ButtonToolbar, Modal } from 'reactstrap';
import classNames from 'classnames';
import { translate } from 'react-i18next';

class ModalComponent extends PureComponent {
  static propTypes = {
    title: PropTypes.string,
    message: PropTypes.string,
    color: PropTypes.string.isRequired,
    colored: PropTypes.bool,
    header: PropTypes.bool,
    disabled: PropTypes.bool,
    size: PropTypes.string,
    btn: PropTypes.string.isRequired,
    onConfirm: PropTypes.func.isRequired,
    outline: PropTypes.bool,
    t: PropTypes.func.isRequired,
  };

  static defaultProps = {
    title: '',
    message: '',
    colored: false,
    header: false,
    disabled: false,
    size: 'lg',
    outline: true,
  };

  constructor() {
    super();
    this.state = {
      modal: false,
    };

    this.toggle = this.toggle.bind(this);
  }

  toggle(e) {
    e.stopPropagation();
    this.setState({
      modal: !this.state.modal,
    });
  }

  render() {
    const { color, btn, title, message, colored, header, disabled, size, outline, t } = this.props;
    let Icon;

    switch (color) {
      case 'primary':
        Icon = <span className="lnr lnr-pushpin modal__title-icon" />;
        break;
      case 'success':
        Icon = <span className="lnr lnr-thumbs-up modal__title-icon" />;
        break;
      case 'warning':
        Icon = <span className="lnr lnr-flag modal__title-icon" />;
        break;
      case 'danger':
        Icon = <span className="lnr lnr-cross-circle modal__title-icon" />;
        break;
      default:
        break;
    }
    const modalClass = classNames({
      'modal-dialog--colored': colored,
      'modal-dialog--header': header,
    });

    return (
      <React.Fragment>
        <Button outline={outline} color={color} disabled={disabled} size={size} onClick={e => this.toggle(e)}>
          {btn}
        </Button>

        <Modal isOpen={this.state.modal} toggle={this.toggle} className={`modal-dialog--${color} ${modalClass}`}>
          <div className="modal__header">
            <button className="lnr lnr-cross modal__close-btn" onClick={this.toggle} />
            {header ? '' : Icon}
            <h4 className="bold-text  modal__title">{title}</h4>
          </div>
          <div className="modal__body">{message}</div>
          <ButtonToolbar className="modal__footer">
            <Button onClick={this.toggle}>{t('form.cancel')}</Button>{' '}
            <Button
              outline={colored}
              color={color}
              onClick={e => {
                this.props.onConfirm(e);
                this.toggle(e);
              }}
            >
              {t('form.confirm')}
            </Button>
          </ButtonToolbar>
        </Modal>
      </React.Fragment>
    );
  }
}

export default translate('common')(ModalComponent);
