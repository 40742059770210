import React from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-i18next';
import { Field, reduxForm } from 'redux-form';
import renderRadioButtonField from '../../../shared/components/form/RadioButton';
import renderCheckBoxField from '../../../shared/components/form/CheckBox';

const CheckboxForm = ({ handleSubmit, t, disableWorksitesOnly, disableInactiveOnly }) => (
  <form className="form" onSubmit={handleSubmit}>
    <div className="form__half">
      <div className="form__form-group" style={{ marginBottom: '20px' }}>
        <div className="form__form-group-field">
          <Field
            name="radio_worksites_workers"
            component={renderRadioButtonField}
            label={t('finance.viewByWorkers')}
            defaultChecked
            radioValue="2"
            className="colored-click"
          />
        </div>
      </div>

      <div className="form__form-group" style={{ marginBottom: '6px' }}>
        <div className="form__form-group-field">
          <Field
            name="radio_worksites_workers"
            component={renderRadioButtonField}
            label={t('finance.viewByWorksites')}
            radioValue="1"
            className="colored-click"
          />
        </div>
      </div>
    </div>

    <div className="form__half">
      <div className="form__form-group" style={{ marginBottom: '20px' }}>
        <div className="form__form-group-field">
          <Field
            name="checkbox_worksites_only"
            component={renderCheckBoxField}
            label={t('finance.worksitesOnly')}
            className="colored-click"
            disabled={disableWorksitesOnly}
          />
        </div>
      </div>

      <div className="form__form-group" style={{ marginBottom: '6px' }}>
        <div className="form__form-group-field">
          <Field
            name="checkbox_inactive_only"
            component={renderCheckBoxField}
            label={t('finance.inactiveOnly')}
            className="colored-click"
            disabled={disableInactiveOnly}
          />
        </div>
      </div>
    </div>
  </form>
);

CheckboxForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  disableWorksitesOnly: PropTypes.bool.isRequired,
  disableInactiveOnly: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
};

CheckboxForm.defaultProps = {};

export default reduxForm({
  form: 'checkbox_form_wages_addons',
  onChange: (values, dispatch, props) => {
    props.submit();
  },
})(translate('common')(CheckboxForm));
