import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody, Col, ButtonToolbar, Button } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import renderSelectField from '../../../../../shared/components/form/Select';

const QuickDropdownForm = ({ handleSubmit, handleCancel, options, pristine }) => (
  <Col md={12} lg={12}>
    <Card>
      <CardBody>
        <form className="form" onSubmit={handleSubmit}>
          <div className="form__form-group">
            <span className="form__form-group-label">Avto se trenutno nahaja na gradbišču:</span>
            <div className="form__form-group-field">
              <Field name="worksiteId" component={renderSelectField} options={options} />
            </div>
          </div>

          <ButtonToolbar className="form__button-toolbar">
            <Button type="button" onClick={handleCancel}>
              Prekliči
            </Button>
            <Button color="primary" type="submit" disabled={pristine}>
              Portdi
            </Button>
          </ButtonToolbar>
        </form>
      </CardBody>
    </Card>
  </Col>
);

QuickDropdownForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  pristine: PropTypes.bool.isRequired,
};

export default reduxForm({
  form: 'vozila_qdd_form', // a unique identifier for this form
})(QuickDropdownForm);
