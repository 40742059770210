import socketIOClient from 'socket.io-client';
import { SERVER_LINK, CONFIG } from 'constants/config';
import { getItem } from 'helpers/cookies';
import payload from 'helpers/jwt';
import { updateStateFromLocal } from '../dataManager';

const portParser = link => {
  const a = document.createElement('a');
  a.href = link;
  console.log('SERVER LINK TRENUTNI PORT', a.port);

  const token = getItem('jwt');
  if (!token) {
    console.log('SERVER LINK TOKEN MISSING ERROR!!');
    console.log('TCL: portParser -> link', link);
    return link;
  }
  const { port } = payload(token);
  if (!port) {
    console.log('SERVER LINK PORT MISSING ERROR!!');
    console.log('TCL: portParser -> link', link);
    return link;
  }

  a.port = port;
  console.log('SERVER LINK NOVI HREF', a.href);
  return a.href;
};

export default class SSEManager {
  socket;

  constructor() {
    this.connect();
  }

  connect() {
    console.log('TCL: SSEManager -> connect -> connect');
    this.socket = socketIOClient(portParser(SERVER_LINK));
  }

  disconnect() {
    console.log('TCL: SSEManager -> disconnect -> disconnect');
    this.socket.disconnect();
  }

  registerUpdateListener() {
    console.log('TCL: SSEManager -> registerUpdateListener -> registerUpdateListener');
    this.socket.on('update', data => {
      const { datalist, type } = JSON.parse(data);
      console.log('TCL: SSEManager -> registerUpdateListener -> type', type);
      console.log('TCL: SSEManager -> registerUpdateListener -> datalist', datalist);
      console.log('DOBIL NOVI STATE... updateam localno');

      const newState = {};
      switch (type) {
        case CONFIG.states.workers:
          newState.employees = datalist;
          updateStateFromLocal(newState, CONFIG.states.workers);
          break;
        case CONFIG.states.companies:
          newState.companies = datalist;
          updateStateFromLocal(newState, CONFIG.states.companies);
          break;
        case CONFIG.states.orderers:
          newState.orderers = datalist;
          updateStateFromLocal(newState, CONFIG.states.orderers);
          break;
        case CONFIG.states.worksites:
          newState.worksites = datalist;
          updateStateFromLocal(newState, CONFIG.states.worksites);
          break;
        case CONFIG.states.accommodations:
          newState.accommodations = datalist;
          updateStateFromLocal(newState, CONFIG.states.accommodations);
          break;
        case CONFIG.states.providers:
          newState.providers = datalist;
          updateStateFromLocal(newState, CONFIG.states.providers);
          break;
        case CONFIG.states.vehicles:
          newState.vehicles = datalist;
          updateStateFromLocal(newState, CONFIG.states.vehicles);
          break;
        case CONFIG.states.ows:
          newState.ows = datalist;
          updateStateFromLocal(newState, CONFIG.states.ows);
          break;

        default:
          break;
      }
      console.log('TCL: SSEManager -> registerUpdateListener -> newState', newState);
    });
  }
}
