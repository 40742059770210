import React, { Component, Fragment } from 'react';
import he from 'he';
import PropTypes from 'prop-types';
import { translate } from 'react-i18next';
import { Col, Card, Row, CardBody } from 'reactstrap';
import { connect } from 'react-redux';
import providersApi from 'api/providers';
import accommodationsApi from 'api/accommodations';
import NotificationSystem from 'rc-notification';
import { BasicNotification } from 'shared/components/Notification';
import CloseIcon from 'mdi-react/CloseIcon';
import ArrowDownCircle from 'mdi-react/ArrowDropDownCircleIcon';
import ArrowUpCircle from 'mdi-react/ArrowUpDropCircleIcon';
import PlusIcon from 'mdi-react/PlusCircleIcon';
import { AccommodationsProps } from 'shared/prop-types/ReducerProps';
import Carousel from './Carousel';
import Amenity from './Amenity';

let notificationLU = null;
let notificationRU = null;
let notificationTC = null;

const showNotification = ({ notification, position }) => {
  switch (position) {
    case 'left-up':
      notificationLU.notice({
        content: notification,
        duration: 5,
        closable: true,
        style: { top: 0, left: 240 },
        className: position,
      });
      break;
    case 'right-up':
      notificationRU.notice({
        content: notification,
        duration: 5,
        closable: true,
        style: { top: 0, left: 'calc(100vw - 100%)' },
        className: position,
      });
      break;
    default:
      notificationTC.notice({
        content: notification,
        duration: 5,
        closable: true,
        style: { top: 0, left: 0 },
        className: position,
      });
      break;
  }
};
class Place extends Component {
  static propTypes = {
    place: PropTypes.object.isRequired,
    accommodationStore: AccommodationsProps.isRequired,
    t: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      lightboxOpen: false,
      showMoreOpen: false,
    };
  }

  componentDidMount() {
    document.addEventListener('keydown', this.closeLightboxKeypress, false);

    NotificationSystem.newInstance({}, n => (notificationLU = n));
    NotificationSystem.newInstance({}, n => (notificationRU = n));
    NotificationSystem.newInstance({}, n => (notificationTC = n));
  }

  getZipAndLocation = () => {
    const { place } = this.props;
    const locationSplit = place.location.cityAndZip.split(' ');
    const zip = locationSplit[0];
    const city = locationSplit.slice(1, locationSplit.length).join(' ');
    return {
      zip,
      city,
    };
  };

  openLightbox = () => {
    this.setState({ lightboxOpen: true });
  };

  closeLightbox = () => {
    this.setState({ lightboxOpen: false });
  };

  toggleShowMore = () => {
    this.setState(prevState => ({ showMoreOpen: !prevState.showMoreOpen }));
  };

  closeShowMore = () => {
    this.setState({ showMoreOpen: false });
  };

  showNotif = ({ title, message, success }) => {
    const color = success ? 'success' : 'danger';
    showNotification({
      notification: <BasicNotification color={color} title={title} message={message} />,
      position: 'right-up',
    });
  };

  findProvider = name => {
    const {
      accommodationStore: { providerTitles },
    } = this.props;
    return providerTitles.find(prov => prov.label === name);
  };

  createProvider = async providerBody => providersApi.create(providerBody);

  findOrCreateProvider = async (name, providerBody) => {
    let provider = this.findProvider(name);
    if (!provider) {
      provider = await this.createProvider(providerBody);
    }
    return provider;
  };

  isInDatabase = () => {
    const {
      accommodationStore: { accommodations },
      place: { mapsObject },
    } = this.props;
    const { zip, city } = this.getZipAndLocation();
    // eslint-disable-next-line max-len
    const accommodation = accommodations.find(
      acc => acc.address === `${mapsObject.Street} ${mapsObject.HouseNumber}` && acc.zip === zip && acc.city === city,
    );
    return accommodation;
  };

  addToDatabase = async () => {
    if (this.isInDatabase()) {
      return;
    }
    const { place } = this.props;
    const providerBody = {
      title: he.decode(place.name),
      tel: place.phoneNumbers[0],
      contact: place.contactPerson,
    };
    try {
      const provider = await this.findOrCreateProvider(he.decode(place.name), providerBody);
      if (provider) {
        const providerId = provider._id || provider.value;
        const { zip, city } = this.getZipAndLocation();
        const accommodationBody = {
          title: place.name,
          address: `${place.mapsObject.Street} ${place.mapsObject.HouseNumber}`,
          zip,
          city,
          countryId: place.location.country,
          providerId,
        };
        const accommodation = await accommodationsApi.create(accommodationBody);
        if (accommodation) {
          this.showNotif({
            title: 'Uspešno dodano!',
            message: 'Uspešno dodano v lastno bazo',
            success: true,
          });
        }
      }
    } catch (err) {
      console.log('There was an error while adding to database', err);
      this.showNotif({
        title: 'Napaka!',
        message: 'Prišlo je do napake pri dodajanju v lastno bazo',
        success: false,
      });
    }
  };

  closeLightboxKeypress = e => {
    if (e.key === 'Escape') {
      this.closeLightbox();
    }
  };

  fullAddress = () => {
    const { place } = this.props;
    return place.mapsObject.Label;
  };

  encodedAddress = () => encodeURIComponent(this.fullAddress());

  renderTelephoneNumbers = () => {
    const { place } = this.props;
    return place.phoneNumbers.map(number => <li>{number}</li>);
  };

  renderAmenities = () => {
    const {
      place: { amenities },
    } = this.props;
    if (!amenities) {
      return null;
    }
    return amenities.map(amenity => <Amenity amenity={amenity} />);
  };

  render() {
    const { place, t } = this.props;
    const { showMoreOpen } = this.state;
    return (
      <Col xs={12} md={6}>
        <Card>
          <CardBody className="d-flex flex-column justify-content-between">
            <div className="card__title">
              <Row>
                <Col xs={12} md={12} lg={6} className="place__carousel">
                  <Carousel onImageClick={this.openLightbox} images={place.images} />
                </Col>
                <Col xs={12} md={6}>
                  <div className="d-flex flex-column justify-content-between h-100 place-bottom-padding">
                    <div className="desc">
                      <div className="d-flex justify-content-between">
                        <h3 className="bold-text">
                          <a
                            href={`https://www.google.com/maps/search/?api=1&query=${this.encodedAddress()}`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {he.decode(place.name)}
                          </a>
                        </h3>
                        <span className={`${this.isInDatabase() ? '' : 'c-pointer'} mt-1`}>
                          <PlusIcon onClick={this.addToDatabase} color={this.isInDatabase() ? '#818181' : '#70bbfd'} />
                        </span>
                      </div>
                      <h5 className="subhead">{this.fullAddress()}</h5>
                      {place.distance && (
                        <h5 className="subhead">
                          {t('places.card.distance')}: <b>{Math.round(place.distance)} km</b>
                        </h5>
                      )}
                      {place.people && place.people.from && place.people.to && (
                        <h5 className="subhead">
                          {t('places.card.people', {
                            from: place.people.from,
                            to: place.people.to,
                          })}
                        </h5>
                      )}
                      {place.price ? (
                        <h5 className="subhead">{t('places.card.priceFrom', { price: place.price })}</h5>
                      ) : (
                        <h5 className="subhead">{t('places.card.priceNegotiable')}</h5>
                      )}
                      <h5 className="subhead">
                        {t('places.card.contactPerson', {
                          contactPerson: place.contactPerson,
                        })}
                      </h5>
                      {place.phoneNumbers && (
                        <h5 className="subhead">
                          {t('places.card.phoneNumbers')}
                          <ul>{this.renderTelephoneNumbers()}</ul>
                        </h5>
                      )}
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
            <Row>
              <Col xs={12}>
                <div className="amenities-list">{this.renderAmenities()}</div>
              </Col>
            </Row>
            {place.description && place.description.length > 0 && (
              <Fragment>
                {showMoreOpen && (
                  <Fragment>
                    <div className="preformatted">{place.description}</div>
                    <div className="text-center">
                      <ArrowUpCircle onClick={this.toggleShowMore} color="#70bbfd" className="c-pointer" />
                    </div>
                  </Fragment>
                )}{' '}
                {!showMoreOpen && (
                  <div className="text-center">
                    <ArrowDownCircle onClick={this.toggleShowMore} color="#70bbfd" className="c-pointer" />
                  </div>
                )}
              </Fragment>
            )}
          </CardBody>
        </Card>
        {this.state.lightboxOpen && (
          <div
            className="hures-lightbox"
            onClick={this.closeLightbox}
            onKeyPress={this.closeLightboxKeypress}
            role="presentation"
          >
            <div className="close-icon">
              <CloseIcon onClick={this.closeLightbox} />
            </div>
            <div
              className="carousel-container"
              onClick={e => e.stopPropagation()}
              onKeyPress={e => e.stopPropagation()}
              role="presentation"
            >
              <Carousel onImageClick={() => console.log('eyyo')} images={place.images} />
            </div>
          </div>
        )}
      </Col>
    );
  }
}
const reduxed = connect(state => ({
  accommodationStore: state.accommodations,
}))(Place);
export default translate('common')(reduxed);
