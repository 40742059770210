import React from 'react';
import PropTypes from 'prop-types';
import { Button, Modal } from 'reactstrap';
import ReactGA from 'react-ga';
import classNames from 'classnames';
import companiesApi from 'api/companies';
import AddIcon from 'mdi-react/AccountMultiplePlusOutlineIcon';
import EditIcon from 'mdi-react/AccountEditIcon';

import PodjetjaForm from './PodjetjaForm';

class PodjetjaModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
    };

    this.toggle = this.toggle.bind(this);
    this.cancel = this.cancel.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleESC = this.handleESC.bind(this);
  }

  componentDidMount() {
    document.addEventListener('keydown', this.handleESC, false);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleESC, false);
  }

  handleESC(event) {
    if (event.keyCode === 27) {
      this.setState({ modal: false });
      this.cancel(null);
    }
  }

  toggle() {
    if (!this.state.modal) {
      ReactGA.event({
        category: 'Napotitve',
        action: `Odprtje modala za Podjetja - ${this.props.editData ? 'Urejanje' : 'Dodajanje'}`,
      });
    }
    this.setState({
      modal: !this.state.modal,
    });
  }

  cancel(data) {
    this.setState({
      modal: false,
    });
    this.props.onClose(data);
  }

  async handleDelete(data) {
    const successMessage = `Podjetje ${data.name} uspešno izbrisano!`;
    const errorMessage = `Podjetje ${data.name} NI uspešno izbrisano!`;

    try {
      const success = await companiesApi.delete({ _id: data._id });
      if (success) {
        this.cancel({
          title: 'Izbris podjetja',
          message: successMessage,
          success: true,
        });
      } else {
        this.cancel({
          title: 'Izbris podjetja',
          message: errorMessage,
          success: false,
        });
      }
    } catch (error) {
      console.log('TCL: PodjetjaModal -> handleDelete -> error', error);
      this.cancel(null);
    }
  }

  async handleSubmit(values) {
    console.log('TCL: PodjetjaModal -> handleSubmit -> values', values);
    const { icon } = this.props;

    const isEdit = icon !== 'add';
    try {
      const data = {
        name: values.name,
        address: values.address,
        zip: values.zip,
        city: values.city,
        taxID: values.taxID,
        registrationNum: values.registrationNum,
        agent: values.agent,
        color: values.color.hex ? values.color.hex.toUpperCase() : undefined,
        countryId: values.countryId.value,
        coop: values.coop || false,
      };

      if (isEdit) {
        data._id = values._id;
      }

      const notifTitle = isEdit ? 'Uredi podjetje' : 'Dodaj podjetje';
      const successMessage = isEdit
        ? `Podjetje ${values.name} uspešno posodobljeno!`
        : `Podjetje ${values.name} uspešno dodano!`;
      const errorMessage = isEdit
        ? `Podjetje ${values.name} ni uspešno posodobljeno!`
        : `Podjetje ${values.name} ni uspešno dodano!`;

      const success = isEdit ? await companiesApi.update(data) : await companiesApi.add(data);
      if (success) {
        this.cancel({
          title: notifTitle,
          message: successMessage,
          success: true,
        });
      } else {
        this.cancel({
          title: notifTitle,
          message: errorMessage,
          success: false,
        });
      }
    } catch (e) {
      console.log(e);
      this.cancel(null);
    }
  }

  render() {
    const { icon, btn, title, colored, header, hasBtn, show, editData, countries } = this.props;
    let Icon;

    switch (icon) {
      case 'add':
        Icon = <AddIcon />;
        break;
      case 'edit':
        Icon = <EditIcon />;
        break;
      default:
        break;
    }
    const modalClass = classNames({
      'modal-dialog--colored': colored,
      'modal-dialog--header': header,
    });

    return (
      <div style={{ width: '100%' }}>
        {hasBtn ? (
          <Button size="sm" color="primary" onClick={this.toggle}>
            {btn}
          </Button>
        ) : null}

        <Modal isOpen={this.state.modal || show} toggle={this.toggle} className={`modal-dialog--primary ${modalClass}`}>
          <div className="modal__header">
            <button className="lnr lnr-cross modal__close-btn" onClick={this.toggle} />
            {header ? '' : Icon}
            <h4 className="bold-text  modal__title">{title}</h4>
          </div>
          <div className="modal__body">
            <div className="theme-light">
              {editData ? (
                <PodjetjaForm
                  onSubmit={this.handleSubmit}
                  countries={countries}
                  handleCancel={() => this.cancel(null)}
                  handleDelete={this.handleDelete}
                  initialValues={editData}
                  editData={editData}
                />
              ) : (
                <PodjetjaForm
                  onSubmit={this.handleSubmit}
                  countries={countries}
                  handleCancel={() => this.cancel(null)}
                  handleDelete={this.handleDelete}
                />
              )}
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

PodjetjaModal.propTypes = {
  title: PropTypes.string,
  countries: PropTypes.arrayOf(PropTypes.object).isRequired,
  icon: PropTypes.string.isRequired,
  colored: PropTypes.bool,
  header: PropTypes.bool,
  btn: PropTypes.string.isRequired,
  hasBtn: PropTypes.bool.isRequired,
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  editData: PropTypes.object,
};

PodjetjaModal.defaultProps = {
  title: '',
  colored: false,
  header: false,
  editData: null,
};

export default PodjetjaModal;
