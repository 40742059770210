import { API_LINK } from 'constants/config';
import { GET, POST } from 'helpers/agent';

export default {
  getall: async () => {
    const data = await GET(`${API_LINK}/com/getall`);
    if (data.success) {
      return { companies: data.companies };
    }
    return { companies: [] };
  },
  getfull: async () => {
    const data = await POST(`${API_LINK}/com/getfull`);
    if (data.success) {
      return { companies: data.companies };
    }
    return { companies: [] };
  },
  add: async data => {
    const response = await POST(`${API_LINK}/com/addcompany`, JSON.stringify(data));
    return response.success;
  },
  update: async data => {
    const response = await POST(`${API_LINK}/com/update`, JSON.stringify(data));
    return response.success;
  },
  delete: async data => {
    const response = await POST(`${API_LINK}/com/delete`, JSON.stringify(data));
    return response.success;
  },
  getone: async id => {
    const data = await POST(`${API_LINK}/com/getone`, JSON.stringify(id));
    if (data.success) {
      return { company: data.company };
    }
    return { company: null };
  },
};
