import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactLoading from 'react-loading';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import ReactGA from 'react-ga';
// eslint-disable-next-line import/no-extraneous-dependencies
import 'bootstrap/dist/css/bootstrap.css';
import '../../scss/app.scss';
import Router from './Router';

import { showDocsExpBadge } from '../../redux/actions/docsActions';
import { getItem, removeItem } from '../../helpers/cookies';
import { setUser } from '../../redux/actions/userActions';
import payload from '../../helpers/jwt';
import getRoles from '../../constants/roles';
import { AppStatusProps } from '../../shared/prop-types/ReducerProps';
import { downloadAllRequiredData } from '../../dataManager';
import Loading from '../../shared/components/Loading';
import SSEManager from '../../api/sse';
import { persistor } from './store';
import { FRONTEND_LINK } from '../../constants/config';

class App extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    appStatus: AppStatusProps.isRequired,
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
  };

  constructor() {
    super();
    this.state = {
      loading: true,
      loaded: false,
      ROLES: getRoles(),
    };

    this.checkDocs = this.checkDocs.bind(this);
    this.checkRoutes(true);
  }

  componentDidMount() {
    const {
      appStatus: { requiredDataLoaded },
    } = this.props;

    this.checkRoutes();

    const token = getItem('jwt');
    if (token) {
      if (!this._sseManager) {
        this._sseManager = new SSEManager();
        this._sseManager.registerUpdateListener();
      }
      const userData = payload(token);
      const { exp } = userData;
      if (exp * 1000 <= Date.now()) {
        removeItem('jwt');
        window.location.reload();
      } else {
        this.props.dispatch(setUser(userData));
        ReactGA.set({ username: userData.username });
      }
    }

    if (!requiredDataLoaded && getItem('jwt')) {
      console.log('TCL: App -> componentDidMount -> requiredDataLoaded', requiredDataLoaded);
      downloadAllRequiredData();
      return;
    }

    window.addEventListener('load', () => {
      this.setState({ loading: false });
      setTimeout(() => this.setState({ loaded: true }), 500);
    });
  }

  componentWillUpdate(nextProps) {
    const {
      appStatus: { requiredDataLoaded, appLoading },
    } = nextProps;
    if (!this.props.appStatus.requiredDataLoaded && requiredDataLoaded) {
      // eslint-disable-next-line react/no-will-update-set-state
      this.setState({ loaded: true, loading: false });
    }

    if (!this.props.appStatus.requiredDataLoaded && !this.props.appStatus.appLoading && !appLoading && getItem('jwt')) {
      console.log('ZAZNAL DA NI PODATKOV, GREM V LOADING...!');
      if (!this._sseManager) {
        this._sseManager = new SSEManager();
        this._sseManager.registerUpdateListener();
      }
      downloadAllRequiredData();
      // eslint-disable-next-line react/no-will-update-set-state
      this.setState({ loaded: false, loading: true });
    }

    const routeChanged = this.props.location.pathname !== nextProps.location.pathname;
    this.checkRoutes(routeChanged);
  }

  _sseManager;

  checkRoutes(routeChanged = false) {
    const { ROLES } = this.state;
    const { pathname } = window.location;
    const token = getItem('jwt');
    if (!token && pathname !== '/prijava' && !pathname.includes('/ure/popravi')) {
      // da je state reset uspešen mora bit takšen reload!
      persistor.purge();
      window.location.replace(`${FRONTEND_LINK}/prijava`);
    }

    if (token) {
      let forbidden = false;

      //TODO rework role check
      if (ROLES) {
        if (!ROLES.full) {
          if (
            ROLES.workhours &&
            ROLES.workhours.readonly &&
            (pathname === '/ure/delavci' || pathname === '/ure/gradbisca')
          ) {
            forbidden = true;
          } else if (
            !ROLES.workhours &&
            (pathname === '/ure/gradbisca' || pathname === '/ure/delavci' || pathname === '/ure/izpis')
          ) {
            forbidden = true;
          } else if (!ROLES.assignments && pathname === '/napotitve') {
            forbidden = true;
          } else if (!ROLES.changes && pathname === '/spremembe') {
            forbidden = true;
          } else if (!ROLES.companies && pathname === '/podjetja') {
            forbidden = true;
          } else if (!ROLES.workers && (pathname === '/delavci' || pathname === '/delavci/zgodovina')) {
            forbidden = true;
          } else if (!ROLES.orderers && pathname === '/narocniki') {
            forbidden = true;
          } else if (!ROLES.worksites && pathname === '/gradbisca') {
            forbidden = true;
          } else if (
            !ROLES.accommodations &&
            (pathname === '/prenocisca' ||
              pathname === '/prenocisca/rezervacije' ||
              pathname === '/prenocisca/ponudniki' ||
              pathname === '/prenocisca/zgodovina')
          ) {
            forbidden = true;
          } else if (!ROLES.vehicles && (pathname === '/vozila' || pathname === '/vozila/zgodovina')) {
            forbidden = true;
          }
        }

        //console.log('App -> checkRoutes -> forbidden', forbidden);
        //todo fix roles for finance
        if (!forbidden) {
          forbidden =
            !(ROLES.super || ROLES.sistem) &&
            (pathname === '/finance' || pathname === '/finance/wages' || pathname === '/finance/cashbox');
        }
        if (pathname === '/finance/wages' && (ROLES.satnice || ROLES.projekt || ROLES.sistem)) {
          forbidden = false;
        }
        if (pathname === '/finance/cashbox' && (ROLES.cashbox || ROLES.sistem)) {
          forbidden = false;
        }

        if (forbidden) {
          if (this.props) {
            this.props.history.replace('/tabla');
          } else {
            window.location.replace(`${FRONTEND_LINK}/tabla`);
          }
        }
        if (routeChanged) {
          ReactGA.pageview(pathname);
        }
      }
    }
  }

  async checkDocs() {
    this.props.dispatch(showDocsExpBadge(true));
  }

  render() {
    const { loaded, loading } = this.state;

    const {
      appStatus: { appLoadingStatus },
    } = this.props;

    let status = <span />;
    if (appLoadingStatus) {
      status =
        appLoadingStatus.split('&&').length === 1 ? (
          <div style={{ width: '100%' }}>
            <div style={{ margin: '0 auto', textAlign: 'center' }}>{appLoadingStatus}</div>
          </div>
        ) : (
          <div style={{ width: '100%' }}>
            <div style={{ margin: '0 auto', textAlign: 'center' }}>{appLoadingStatus.split('&&')[0]}</div>
            <div
              style={{
                margin: '0 auto',
                textAlign: 'center',
                color: 'DarkTurquoise',
              }}
            >
              {appLoadingStatus.split('&&')[1]}
            </div>
          </div>
        );
    }

    return (
      <div>
        {!loaded && (
          <div className={`load${loading ? '' : ' loaded'}`}>
            <div className="load__icon-wrap">
              <ReactLoading type="spin" color="cyan" height={128} width={128} className="react-loader" />
              <h2 style={{ marginTop: '50px', color: 'lightgray' }}>{status}</h2>
              <h2 style={{ marginTop: '10px', color: 'lightgray' }}>
                <Loading />
              </h2>
            </div>
          </div>
        )}
        <div>
          <Router />
        </div>
      </div>
    );
  }
}

export default withRouter(
  connect(state => ({
    appStatus: state.appStatus,
  }))(App),
);
