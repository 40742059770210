import { LOGIN_LINK } from 'constants/config';
import { POST } from 'helpers/agent';

export default {
  login: async (username, password) => {
    const body = JSON.stringify({
      username,
      password,
    });
    const data = await POST(`${LOGIN_LINK()}`, body);
    console.log('TCL: data', data);
    if (data.success) {
      return { success: true, token: data.token, user: data.user };
    }
    return { success: false, error: data.error };
  },
};
