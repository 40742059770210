const validate = values => {
  const errors = {};
  if (!values.title) {
    errors.title = 'Dieses Feld muss ausgefüllt werden!';
  }

  return errors;
};

export default validate;
