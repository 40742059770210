export default (givenMonth, year, additional = []) => {
  const month = givenMonth - 1;
  const date = new Date(year, month, 1);
  const days = [];
  // let additionalCopy = additional;
  while (date.getMonth() === month) {
    days.push(new Date(date));
    let index = additional.indexOf(date.getDate());
    while (index > -1) {
      // console.log('mamo dodatni dan za vrinit');
      days.push(new Date(date));
      additional.splice(index, 1);
      // console.log('additional', additional);
      index = additional.indexOf(date.getDate());
    }

    date.setDate(date.getDate() + 1);
  }

  // console.log('days', days);

  return days;
};
