import { API_LINK } from 'constants/config';
import { GET } from 'helpers/agent';

export default {
  getall: async () => {
    const data = await GET(`${API_LINK}/c/getall?lang=${localStorage.getItem('lang') || 'sl'}`);
    if (data.success) {
      return { countries: data.countries };
    }
    return { countries: [] };
  },
};
