import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-i18next';
import { Popover, PopoverHeader, PopoverBody } from 'reactstrap';
import { NoteIcon } from 'mdi-react';
import EditNoteModal from '../Modals/EditNoteModal';

class Note extends Component {
  static propTypes = {
    note: PropTypes.string,
    id: PropTypes.string.isRequired,
    owId: PropTypes.string.isRequired,
    t: PropTypes.func.isRequired,
  };

  static defaultProps = {
    note: null,
  };

  constructor(props) {
    super(props);

    this.state = {
      popoverOpen: false,
      modalOpen: false,
    };
  }

  openPopover = () => {
    this.setState({ popoverOpen: true });
  };

  closePopover = () => {
    this.setState({ popoverOpen: false });
  };

  toggleModal = () => {
    this.setState(prevState => ({ modalOpen: !prevState.modalOpen }));
  };

  updateNote = value => {
    console.log('update', value);
  };

  render() {
    const { note, id, owId, t } = this.props;
    const { modalOpen } = this.state;
    return (
      <Fragment>
        <div className="note" id={id}>
          <NoteIcon
            onMouseEnter={this.openPopover}
            onMouseLeave={this.closePopover}
            onClick={this.toggleModal}
            color="#70bbfd"
          />
        </div>
        <Popover placement="bottom" isOpen={this.state.popoverOpen} target={id} toggle={this.toggle}>
          <PopoverHeader>{t('board.notes.popoverTitle')}</PopoverHeader>
          <PopoverBody>{note || t('board.notes.popoverEmptyDefault')}</PopoverBody>
        </Popover>
        <EditNoteModal note={note} id={owId} isOpen={modalOpen} toggle={this.toggleModal} />
      </Fragment>
    );
  }
}

export default translate('common')(Note);
