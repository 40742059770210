import React, { Component } from 'react';
import { translate } from 'react-i18next';
import { CardTitle, Input } from 'reactstrap';
import { MinusIcon } from 'mdi-react';
import PropTypes from 'prop-types';

class EmployeeRange extends Component {
  static propTypes = {
    onChange: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      from: null,
      to: null,
    };
  }

  handleFrom = event => {
    this.setState(
      {
        from: event.target.value,
      },
      this.handleChange,
    );
  };

  handleTo = event => {
    this.setState(
      {
        to: event.target.value,
      },
      this.handleChange,
    );
  };

  handleChange = () => {
    const { from, to } = this.state;
    this.props.onChange(from, to);
  };

  render() {
    const { t } = this.props;
    const { from, to } = this.state;
    return (
      <React.Fragment>
        <CardTitle>{t('places.filters.employeeRange')}</CardTitle>
        <div className="employee-range">
          <Input
            type="text"
            name="from"
            className="input from"
            onChange={this.handleFrom}
            value={from}
            placeholder={t('places.filters.from')}
          />
          <MinusIcon />
          <Input
            type="text"
            name="to"
            className="input to"
            onChange={this.handleTo}
            value={to}
            placeholder={t('places.filters.to')}
          />
        </div>
      </React.Fragment>
    );
  }
}

export default translate('common')(EmployeeRange);
