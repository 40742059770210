import React, { Component } from 'react';
import { Col, Row, Button } from 'reactstrap';
import { translate } from 'react-i18next';
import { PropTypes } from 'prop-types';
import getRoles from 'constants/roles';
import SearchFilter from './components/SearchFilter';
import DateFilter from './components/DateFilter';
import AssignmentsFormModal from '../Modals/AssignmentsFormModal';
import LeavesFormModal from '../Modals/LeavesFormModal';

class Filters extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      assignmentsFormModalShown: false,
      leavesFormModalShown: false,
      ROLES: getRoles(),
    };
  }

  showAssignmentsFormModal = () => {
    this.setState({
      assignmentsFormModalShown: true,
    });
  };

  hideAssignmentsFormModal = () => {
    this.setState({
      assignmentsFormModalShown: false,
    });
  };

  showLeavesFormModal = () => {
    this.setState({
      leavesFormModalShown: true,
    });
  };

  hideLeavesFormModal = () => {
    this.setState({
      leavesFormModalShown: false,
    });
  };

  render() {
    const { assignmentsFormModalShown, leavesFormModalShown, ROLES } = this.state;
    const { t } = this.props;
    return (
      <Row>
        <Col md={6}>
          <SearchFilter />
        </Col>
        <Col md={ROLES.full ? 4 : 6}>
          <DateFilter />
        </Col>
        {ROLES.full && (
          <Col md={2} className="filter-actions">
            <Button className="w-100" color="primary" onClick={this.showAssignmentsFormModal}>
              {t('board.filters.buttons.assignment_add')}
            </Button>
            <Button className="w-100" color="primary" onClick={this.showLeavesFormModal}>
              {t('board.filters.buttons.leaves')}
            </Button>
          </Col>
        )}
        {assignmentsFormModalShown && (
          <AssignmentsFormModal
            icon="add"
            title={t('board.filters.buttons.assignment_add')}
            show={assignmentsFormModalShown}
            onClose={this.hideAssignmentsFormModal}
          />
        )}
        {leavesFormModalShown && (
          <LeavesFormModal
            icon="add"
            title={t('board.filters.buttons.leaves')}
            show={leavesFormModalShown}
            onClose={this.hideLeavesFormModal}
          />
        )}
      </Row>
    );
  }
}

export default translate('common')(Filters);
