import React from 'react';
import { Card, CardBody, Col, Table } from 'reactstrap';
import PropTypes from 'prop-types';
import { translate } from 'react-i18next';

const renderTable = asslogs =>
  asslogs.map(alog => (
    <tr key={alog._id} id={alog._id}>
      <td>{alog.dateString}</td>
      <td>{alog.dateEnd ? alog.dateEndString : '/'}</td>
      <td>{alog.orderer}</td>
      <td>{alog.worksite}</td>
      <td>{alog.leave || '/'}</td>
    </tr>
  ));

const DelavciZgodovinaTable = ({ title, asslogs, t }) => (
  <Col md={12} lg={12} xl={12}>
    <Card>
      <CardBody>
        <div className="card__title">
          <h5 className="bold-text">{title}</h5>
          {/* <h5 className="subhead">lol</h5> */}
        </div>
        <Table className="table--bordered" responsive>
          <thead>
            <tr>
              <th>{t('misc.beginDate')}</th>
              <th>{t('misc.endDate')}</th>
              <th>{t('board.ow.orderer')}</th>
              <th>{t('form.worksite')}</th>
              <th>{t('board.ow.leave')}</th>
            </tr>
          </thead>
          <tbody>{renderTable(asslogs)}</tbody>
        </Table>
      </CardBody>
    </Card>
  </Col>
);

DelavciZgodovinaTable.propTypes = {
  title: PropTypes.string.isRequired,
  asslogs: PropTypes.arrayOf(PropTypes.object).isRequired,
  t: PropTypes.func.isRequired,
};

export default translate('common')(DelavciZgodovinaTable);
