import {
  SET_PLACES_LOADING,
  SET_PLACES,
  SET_SEARCH_PLACES,
  CLEAR_SEARCH,
  SET_ERROR,
  CLEAR_PLACES,
} from '../actions/placesActions';

const initialState = {
  loaded: false,
  places: [],
  searchPlaces: null,
  error: null,
  loading: false,
};

export default function(state = initialState, action) {
  switch (action.type) {
    case SET_PLACES:
      return {
        ...state,
        places: [...state.places, ...action.payload.places],
        searchPlaces: null,
        filtered: null,
        loaded: true,
        loading: false,
      };
    case SET_SEARCH_PLACES:
      return {
        ...state,
        searchPlaces: action.payload.searchedPlaces,
        loaded: true,
        loading: false,
      };
    case CLEAR_SEARCH:
      return { ...state, searchPlaces: null };
    case CLEAR_PLACES:
      return { ...state, places: [] };
    case SET_PLACES_LOADING:
      return { ...state, loaded: false, loading: true };
    case SET_ERROR:
      return {
        ...state,
        places: [],
        searchPlaces: null,
        error: action.payload,
      };
    default:
      return state;
  }
}
