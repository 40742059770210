import {
  CHANGE_APP_LOADING_STAT,
  CHANGE_APP_LOADED_STAT,
  SET_APP_LOADING_STATUS,
  SET_APP_UPDATING_STATUS,
  SET_ROUTER_BLOCK,
  SET_ROUTER_BLOCK_NOTIF,
} from '../actions/appActions';

const initialState = {
  appLoading: false,
  requiredDataLoaded: false,
  appLoadingStatus: '',

  appUpdating: false,
  appUpdatingStatus: '',

  blockRouter: false,
  showRouterBlockNotif: null,
};

export default function(state = initialState, action) {
  switch (action.type) {
    case CHANGE_APP_LOADING_STAT:
      return { ...state, appLoading: action.loading };
    case CHANGE_APP_LOADED_STAT:
      if (action.loaded) {
        return { appLoading: false, requiredDataLoaded: true };
      }
      return { ...state, requiredDataLoaded: action.loaded };
    case SET_APP_LOADING_STATUS:
      return { ...state, appLoadingStatus: action.appLoadingStatus };
    case SET_APP_UPDATING_STATUS:
      return {
        ...state,
        appUpdating: action.appUpdating,
        appUpdatingStatus: action.appUpdatingStatus,
      };
    case SET_ROUTER_BLOCK:
      return { ...state, blockRouter: action.blockRouter };
    case SET_ROUTER_BLOCK_NOTIF:
      return { ...state, showRouterBlockNotif: action.showRouterBlockNotif };
    default:
      return state;
  }
}
