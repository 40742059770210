import React, { PureComponent } from 'react';
import DatePicker, { registerLocale, setDefaultLocale } from 'react-datepicker';
import PropTypes from 'prop-types';
// import enGB from 'date-fns/locale/en-GB';
import de from 'date-fns/locale/de';
import moment from 'moment-timezone';

export class DatePickerField extends PureComponent {
  static propTypes = {
    onChange: PropTypes.func.isRequired,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({
        value: PropTypes.string,
        label: PropTypes.string,
      }),
    ]).isRequired,

    // initialDate: PropTypes.string,
  };

  /* static defaultProps = {
    meta: null,
  };
 */
  /* static defaultProps = {
    initialDate: null,
  }; */

  constructor() {
    super();
    // console.log('this.props.meta.initial', this.props.initial);

    this.state = {
      startDate: null, // this.props.initialDate, // new Date(Date.now()),
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleFormatting = this.handleFormatting.bind(this);
    registerLocale('de', de);
    setDefaultLocale('de');
  }

  // registerLocale('en-GB', enGB);
  componentDidMount() {
    const { value } = this.props;
    if (value) {
      /* eslint-disable */
      this.setState({
        startDate: new Date(value),
      });
    }
    /* eslint-enable */
    /* const val = document.getElementById('date-wrapper').getAttribute('data-value');
    this.setState({
      startDate: new Date(Number(val)),
    }); */
  }

  /* componentDidUpdate() {
    const { value } = this.props;
    if (value) console.log('value', value);
  } */

  /* componentWillReceiveProps() {
    const { value } = this.props;
    if (value) console.log('value', value);
  } */

  handleChange(date) {
    this.setState({
      startDate: date,
    });
    this.props.onChange(date);
  }

  // Your custom transformation/validation
  handleFormatting = rawValue => {
    console.log('raw:', rawValue);

    const parsedDate = moment(rawValue, 'd.M.yyyy');

    if (parsedDate.isValid()) {
      this.setState({ startDate: parsedDate.toDate() });
    } else {
      console.log('ni valid');
    }
  };

  render() {
    return (
      <div className="date-picker">
        <DatePicker
          className="form__form-group-datepicker form-control"
          selected={this.state.startDate}
          onChange={this.handleChange}
          onChangeRaw={this.handleFormatting}
          dateFormat="d.M.yyyy"
        />
      </div>
    );
  }
}

const renderDatePickerField = props => (
  <div className="form__form-group-input-wrap form__form-group-input-wrap--error-above">
    <DatePickerField {...props.input} />
    {props.meta.touched && props.meta.error && <span className="form__form-group-error">{props.meta.error}</span>}
  </div>
);

renderDatePickerField.propTypes = {
  input: PropTypes.shape({
    onChange: PropTypes.func,
    name: PropTypes.string,
  }).isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
  /* options: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.number,
    label: PropTypes.string,
  })), */
};

renderDatePickerField.defaultProps = {
  meta: null,
};

export default renderDatePickerField;
