import moment from 'moment-timezone';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactGA from 'react-ga';
import { Col, Container, Row, ButtonToolbar, Progress } from 'reactstrap';
import getRoles from 'constants/roles';
import { translate } from 'react-i18next';
import NotificationSystem from 'rc-notification';
import { connect } from 'react-redux';
import { API_LINK } from '../../constants/config';
import { POST, BULK_DOWNLOAD } from '../../helpers/agent';
import NapotitveModal from './components/NapotitveModal';
import { BasicNotification } from '../../shared/components/Notification';
import SelectWeek from './components/SelectWeek';
import NapotitveTable from './components/NapotitveTable';
import {
  OrderersProps,
  WorksitesProps,
  EmployeesProps,
  VehiclesProps,
  AccommodationsProps,
  AppStatusProps,
} from '../../shared/prop-types/ReducerProps';

const API_ASSIGNMENTS = '/a/getweek';
const API_ASSIGNMENTS_GET_ONE = '/a/getOneById';
const API_ASSIGNMENTS_STAGED_GET_ONE = '/a/getStagedById';
const API_ASSIGNMENTS_END = '/a/end';

const D_FORMAT = 'DD. MM. YY';

let notificationLU = null;
let notificationRU = null;
let notificationTC = null;

const showNotification = ({ notification, position }) => {
  switch (position) {
    case 'left-up':
      notificationLU.notice({
        content: notification,
        duration: 5,
        closable: true,
        style: { top: 0, left: 240 },
        className: position,
      });
      break;
    case 'right-up':
      notificationRU.notice({
        content: notification,
        duration: 5,
        closable: true,
        style: { top: 0, left: 'calc(100vw - 100%)' },
        className: position,
      });
      break;
    default:
      notificationTC.notice({
        content: notification,
        duration: 5,
        closable: true,
        style: { top: 0, left: 0 },
        className: position,
      });
      break;
  }
};

class NapotivePage extends Component {
  static propTypes = {
    orderersState: OrderersProps.isRequired,
    worksitesState: WorksitesProps.isRequired,
    workersState: EmployeesProps.isRequired,
    vehiclesState: VehiclesProps.isRequired,
    accommodationsState: AccommodationsProps.isRequired,
    appStatus: AppStatusProps.isRequired,
    t: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      assignments: [],
      orderers: [],
      worksites: [],
      workers: [],
      vehicles: [],
      accommodations: [],
      showEdit: false,
      progressNum: 10,
      editAssignment: null,
      beginDate: '',
      kw: '',
      endDate: '',
      ROLES: getRoles(),
    };

    this.onRowSelect = this.onRowSelect.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.showNotif = this.showNotif.bind(this);
    this.fetchData = this.fetchData.bind(this);
    this.handleProgress = this.handleProgress.bind(this);
    this.handleEndAssignment = this.handleEndAssignment.bind(this);
    this.fetchSelectedWeek = this.fetchSelectedWeek.bind(this);
    this.handleDownload = this.handleDownload.bind(this);
  }

  componentDidMount() {
    this._mounted = true;
    NotificationSystem.newInstance({}, n => (notificationLU = n));
    NotificationSystem.newInstance({}, n => (notificationRU = n));
    NotificationSystem.newInstance({}, n => (notificationTC = n));

    const { appStatus } = this.props;
    if (appStatus.requiredDataLoaded) {
      console.log('EMPLOYEES -> Data loaded.. continue...');
      this.fetchData();
    }
  }

  componentDidUpdate(prevProps) {
    const { appStatus } = this.props;
    if (appStatus.requiredDataLoaded && !prevProps.appStatus.requiredDataLoaded) {
      console.log('EMPLOYEES -> Data finished loading.. continue...');
      this.fetchData();
    }

    if (!appStatus.appUpdating && prevProps.appStatus.appUpdating) {
      console.log('EMPLOYEES -> Data finished UPDATING.. refresh table...');
      this.fetchData();
    }
  }

  componentWillUnmount() {
    this._mounted = false;
    notificationLU.destroy();
    notificationRU.destroy();
    notificationTC.destroy();
  }

  async onRowSelect(values) {
    const { ROLES } = this.state;
    const { t } = this.props;
    if (ROLES.assignments && ROLES.assignments.readonly) {
      ReactGA.event({
        category: 'Napotitve',
        action: `Urejanje Napotitve - ${values} - Ni dostopa`,
      });
      this.showNotif({
        title: `${t('misc.forbidden')}!`,
        message: `${t('misc.noright')}!`,
        success: false,
      });
      return;
    }
    ReactGA.event({
      category: 'Napotitve',
      action: `Urejanje Napotitve - ${values}`,
    });
    console.log('TCL: NapotivePage -> onRowSelect -> values', values);
    const that = this;
    const body = JSON.stringify({ id: values.id });
    const link = values.staged ? API_ASSIGNMENTS_STAGED_GET_ONE : API_ASSIGNMENTS_GET_ONE;

    const data = await POST(API_LINK + link, body);
    if (data.success) {
      that.setState({ editAssignment: data.assignment, showEdit: true });
    }
  }

  showNotif = ({ title, message, success }) => {
    const color = success ? 'success' : 'danger';
    showNotification({
      notification: <BasicNotification color={color} title={title} message={message} />,
      position: 'right-up',
    });
  };

  handleCloseModal(data) {
    if (data) this.showNotif(data);
    this.setState({ showEdit: false });
    if (data) {
      this.fetchData();
      const { beginDate, kw, endDate } = this.state;
      this.fetchSelectedWeek({
        date: moment(beginDate, D_FORMAT).toDate(),
        kw,
        toDate: moment(endDate, D_FORMAT).toDate(),
      });
    }
  }

  handleProgress(addProgress) {
    const { progressNum } = this.state;
    this.setState({ progressNum: progressNum + addProgress });
    const that = this;
    if (progressNum + addProgress >= 100) {
      setTimeout(() => {
        that.setState({ progressNum: 0 });
      }, 1000);
    }
  }

  _mounted = false;

  fetchData() {
    try {
      const { orderersState, worksitesState, workersState, vehiclesState, accommodationsState } = this.props;

      this.setState({
        orderers: orderersState.titlesOrderers,
        worksites: worksitesState.titlesWorksites,
        workers: workersState.employeesNames,
        vehicles: vehiclesState.licenses,
        accommodations: accommodationsState.accommodationTitles,
      });
    } catch (error) {
      console.log(error);
    }
  }

  async fetchSelectedWeek({ date, kw, toDate }) {
    if (!date && !toDate && !kw) return;

    try {
      this.setState({
        beginDate: moment(date).format(D_FORMAT),
        kw,
        endDate: moment(toDate).format(D_FORMAT),
      });

      this.handleProgress(40);
      const dataRes = await POST(
        API_LINK + API_ASSIGNMENTS,
        JSON.stringify({
          beginDate: moment(date).format(D_FORMAT),
        }),
      );
      if (dataRes.success) {
        this.setState({ assignments: dataRes.assignments });
        this.handleProgress(100);
      }
    } catch (error) {
      console.log(error);
    }
  }

  async handleEndAssignment(id) {
    const body = JSON.stringify({ _id: id });
    const data = await POST(API_LINK + API_ASSIGNMENTS_END, body);

    if (data.success) {
      this.fetchData();
      const { beginDate, kw, endDate } = this.state;
      this.fetchSelectedWeek({
        date: moment(beginDate, D_FORMAT).toDate(),
        kw,
        toDate: moment(endDate, D_FORMAT).toDate(),
      });
    }
  }

  async handleDownload(values) {
    console.log('TCL: handleDownload -> values', values);
    const success = await BULK_DOWNLOAD(`${API_LINK}`, values, 'ASSIGNMENT_DOCS');
    console.log('TCL: handleDownload -> success', success);
  }

  render() {
    const {
      accommodations,
      progressNum,
      showEdit,
      editAssignment,
      assignments,
      vehicles,
      workers,
      worksites,
      orderers,
      beginDate,
      kw,
      endDate,
      ROLES,
    } = this.state;
    const { t } = this.props;
    let showBtn = ROLES.full;

    if (!showBtn) {
      showBtn = !(ROLES.assignments && ROLES.assignments.readonly);
    }

    return (
      <Container className="dashboard">
        <Row>
          <Col xs={12} md={4}>
            <h3 className="page-title">{t('sidebar_content.assignments')}</h3>
          </Col>
          <Col xs={12} md={8}>
            <Row>
              <Col xs={12} lg={6} className="select-week__container">
                <SelectWeek disabled={progressNum > 0} onSelectWeek={this.fetchSelectedWeek} />
              </Col>
              <Col lg={{ size: 4, offset: 2 }}>
                <ButtonToolbar className="button-toolbar text-right">
                  <NapotitveModal
                    btn={t('board.filters.buttons.assignment_add')}
                    icon="add"
                    title={t('board.filters.buttons.assignment_add')}
                    orderers={orderers}
                    worksites={worksites}
                    workers={workers}
                    vehicles={vehicles}
                    accommodations={accommodations}
                    hasBtn={showBtn}
                    show={false}
                    onClose={this.handleCloseModal}
                  />
                  <NapotitveModal
                    btn={t('board.ow.popover.editAssignment')}
                    icon="edit"
                    title={t('board.ow.popover.editAssignment')}
                    orderers={orderers}
                    worksites={worksites}
                    workers={workers}
                    vehicles={vehicles}
                    accommodations={accommodations}
                    hasBtn={false}
                    show={showEdit}
                    onClose={this.handleCloseModal}
                    editData={editAssignment}
                  />
                </ButtonToolbar>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          {progressNum > 0 ? (
            <div className="progress-wrap progress-wrap--middle progress--align">
              <Progress animated value={progressNum} />
            </div>
          ) : null}

          {assignments.length > 0 ? (
            <NapotitveTable
              title={`KW ${kw}`}
              subtitle={`(${beginDate} - ${endDate})`}
              assignments={assignments}
              onRowSelect={this.onRowSelect}
              onEndAssignment={this.handleEndAssignment}
              onDownload={this.handleDownload}
            />
          ) : (
            <NapotitveTable
              title={`KW ${kw}`}
              subtitle={`(${beginDate} - ${endDate})`}
              assignments={[]}
              onRowSelect={this.onRowSelect}
              onEndAssignment={this.handleEndAssignment}
              onDownload={this.handleDownload}
            />
          )}
        </Row>
      </Container>
    );
  }
}

const wr = connect(state => ({
  orderersState: state.orderers,
  worksitesState: state.worksites,
  workersState: state.employees,
  vehiclesState: state.vehicles,
  accommodationsState: state.accommodations,
  appStatus: state.appStatus,
}))(NapotivePage);

export default translate('common')(wr);
