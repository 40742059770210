import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'reactstrap';
import constants from '../../../Finance/helpers/index';
import cashboxApi from '../../../../api/cashbox';

const { handleFocus } = constants;

class ReturnedReasonsTable extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { reasons: [] };
  }

  componentDidMount() {
    this.fetchReasons();
  }

  fetchReasons = async () => {
    const { ridelogId } = this.props;
    //if (ridelogId) {
    const reasons = await cashboxApi.getTravelOrderReturnedReasons(ridelogId);
    this.setState({ reasons });
    //}
  };

  tableInput = (id, value, type, index, className = 'form-control', step = 1) => {
    const colorClass = type === 'number' && value < 0 ? ' red-text' : '';
    if (type === 'number') {
      return (
        <input
          onFocus={e => handleFocus(e)}
          onChange={e => this.handleChange(index, id, e.target.value)}
          //onBlur={e => this.handleSubmit(index, id, e.target.value)}
          value={value}
          type={type}
          className={className + colorClass}
          step={step}
        />
      );
    }
    return (
      <textarea
        style={{ minHeight: '32px' }}
        rows={1}
        onChange={e => this.handleChange(index, id, e.target.value)}
        //onBlur={e => this.handleSubmit(index, id, e.target.value)}
        value={value}
        className={className}
      />
    );
  };

  handleChange = (index, dataType, _value) => {
    const value = _value;
    //if (!_value && dataType !== 'notes') value = 0;

    const { reasons } = this.state;

    // let modifiedBy = null;
    // const token = getItem('jwt');
    // if (token) {
    //   const { uid, name, surname, username } = payload(token);
    //   modifiedBy = { uid, name, surname, username, updatedAt: TMZFY(new Date(Date.now())) };
    // }

    const newState = reasons.map((item, i) => {
      if (i === index) {
        return { ...item, [dataType]: value };
      }
      return item;
    });

    this.setState({
      reasons: newState,
    });

    this.props.onChange(newState);
  };

  renderReasons = () => {
    const { reasons } = this.state;

    return reasons.map((reason, index) => {
      const { label, value } = reason;
      return (
        <tr key={value}>
          <td>
            <strong>{label}</strong>
          </td>
          <td>{this.tableInput('receipt', reasons[index].receipt, 'number', index, undefined, 10)}</td>
          <td>{this.tableInput('receiptNotes', reasons[index].receiptNotes, 'text', index, undefined, 10)}</td>
          <td>{this.tableInput('cash', reasons[index].cash, 'number', index, undefined, 10)}</td>
          <td>{this.tableInput('cashNotes', reasons[index].cashNotes, 'text', index, undefined, 10)}</td>
        </tr>
      );
    });
  };

  render() {
    return (
      <Table hover className="table--bordered" responsive>
        <thead>
          <tr>
            <th>Razlog</th>
            <th>Račun €</th>
            <th>Opombe</th>
            <th>Gotovina €</th>
            <th>Opombe</th>
          </tr>
        </thead>
        <tbody>{this.renderReasons()}</tbody>
      </Table>
    );
  }
}

ReturnedReasonsTable.propTypes = {
  //reasons: PropTypes.arrayOf(PropTypes.object).isRequired,
  ridelogId: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

ReturnedReasonsTable.defaultProps = {
  ridelogId: null,
};

export default ReturnedReasonsTable;
