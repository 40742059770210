const validate = values => {
  const errors = {};
  if (!values.name) {
    errors.name = 'To polje mora biti izpolnjeno!';
  }

  return errors;
};

export default validate;
