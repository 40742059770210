import React from 'react';
import PropTypes from 'prop-types';
import getDaysInMonth from 'helpers/getDaysInMonth';
import ReactExport from 'react-data-export';
import { Button } from 'reactstrap';
import moment from 'moment-timezone';
import fix from 'helpers/floatParser';
import { TIMEZONE } from 'constants/config';
import { translate } from 'react-i18next';

/* eslint-disable */
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
/* eslint-enable */

const DownloadButton = title => (
  <Button size="sm" color="primary" style={{ marginRight: '10px' }}>
    {title}
  </Button>
);

const generateHeaders = (month, year, t) => {
  const daysInMonths = getDaysInMonth(month, year);
  const firstPart = [
    {
      title: t('form.num'),
    },
    {
      title: t('form.company'),
    },
    {
      title: t('form.surname'),
    },
    {
      title: t('form.name'),
    },
    {
      title: t('form.from'),
    },
    {
      title: t('form.to'),
    },
  ];
  const daysPart = daysInMonths.map(day => ({
    title: `${moment(day)
      .tz(TIMEZONE)
      .format('ddd')} ${moment(day)
      .tz(TIMEZONE)
      .format('D.M.YY')}`,
  }));
  const lastPart = [
    {
      title: t('calcs.int'),
    },
    {
      title: t('calcs.sum'),
    },
    {
      title: t('sidebar_content.worksites'),
    },
    {
      title: t('form.notes'),
    },
  ];
  const columns = firstPart.concat(daysPart.concat(lastPart));
  const multiDataSet = [
    {
      columns,
      data: [],
    },
  ];
  return multiDataSet;
};

const generateData = dataAll => {
  console.log('TCL: generateData -> dataAll', dataAll);
  const data = [];
  for (let i = 0; i < dataAll.length; i += 1) {
    const worker = dataAll[i];
    const workerRow = [
      {
        value: Number(`${i + 1}`),
      },
      {
        value: i === dataAll.length - 1 ? worker.company : worker.companyFullName,
      },
      {
        value: worker.surname,
      },
      {
        value: worker.name,
      },
      {
        value: worker.registeredAt,
      },
      {
        value: worker.unregisteredAt || '',
      },
    ];

    for (let j = 0; j < worker.workdays.length; j += 1) {
      const workday = worker.workdays[j];
      workerRow.push({
        value: Number(fix(workday.hours)),
      });
    }

    workerRow.push({
      value: Number(fix(worker.internalSum)),
    });
    workerRow.push({
      value: Number(fix(worker.totalSum)),
    });
    workerRow.push({
      value: worker.allOW.length > 0 ? worker.allOW.join(', ') : ' ',
    });
    workerRow.push({
      value: worker.notes.length > 0 ? worker.notes.join(', ') : ' ',
    });

    data.push(workerRow);
  }
  return data;
};

class BrankaSheet extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { data, btn, month, year, t } = this.props;

    const headers = generateHeaders(month, year, t);
    headers[0].data = generateData(data);
    return (
      <ExcelFile element={DownloadButton(btn)} filename={t('calcs.branka')}>
        <ExcelSheet dataSet={headers} name={t('calcs.branka')} />
      </ExcelFile>
    );
  }
}

BrankaSheet.propTypes = {
  btn: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  month: PropTypes.number.isRequired,
  year: PropTypes.number.isRequired,
  t: PropTypes.func.isRequired,
};

export default translate('common')(BrankaSheet);
