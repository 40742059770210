import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';
import Legend from './components/Legend';
import BoardsGrid from './components/Board/BoardsGrid';
import Filters from './components/Filters/index';

const BoardIndex = ({ t }) => (
  <Container>
    <Row>
      <Col lg={6}>
        <h3 className="page-title">{t('board.title')}</h3>
      </Col>
      <Col lg={6}>
        <Legend title={t('board.legend')} />
      </Col>
    </Row>
    <Row>
      <Col xs={12}>
        <Filters />
      </Col>
    </Row>
    <Row>
      <BoardsGrid />
    </Row>
  </Container>
);

BoardIndex.propTypes = {
  t: PropTypes.func.isRequired,
};

export default translate('common')(BoardIndex);
