const validate = values => {
  const errors = {};
  if (!values.date) {
    errors.date = 'To polje mora biti izpolnjeno!';
  }

  if (!values.worksiteId) {
    errors.worksiteId = 'To polje mora biti izpolnjeno!';
  }

  return errors;
};

export default validate;
