import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody, Form, FormGroup, Input, Label, Button, CardTitle } from 'reactstrap';
import moment from 'moment';

export default class EditWorkday extends Component {
  static propTypes = {
    workday: PropTypes.object.isRequired,
    updateHours: PropTypes.func.isRequired,
    worksites: PropTypes.array.isRequired,
    additionalWorkday: PropTypes.object,
  };

  static defaultProps = {
    additionalWorkday: null,
  };

  constructor(props) {
    super(props);
    const additionalHours = props.additionalWorkday ? props.additionalWorkday.hours : 0;
    const additionalWorksite = props.additionalWorkday
      ? props.additionalWorkday.worksiteId
      : this.props.worksites[0]._id;
    const additionalShown = !!props.additionalWorkday;
    const nightHoursShown = !!props.workday.nightHours;

    this.state = {
      hours: props.workday.hours,
      nightHours: props.workday.nightHours,
      nightHoursShown,
      additionalShown,
      additionalHours,
      additionalWorksite,
    };
  }

  componentDidUpdate = prevProps => {
    if (prevProps.workday._id !== this.props.workday._id) {
      const additionalWorksite = this.props.additionalWorkday
        ? this.props.additionalWorkday.worksiteId
        : this.props.worksites[0]._id;
      const additionalHours = this.props.additionalWorkday ? this.props.additionalWorkday.hours : 0;
      const additionalShown = !!this.props.additionalWorkday;
      const nightHoursShown = !!this.props.workday.nightHours;

      this.setState({
        hours: this.props.workday.hours,
        nightHours: this.props.workday.nightHours,
        nightHoursShown,
        additionalShown,
        additionalHours,
        additionalWorksite,
      });
    }
  };

  setHours = event => {
    this.setState({
      hours: event.target.value,
    });
  };

  setNightHours = event => {
    this.setState({
      nightHours: event.target.value,
    });
  };

  setAdditionalHours = event => {
    this.setState({
      additionalHours: event.target.value,
    });
  };

  setAdditionalWorksite = event => {
    this.setState({
      additionalWorksite: event.target.value,
    });
  };

  toggleNightHours = () => {
    this.setState(prevState => ({
      nightHoursShown: !prevState.nightHoursShown,
    }));
  };

  toggleAdditionalHours = () => {
    this.setState(prevState => ({
      additionalShown: !prevState.additionalShown,
    }));
  };

  handleHourSubmit = event => {
    event.preventDefault();
    const { hours } = this.state;
    const { _id } = this.props.workday;
    this.props.updateHours(_id, {
      hours,
    });
  };

  handleNightHourSubmit = event => {
    event.preventDefault();
    const { nightHours } = this.state;
    const { _id } = this.props.workday;
    this.props.updateHours(_id, {
      nightHours,
    });
  };

  handleAdditionalHourSubmit = event => {
    event.preventDefault();
    const { additionalHours, additionalWorksite } = this.state;
    const { _id } = this.props.workday;
    this.props.updateHours(_id, {
      additionalHours,
      additionalWorksite,
    });
  };

  renderHourOptions = () => (
    <React.Fragment>
      <option value="0">0</option>
      <option value="1">1</option>
      <option value="2">2</option>
      <option value="3">3</option>
      <option value="4">4</option>
      <option value="5">5</option>
      <option value="6">6</option>
      <option value="7">7</option>
      <option value="8">8</option>
      <option value="9">9</option>
      <option value="10">10</option>
      <option value="11">11</option>
      <option value="12">12</option>
      <option value="13">13</option>
      <option value="14">14</option>
      <option value="15">15</option>
      <option value="16">16</option>
      <option value="17">17</option>
      <option value="18">18</option>
      <option value="19">19</option>
      <option value="20">20</option>
      <option value="21">21</option>
      <option value="22">22</option>
      <option value="23">23</option>
      <option value="24">24</option>
    </React.Fragment>
  );

  renderWorksitesOptions = () => {
    if (!this.props.worksites) {
      return '';
    }
    return this.props.worksites.map(worksite => (
      <option key={worksite._id} value={worksite._id}>
        {worksite.title}
      </option>
    ));
  };

  render() {
    const { hours, nightHours, nightHoursShown, additionalShown, additionalHours, additionalWorksite } = this.state;
    return (
      <Card className="edit-workday">
        <CardBody>
          <CardTitle>
            <h5>Urejanje: {moment(this.props.workday.date).format('DD/MM/YYYY')}</h5>
          </CardTitle>
          <Form>
            <FormGroup>
              <h3 className="mb-1">Dnevne ure</h3>
              <Label for="hours">Dnevne Ure</Label>
              <div className="edit-workday__input">
                <Input type="select" name="hours" id="hours" value={hours} onChange={this.setHours}>
                  {this.renderHourOptions()}
                </Input>
                <Button color="primary" type="button" onClick={this.handleHourSubmit}>
                  Potrdi
                </Button>
              </div>
            </FormGroup>

            {nightHoursShown && (
              <FormGroup className="mt-4">
                <h3 className="mb-1">Nočne Ure</h3>
                <Label for="nightHours">Nočne Ure</Label>
                <div className="edit-workday__input">
                  <Input
                    type="select"
                    name="nightHours"
                    id="nightHours"
                    value={nightHours}
                    onChange={this.setNightHours}
                  >
                    {this.renderHourOptions()}
                  </Input>
                  <Button color="primary" onClick={this.handleNightHourSubmit} type="button">
                    Potrdi
                  </Button>
                </div>
              </FormGroup>
            )}
            {additionalShown && (
              <FormGroup className="mt-4">
                <h3 className="mb-1">Dodatno gradbišče</h3>
                <Label for="additionalHours">Izberite Gradišče</Label>
                <Input
                  type="select"
                  name="additionalWorksite"
                  id="additionalWorksite"
                  className="mb-2"
                  value={additionalWorksite}
                  onChange={this.setAdditionalWorksite}
                >
                  {this.renderWorksitesOptions()}
                </Input>
                <Label for="additionalHours">Dodatne Ure</Label>

                <div className="edit-workday__input">
                  <Input
                    type="select"
                    name="additionalHours"
                    id="additionalHours"
                    value={additionalHours}
                    onChange={this.setAdditionalHours}
                  >
                    {this.renderHourOptions()}
                  </Input>
                  <Button color="primary" onClick={this.handleAdditionalHourSubmit} type="button">
                    Potrdi
                  </Button>
                </div>
              </FormGroup>
            )}
            <div className="actions mt-5">
              <Button color="primary" onClick={this.toggleNightHours}>
                {nightHoursShown ? 'Odstrani nočne ure' : 'Dodaj nočne ure'}
              </Button>
              <Button color="primary" onClick={this.toggleAdditionalHours}>
                {additionalShown ? 'Odstrani gradbišče' : 'Dodaj gradbišče'}
              </Button>
            </div>
          </Form>
        </CardBody>
      </Card>
    );
  }
}
