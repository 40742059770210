export const SET_OREDERERS = 'SET_OREDERERS';
export const SET_ORDERERS_TITLES_LIST = 'SET_ORDERERS_TITLES_LIST';

export function setOrderers(orderers) {
  return {
    type: SET_OREDERERS,
    orderers,
  };
}

export function setOrderersTitlesList(titlesOrderers) {
  return {
    type: SET_ORDERERS_TITLES_LIST,
    titlesOrderers,
  };
}
