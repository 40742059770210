import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Layout from '../Layout/index';
import MainWrapper from './MainWrapper';

// import LogIn from '../LogIn/index';
// import ExamplePageOne from '../Example/index';
// import ExamplePageTwo from '../ExampleTwo/index';
import UreDelavci from '../DelovneUre/Delavci';
import UreGradbisca from '../DelovneUre/Gradbisca';
import UreIzpis from '../DelovneUre/Izpis';
import PopraviUre from '../DelovneUre/Popravi';
import DelavciPage from '../Delavci';
import VozilaPage from '../Vozila';
import NarocnikiPage from '../Narocniki';
import GradbiscaPage from '../Gradbisca';
import PrenocitvePage from '../Prenocitve';
import NapotivePage from '../Napotitve';
import PodjetjaPage from '../Podjetja';
import PrenocitveIzpisiPage from '../Prenocitve/Izpisi';
//simport TablaPage from '../Tabla';
import BoardsPage from '../Boards';
// import SpremembePage from '../Spremembe';
import VozilaIzpisiPage from '../Vozila/Izpisi';
import PonudnikiPage from '../Prenocitve/Ponudniki';
import PrenocitveIskanje from '../Prenocitve/Iskanje';
import DelavciZgodovinaPage from '../Delavci/Zgodovina/index';
import DokumentiPage from '../Dokumenti/index';
import FinanceWagesPage from '../Finance/Wages';
import FinanceCashboxPage from '../Finance/Cashbox';
import FinanceRecurringPage from '../Finance/Recurring';
import FinanceCompensationPage from '../Finance/Compensation';
import LogIn from '../LogIn/index';
import LogOutPage from '../LogOut';
import RezervacijePage from '../Prenocitve/Rezervacije';

/* const Pages = () => (
  <Switch>
    <Route path="/" component={ExamplePageOne} />
     <Route path="/pages/one" component={ExamplePageOne} />
    <Route path="/pages/two" component={ExamplePageTwo} />
  </Switch>
); */

const DelovneUre = () => (
  <Switch>
    <Route path="/ure/delavci" component={UreDelavci} />
    <Route path="/ure/gradbisca" component={UreGradbisca} />
    <Route path="/ure/izpis" component={UreIzpis} />
  </Switch>
);

const Vozila = () => (
  <Switch>
    <Route path="/vozila/zgodovina" component={VozilaIzpisiPage} />
    <Route path="/vozila" component={VozilaPage} />
  </Switch>
);

const Prenocitve = () => (
  <Switch>
    <Route path="/prenocisca/rezervacije" component={RezervacijePage} />
    <Route path="/prenocisca/ponudniki" component={PonudnikiPage} />
    <Route path="/prenocisca/iskanje" component={PrenocitveIskanje} />
    <Route path="/prenocisca/zgodovina" component={PrenocitveIzpisiPage} />
    <Route path="/prenocisca" component={PrenocitvePage} />
  </Switch>
);

const Delavci = () => (
  <Switch>
    <Route path="/delavci/zgodovina" component={DelavciZgodovinaPage} />
    <Route path="/delavci" component={DelavciPage} />
  </Switch>
);

const Dokumenti = () => (
  <Switch>
    <Route path="/dokumenti/potek" component={DokumentiPage} />
    {/* <Route path="/dokumenti" component={DelavciPage} /> */}
  </Switch>
);

const Finance = () => (
  <Switch>
    <Route path="/finance/wages" component={FinanceWagesPage} />
    <Route path="/finance/cashbox" component={FinanceCashboxPage} />
    <Route path="/finance/recurring" component={FinanceRecurringPage} />
    <Route path="/finance/compensation" component={FinanceCompensationPage} />
    <Route path="/finance" component={FinanceWagesPage} />
  </Switch>
);

const wrappedRoutes = () => (
  <div>
    <Layout />
    <div className="container__wrap">
      <Route exact path="/" component={BoardsPage} />
      <Route exact path="/tabla" component={BoardsPage} />
      {/* <Route exact path="/tabla/old" component={TablaPage} /> */}
      <Route path="/ure" component={DelovneUre} />
      <Route path="/podjetja" component={PodjetjaPage} />
      <Route path="/delavci" component={Delavci} />
      <Route path="/vozila" component={Vozila} />
      <Route path="/narocniki" component={NarocnikiPage} />
      <Route path="/gradbisca" component={GradbiscaPage} />
      <Route path="/prenocisca" component={Prenocitve} />
      <Route path="/napotitve" component={NapotivePage} />
      {/* <Route path="/spremembe" component={SpremembePage} /> */}
      <Route path="/dokumenti" component={Dokumenti} />
      <Route path="/finance" component={Finance} />
    </div>
  </div>
);

const Router = () => (
  <MainWrapper>
    <main>
      <Switch>
        <Route path="/prijava" component={LogIn} />
        <Route path="/odjava" component={LogOutPage} />
        <Route path="/ure/popravi/:token" component={PopraviUre} />
        <Route path="/" component={wrappedRoutes} />
      </Switch>
    </main>
  </MainWrapper>
);

export default Router;
