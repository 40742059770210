export default {
  AMCompanies: [
    'LORzR7gMVxJ',
    'n6wmDuTlGbO',
    'pbSMtF0C9S5',
    '-zaqzkvGXCm',
    'U2yBVC-TNNH',
    '2dM6LCT3GxX',
    '6CUqflX0A6Z',
    'SoGwC5cfZX9',
  ],
  MONTHS: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
  YEARS: [2019, 2020, 2021, 2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030],
  SATNICE_FILTER_MONTH: 'SATNICE_FILTER_MONTH',
  SATNICE_FILTER_YEAR: 'SATNICE_FILTER_YEAR',
  CASHBOX_FILTER_MONTH_FROM: 'CASHBOX_FILTER_MONTH_FROM',
  CASHBOX_FILTER_YEAR_FROM: 'CASHBOX_FILTER_YEAR_FROM',
  CASHBOX_FILTER_MONTH_TO: 'CASHBOX_FILTER_MONTH_TO',
  CASHBOX_FILTER_YEAR_TO: 'CASHBOX_FILTER_YEAR_TO',
  SATNICE_FILTER_COMPANY: 'SATNICE_FILTER_COMPANY',
  SATNICE_FILTER_COMPANY_NAME: 'SATNICE_FILTER_COMPANY_NAME',
  BALANCE_UPDATE: 'balance-update',
  EXPENSE: 'expense',
  TRAVEL_ORDER: 'travel-order',
  SPECIAL: 'special',
  WITHDRAW: 'withdraw',
  MISC: 'misc',
  REFUND: 'refund',
  TRANSFER: 'transfer',
  DEBT: 'debt',
  RECURRING: 'recurring',
  DEBOOK_CASH: 'debook-cash',
  DEBOOK_TRANSFER: 'debook-transfer',
  DEBOOK_LEVELING: 'debook-leveling',
  DEBOOK_TRR: 'debook-trr',
  TRANSACTION_SUBTYPES: [
    { label: '-', value: 'trans_type_normal' },
    { label: 'Akontacija', value: 'trans_type_out' },
    { label: 'Poračun', value: 'trans_type_in' },
  ],
  RETURNED_REASONS: [
    { label: 'Račun', value: 'receipt' },
    { label: 'Gotovina', value: 'cash' },
  ],
  TRANSACTION_SPECIAL_CATEGORIES: [
    { label: 'BG', value: 'bg' },
    { label: 'MU', value: 'mu' },
    { label: 'BA', value: 'ba' },
  ],
  TRAVEL_ORDER_OPTIONS: [
    { label: 'Vinjete / Cestnine', value: 'option1' },
    { label: 'Gorivo', value: 'option2' },
    { label: 'Vzdrževanje', value: 'option3' },
    { label: 'Orodje', value: 'option4' },
    { label: 'Stanovanje', value: 'option5' },
    { label: 'Drugo', value: 'option6' },
    { label: 'Vinjeta SLO (letna)', value: 'option7' },
    { label: 'Vinjeta A (letna)', value: 'option8' },
    { label: 'Cestnine', value: 'option9' },
  ],
  DEBOOK_CATEGORIES: [
    { label: 'Izravnava', value: 'debook-leveling' },
    { label: 'Prenos', value: 'debook-transfer' },
    { label: 'Gotovina', value: 'debook-cash' },
    { label: 'TRR', value: 'debook-trr' },
  ],
  KONTO_IGNORE_CATEGORIES: ['balance-update', 'withdraw', 'transfer', 'debt'],
  handleFocus: event => event.target.select(),
};
