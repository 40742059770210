export const SET_VEHICLES = 'SET_VEHICLES';
export const SET_LICENSES_LIST = 'SET_LICENSES_LIST';

export function setVehicles(vehicles) {
  return {
    type: SET_VEHICLES,
    vehicles,
  };
}

export function setLicensesList(licenses) {
  return {
    type: SET_LICENSES_LIST,
    licenses,
  };
}
