import React from 'react';
import PropTypes from 'prop-types';
import { Button, Modal } from 'reactstrap';

import classNames from 'classnames';
import AddIcon from 'mdi-react/AccountMultiplePlusOutlineIcon';
import EditIcon from 'mdi-react/AccountEditIcon';
/* import { API_LINK } from 'constants/config';
import { POST } from 'helpers/agent'; */
import UreGradbiscaForm from './UreGradbiscaForm';

class UreGradbiscaModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { modal: false };
    this.toggle = this.toggle.bind(this);
    this.cancel = this.cancel.bind(this);
    this.handleESC = this.handleESC.bind(this);
  }

  componentDidMount() {
    document.addEventListener('keydown', this.handleESC, false);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleESC, false);
  }

  handleESC(event) {
    if (event.keyCode === 27) {
      this.setState({ modal: false });
      this.cancel(null);
    }
  }

  toggle() {
    this.setState({
      modal: !this.state.modal,
    });
  }

  cancel(data) {
    this.setState({
      modal: false,
    });
    this.props.onClose(data);
  }

  render() {
    const { icon, btn, title, colored, header, hasBtn, show, workers } = this.props;
    let Icon;

    switch (icon) {
      case 'add':
        Icon = <AddIcon />;
        break;
      case 'edit':
        Icon = <EditIcon />;
        break;
      default:
        break;
    }
    const modalClass = classNames({
      'modal-dialog--colored': colored,
      'modal-dialog--header': header,
    });
    return (
      <div style={{ width: '100%' }}>
        {hasBtn ? (
          <Button className="icon large-button" color="primary" onClick={this.toggle}>
            <p>
              {icon === 'add' ? <AddIcon /> : <EditIcon />}
              {btn}
            </p>
          </Button>
        ) : null}

        <Modal isOpen={this.state.modal || show} toggle={this.toggle} className={`modal-dialog--primary ${modalClass}`}>
          <div className="modal__header">
            <button className="lnr lnr-cross modal__close-btn" onClick={this.toggle} />
            {header ? '' : Icon}
            <h4 className="bold-text  modal__title">{title}</h4>
          </div>
          <div className="modal__body">
            <div className="theme-light">
              <UreGradbiscaForm onSubmit={this.cancel} workers={workers} handleCancel={() => this.cancel(null)} />
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

UreGradbiscaModal.propTypes = {
  title: PropTypes.string,
  workers: PropTypes.arrayOf(PropTypes.object).isRequired,
  icon: PropTypes.string.isRequired,
  colored: PropTypes.bool,
  header: PropTypes.bool,
  btn: PropTypes.string.isRequired,
  hasBtn: PropTypes.bool.isRequired,
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

UreGradbiscaModal.defaultProps = {
  title: '',
  colored: false,
  header: false,
};

export default UreGradbiscaModal;
