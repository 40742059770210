import React, { Fragment } from 'react';
import moment from 'moment-timezone';
import { Card, CardBody, Col, Table, UncontrolledTooltip } from 'reactstrap';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';
import CheckIcon from 'mdi-react/CheckIcon';
import CloseIcon from 'mdi-react/CloseIcon';
import shortid from 'shortid';
import { TIMEZONE } from '../../../../constants/config';
import { commify } from '../../../../helpers/floatParser';

// const currentEmpl = null;

const toMonthDay = date =>
  moment(date)
    .tz(TIMEZONE)
    .date();

const renderReasons = (reasons, ridelogId, fake = false) => {
  const mapped = reasons.reduce((list, elt) => {
    if (elt.receipt.toString() !== '0') {
      list.push(
        <div>
          {elt.label} račun: {elt.receipt}€
        </div>,
      );
    }

    if (elt.cash.toString() !== '0') {
      list.push(
        <div>
          {elt.label} gotovina: {elt.cash}€
        </div>,
      );
    }

    return list;
  }, []);
  return (
    <Fragment>
      {mapped.length > 0 && (
        <UncontrolledTooltip placement="right" target={`reasons-${ridelogId}`}>
          {mapped}
        </UncontrolledTooltip>
      )}
      <strong id={`reasons-${ridelogId}`}>{mapped.length > 0 ? <CheckIcon /> : <CloseIcon />}</strong>
    </Fragment>
  );
};

const renderTable = (ridelogs, onRowSelect, type) =>
  ridelogs.map((rlog, index) => {
    //console.log('rlog', rlog);
    const isFake = rlog.fake;
    const { isSwitch } = rlog;

    // if (
    //   rlog.returnedAt &&
    //   toMonthDay(rlog.date) === 1 &&
    //   toMonthDay(rlog.returnedAt) !== 1 &&
    //   index < ridelogs.length - 1 &&
    //   !isSwitch
    // ) {
    //   console.log('TCL: index', index);
    //   isFake = true;
    // }

    let style = isFake ? { backgroundColor: '#F3DAD9' } : {};
    const taken = isFake ? '...' : rlog.dateString || '/';
    const mid = isFake ? rlog.dateString || '/' : '...';
    const returned = rlog.returnedAtString === 'Invalid date' || isFake ? '/' : rlog.returnedAtString;

    if (rlog.warning) {
      style = { color: 'red' };
    }

    //console.log('rlog', rlog);

    return (
      <React.Fragment>
        <tr key={rlog._id} id={rlog._id} style={style} onClick={() => onRowSelect(rlog)}>
          <td>{taken}</td>
          {type === 'vehicle' && <td>{mid}</td>}
          <td>{returned}</td>
          <td>{rlog.employee || rlog.vehicle}</td>
          <td>{rlog.kilometers}</td>
          {/* <td>{commify(rlog.cost) || '0'}</td> */}
          {/* <td>{rlog.receipt ? <CheckIcon /> : <CloseIcon />}</td> */}
          <td>{renderReasons(rlog.reasons, rlog._id, rlog.fake)}</td>
          <td>{rlog.notes}</td>
        </tr>
        {rlog.warning && (
          <tr key={shortid.generate()} onClick={() => onRowSelect(rlog)}>
            <td colSpan="8" style={style}>
              <strong>POZOR! NESKLADJE DATUMOV: </strong>
              {rlog.warning}
              <strong> Kliknite tukaj, da uredite datum vrnitve vozila!</strong>
            </td>
          </tr>
        )}
      </React.Fragment>
    );
  });

const VozilaIzpisiTable = ({ title, ridelogs, onRowSelect, type, t }) => (
  <Col md={12} lg={12} xl={12}>
    <Card>
      <CardBody>
        <div className="card__title">
          <h5 className="bold-text">{title}</h5>
          {/* <h5 className="subhead">lol</h5> */}
        </div>
        <Table hover className="table--bordered" responsive>
          <thead>
            <tr>
              <th>{t('misc.takeDate')}</th>
              {type === 'vehicle' && <th>{t('misc.midDate')}</th>}
              <th>{t('misc.returnDate')}</th>
              <th>{type === 'vehicle' ? t('board.ow.worker') : t('board.ow.vehicle')}</th>
              <th>{t('misc.kilometers')}</th>
              {/* <th>{t('misc.amount')}</th> */}
              <th>{t('misc.receipt')}</th>
              <th>{t('form.notes')}</th>
            </tr>
          </thead>
          <tbody>{renderTable(ridelogs, onRowSelect, type)}</tbody>
        </Table>
      </CardBody>
    </Card>
  </Col>
);

VozilaIzpisiTable.propTypes = {
  title: PropTypes.string.isRequired,
  ridelogs: PropTypes.arrayOf(PropTypes.object).isRequired,
  onRowSelect: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
};

VozilaIzpisiTable.defaultProps = {};

export default translate('common')(VozilaIzpisiTable);
