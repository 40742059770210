import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody, Col, ButtonToolbar, Button, Nav, NavItem, NavLink, UncontrolledTooltip } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import { translate } from 'react-i18next';

// import CalendarBlankIcon from 'mdi-react/CalendarBlankIcon';
import classnames from 'classnames';
import Alert from 'shared/components/Alert';
import DeleteOutlineIcon from 'mdi-react/DeleteOutlineIcon';
import Modal from '../../../shared/components/Modal';
import renderSelectField from '../../../shared/components/form/Select';
// import renderDatePickerField from '../../../shared/components/form/DatePicker';
import validate from './validate';
import DocumentVersionsTable from '../../../shared/components/DocumentVersionsTable';
import renderFileInputField from '../../../shared/components/form/FileInput';
import renderTextField from '../../../shared/components/form/TextField';
import renderDatePickerField from '../../../shared/components/form/DatePicker';
import Collapse from '../../../shared/components/Collapse';
import { DOWNLOAD } from '../../../helpers/agent';
import { API_LINK } from '../../../constants/config';

const DNONE = {
  display: 'none',
};

const vehTypeOptions = [
  {
    value: 'OSEBNI',
    label: 'OSEBNI',
  },
  {
    value: 'KOMBI',
    label: 'KOMBI',
  },
  {
    value: 'OSTALO',
    label: 'OSTALO',
  },
  {
    value: 'TOVORNO',
    label: 'TOVORNO',
  },
  {
    value: 'AVTOBUS',
    label: 'AVTOBUS',
  },
];

const renderField = ({ input, placeholder, type, meta: { touched, error } }) => (
  <div className="form__form-group-input-wrap form__form-group-input-wrap--error-above">
    <input {...input} placeholder={placeholder} type={type} />
    {touched && error && <span className="form__form-group-error">{error}</span>}
  </div>
);

renderField.propTypes = {
  input: PropTypes.shape().isRequired,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
};

renderField.defaultProps = {
  placeholder: '',
  meta: null,
  type: 'text',
};

class VozilaForm extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showBasic: true,
      showDocs: false,
      activeTab: props.initialTab,
      showAlert: {},
      alertMsg: '',
      fileCounter: 0,
      nobulk: false,
      blockButtons: false,
      //tech
      techFieldsCount: props.initialTechFieldCount,
      removedTechFieldIds: [],
      //ins
      insFieldsCount: props.initialInsFieldCount,
      removedInsFieldIds: [],
      //reg
      regFieldsCount: props.initialRegFieldCount,
      removedRegFieldIds: [],
    };
  }

  UNSAFE_componentWillUpdate(nextProps, nextState) {
    if (this.state.showBasic && this.state.activeTab !== '1' && nextState.activeTab !== '1') {
      console.log('TOGGLAM');
      this.toggle(nextState.activeTab);
    }
  }

  toggle = tab => {
    if (tab === '1') {
      this.setState({ activeTab: tab, showBasic: true, showDocs: false });
    } else {
      this.setState({ activeTab: tab, showBasic: false, showDocs: true });
    }
  };

  renderTabsField = activeTab => {
    const { t } = this.props;
    return (
      <div className="tabs tabs--justify tabs--bordered-bottom" style={{ marginBottom: '20px' }}>
        <div className="tabs__wrap">
          <Nav tabs>
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === '1' })}
                onClick={() => {
                  this.toggle('1');
                }}
              >
                {t('form.basicInfo')}
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === '2' })}
                onClick={() => {
                  this.toggle('2');
                }}
              >
                {t('form.docs')}
              </NavLink>
            </NavItem>
          </Nav>
        </div>
      </div>
    );
  };

  dismissAlert = () => {
    this.setState({
      showAlert: {},
      alertMsg: '',
      nobulk: false,
    });
  };

  downloadFile = async (_type, workerId, name, surname, fieldNum = 0) => {
    const { editData, t } = this.props;
    const type = _type;
    const alertType = _type;
    try {
      const success = await DOWNLOAD(`${API_LINK}/f/download`, type, {
        id: workerId,
        name,
        surname,
      });

      if (!success) {
        this.setState({
          showAlert: {
            [alertType]: true,
          },
          alertMsg: (
            <span>
              {t('form.docNotFound')}! <i style={{ color: 'red' }}>({t('form.docNotUploaded')})</i>
            </span>
          ),
        });
      }
    } catch (error) {
      console.log('VozilaForm -> downloadFile -> error', error);
      this.setState({
        showAlert: {
          [alertType]: true,
        },
        alertMsg: <span>{t('form.docError')}!</span>,
      });
    }
  };

  handleFileInputChange = e => {
    if (e.file) {
      this.setState({ fileCounter: this.state.fileCounter + 1 });
    } else {
      this.setState({ fileCounter: this.state.fileCounter - 1 });
    }
    console.log('TCL: handleFileInputChange -> e', e);
  };

  hijackSubmit = async (e, upload = false) => {
    e.preventDefault();
    // eslint-disable-next-line
    const { handleSubmit, onSubmit, handleUpload } = this.props;
    const { fileCounter, removedTechFieldIds, removedInsFieldIds, removedRegFieldIds } = this.state;
    this.setState({ blockButtons: true });

    handleSubmit(async values => {
      const valuesCopy = JSON.parse(JSON.stringify(values));
      valuesCopy.extraTech = removedTechFieldIds;
      valuesCopy.extraIns = removedInsFieldIds;
      valuesCopy.extraReg = removedRegFieldIds;
      valuesCopy.fileCounter = fileCounter;
      if (!upload) {
        onSubmit(valuesCopy);
      } else {
        await handleUpload(valuesCopy);
        await onSubmit(valuesCopy);
      }
    })();
  };

  //* TEHNIČNI */

  addTechField = () => {
    this.setState({ techFieldsCount: this.state.techFieldsCount + 1 });
  };

  removeTechField = (ctx, fieldNum) => {
    const rtf = ctx.state.removedTechFieldIds;
    rtf.push(fieldNum);
    ctx.setState({ removedTechFieldIds: rtf });
    ctx.forceUpdate();
  };

  renderTechFields = (showDocs, AlertElt) => {
    const { techFieldsCount, removedTechFieldIds, showAlert } = this.state;
    const { editData, t } = this.props;
    const that = this;
    const fields = [];
    for (let index = 0; index < techFieldsCount; index += 1) {
      if (!removedTechFieldIds.includes(index)) fields.push(index);
    }

    return fields.map(fieldNum => {
      const TECHFIELDS = [
        <Fragment>
          <div key={`form-vehicle-tech-field-${fieldNum}`} className="form-flex" style={showDocs ? {} : DNONE}>
            <div style={{ display: 'none' }}>
              <Field name={`techId${fieldNum}`} component={renderTextField} />
            </div>

            <Field
              name={`techScan${fieldNum}`}
              component={renderFileInputField}
              onChange={e => this.handleFileInputChange(e)}
            />

            <Field name={`techNum${fieldNum}`} component={renderTextField} />

            <Field name={`techStart${fieldNum}`} component={renderDatePickerField} />

            <Field name={`techEnd${fieldNum}`} component={renderDatePickerField} />

            {techFieldsCount - removedTechFieldIds.length > 1 && (
              <div
                style={{ marginTop: '2px' }}
                //title={t('misc.remove')}
                id={`removeTech${fieldNum}`}
                role="button"
                onClick={() => that.removeTechField(that, fieldNum)}
              >
                <DeleteOutlineIcon />
                <UncontrolledTooltip placement="top" target={`removeTech${fieldNum}`}>
                  {t('misc.remove')}
                </UncontrolledTooltip>
              </div>
            )}
            {showDocs && editData && (
              <DocumentVersionsTable
                files={editData[`techFile${fieldNum}`]}
                onDocumentRemoved={this.props.onDocumentRemoved}
                downloadData={{
                  type: 'tech',
                  workerId: editData._id,
                  name: editData.name,
                  surname: editData.surname,
                  fieldNum,
                }}
                onDownload={this.downloadFile}
                notif={this.props.notif}
              />
            )}
          </div>
        </Fragment>,
      ];

      if (showAlert[`tech${fieldNum}`]) {
        TECHFIELDS.push(AlertElt);
      }

      return TECHFIELDS;
    });
  };

  //! TEHNIČNI

  //* ZAVAROVANJE */

  addInsField = () => {
    this.setState({ insFieldsCount: this.state.insFieldsCount + 1 });
  };

  removeInsField = (ctx, fieldNum) => {
    const rtf = ctx.state.removedInsFieldIds;
    rtf.push(fieldNum);
    ctx.setState({ removedInsFieldIds: rtf });
    ctx.forceUpdate();
  };

  renderInsFields = (showDocs, AlertElt) => {
    const { insFieldsCount, removedInsFieldIds, showAlert } = this.state;
    const { editData, t } = this.props;
    const that = this;
    const fields = [];
    for (let index = 0; index < insFieldsCount; index += 1) {
      if (!removedInsFieldIds.includes(index)) fields.push(index);
    }

    return fields.map(fieldNum => {
      const INSFIELDS = [
        <Fragment>
          <div key={`form-vehicle-ins-field-${fieldNum}`} className="form-flex" style={showDocs ? {} : DNONE}>
            <Field
              name={`insScan${fieldNum}`}
              component={renderFileInputField}
              onChange={e => this.handleFileInputChange(e)}
            />

            <Field name={`insNum${fieldNum}`} component={renderTextField} />

            <Field name={`insStart${fieldNum}`} component={renderDatePickerField} />

            <Field name={`insEnd${fieldNum}`} component={renderDatePickerField} />

            {insFieldsCount - removedInsFieldIds.length > 1 && (
              <div
                style={{ marginTop: '2px' }}
                //title={t('misc.remove')}
                id={`removeIns${fieldNum}`}
                role="button"
                onClick={() => that.removeInsField(that, fieldNum)}
              >
                <DeleteOutlineIcon />
                <UncontrolledTooltip placement="top" target={`removeIns${fieldNum}`}>
                  {t('misc.remove')}
                </UncontrolledTooltip>
              </div>
            )}
            {showDocs && editData && (
              <DocumentVersionsTable
                files={editData[`insFile${fieldNum}`]}
                onDocumentRemoved={this.props.onDocumentRemoved}
                downloadData={{
                  type: 'ins',
                  workerId: editData._id,
                  name: editData.name,
                  surname: editData.surname,
                  fieldNum,
                }}
                onDownload={this.downloadFile}
                notif={this.props.notif}
              />
            )}
          </div>
        </Fragment>,
      ];

      if (showAlert[`ins${fieldNum}`]) {
        INSFIELDS.push(AlertElt);
      }

      return INSFIELDS;
    });
  };

  //! ZAVAROVANJE

  //* ZAVAROVANJE */

  addRegField = () => {
    this.setState({ regFieldsCount: this.state.regFieldsCount + 1 });
  };

  removeRegField = (ctx, fieldNum) => {
    const rtf = ctx.state.removedRegFieldIds;
    rtf.push(fieldNum);
    ctx.setState({ removedRegFieldIds: rtf });
    ctx.forceUpdate();
  };

  renderRegFields = (showDocs, AlertElt) => {
    const { regFieldsCount, removedRegFieldIds, showAlert } = this.state;
    const { editData, t } = this.props;
    const that = this;
    const fields = [];
    for (let index = 0; index < regFieldsCount; index += 1) {
      if (!removedRegFieldIds.includes(index)) fields.push(index);
    }

    return fields.map(fieldNum => {
      const INSFIELDS = [
        <Fragment>
          <div key={`form-vehicle-reg-field-${fieldNum}`} className="form-flex" style={showDocs ? {} : DNONE}>
            <Field
              name={`regScan${fieldNum}`}
              component={renderFileInputField}
              onChange={e => this.handleFileInputChange(e)}
            />

            {/* <Field name={`regNum${fieldNum}`} component={renderTextField} /> */}
            <Field name={`regFirst${fieldNum}`} component={renderDatePickerField} />

            <Field name={`regStart${fieldNum}`} component={renderDatePickerField} />

            <Field name={`regEnd${fieldNum}`} component={renderDatePickerField} />

            {regFieldsCount - removedRegFieldIds.length > 1 && (
              <div
                style={{ marginTop: '2px' }}
                //title={t('misc.remove')}
                id={`removeReg${fieldNum}`}
                role="button"
                onClick={() => that.removeRegField(that, fieldNum)}
              >
                <DeleteOutlineIcon />
                <UncontrolledTooltip placement="top" target={`removeReg${fieldNum}`}>
                  {t('misc.remove')}
                </UncontrolledTooltip>
              </div>
            )}
            {showDocs && editData && (
              <DocumentVersionsTable
                files={editData[`regFile${fieldNum}`]}
                onDocumentRemoved={this.props.onDocumentRemoved}
                downloadData={{
                  type: 'reg',
                  workerId: editData._id,
                  name: editData.name,
                  surname: editData.surname,
                  fieldNum,
                }}
                onDownload={this.downloadFile}
                notif={this.props.notif}
              />
            )}
          </div>
        </Fragment>,
      ];

      if (showAlert[`reg${fieldNum}`]) {
        INSFIELDS.push(AlertElt);
      }

      return INSFIELDS;
    });
  };

  //! ZAVAROVANJE

  render() {
    const { handleCancel, handleDelete, editData, people, pristine, t } = this.props;
    const { activeTab, showBasic, showDocs, showAlert, alertMsg, blockButtons } = this.state;

    let saveBtnText = editData ? t('form.save') : t('form.add');
    if (this.state.fileCounter > 0) {
      saveBtnText = `${t('form.upload')} & ${saveBtnText}`;
    }

    const AlertElt = (
      <Alert color="danger" className="alert--bordered" icon handleDismiss={this.dismissAlert}>
        <p>
          <span className="bold-text">{t('form.docError')}:</span>
          <br />
          {alertMsg}
        </p>
      </Alert>
    );

    return (
      <Col md={12} lg={12}>
        <Card>
          <CardBody>
            {this.renderTabsField(activeTab)}
            <form className="form" onSubmit={e => this.hijackSubmit(e)}>
              <div className="form__form-group" style={showBasic ? {} : DNONE}>
                <span className="form__form-group-label">Registrska</span>
                <div className="form__form-group-field">
                  <Field name="licensePlate" component={renderField} type="text" />
                </div>
              </div>

              <div className="form__form-group" style={showBasic ? {} : DNONE}>
                <span className="form__form-group-label">Znamka</span>
                <div className="form__form-group-field">
                  <Field name="brand" component={renderField} type="text" />
                </div>
              </div>

              <div className="form__form-group" style={showBasic ? {} : DNONE}>
                <span className="form__form-group-label">Dobavitelj</span>
                <div className="form__form-group-field">
                  <Field name="provider" component={renderField} type="text" />
                </div>
              </div>

              <div className="form__form-group" style={showBasic ? {} : DNONE}>
                <span className="form__form-group-label">Tip</span>
                <div className="form__form-group-field">
                  <Field name="type" component={renderSelectField} options={vehTypeOptions} />
                </div>
              </div>

              {/* tehnični */}
              <Collapse title={t('vehicles.tech_long')} className="with-shadow" style={showDocs ? {} : DNONE}>
                <div className="form-assignments-titles form-dummy-space" style={showDocs ? {} : DNONE}>
                  <div className="mr-ins">
                    <span className="form__form-group-label">{t('vehicles.tech_long')}</span>
                  </div>

                  <div className="mr-ins">
                    <span className="form__form-group-label">
                      {t('vehicles.tech')} ({t('form.num')})
                    </span>
                  </div>
                  <div className="mr-ins">
                    <span className="form__form-group-label">
                      {t('vehicles.tech')} ({t('form.begin')})
                    </span>
                  </div>
                  <div className="last">
                    <span className="form__form-group-label">
                      {t('vehicles.tech')} ({t('form.exp')})
                    </span>
                  </div>
                </div>
                {this.renderTechFields(showDocs, AlertElt)}
                <div className="form-add-new-field-btn" style={showDocs ? {} : DNONE}>
                  <Button
                    size="sm"
                    style={{
                      width: '145px',
                      fontSize: '12px',
                      borderRadius: '2px',
                    }}
                    onClick={this.addTechField}
                  >
                    {`${t('form.add')} ${t('vehicles.tech')}`}
                  </Button>
                </div>
              </Collapse>
              {/* tehnični */}

              {/* zavarovanje */}
              <Collapse title={t('vehicles.ins')} className="with-shadow" style={showDocs ? {} : DNONE}>
                <div className="form-assignments-titles form-dummy-space" style={showDocs ? {} : DNONE}>
                  <div className="mr-ins">
                    <span className="form__form-group-label">{t('vehicles.ins')}</span>
                  </div>

                  <div className="mr-ins">
                    <span className="form__form-group-label">
                      {t('vehicles.ins')} ({t('form.num')})
                    </span>
                  </div>
                  <div className="mr-ins">
                    <span className="form__form-group-label">
                      {t('vehicles.ins')} ({t('form.begin')})
                    </span>
                  </div>
                  <div className="last">
                    <span className="form__form-group-label">
                      {t('vehicles.ins')} ({t('form.exp')})
                    </span>
                  </div>
                </div>
                {this.renderInsFields(showDocs, AlertElt)}
                <div className="form-add-new-field-btn" style={showDocs ? {} : DNONE}>
                  <Button
                    size="sm"
                    style={{
                      width: '145px',
                      fontSize: '12px',
                      borderRadius: '2px',
                    }}
                    onClick={this.addInsField}
                  >
                    {`${t('form.add')} ${t('vehicles.ins')}`}
                  </Button>
                </div>
              </Collapse>
              {/* zavarovanje */}

              {/* registracija */}
              <Collapse title={t('vehicles.reg')} className="with-shadow" style={showDocs ? {} : DNONE}>
                <div className="form-assignments-titles form-dummy-space" style={showDocs ? {} : DNONE}>
                  <div className="mr-ins">
                    <span className="form__form-group-label">{t('vehicles.reg')}</span>
                  </div>

                  <div className="mr-ins">
                    <span className="form__form-group-label">{t('vehicles.reg')} (Prva)</span>
                  </div>
                  <div className="mr-ins">
                    <span className="form__form-group-label">
                      {t('vehicles.reg')} ({t('form.begin')})
                    </span>
                  </div>
                  <div className="last">
                    <span className="form__form-group-label">
                      {t('vehicles.reg')} ({t('form.exp')})
                    </span>
                  </div>
                </div>
                {this.renderRegFields(showDocs, AlertElt)}
                <div className="form-add-new-field-btn" style={showDocs ? {} : DNONE}>
                  <Button
                    size="sm"
                    style={{
                      width: '145px',
                      fontSize: '12px',
                      borderRadius: '2px',
                    }}
                    onClick={this.addRegField}
                  >
                    {`${t('form.add')} ${t('vehicles.reg')}`}
                  </Button>
                </div>
              </Collapse>
              {/* registracija */}

              <ButtonToolbar className="form__button-toolbar">
                {editData ? (
                  <Modal
                    color="danger"
                    title="Pozor!"
                    colored
                    btn="Izbris"
                    message={`Ste prepričani da želite izbrisati vozilo z registrsko ${editData.licensePlate}?`}
                    onConfirm={() => handleDelete(editData)}
                  />
                ) : null}
                <Button type="button" onClick={handleCancel}>
                  Prekliči
                </Button>
                <Button
                  color="primary"
                  onClick={e => {
                    this.dismissAlert();
                    if (this.state.fileCounter > 0) {
                      this.hijackSubmit(e, true);
                    } else {
                      this.hijackSubmit(e, false);
                    }
                  }}
                  disabled={pristine && this.state.fileCounter === 0 && blockButtons}
                >
                  {saveBtnText}
                </Button>
              </ButtonToolbar>
            </form>
          </CardBody>
        </Card>
      </Col>
    );
  }
}

VozilaForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
  handleUpload: PropTypes.func.isRequired,
  people: PropTypes.arrayOf(PropTypes.object).isRequired,
  editData: PropTypes.object,
  pristine: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
  onDocumentRemoved: PropTypes.func,
  notif: PropTypes.object,
  initialTab: PropTypes.string,

  initialTechFieldCount: PropTypes.number,
  initialInsFieldCount: PropTypes.number,
  initialRegFieldCount: PropTypes.number,
};

VozilaForm.defaultProps = {
  editData: null,
  onDocumentRemoved: null,
  notif: null,
  initialTab: '1',
  initialTechFieldCount: 1,
  initialInsFieldCount: 1,
  initialRegFieldCount: 1,
};

const rf = reduxForm({
  form: 'vozila_form', // a unique identifier for this form
  validate,
  /* onChange: (values, dispatch, props, previousValues) => {
      props.submit();
    }, */

  // initialValues: { name: "", surname: "",  },
})(VozilaForm);
export default translate('common')(rf);
