import React, { Component } from 'react';
import { translate } from 'react-i18next';
import { Card, CardBody, Row, Col } from 'reactstrap';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { MultiSelectField } from 'shared/components/form/MultiSelect';
import { EmployeesProps, CompaniesProps, VehiclesProps, DataListProps } from 'shared/prop-types/ReducerProps';
import { translateTrasactionLabelsArray } from '../../../translations/dynamicTranslator';

import constants from '../helpers/index';

const { TRANSACTION_SUBTYPES, TRAVEL_ORDER_OPTIONS, RETURNED_REASONS } = constants;

const VIEW_ITEMS = t => [
  {
    value: true,
    type: 'unconfirmed',
    label: t('cashbox.unconfirmed'),
  },
  {
    value: false,
    type: 'unconfirmed',
    label: t('cashbox.confirmed'),
  },
  {
    value: true,
    type: 'imported',
    label: 'UVOŽENI',
  },
];

class CashboxFilter extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    workersState: EmployeesProps.isRequired,
    companiesState: CompaniesProps.isRequired,
    vehiclesState: VehiclesProps.isRequired,
    dataListState: DataListProps.isRequired,
    onSubmit: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      selectedCompanySearchItem: [],
      selectedWorkerSearchItem: [],
      selectedVehicleSearchItem: [],
      selectedTransactionCategorySearchItem: [],
      selectedTransactionSourceSearchItem: [],
      selectedTransactionSubtypeSearchItem: [],
      selectedViewSearchItem: [],
      selectedTravelOrderOptionSearchItem: [],
      selectedReturnedReasonSearchItem: [],
    };
  }

  componentDidMount() {
    this.props.onSubmit({
      ...this.state,
    });
  }

  handleCompanyChange = value => {
    this.setState({
      selectedCompanySearchItem: value,
    });
    this.props.onSubmit({
      ...this.state,
      selectedCompanySearchItem: value,
    });
  };

  handleWorkerChange = value => {
    this.setState({
      selectedWorkerSearchItem: value,
    });
    this.props.onSubmit({
      ...this.state,
      selectedWorkerSearchItem: value,
    });
  };

  handleTransactionSourcesChange = value => {
    this.setState({
      selectedTransactionSourceSearchItem: value,
    });
    this.props.onSubmit({
      ...this.state,
      selectedTransactionSourceSearchItem: value,
    });
  };

  handleTransactionSubtypeChange = value => {
    this.setState({
      selectedTransactionSubtypeSearchItem: value,
    });
    this.props.onSubmit({
      ...this.state,
      selectedTransactionSubtypeSearchItem: value,
    });
  };

  handleTransactionCategoryChange = value => {
    this.setState({
      selectedTransactionCategorySearchItem: value,
    });
    this.props.onSubmit({
      ...this.state,
      selectedTransactionCategorySearchItem: value,
    });
  };

  handleVehicleChange = value => {
    this.setState({
      selectedVehicleSearchItem: value,
    });
    this.props.onSubmit({
      ...this.state,
      selectedVehicleSearchItem: value,
    });
  };

  handleViewChange = value => {
    this.setState({
      selectedViewSearchItem: value,
    });
    this.props.onSubmit({
      ...this.state,
      selectedViewSearchItem: value,
    });
  };

  handleTravelOrderOptionsChange = value => {
    this.setState({
      selectedTravelOrderOptionSearchItem: value,
    });
    this.props.onSubmit({
      ...this.state,
      selectedTravelOrderOptionSearchItem: value,
    });
  };

  handleReturnedReasonsChange = value => {
    this.setState({
      selectedReturnedReasonSearchItem: value,
    });
    this.props.onSubmit({
      ...this.state,
      selectedReturnedReasonSearchItem: value,
    });
  };

  render() {
    const { t, workersState, vehiclesState, dataListState } = this.props;
    const {
      companiesState: { companiesTitles },
    } = this.props;

    const {
      selectedCompanySearchItem,
      selectedWorkerSearchItem,
      selectedVehicleSearchItem,
      selectedTransactionCategorySearchItem,
      selectedTransactionSourceSearchItem,
      selectedTransactionSubtypeSearchItem,
      selectedViewSearchItem,
      selectedTravelOrderOptionSearchItem,
      selectedReturnedReasonSearchItem,
    } = this.state;

    return (
      <Card>
        <CardBody>
          <div className="card__title">
            <h5 className="bold-text">{t('board.filters.title')}</h5>
          </div>

          <Row>
            <Col lg={6}>
              <div className="display-table">
                <MultiSelectField
                  name="rez-sources-filter-search-1"
                  onChange={this.handleTransactionSourcesChange}
                  placeholder={t('cashbox.source')}
                  options={dataListState.transactionSources}
                  value={selectedTransactionSourceSearchItem}
                />
              </div>
            </Col>
            <Col lg={6}>
              <div className="display-table">
                <MultiSelectField
                  name="rez-categories-filter-search-2"
                  onChange={this.handleTransactionCategoryChange}
                  placeholder={t('cashbox.trans_cat')}
                  options={translateTrasactionLabelsArray(dataListState.transactionCategories)}
                  value={selectedTransactionCategorySearchItem}
                />
              </div>
            </Col>
          </Row>

          <Row className="mt-1">
            <Col lg={6}>
              <div className="display-table">
                <MultiSelectField
                  name="rez-subtype-filter-search-2"
                  onChange={this.handleTransactionSubtypeChange}
                  placeholder={t('cashbox.trans_type')}
                  options={translateTrasactionLabelsArray(TRANSACTION_SUBTYPES)}
                  value={selectedTransactionSubtypeSearchItem}
                />
              </div>
            </Col>
            <Col lg={6}>
              <div className="display-table">
                <MultiSelectField
                  name="rez-vehicle-filter-search-1"
                  onChange={this.handleVehicleChange}
                  placeholder={t('board.ow.vehicle')}
                  options={vehiclesState.licenses}
                  value={selectedVehicleSearchItem}
                />
              </div>
            </Col>
          </Row>

          <Row className="mt-1">
            <Col lg={6}>
              <div className="display-table">
                <MultiSelectField
                  name="rez-to-options-filter-search-1"
                  onChange={this.handleTravelOrderOptionsChange}
                  placeholder={`${t('cashbox.to_option')} (${t('cashbox.travel_order')})`}
                  options={translateTrasactionLabelsArray(TRAVEL_ORDER_OPTIONS)}
                  value={selectedTravelOrderOptionSearchItem}
                />
              </div>
            </Col>
            <Col lg={6}>
              <div className="display-table">
                <MultiSelectField
                  name="rez-returned-filter-search-2"
                  onChange={this.handleReturnedReasonsChange}
                  placeholder={t('cashbox.returned')}
                  options={translateTrasactionLabelsArray(RETURNED_REASONS)}
                  value={selectedReturnedReasonSearchItem}
                />
              </div>
            </Col>
          </Row>

          <Row className="mt-1">
            <Col lg={6}>
              <div className="display-table">
                <MultiSelectField
                  name="rez-companies-filter-search-1"
                  onChange={this.handleCompanyChange}
                  placeholder={`${t('form.company')}`}
                  options={companiesTitles}
                  value={selectedCompanySearchItem}
                />
              </div>
            </Col>
            <Col lg={6}>
              <div className="display-table">
                <MultiSelectField
                  name="rez-workers-filter-search-2"
                  onChange={this.handleWorkerChange}
                  placeholder={`${t('board.ow.worker')}`}
                  options={workersState.employeesNames}
                  value={selectedWorkerSearchItem}
                />
              </div>
            </Col>
          </Row>

          <Row className="mt-1">
            <Col lg={6}>
              <div className="display-table">
                <MultiSelectField
                  name="rez-view-filter-search-2"
                  onChange={this.handleViewChange}
                  placeholder={t('docsexp.view')}
                  options={VIEW_ITEMS(t)}
                  value={selectedViewSearchItem}
                />
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
    );
  }
}

const cn = connect(state => ({
  workersState: state.employees,
  companiesState: state.companies,
  vehiclesState: state.vehicles,
  dataListState: state.dataList,
}))(CashboxFilter);

export default translate('common')(cn);
