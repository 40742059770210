import React, { PureComponent } from 'react';
import { Field, reduxForm } from 'redux-form';
// import EyeIcon from 'mdi-react/EyeIcon';
import KeyVariantIcon from 'mdi-react/KeyVariantIcon';
import AccountOutlineIcon from 'mdi-react/AccountOutlineIcon';
import { translate } from 'react-i18next';
// import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';

class LogInForm extends PureComponent {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
  };

  constructor() {
    super();
    this.state = {
      showPassword: false,
    };
  }

  showPassword = e => {
    e.preventDefault();
    this.setState({
      showPassword: !this.state.showPassword,
    });
  };

  render() {
    const { handleSubmit, t } = this.props;

    return (
      <form className="form" onSubmit={handleSubmit}>
        <div className="form__form-group">
          <span className="form__form-group-label">{t('login.form.username')}</span>
          <div className="form__form-group-field">
            <div className="form__form-group-icon">
              <AccountOutlineIcon />
            </div>
            <Field name="username" component="input" type="text" placeholder={t('login.form.user')} />
          </div>
        </div>
        <div className="form__form-group">
          <span className="form__form-group-label">{t('login.form.password')}</span>
          <div className="form__form-group-field">
            <div className="form__form-group-icon">
              <KeyVariantIcon />
            </div>
            <Field
              name="password"
              component="input"
              type={this.state.showPassword ? 'text' : 'password'}
              placeholder={t('login.form.password')}
            />
            {/* <button
              className={`form__form-group-button${this.state.showPassword ? ' active' : ''}`}
              onClick={e => this.showPassword(e)}
            >
              <EyeIcon />
            </button> */}
          </div>
        </div>

        {/* <ButtonToolbar className="form__button-toolbar"> */}
        <Button color="primary" type="submit" style={{ width: '100%', fontWeight: 'bold' }}>
          {t('login.form.login')}
        </Button>
        {/* </ButtonToolbar> */}
        {/* <Link className="btn btn-primary account__btn account__btn--small" to="/pages/one">
          Prijava
        </Link> */}
      </form>
    );
  }
}

const wr = reduxForm({
  form: 'log_in_form',
})(LogInForm);

export default translate('common')(wr);
