import React from 'react';
import { Card, CardBody, Col, Table, Badge } from 'reactstrap';
import PropTypes from 'prop-types';
import CheckIcon from 'mdi-react/CheckIcon';
import CloseIcon from 'mdi-react/CloseIcon';

const renderTable = (companiesAll, onRowSelect) => {
  const companies = [];
  companiesAll.forEach(c => {
    if (!c.fake) {
      companies.push(c);
    }
  });

  return companies.map(company => (
    <tr key={company._id} id={company._id} onClick={() => onRowSelect(company._id)}>
      <td>{company.name}</td>
      <td>{company.address}</td>
      <td>{company.zip}</td>
      <td>{company.city}</td>
      <td>{company.country}</td>
      <td>
        {company.color ? (
          <Badge style={{ backgroundColor: `${company.color}`, color: '#646777' }}>{company.color}</Badge>
        ) : (
          ''
        )}
      </td>
      <td>{company.coop ? <CheckIcon /> : <CloseIcon />}</td>
    </tr>
  ));
};

const PodjetjaTable = ({ title, companies, onRowSelect }) => (
  <Col md={12} lg={12} xl={12}>
    <Card>
      <CardBody>
        <div className="card__title">
          <h5 className="bold-text">{title}</h5>
          {/* <h5 className="subhead">lol</h5> */}
        </div>
        <Table hover className="table--bordered" responsive>
          <thead>
            <tr>
              <th>Naziv</th>
              <th>Naslov</th>
              <th>Poštna št.</th>
              <th>Kraj</th>
              <th>Država</th>
              <th>Barva</th>
              <th>Kooperant</th>
            </tr>
          </thead>
          <tbody>{renderTable(companies, onRowSelect)}</tbody>
        </Table>
      </CardBody>
    </Card>
  </Col>
);

PodjetjaTable.propTypes = {
  title: PropTypes.string.isRequired,
  companies: PropTypes.arrayOf(PropTypes.object).isRequired,
  onRowSelect: PropTypes.func.isRequired,
};

export default PodjetjaTable;
