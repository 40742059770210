import React, { Component, Fragment } from 'react';
import ReactGA from 'react-ga';
import { ButtonToolbar, Col, Container, Row } from 'reactstrap';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { translate } from 'react-i18next';
import docsApi from 'api/docs';
import ReactLoading from 'react-loading';
import { DataListProps } from 'shared/prop-types/ReducerProps';
import DokumentiExpTable from './components/DocumentsExpTable';
import DocumentsExpFilters from './components/Filters';
import Legend from '../Boards/components/Legend';
import DokumentsExpSheet from './components/DokumentsExpSheet';

class DokumentiPage extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    dataListStore: DataListProps.isRequired,
  };

  constructor(props) {
    super(props);
    const currentFilterValues = this.defaultFilterValues();
    this.state = {
      docs: [],
      currentFilterValues,
      loading: true,
    };
  }

  componentDidMount() {
    this.commitFilters(this.state.currentFilterValues);
  }

  defaultFilterValues = () => {
    return {
      dropdown: {
        selectedDocTypeSearchItem: [],
        selectedWorkerSearchItem: [],
        selectedVehicleSearchItem: [],
      },
      options: {
        docStateSelectedButton: '1',
        docVisibilitySelectedButton: '2',
        docTypeSelectedButton: '1',
      },
      time: {
        range: [-50, 50],
      },
    };
  };

  fetchFilteredByTimeRange = async range => {
    const body = {
      minRange: range[0],
      maxRange: range[1],
      infinity: 365,
    };
    const filtered = await docsApi.getFiltered(body);
    return filtered;
  };

  filterByTypeAndWorkersAndVehicles = (filteredByRange, filterValues) => {
    let workersIds = [];
    let vehiclesIds = [];
    let types = [];
    if (filterValues.dropdown.selectedWorkerSearchItem.length > 0) {
      workersIds = filterValues.dropdown.selectedWorkerSearchItem.map(w => w.value);
    }
    if (filterValues.dropdown.selectedVehicleSearchItem.length > 0) {
      vehiclesIds = filterValues.dropdown.selectedVehicleSearchItem.map(w => w.value);
    }
    if (filterValues.dropdown.selectedDocTypeSearchItem.length > 0) {
      types = filterValues.dropdown.selectedDocTypeSearchItem.map(w => w.value);
    }

    const filteredByWorkers = filteredByRange.filter(
      f => workersIds.length === 0 || (f.type === 'employee' && workersIds.includes(f.worker._id)),
    );
    const filteredByVehicles = filteredByWorkers.filter(
      f => vehiclesIds.length === 0 || (f.type === 'vehicle' && vehiclesIds.includes(f.vehicle._id)),
    );

    const filteredByTypes = filteredByVehicles.filter(f => types.length === 0 || types.includes(f.doc.type));

    return filteredByTypes;
  };

  filterByOptions = (filteredByTypeAndWorkers, filterValues) => {
    const {
      dataListStore: { docsExpTimetable },
    } = this.props;

    let filteredByDocState = filteredByTypeAndWorkers;

    if (filterValues.options.docStateSelectedButton === '2') {
      filteredByDocState = filteredByTypeAndWorkers.filter(f => f.diff <= 0);
    } else if (filterValues.options.docStateSelectedButton === '3') {
      const filteredPositive = filteredByTypeAndWorkers.filter(f => f.diff >= 0);
      filteredByDocState = [];
      for (let i = 0; i < filteredPositive.length; i += 1) {
        const doc = filteredPositive[i];

        const alertTime = docsExpTimetable[doc.doc.type];
        if (doc.diff <= alertTime) {
          filteredByDocState.push(doc);
        }
      }
    }

    let filteredByDocVisibility = filteredByDocState;

    if (filterValues.options.docVisibilitySelectedButton === '2') {
      filteredByDocVisibility = filteredByDocState.filter(f => !f.hidden);
    } else if (filterValues.options.docVisibilitySelectedButton === '3') {
      filteredByDocVisibility = filteredByDocState.filter(f => f.hidden);
    }

    let filteredByDocType = filteredByDocVisibility;

    if (filterValues.options.docTypeSelectedButton === '2') {
      filteredByDocType = filteredByDocVisibility.filter(f => f.type === 'employee');
    } else if (filterValues.options.docTypeSelectedButton === '3') {
      filteredByDocType = filteredByDocVisibility.filter(f => f.type === 'vehicle');
    }

    return filteredByDocType;
  };

  commitFilters = async filterValues => {
    const filteredByRange = await this.fetchFilteredByTimeRange(filterValues.time.range);

    const filteredByTypeAndWorkers = this.filterByTypeAndWorkersAndVehicles(filteredByRange, filterValues);

    const filteredByOptions = this.filterByOptions(filteredByTypeAndWorkers, filterValues);

    this.setState({
      loading: false,
      docs: filteredByOptions,
      currentFilterValues: filterValues,
    });
  };

  handleFilterSubmit = filterValues => {
    try {
      this.setState({ loading: true });
      this.commitFilters(filterValues);
    } catch (error) {
      console.log('TCL: DokumentiPage -> handleFilterSubmit -> error', error);
    }
  };

  handleDocVisibility = (docId, hidden, id, type) => {
    const index = this.state.docs.findIndex(d => d._id === docId);
    const changedDocs = this.state.docs;
    changedDocs[index].hidden = !hidden;
    if (!hidden && this.state.currentFilterValues.options.docVisibilitySelectedButton === '2') {
      changedDocs.splice(index, 1);
    } else if (hidden && this.state.currentFilterValues.options.docVisibilitySelectedButton === '3') {
      changedDocs.splice(index, 1);
    }

    this.setState({ docs: changedDocs });

    if (!hidden) {
      docsApi.hideDocument({ docId, id, type });
    } else {
      docsApi.unhideDocument({ docId, id, type });
    }
  };

  reload = () => {
    this.handleFilterSubmit(this.state.currentFilterValues);
  };

  render() {
    const { docs, loading } = this.state;
    const { t } = this.props;
    const defaultFilter = this.defaultFilterValues();
    return (
      <Container className="dashboard">
        <Row>
          <Col lg={12}>
            <h3 className="page-title">{t('sidebar_content.docs.title')}</h3>
          </Col>
          <Col xs={12}>
            <DocumentsExpFilters defaultValues={defaultFilter} onSubmit={this.handleFilterSubmit} />
          </Col>
        </Row>
        <Row>
          <Col lg={6}>
            <Legend title={t('board.legend')} lettercodes={false} />
          </Col>
        </Row>
        <Row>
          {loading ? (
            <ReactLoading type="bars" color="gray" height={128} width={128} className="react-loader" />
          ) : (
            <Fragment>
              <ButtonToolbar>
                <DokumentsExpSheet btn={t('form.exportExcel')} title={t('sidebar_content.docs.title')} data={docs} />
              </ButtonToolbar>

              <Col md={12} lg={12} xl={12}>
                <DokumentiExpTable
                  title={t('sidebar_content.docs.title')}
                  docs={docs}
                  onDocVisibilityChange={this.handleDocVisibility}
                  onEmployeeEdited={this.reload}
                />
              </Col>
            </Fragment>
          )}
        </Row>
      </Container>
    );
  }
}

const wr = withRouter(
  connect(state => ({
    dataListStore: state.dataList,
  }))(DokumentiPage),
);

export default translate('common')(wr);

// handleDropdown(value) {
//   console.log('TCL: DokumentiPage -> handleDropdown -> value', value);
//   if (!value.selected_dropdown) {
//     this.setState({ docs: this.state.docsFull });
//     return;
//   }

//   ReactGA.event({
//     category: 'Dokumenti',
//     action: `Filtriranje Dokumentov - ${value.selected_dropdown.label}`,
//   });

//   const temp = this.state.docsFull.filter(
//     alert => alert.doc.type === value.selected_dropdown.value,
//   );
//   this.setState({ docs: temp });
// }

// async fetchData() {
//   const { dataListStore } = this.props;
//   // this.setState({
//   //   docs: dataListStore.docs,
//   //   docsFull: dataListStore.docs,
//   // });
// }
