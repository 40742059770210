import { SHOW_DOCS_EXP_BADGE, SET_DOCS_EXP_ALERTS } from '../actions/docsActions';

const initialState = {
  show: false,
  alerts: [],
};

export default function(state = initialState, action) {
  switch (action.type) {
    case SHOW_DOCS_EXP_BADGE:
      return { ...state, show: action.show };
    case SET_DOCS_EXP_ALERTS:
      return { ...state, alerts: action.alerts };
    default:
      return state;
  }
}
