import { API_LINK } from 'constants/config';
import { GET } from 'helpers/agent';

export default {
  getall: async () => {
    const data = await GET(`${API_LINK}/o/getall`);
    if (data.success) {
      const orderersList = [];
      data.orderers.forEach(ord => {
        const ordData = {
          value: ord._id,
          label: ord.title,
        };
        orderersList.push(ordData);
      });
      return { orderers: data.orderers, titlesOrderers: orderersList };
    }
    return { orderers: [], titlesOrderers: [] };
  },
};
