import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-i18next';
import countries from 'i18n-iso-countries';
import { translateVehType } from 'translations/dynamicTranslator';
import DownIcon from 'mdi-react/ChevronDownIcon';
import UpIcon from 'mdi-react/ChevronUpIcon';
import { connect } from 'react-redux';
import { Badge, UncontrolledTooltip, Popover, PopoverHeader, PopoverBody, Button } from 'reactstrap';
import { CompaniesProps } from 'shared/prop-types/ReducerProps';
import CopyToClipboard from 'react-copy-to-clipboard';
import moment from 'moment-timezone';
import employeesApi from 'api/employees';
import providersApi from 'api/providers';
import accommodationsApi from 'api/accommodations';
import assignmentsApi from 'api/assignments';
import getRoles from 'constants/roles';
import { D_FORMAT } from '../../../../constants/config';
import EmployeesFormModal from '../Modals/EmployeesFormModal';
import DeassignModal from '../Modals/DeassignModal';
import ChangesModal from '../Modals/ChangesModal';
import AccommodationsFormModal from '../Modals/AccommodationsFormModal';
import ProviderFormModal from '../Modals/ProviderFormModal';
import AssignmentsFormModal from '../Modals/AssignmentsFormModal';

class BoardTableRow extends Component {
  static propTypes = {
    companiesStore: CompaniesProps.isRequired,
    employee: PropTypes.object.isRequired,
    ow: PropTypes.object.isRequired,
    isStaged: PropTypes.bool.isRequired,
    t: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      collapsed: true,
      accommodationsPopoverShown: false,
      employeesFormModalShown: false,
      employeePopoverShown: false,
      deassignModalShown: false,
      changesModalShown: false,
      providerFormModalShown: false,
      assignmentsPopoverShown: false,
      assignmentsFormModalShown: false,
      employeeSelectedTab: '1',
      ROLES: getRoles(),
    };
  }

  getEditableEmployee = async () => {
    const { employee, isStaged } = this.props;

    const data = await employeesApi.getOne(JSON.stringify({ id: !isStaged ? employee._id : employee.worker }));
    return data;
  };

  getEmployeeCurrentState = async () => {
    const { employee } = this.props;
    const data = await employeesApi.getCurrentState({ workerId: employee._id });

    return data;
  };

  getEditableAccommodation = async () => {
    const { employee } = this.props;
    const data = await accommodationsApi.getOne({
      id: employee.accommodation._id,
    });
    return data;
  };

  getEditableProvider = async () => {
    const { employee } = this.props;
    const data = await providersApi.getOne({
      id: employee.accommodationProvider._id,
    });
    return data;
  };

  getEditableAssignment = async () => {
    const { employee, isStaged } = this.props;

    const postData = { id: employee.assignment._id };
    const data = isStaged ? await assignmentsApi.getOneStaged(postData) : await assignmentsApi.getOne(postData);

    return data;
  };

  currentCompany = () => {
    const { employee, companiesStore } = this.props;
    if (!employee.company) {
      return null;
    }
    return companiesStore.companies.find(comp => employee.company.companyId === comp._id);
  };

  badgeColor = () => {
    const company = this.currentCompany();
    if (!company) {
      return '';
    }
    return company.color;
  };

  colorA1DateString = (a1Date, a1DateString) => {
    const style = {};
    try {
      // const { boardDate } = this.props;
      const boardDate = new Date();
      const dayDiff = moment(boardDate).diff(moment(a1Date), 'days');
      // pozitivno število pomeni čez rok, negativno do roka
      if (dayDiff >= 0) {
        style.color = 'red';
      } else if (dayDiff >= -7) {
        style.color = 'orange';
      }
    } catch (e) {
      return <span>{`${a1DateString}`}</span>;
    }
    return <span style={style}>{`${a1DateString}`}</span>;
  };

  a1Info = () => {
    const {
      employee: { a1 },
      ow: { worksite },
      t,
    } = this.props;

    /* if (!a1) {
      return <strong style={{ color: 'red' }}>{t('board.ow.a1Missing')}!</strong>;
    }
    if (worksite && (worksite.countryId === 'SI' || worksite.countryId === 'DE')) {
      return <span style={{ color: 'lightgray' }}>{t('board.ow.noneed')}</span>;
    }
    return this.colorA1DateString(a1.date, a1.dateString); */
    let a1Info = <span style={{ color: 'lightgray' }}>{t('board.ow.noneed')}</span>;
    if (a1.needed) {
      if (a1.date) {
        a1Info = this.colorA1DateString(a1.date, a1.dateString);
      } else {
        a1Info = <strong style={{ color: 'red' }}>MANJKA!</strong>;
      }
    }
    return a1Info;
  };

  handleDocumentRemoved = async () => {
    const data = await this.getEditableEmployee();
    this.setState({ editableEmployee: data });
  };

  clipboardText = () => {
    const { employee, t } = this.props;

    return `${
      employee.accommodationProvider && employee.accommodationProvider.title
        ? employee.accommodationProvider.title.toUpperCase()
        : ''
    }
    ${employee.accommodation && employee.accommodation.address && employee.accommodation.address.toUpperCase()}
    ${employee.accommodation &&
      employee.accommodation.zip &&
      employee.accommodation.zip.toUpperCase()}, ${employee.accommodation &&
      employee.accommodation.city &&
      employee.accommodation.city.toUpperCase()}
    ${employee.accommodation && employee.accommodation.country && employee.accommodation.country.toUpperCase()}
    ${t('board.ow.provider')}:
    ${
      employee.accommodationProvider && employee.accommodationProvider.contact
        ? `${employee.accommodationProvider.contact}`
        : ''
    }
    ${
      employee.accommodationProvider && employee.accommodationProvider.tel
        ? `${employee.accommodationProvider.tel}`
        : ''
    }`;
  };

  leaveInfo = () => {
    const { employee, t } = this.props;
    return {
      style: employee.onLeave ? { backgroundColor: '#6AABB8', color: 'white' } : null,
      color: employee.onLeave ? { color: 'white' } : {},
      text: employee.onLeave ? `- ${t('board.ow.leave')}` : '',
    };
  };

  toggleCollapse = () => {
    if (window.innerWidth > 991) {
      return;
    }
    this.setState(prevState => ({
      collapsed: !prevState.collapsed,
    }));
  };

  showEmployeesFormModal = async (tabNum = '1') => {
    const data = await this.getEditableEmployee();
    this.setState({
      employeesFormModalShown: true,
      editableEmployee: data,
      employeeSelectedTab: tabNum,
    });
  };

  hideEmployeesFormModal = () => {
    this.setState({ employeesFormModalShown: false });
  };

  showDeassignModal = async () => {
    const data = await this.getEditableEmployee();
    this.setState({ deassignModalShown: true, editableEmployee: data });
  };

  hideDeassignModal = () => {
    this.setState({ deassignModalShown: false });
  };

  showChangesModal = async () => {
    const data = await this.getEmployeeCurrentState(); // this.getEditableEmployee();
    this.setState({ changesModalShown: true, currentStateEmployee: data });
  };

  hideChangesModal = () => {
    this.setState({ changesModalShown: false });
  };

  showAccommodationsFormModal = async () => {
    const data = await this.getEditableAccommodation();
    this.setState({
      accommodationsFormModalShown: true,
      editableAccommodation: data,
    });
  };

  hideAccommodationsFormModal = () => {
    this.setState({ accommodationsFormModalShown: false });
  };

  showAssignmentsFormModal = async () => {
    const data = await this.getEditableAssignment();
    this.setState({
      assignmentsFormModalShown: true,
      editableAssignment: data,
    });
  };

  hideAssignmentsFormModal = () => {
    this.setState({ assignmentsFormModalShown: false });
  };

  showProviderFormModal = async () => {
    const data = await this.getEditableProvider();
    this.setState({ providerFormModalShown: true, editableProvider: data });
  };

  hideProviderFormModal = () => {
    this.setState({ providerFormModalShown: false });
  };

  toggleEmployeesPopover = () => {
    this.setState(prevState => ({
      employeePopoverShown: !prevState.employeePopoverShown,
    }));
  };

  toggleAccommodationsPopover = () => {
    this.setState(prevState => ({
      accommodationsPopoverShown: !prevState.accommodationsPopoverShown,
    }));
  };

  toggleAssignmentsPopover = () => {
    this.setState(prevState => ({
      assignmentsPopoverShown: !prevState.assignmentsPopoverShown,
    }));
  };

  editData = () => {
    const { employee, ow } = this.props;
    const { accommodation, vehicle } = employee;
    const { editableEmployee } = this.state;
    const worker = editableEmployee;
    return {
      workerId: editableEmployee._id,
      ordererId: {
        value: ow.orderer ? ow.orderer._id : null,
        label: ow.orderer ? ow.orderer.title : null,
      },
      worksiteId: {
        value: ow.worksite ? ow.worksite._id : null,
        label: ow.worksite ? ow.worksite.title : null,
      },
      vehicleId: {
        value: vehicle ? vehicle._id : null,
        label: vehicle ? `${vehicle.licensePlate} ${vehicle.brand}` : null,
      },
      accommodationId: {
        value: accommodation ? accommodation._id : null,
        label: accommodation ? accommodation.address : null,
      },
      docsData: {
        company: employee.company,
        assignment: { date: editableEmployee.assignmentDate },
        worksite: ow.worksite,
        worker: editableEmployee,
      },
      date: employee.assignment.date,
      ...worker,
    };
  };

  deassignEditData = () => {
    const { employee, ow } = this.props;
    //console.log('TCL: BoardTableRow -> deassignEditData -> ow', ow);
    const { accommodation, vehicle } = employee;
    // console.log('TCL: BoardTableRow -> deassignEditData -> vehicle', vehicle);
    // console.log(
    //   'TCL: BoardTableRow -> deassignEditData -> accommodation',
    //   accommodation,
    // );
    const { editableEmployee } = this.state;
    // console.log(
    //   'TCL: BoardTableRow -> deassignEditData -> editableEmployee',
    //   editableEmployee,
    // );
    const worker = editableEmployee;
    const lang = localStorage.getItem('lang') || 'sl';

    const worksiteId = {
      value: ow.worksite && ow.worksite._id ? ow.worksite._id : null,
      label: ow.worksite && ow.worksite.title ? ow.worksite.title : null,
    };
    const ordererId = {
      value: ow.orderer && ow.orderer._id ? ow.orderer._id : null,
      label: ow.orderer && ow.orderer.title ? ow.orderer.title : null,
    };
    const vehicleId = {
      value: vehicle && vehicle._id ? vehicle._id : null,
      label: vehicle ? `${vehicle.licensePlate} ${vehicle.brand}` : null,
    };
    const accommodationId = {
      value: accommodation && accommodation._id ? accommodation._id : null,
      label: accommodation && accommodation.title ? accommodation.title : null,
    };

    const deassData = {
      vehicle: vehicle ? true : null,
      accommodation: accommodation ? false : null,
      worksite: ow.worksite ? true : null,
      orderer: ow.orderer ? true : null,
      date: new Date(Date.now()),
      dateTo: new Date(Date.now()),
      dateIssued: new Date(Date.now()),
      docsData: {
        company: {
          ...employee.company,
          country: employee.company.countryId ? countries.getName(employee.company.countryId, lang) : '',
        },
        assignment: { date: editableEmployee.assignmentDate },
        worksite: {
          ...ow.worksite,
          country: ow.worksite && ow.worksite.countryId ? countries.getName(ow.worksite.countryId, lang) : '',
        },
        worker: { ...editableEmployee, nameOnly: editableEmployee.name },
      },
      additional: {
        ...worker,
        workerId: editableEmployee._id,
        worksiteId,
        ordererId,
        vehicleId,
        accommodationId,
      },
    };
    console.log('TCL: BoardTableRow -> deassignEditData -> deassData', deassData);
    return deassData;
  };

  currentStateData = () => {
    const { currentStateEmployee } = this.state;
    return {
      workerId: currentStateEmployee.worker._id,
      ordererId: {
        value: currentStateEmployee.orderer ? currentStateEmployee.orderer._id : null,
        label: currentStateEmployee.orderer ? currentStateEmployee.orderer.title : null,
      },
      worksiteId: {
        value: currentStateEmployee.worksite ? currentStateEmployee.worksite._id : null,
        label: currentStateEmployee.worksite ? currentStateEmployee.worksite.title : null,
      },
      vehicleId: {
        value: currentStateEmployee.vehicle ? currentStateEmployee.vehicle._id : null,
        label: currentStateEmployee.vehicle
          ? `${currentStateEmployee.vehicle.licensePlate} ${currentStateEmployee.vehicle.brand}`
          : null,
      },
      accommodationId: {
        value: currentStateEmployee.accommodation ? currentStateEmployee.accommodation._id : null,
        label: currentStateEmployee.accommodation ? currentStateEmployee.accommodation.address : null,
      },
      ...currentStateEmployee.worker,
      date: new Date(Date.now()),
    };
  };

  renderTooltip = () => {
    const { employee, ow } = this.props;
    return (
      <UncontrolledTooltip placement="left" target={`AccommodationsPopover${employee._id}${ow._id}`}>
        {employee.accommodationProvider &&
          employee.accommodationProvider.title &&
          employee.accommodationProvider.title.toUpperCase()}
        <br />
        {employee.accommodation && employee.accommodation.address && employee.accommodation.address.toUpperCase()}
        <br />
        {`${employee.accommodation &&
          employee.accommodation.zip &&
          employee.accommodation.zip.toUpperCase()}, ${employee.accommodation &&
          employee.accommodation.city &&
          employee.accommodation.city.toUpperCase()}`}
        <br />
        {employee.accommodation && employee.accommodation.country && employee.accommodation.country.toUpperCase()}
        <br />
        Ponudnik:
        {employee.accommodationProvider && employee.accommodationProvider.contact && (
          <Fragment>
            <br />
            {employee.accommodationProvider.contact}
          </Fragment>
        )}
        {employee.accommodationProvider && employee.accommodationProvider.tel && (
          <Fragment>
            <br />
            {employee.accommodationProvider.tel}
          </Fragment>
        )}
      </UncontrolledTooltip>
    );
  };

  render() {
    const { employee, ow, isStaged, t } = this.props;
    const {
      collapsed,
      employeesFormModalShown,
      employeePopoverShown,
      editableEmployee,
      deassignModalShown,
      changesModalShown,
      accommodationsPopoverShown,
      accommodationsFormModalShown,
      providerFormModalShown,
      editableAccommodation,
      editableProvider,
      assignmentsFormModalShown,
      assignmentsPopoverShown,
      editableAssignment,
      employeeSelectedTab,
      ROLES,
    } = this.state;
    return (
      <tr style={this.leaveInfo().style || {}}>
        <td onClick={this.toggleCollapse} onKeyDown={this.toggleCollapse} role="presentation">
          <Badge
            style={{
              backgroundColor: `${this.badgeColor()}`,
              color: '#646777',
              cursor: 'pointer',
            }}
            id={`EmployeesPopover${employee._id}${ow._id}`}
            onClick={this.toggleEmployeesPopover}
          >
            {`${employee.surname} ${employee.name} ${this.leaveInfo().text}`}
            {employee.vehicle && (
              <div className="col-3 hidden-desktop" style={{ marginTop: '5px', whiteSpace: 'nowrap' }}>
                <strong style={{ color: 'IndianRed' }}>
                  {translateVehType(employee.vehicle.licensePlate, localStorage.getItem('lang') || 'sl')}
                </strong>
              </div>
            )}
          </Badge>

          {ROLES.full && (
            <Popover
              placement="right"
              isOpen={employeePopoverShown}
              target={`EmployeesPopover${employee._id}${ow._id}`}
              toggle={this.toggleEmployeesPopover}
            >
              <PopoverHeader>{t('board.ow.popover.editWorker')}</PopoverHeader>
              <PopoverBody className="board-actions">
                <Button color="primary" className="mr-0 mb-2" onClick={() => this.showEmployeesFormModal('1')}>
                  {t('board.ow.popover.profileWorker')}
                </Button>
                <Button color="primary" className="mr-0 mb-2" onClick={() => this.showEmployeesFormModal('4')}>
                  {t('board.notes.modalTitle')}
                </Button>
                <Button color="primary" className="mr-0 mb-2" onClick={this.showAssignmentsFormModal}>
                  {t('board.ow.popover.viewAssignment')}
                </Button>
                {!isStaged && (
                  <Fragment>
                    <Button color="primary" className="mr-0 mb-2" onClick={this.showChangesModal}>
                      {t('board.ow.popover.updateState')}
                    </Button>
                    <Button color="primary" className="mr-0 mb-2" onClick={this.showDeassignModal}>
                      {t('board.ow.popover.deassign')}
                    </Button>
                  </Fragment>
                )}
              </PopoverBody>
            </Popover>
          )}

          {collapsed ? (
            <DownIcon className="tabla__mobile-down-icon hidden-desktop" />
          ) : (
            <UpIcon className="tabla__mobile-down-icon hidden-desktop" />
          )}
        </td>
        {!collapsed || window.innerWidth >= 991 ? (
          <Fragment>
            <td
              data-label={t('board.ow.accommodation')}
              className="with-label"
              id={`AccommodationsPopover${employee._id}${ow._id}`}
              onClick={this.toggleAccommodationsPopover}
              onKeyDown={this.toggleAccommodationsPopover}
              role="presentation"
            >
              {employee.accommodation ? (
                <Fragment>
                  <span className="board-accommodation" style={this.leaveInfo().style ? { color: 'lightcyan' } : {}}>
                    {employee.accommodation.address}
                  </span>
                  {this.renderTooltip()}
                </Fragment>
              ) : (
                <span style={this.leaveInfo().textColor}>/</span>
              )}
            </td>

            {ROLES.full && (
              <Popover
                placement="right"
                isOpen={employee.accommodation && accommodationsPopoverShown}
                target={`AccommodationsPopover${employee._id}${ow._id}`}
                toggle={this.toggleAccommodationsPopover}
              >
                <PopoverHeader>{t('board.ow.popover.editAccommodationTitle')}</PopoverHeader>
                <PopoverBody className="board-actions">
                  <Button color="primary" className="mr-0 mb-2" onClick={this.showAccommodationsFormModal}>
                    {t('board.ow.popover.editAccommodation')}
                  </Button>
                  <Button color="primary" className="mr-0 mb-2" onClick={this.showProviderFormModal}>
                    {t('board.ow.popover.editProvider')}
                  </Button>

                  <CopyToClipboard text={this.clipboardText()}>
                    <Button
                      color="primary"
                      className="mr-0 mb-2"
                      id={`AccommodationsPopoverButton${employee._id}${ow._id}`}
                      style={this.leaveInfo().textColor}
                      href="#accomodation"
                      onClick={event => {
                        event.preventDefault();
                      }}
                    >
                      {t('board.ow.popover.copyData')}
                    </Button>
                  </CopyToClipboard>
                </PopoverBody>
              </Popover>
            )}
            <td
              data-label={t('board.ow.date')}
              // className="with-label"
              // onClick={this.toggleAssignmentsPopover}
              // onKeyDown={this.toggleAssignmentsPopover}
              role="presentation"
              id={`AssignmentsPopover${employee._id}${ow._id}`}
            >
              <span style={this.leaveInfo().textColor}>
                {employee.assignment && employee.assignment.date
                  ? moment(employee.assignment.date).format(D_FORMAT)
                  : '/'}
              </span>
            </td>

            {/* <Popover
              placement="right"
              isOpen={assignmentsPopoverShown}
              target={`AssignmentsPopover${employee._id}${ow._id}`}
              toggle={this.toggleAssignmentsPopover}
            >
              <PopoverHeader>Napotitev</PopoverHeader>
              <PopoverBody className="board-actions">
                <Button color="primary" className="mr-0 mb-2" onClick={this.showAssignmentsFormModal}>
                  Preglej Napotitev
                </Button>
              </PopoverBody>
            </Popover> */}
            <td data-label={t('board.ow.vehicle')} className="with-label">
              <span style={this.leaveInfo().textColor}>
                {employee.vehicle
                  ? translateVehType(employee.vehicle.licensePlate, localStorage.getItem('lang') || 'sl')
                  : '/'}
              </span>
            </td>

            <td data-label={t('board.ow.a1Exp')} className="with-label">
              <span style={this.leaveInfo().textColor}>{this.a1Info()}</span>
            </td>
          </Fragment>
        ) : (
          ''
        )}
        {employeesFormModalShown && (
          <EmployeesFormModal
            title={t('board.ow.popover.editWorker')}
            icon="edit"
            show={employeesFormModalShown}
            onClose={this.hideEmployeesFormModal}
            editData={editableEmployee}
            onDocumentRemoved={this.handleDocumentRemoved}
            initialTab={employeeSelectedTab}
          />
        )}
        {deassignModalShown && (
          <DeassignModal
            icon="edit"
            title={t('board.ow.popover.deassign')}
            show={deassignModalShown}
            color="primary"
            onClose={this.hideDeassignModal}
            editData={this.deassignEditData()}
          />
        )}
        {changesModalShown && (
          <ChangesModal
            icon="edit"
            title={t('board.ow.popover.updateState')}
            show={changesModalShown}
            onClose={this.hideChangesModal}
            editData={this.currentStateData()}
          />
        )}
        {accommodationsFormModalShown && (
          <AccommodationsFormModal
            icon="edit"
            title={t('board.ow.popover.editAccommodation')}
            show={accommodationsFormModalShown}
            onClose={this.hideAccommodationsFormModal}
            editData={editableAccommodation}
          />
        )}
        {providerFormModalShown && (
          <ProviderFormModal
            icon="edit"
            title={t('board.ow.popover.editProvider')}
            show={providerFormModalShown}
            onClose={this.hideProviderFormModal}
            editData={editableProvider}
          />
        )}
        {assignmentsFormModalShown && (
          <AssignmentsFormModal
            icon="edit"
            title={t('board.ow.popover.editAssignment')}
            show={assignmentsFormModalShown}
            onClose={this.hideAssignmentsFormModal}
            editData={editableAssignment}
          />
        )}
      </tr>
    );
  }
}

const wr = connect(state => ({
  companiesStore: state.companies,
}))(BoardTableRow);

export default translate('common')(wr);
