export const SET_PROFESSIONS_DATA_LIST = 'SET_PROFESSIONS_DATA_LIST';
export const SET_COUNTRIES_DATA_LIST = 'SET_COUNTRIES_DATA_LIST';
export const SET_DOCS_EXP_DATA_LIST = 'SET_DOCS_EXP_DATA_LIST';
export const SET_TRANSACTION_SOURCES_DATA_LIST = 'SET_TRANSACTION_SOURCES_DATA_LIST';
export const SET_TRANSACTION_CATEGORIES_DATA_LIST = 'SET_TRANSACTION_CATEGORIES_DATA_LIST';

export function setProfessionsDataList(professions) {
  return {
    type: SET_PROFESSIONS_DATA_LIST,
    professions,
  };
}

export function setCountriesDataList(countries) {
  return {
    type: SET_COUNTRIES_DATA_LIST,
    countries,
  };
}

export function setDocsExpDataList(docsData) {
  return {
    type: SET_DOCS_EXP_DATA_LIST,
    docsData,
  };
}

export function setTransactionSourcesDataList(transactionSources) {
  return {
    type: SET_TRANSACTION_SOURCES_DATA_LIST,
    transactionSources,
  };
}

export function setTransactionCategoriesDataList(transactionCategories) {
  return {
    type: SET_TRANSACTION_CATEGORIES_DATA_LIST,
    transactionCategories,
  };
}
