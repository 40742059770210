import React, { Component } from 'react';
import { translate } from 'react-i18next';
import { Card, CardBody, Row, Col, Button } from 'reactstrap';
import PropTypes from 'prop-types';
import Range from 'shared/components/range_slider/Range';

class DocTimeFilter extends Component {
  _rangeVal;

  static propTypes = {
    t: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    defaultValues: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      range: props.defaultValues.range,
      unconfirmedChanges: false,
    };
    this._rangeVal = this.state.range;
  }

  handleRangeSubmit = () => {
    this.setState({ unconfirmedChanges: false });
    this.props.onSubmit(this._rangeVal);
  };

  handleChange = val => {
    this._rangeVal = val;
    this.setState({ unconfirmedChanges: true });
  };

  render() {
    const { t } = this.props;
    const { range, unconfirmedChanges } = this.state;
    return (
      <Card>
        <CardBody className="pb-4">
          <div className="card__title">
            <h5 className="bold-text">{t('docsexp.period')}</h5>
            <h5 className="subhead">
              <i>{t('docsexp.periodSub')}</i>
            </h5>
          </div>
          <Row>
            <Col lg={9}>
              <Range min={-365} max={365} value={range} tipFormatter={value => value} onChange={this.handleChange} />
            </Col>
            <Col lg={3} className="mt-4">
              <Button size="sm" disabled={!unconfirmedChanges} color="primary" onClick={this.handleRangeSubmit}>
                {t('docsexp.confirm')}
              </Button>
            </Col>
          </Row>
        </CardBody>
      </Card>
    );
  }
}

export default translate('common')(DocTimeFilter);
