import { API_LINK } from 'constants/config';
import { GET, POST } from 'helpers/agent';

export default {
  getall: async () => {
    const response = await GET(`${API_LINK}/acm/getall`);
    if (response.success) {
      const titlesList = [];
      response.accommodations.forEach(acm => {
        const acmData = {
          value: acm._id,
          label: acm.address,
        };
        titlesList.push(acmData);
      });
      const providersList = [];
      response.accommodations.forEach(prov => {
        const provData = {
          value: prov.providerId,
          label: prov.title,
        };
        if (!providersList.find(p => p.value === prov.providerId)) {
          providersList.push(provData);
        }
      });
      return {
        accommodations: response.accommodations,
        accommodationTitles: titlesList,
        providerTitles: providersList,
      };
    }
    return { accommodations: [], accommodationTitles: [], providerTitles: [] };
  },
  getproviders: async () => {
    const response = await GET(`${API_LINK}/pr/getall`);
    if (response.success) {
      return { providers: response.providers };
    }
    return { providers: [] };
  },
  create: async data => {
    const response = await POST(`${API_LINK}/acm/add`, JSON.stringify(data));
    return response.accommodation;
  },
  addReservation: async data => {
    const response = await POST(`${API_LINK}/acm/addReservation`, JSON.stringify(data));
    return response.success;
  },
  getReservations: async data => {
    const response = await POST(`${API_LINK}/acm/getReservations`, JSON.stringify(data));
    if (response.success) {
      return { reservations: response.reservations };
    }
    return { reservations: [] };
  },
  getReservationById: async data => {
    const response = await POST(`${API_LINK}/acm/getReservationById`, JSON.stringify(data));
    if (response.success) {
      return { reservation: response.reservation };
    }
    return { reservation: null };
  },
  updateReservation: async data => {
    const response = await POST(`${API_LINK}/acm/updateReservation`, JSON.stringify(data));
    return response.success;
  },
  deleteReservation: async data => {
    const response = await POST(`${API_LINK}/acm/deleteReservation`, JSON.stringify(data));
    return response.success;
  },
  addAccommodation: async data => {
    const response = await POST(`${API_LINK}/acm/add`, JSON.stringify(data));
    return response.success;
  },
  updateAccommodation: async data => {
    const response = await POST(`${API_LINK}/acm/update`, JSON.stringify(data));
    return response.success;
  },
  deleteAccommodation: async data => {
    const response = await POST(`${API_LINK}/acm/delete`, JSON.stringify(data));
    return response.success;
  },

  getOne: async data => {
    const response = await POST(`${API_LINK}/acm/getOneById`, JSON.stringify(data));
    return response.accommodation;
  },
};
