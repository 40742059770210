const validate = values => {
  // console.log('TCL: validate -> values', values);
  const errors = {};

  if (!values.companyId) {
    errors.companyId = 'Dieses Feld muss ausgefüllt werden!';
  }
  if (!values.providerId) {
    errors.providerId = 'Dieses Feld muss ausgefüllt werden!';
  }
  if (!values.worksiteId) {
    errors.worksiteId = 'Dieses Feld muss ausgefüllt werden!';
  }
  if (!values.dateFrom) {
    errors.dateFrom = 'Dieses Feld muss ausgefüllt werden!';
  }
  if (!values.dateTo) {
    errors.dateTo = 'Dieses Feld muss ausgefüllt werden!';
  }

  if (values.dateFrom && values.dateTo) {
    if (new Date(values.dateTo).getTime() <= new Date(values.dateFrom).getTime()) {
      errors.dateTo = 'Datum mora biti večji';
    }
  }

  if (!values.persons) {
    errors.persons = 'Dieses Feld muss ausgefüllt werden!';
  } else if (Number.isNaN(values.persons)) {
    errors.persons = 'Dieses Feld muss ausgefüllt werden!';
  }

  if (values.price) {
    if (Number.isNaN(values.price)) {
      errors.price = 'Dieses Feld muss ausgefüllt werden!';
    }
  }

  if (values.unpaid) {
    if (Number.isNaN(values.unpaid)) {
      errors.unpaid = 'Dieses Feld muss ausgefüllt werden!';
    }
  }

  return errors;
};

export default validate;
