import React from 'react';
import { connect } from 'react-redux';
import ReactGA from 'react-ga';
import employeesApi from 'api/employees';
import { Col, Container, Row, Card, CardBody, Progress } from 'reactstrap';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';
import SelectDropdown from 'shared/components/SelectDropdown';
import { API_LINK } from 'constants/config';
import { POST } from 'helpers/agent';
import DelavciZgodovinaTable from './components/DelavciZgodovinaTable';
import { EmployeesProps, AppStatusProps } from '../../../shared/prop-types/ReducerProps';

// const API_EMPLOYEES_LOGS = '/e/getlogs';

class DelavciZgodovinaPage extends React.Component {
  static propTypes = {
    workersState: EmployeesProps.isRequired,
    appStatus: AppStatusProps.isRequired,
    t: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      people: [],
      assignments: [],
      progressNum: 0,
      selectedWorker: null,
    };

    this.fetchData = this.fetchData.bind(this);
    this.handleProgress = this.handleProgress.bind(this);
    this.handleDropdownSubmit = this.handleDropdownSubmit.bind(this);
  }

  componentDidMount() {
    const { appStatus } = this.props;
    if (appStatus.requiredDataLoaded) {
      console.log('EMPLOYEES_HISTORY -> Data loaded.. continue...');
      this.fetchData();
    }
  }

  componentDidUpdate(prevProps) {
    const { appStatus } = this.props;
    if (appStatus.requiredDataLoaded && !prevProps.appStatus.requiredDataLoaded) {
      console.log('EMPLOYEES_HISTORY -> Data finished loading.. continue...');
      this.fetchData();
    }

    if (!appStatus.appUpdating && prevProps.appStatus.appUpdating) {
      console.log('EMPLOYEES_HISTORY -> Data finished UPDATING.. refresh table...');
      this.fetchData();
    }
  }

  handleProgress(addProgress) {
    const { progressNum } = this.state;
    this.setState({ progressNum: progressNum + addProgress });
    const that = this;
    if (progressNum + addProgress >= 100) {
      setTimeout(() => {
        that.handleProgress(-(progressNum + addProgress));
      }, 1000);
    }
  }

  fetchData() {
    const { workersState } = this.props;
    this.setState({ people: workersState.employeesNames });
  }

  async handleDropdownSubmit(values) {
    try {
      if (values.selected_dropdown === null) {
        return;
      }
      ReactGA.event({
        category: 'Delavci - Zgodovina',
        action: `Filtriranje Delavca - ${values.selected_dropdown.label}`,
      });
      const workerId = values.selected_dropdown.value;
      this.handleProgress(50);
      const data = await employeesApi.getlogs(workerId);
      if (data) {
        this.setState({
          assignments: data,
          selectedWorker: values.selected_dropdown.label,
        });
        this.handleProgress(50);
      }
    } catch (error) {
      console.log(error);
    }
  }

  render() {
    const { people, assignments, progressNum, selectedWorker } = this.state;
    const { t } = this.props;
    return (
      <Container className="dashboard">
        <Row>
          <Col lg={12}>
            <h3 className="page-title">
              {t('sidebar_content.workers.title')} - {t('sidebar_content.workers.history')}
            </h3>
          </Col>
          <Col lg={12}>
            <Row>
              <Col lg={6}>
                <Card>
                  <CardBody>
                    <SelectDropdown
                      title={`${t('board.ow.worker')}:`}
                      items={people}
                      onSubmit={this.handleDropdownSubmit}
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          {progressNum > 0 ? (
            <div className="progress-wrap progress-wrap--middle progress--align">
              <Progress animated value={progressNum} />
            </div>
          ) : null}
          {assignments && assignments.length > 0 && (
            <DelavciZgodovinaTable
              title={
                selectedWorker
                  ? `${t('sidebar_content.workers.history')}: ${selectedWorker}`
                  : `${t('sidebar_content.workers.history')}`
              }
              asslogs={assignments}
            />
          )}
        </Row>
      </Container>
    );
  }
}

const wr = connect(state => ({
  workersState: state.employees,
  appStatus: state.appStatus,
}))(DelavciZgodovinaPage);

export default translate('common')(wr);
