import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import shortid from 'shortid';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { translate } from 'react-i18next';
import getRoles from 'constants/roles';
import SidebarLink from './SidebarLink';
import SidebarCategory from './SidebarCategory';
import { AppStatusProps, DataListProps } from '../../../shared/prop-types/ReducerProps';
import { setRouterBlockNotif } from '../../../redux/actions/appActions';

class SidebarContent extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    onClick: PropTypes.func.isRequired,
    appStatus: AppStatusProps.isRequired,
    dataListStore: DataListProps.isRequired,
    t: PropTypes.func.isRequired,
  };

  hideSidebar = toRoute => {
    if (toRoute === '/dokumenti/potek') {
      localStorage.setItem('docAlertOverrride', true);
      this.forceUpdate();
    }
    const {
      appStatus: { blockRouter, showRouterBlockNotif },
      dispatch,
    } = this.props;

    if (blockRouter && !showRouterBlockNotif) {
      dispatch(setRouterBlockNotif(toRoute));
    }

    this.props.onClick();
  };

  render() {
    const {
      appStatus: { blockRouter },
      t,
      dataListStore: { showAlert },
    } = this.props;
    const { pathname } = window.location;
    const alertOverride = localStorage.getItem('docAlertOverrride');

    let showDocAlert = showAlert;
    if (alertOverride === 'true') {
      showDocAlert = false;
    }

    const roles = getRoles();

    const blockpath = blockRouter ? pathname : null;

    // const route = this.rf ? window.location.pathname

    return (
      <div className="sidebar__content">
        <ul key={shortid.generate()} className="sidebar__block">
          {(roles.full || roles.table) && (
            <SidebarLink
              title={t('sidebar_content.board')}
              icon="screen"
              route="/tabla"
              block={blockpath}
              onClick={this.hideSidebar}
            />
          )}
          {(roles.full || roles.workhours) && (
            <SidebarCategory title={t('sidebar_content.workhours.title')} icon="clock">
              {(roles.full || !roles.workhours.readonly) && (
                <SidebarLink
                  title={t('sidebar_content.workhours.by_worker')}
                  route="/ure/delavci"
                  block={blockpath}
                  onClick={this.hideSidebar}
                />
              )}
              {(roles.full || !roles.workhours.readonly) && (
                <SidebarLink
                  title={t('sidebar_content.workhours.by_worksite')}
                  route="/ure/gradbisca"
                  block={blockpath}
                  onClick={this.hideSidebar}
                />
              )}
              <SidebarLink
                title={t('sidebar_content.workhours.calcs')}
                route="/ure/izpis"
                block={blockpath}
                onClick={this.hideSidebar}
              />
            </SidebarCategory>
          )}
          {(roles.full || roles.assignments) && (
            <SidebarLink
              title={t('sidebar_content.assignments')}
              icon="file-add"
              route="/napotitve"
              block={blockpath}
              onClick={this.hideSidebar}
            />
          )}
          {/* {(roles.full || roles.changes) && (
            <SidebarLink
              title="Spremembe"
              icon="sync"
              route="/spremembe"
              block={blockpath}
              onClick={this.hideSidebar}
            />
          )} */}
        </ul>
        <ul key={shortid.generate()} className="sidebar__block">
          {(roles.full || roles.companies) && (
            <SidebarLink
              title={t('sidebar_content.companies')}
              icon="briefcase"
              route="/podjetja"
              block={blockpath}
              onClick={this.hideSidebar}
            />
          )}
          {(roles.full || roles.workers) && (
            <SidebarCategory title={t('sidebar_content.workers.title')} icon="user">
              {(roles.full || roles.workers === true || roles.workers.list) && (
                <SidebarLink
                  title={t('sidebar_content.workers.edit')}
                  route="/delavci"
                  block={blockpath}
                  onClick={this.hideSidebar}
                />
              )}
              {(roles.full || roles.workers === true || roles.workers.history) && (
                <SidebarLink
                  title={t('sidebar_content.workers.history')}
                  route="/delavci/zgodovina"
                  block={blockpath}
                  onClick={this.hideSidebar}
                />
              )}
            </SidebarCategory>
          )}
          {(roles.full || roles.orderers) && (
            <SidebarLink
              title={t('sidebar_content.orderers')}
              icon="store"
              route="/narocniki"
              block={blockpath}
              onClick={this.hideSidebar}
            />
          )}
          {(roles.full || roles.worksites) && (
            <SidebarLink
              title={t('sidebar_content.worksites')}
              icon="construction"
              route="/gradbisca"
              block={blockpath}
              onClick={this.hideSidebar}
            />
          )}

          {(roles.full || roles.accommodations) && (
            <SidebarCategory title={t('sidebar_content.accommodations.title')} icon="apartment">
              {(roles.full || roles.accommodations === true || roles.accommodations.locations) && (
                <SidebarLink
                  title={t('sidebar_content.accommodations.locations')}
                  route="/prenocisca"
                  block={blockpath}
                  onClick={this.hideSidebar}
                />
              )}
              {(roles.full || roles.accommodations === true || roles.accommodations.providers) && (
                <SidebarLink
                  title={t('sidebar_content.accommodations.providers')}
                  route="/prenocisca/ponudniki"
                  block={blockpath}
                  onClick={this.hideSidebar}
                />
              )}
              {(roles.full || roles.accommodations === true || roles.accommodations.history) && (
                <SidebarLink
                  title={t('sidebar_content.accommodations.history')}
                  route="/prenocisca/zgodovina"
                  block={blockpath}
                  onClick={this.hideSidebar}
                />
              )}

              {(roles.full || roles.accommodations === true || roles.accommodations.reservations) && (
                <SidebarLink
                  title={t('sidebar_content.accommodations.reservations')}
                  route="/prenocisca/rezervacije"
                  block={blockpath}
                  onClick={this.hideSidebar}
                />
              )}

              {(roles.full || roles.accommodations === true || roles.accommodations.reservations) && (
                <SidebarLink
                  title={t('sidebar_content.accommodations.search')}
                  route="/prenocisca/iskanje"
                  block={blockpath}
                  onClick={this.hideSidebar}
                />
              )}
            </SidebarCategory>
          )}

          {(roles.full || roles.vehicles) && (
            <SidebarCategory title={t('sidebar_content.vehicles.title')} icon="car">
              {(roles.full || roles.vehicles === true || roles.vehicles.list) && (
                <SidebarLink
                  title={t('sidebar_content.vehicles.edit')}
                  route="/vozila"
                  block={blockpath}
                  onClick={this.hideSidebar}
                />
              )}
              {(roles.full || roles.vehicles === true || roles.vehicles.history) && (
                <SidebarLink
                  title={t('sidebar_content.vehicles.history')}
                  route="/vozila/zgodovina"
                  block={blockpath}
                  onClick={this.hideSidebar}
                />
              )}
            </SidebarCategory>
          )}

          {(roles.full || roles.docs) && (
            <SidebarCategory title={t('sidebar_content.docs.title')} icon="license" isNew={showDocAlert}>
              <SidebarLink
                title={t('sidebar_content.docs.expiry')}
                route="/dokumenti/potek"
                block={blockpath}
                onClick={this.hideSidebar}
                newLink={showDocAlert ? t('misc.new') : null}
              />
            </SidebarCategory>
          )}
        </ul>
        {(roles.super || roles.satnice || roles.cashbox || roles.projekt || roles.sistem) && (
          <ul key={shortid.generate()} className="sidebar__block">
            <SidebarCategory title={t('sidebar_content.finance.title')} icon="book">
              {(roles.super || roles.satnice || roles.sistem || roles.projekt) && (
                <SidebarLink
                  title="Urne postavke" //{t('sidebar_content.finance.wages')}
                  route="/finance/wages"
                  block={blockpath}
                  onClick={this.hideSidebar}
                />
              )}
              {(roles.demo || roles.cashbox || roles.sistem) && (
                <Fragment>
                  <SidebarLink
                    title={t('sidebar_content.finance.cashbox')}
                    route="/finance/cashbox"
                    block={blockpath}
                    onClick={this.hideSidebar}
                  />
                  {(roles.demo || roles.sistem) && (
                    <Fragment>
                      <SidebarLink
                        title={t('sidebar_content.finance.recurring')}
                        route="/finance/recurring"
                        block={blockpath}
                        onClick={this.hideSidebar}
                      />
                      <SidebarLink
                        title={t('sidebar_content.finance.wages')}
                        route="/finance/compensation"
                        block={blockpath}
                        onClick={this.hideSidebar}
                      />
                    </Fragment>
                  )}
                </Fragment>
              )}
            </SidebarCategory>
          </ul>
        )}
      </div>
    );
  }
}

const wr = withRouter(
  connect(state => ({
    dataListStore: state.dataList,
    appStatus: state.appStatus,
  }))(SidebarContent),
);

export default translate('common')(wr);
