/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody, Col, ButtonToolbar, Button, Nav, NavItem, NavLink } from 'reactstrap';
import classnames from 'classnames';
import moment from 'moment-timezone';
import vehiclesApi from 'api/vehicles';
import CalendarBlankIcon from 'mdi-react/CalendarBlankIcon';
import { Field, reduxForm } from 'redux-form';
import validate from './validate';
import renderSelectField from '../../../shared/components/form/Select';
import renderDatePickerField from '../../../shared/components/form/DatePicker';

const DNONE = { display: 'none' };
const SHOW = {};

class SpremembeForm extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      fieldsCount: props.initialFieldCount,
      removedFieldIds: [],
      activeTab: '1',
      showDefault: true,
      showLeaves: false,
      currDriver: null,
    };

    this.addField = this.addField.bind(this);
    this.removeField = this.removeField.bind(this);
    this.toggle = this.toggle.bind(this);
    this.renderTabsField = this.renderTabsField.bind(this);
    this.renderLeavesFields = this.renderLeavesFields.bind(this);
    this.handleSelectVehicle = this.handleSelectVehicle.bind(this);
  }

  toggle = tab => {
    if (this.state.activeTab !== tab) {
      switch (tab) {
        case '2':
          this.setState({
            activeTab: tab,
            showDefault: false,
            showLeaves: true,
          });
          break;

        default:
          this.setState({
            activeTab: tab,
            showDefault: true,
            showLeaves: false,
          });
          break;
      }
    }
  };

  addField() {
    this.setState({ fieldsCount: this.state.fieldsCount + 1 });
  }

  removeField(ctx, fieldNum) {
    const rf = ctx.state.removedFieldIds;
    rf.push(fieldNum);
    ctx.setState({ removedFieldIds: rf });
    ctx.forceUpdate();
  }

  hijackSubmit = e => {
    e.preventDefault();

    // eslint-disable-next-line
    const { handleSubmit, onSubmit, editData } = this.props;
    // console.log('TCL: SpremembeForm -> hijackSubmit -> editData', editData);
    const { removedFieldIds } = this.state;
    handleSubmit(values => {
      const valuesCopy = JSON.parse(JSON.stringify(values));

      console.log('valuesCopy', valuesCopy);

      let isPristine = true;
      let date = typeof editData.date === 'string' ? new Date(editData.date) : editData.date;
      let valuesDate = typeof values.date === 'string' ? new Date(values.date) : values.date;

      date = moment(date)
        .startOf('day')
        .toDate();
      valuesDate = moment(valuesDate)
        .startOf('day')
        .toDate();

      if (date.getTime() !== valuesDate.getTime()) {
        isPristine = false;
      } else if (JSON.stringify(editData.ordererId) !== JSON.stringify(values.ordererId)) {
        isPristine = false;
      } else if (JSON.stringify(editData.worksiteId) !== JSON.stringify(values.worksiteId)) {
        isPristine = false;
      } else if (JSON.stringify(editData.accommodationId) !== JSON.stringify(values.accommodationId)) {
        isPristine = false;
      } else if (JSON.stringify(editData.vehicleId) !== JSON.stringify(values.vehicleId)) {
        isPristine = false;
      }
      // console.log('TCL: SpremembeForm -> hijackSubmit -> isPristine', isPristine);

      valuesCopy.extra = removedFieldIds;
      valuesCopy.isPristine = isPristine;
      onSubmit(valuesCopy);
    })();
  };

  async handleSelectVehicle(e) {
    console.log('TCL: SpremembeForm -> handleSelectVehicle -> e', e);
    const { driver } = await vehiclesApi.getCurrentDriver({
      vehicleId: e.value,
    });
    console.log('TCL: SpremembeForm -> handleSelectVehicle -> driver', driver);
    if (driver) {
      this.setState({
        currDriver: `POZOR! Ta avto trenutno vozi ${driver.driver.surname.toUpperCase()} ${driver.driver.name}!`,
      });
    } else {
      this.setState({
        currDriver: null,
      });
    }
  }

  renderTabsField(activeTab) {
    return (
      <div className="tabs tabs--justify tabs--bordered-bottom" style={{ marginBottom: '40px' }}>
        <div className="tabs__wrap">
          <Nav tabs>
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === '1' })}
                onClick={() => {
                  this.toggle('1');
                }}
              >
                STANJE
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === '2' })}
                onClick={() => {
                  this.toggle('2');
                }}
              >
                DOPUSTI
              </NavLink>
            </NavItem>
          </Nav>
        </div>
      </div>
    );
  }

  renderLeavesFields(showLeaves) {
    const { fieldsCount, removedFieldIds } = this.state;
    const that = this;

    const fields = [];
    for (let index = 0; index < fieldsCount; index += 1) {
      if (!removedFieldIds.includes(index)) fields.push(index);
    }

    return fields.map(fieldNum => (
      <div
        key={`form-assignments-field-${fieldNum}`}
        className="form-assignments-fields"
        style={showLeaves ? { marginBottom: '5px' } : DNONE}
      >
        <div className="mr-50">
          <Field name={`leaveStartsAt${fieldNum}`} component={renderDatePickerField} />
        </div>
        <div className="last-50">
          <Field name={`leaveEndsAt${fieldNum}`} component={renderDatePickerField} />
        </div>
        {fieldsCount - removedFieldIds.length > 1 ? (
          <div style={{ width: '0px' }}>
            <Button
              className="form-assignments-remove-worker-btn"
              size="sm"
              color="link"
              onClick={() => that.removeField(that, fieldNum)}
            >
              X
            </Button>
          </div>
        ) : null}
      </div>
    ));
  }

  render() {
    const { handleCancel, editData, pristine, orderers, worksites, vehicles, accommodations } = this.props;

    const { activeTab, showDefault, showLeaves, removedFieldIds } = this.state;
    return (
      <Col md={12} lg={12}>
        <Card>
          <CardBody>
            {this.renderTabsField(activeTab)}
            <form className="form" onSubmit={this.hijackSubmit}>
              <div className="form__form-group" style={showDefault ? SHOW : DNONE}>
                <span className="form__form-group-label">Datum</span>
                <div className="form__form-group-field">
                  <Field name="date" component={renderDatePickerField} />
                  <div className="form__form-group-icon">
                    <CalendarBlankIcon />
                  </div>
                </div>
              </div>
              <div className="form__form-group" style={showDefault ? SHOW : DNONE}>
                <span className="form__form-group-label">Naročnik</span>
                <div className="form__form-group-field">
                  <Field name="ordererId" component={renderSelectField} options={orderers} />
                </div>
              </div>
              <div className="form__form-group" style={showDefault ? SHOW : DNONE}>
                <span className="form__form-group-label">Gradbišče</span>
                <div className="form__form-group-field">
                  <Field name="worksiteId" component={renderSelectField} options={worksites} />
                </div>
              </div>
              <div className="form__form-group" style={showDefault ? SHOW : DNONE}>
                <span className="form__form-group-label">Prenočišče</span>
                <div className="form__form-group-field">
                  <Field name="accommodationId" component={renderSelectField} options={accommodations} />
                </div>
              </div>
              <div className="form__form-group" style={showDefault ? SHOW : DNONE}>
                <span className="form__form-group-label">Vozilo</span>
                <div className="form__form-group-field">
                  <Field
                    name="vehicleId"
                    component={renderSelectField}
                    options={vehicles}
                    onChange={e => this.handleSelectVehicle(e)}
                  />
                </div>
              </div>
              <div className="form__form-group">
                <span style={showDefault && this.state.currDriver ? { color: 'red', marginBottom: '20px' } : DNONE}>
                  {this.state.currDriver}
                </span>
              </div>
              {/* LEAVES */}
              <div className="form-assignments-titles" style={showLeaves ? SHOW : DNONE}>
                <div className="mr-50">
                  <span className="form__form-group-label">Začetek dopusta</span>
                </div>
                <div className="last-50">
                  <span className="form__form-group-label">Konec dopusta</span>
                </div>
              </div>
              {this.renderLeavesFields(showLeaves)}
              <div className="form-assignments-add-worker-btn-wrapper" style={showLeaves ? SHOW : DNONE}>
                <Button className="form-assignments-add-worker-btn" size="sm" color="link" onClick={this.addField}>
                  Dodaj dopust
                </Button>
              </div>
              {/* LEAVES */}
              <ButtonToolbar className="form__button-toolbar">
                <Button type="button" onClick={handleCancel}>
                  Prekliči
                </Button>
                <Button color="primary" type="submit" disabled={pristine && removedFieldIds.length === 0}>
                  {editData ? 'Shrani spremembe' : 'Dodaj'}
                </Button>
              </ButtonToolbar>
            </form>
          </CardBody>
        </Card>
      </Col>
    );
  }
}

SpremembeForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
  // handleDelete: PropTypes.func.isRequired,
  orderers: PropTypes.arrayOf(PropTypes.object).isRequired,
  worksites: PropTypes.arrayOf(PropTypes.object).isRequired,
  vehicles: PropTypes.arrayOf(PropTypes.object).isRequired,
  accommodations: PropTypes.arrayOf(PropTypes.object).isRequired,
  editData: PropTypes.object,
  pristine: PropTypes.bool.isRequired,
  initialFieldCount: PropTypes.number,
};

SpremembeForm.defaultProps = {
  editData: null,
  initialFieldCount: 1,
};

export default reduxForm({
  form: 'spremembe_form', // a unique identifier for this form
  validate,
  /* initialValues: { date: new Date(Date.now()) }, */
})(SpremembeForm);
