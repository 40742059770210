import React from 'react';
import PropTypes from 'prop-types';
import ReactExport from 'react-data-export';
import { Button } from 'reactstrap';
import fix from 'helpers/floatParser';
import { translate } from 'react-i18next';
import translateProfession from 'translations/dynamicTranslator';

/* eslint-disable */
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
/* eslint-enable */

const DownloadButton = title => (
  <Button size="sm" color="primary" style={{ marginBottom: '10px', marginLeft: '15px' }}>
    {title}
  </Button>
);

const generateHeaders = t => {
  const firstPart = [
    {
      title: t('board.ow.worker'),
    },
    {
      title: t('form.profession'),
    },
    {
      title: t('workerstable.dayHours'),
    },
    {
      title: t('workerstable.nightHours'),
    },
    {
      title: `${t('workerstable.intHours')} (${t('workerstable.day')})`,
    },
    {
      title: `${t('workerstable.intHours')} (${t('workerstable.night')})`,
    },
    {
      title: t('workerstable.sum'),
    },
    {
      title: t('form.notes'),
    },
  ];

  const multiDataSet = [
    {
      columns: firstPart,
      data: [],
    },
  ];
  return multiDataSet;
};

const generateData = dataAll => {
  console.log('TCL: generateData -> dataAll', dataAll);
  const data = [];
  for (let i = 0; i < dataAll.workers.length; i += 1) {
    const worker = dataAll.workers[i];
    const workerRow = [
      {
        value: worker.name,
      },
      {
        value: translateProfession(worker.profession, localStorage.getItem('lang') || 'sl'),
      },
      {
        value: Number(fix(worker.sum.hoursSum)),
      },
      {
        value: Number(fix(worker.sum.nightHoursSum)),
      },
      {
        value: Number(fix(worker.sum.internalDaySum)),
      },
      {
        value: Number(fix(worker.sum.internalNightSum)),
      },
      {
        value: Number(fix(worker.sum.totalSum)),
      },
      {
        value: worker.sum.notes.length > 0 ? worker.sum.notes.join(', ') : ' ',
      },
    ];

    data.push(workerRow);
  }

  return data;
};

class WorkersSheet extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { data, btn, t } = this.props;

    const headers = generateHeaders(t);
    headers[0].data = generateData(data);
    return (
      <ExcelFile element={DownloadButton(btn)} filename={t('calcs.workers')}>
        <ExcelSheet dataSet={headers} name={t('calcs.workers')} />
      </ExcelFile>
    );
  }
}

WorkersSheet.propTypes = {
  btn: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  t: PropTypes.func.isRequired,
};

export default translate('common')(WorkersSheet);
