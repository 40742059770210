import React, { Fragment } from 'react';
import _ from 'lodash';
import moment from 'moment-timezone';
import { UncontrolledTooltip } from 'reactstrap';
import fix from '../../../../helpers/floatParser';
import { TIMEZONE } from '../../../../constants/config';
import { TMZFY } from '../../../../helpers/functions';
import constants from '../../helpers/index';

const { DEBOOK_CATEGORIES } = constants;

const isIgnored = (ignoreArr, month, year) => {
  if (!ignoreArr) {
    return false;
  }
  const ignoredThisMonth = ignoreArr.find(i => i.month === month && i.year === year);
  return !!ignoredThisMonth;
};

const createKontoId = (employee, type, vehicle) => {
  const load = {
    employee,
    type,
    vehicle,
  };
  return JSON.stringify(load);
};

const getVehicle = (vehicleTransactions, vehicleId) => {
  const veh = vehicleTransactions.find(t => t.vehicle._id === vehicleId);
  return veh.vehicle;
};

const createVehicleKonto = (transactions, vehicleId, employee) => {
  const vehicleTransactions = transactions.filter(
    t => t.vehicle._id === vehicleId || (t.kontoId && t.kontoId.vehicle && t.kontoId.vehicle._id === vehicleId),
  );
  const vehicleTransactionsIds = vehicleTransactions.map(t => t._id);
  const vehData = getVehicle(vehicleTransactions, vehicleId);
  return {
    name: vehData.label.toUpperCase(),
    transactions: vehicleTransactions,
    ids: vehicleTransactionsIds,
    type: 'vehicle',
    kontoId: createKontoId(employee, 'vehicle', vehData),
  };
};

const createOtherKonto = (transactions, recurringTransactions, vehicleTransactionsIds, employee) => {
  const otherTransactions = transactions.reduce((list, transaction) => {
    if (!vehicleTransactionsIds.includes(transaction._id) && transaction.saldo) {
      list.push(transaction);
    }
    return list;
  }, recurringTransactions);
  return {
    name: 'TRANSAKCIJE',
    transactions: otherTransactions,
    type: 'other',
    kontoId: createKontoId(employee, 'other', null),
  };
};
const prepCompensationData = _compensation => {
  const { _id, compensation, createdAt, updatedAt, notes, year, month, modifiedBy } = _compensation;
  const newDate = moment(createdAt)
    .set({ year: Number(year), month: Number(month) - 1 })
    .tz(TIMEZONE);
  return {
    _id,
    amount: compensation,
    dateRaw: newDate,
    date: TMZFY(newDate),
    category: {
      name: DEBOOK_CATEGORIES[0].label,
      catId: DEBOOK_CATEGORIES[0].value,
    },
    notes: notes || '/',
    type: { name: 'Izravnava', _id: 'trans_type_normal' },
    vehicle: {},
    modifiedBy: { ...JSON.parse(modifiedBy), updatedAt: TMZFY(updatedAt) },
    isCompensation: true,
  };
};
const mapCompensationsInKontos = (allKontos, compensations) => {
  // console.log('mapCompensationsInKontos -> compensations', compensations);
  // console.log('mapCompensationsInKontos -> allKontos', allKontos);

  return allKontos.map(konto => {
    const compensationsF = compensations.filter(c => c.kontoId === konto.kontoId);
    const { transactions } = konto;

    compensationsF.forEach(compensation => {
      transactions.push(prepCompensationData(compensation));
    });

    const sorted = transactions.sort((a, b) => new Date(b.dateRaw).getTime() - new Date(a.dateRaw).getTime());
    return {
      ...konto,
      transactions: sorted,
    };
  });
};

const createKontosFromTransactions = (transactions, recurringTransactions, compensations, employee) => {
  const vehicleGroupIds = _.uniq(
    transactions.reduce((list, t) => {
      if (t.kontoId && !t.kontoId.vehicle && t.vehicle._id) {
        return list;
      }
      if (t.kontoId && t.kontoId.vehicle && t.vehicle._id !== t.kontoId.vehicle._id) {
        return list;
      }

      if (t.vehicle._id) {
        list.push(t.vehicle._id);
      }
      return list;
    }, []),
  );
  let mergedVehicleTransactionsIds = [];
  const vehicleKontos = vehicleGroupIds.map(vehicleId => {
    const vehicleKonto = createVehicleKonto(transactions, vehicleId, employee);
    mergedVehicleTransactionsIds = mergedVehicleTransactionsIds.concat(vehicleKonto.ids);
    return vehicleKonto;
  });
  const otherKonto = createOtherKonto(transactions, recurringTransactions, mergedVehicleTransactionsIds, employee);

  const otherKontoArr = otherKonto.transactions.length > 0 ? [otherKonto] : [];
  const allKontos = otherKontoArr.concat(vehicleKontos);

  return mapCompensationsInKontos(allKontos, compensations);
};

const calculateSaldoFromTransactions = (transactions, month, year) => {
  const saldo = transactions.reduce(
    (saldoSum, transaction) => {
      if (isIgnored(transaction.ignore, month, year)) {
        return saldoSum;
      }
      const _saldoSum = saldoSum;
      if (transaction.isCompensation) {
        _saldoSum.confirmed += -transaction.amount;
      } else if (transaction.saldo && !transaction.unconfirmed) {
        _saldoSum.confirmed += transaction.amount;
      } else if (transaction.saldo && transaction.unconfirmed) {
        _saldoSum.unconfirmed += transaction.amount;
      }

      return _saldoSum;
    },
    { confirmed: 0, unconfirmed: 0 },
  );
  return {
    confirmed: fix(saldo.confirmed),
    unconfirmed: fix(saldo.unconfirmed),
    total: fix(saldo.confirmed + saldo.unconfirmed),
  };
};

const createProperDate = (dateRaw, month, year, format = true) => {
  const date = moment(dateRaw)
    .tz(TIMEZONE)
    .date();

  const newDate = moment()
    .set({ year: Number(year), month: Number(month) - 1, date })
    .tz(TIMEZONE);

  if (format) {
    return newDate.format('DD. MM. YY');
  }

  return newDate.toDate();
};

export default {
  kontify: (transactions, recurringTransactions, compensations, employee) => {
    return createKontosFromTransactions(transactions, recurringTransactions, compensations, employee);
  },
  calculateSaldo: (data, isKonto, month, year, id) => {
    let saldo;
    if (!isKonto) {
      saldo = calculateSaldoFromTransactions(data, month, year);
    } else {
      saldo = data.reduce(
        (totalSaldo, konto) => {
          const _totalSaldo = totalSaldo;
          const { confirmed, unconfirmed } = calculateSaldoFromTransactions(konto.transactions, month, year);
          _totalSaldo.confirmed += confirmed;
          _totalSaldo.unconfirmed += unconfirmed;
          return _totalSaldo;
        },
        { confirmed: 0, unconfirmed: 0 },
      );
    }

    const theTotal = fix(saldo.confirmed + saldo.unconfirmed);
    const confirmedSaldoString = Number(saldo.confirmed) > 0 ? `+${fix(saldo.confirmed)}` : fix(saldo.confirmed);
    const unconfirmedSaldoString =
      Number(saldo.unconfirmed) > 0 ? `+${fix(saldo.unconfirmed)}` : fix(saldo.unconfirmed);
    const totalSaldoString = Number(theTotal) > 0 ? `+${fix(theTotal)}` : fix(theTotal);
    const confirmedColor = saldo.confirmed >= 0 ? 'green' : 'red';
    const unconfirmedColor = saldo.unconfirmed >= 0 ? 'green' : 'red';
    const totalColor = theTotal >= 0 ? 'green' : 'red';
    return {
      confirmedNum: fix(saldo.confirmed),
      unconfirmedNum: fix(saldo.unconfirmed),
      totalNum: theTotal,
      confirmed: (
        <Fragment>
          <span id={`${id}-conf`} style={{ color: confirmedColor }}>
            {confirmedSaldoString}€
          </span>
          <UncontrolledTooltip placement="top" target={`${id}-conf`}>
            Seštevek potrjenih
          </UncontrolledTooltip>
        </Fragment>
      ),
      unconfirmed: (
        <Fragment>
          <span id={`${id}-unconf`} style={{ color: unconfirmedColor, backgroundColor: 'pink' }}>
            {unconfirmedSaldoString}€
          </span>
          <UncontrolledTooltip placement="top" target={`${id}-unconf`}>
            Seštevek nepotrjenih
          </UncontrolledTooltip>
        </Fragment>
      ),
      total: (
        <Fragment>
          <span id={`${id}-tot`} style={{ color: totalColor, backgroundColor: 'lightgray' }}>
            ( {totalSaldoString}€ )
          </span>
          <UncontrolledTooltip placement="top" target={`${id}-tot`}>
            Skupni seštevek
          </UncontrolledTooltip>
        </Fragment>
      ),
    };
  },
  createEditValuesFromRecurring: (recurringTemplate, month, year) => {
    const { date, employee, notes, payment, purpose } = recurringTemplate;
    return {
      amount: payment,
      date: createProperDate(date, month, year, false),
      employee,
      notes,
      purpose,
      transactionCategory: { value: 'debt', label: 'Obveznosti / Terjatve' },
      transactionType: { value: 'trans_type_normal', label: '-' },
      recurringId: recurringTemplate.transactionId,
    };
  },
  renderProperDate: (dateRaw, month, year, format = true) => {
    return createProperDate(dateRaw, month, year, format);
  },
  createEditValuesForLeveling: (category, kontoId, month, year) => {
    const date = moment()
      .set({ year: Number(year), month: Number(month) - 1 })
      .tz(TIMEZONE)
      .toDate();
    const { employee, vehicle, type } = JSON.parse(kontoId);
    return {
      date,
      employee: { label: employee.name, value: employee._id },
      transactionCategory: category,
      vehicle: vehicle ? { label: vehicle.label, value: vehicle._id } : null,
      debookType: type,
      isDebook: true,
      kontoId,
      isNew: true,
    };
  },
  allowHistoRowRender: (transactionDate, month, year, histoValue) => {
    if (histoValue.value === 0) {
      return true;
    }

    const monthYearDate = moment()
      .set({ year: Number(year), month: Number(month) })
      .tz(TIMEZONE)
      .startOf('month')
      .toDate();

    const histoDate = moment(monthYearDate)
      .subtract(histoValue.value, 'month')
      .toDate();

    return new Date(transactionDate).getTime() >= histoDate.getTime();
  },
  isTransactionIgnored: (ignoreArr, month, year) => {
    return isIgnored(ignoreArr, month, year);
  },
};
