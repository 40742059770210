const validate = values => {
  console.log('values', values);
  const errors = {};
  if (!values.amount) {
    errors.amount = 'To poje mora biti izpolnjeno!';
  }

  if (!values.date) {
    errors.date = 'To poje mora biti izpolnjeno!';
  }

  if (!values.purpose) {
    errors.purpose = 'To poje mora biti izpolnjeno!';
  }

  if (!values.basis) {
    errors.basis = 'To poje mora biti izpolnjeno!';
  }

  if (!values.payment) {
    errors.payment = 'To poje mora biti izpolnjeno!';
  }

  // pri potnih nalogih ni potrebno da je delavec izpolnjen
  if (values.transactionCategory && values.transactionCategory.value !== 'travel-order' && !values.employee) {
    errors.employee = 'To poje mora biti izpolnjeno!';
  }

  // pri potnih nalogih ni potrebno da je delavec izpolnjen
  if (values.transactionCategory && values.transactionCategory.value !== 'travel-order' && !values.travelOrderOption) {
    errors.travelOrderOption = 'To poje mora biti izpolnjeno!';
  }

  if (!values.transactionSource) {
    errors.transactionSource = 'To poje mora biti izpolnjeno!';
  }

  return errors;
};

export default validate;
