import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';
import PropTypes from 'prop-types';
import { translate } from 'react-i18next';
import { connect } from 'react-redux';
import ReactLoading from 'react-loading';
import BottomScrollListener from 'react-bottom-scroll-listener';
import { getAll, clearPlaces, clearSearch } from 'redux/actions/placesActions';
import { PlacesProps } from 'shared/prop-types/ReducerProps';
import Filters from './components/filters';
import Place from './components/Place';

class Iskanje extends Component {
  static propTypes = {
    placesStore: PlacesProps.isRequired,
    dispatch: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      page: 0,
    };
  }

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(clearPlaces());
    dispatch(clearSearch());
    this.loadMorePlaces();
  }

  loadMorePlaces = () => {
    const {
      dispatch,
      placesStore: { searchPlaces },
    } = this.props;
    const { page } = this.state;
    if (!searchPlaces) {
      dispatch(getAll(page + 1));
      this.incrementPage();
    }
  };

  handleBottom = () => {
    const {
      placesStore: { searchPlaces },
    } = this.props;
    if (searchPlaces) {
      this.loadMorePlaces();
    }
  };

  incrementPage = () => {
    this.setState(prevState => ({
      page: prevState.page + 1,
    }));
  };

  renderPlaces = () => {
    const {
      placesStore: { places, searchPlaces },
      t,
    } = this.props;
    const placesToRender = searchPlaces || places;
    if (placesToRender && placesToRender.length === 0) {
      return (
        <div className="w-100 text-center">
          <h3>{t('places.notFoundError')}</h3>
        </div>
      );
    }
    return placesToRender.map(place => <Place key={place.id} place={place} />);
  };

  render() {
    const {
      placesStore: { loading, loaded },
      t,
    } = this.props;
    return (
      <BottomScrollListener onBottom={this.loadMorePlaces}>
        <Container>
          <Row>
            <Col xs={12}>
              <h3 className="page-title">{t('places.title')}</h3>
            </Col>
          </Row>
          <Filters />
          <Row>{this.renderPlaces()}</Row>
        </Container>
        {loading && !loaded && (
          <div className="pt-5 pb-5">
            <ReactLoading type="spin" color="cyan" height={128} width={128} className="react-loader" />
          </div>
        )}
      </BottomScrollListener>
    );
  }
}
const reduxed = connect(state => ({
  placesStore: state.places,
}))(Iskanje);
export default translate('common')(reduxed);
