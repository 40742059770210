import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody, Col, Table, Badge } from 'reactstrap';
import { translate } from 'react-i18next';

const renderTable = (sleeplogs, onRowSelect) =>
  sleeplogs.map(log => (
    <tr key={log._id} id={log._id}>
      <td>{log.dateString}</td>
      <td>{log.toDateString}</td>
      <td>
        <Badge style={{ backgroundColor: `${log.employee.color}`, color: '#646777' }}>{log.employee.name}</Badge>
      </td>
    </tr>
  ));

const PrenocitveIzpisiTable = ({ title, sleeplogs, onRowSelect, t }) => (
  <Col md={12} lg={12} xl={12}>
    <Card>
      <CardBody>
        <div className="card__title">
          <h5 className="bold-text">{title}</h5>
        </div>
        <Table className="table--bordered" responsive>
          <thead>
            <tr>
              <th>{t('misc.arrival')}</th>
              <th>{t('misc.departure')}</th>
              <th>{t('board.ow.worker')}</th>
            </tr>
          </thead>
          <tbody>{renderTable(sleeplogs)}</tbody>
        </Table>
      </CardBody>
    </Card>
  </Col>
);

PrenocitveIzpisiTable.propTypes = {
  title: PropTypes.string.isRequired,
  sleeplogs: PropTypes.arrayOf(PropTypes.object).isRequired,
  onRowSelect: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

PrenocitveIzpisiTable.defaultProps = {};

export default translate('common')(PrenocitveIzpisiTable);
