import { SET_COMPANIES, SET_COMPANIES_TITLES_LIST } from '../actions/companiesActions';

const initialState = {
  companies: [],
  companiesTitles: [],
};

export default function(state = initialState, action) {
  switch (action.type) {
    case SET_COMPANIES:
      return { ...state, companies: action.companies };
    case SET_COMPANIES_TITLES_LIST: {
      const companiesTitles = action.companiesTitles.map(com => ({
        value: com._id,
        label: com.name,
        color: com.color,
      }));
      return { ...state, companiesTitles };
    }
    default:
      return state;
  }
}
