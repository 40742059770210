import {
  SET_OWS,
  GET_OWS_ERROR,
  SET_FILTERED,
  CLEAR_FILTERED,
  SET_OWS_LOADING,
  SET_NOTE_ON_OW,
} from '../actions/owActions';

const initialState = {
  loaded: false,
  ows: [],
  filtered: null,
  error: null,
};

function updateNoteOws(id, note, ows) {
  return ows.map(ow => {
    if (ow._id === id) {
      ow.live.note = note;
      ow.staged.note = note;
    }
    return ow;
  });
}

export default function(state = initialState, action) {
  switch (action.type) {
    case SET_OWS:
      return {
        ...state,
        ows: action.payload,
        filtered: null,
        loaded: true,
      };
    case GET_OWS_ERROR:
      return { ...state, ows: [], error: action.payload };
    case SET_FILTERED:
      return { ...state, filtered: action.payload };
    case CLEAR_FILTERED:
      return { ...state, filtered: null };
    case SET_OWS_LOADING:
      return { ...state, loaded: false };
    case SET_NOTE_ON_OW:
      return {
        ...state,
        ows: updateNoteOws(action.payload.id, action.payload.note, state.ows),
        loaded: false,
      };
    default:
      return state;
  }
}
