import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import { EmployeesProps, CompaniesProps, VehiclesProps, DataListProps } from 'shared/prop-types/ReducerProps';
import { Field, reduxForm } from 'redux-form';
import { ArrowDownBoldBoxIcon } from 'mdi-react';
import Alert from 'shared/components/Alert';
import renderSelectField from '../../../../../../shared/components/form/Select';
import renderTextField from '../../../../../../shared/components/form/TextField';
import constants from '../../../../helpers/index';
import renderRadioButtonField from '../../../../../../shared/components/form/RadioButton';

const {
  EXPENSE,
  BALANCE_UPDATE,
  SPECIAL,
  WITHDRAW,
  MISC,
  TRAVEL_ORDER,
  TRAVEL_ORDER_OPTIONS,
  TRANSACTION_SPECIAL_CATEGORIES,
  TRANSFER,
  DEBT,
  RECURRING,
  DEBOOK_CASH,
  DEBOOK_TRANSFER,
  DEBOOK_LEVELING,
  DEBOOK_TRR,
} = constants;

const PaymentField = t => (
  <div key="PaymentField" className="form__form-group">
    <span className="form__form-group-label">{t('cashbox.payment')} €</span>
    <div className="form__form-group-field bigger-text">
      <Field name="payment" component={renderTextField} type="number" />
    </div>
  </div>
);

const BasisField = t => (
  <div key="BasisField" className="form__form-group">
    <span className="form__form-group-label">{t('cashbox.basis')} €</span>
    <div className="form__form-group-field bigger-text">
      <Field name="basis" component={renderTextField} type="number" />
    </div>
  </div>
);

const TravelOrderOptions = t => (
  <div key="TravelOrderOptions" className="form__form-group">
    <span className="form__form-group-label">{t('cashbox.to_option')}</span>
    <div className="form__form-group-field bigger-text">
      <Field name="travelOrderOption" component={renderSelectField} options={TRAVEL_ORDER_OPTIONS} />
    </div>
  </div>
);

const VehicleField = (vehicles, t) => (
  <div key="VehicleField" className="form__form-group">
    <span className="form__form-group-label">{t('board.ow.vehicle')}</span>
    <div className="form__form-group-field bigger-text">
      <Field name="vehicle" component={renderSelectField} options={vehicles} />
    </div>
  </div>
);

const PurposeField = t => (
  <div key="PurposeField" className="form__form-group">
    <span className="form__form-group-label">{t('cashbox.purpose')}</span>
    <div className="form__form-group-field bigger-text">
      <Field name="purpose" component={renderTextField} type="text" />
    </div>
  </div>
);

const CompanyField = (companies, t) => (
  <div key="CompanyField" className="form__form-group">
    <span className="form__form-group-label">{t('form.company')}</span>
    <div className="form__form-group-field bigger-text">
      <Field name="company" component={renderSelectField} options={companies} />
    </div>
  </div>
);

const SpecialCategoryField = t => (
  <div key="SpecialCategoryField" className="form__form-group">
    <span className="form__form-group-label">{t('cashbox.sc_participant')}</span>
    <div className="form__form-group-field bigger-text">
      <Field name="specialCategory" component={renderSelectField} options={TRANSACTION_SPECIAL_CATEGORIES} />
    </div>
  </div>
);

const WhoField = t => (
  <div key="WhoField" className="form__form-group">
    <span className="form__form-group-label">
      {t('cashbox.who')} ({t('cashbox.initial')})
    </span>
    <div className="form__form-group-field bigger-text">
      <Field name="who" component={renderTextField} type="text" />
    </div>
  </div>
);

const EmployeeField = (employees, konto, kontoEmployee, t) => (
  <div key="EmployeeField" className="form__form-group">
    <span className="form__form-group-label">{t('board.ow.worker')}</span>
    <div className="form__form-group-field bigger-text">
      <Field name="employee" component={renderSelectField} options={konto ? [kontoEmployee] : employees} />
    </div>
  </div>
);

const NotesField = t => (
  <div key="NotesField" className="form__form-group">
    <span className="form__form-group-label">{t('form.notes')}</span>
    <div className="form__form-group-field">
      <Field name="notes" component="textarea" type="text" />
    </div>
  </div>
);

const AmountField = t => (
  <div key="AmountField" className="form__form-group">
    <span className="form__form-group-label">{t('cashbox.amount')} €</span>
    <div className="form__form-group-field bigger-text">
      <Field name="amount" component={renderTextField} type="number" />
    </div>
  </div>
);

const TransactionSourceField = (transactionSources, id, t) => {
  let name = 'transactionSource';
  if (id) name += id;
  return (
    <div key={`TransactionSourceField${id}`} className="form__form-group">
      <span className="form__form-group-label">{t('cashbox.source')}</span>
      <div className="form__form-group-field bigger-text">
        <Field name={name} component={renderSelectField} options={transactionSources} />
      </div>
    </div>
  );
};

const ArrowRender = (text, t) => (
  <div key="ArrowDownBoldBoxIcon" style={{ width: '100%' }}>
    <ArrowDownBoldBoxIcon size={50} style={{ margin: '0 auto' }} />
    <span style={{ margin: '0 auto' }}>
      <strong>{text}</strong>
    </span>
  </div>
);

const InfoAlert = (text, bold, t) => (
  <Alert key="InfoAlert" color="info" className="alert--neutral" icon handleDismiss={() => null}>
    <p>
      <span className="bold-text">Info:</span>
      <br />
      {text}
    </p>
    {bold && (
      <p>
        <span className="bold-text">
          <i>{bold}</i>
        </span>
      </p>
    )}
  </Alert>
);

const getProperFields = (category, type, props) => {
  const { konto, kontoEmployee, t } = props;
  const fields = [];
  switch (category) {
    //spremeni stanje vira
    case BALANCE_UPDATE:
      return [
        AmountField(t),
        ArrowRender('POSODOBI ZA', t),
        TransactionSourceField(props.dataListState.transactionSources, null, t),
        NotesField(t),
        InfoAlert('Spreminjanje stanja izbranega vira.', '(TO NI PRENOS MED DVEMA VIROMA!)', t),
      ];
    case EXPENSE: {
      const INFO_TEXT = 'Izdaja, akontacije in poračuni za materijalne stroške.';
      if (!type) return [InfoAlert(INFO_TEXT, t)];
      if (type === 'trans_type_out') {
        fields.push(TransactionSourceField(props.dataListState.transactionSources, null, t));
        fields.push(AmountField(t));
        fields.push(ArrowRender('AKONTACIJA', t));
        fields.push(EmployeeField(props.workersState.employeesNames, konto, kontoEmployee, t));
      } else if (type === 'trans_type_in') {
        fields.push(EmployeeField(props.workersState.employeesNames, konto, kontoEmployee, t));
        fields.push(AmountField(t));
        fields.push(ArrowRender('PORAČUN', t));
        fields.push(TransactionSourceField(props.dataListState.transactionSources, null, t));
      } else if (type === 'trans_type_normal') {
        fields.push(TransactionSourceField(props.dataListState.transactionSources, null, t));
        fields.push(AmountField(t));
        fields.push(ArrowRender('IZDAJ', t));
        fields.push(EmployeeField(props.workersState.employeesNames, konto, kontoEmployee, t));
      }
      fields.push(WhoField(t));
      fields.push(NotesField(t));
      fields.push(InfoAlert(INFO_TEXT, t));
      return fields;
    }
    case SPECIAL: {
      const INFO_TEXT = 'Posebne transakcije za BG, MU, BA.';
      return [
        AmountField(t),
        TransactionSourceField(props.dataListState.transactionSources, null, t),
        EmployeeField(props.workersState.employeesNames, konto, kontoEmployee, t),
        ArrowRender('ZA', t),
        SpecialCategoryField(t),
        WhoField(t),
        NotesField(t),
        InfoAlert(INFO_TEXT, t),
      ];
    }
    case WITHDRAW: {
      const INFO_TEXT = 'Dvig gotovine iz podjetja.';
      return [
        AmountField(t),
        CompanyField(props.companiesState.companiesTitles, t),
        ArrowRender('DVIG ZA', t),
        TransactionSourceField(props.dataListState.transactionSources, null, t),
        WhoField(t),
        NotesField(t),
        InfoAlert(INFO_TEXT, t),
      ];
    }
    case MISC: {
      const INFO_TEXT = 'Razne ostale transakcije s poljubnim namenom.';
      if (!type) return [InfoAlert(INFO_TEXT, t)];
      return [
        PurposeField(t),
        TransactionSourceField(props.dataListState.transactionSources, null, t),
        AmountField(t),
        ArrowRender('ZA', t),
        EmployeeField(props.workersState.employeesNames, konto, kontoEmployee, t),
        WhoField(t),
        NotesField(t),
        InfoAlert(INFO_TEXT, t),
      ];
    }
    case TRAVEL_ORDER: {
      const INFO_TEXT = 'Akontacije in poračuni za potne naloge.';
      if (!type) return [InfoAlert(INFO_TEXT, t)];
      if (type === 'trans_type_out') {
        return [
          TransactionSourceField(props.dataListState.transactionSources, null, t),
          AmountField(t),
          EmployeeField(props.workersState.employeesNames, konto, kontoEmployee, t),
          ArrowRender('AKONTACIJA ZA', t),
          VehicleField(props.vehiclesState.licenses, t),
          TravelOrderOptions(t),
          WhoField(t),
          NotesField(t),
          InfoAlert(INFO_TEXT, t),
        ];
      }
      if (type === 'trans_type_in') {
        return [
          AmountField(t),
          EmployeeField(props.workersState.employeesNames, konto, kontoEmployee, t),
          ArrowRender('PORAČUN ZA', t),
          VehicleField(props.vehiclesState.licenses, t),
          TransactionSourceField(props.dataListState.transactionSources, null, t),
          TravelOrderOptions(t),
          WhoField(t),
          NotesField(t),
          InfoAlert(INFO_TEXT, t),
        ];
      }
      if (type === 'trans_type_in_receipt') {
        return [
          AmountField(t),
          EmployeeField(props.workersState.employeesNames, konto, kontoEmployee, t),
          ArrowRender('PORAČUN ZA', t),
          VehicleField(props.vehiclesState.licenses, t),
          TravelOrderOptions(t),
          WhoField(t),
          NotesField(t),
          InfoAlert(INFO_TEXT, t),
        ];
      }
      return [
        AmountField(t),
        EmployeeField(props.workersState.employeesNames, konto, kontoEmployee, t),
        ArrowRender('PORAČUN ZA', t),
        VehicleField(props.vehiclesState.licenses, t),
        TransactionSourceField(props.dataListState.transactionSources, null, t),
        TravelOrderOptions(t),
        WhoField(t),
        NotesField(t),
        InfoAlert(INFO_TEXT, t),
      ];
    }
    case TRANSFER: {
      const INFO_TEXT = 'Prenos med dvema viroma.';
      return [
        AmountField(t),
        TransactionSourceField(props.dataListState.transactionSources, 1, t),
        ArrowRender('PRENOS V', t),
        TransactionSourceField(props.dataListState.transactionSources, 2, t),
        WhoField(t),
        NotesField(t),
        InfoAlert(INFO_TEXT, t),
      ];
    }
    case DEBT: {
      const INFO_TEXT = 'Spreminjanje dolgov delavca.';
      return [
        AmountField(t),
        ArrowRender('POSODOBI ZA', t),
        EmployeeField(props.workersState.employeesNames, konto, kontoEmployee, t),
        PurposeField(t),
        NotesField(t),
        InfoAlert(INFO_TEXT, '(SE NE NANAŠA NA NOBEN VIR!)', t),
      ];
    }
    case RECURRING: {
      const INFO_TEXT = 'Transakcije ki se izvajajo mesečno za nedoločen čas ali do poravnave dolga.';
      if (!type) return [InfoAlert(INFO_TEXT, t)];
      if (type === 'finite') {
        return [
          EmployeeField(props.workersState.employeesNames, konto, kontoEmployee, t),
          BasisField(t),
          PaymentField(t),
          PurposeField(t),
          NotesField(t),
          InfoAlert(INFO_TEXT, t),
        ];
      }
      return [
        EmployeeField(props.workersState.employeesNames, konto, kontoEmployee, t),
        PaymentField(t),
        PurposeField(t),
        NotesField(t),
        InfoAlert(INFO_TEXT, t),
      ];
    }
    case DEBOOK_CASH: {
      const INFO_TEXT = 'Razknjižba z gotovino';
      return [
        AmountField(t),
        TransactionSourceField(props.dataListState.transactionSources, null, t),
        NotesField(t),
        InfoAlert(INFO_TEXT, t),
      ];
    }
    case DEBOOK_TRR: {
      const INFO_TEXT = 'Razknjižba s TRR';
      return [
        AmountField(t),
        TransactionSourceField(props.dataListState.transactionSources, null, t),
        NotesField(t),
        InfoAlert(INFO_TEXT, t),
      ];
    }
    case DEBOOK_LEVELING: {
      const INFO_TEXT = 'Izravnava pri plači';
      return [AmountField(t), NotesField(t), InfoAlert(INFO_TEXT, t)];
    }
    case DEBOOK_TRANSFER: {
      const INFO_TEXT = 'Prenos v novi potni nalog';
      return [
        TransactionSourceField(props.dataListState.transactionSources, null, t),
        AmountField(t),
        EmployeeField(props.workersState.employeesNames, konto, kontoEmployee, t),
        ArrowRender('AKONTACIJA ZA', t),
        VehicleField(props.vehiclesState.licenses, t),
        NotesField(t),
        InfoAlert(INFO_TEXT, t),
      ];
    }
    default:
      return null;
  }
};

const TransactionFields = props => {
  const { selectedCategory, selectedType, initialValues } = props;
  //const { t, selectedCategory, workersState, companiesState, vehiclesState, dataListState }= props;
  return getProperFields(selectedCategory || initialValues.transactionCategory.value, selectedType, props);
};

TransactionFields.propTypes = {
  t: PropTypes.func.isRequired,
  selectedCategory: PropTypes.string,
  selectedType: PropTypes.string,
  workersState: EmployeesProps.isRequired,
  companiesState: CompaniesProps.isRequired,
  vehiclesState: VehiclesProps.isRequired,
  dataListState: DataListProps.isRequired,
  konto: PropTypes.bool,
  kontoEmployee: PropTypes.object,
};

TransactionFields.defaultProps = {
  konto: false,
  kontoEmployee: null,
  selectedCategory: null,
  selectedType: null,
};

const cn = connect(state => ({
  workersState: state.employees,
  companiesState: state.companies,
  vehiclesState: state.vehicles,
  dataListState: state.dataList,
}))(TransactionFields);

const wr = reduxForm({
  form: 'transaction_form_cashbox', // a unique identifier for this form
  //validate,
  // initialValues: {
  //   travelOrderOption: { label: '-', value: 'option5' },
  // },
})(cn);

export default translate('common')(wr);
