import professions_de from './de/professions.json';
import docs_de from './de/docs.json';
import types_de from './de/vehicleTypes.json';
import transactions_de from './de/transactions.json';

export default function translateProfession(profession, lang) {
  try {
    if (lang === 'sl') return profession;
    const trans = professions_de[profession];
    if (!trans) return profession;
    return trans;
  } catch (error) {
    return profession;
  }
}

export function translateVehType(type, lang) {
  try {
    if (lang === 'sl') return type;
    const trans = types_de[type];
    if (!trans) return type;
    return trans;
  } catch (error) {
    return type;
  }
}

export function translateDocName(name, lang) {
  try {
    if (lang === 'sl') return name;
    const trans = docs_de[name];
    if (!trans) return name;
    return trans;
  } catch (error) {
    return name;
  }
}

function translateTransactionParts(name, lang) {
  try {
    if (lang === 'sl') return name;
    const trans = transactions_de[name];
    if (!trans) return name;
    return trans;
  } catch (error) {
    return name;
  }
}

export function translateTrasactionLabelsArray(arr) {
  return arr.map(elt => {
    return {
      ...elt,
      label: translateTransactionParts(elt.label, localStorage.getItem('lang') || 'sl'),
    };
  });
}
