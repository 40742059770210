import React from 'react';
import NotificationSystem from 'rc-notification';
import { BasicNotification } from 'shared/components/Notification';

class RUNotification {
  notificationRU = null;

  constructor() {
    NotificationSystem.newInstance({}, n => (this.notificationRU = n));
  }

  showNotification = ({ notification, position }) => {
    this.notificationRU.notice({
      content: notification,
      duration: 5,
      closable: true,
      style: { top: 0, left: 'calc(100vw - 100%)' },
      className: position,
    });
  };

  destroy() {
    this.notificationRU.destroy();
  }

  showNotif = ({ title, message, success }) => {
    const color = success ? 'success' : 'danger';
    this.showNotification({
      notification: <BasicNotification color={color} title={title} message={message} />,
      position: 'right-up',
    });
  };
}

export default RUNotification;
