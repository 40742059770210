import { API_LINK } from 'constants/config';
import { GET, POST } from 'helpers/agent';

export default {
  getall: async (data = null) => {
    const response = await GET(`${API_LINK}/v/getall`);
    if (response.success) {
      const licenseList = [];
      response.vehicles.forEach(vehi => {
        const vehData = {
          value: vehi._id,
          label: `${vehi.licensePlate} ${vehi.brand}`,
        };
        licenseList.push(vehData);
      });
      return { vehicles: response.vehicles, licenses: licenseList };
    }
    return { vehicles: [], licenses: [] };
  },
  getCurrentDriver: async data => {
    const response = await POST(`${API_LINK}/v/getCurrentDriver`, JSON.stringify(data));
    if (response.success) {
      return { driver: response.currDriver };
    }
    return { driver: null };
  },
  checkVehicleLogsClash: async data => {
    const response = await POST(`${API_LINK}/v/checkVehicleLogsClash`, JSON.stringify(data));
    if (response.success) {
      return { clashMsg: response.clashMsg };
    }
    return { clashMsg: null };
  },
  getridelogs: async data => {
    const response = await POST(`${API_LINK}/v/getridelogs`, JSON.stringify(data));
    if (response.success) {
      return {
        ridelogsList: response.ridelogsList,
        worksite: response.worksite,
      };
    }
    return { ridelogsList: [] };
  },
  getOneById: async id => {
    const response = await POST(`${API_LINK}/v/getOneById`, JSON.stringify({ id }));
    if (response.success) {
      return response.vehicle;
    }
    return null;
  },
};
