import React, { Component, Fragment } from 'react';
import { Container, Row, Col, Card, CardBody, Button, ButtonToolbar, Progress } from 'reactstrap';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';
import SuperModal from '../../../shared/components/SuperModal';
import TransactionForm from '../Cashbox/components/Forms/TransactionForm';
import getRoles from '../../../constants/roles';
import cashboxApi from '../../../api/cashbox';
import constants from '../helpers/index';
import RecurringTable from './components/Tables/RecurringTable';

const { RECURRING } = constants;

class RecurringIndex extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      transactionsFull: [],
      transactions: [],
      progress: 0,
      showModal: false,
      transactionEditValues: undefined,
      ROLES: getRoles(),
    };
  }

  componentDidMount() {
    this.fetchTemplates();
  }

  fetchTemplates = async () => {
    this.setState({ progress: 15 });
    const { transactions } = await cashboxApi.getRecurringTemplates();

    this.setState({ transactions, transactionsFull: transactions });
    this.dismissProgress();
  };

  dismissProgress = () => {
    this.setState({ progress: 100 });
    setTimeout(() => {
      this.setState({ progress: 0 });
    }, 1000);
  };

  renderProgress = () => {
    const { progress } = this.state;
    return progress === 0 ? (
      <div style={{ width: '100%', height: '5px', backgroundColor: '#ECECEC', marginBottom: '30px' }}></div>
    ) : (
      <div className="progress-wrap progress-wrap--blue">
        <Progress value={progress} />
      </div>
    );
  };

  toggleModal = (show, resetInitial = false) => {
    if (!resetInitial) {
      this.setState({ showModal: show });
    } else {
      this.setState({ showModal: show, transactionEditValues: undefined });
    }
  };

  handleSubmit = async values => {
    if (values.transactionId) {
      await cashboxApi.updateTransaction({ ...values, transactionCategory: { value: RECURRING } });
    } else {
      await cashboxApi.addTransaction({ ...values, transactionCategory: { value: RECURRING } });
    }
    this.toggleModal(false);
    // const { month, year } = this.state;
    this.fetchTemplates();
  };

  handleRowClick = async value => {
    const transactionEditValues = await cashboxApi.getRecurringTemplate(value);
    //console.log('RecurringIndex -> transactionEditValues', transactionEditValues);
    this.setState({ transactionEditValues });
    this.toggleModal(true);
  };

  handleDelete = async value => {
    await cashboxApi.deleteRecurringTemplate(value);
    this.toggleModal(false);
    // const { month, year } = this.state;
    this.fetchTemplates();
  };

  handleFreeze = async value => {
    await cashboxApi.freezeRecurringTemplate(value);
    this.toggleModal(false);
    // const { month, year } = this.state;
    this.fetchTemplates();
  };

  render() {
    const { t } = this.props;
    const { showModal, transactions, transactionEditValues, ROLES } = this.state;
    return (
      <Container className="dashboard">
        <Row>
          <Col md={12}>
            <h3 className="page-title">{t('sidebar_content.finance.recurring')}</h3>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <div style={{ position: 'relative' }}>
              <div className="super-modal-abs">
                <Button color="primary" onClick={() => this.toggleModal(true, true)}>
                  {t('cashbox.newrecurring')}
                </Button>
              </div>
              <SuperModal
                title={t('cashbox.newrecurring').toUpperCase()}
                show={showModal}
                onClose={() => this.toggleModal(false)}
              >
                <TransactionForm
                  onSubmit={this.handleSubmit}
                  onCancel={() => this.toggleModal(false)}
                  onDelete={this.handleDelete}
                  onConfirm={() => null}
                  onFreeze={this.handleFreeze}
                  initialValues={transactionEditValues}
                  recurring
                />
              </SuperModal>
            </div>
          </Col>
        </Row>
        <Row className="mt-5">
          <Col md={12}>
            <Card>
              <CardBody>
                {this.renderProgress()}
                <RecurringTable transactions={transactions} onRowClick={this.handleRowClick} />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default translate('common')(RecurringIndex);
