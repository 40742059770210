import React, { Component } from 'react';
import shortid from 'shortid';
import { Col, Container, Row, Card, CardBody } from 'reactstrap';
import SelectDropdown from 'shared/components/SelectDropdown';
import Picker from 'react-month-picker';
import PropTypes from 'prop-types';
import ReactLoading from 'react-loading';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import workhoursApi from 'api/workhours';
import moment from 'moment-timezone';
import employees from 'api/employees';
// import ReactGA from 'react-ga';

import NotificationSystem from 'rc-notification';

import { setWokrdays, setWorkers } from 'redux/actions/hoursActions';

import { HoursProps, AppStatusProps, OrderersProps, WorksitesProps } from 'shared/prop-types/ReducerProps';

import { getItem, setItem } from 'helpers/cookies';
import Table from './components/Table';
import { TIMEZONE, PICKER_DATE_WORKERS } from '../../../constants/config';
import { setRouterBlockNotif, setRouterBlock } from '../../../redux/actions/appActions';
import { FullWideNotification } from '../../../shared/components/Notification';
import Legend from '../../Boards/components/Legend';

const pickerLang = {
  months: ['Jan', 'Feb', 'Mar', 'Apr', 'Maj', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dec'],
  from: 'Od',
  to: 'Do',
};

let notificationTC = null;

const showNotification = notification => {
  notificationTC.notice({
    content: notification,
    duration: 5,
    closable: true,
    style: { top: 0, left: 0 },
    className: 'full',
  });
};

class UreDelavciPage extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    hours: HoursProps.isRequired,
    appStatus: AppStatusProps.isRequired,
    orderersState: OrderersProps.isRequired,
    worksitesState: WorksitesProps.isRequired,
    t: PropTypes.func.isRequired,
  };

  constructor() {
    super();
    const storedDate = getItem(PICKER_DATE_WORKERS);
    let month =
      moment()
        .tz(TIMEZONE)
        .month() + 1;
    let year = moment()
      .tz(TIMEZONE)
      .year();
    if (storedDate) {
      month = Number(storedDate.split('/')[0]);
      year = Number(storedDate.split('/')[1]);
    }
    this.state = {
      employeeTitles: [],
      titlesWorksites: [],
      titlesOrderers: [],
      selectedEmployee: null,
      monthPickerValue: {
        year,
        month,
      },
      workerLoaded: false,
      loading: false,
    };

    this.monthPickerRef = null;
    this.handleEmployeeSubmit = this.handleEmployeeSubmit.bind(this);
    this.onMonthPickerClick = this.onMonthPickerClick.bind(this);
    this.handleTableChange = this.handleTableChange.bind(this);
    this.handleMonthChange = this.handleMonthChange.bind(this);
    this.handleAMonthDissmis = this.handleAMonthDissmis.bind(this);
  }

  componentDidMount() {
    this._mounted = true;
    NotificationSystem.newInstance({}, n => (notificationTC = n));

    const { appStatus } = this.props;
    if (appStatus.requiredDataLoaded) {
      console.log('DELOVNE URE - PO DELAVCU -> Data loaded.. continue...');
      this.fetchData();
    }
  }

  componentDidUpdate(prevProps) {
    const { appStatus } = this.props;
    if (appStatus.requiredDataLoaded && !prevProps.appStatus.requiredDataLoaded) {
      console.log('DELOVNE URE - PO DELAVCU -> Data finished loading.. continue...');
      this.fetchData();
    }

    if (!appStatus.appUpdating && prevProps.appStatus.appUpdating) {
      console.log('DELOVNE URE - PO DELAVCU -> Data finished UPDATING.. refresh table...');
      this.fetchData();
    }
  }

  componentWillUnmount() {
    this._mounted = false;
    notificationTC.destroy();
  }

  onMonthPickerClick() {
    this.monthPickerRef.show();
  }

  showNotif = ({ message, success }) => {
    const color = success ? 'success' : 'danger';
    showNotification(<FullWideNotification color={color} message={message} />);
  };

  _mounted = false;

  async fetchData() {
    const { worksitesState, orderersState } = this.props;

    this.setState({
      loading: false,
      titlesWorksites: worksitesState.worksitesWithAddress,
      titlesOrderers: orderersState.titlesOrderers,
    });
    this.setState(await employees.getNamesForWorksites(this.state.monthPickerValue));
  }

  async handleMonthChange(year, month) {
    await this.blockCheck();

    setItem(PICKER_DATE_WORKERS, `${month}/${year}`);
    this.setState({
      monthPickerValue: { year, month },
      workerLoaded: false,
    });
    this.setState({ loading: true });
    this.setState(await employees.getNamesForWorksites({ year, month }));
    this.setState({ loading: false });
    setTimeout(() => {
      const { selectedEmployee, monthPickerValue } = this.state;
      if (selectedEmployee) {
        this.submitSearch(selectedEmployee, monthPickerValue);
      }
    }, 500);
  }

  handleAMonthDissmis(year, month) {
    if (year && month) {
      setItem(PICKER_DATE_WORKERS, `${month}/${year}`);
      this.setState({
        monthPickerValue: { year, month },
      });
    }
  }

  async blockCheck() {
    let saveFirst;

    if (this.props.appStatus.blockRouter) {
      saveFirst = window.confirm('Ali želite shraniti ure, predno zamenjate delavca?');
    }

    if (saveFirst) {
      await workhoursApi.postHours();
    } else {
      workhoursApi.clearSchedule();
    }

    this.props.dispatch(setRouterBlock(false));
    this.props.dispatch(setRouterBlockNotif(null));
  }

  async handleEmployeeSubmit(event) {
    await this.blockCheck();

    const selectedEmployee = event.selected_dropdown;
    if (selectedEmployee) {
      this.setState({
        selectedEmployee,
        workerLoaded: false,
      });
      const { monthPickerValue } = this.state;
      this.submitSearch(selectedEmployee, monthPickerValue);
    }
  }

  async handleTableChange() {
    console.log('TCL: UreDelavciPage -> handleTableChange -> handleTableChange');
    const { monthPickerValue, selectedEmployee } = this.state;
    await this.submitSearch(selectedEmployee, monthPickerValue);
  }

  async submitSearch(employee, date) {
    const data = {
      workerId: employee.value,
      ...date,
    };
    try {
      const response = await workhoursApi.getbyworker(data);
      const { workdays } = response;
      this.props.dispatch(setWokrdays(workdays));
      this.props.dispatch(
        setWorkers({
          workers: null,
          workersLoaded: false,
        }),
      );
      if (this._mounted) {
        this.setState({ workerLoaded: true });
      }
    } catch (err) {
      console.error('there was an error fetching the workhours', err);
    }
  }

  makeText(m) {
    if (m && m.year && m.month) return `${pickerLang.months[m.month - 1]}. ${m.year}`;
    return '?';
  }

  prepareDataForTable(data) {
    return data.map(object => ({
      id: object.value,
      value: object.label,
    }));
  }

  render() {
    const {
      selectedEmployee,
      employeeTitles,
      monthPickerValue,
      titlesWorksites,
      titlesOrderers,
      workerLoaded,
      loading,
    } = this.state;
    const { hours, t } = this.props;
    const Loader = [
      <ReactLoading
        key={shortid.generate()}
        type="bubbles"
        color="gray"
        height={64}
        width={64}
        className="react-loader"
      />,
      <h4 key={shortid.generate()} style={{ marginLeft: '180px' }}>
        Pridobivam delavce
      </h4>,
    ];
    return (
      <Container className="dashboard">
        <Row>
          <Col md="4">
            <h3 className="page-title">Delovne ure po delavcih</h3>
          </Col>
          <Col md="4">
            {employeeTitles.length > 0 && !loading ? (
              <Card>
                <CardBody>
                  <SelectDropdown title="Išči Delavca" items={employeeTitles} onSubmit={this.handleEmployeeSubmit} />
                </CardBody>
              </Card>
            ) : (
              Loader
            )}
          </Col>
          <Col md="4">
            <Card>
              <CardBody>
                <div className="date-picker">
                  <div className="form__form-group-label">Izberi mesec:</div>
                  <Picker
                    ref={ref => (this.monthPickerRef = ref)}
                    years={[2008, 2009, 2010, 2011, 2012, 2014, 2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030]}
                    value={monthPickerValue}
                    lang={pickerLang.months}
                    onChange={this.handleMonthChange}
                    onDismiss={this.handleAMonthDissmis}
                  >
                    <div
                      role="button"
                      tabIndex={0}
                      onKeyDown={this.onMonthPickerClick}
                      onClick={this.onMonthPickerClick}
                    >
                      {this.makeText(monthPickerValue)}
                    </div>
                  </Picker>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col lg={6}>
            <Legend title={t('board.legend')} lettercodes />
          </Col>
        </Row>
        <Row>
          <Col md="12">
            {workerLoaded && selectedEmployee && hours.workdaysLoaded && hours.workdays && (
              <Table
                employee={selectedEmployee}
                date={monthPickerValue}
                worksites={this.prepareDataForTable(titlesWorksites)}
                orderers={this.prepareDataForTable(titlesOrderers)}
                onChange={this.handleTableChange}
                onHoursPosted={this.showNotif}
              />
            )}
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  hours: state.hours,
  appStatus: state.appStatus,
  orderersState: state.orderers,
  worksitesState: state.worksites,
});

const wr = connect(mapStateToProps)(UreDelavciPage);

export default translate('common')(wr);
