import React from 'react';
import PropTypes from 'prop-types';
import { Button, Modal } from 'reactstrap';

import classNames from 'classnames';
import AddIcon from 'mdi-react/AccountMultiplePlusOutlineIcon';
import EditIcon from 'mdi-react/AccountEditIcon';

import { API_LINK } from '../../../../constants/config';
import { POST } from '../../../../helpers/agent';
import PrenocitveIzpisiFrom from './PrenocitveIzpisiFrom';

const API_REASSIGN_SLEEP = '/sl/add';

class PrenocitveIzpisiModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { modal: false };

    this.toggle = this.toggle.bind(this);
    this.cancel = this.cancel.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  toggle() {
    this.setState({
      modal: !this.state.modal,
    });
  }

  cancel(data) {
    this.setState({
      modal: false,
    });
    this.props.onClose(data);
  }

  async handleSubmit(values) {
    try {
      const body = {
        sleeperId: values.sleeperId,
        accommodationId: values.accommodationId.value,
      };
      const data = await POST(API_LINK + API_REASSIGN_SLEEP, JSON.stringify(body));
      if (data.success) {
        this.cancel({
          title: 'Menjava prenočišča',
          message: 'Prenočišča uspešno zamenjano!',
          success: true,
        });
      } else {
        this.cancel({
          title: 'Menjava prenočišča',
          message: 'Prenočišče ni uspešno zamenjano!',
          success: false,
        });
      }
    } catch (error) {
      // console.log(error);
      this.cancel(null);
    }
  }

  render() {
    const { icon, btn, title, colored, header, hasBtn, show, editData, accommodations } = this.props;
    let Icon;

    switch (icon) {
      case 'add':
        Icon = <AddIcon />;
        break;
      case 'edit':
        Icon = <EditIcon />;
        break;
      default:
        break;
    }
    const modalClass = classNames({
      'modal-dialog--colored': colored,
      'modal-dialog--header': header,
    });
    return (
      <div style={{ width: '100%' }}>
        {hasBtn ? (
          <Button className="icon large-button" color="primary" onClick={this.toggle}>
            <p>
              {icon === 'add' ? <AddIcon /> : <EditIcon />}
              {btn}
            </p>
          </Button>
        ) : null}

        <Modal isOpen={this.state.modal || show} toggle={this.toggle} className={`modal-dialog--primary ${modalClass}`}>
          <div className="modal__header">
            <button className="lnr lnr-cross modal__close-btn" onClick={this.toggle} />
            {header ? '' : Icon}
            <h4 className="bold-text  modal__title">{title}</h4>
          </div>
          <div className="modal__body">
            <div className="theme-light">
              {editData ? (
                <PrenocitveIzpisiFrom
                  onSubmit={this.handleSubmit}
                  accommodations={accommodations}
                  handleCancel={() => this.cancel(null)}
                  handleDelete={this.handleDelete}
                  initialValues={editData}
                  editData={editData}
                />
              ) : (
                <PrenocitveIzpisiFrom
                  onSubmit={this.handleSubmit}
                  accommodations={accommodations}
                  handleCancel={() => this.cancel(null)}
                  handleDelete={this.handleDelete}
                />
              )}
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

PrenocitveIzpisiModal.propTypes = {
  title: PropTypes.string,
  accommodations: PropTypes.arrayOf(PropTypes.object).isRequired,
  icon: PropTypes.string.isRequired,
  colored: PropTypes.bool,
  header: PropTypes.bool,
  btn: PropTypes.string.isRequired,
  hasBtn: PropTypes.bool.isRequired,
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  editData: PropTypes.object,
};

PrenocitveIzpisiModal.defaultProps = {
  title: '',
  colored: false,
  header: false,
  editData: null,
};

export default PrenocitveIzpisiModal;
