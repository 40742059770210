import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import { Table, ButtonToolbar, Progress, Button, UncontrolledTooltip, Row, Col } from 'reactstrap';
import moment from 'moment-timezone';
import { CompaniesProps, EmployeesProps } from 'shared/prop-types/ReducerProps';
import { getItem, setItem } from 'helpers/cookies';
import financeApi from 'api/finance';
import ArrowBackIcon from 'mdi-react/ArrowBackIcon';
import ReactLoading from 'react-loading';
import fix, { commify } from '../../../../../helpers/floatParser';
import constants from '../../../helpers/index';
import Dropdown from '../../../Filters/Dropdown';
import { MultiSelectField } from '../../../../../shared/components/form/MultiSelect';
import cashboxApi from '../../../../../api/cashbox';
import kontoHelper from '../../helpers/index';
import Konto from './Konto';
import SuperModal from '../../../../../shared/components/SuperModal';
import TransactionForm from '../../../Cashbox/components/Forms/TransactionForm';
import HistorySlider from '../Filters/HistorySlider';

const { kontify, calculateSaldo, createEditValuesFromRecurring, createEditValuesForLeveling } = kontoHelper;

const { MONTHS, YEARS, DEBOOK_LEVELING } = constants;

class KontoTable extends Component {
  static propTypes = {
    employee: PropTypes.object.isRequired,
    onDismiss: PropTypes.func.isRequired,
    month: PropTypes.string.isRequired,
    year: PropTypes.string.isRequired,
    //onRowClick: PropTypes.func.isRequired,
    //workersState: EmployeesProps.isRequired,
    t: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    const { t } = props;
    this.state = {
      loading: true,
      kontos: [],
      showModal: false,
      transactionEditValues: undefined,
      isRecurring: false,
      historySilderValue: { value: 0, label: t('wages.showall') },
    };
  }

  componentDidMount() {
    this.fetchKontos();
  }

  fetchKontos = async () => {
    const { employee, month, year } = this.props;
    const { transactions } = await cashboxApi.getTransactionsByEmployee(employee._id, month, year);
    const { recurring } = await financeApi.getEmployeeRecurringTransactionsFromTemplates(employee._id, month, year);
    const { compensations } = await financeApi.getEmployeeCompensations(employee._id, month, year);
    console.log('KontoTable -> fetchKontos -> compensations', compensations);
    console.log('KontoTable -> fetchKontos -> recurring', recurring);
    console.log('KontoTable -> fetchKontos -> transactions', transactions);
    const kontos = kontify(transactions, recurring, compensations, employee);
    this.setState({ kontos, loading: false });
  };

  handleDebookClick = async (category, kontoId) => {
    const { month, year } = this.props;
    const transactionEditValues = createEditValuesForLeveling(category, kontoId, month, year);
    this.setState({ transactionEditValues });
    this.toggleModal(true);
  };

  handleIgnoreClick = async (transactionId, ignore) => {
    const { month, year } = this.props;
    await cashboxApi.ignoreTransaction(transactionId, month, year, ignore);
    this.fetchKontos();
  };

  handleRowClick = async (value, isTemplate, isCompensation) => {
    console.log('KontoTable -> handleRowClick -> isCompensation', isCompensation);
    const { month, year } = this.props;
    let transactionEditValues;
    if (isCompensation) {
      transactionEditValues = await financeApi.getCompensation(value);
    } else if (!isTemplate) {
      transactionEditValues = await cashboxApi.getTransaction(value);
    } else {
      const recurringTemplate = await cashboxApi.getRecurringTemplate(value);
      transactionEditValues = createEditValuesFromRecurring(recurringTemplate, month, year);
    }
    console.log('KontoTable -> handleRowClick -> transactionEditValues', transactionEditValues);
    this.setState({ transactionEditValues });
    this.toggleModal(true);
  };

  handleSubmit = async values => {
    const { month, year } = this.props;
    if (values.transactionId) {
      if (values.transactionCategory.value === DEBOOK_LEVELING) {
        //izravnave obravnava drugi api kot ostale
        await financeApi.upsertCompensation(
          values.employee.value,
          month,
          year,
          Number(values.amount),
          values.notes,
          JSON.stringify(values.kontoId),
        );
      } else {
        await cashboxApi.updateTransaction(values);
      }
    } else if (values.transactionCategory.value === DEBOOK_LEVELING) {
      // izravnave obravnava drugi api kot ostale
      await financeApi.upsertCompensation(
        values.employee.value,
        month,
        year,
        Number(values.amount),
        values.notes,
        values.kontoId,
      );
    } else {
      await cashboxApi.addTransaction(values);
    }
    this.toggleModal(false);
    this.fetchKontos();
  };

  handleDelete = async (value, catId = null) => {
    if (catId === DEBOOK_LEVELING) {
      await financeApi.deleteCompensation(value);
    } else {
      await cashboxApi.deleteTransaction(value);
    }
    this.toggleModal(false);
    this.fetchKontos();
  };

  handleConfirm = async (value, isTemplate) => {
    const { month, year } = this.props;
    if (!isTemplate) {
      await cashboxApi.confirmTransaction(value);
    } else {
      await cashboxApi.confirmTemplateTransaction(value, null, null, null, month, year);
    }
    this.toggleModal(false);
    this.fetchKontos();
  };

  handleHistoryChange = value => {
    this.setState({ historySilderValue: value });
  };

  renderDismissButton = () => {
    const { onDismiss } = this.props;
    return (
      <Fragment>
        <Button size="sm" className="konto-dismiss-btn" onClick={onDismiss}>
          <ArrowBackIcon />
        </Button>
        <HistorySlider onSubmit={this.handleHistoryChange} />
      </Fragment>
    );
  };

  renderKontos = () => {
    const { kontos, historySilderValue } = this.state;
    const { month, year } = this.props;
    return kontos.map(konto => {
      return (
        <Konto
          key={`konto-key-${JSON.stringify(konto.kontoId)}`}
          transactions={konto.transactions}
          kontoName={konto.name}
          kontoType={konto.type}
          kontoId={konto.kontoId}
          onTransactionConfirm={this.handleConfirm}
          month={month}
          year={year}
          onRowClick={this.handleRowClick}
          onDebookClick={this.handleDebookClick}
          onTransactionIgnore={this.handleIgnoreClick}
          histoValue={historySilderValue}
        />
      );
    });
  };

  toggleModal = (show, resetInitial = false) => {
    if (!resetInitial) {
      this.setState({ showModal: show });
    } else {
      this.setState({ showModal: show, transactionEditValues: { date: new Date(Date.now()), isNew: true } });
    }
  };

  render() {
    const { t, employee, month, year } = this.props;
    const { loading, kontos, showModal, transactionEditValues, isRecurring } = this.state;
    const { confirmed, unconfirmed, total } = calculateSaldo(kontos, true, month, year, 'konto-total');
    return (
      <Fragment>
        <Row>
          <Col md={12}>
            <div style={{ position: 'relative' }}>
              <div className="super-modal-abs">
                <Button color="primary" onClick={() => this.toggleModal(true, true)}>
                  {t('cashbox.trans_add')}
                </Button>
              </div>
              <SuperModal
                title={t('cashbox.trans_new').toUpperCase()}
                subtitle={employee.name}
                show={showModal}
                onClose={() => this.toggleModal(false)}
              >
                <TransactionForm
                  onSubmit={this.handleSubmit}
                  onCancel={() => this.toggleModal(false)}
                  onDelete={this.handleDelete}
                  onConfirm={this.handleConfirm}
                  onFreeze={() => null}
                  initialValues={transactionEditValues}
                  recurring={isRecurring}
                  konto
                  kontoEmployee={{ value: employee._id, label: employee.name }}
                />
              </SuperModal>
            </div>
          </Col>
        </Row>
        <ButtonToolbar style={{ position: 'relative' }}>{this.renderDismissButton()}</ButtonToolbar>
        <div style={{ width: '100%', textAlign: 'center', marginBottom: '30px' }}>
          <h3>{employee.name}</h3>
          <h4 style={{ color: 'lightgray' }}>{`${month}/${year}`}</h4>
          <br />
          <h4 className="bold-text" style={{ textAlign: 'left', marginLeft: '15px' }}>
            SALDO: {confirmed} / {unconfirmed} -- {total}
          </h4>
        </div>

        {loading ? (
          <ReactLoading type="bars" color="gray" height={128} width={128} className="react-loader" />
        ) : (
          this.renderKontos()
        )}
      </Fragment>
    );
  }
}

const wr = connect(state => ({
  workersState: state.employees,
}))(KontoTable);

export default translate('common')(wr);
