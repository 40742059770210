import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Table, Badge } from 'reactstrap';
import { translate } from 'react-i18next';

class RecurringTable extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  renderStatus = status => {
    const { t } = this.props;
    let color;
    let text;
    switch (status) {
      case '2':
        color = 'blue';
        text = t('cashbox.frozen').toUpperCase();

        break;
      case '3':
        color = 'gray';
        text = t('cashbox.done').toUpperCase();

        break;

      default:
        color = 'green';
        text = t('cashbox.open').toUpperCase();
        break;
    }

    return (
      <Badge
        style={{
          backgroundColor: color,
          color: 'white',
        }}
      >
        {text}
      </Badge>
    );
  };

  renderCompany = company => {
    if (!company) return '-';
    return (
      <Badge
        style={{
          backgroundColor: `${company.color}`,
          color: '#646777',
        }}
      >
        {company.name}
      </Badge>
    );
  };

  renderEmployee = employee => {
    if (!employee) return '-';
    return `${employee.surname.toUpperCase()} ${employee.name}`;
  };

  renderTransactions = (transactions, onRowClick) => {
    const { t } = this.props;
    return transactions.map((transaction, index) => {
      const count = transactions.length - index;
      const { _id, date, purpose, employee, company, type, notes, basis, payment, remaining, status } = transaction;

      const tip = type === 'finite' ? t('cashbox.finite').toUpperCase() : t('cashbox.infinite').toUpperCase();

      return (
        <tr key={_id} onClick={() => onRowClick(_id)}>
          <td>{count}</td>
          <td>{date}</td>
          <td>
            <strong>{purpose.toUpperCase()}</strong>
          </td>
          <td>{tip}</td>
          <td>{this.renderEmployee(employee)}</td>
          <td>{this.renderCompany(company)}</td>
          <td>
            <strong>{basis || '/'}</strong>
          </td>
          <td>
            <strong>{payment}</strong>
          </td>
          <td>
            <strong>{remaining}</strong>
          </td>
          <td>{this.renderStatus(status)}</td>
          <td>{notes}</td>
        </tr>
      );
    });
  };

  render() {
    const { transactions, onRowClick, t } = this.props;
    return (
      <Table hover className="table--bordered" responsive>
        <thead>
          <tr>
            <th>#</th>
            <th>{t('board.ow.date')}</th>
            <th>{t('cashbox.purpose')}</th>
            <th>{t('cashbox.recurringtype')}</th>
            <th>{t('board.ow.worker')}</th>
            <th>{t('form.company')}</th>
            <th>{t('cashbox.basis')} (€)</th>
            <th>{t('cashbox.payment')} (€)</th>
            <th>{t('cashbox.remaining')} (€)</th>
            <th>{t('cashbox.status')}</th>
            <th>{t('form.notes')}</th>
          </tr>
        </thead>
        <tbody>{this.renderTransactions(transactions, onRowClick)}</tbody>
      </Table>
    );
  }
}

RecurringTable.propTypes = {
  transactions: PropTypes.arrayOf(PropTypes.object).isRequired,
  onRowClick: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

RecurringTable.defaultProps = {};

export default translate('common')(RecurringTable);
