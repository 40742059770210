import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody, Col, ButtonToolbar, Button, Nav, NavItem, NavLink } from 'reactstrap';
import classnames from 'classnames';
import shortid from 'shortid';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import CalendarBlankIcon from 'mdi-react/CalendarBlankIcon';
import { API_LINK } from 'constants/config';
import validate from './validate2';
import renderCheckBoxField from '../../../shared/components/form/CheckBox';
import renderDatePickerField from '../../../shared/components/form/DatePicker';
import { BULK_DOWNLOAD } from '../../../helpers/agent';
import Alert from '../../../shared/components/Alert';

const DNONE = { display: 'none' };
const SHOW = {};

const REQUIRED_DATA_FOR_ANEKS = {
  company: {
    name: 'ime',
    address: 'naslov',
    zip: 'poštna',
    city: 'kraj',
    country: 'država',
    registrationNum: 'matična',
    agent: 'zastopnik',
    taxID: 'davčna',
  },
  worker: {
    nameOnly: 'ime',
    surname: 'priimek',
    emso: 'emso',
    taxID: 'davčna',
    birthDate: 'datum roj.',
    birthPlace: 'kraj roj.',
    birthCountry: 'država roj.',
    address: 'naslov',
    zip: 'poštna',
    city: 'kraj',
    profession: 'poklic',
  },
  worksite: {
    title: 'naziv',
    address: 'naslov',
    zip: 'poštna',
    city: 'kraj',
    country: 'država',
  },
};

const keyExists = (data, key) => Object.prototype.hasOwnProperty.call(data, key);
const checkData = (type, data) => {
  const missing = [];

  Object.keys(REQUIRED_DATA_FOR_ANEKS[type]).forEach(key => {
    if (!keyExists(data[type], key)) {
      missing.push(REQUIRED_DATA_FOR_ANEKS[type][key]);
    } else if (!data[type][key] || data[type][key] === '') {
      missing.push(REQUIRED_DATA_FOR_ANEKS[type][key]);
    }
  });

  return missing;
};

class SpremembeDeassignForm extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: '1',
      showDefault: true,
      alertShow: true,
    };

    this.toggle = this.toggle.bind(this);
    this.renderTabsField = this.renderTabsField.bind(this);
    this.handleAneksGeneration = this.handleAneksGeneration.bind(this);
    this.renderMissingDataWarn = this.renderMissingDataWarn.bind(this);
    this.dismissAlert = this.dismissAlert.bind(this);
  }

  dismissAlert() {
    this.setState({ alertShow: false });
  }

  async handleAneksGeneration() {
    /*eslint-disable*/
    const {
      form: {
        spremembe_deassign_form: { values },
      },
    } = this.props;
    /* eslint-enable */

    const dataForDocs = {
      ...values.docsData,
      assignment: {
        date: values.docsData.assignment.date,
        dateEnd: values.date,
        dateTo: values.dateTo,
      },
      misc: {
        dateIssued: values.dateIssued,
      },
    };

    const success = await BULK_DOWNLOAD(`${API_LINK}`, dataForDocs, 'ANEKS_PREMATURE');
    console.log('TCL: handleDownload -> success', success);
  }

  toggle = tab => {
    if (this.state.activeTab !== tab) {
      switch (tab) {
        case '2':
          this.setState({
            activeTab: tab,
            showDefault: false,
            alertShow: true,
          });
          break;

        default:
          this.setState({
            activeTab: tab,
            showDefault: true,
          });
          break;
      }
    }
  };

  renderTabsField(activeTab) {
    return (
      <div className="tabs tabs--justify tabs--bordered-bottom" style={{ marginBottom: '40px' }}>
        <div className="tabs__wrap">
          <Nav tabs>
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === '1' })}
                onClick={() => {
                  this.toggle('1');
                }}
              >
                ZAKLJUČI
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === '2' })}
                onClick={() => {
                  this.toggle('2');
                }}
              >
                IZDELAVA ANEKSA
              </NavLink>
            </NavItem>
          </Nav>
        </div>
      </div>
    );
  }

  renderMissingDataWarn() {
    /*eslint-disable*/
    const {
      form: {
        spremembe_deassign_form: { values },
      },
    } = this.props;
    /* eslint-enable */

    console.log('TCL: SpremembeDeassignForm -> renderMissingDataWarn -> values', values);
    const types = ['company', 'worker', 'worksite'];
    const missing = [];

    types.forEach(type => {
      const missingElts = checkData(type, values.docsData);
      console.log('TCL: SpremembeDeassignForm -> renderMissingDataWarn -> missingElts', missingElts);
      if (missingElts.length > 0) {
        let t;
        switch (type) {
          case 'company':
            t = 'PODJETJE';
            break;
          case 'worker':
            t = 'DELAVEC';
            break;
          case 'worksite':
            t = 'GRADBIŠČE';
            break;
          default:
            break;
        }
        missing.push(`${t}: (${missingElts.join(', ')})`);
      }
    });

    let AlertElt = null;
    if (missing.length > 0) {
      AlertElt = (
        <Alert color="danger" className="alert--bordered" icon handleDismiss={this.dismissAlert}>
          <div>
            <span className="bold-text">Manjkajoči podatki:</span>
            <br />
            {missing.map(m => (
              <p key={shortid.generate()}>{m}</p>
            ))}
            <span style={{ color: 'red', fontSize: 'x-small' }}>
              Dokument z nepopolnimi podatki ne bo pravilno generiran!
            </span>
          </div>
        </Alert>
      );
    }

    return AlertElt;
  }

  render() {
    const { handleSubmit, handleCancel, editData } = this.props;
    const { activeTab, showDefault, alertShow } = this.state;

    return (
      <Col md={12} lg={12}>
        <Card>
          <CardBody>
            {this.renderTabsField(activeTab)}
            {!showDefault && alertShow && this.renderMissingDataWarn(activeTab)}
            <form className="form" onSubmit={handleSubmit}>
              <div className="form__form-group">
                <span className="form__form-group-label">Datum predčasnega zaključka napotitve</span>
                <div className="form__form-group-field">
                  <Field name="date" component={renderDatePickerField} />
                  <div className="form__form-group-icon">
                    <CalendarBlankIcon />
                  </div>
                </div>
              </div>
              {editData.orderer && (
                <div className="form__form-group" style={showDefault ? SHOW : DNONE}>
                  <div className="form__form-group-field">
                    <Field
                      name="orderer"
                      component={renderCheckBoxField}
                      label="Zaključi naročnika"
                      className="colored"
                    />
                  </div>
                </div>
              )}
              {editData.worksite && (
                <div className="form__form-group" style={showDefault ? SHOW : DNONE}>
                  <div className="form__form-group-field">
                    <Field
                      name="worksite"
                      component={renderCheckBoxField}
                      label="Zaključi gradbišče"
                      className="colored"
                    />
                  </div>
                </div>
              )}
              {/* {editData.vehicle && (
                <div
                  className="form__form-group"
                  style={showDefault ? SHOW : DNONE}
                >
                  <div className="form__form-group-field">
                    <Field
                      name="vehicle"
                      component={renderCheckBoxField}
                      label="Zaključi vozilo"
                      className="colored"
                    />
                  </div>
                </div>
              )} */}
              {editData.accommodation !== null && (
                <div className="form__form-group" style={showDefault ? SHOW : DNONE}>
                  <div className="form__form-group-field">
                    <Field
                      name="accommodation"
                      component={renderCheckBoxField}
                      label="Zaključi prenočišče"
                      className="colored"
                    />
                  </div>
                </div>
              )}

              <div className="form__form-group" style={!showDefault ? SHOW : DNONE}>
                <span className="form__form-group-label">Predviden zaključek dela (datum)</span>
                <div className="form__form-group-field">
                  <Field name="dateTo" component={renderDatePickerField} />
                  <div className="form__form-group-icon">
                    <CalendarBlankIcon />
                  </div>
                </div>
              </div>

              <div className="form__form-group" style={!showDefault ? SHOW : DNONE}>
                <span className="form__form-group-label">Datum izdaje aneksa</span>
                <div className="form__form-group-field">
                  <Field name="dateIssued" component={renderDatePickerField} />
                  <div className="form__form-group-icon">
                    <CalendarBlankIcon />
                  </div>
                </div>
              </div>

              <ButtonToolbar className="form__button-toolbar">
                <Button type="button" onClick={handleCancel}>
                  Prekliči
                </Button>
                {showDefault ? (
                  <Button color="primary" type="submit">
                    {editData ? 'Shrani spremembe' : 'Dodaj'}
                  </Button>
                ) : (
                  <Button color="warning" onClick={this.handleAneksGeneration}>
                    Prenesi dokument
                  </Button>
                )}
              </ButtonToolbar>
            </form>
          </CardBody>
        </Card>
      </Col>
    );
  }
}

SpremembeDeassignForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
  editData: PropTypes.object,
  // pristine: PropTypes.bool.isRequired,
};

SpremembeDeassignForm.defaultProps = {
  editData: null,
};

export default reduxForm({
  form: 'spremembe_deassign_form', // a unique identifier for this form
  validate,
  // initialValues: { date: new Date(Date.now()) },
})(
  connect(state => ({
    form: state.form,
  }))(SpremembeDeassignForm),
);
