/* eslint-disable consistent-return */
import React, { PureComponent } from 'react';
import ReactDataGrid from 'react-data-grid';
import update from 'immutability-helper';
import PropTypes from 'prop-types';
import _ from 'lodash';

export default class EditableTable extends PureComponent {
  static propTypes = {
    heads: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string,
        name: PropTypes.string,
        editable: PropTypes.bool,
        sortable: PropTypes.bool,
        events: PropTypes.shape(),
      }),
    ).isRequired,
    rows: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    onUpdate: PropTypes.func.isRequired,
    rowRenderer: PropTypes.shape(),
    additionalClass: PropTypes.string,
  };

  static defaultProps = {
    rowRenderer: null,
    additionalClass: '',
  };

  constructor(props, context) {
    super(props, context);
    const originalRows = this.props.rows;
    const rows = originalRows.slice(0, originalRows.length);
    this.state = { rows, originalRows };
  }

  componentDidUpdate(prevProps) {
    if (!_.isEqual(prevProps, this.props)) {
      const originalRows = this.props.rows;
      const rows = originalRows.slice(0, originalRows.length);
      this.updateRows({ rows, originalRows });
    }
  }

  updateRows({ rows, originalRows }) {
    this.setState({ rows, originalRows });
  }

  handleGridRowsUpdated = ({ fromRow, toRow, updated }) => {
    const rows = this.state.rows.slice();

    this.props.onUpdate({ row: fromRow, updated, rowGetter: this.rowGetter });

    for (let i = fromRow; i <= toRow; i += 1) {
      const rowToUpdate = rows[i];
      rows[i] = update(rowToUpdate, { $merge: updated });
    }

    this.setState({ rows });
  };

  handleGridSort = (sortColumn, sortDirection) => {
    const comparer = (a, b) => {
      if (sortDirection === 'ASC') {
        return a[sortColumn] > b[sortColumn] ? 1 : -1;
      }
      if (sortDirection === 'DESC') {
        return a[sortColumn] < b[sortColumn] ? 1 : -1;
      }
    };

    const sortRows = this.state.originalRows.slice(0);
    const rows = sortDirection === 'NONE' ? this.state.originalRows.slice(0, 10) : sortRows.sort(comparer).slice(0, 10);

    this.setState({ rows });
  };

  rowGetter = i => this.state.rows[i];

  render() {
    const { additionalClass } = this.props;
    return (
      <div className={`table ${additionalClass}`}>
        <ReactDataGrid
          onGridSort={this.handleGridSort}
          enableCellSelect
          columns={this.props.heads}
          rowGetter={this.rowGetter}
          rowsCount={this.state.rows.length}
          onGridRowsUpdated={this.handleGridRowsUpdated}
          rowHeight={44}
          minColumnWidth={100}
          rowRenderer={this.props.rowRenderer}
        />
      </div>
    );
  }
}
