export const SHOW_DOCS_EXP_BADGE = 'SHOW_DOCS_EXP_BADGE';
export const SET_DOCS_EXP_ALERTS = 'SET_DOCS_EXP_ALERTS';

export function showDocsExpBadge(show) {
  return {
    type: SHOW_DOCS_EXP_BADGE,
    show,
  };
}

export function setAlerts(alerts) {
  return {
    type: SET_DOCS_EXP_ALERTS,
    alerts,
  };
}
