import { SET_VEHICLES, SET_LICENSES_LIST } from '../actions/vehiclesActions';

const initialState = {
  vehicles: [],
  licenses: [],
};

export default function(state = initialState, action) {
  const licenseList = [];
  switch (action.type) {
    case SET_VEHICLES:
      action.vehicles.forEach(vehi => {
        const vehData = {
          value: vehi._id,
          label: `${vehi.licensePlate} ${vehi.brand}`,
        };
        licenseList.push(vehData);
      });
      return { ...state, vehicles: action.vehicles, licenses: licenseList };
    case SET_LICENSES_LIST:
      return { ...state, licenses: action.licenses };
    default:
      return state;
  }
}
