import React, { PureComponent, Fragment } from 'react';
import shortid from 'shortid';
import classnames from 'classnames';
import ChevronUp from 'mdi-react/ChevronUpIcon';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ChevronDown from 'mdi-react/ChevronDownIcon';
import { Button, Col, Row, Card, CardBody, CardTitle } from 'reactstrap';
import { translate } from 'react-i18next';
import { CompaniesProps } from '../../../shared/prop-types/ReducerProps';

class Legend extends PureComponent {
  static propTypes = {
    companies: CompaniesProps.isRequired,
    t: PropTypes.func.isRequired,
    lettercodes: PropTypes.bool,
  };

  static defaultProps = {
    lettercodes: false,
  };

  constructor(props) {
    super(props);

    this.state = {
      visible: false,
    };

    this.handleHideButtonClick = this.handleHideButtonClick.bind(this);
    this.handleESC = this.handleESC.bind(this);
  }

  componentDidMount() {
    document.addEventListener('keydown', this.handleESC, false);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleESC, false);
  }

  handleESC(event) {
    if (event.keyCode === 27) {
      const { visible } = this.state;
      if (visible) {
        this.setState(prevState => ({
          visible: !prevState.visible,
        }));
      }
    }
  }

  handleHideButtonClick(hide = null) {
    if (hide) {
      this.setState({
        visible: false,
      });
    } else {
      this.setState(prevState => ({
        visible: !prevState.visible,
      }));
    }
  }

  renderTable = () => {
    const {
      companies: { companiesTitles },
    } = this.props;
    return companiesTitles.map(companiesRow => (
      <Col xs={6} key={shortid.generate()}>
        <div
          className="table-col"
          style={{
            backgroundColor: `${companiesRow.color}`,
            fontSize: 'medium',
          }}
        >
          <strong>{companiesRow.label.toUpperCase()}</strong>
        </div>
      </Col>
    ));
  };

  renderLetterCodeTable = t => {
    const letterCodes = [
      {
        code: 'D',
        color: '#6aabb8',
        desc: t('lettercode.leave'),
      },
      {
        code: 'B',
        color: '#7c4162',
        desc: t('lettercode.sick'),
      },
      {
        code: 'C',
        color: '#6d3e23',
        desc: t('lettercode.wait'),
      },
      {
        code: 'P',
        color: '#256d23',
        desc: t('lettercode.free'),
      },
      {
        code: '',
        color: 'red',
        desc: t('lettercode.error'),
      },
    ];
    return letterCodes.map(lc => (
      <Col xs={6} key={shortid.generate()}>
        <div
          className="table-col"
          style={{
            backgroundColor: `${lc.color}`,
            color: 'white',
            fontSize: 'medium',
          }}
        >
          <strong>{`${lc.code} - ${lc.desc.toUpperCase()}`}</strong>
        </div>
      </Col>
    ));
  };

  render() {
    const { visible } = this.state;
    const { t, lettercodes } = this.props;
    const visibleClass = visible ? 'visible' : '';
    const bgVisible = visible ? 'bgVisible' : '';
    return (
      <Fragment>
        <div className={classnames('transparentBg', bgVisible)} />
        <div className={classnames('legend-table', visibleClass)}>
          <Card style={{ height: 'unset' }}>
            <CardBody>
              <CardTitle>{`${t('board.legend')} - ${t('sidebar_content.companies')}`}</CardTitle>
              <Row className="table--bordered  no-gutters" style={{ paddingBottom: '20px' }}>
                {this.renderTable()}
              </Row>
              {lettercodes && (
                <Fragment>
                  <CardTitle>{`${t('board.legend')} - ${t('lettercode.title')}`}</CardTitle>
                  <Row className="table--bordered  no-gutters" style={{ paddingBottom: '20px' }}>
                    {this.renderLetterCodeTable(t)}
                  </Row>
                </Fragment>
              )}
            </CardBody>
          </Card>
        </div>
        <Button
          color="danger"
          className="legend-table__toggle-mobile"
          onClick={() => this.handleHideButtonClick(null)}
          onBlur={() => this.handleHideButtonClick(true)}
        >
          {t('board.legend')} {visible ? <ChevronDown /> : <ChevronUp />}
        </Button>
      </Fragment>
    );
  }
}

const wr = withRouter(
  connect(state => ({
    companies: state.companies,
  }))(Legend),
);

export default translate('common')(wr);
