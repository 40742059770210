import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactGA from 'react-ga';
import { Col, Container, Row, ButtonToolbar, Card, CardBody, Progress } from 'reactstrap';
import NotificationSystem from 'rc-notification';
import { API_LINK } from '../../constants/config';
import NarocnikiTable from './components/NarocnikiTable';
import { BasicNotification } from '../../shared/components/Notification';
import SelectDropdown from '../../shared/components/SelectDropdown';
import NarocnikiModal from './components/NarocnikiModal';
import { POST } from '../../helpers/agent';
import getRoles from '../../constants/roles';
import { OrderersProps, DataListProps, AppStatusProps } from '../../shared/prop-types/ReducerProps';

const API_ORDERERS_GET_ONE = '/o/getOneById';

let notificationLU = null;
let notificationRU = null;
let notificationTC = null;

const showNotification = ({ notification, position }) => {
  switch (position) {
    case 'left-up':
      notificationLU.notice({
        content: notification,
        duration: 5,
        closable: true,
        style: { top: 0, left: 240 },
        className: position,
      });
      break;
    case 'right-up':
      notificationRU.notice({
        content: notification,
        duration: 5,
        closable: true,
        style: { top: 0, left: 'calc(100vw - 100%)' },
        className: position,
      });
      break;
    default:
      notificationTC.notice({
        content: notification,
        duration: 5,
        closable: true,
        style: { top: 0, left: 0 },
        className: position,
      });
      break;
  }
};

class NarocnikiPage extends Component {
  static propTypes = {
    orderersState: OrderersProps.isRequired,
    dataListState: DataListProps.isRequired,
    appStatus: AppStatusProps.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      orderers: [],
      orderersFull: [],
      titles: [],
      countries: [],
      showEdit: false,
      progressNum: 0,
      editOrderer: null,
      ROLES: getRoles(),
    };

    this.onRowSelect = this.onRowSelect.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.showNotif = this.showNotif.bind(this);
    this.fetchData = this.fetchData.bind(this);
    this.handleProgress = this.handleProgress.bind(this);
    this.handleDropdownSubmit = this.handleDropdownSubmit.bind(this);
  }

  componentDidMount() {
    NotificationSystem.newInstance({}, n => (notificationLU = n));
    NotificationSystem.newInstance({}, n => (notificationRU = n));
    NotificationSystem.newInstance({}, n => (notificationTC = n));

    const { appStatus } = this.props;
    if (appStatus.requiredDataLoaded) {
      console.log('ORDERERS -> Data loaded.. continue...');
      this.fetchData();
    }
  }

  componentDidUpdate(prevProps) {
    const { appStatus } = this.props;
    if (appStatus.requiredDataLoaded && !prevProps.appStatus.requiredDataLoaded) {
      console.log('ORDERERS -> Data finished loading.. continue...');
      this.fetchData();
    }

    if (!appStatus.appUpdating && prevProps.appStatus.appUpdating) {
      console.log('ORDERERS -> Data finished UPDATING.. refresh table...');
      this.fetchData();
    }
  }

  componentWillUnmount() {
    notificationLU.destroy();
    notificationRU.destroy();
    notificationTC.destroy();
  }

  async onRowSelect(values) {
    const { ROLES } = this.state;
    if (ROLES.orderers && ROLES.orderers.readonly) {
      ReactGA.event({
        category: 'Naročniki',
        action: `Odprtje modala za naročnike - ${values} - Ni dostopa`,
      });

      this.showNotif({
        title: 'Ni dostopa!',
        message: 'Nimate pravic za urejanje naročnikov!',
        success: false,
      });
      return;
    }

    ReactGA.event({
      category: 'Naročniki',
      action: `Odprtje modala za naročnike - ${values} - Ni dostopa`,
    });

    const that = this;
    const body = JSON.stringify({ id: values });
    const data = await POST(API_LINK + API_ORDERERS_GET_ONE, body);
    if (data.success) {
      console.log('orderer', data.orderer);
      that.setState({ editOrderer: data.orderer, showEdit: true });
    }
  }

  fetchData() {
    const { orderersState, dataListState } = this.props;
    this.setState({
      orderers: orderersState.orderers,
      orderersFull: orderersState.orderers,
      titles: orderersState.titlesOrderers,
      countries: dataListState.countries,
    });
  }

  handleProgress(addProgress) {
    const { progressNum } = this.state;
    this.setState({ progressNum: progressNum + addProgress });
    const that = this;
    if (progressNum + addProgress >= 100) {
      setTimeout(() => {
        that.handleProgress(-(progressNum + addProgress));
      }, 1000);
    }
  }

  showNotif = ({ title, message, success }) => {
    const color = success ? 'success' : 'danger';
    showNotification({
      notification: <BasicNotification color={color} title={title} message={message} />,
      position: 'right-up',
    });
  };

  handleCloseModal(data) {
    if (data) this.showNotif(data);
    this.setState({ showEdit: false });
    if (data) this.fetchData();
  }

  handleDropdownSubmit(values) {
    try {
      if (values.selected_dropdown === null) {
        this.fetchData();
        return;
      }
      ReactGA.event({
        category: 'Naročniki',
        action: `Filtriranje po Naročnikih - ${values.selected_dropdown.label}`,
      });
      const { orderersFull } = this.state;
      const id = values.selected_dropdown.value;
      const filtered = [];
      orderersFull.forEach(ord => {
        if (ord._id === id) {
          filtered.push(ord);
        }
      });
      this.setState({ orderers: filtered });
    } catch (error) {
      console.log(error);
    }
  }

  render() {
    const { orderers, progressNum, titles, countries, showEdit, editOrderer, ROLES } = this.state;
    let showBtn = ROLES.full;

    if (!showBtn) {
      showBtn = !(ROLES.orderers && ROLES.orderers.readonly);
    }
    return (
      <Container className="dashboard">
        <Row>
          <Col lg={12}>
            <h3 className="page-title">Naročniki</h3>
          </Col>
          <Col lg={12}>
            <Row>
              <Col lg={6}>
                <Card>
                  <CardBody>
                    <SelectDropdown title="Išči naročnika" items={titles} onSubmit={this.handleDropdownSubmit} />
                  </CardBody>
                </Card>
              </Col>
              <Col lg={{ size: 4, offset: 2 }}>
                <ButtonToolbar className="button-toolbar text-right">
                  <NarocnikiModal
                    btn="Dodaj naročnika"
                    icon="add"
                    title="Dodaj naročnika"
                    countries={countries}
                    hasBtn={showBtn}
                    show={false}
                    onClose={this.handleCloseModal}
                  />
                </ButtonToolbar>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          {progressNum > 0 ? (
            <div className="progress-wrap progress-wrap--middle progress--align">
              <Progress animated value={progressNum} />
            </div>
          ) : null}
          <NarocnikiTable title="Podatki o naročnikih" orderers={orderers} onRowSelect={this.onRowSelect} />
        </Row>

        <NarocnikiModal
          btn="Uredi naročnika"
          icon="edit"
          title="Uredi naročnika"
          countries={countries}
          hasBtn={false}
          show={showEdit}
          onClose={this.handleCloseModal}
          editData={editOrderer}
        />
      </Container>
    );
  }
}

export default connect(state => ({
  orderersState: state.orderers,
  dataListState: state.dataList,
  appStatus: state.appStatus,
}))(NarocnikiPage);
