import React from 'react';
import PropTypes from 'prop-types';
import ReactExport from 'react-data-export';
import { Button } from 'reactstrap';
import fix from 'helpers/floatParser';
import stable from 'stable';
import { translate } from 'react-i18next';
import { CONFIG } from '../../../../../constants/config';

/* eslint-disable */
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
/* eslint-enable */

const DownloadButton = title => (
  <Button size="sm" color="primary" style={{ marginBottom: '10px' }}>
    {title}
  </Button>
);

const createEmptyCells = listSize => {
  const emptyCells = [];

  const emptyCellCount = listSize * 2 - 2;
  for (let i = 0; i < emptyCellCount; i += 1) {
    emptyCells.push({
      value: ' ',
    });
  }
  return emptyCells;
};

const calcSumsTotalForWorkersOW = sums => {
  return sums.reduce((total, sum) => {
    return total + sum.hoursSum + sum.nightHoursSum;
  }, 0);
};

const calcWorkersTotal = ows => {
  return ows.reduce((total, ow) => {
    const owTotal = calcSumsTotalForWorkersOW(ow.sums);
    return total + owTotal;
  }, 0);
};

const calcCompanyTotal = workers => {
  return workers.reduce((total, worker) => {
    const workerTotal = calcWorkersTotal(worker.OW);
    return total + workerTotal;
  }, 0);
};

const sortWorkers = workers => {
  const lexCmpName = (a, b) => CONFIG.COLLATOR.compare(a.name, b.name);
  const lexCmpSurname = (a, b) => CONFIG.COLLATOR.compare(a.surname, b.surname);
  const byName = stable(workers, lexCmpName);
  const bySurname = stable(byName, lexCmpSurname);
  return bySurname;
};
const fillMissingSums = (sums, listSize) => {
  const initialSumData = {
    hoursSum: 0,
    nightHoursSum: 0,
    total: 0,
  };
  if (sums.length === listSize) return sums;
  const filled = [...sums];
  const diff = listSize - sums.length;
  for (let i = 0; i < diff; i += 1) {
    filled.push(initialSumData);
  }
  return filled;
};
const renderSums = sums => {
  const filled = fillMissingSums(sums);

  const sumsCols = filled.reduce((list, sum) => {
    const tds = [...list];
    tds.push({
      value: Number(fix(sum.hoursSum)),
    });
    tds.push({
      value: Number(fix(sum.nightHoursSum)),
    });
    return tds;
    // return (
    //   <Fragment>
    //     <td>{fix(sum.hoursSum)}</td>
    //     <td>{fix(sum.nightHoursSum)}</td>
    //   </Fragment>
    // );
  }, []);
  const total = calcSumsTotalForWorkersOW(filled);
  sumsCols.push({
    value: Number(fix(total)),
  });
  return sumsCols;
};

const generateHeaders = (t, month, listSize) => {
  let hourHeads = [
    {
      title: t('workerstable.dayHours'),
    },
    {
      title: t('workerstable.nightHours'),
    },
  ];
  if (listSize && listSize > 1) {
    hourHeads = [];
    for (let i = 0; i < listSize; i += 1) {
      const monthNum = month.from + i > 12 ? month.from + i - 12 : month.from + i;
      hourHeads.push({ title: `${t('workerstable.dayHours')} (${monthNum}.)` });
      hourHeads.push({ title: `${t('workerstable.nightHours')} (${monthNum}.)` });
    }
  }
  const firstPart = [
    {
      title: t('form.company'),
    },
    {
      title: t('board.ow.worker'),
    },
    {
      title: t('board.ow.orderer'),
    },
    {
      title: t('board.filters.worksite'),
    },
    {
      title: t('form.address'),
    },
    {
      title: t('form.zip'),
    },
    {
      title: t('form.city'),
    },
    {
      title: t('form.country'),
    },
    ...hourHeads,
    {
      title: t('workerstable.sum'),
    },
  ];

  const multiDataSet = [
    {
      columns: firstPart,
      data: [],
    },
  ];
  return multiDataSet;
};

const generateData = dataAll => {
  console.log('TCL: generateData -> dataAll', dataAll);
  const { month, year } = dataAll;
  const listSize = dataAll.listSize || 1;
  const emptyCells = createEmptyCells(listSize);

  const data = [];
  for (let i = 0; i < dataAll.worklist.length; i += 1) {
    const { company, workers } = dataAll.worklist[i];
    const total = listSize <= 1 ? 0 : calcCompanyTotal(workers);
    const companyRow = [
      {
        value: company.name,
      },
      {
        value: ' ',
      },
      {
        value: ' ',
      },
      {
        value: ' ',
      },
      {
        value: ' ',
      },
      {
        value: ' ',
      },
      {
        value: ' ',
      },
      {
        value: ' ',
      },
      {
        value: ' ',
      },
      {
        value: ' ',
      },
      // {
      //   value: Number(fix(dataAll.worklist[i].total)),
      // },
    ];
    if (listSize <= 1) {
      companyRow.push({
        value: Number(fix(dataAll.worklist[i].total)),
      });
    } else {
      companyRow.push(...emptyCells);
      companyRow.push({
        value: Number(fix(total)),
      });
    }
    data.push(companyRow);

    const sortedWorkers = sortWorkers(workers);
    for (let j = 0; j < sortedWorkers.length; j += 1) {
      const { name, surname, OW } = sortedWorkers[j];
      const totalForWorker = listSize <= 1 ? 0 : calcWorkersTotal(OW);
      const workerRow = [
        {
          value: ' ',
        },
        {
          value: `${surname} ${name}`,
        },
        {
          value: ' ',
        },
        {
          value: ' ',
        },
        {
          value: ' ',
        },
        {
          value: ' ',
        },
        {
          value: ' ',
        },
        {
          value: ' ',
        },
        {
          value: ' ',
        },
        {
          value: ' ',
        },
        // {
        //   value: Number(fix(sortedWorkers[j].total)),
        // },
      ];
      if (listSize <= 1) {
        workerRow.push({
          value: Number(fix(sortedWorkers[j].total)),
        });
      } else {
        workerRow.push(...emptyCells);
        workerRow.push({
          value: Number(fix(totalForWorker)),
        });
      }
      data.push(workerRow);

      const LEX_COMP_ORDERER_TITLE = (a, b) => CONFIG.COLLATOR.compare(a.orderer.title, b.orderer.title);
      const LEX_COMP_WORKSITE_TITLE = (a, b) => CONFIG.COLLATOR.compare(a.worksite.title, b.worksite.title);
      const worksitesSorted = stable(OW, LEX_COMP_WORKSITE_TITLE);
      const orderersSorted = stable(worksitesSorted, LEX_COMP_ORDERER_TITLE);

      for (let k = 0; k < orderersSorted.length; k += 1) {
        const { orderer, worksite, sum, sums } = orderersSorted[k];
        const OWRow = [
          {
            value: ' ',
          },
          {
            value: ' ',
          },
          {
            value: orderer.title,
          },
          {
            value: worksite._title,
          },
          {
            value: worksite.address,
          },
          {
            value: worksite.zip,
          },
          {
            value: worksite.city,
          },
          {
            value: worksite.country,
          },
          // {
          //   value: Number(fix(sum.hoursSum)),
          // },
          // {
          //   value: Number(fix(sum.nightHoursSum)),
          // },
          // {
          //   value: Number(fix(sum.total)),
          // },
        ];
        if (listSize <= 1) {
          OWRow.push({
            value: Number(fix(sum.hoursSum)),
          });
          OWRow.push({
            value: Number(fix(sum.nightHoursSum)),
          });
          OWRow.push({
            value: Number(fix(sum.total)),
          });
        } else {
          const sumsArr = renderSums(sums);
          OWRow.push(...sumsArr);
        }
        data.push(OWRow);
      }
    }
  }

  return data;
};

class WorklistSheet extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { data, btn, t } = this.props;
    console.log('WorklistSheet -> render -> data', data);
    const { month, listSize } = data;

    const headers = generateHeaders(t, month, listSize);
    headers[0].data = generateData(data);
    return (
      <ExcelFile element={DownloadButton(btn)} filename={t('calcs.orderers')}>
        <ExcelSheet dataSet={headers} name={t('calcs.orderers')} />
      </ExcelFile>
    );
  }
}

WorklistSheet.propTypes = {
  btn: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  t: PropTypes.func.isRequired,
};

export default translate('common')(WorklistSheet);
