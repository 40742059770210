import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'reactstrap';
import ReactGA from 'react-ga';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { API_LINK } from 'constants/config';
import { POST } from 'helpers/agent';
import { translate } from 'react-i18next';

import {
  EmployeesProps,
  OrderersProps,
  VehiclesProps,
  AccommodationsProps,
  WorksitesProps,
} from 'shared/prop-types/ReducerProps';
import NapotitveForm from '../../../Napotitve/components/NapotitveForm';

const API_ADD_ASSIGNMENT = '/a/add';
const API_STAGE_ASSIGNMENT = '/a/stage';
const API_EDIT_STAGE_ASSIGNMENT = '/a/updateStage';
const API_EDIT_ASSIGNMENT = '/a/update';
const API_DELETE_ASSIGNMENT = '/a/delete';
const API_DELETE_STAGE_ASSIGNMENT = '/a/deleteStage';

const parseWorkerFields = fields => {
  const allFields = {};

  allFields._id = fields._id || undefined;
  allFields.date = fields.date || undefined;
  allFields.departureDate = fields.departureDate || undefined;
  allFields.placeholders = fields.placeholders;

  if (fields.ordererId) {
    if (fields.ordererId.value) {
      allFields.ordererId = fields.ordererId.value;
    } else {
      allFields.ordererId = fields.ordererId;
    }
  }

  if (fields.worksiteId) {
    if (fields.worksiteId.value) {
      allFields.worksiteId = fields.worksiteId.value;
    } else {
      allFields.worksiteId = fields.worksiteId;
    }
  }
  allFields.notes = fields.notes || undefined;

  const parsedFields = [];
  for (let index = 0; index < 100; index += 1) {
    const { extra } = fields;
    if (extra.includes(index)) continue;
    const workerData = {
      startsFrom: false,
    };
    let shouldAdd = false;
    if (fields[`workerId${index}`]) {
      workerData.worker = fields[`workerId${index}`].value;
      shouldAdd = true;
    }

    if (fields[`accommodationId${index}`]) {
      workerData.accommodation = fields[`accommodationId${index}`].value;
    }

    if (fields[`vehicleId${index}`]) {
      workerData.vehicle = fields[`vehicleId${index}`].value;
    }

    if (fields[`startsFrom${index}`] !== null) {
      workerData.startsFrom = fields[`startsFrom${index}`];
    }

    if (shouldAdd) parsedFields.push(workerData);
  }

  allFields.workers = parsedFields;

  return allFields;
};

class AsssignmentsFormModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
    };

    this.toggle = this.toggle.bind(this);
    this.cancel = this.cancel.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleESC = this.handleESC.bind(this);
  }

  componentDidMount() {
    document.addEventListener('keydown', this.handleESC, false);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleESC, false);
  }

  handleESC(event) {
    if (event.keyCode === 27) {
      this.setState({ modal: false });
      this.cancel(null);
    }
  }

  toggle() {
    if (!this.state.modal) {
      ReactGA.event({
        category: 'Napotitve',
        action: `Odprtje modala za Napotitve - ${this.props.editData ? 'Urejanje' : 'Dodajanje'}`,
      });
    }
    this.setState(prevState => ({
      modal: !prevState.modal,
    }));
  }

  cancel(data) {
    this.setState({
      modal: false,
    });
    this.props.onClose(data);
  }

  async handleDelete(data) {
    const { t } = this.props;
    const apiLink = data.staged ? API_DELETE_STAGE_ASSIGNMENT : API_DELETE_ASSIGNMENT;
    const successMessage = `${t('messages.assignment.delete.success')}!`;
    const errorMessage = `${t('messages.assignment.delete.error')}!`;
    try {
      const dataRes = await POST(API_LINK + apiLink, JSON.stringify({ _id: data._id }));
      if (dataRes.success) {
        this.cancel({
          title: t('messages.assignment.delete.title'),
          message: successMessage,
          success: true,
        });
      } else {
        this.cancel({
          title: t('messages.assignment.delete.title'),
          message: errorMessage,
          success: false,
        });
      }
    } catch (error) {
      this.cancel(null);
    }
  }

  async handleSubmit(values) {
    const body = JSON.stringify(parseWorkerFields(values));

    const { staged, commit } = values;
    console.log('TCL: AsssignmentsFormModal -> handleSubmit -> values', values);

    const { icon, t } = this.props;
    const isEdit = icon !== 'add';
    let apiLink = '';
    if (isEdit && staged) {
      apiLink = API_EDIT_STAGE_ASSIGNMENT;
    } else if (isEdit && !staged) {
      apiLink = API_EDIT_ASSIGNMENT;
    } else {
      apiLink = API_STAGE_ASSIGNMENT;
    }
    let notifTitle = isEdit ? t('messages.assignment.edit.title') : t('messages.assignment.add.title');
    let successMessage = isEdit
      ? `${t('messages.assignment.edit.success')}!`
      : `${t('messages.assignment.add.success')}!`;
    let errorMessage = isEdit ? `${t('messages.assignment.edit.error')}!` : `${t('messages.assignment.add.error')}!`;

    if (staged && commit) {
      apiLink = API_ADD_ASSIGNMENT;
      notifTitle = t('messages.assignment.confirm.title');
      successMessage = t('messages.assignment.confirm.success');
      errorMessage = t('messages.assignment.confirm.error');
    }

    try {
      const data = await POST(API_LINK + apiLink, body);
      if (data.success) {
        this.cancel({
          title: notifTitle,
          message: successMessage,
          success: true,
        });
      } else {
        this.cancel({
          title: notifTitle,
          message: errorMessage,
          success: false,
        });
      }
    } catch (error) {
      console.log(error);
      this.cancel(null);
    }
  }

  render() {
    const {
      title,
      colored,
      header,
      show,
      editData,
      orderersStore,
      worksitesStore,
      employeesStore,
      vehiclesStore,
      accommodationsStore,
    } = this.props;

    const modalClass = classNames({
      'modal-dialog--colored': colored,
      'modal-dialog--header': header,
    });

    return (
      <div style={{ width: '100%' }}>
        <Modal isOpen={this.state.modal || show} toggle={this.toggle} className={`modal-dialog--primary ${modalClass}`}>
          <div className="modal__header">
            <button className="lnr lnr-cross modal__close-btn" onClick={this.toggle} />

            <h4 className="bold-text  modal__title">{title}</h4>
          </div>
          <div className="modal__body">
            <div className="theme-light">
              {editData ? (
                <NapotitveForm
                  orderers={orderersStore.titlesOrderers}
                  worksites={worksitesStore.titlesWorksites}
                  workers={employeesStore.employeesNames}
                  vehicles={vehiclesStore.licenses}
                  accommodations={accommodationsStore.accommodationTitles}
                  onSubmit={this.handleSubmit}
                  handleCancel={() => this.cancel(null)}
                  handleDelete={this.handleDelete}
                  initialValues={editData}
                  editData={editData}
                  initialFieldCount={editData.workersCount}
                />
              ) : (
                <NapotitveForm
                  orderers={orderersStore.titlesOrderers}
                  worksites={worksitesStore.titlesWorksites}
                  workers={employeesStore.employeesNames}
                  vehicles={vehiclesStore.licenses}
                  accommodations={accommodationsStore.accommodationTitles}
                  onSubmit={this.handleSubmit}
                  handleCancel={() => this.cancel(null)}
                  handleDelete={this.handleDelete}
                />
              )}
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

AsssignmentsFormModal.propTypes = {
  title: PropTypes.string,
  orderersStore: OrderersProps.isRequired,
  worksitesStore: WorksitesProps.isRequired,
  employeesStore: EmployeesProps.isRequired,
  vehiclesStore: VehiclesProps.isRequired,
  accommodationsStore: AccommodationsProps.isRequired,
  icon: PropTypes.string.isRequired,
  colored: PropTypes.bool,
  header: PropTypes.bool,
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  editData: PropTypes.object,
  t: PropTypes.func.isRequired,
};

AsssignmentsFormModal.defaultProps = {
  title: '',
  colored: false,
  header: false,
  editData: null,
};

const wr = connect(state => ({
  orderersStore: state.orderers,
  worksitesStore: state.worksites,
  employeesStore: state.employees,
  vehiclesStore: state.vehicles,
  accommodationsStore: state.accommodations,
}))(AsssignmentsFormModal);

export default translate('common')(wr);
