import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import { Col, Card, CardBody, ButtonToolbar, Button } from 'reactstrap';
//import slugify from 'slugify';
import Modal from 'shared/components/Modal';
import { EmployeesProps, CompaniesProps, VehiclesProps, DataListProps } from 'shared/prop-types/ReducerProps';
import CalendarBlankIcon from 'mdi-react/CalendarBlankIcon';
import renderSelectField from '../../../../../shared/components/form/Select';
import validate from './validate';
import constants from '../../../helpers/index';
//import renderTextField from '../../../../../shared/components/form/TextField';
import renderDatePickerField from '../../../../../shared/components/form/DatePicker';
import TransactionFields from './components/TransactionFields';
import getRoles from '../../../../../constants/roles';

const {
  TRANSACTION_SUBTYPES,
  EXPENSE,
  MISC,
  TRAVEL_ORDER,
  RECURRING,
  RETURNED_REASONS,
  KONTO_IGNORE_CATEGORIES,
  DEBOOK_CATEGORIES,
} = constants;

class TransactionForm extends Component {
  static propTypes = {
    initialValues: PropTypes.object, //(PropTypes.object),
    t: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired,
    onFreeze: PropTypes.func.isRequired,
    recurring: PropTypes.bool,
    pristine: PropTypes.bool.isRequired,
    workersState: EmployeesProps.isRequired,
    companiesState: CompaniesProps.isRequired,
    vehiclesState: VehiclesProps.isRequired,
    dataListState: DataListProps.isRequired,
    konto: PropTypes.bool,
    kontoEmployee: PropTypes.object,
  };

  static defaultProps = {
    initialValues: [],
    recurring: false,
    konto: false,
    kontoEmployee: null,
  };

  constructor(props) {
    super(props);
    this.state = {
      //transactionSources: props.dataListState.transactionSources,
      transactionCategories: props.dataListState.transactionCategories,
      transactionTypes: TRANSACTION_SUBTYPES,
      returnedReasons: RETURNED_REASONS,
      selectedCategory: props.recurring === true ? 'recurring' : null,
      selectedType: null,
      ROLES: getRoles(),
    };
  }

  handleCategorySelect = event => {
    console.log('TCL: TransactionForm -> handleCategorySelect -> event', event.value);
    this.setState({ selectedCategory: event.value });
  };

  handleTypeSelect = event => {
    console.log('TransactionForm -> event', event);
    this.setState({ selectedType: event.value });
  };

  shouldRenderAllTypes = () => {
    const { selectedCategory } = this.state;
    const { initialValues } = this.props;
    const shouldRenderArr = [EXPENSE, MISC];
    return (
      (initialValues &&
        initialValues.transactionCategory &&
        shouldRenderArr.includes(initialValues.transactionCategory.value)) ||
      (selectedCategory && shouldRenderArr.includes(selectedCategory))
    );
  };

  shouldRenderInOutTypes = () => {
    const { selectedCategory } = this.state;
    const { initialValues } = this.props;
    const shouldRenderArr = [TRAVEL_ORDER];
    return (
      (initialValues &&
        initialValues.transactionCategory &&
        shouldRenderArr.includes(initialValues.transactionCategory.value)) ||
      (selectedCategory && shouldRenderArr.includes(selectedCategory))
    );
  };

  // shouldRenderReturnedReasons = () => {
  //   const { selectedType } = this.state;
  //   const { initialValues } = this.props;
  //   const shouldRenderArr = [TRAVEL_ORDER];
  //   return (
  //     (initialValues &&
  //       initialValues.transactionCategory &&
  //       shouldRenderArr.includes(initialValues.transactionCategory.value)) ||
  //     (selectedType && shouldRenderArr.includes(selectedType))
  //   );
  // };

  shouldRenderRepeatingOptions = () => {
    const { selectedCategory } = this.state;
    const { initialValues } = this.props;
    const shouldRenderArr = [RECURRING];
    return (
      (initialValues &&
        initialValues.transactionCategory &&
        shouldRenderArr.includes(initialValues.transactionCategory.value)) ||
      (selectedCategory && shouldRenderArr.includes(selectedCategory))
    );
  };

  mergeTypesWithReturnedReasons = types => {
    const { returnedReasons } = this.state;
    return types.reduce((list, elt) => {
      if (elt.value === 'trans_type_in') {
        list.push(elt);
        returnedReasons.forEach(rr => {
          list.push({
            label: `Prinesel ${rr.label}`,
            value: `${elt.value}_${rr.value}`,
          });
        });
      } else {
        list.push(elt);
      }

      return list;
    }, []);
  };

  renderTransactionTypes = () => {
    const { transactionTypes } = this.state;
    const { recurring, t } = this.props;
    let _types = JSON.parse(JSON.stringify(transactionTypes));
    if (this.shouldRenderInOutTypes()) {
      _types.shift();
      _types = this.mergeTypesWithReturnedReasons(_types);
    } else if (this.shouldRenderRepeatingOptions() || recurring) {
      _types = [
        {
          value: 'finite',
          label: t('cashbox.finite_long'),
        },
        {
          value: 'indefinite',
          label: t('cashbox.infinite_long'),
        },
      ];
    } else if (!this.shouldRenderAllTypes()) {
      return null;
    }

    return (
      <div key="TransactionTypes" className="form__form-group">
        <span className="form__form-group-label">{t('cashbox.trans_type')}</span>
        <div className="form__form-group-field bigger-text">
          <Field
            name="transactionType"
            component={renderSelectField}
            options={_types}
            onChange={e => this.handleTypeSelect(e)}
          />
        </div>
      </div>
    );
  };

  filterProperCategories = () => {
    const { transactionCategories } = this.state;
    const { initialValues } = this.props;

    const { konto } = this.props;
    if (!konto) {
      return transactionCategories;
    }

    if (initialValues && initialValues.isDebook) {
      return DEBOOK_CATEGORIES;
    }

    return transactionCategories.filter(tc => !KONTO_IGNORE_CATEGORIES.includes(tc.value));
  };

  filterCategoriesForRole = categories => {
    const { ROLES } = this.state;
    const allowed = ['expense', 'travel-order', 'special', 'misc'];
    if (!(ROLES.super || ROLES.sistem)) {
      return categories.filter(c => allowed.includes(c.value));
    }
    //console.log('TransactionForm -> filterCategoriesForRole -> categories', categories);
    return categories;
  };

  render() {
    const {
      t,
      handleSubmit,
      onCancel,
      pristine,
      workersState,
      companiesState,
      vehiclesState,
      onDelete,
      onConfirm,
      onFreeze,
      initialValues,
      recurring,
      konto,
      kontoEmployee,
    } = this.props;

    const { selectedCategory, selectedType, ROLES } = this.state;

    const transactionCategories = this.filterProperCategories();
    return (
      <Col md={12} lg={12}>
        <Card>
          <CardBody>
            <form className="form" onSubmit={handleSubmit}>
              {/** leva stran */}
              <div className="form__half">
                <div className="form__form-group">
                  <span className="form__form-group-label">{t('board.ow.date')}</span>
                  <div className="form__form-group-field">
                    <Field name="date" component={renderDatePickerField} />
                    <div className="form__form-group-icon">
                      <CalendarBlankIcon />
                    </div>
                  </div>
                </div>
              </div>

              {/** desna stran */}

              {!recurring && !(initialValues && !initialValues.isNew && konto) && (
                <div className="form__half">
                  <div className="form__form-group">
                    <span className="form__form-group-label">{t('cashbox.trans_cat')}</span>
                    <div className="form__form-group-field">
                      <Field
                        name="transactionCategory"
                        component={renderSelectField}
                        options={this.filterCategoriesForRole(transactionCategories)}
                        onChange={e => this.handleCategorySelect(e)}
                      />
                    </div>
                  </div>
                </div>
              )}

              {this.renderTransactionTypes()}

              {((initialValues && initialValues.transactionCategory) || selectedCategory) && (
                <TransactionFields
                  selectedCategory={selectedCategory}
                  initialValues={initialValues}
                  selectedType={selectedType || (initialValues.transactionType && initialValues.transactionType.value)}
                  konto={konto}
                  kontoEmployee={kontoEmployee}
                />
              )}

              {initialValues && initialValues.transactionId && (
                <input type="hidden" name="transactionId" value={initialValues.transactionId} />
              )}
              {initialValues && initialValues.recurringId && (
                <input type="hidden" name="recurringId" value={initialValues.recurringId} />
              )}
              {initialValues && initialValues.kontoId && (
                <input type="hidden" name="kontoId" value={initialValues.kontoId} />
              )}

              <ButtonToolbar className="form__button-toolbar">
                {initialValues &&
                  initialValues.transactionId &&
                  (ROLES.super || ROLES.sistem || ((ROLES.kaja || ROLES.valerija) && initialValues.unconfirmed)) && (
                    <Modal
                      color="danger"
                      title={t('form.warn')}
                      colored
                      btn={t('form.delete')}
                      message="Ste prepričani da želite izbrisati transackcijo?"
                      onConfirm={() => onDelete(initialValues.transactionId, initialValues.transactionCategory.value)}
                    />
                  )}
                <Button type="button" onClick={onCancel}>
                  {t('form.cancel')}
                </Button>
                <Button color="primary" type="submit" disabled={pristine && !initialValues.recurringId}>
                  {t('form.save')}
                </Button>
                {initialValues && initialValues.unconfirmed && ROLES.super && (
                  <Modal
                    color="danger"
                    title={t('form.warn')}
                    colored
                    btn={t('form.confirm')}
                    message="Ste prepričani da želite potrditi transackcijo?"
                    onConfirm={() => onConfirm(initialValues.transactionId)}
                  />
                )}
                {recurring && initialValues && initialValues.transactionId && (
                  <Button color="primary" onClick={() => onFreeze(initialValues.transactionId)} outline>
                    {initialValues.frozen ? t('cashbox.unfreeze') : t('cashbox.freeze')}
                  </Button>
                )}
              </ButtonToolbar>
            </form>
          </CardBody>
        </Card>
      </Col>
    );
  }
}

const cn = connect(state => ({
  workersState: state.employees,
  companiesState: state.companies,
  vehiclesState: state.vehicles,
  dataListState: state.dataList,
}))(TransactionForm);

export default reduxForm({
  form: 'transaction_form_cashbox',
  validate,
  initialValues: { date: new Date(Date.now()) },
})(translate('common')(cn));
