import { API_LINK } from 'constants/config';
import { GET, POST } from 'helpers/agent';

export default {
  getTransactions: async (monthFrom, yearFrom, monthTo, yearTo) => {
    const data = await GET(
      `${API_LINK}/cash/getTransactions?monthFrom=${monthFrom}&yearFrom=${yearFrom}&monthTo=${monthTo}&yearTo=${yearTo}`,
    );
    if (data) {
      return { transactions: data.transactions };
    }
    return [];
  },
  getRecurringTemplates: async () => {
    const data = await GET(`${API_LINK}/cash/getRecurringTemplates`);
    if (data) {
      return { transactions: data.transactions };
    }
    return [];
  },
  getRecurringTemplate: async templateId => {
    const data = await GET(`${API_LINK}/cash/getRecurringTemplate?templateId=${templateId}`);
    if (data) {
      return data;
    }
    return null;
  },
  getTransaction: async transactionId => {
    const data = await GET(`${API_LINK}/cash/getTransaction?transactionId=${transactionId}`);
    if (data) {
      return data;
    }
    return null;
  },
  addTransaction: async transactionData => {
    await POST(`${API_LINK}/cash/addTransaction`, JSON.stringify(transactionData));
  },
  updateTransaction: async transactionData => {
    await POST(`${API_LINK}/cash/updateTransaction`, JSON.stringify(transactionData));
  },
  deleteTransaction: async transactionId => {
    await POST(`${API_LINK}/cash/deleteTransaction`, JSON.stringify({ transactionId }));
  },
  ignoreTransaction: async (transactionId, month, year, ignore) => {
    await POST(`${API_LINK}/cash/ignoreTransaction`, JSON.stringify({ transactionId, month, year, ignore }));
  },
  deleteRecurringTemplate: async templateId => {
    await POST(`${API_LINK}/cash/deleteRecurringTemplate`, JSON.stringify({ templateId }));
  },
  freezeRecurringTemplate: async templateId => {
    await POST(`${API_LINK}/cash/freezeRecurringTemplate`, JSON.stringify({ templateId }));
  },
  confirmTransaction: async transactionId => {
    await POST(`${API_LINK}/cash/confirmTransaction`, JSON.stringify({ transactionId }));
  },
  confirmTemplateTransaction: async (templateId, amount, date, notes, month, year) => {
    await POST(
      `${API_LINK}/cash/confirmTemplateTransaction`,
      JSON.stringify({ templateId, amount, date, notes, month, year }),
    );
  },
  getTransactionSources: async () => {
    const data = await GET(`${API_LINK}/cash/getTransactionSources`);
    if (data) {
      return { transactionSources: data.transactionSources };
    }
    return [];
  },
  getTransactionCategories: async () => {
    const data = await GET(`${API_LINK}/cash/getTransactionCategories`);

    if (data) {
      return { transactionCategories: data.transactionCategories };
    }
    return [];
  },
  getBalances: async () => {
    const data = await GET(`${API_LINK}/cash/getBalances`);
    if (data) {
      return data.balances;
    }
    return [];
  },
  getTravelOrderReturnedReasons: async ridelogId => {
    const data = await GET(`${API_LINK}/cash/getTravelOrderReturnedReasons?ridelogId=${ridelogId}`);
    if (data) {
      return data.reasons;
    }
    return [];
  },
  getTransactionsByEmployee: async (employeeId, month, year) => {
    const data = await GET(
      `${API_LINK}/cash/getTransactionsByEmployee?employeeId=${employeeId}&month=${month}&year=${year}`,
    );
    if (data) {
      return { transactions: data.transactions };
    }
    return [];
  },
};
