import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-i18next';
import { Table, Card, CardBody, Badge, ButtonToolbar } from 'reactstrap';
import shortid from 'shortid';
import stable from 'stable';
import moment from 'moment-timezone';
import workhoursApi from 'api/workhours';
import ReactLoading from 'react-loading';
import { CONFIG } from '../../../../constants/config';
import fix, { commify } from '../../../../helpers/floatParser';
import Dropdown from '../../../Finance/Filters/Dropdown';
import constants from '../../../Finance/helpers';

const { MONTHS, YEARS } = constants;

class Worklist extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: props.data,
      month: {
        from: props.month,
        to: props.month,
      },
      year: {
        from: props.year,
        to: props.year,
      },
      listSize: 1,
      loading: false,
    };
  }

  calculateListSizeFromSpan = (month, year) => {
    const yearFrom = Number(year.from);
    const yearTo = Number(year.to);
    const monthFrom = Number(month.from);
    const monthTo = Number(month.to);
    const from = moment([yearFrom, monthFrom - 1, 1]);
    const to = moment([yearTo, monthTo - 1, 1]);
    return to.diff(from, 'month') + 1;
  };

  isDateSpanValid = (month, year) => {
    const yearFrom = Number(year.from);
    const yearTo = Number(year.to);
    const monthFrom = Number(month.from);
    const monthTo = Number(month.to);
    const from = moment([yearFrom, monthFrom - 1, 1]);
    const to = moment([yearTo, monthTo - 1, 1]);
    return from <= to;
  };

  initSumsToWorkerOWs = (companiesList, index, list) => {
    //console.log('Worklist -> initSumsToWorkerOWs -> index', index);
    return companiesList.map(comData => {
      if (comData.workers.length === 0) {
        return comData;
      }
      const allWorkers = this.insertMissingWorkersFromOtherMonths(comData.workers, list, comData.company._id);
      const remappedWorkers = allWorkers.map(workerData => {
        const remappedOW = workerData.OW.map(owData => {
          const { sum } = owData;
          const initialSumData = {
            hoursSum: 0,
            nightHoursSum: 0,
            total: 0,
          };
          const sums = Array.from(Array(index), () => initialSumData);
          sums.splice(index, 0, sum);

          return {
            ...owData,
            sums,
          };
        });
        return {
          ...workerData,
          OW: remappedOW,
        };
      });

      console.log('Worklist -> initSumsToWorkerOWs -> remappedWorkers', remappedWorkers);
      return {
        ...comData,
        workers: remappedWorkers,
      };
    });
  };

  replaceArrayEltOnIndex = (arr, newElt, index) => {
    return arr.reduce((nma, celt, nmaIdx) => {
      if (nmaIdx === index) {
        nma.push(newElt);
        return nma;
      }
      nma.push(celt);
      return nma;
    }, []);
  };

  remapSingleOWData = (owData, index) => {
    const { sum } = owData;
    const initialSumData = {
      hoursSum: 0,
      nightHoursSum: 0,
      total: 0,
    };
    const sums = Array.from(Array(index), () => initialSumData);
    sums.splice(index, 0, sum);

    return {
      ...owData,
      sums,
    };
  };

  remapOWsWithSumsArray = (ows, index) => {
    return ows.map(owData => {
      return this.remapSingleOWData(owData, index);
    });
  };

  insertMissingWorkersFromOtherMonths = (workers, list, companyId) => {
    console.log('Worklist -> insertMissingWorkersFromOtherMonths -> list', list);
    console.log('Worklist -> insertMissingWorkersFromOtherMonths -> workers', workers);
    const allCompanyWorkers = list.reduce((allWorkers, dataForMonthYear) => {
      console.log('Worklist -> insertMissingWorkersFromOtherMonths -> dataForMonthYear', dataForMonthYear);
      const companyWorkers = dataForMonthYear.worklist.worklist.reduce((mergedWorkers, currentCompanyData) => {
        if (currentCompanyData.company._id !== companyId) {
          return mergedWorkers;
        }
        if (currentCompanyData.workers.length === 0) {
          return mergedWorkers;
        }
        const workersArr = [...mergedWorkers];
        currentCompanyData.workers.forEach(wrkr => {
          const exists = workersArr.find(w => w._id === wrkr._id);
          if (!exists) {
            workersArr.push({ ...wrkr, OW: [] });
          }
        });
        return workersArr;
      }, workers);
      console.log('Worklist -> insertMissingWorkersFromOtherMonths -> companyWorkers', companyWorkers);
      const finalWorkers = [...allWorkers];
      companyWorkers.forEach(wrkr => {
        const exists = finalWorkers.find(w => w._id === wrkr._id);
        if (!exists) {
          finalWorkers.push(wrkr);
        }
      });
      return finalWorkers;
    }, []);
    console.log('Worklist -> insertMissingWorkersFromOtherMonths -> allCompanyWorkers', allCompanyWorkers);
    return allCompanyWorkers;
  };

  convertListToData = list => {
    // console.log('Worklist -> list', list);
    // console.log('Worklist -> this.props.data', this.props.data);
    const initialSumData = {
      hoursSum: 0,
      nightHoursSum: 0,
      total: 0,
    };
    const converted = list.reduce(
      (final, dataForMonthYear, mainIndex) => {
        console.log('Worklist -> final', final);
        console.log('Worklist -> dataForMonthYear', dataForMonthYear);

        let allCompaniesList;
        if (final.worklist.length === 0) {
          console.log('first init');
          allCompaniesList = this.initSumsToWorkerOWs(dataForMonthYear.worklist.worklist, mainIndex, list);
          console.log('Worklist -> allCompaniesList', allCompaniesList);
        } else {
          allCompaniesList = dataForMonthYear.worklist.worklist.reduce(
            (mergedCompanies, currentCompanyData, companyIndex) => {
              console.log('Worklist -> currentCompanyData', currentCompanyData);
              const mergedCompanyData = mergedCompanies.find(c => c.company._id === currentCompanyData.company._id);
              console.log('Worklist -> mergedCompanyData', mergedCompanyData);

              //če je bila firma prej brez delavcev združimo s trenutnim
              if (mergedCompanyData.workers.length === 0) {
                // const allCWorkers = this.insertMissingWorkersFromOtherMonths(
                //   currentCompanyData.workers,
                //   list,
                //   currentCompanyData.company._id,
                // );
                // const currentCompanyDataWithAllWorkers = { ...currentCompanyData, workers: allCWorkers };

                const initializedComp = this.initSumsToWorkerOWs([currentCompanyData], mainIndex, list);
                //zamenjamo initialized company z originalnim
                return this.replaceArrayEltOnIndex(mergedCompanies, initializedComp[0], companyIndex); //newMergedArray;
              }

              // const allWorkers = this.insertMissingWorkersFromOtherMonths(
              //   currentCompanyData.workers,
              //   list,
              //   currentCompanyData.company._id,
              // );
              const mergedWorkersData = currentCompanyData.workers.reduce((mergedWorkers, currentWorkerData) => {
                //console.log('Worklist -> mergedWorkers', mergedWorkers);
                // najdemo delavca iz obstoječega seznama
                const mergedWorkerData = mergedWorkers.find(w => w._id === currentWorkerData._id);
                let remappedWorker; // = currentWorkerData;
                //če ga ne najdemo, ni delal do sedaj
                if (!mergedWorkerData) {
                  console.log('ne najdem -> ', currentWorkerData);
                  const remappedWorkerOW = this.remapOWsWithSumsArray(currentWorkerData.OW, mainIndex);
                  remappedWorker = {
                    ...currentWorkerData,
                    OW: remappedWorkerOW,
                  };
                  //console.log('Worklist -> remappedWorker', remappedWorker);
                  // vstavimo delavca v merged array,
                } else {
                  console.log('je delal');
                  const mergedWorkerOW = currentWorkerData.OW.reduce((mergedOWs, currentOWData) => {
                    // console.log('Worklist -> currentOWData', currentOWData);
                    // console.log('Worklist -> mergedOWs', mergedOWs);
                    const mergedOWData = mergedOWs.find(ow => ow.owId === currentOWData.owId);
                    // console.log('Worklist -> mergedOWData', mergedOWData);
                    let mergedOWList = [...mergedOWs];
                    // console.log('Worklist -> mergedOWList', mergedOWList);
                    if (!mergedOWData) {
                      // do sedaj še ni imel tega OWja
                      const remappedWorkerOW = this.remapSingleOWData(currentOWData, mainIndex);
                      mergedOWList.push(remappedWorkerOW);
                    } else {
                      // združimo enaka OWja
                      const { sum } = currentOWData;
                      const newSums = [...mergedOWData.sums];
                      // novi sum mora biti dan na mesto mainIndexa

                      if (newSums.length === mainIndex) {
                        newSums.push(sum);
                      } else {
                        const diff = mainIndex - newSums.length;
                        for (let i = 0; i < diff; i += 1) {
                          newSums.push(initialSumData);
                        }
                        newSums.push(sum);
                      }
                      const newOWData = { ...mergedOWData, sums: newSums };
                      const mergedOWsIndex = mergedOWs.findIndex(mow => mow.owId === newOWData.owId);
                      mergedOWList = this.replaceArrayEltOnIndex(mergedOWs, newOWData, mergedOWsIndex);
                      // console.log('Worklist -> mergedOWs', mergedOWs);
                      // console.log('Worklist -> newOWData', newOWData);
                    }

                    return mergedOWList;
                  }, mergedWorkerData.OW);

                  remappedWorker = {
                    ...currentWorkerData,
                    OW: mergedWorkerOW,
                  };

                  // console.log('Worklist -> mergedWorkerData', mergedWorkerData);
                  // console.log('Worklist -> currentWorkerData', currentWorkerData);
                }

                const remappedWorkerIndex = mergedWorkers.findIndex(mw => mw._id === remappedWorker._id);

                return this.replaceArrayEltOnIndex(mergedWorkers, remappedWorker, remappedWorkerIndex);
              }, mergedCompanyData.workers);

              const companyWithMergedWorkersData = {
                ...currentCompanyData,
                workers: mergedWorkersData,
              };

              return this.replaceArrayEltOnIndex(mergedCompanies, companyWithMergedWorkersData, companyIndex);
            },
            final.worklist,
          );
        }

        console.log('Worklist -> allCompaniesList', allCompaniesList);
        return {
          totalSum: final.totalSum + dataForMonthYear.worklist.totalSum,
          worklist: allCompaniesList,
        };
      },
      { totalSum: 0, worklist: [] },
    );
    console.log('Worklist -> converted', converted);

    return converted; //this.props.data;
  };

  getWorklistData = async (month, year) => {
    const { selectedCompany } = this.props;
    const isDateValid = this.isDateSpanValid(month, year);
    if (!isDateValid) {
      this.setState({ dateError: 'Končni datum ne sme biti večji od začetnega!' });
      return;
    }
    if (
      Number(month.from) === Number(month.to) &&
      Number(year.from) === Number(year.to) &&
      this.props.month === Number(month.from) &&
      this.props.year === Number(year.from)
    ) {
      this.props.onWorklistDataChange({ ...this.props.data, month, year, listSize: 1 });
      this.setState({ listSize: 1, data: this.props.data, loading: false, dateError: null });
      return;
    }
    this.setState({ loading: true });
    const listSize = this.calculateListSizeFromSpan(month, year);
    // console.log('Worklist -> getWorklistData -> listSize', listSize);
    if (listSize > 6) {
      this.props.onWorklistDataChange({ ...this.props.data, month, year, listSize: 1 });
      this.setState({
        listSize: 1,
        data: this.props.data,
        loading: false,
        dateError: 'Razpon ne sme biti večji od 6 mesecev!',
      });
      return;
    }
    this.setState({ dateError: null });

    const yearFrom = Number(year.from);
    const monthFrom = Number(month.from);
    const datalist = [];
    let date = moment([yearFrom, monthFrom - 2, 1]);
    for (let i = 0; i < listSize; i += 1) {
      //console.log('Worklist -> getWorklistData -> i', i);
      date = date.add(1, 'month').startOf('month'); //moment([yearFrom, monthFrom + i - 1, 1]);
      //console.log('Worklist -> getWorklistData -> date', date.toDate());

      const currMonth = date.month() + 1;
      //console.log('Worklist -> getWorklistData -> currMonth', currMonth);
      const currYear = date.year();
      //console.log('Worklist -> getWorklistData -> currYear', currYear);
      // eslint-disable-next-line no-await-in-loop
      const data = await workhoursApi.calculateWorklist({ month: currMonth, year: currYear, selectedCompany });
      if (data.worklist) {
        datalist.push({ worklist: data.worklist, month: currMonth, year: currYear });
      }
    }
    const calculatedData = this.convertListToData(datalist);
    this.props.onWorklistDataChange({ ...calculatedData, month, year, listSize });
    this.setState({ listSize, data: calculatedData, loading: false });
  };

  handleMonthYearSelect = (monthYearValue, fromTo, monthYear) => {
    const isMonth = monthYear === 'month';
    const otherMonthYear = monthYear === 'month' ? 'year' : 'month';

    const monthYearState = this.state[monthYear];
    monthYearState[fromTo] = monthYearValue;

    const setter = {
      [monthYear]: {
        ...monthYearState,
      },
    };

    this.setState(setter);

    if (isMonth) {
      this.getWorklistData(setter[monthYear], this.state[otherMonthYear]);
    } else {
      this.getWorklistData(this.state[otherMonthYear], setter[monthYear]);
    }
  };

  renderDropdown = () => {
    return (
      <Fragment>
        <Dropdown
          defaultOption={this.state.year.from}
          options={YEARS.map(m => {
            return { value: m, label: m.toString(), color: 'white' };
          })}
          onItemSelect={value => this.handleMonthYearSelect(value, 'from', 'year')}
        />
        <Dropdown
          defaultOption={this.state.month.from}
          options={MONTHS.map(m => {
            return { value: m, label: m.toString(), color: 'white' };
          })}
          onItemSelect={value => this.handleMonthYearSelect(value, 'from', 'month')}
        />
        <div style={{ padding: 6, paddingRight: 20 }}>---</div>
        <Dropdown
          defaultOption={this.state.year.to}
          options={YEARS.map(m => {
            return { value: m, label: m.toString(), color: 'white' };
          })}
          onItemSelect={value => this.handleMonthYearSelect(value, 'to', 'year')}
        />
        <Dropdown
          defaultOption={this.state.month.to}
          options={MONTHS.map(m => {
            return { value: m, label: m.toString(), color: 'white' };
          })}
          onItemSelect={value => this.handleMonthYearSelect(value, 'to', 'month')}
        />
      </Fragment>
    );
  };

  createEmptyCells = () => {
    const { listSize } = this.state;
    const emptyCells = [];

    const emptyCellCount = listSize * 2 - 2;
    for (let i = 0; i < emptyCellCount; i += 1) {
      emptyCells.push(<td />);
    }
    return emptyCells;
  };

  calcSumsTotalForWorkersOW = sums => {
    return sums.reduce((total, sum) => {
      return total + sum.hoursSum + sum.nightHoursSum;
    }, 0);
  };

  calcWorkersTotal = ows => {
    return ows.reduce((total, ow) => {
      const owTotal = this.calcSumsTotalForWorkersOW(ow.sums);
      return total + owTotal;
    }, 0);
  };

  calcCompanyTotal = workers => {
    return workers.reduce((total, worker) => {
      const workerTotal = this.calcWorkersTotal(worker.OW);
      return total + workerTotal;
    }, 0);
  };

  renderTableBody = () => {
    const { data, listSize } = this.state;
    console.log('Worklist -> renderTableBody -> data', data);

    return data.worklist.map(element => {
      const total = listSize <= 1 ? 0 : this.calcCompanyTotal(element.workers);
      return (
        <Fragment key={shortid.generate()}>
          <tr key={shortid.generate()}>
            <td>
              <Badge
                style={{
                  backgroundColor: `${element.company.color}`,
                  color: '#646777',
                }}
              >
                {element.company.name}
              </Badge>
            </td>
            <td />
            <td />
            <td />
            <td />
            <td />
            {/* <td>{element.total !== 0 ? commify(fix(element.total)) : ''}</td> */}
            {listSize <= 1 ? (
              <td>{element.total !== 0 ? commify(fix(element.total)) : ''}</td>
            ) : (
              <Fragment>
                {this.createEmptyCells()}
                <td>{total !== 0 ? commify(fix(total)) : ''}</td>
              </Fragment>
            )}
          </tr>
          {this.renderWorkers(element.workers)}
        </Fragment>
      );
    });
  };

  sortWorkers = workers => {
    const lexCmpName = (a, b) => CONFIG.COLLATOR.compare(a.name, b.name);
    const lexCmpSurname = (a, b) => CONFIG.COLLATOR.compare(a.surname, b.surname);
    const byName = stable(workers, lexCmpName);
    const bySurname = stable(byName, lexCmpSurname);
    return bySurname;
  };

  renderWorkers = workers => {
    const { listSize } = this.state;
    const sortedWorkers = this.sortWorkers(workers);
    return sortedWorkers.map(worker => {
      const total = listSize <= 1 ? 0 : this.calcWorkersTotal(worker.OW);
      return (
        <Fragment>
          <tr key={shortid.generate()}>
            <td />
            <td>{`${worker.surname.toUpperCase()} ${worker.name}`}</td>
            <td />
            <td />
            <td />
            <td />
            {listSize <= 1 ? (
              <td>{worker.total !== 0 ? commify(fix(worker.total)) : ''}</td>
            ) : (
              <Fragment>
                {this.createEmptyCells()}
                <td>{total !== 0 ? commify(fix(total)) : ''}</td>
              </Fragment>
            )}
          </tr>
          {this.renderOrdererWorksites(worker.OW)}
        </Fragment>
      );
    });
  };

  renderOrdererWorksites = ordererWorksites => {
    const { listSize } = this.state;
    const LEX_COMP_ORDERER_TITLE = (a, b) => CONFIG.COLLATOR.compare(a.orderer.title, b.orderer.title);
    const LEX_COMP_WORKSITE_TITLE = (a, b) => CONFIG.COLLATOR.compare(a.worksite.title, b.worksite.title);
    const worksitesSorted = stable(ordererWorksites, LEX_COMP_WORKSITE_TITLE);
    const orderersSorted = stable(worksitesSorted, LEX_COMP_ORDERER_TITLE);
    return orderersSorted.map(ow => (
      <tr key={ow.owId}>
        <td />
        <td />
        <td>{ow.orderer.title}</td>
        <td>{ow.worksite.title}</td>
        {/* todo render sums array */}
        {listSize <= 1 ? (
          <Fragment>
            <td>{commify(fix(ow.sum.hoursSum))}</td>
            <td>{commify(fix(ow.sum.nightHoursSum))}</td>
            <td>{ow.sum.total !== 0 ? commify(fix(ow.sum.total)) : ''}</td>
          </Fragment>
        ) : (
          this.renderSums(ow.sums)
        )}
      </tr>
    ));
  };

  fillMissingSums = sums => {
    const { listSize } = this.state;
    const initialSumData = {
      hoursSum: 0,
      nightHoursSum: 0,
      total: 0,
    };
    if (sums.length === listSize) return sums;
    const filled = [...sums];
    const diff = listSize - sums.length;
    for (let i = 0; i < diff; i += 1) {
      filled.push(initialSumData);
    }
    return filled;
  };

  renderSums = sums => {
    const filled = this.fillMissingSums(sums);

    const sumsCols = filled.map(sum => {
      return (
        <Fragment>
          <td>{commify(fix(sum.hoursSum))}</td>
          <td>{commify(fix(sum.nightHoursSum))}</td>
        </Fragment>
      );
    });
    const total = this.calcSumsTotalForWorkersOW(filled);
    sumsCols.push(<td>{total !== 0 ? commify(fix(total)) : ''}</td>);
    return sumsCols;
  };

  renderTheads = () => {
    const { t } = this.props;
    const { listSize, month } = this.state;
    if (listSize <= 1) {
      return (
        <Fragment>
          <td>{t('workerstable.dayHours')}</td>
          <td>{t('workerstable.nightHours')}</td>
        </Fragment>
      );
    }
    const theads = [];
    for (let i = 0; i < listSize; i += 1) {
      const monthNum = month.from + i > 12 ? month.from + i - 12 : month.from + i;
      theads.push(<td>{`${t('workerstable.dayHours')} (${monthNum}.)`}</td>);
      theads.push(<td>{`${t('workerstable.nightHours')} (${monthNum}.)`}</td>);
    }
    return theads;
  };

  render() {
    const { t } = this.props;
    const { loading, data, dateError } = this.state;
    if (!data) return '';
    return (
      <Card>
        <CardBody>
          <div className="card__title">
            <h5 className="bold-text">{t('calcs.orderers')}</h5>
            <h5 style={{ color: 'coral' }}>
              {t('workerstable.sum')}: <strong>{commify(fix(data.totalSum))}</strong>
            </h5>
            <ButtonToolbar style={{ position: 'relative', float: 'right', marginBottom: '40px' }}>
              {this.renderDropdown()}
            </ButtonToolbar>
            {dateError && (
              <div style={{ position: 'absolute', right: 0, top: '90px' }}>
                <strong style={{ color: 'red' }}>{dateError}</strong>
              </div>
            )}
          </div>
          {loading ? (
            <ReactLoading type="bars" color="gray" height={128} width={128} className="react-loader" />
          ) : (
            <Table className="izpis-table" responsive>
              <thead>
                <tr>
                  <td>{t('form.company')}</td>
                  <td>{t('board.ow.worker')}</td>
                  <td>{t('board.ow.orderer')}</td>
                  <td>{t('board.filters.worksite')}</td>
                  {this.renderTheads()}
                  <td>{t('workerstable.sum')}</td>
                </tr>
              </thead>
              <tbody>{this.renderTableBody()}</tbody>
            </Table>
          )}
        </CardBody>
      </Card>
    );
  }
}

Worklist.propTypes = {
  month: PropTypes.number.isRequired,
  year: PropTypes.number.isRequired,
  data: PropTypes.object.isRequired,
  selectedCompany: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
  onWorklistDataChange: PropTypes.func.isRequired,
};
export default translate('common')(Worklist);
