/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import { Button, Modal } from 'reactstrap';
import ReactGA from 'react-ga';
import classNames from 'classnames';
import AddIcon from 'mdi-react/AccountMultiplePlusOutlineIcon';
import EditIcon from 'mdi-react/AccountEditIcon';
import GradbiscaForm from './GradbiscaForm';
import { API_LINK } from '../../../constants/config';
import { POST } from '../../../helpers/agent';
import trimmify from '../../../helpers/functions';

const API_ADD_WORKSITE = '/w/add';
const API_EDIT_WORKSITE = '/w/update';
const API_DELETE_WORKSITE = '/w/delete';

class GradbiscaModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
    };

    this.toggle = this.toggle.bind(this);
    this.cancel = this.cancel.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleESC = this.handleESC.bind(this);
  }
  componentDidMount() {
    document.addEventListener('keydown', this.handleESC, false);
  }
  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleESC, false);
  }

  handleESC(event) {
    if (event.keyCode === 27) {
      this.setState({ modal: false });
      this.cancel(null);
    }
  }

  toggle() {
    if (!this.state.modal) {
      ReactGA.event({
        category: 'Gradbišča',
        action: `Odprtje modala za Gradbišča - ${this.props.editData ? 'Urejanje' : 'Dodajanje'}`,
      });
    }
    this.setState({
      modal: !this.state.modal,
    });
  }

  cancel(data) {
    this.setState({
      modal: false,
    });
    this.props.onClose(data);
  }

  async handleDelete(data) {
    const successMessage = `Gradbišče ${data.title} uspešno izbrisano!`;
    const errorMessage = `Gradbišče ${data.title} NI uspešno izbrisano!`;
    //const that = this;
    try {
      //console.log('zdaj bi izbrisal', data);
      const dataRes = await POST(API_LINK + API_DELETE_WORKSITE, JSON.stringify({ _id: data._id }));
      if (dataRes.success) {
        //console.log('Request success: ', data);
        this.cancel({ title: 'Izbris gradbišča', message: successMessage, success: true });
      } else {
        //console.log('Request success: ', data);
        //this.showNotif('error', isEdit, values.name, false);
        this.cancel({ title: 'Izbris gradbišča', message: errorMessage, success: false });
      }
    } catch (error) {
      console.log(error);
      this.cancel(null);
    }
  }

  async handleSubmit(values) {
    //event.preventDefault();
    const { icon } = this.props;
    const isEdit = icon !== 'add';
    const apiLink = isEdit ? API_EDIT_WORKSITE : API_ADD_WORKSITE;
    try {
      //const that = this;
      console.log(icon + ' gradbišče', values);
      const body = JSON.parse(JSON.stringify(values));
      body.countryId = values.countryId ? values.countryId.value : '';
      body.accommodations = [];
      if (values.accommodations) {
        const acmList = [];
        for (const acm of values.accommodations) {
          const acmID = acm.value || acm;
          acmList.push(acmID);
        }
        body.accommodations = acmList;
      }

      //body.ordererId = values.ordererId ? values.ordererId.value : '';
      const notifTitle = isEdit ? 'Uredi gradbišče' : 'Dodaj gradbišče';
      const successMessage = isEdit
        ? `Gradbišče ${values.title} uspešno posodobljeno!`
        : `Gradbišče ${values.title} uspešno dodano!`;
      const errorMessage = isEdit
        ? `Gradbišče ${values.title} ni uspešno posodobljeno!`
        : `Gradbišče ${values.title} ni uspešno dodano!`;

      //console.log('ORIGINAL BODY:', body);
      const data = await POST(API_LINK + apiLink, JSON.stringify(trimmify(body)));
      if (data.success) {
        //console.log('Request success: ', data);
        this.cancel({ title: notifTitle, message: successMessage, success: true });
      } else {
        //console.log('Request success: ', data);
        //this.showNotif('error', isEdit, values.name, false);
        this.cancel({ title: notifTitle, message: errorMessage, success: false });
      }
    } catch (error) {
      console.log(error);
      this.cancel(null);
    }
  }

  render() {
    const { icon, btn, title, colored, header, hasBtn, show, editData, countries, accommodations } = this.props;
    let Icon;

    switch (icon) {
      case 'add':
        Icon = <AddIcon />;
        break;
      case 'edit':
        Icon = <EditIcon />;
        break;
      default:
        break;
    }
    const modalClass = classNames({
      'modal-dialog--colored': colored,
      'modal-dialog--header': header,
    });

    return (
      <div style={{ width: '100%' }}>
        {hasBtn ? (
          <Button size="sm" color="primary" onClick={this.toggle}>
            {btn}
          </Button>
        ) : null}

        <Modal isOpen={this.state.modal || show} toggle={this.toggle} className={`modal-dialog--primary ${modalClass}`}>
          <div className="modal__header">
            <button className="lnr lnr-cross modal__close-btn" onClick={this.toggle} />
            {header ? '' : Icon}
            <h4 className="bold-text  modal__title">{title}</h4>
          </div>
          <div className="modal__body">
            <div className="theme-light">
              {editData ? (
                <GradbiscaForm
                  onSubmit={this.handleSubmit}
                  countries={countries}
                  accommodations={accommodations}
                  handleCancel={() => this.cancel(null)}
                  handleDelete={this.handleDelete}
                  initialValues={editData}
                  editData={editData}
                />
              ) : (
                <GradbiscaForm
                  onSubmit={this.handleSubmit}
                  countries={countries}
                  accommodations={accommodations}
                  //orderers={orderers}
                  handleCancel={() => this.cancel(null)}
                  handleDelete={this.handleDelete}
                />
              )}
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

GradbiscaModal.propTypes = {
  title: PropTypes.string,
  countries: PropTypes.arrayOf(PropTypes.object).isRequired,
  accommodations: PropTypes.arrayOf(PropTypes.object).isRequired,
  icon: PropTypes.string.isRequired,
  colored: PropTypes.bool,
  header: PropTypes.bool,
  btn: PropTypes.string.isRequired,
  hasBtn: PropTypes.bool.isRequired,
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  editData: PropTypes.object,
};

GradbiscaModal.defaultProps = {
  title: '',
  colored: false,
  header: false,
};

export default GradbiscaModal;
