import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import ReactLoading from 'react-loading';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import TopbarSidebarButton from './TopbarSidebarButton';
import TopbarProfile from './TopbarProfile';
import { AppStatusProps } from '../../../shared/prop-types/ReducerProps';
import TopbarLanguage from './TopbarLanguage';

class Topbar extends PureComponent {
  static propTypes = {
    changeMobileSidebarVisibility: PropTypes.func.isRequired,
    changeSidebarVisibility: PropTypes.func.isRequired,
    appStatus: AppStatusProps.isRequired,
  };

  render() {
    const {
      changeMobileSidebarVisibility,
      changeSidebarVisibility,
      appStatus: { appUpdating, appUpdatingStatus },
    } = this.props;

    return (
      <div className="topbar">
        <div className="topbar__wrapper">
          <div className="topbar__left">
            <TopbarSidebarButton
              changeMobileSidebarVisibility={changeMobileSidebarVisibility}
              changeSidebarVisibility={changeSidebarVisibility}
            />
            <Link className="topbar__logo" to="/" />
          </div>
          {appUpdating && (
            <div
              style={{
                position: 'relative',
                width: '100%',
                paddingTop: '10px',
              }}
            >
              <ReactLoading type="spin" height={16} width={16} color="lightgray" className="react-loader" />
              <div
                style={{
                  color: 'lightgray',
                  margin: '0 auto',
                  textAlign: 'center',
                  textTransform: 'uppercase',
                }}
              >
                <strong>{appUpdatingStatus}</strong>
              </div>
            </div>
          )}
          <div className="topbar__right">
            <TopbarProfile />
            <TopbarLanguage />
          </div>
        </div>
      </div>
    );
  }
}

export default connect(state => ({
  appStatus: state.appStatus,
}))(Topbar);
