import React from 'react';
import { Card, CardBody, Col, ButtonToolbar, Button } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import renderSelectField from '../../../shared/components/form/Select';
import Modal from '../../../shared/components/Modal';
import validate from './validate';
import renderTextField from '../../../shared/components/form/TextField';
import renderChromeColorPickerField from '../../../shared/components/form/ChromeColorPicker';
import renderCheckBoxField from '../../../shared/components/form/CheckBox';

const PodjetjaForm = ({ handleSubmit, handleCancel, handleDelete, countries, editData, pristine }) => (
  <Col md={12} lg={12}>
    <Card>
      <CardBody>
        <form className="form" onSubmit={handleSubmit}>
          <div className="form__half">
            <div className="form__form-group">
              <span className="form__form-group-label">Naziv</span>
              <div className="form__form-group-field">
                <Field name="name" component={renderTextField} type="text" />
              </div>
            </div>
          </div>

          <div className="form__half">
            <div className="form__form-group">
              <span className="form__form-group-label">Zastopnik</span>
              <div className="form__form-group-field">
                <Field name="agent" component={renderTextField} type="text" />
              </div>
            </div>
          </div>

          <div className="form__half">
            <div className="form__form-group">
              <span className="form__form-group-label">Naslov</span>
              <div className="form__form-group-field">
                <Field name="address" component={renderTextField} type="text" />
              </div>
            </div>

            <div className="form__form-group">
              <span className="form__form-group-label">Kraj</span>
              <div className="form__form-group-field">
                <Field name="city" component={renderTextField} type="text" />
              </div>
            </div>
          </div>

          <div className="form__half" style={{ marginLeft: '30px' }}>
            <div className="form__form-group">
              <span className="form__form-group-label">Poštna številka</span>
              <div className="form__form-group-field">
                <Field name="zip" component={renderTextField} type="text" />
              </div>
            </div>

            <div className="form__form-group">
              <span className="form__form-group-label">Država</span>
              <div className="form__form-group-field">
                <Field name="countryId" component={renderSelectField} options={countries} />
              </div>
            </div>
          </div>

          <div className="form__half">
            <div className="form__form-group">
              <span className="form__form-group-label">Davčna številka</span>
              <div className="form__form-group-field">
                <Field name="taxID" component={renderTextField} type="text" />
              </div>
            </div>

            <div className="form__form-group">
              <span className="form__form-group-label">&nbsp;</span>
              <div className="form__form-group-field">
                <Field name="coop" component={renderCheckBoxField} label="Kooperant" className="colored" />
              </div>
            </div>
          </div>

          <div className="form__half" style={{ marginLeft: '30px' }}>
            <div className="form__form-group">
              <span className="form__form-group-label">Matična številka</span>
              <div className="form__form-group-field">
                <Field name="registrationNum" component={renderTextField} type="text" />
              </div>
            </div>

            <div className="form__form-group">
              <span className="form__form-group-label">Barva</span>
              <div className="form__form-group-field">
                <Field name="color" component={renderChromeColorPickerField} />
              </div>
            </div>
          </div>

          <ButtonToolbar className="form__button-toolbar">
            {editData ? (
              <Modal
                color="danger"
                title="Pozor!"
                colored
                btn="Izbris"
                message={`Ste prepričani da želite izbrisati podjetje z nazivom ${editData.name}?`}
                onConfirm={() => handleDelete(editData)}
              />
            ) : null}
            <Button type="button" onClick={handleCancel}>
              Prekliči
            </Button>
            <Button color="primary" type="submit" disabled={pristine}>
              {editData ? 'Shrani spremembe' : 'Dodaj'}
            </Button>
          </ButtonToolbar>
        </form>
      </CardBody>
    </Card>
  </Col>
);

PodjetjaForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
  countries: PropTypes.arrayOf(PropTypes.object).isRequired,
  editData: PropTypes.object,
  pristine: PropTypes.bool.isRequired,
};

PodjetjaForm.defaultProps = {
  editData: null,
};

export default reduxForm({
  form: 'podjetja_form', // a unique identifier for this form
  initialValues: { color: { hex: '#07bbfd' } },
  validate,
})(PodjetjaForm);
