import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-i18next';
import getDaysInMonth from 'helpers/getDaysInMonth';
import { Table, Card, CardBody, Badge, UncontrolledTooltip } from 'reactstrap';
import moment from 'moment-timezone';
import shortid from 'shortid';
import Switch from 'react-toggle-switch';
import ReactLoading from 'react-loading';
import { TIMEZONE } from '../../../../../constants/config';
import fix, { commify } from '../../../../../helpers/floatParser';

const isWeekend = day => {
  const dayNum = new Date(day).getDay();
  return dayNum === 6 || dayNum === 0;
};

class SingleOWTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // show day + nigh hours
      switched: true,
    };
  }

  toggleSwitch = () => {
    this.setState(prevState => ({
      switched: !prevState.switched,
    }));
  };

  generateMonthHeads(month, year) {
    const daysInMonths = getDaysInMonth(month, year);
    const { tag } = this.props;
    return daysInMonths.map(day => {
      if (tag === 'normal') {
        return (
          <td key={day.getTime().toString()}>
            <p>
              {moment(day)
                .tz(TIMEZONE)
                .format('ddd')}
            </p>
            <p>
              {moment(day)
                .tz(TIMEZONE)
                .format('D.M.')}
            </p>
          </td>
        );
      }
      return (
        <td key={day.getTime().toString()}>
          <p>/</p>
          <p>/</p>
        </td>
      );
    });
  }

  generateColMonthHeads(month, year) {
    const daysInMonths = getDaysInMonth(month, year);
    return daysInMonths.map(day => {
      const weekend = isWeekend(day);
      return <col className={weekend ? 'weekend-col-table' : ''} />;
    });
  }

  calcTotals(workdays, transfers) {
    let totalDay = {};
    let totalNight = {};
    /* et totalTransferDay = {}
    let totalTransferNight = {} */
    try {
      totalDay = workdays.reduce((a, b) => ({
        dayHours: a.dayHours + b.dayHours,
      }));
      totalNight = workdays.reduce((a, b) => ({
        nightHours: a.nightHours + b.nightHours,
      }));
    } catch (e) {
      totalDay.dayHours = 0;
      totalNight.nightHours = 0;
    }

    return {
      totalDay: fix(totalDay.dayHours),
      totalNight: fix(totalNight.nightHours),
      totalTransferDay: fix(transfers.daySum),
      totalTransferNight: fix(transfers.nightSum),
      total: fix(totalDay.dayHours + totalNight.nightHours + transfers.daySum + transfers.nightSum),
    };
  }

  renderWorkdays(workdays, lastRowClass = '') {
    const { month, year } = this.props;
    const { switched } = this.state;
    const daysInMonths = getDaysInMonth(month, year);
    return daysInMonths.map(day => {
      let workday = workdays.find(w => w.date === day.getTime());

      if (!workday) {
        workday = {
          dayHours: 0,
          nightHours: 0,
        };
      }

      const theHours = !switched
        ? `${fix(workday.dayHours)}/${fix(workday.nightHours)}`
        : `${fix(workday.dayHours) + fix(workday.nightHours)}`;

      return (
        <td key={shortid.generate()}>
          {workday.dayHours !== 0 || workday.nightHours !== 0 ? (
            <strong className={lastRowClass} style={{ color: 'black' }}>
              {commify(theHours)}
            </strong>
          ) : (
            <span className={lastRowClass} style={{ color: 'lightgray' }}>
              {!switched ? '0/0' : '0'}
            </span>
          )}
        </td>
      );
      // if (workday) {
      //   /* hoursString = fix(workday.dayHours);
      //   if (workday.nightHours !== 0) {
      //     hoursString += `/${fix(workday.nightHours)}`;
      //   } */
      //   /* const theHours = !switched
      //   ? `${fix(workday.daySum)}/${fix(workday.nightSum)}`
      //   : `${fix(workday.daySum) + fix(workday.nightSum)}`; */
      // }

      // if (hoursString !== 0) {
      //   return (
      //     <td key={shortid.generate()}>
      //       <strong style={{ color: 'black' }}>{!switched ? '0/0' : '0'}</strong>
      //     </td>
      //   );
      // }

      // return (
      //   <td key={shortid.generate()}>
      //     <span style={{ color: 'lightgray' }}>{hoursString}</span>
      //   </td>
      // );
    });
  }

  renderTable(list) {
    const { showCompanyName } = this.props;
    return list.map((worker, index) => {
      const lastRowClass = index === list.length - 1 ? 'table-ow-smaller-row' : '';

      const id = shortid.generate();
      // console.log(`calkuliram za ${worker.label} ${worker.workdays}`);
      const totals = this.calcTotals(worker.workdays, worker.transfers);
      return (
        <tr key={id} id={id}>
          <td style={{ minWidth: '120px' }}>
            <Badge
              style={{
                backgroundColor: `${worker.color}`,
                color: worker.color === '#4CAFEA' ? 'white' : '#646777',
                fontSize: '10px',
                padding: '4px',
              }}
            >
              <strong>{worker.label}</strong>
            </Badge>
            {showCompanyName && <br />}
            {showCompanyName && <span className="table--company-small">{worker.fullCompanyName}</span>}
          </td>
          {this.renderWorkdays(worker.workdays, lastRowClass)}
          <td>
            <strong className={lastRowClass} style={{ color: 'black' }}>
              {commify(totals.totalDay)}
            </strong>
          </td>
          <td>
            <strong className={lastRowClass} style={{ color: 'black' }}>
              {commify(totals.totalNight)}
            </strong>
          </td>
          <td>
            <strong className={lastRowClass} style={{ color: 'black' }}>
              {commify(totals.totalTransferDay)}
            </strong>
          </td>
          <td>
            <strong className={lastRowClass} style={{ color: 'black' }}>
              {commify(totals.totalTransferNight)}
            </strong>
          </td>
          <td>
            <strong className={lastRowClass} style={{ color: 'black' }}>
              {commify(totals.total)}
            </strong>
          </td>
        </tr>
      );
    });
  }

  render() {
    const { data, month, year, title, subtitle, t, tag } = this.props;
    const th = tag === 'normal' ? t('board.ow.worker') : '/';
    /* eslint-disable */
    const tableRender = [
      <Switch key={shortid.generate()} className="night-switch" onClick={this.toggleSwitch} on={this.state.switched} />,
      <div key={shortid.generate()} className="switchToolTip" id="SwitchToolTipModal" onClick={this.toggleSwitch} />,
      <UncontrolledTooltip key={shortid.generate()} placement="top" target="SwitchToolTipModal">
        {this.state.switched ? 'Prikazujem samo dnevne ure' : 'Prikazujem dnevne in nočne ure'}
      </UncontrolledTooltip>,
      <Table key={shortid.generate()} className="table--bordered custom-style small-pad" striped>
        <colgroup>
          <col />
          {this.generateColMonthHeads(month, year)}
        </colgroup>
        <thead>
          <tr>
            <th>{th}</th>
            {this.generateMonthHeads(month, year)}
            <td>
              {t('calcs.sum')} ({t('calcs.d')})
            </td>
            <td>
              {t('calcs.sum')} ({t('calcs.n')})
            </td>
            <td>
              {t('calcs.sum')} ({t('calcs.p')}/{t('calcs.d')})
            </td>
            <td>
              {t('calcs.sum')} ({t('calcs.p')}/{t('calcs.n')})
            </td>
            <td>{t('calcs.sum')}</td>
          </tr>
        </thead>
        <tbody>{this.renderTable(data)}</tbody>
      </Table>,
    ];
    /* eslint-enable */
    return (
      <Card style={{ paddingBottom: '0px' }} className="printable-table">
        <CardBody className="single-ow">
          <div className="card__title printable-title">
            <h5 className="bold-text">{title}</h5>
            <h5 className="subhead">{subtitle}</h5>
          </div>
          {data.length > 0 ? (
            tableRender
          ) : (
            <ReactLoading type="bars" color="gray" height={128} width={128} className="react-loader" />
          )}
        </CardBody>
      </Card>
    );
  }
}

SingleOWTable.propTypes = {
  data: PropTypes.array.isRequired,
  month: PropTypes.number.isRequired,
  year: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  showCompanyName: PropTypes.bool,
  t: PropTypes.func.isRequired,
  tag: PropTypes.string.isRequired,
};

SingleOWTable.defaultProps = {
  showCompanyName: false,
};

export default translate('common')(SingleOWTable);
