import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'reactstrap';
import { connect } from 'react-redux';
import employeesApi from 'api/employees';
import classNames from 'classnames';
import AddIcon from 'mdi-react/AccountMultiplePlusOutlineIcon';
import EditIcon from 'mdi-react/AccountEditIcon';
import { EmployeesProps } from 'shared/prop-types/ReducerProps';
import DopustiForm from '../../../Spremembe/components/Dopusti/DopustiForm';

class LeavesFormModal extends React.PureComponent {
  constructor(props) {
    super(props);

    this.cancel = this.cancel.bind(this);

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleESC = this.handleESC.bind(this);
  }

  componentDidMount() {
    document.addEventListener('keydown', this.handleESC, false);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleESC, false);
  }

  handleESC(event) {
    if (event.keyCode === 27) {
      this.props.onClose();
      this.cancel(null);
    }
  }

  cancel() {
    this.props.onClose();
  }

  async handleSubmit(values) {
    try {
      console.log('TCL: LeavesFormModal -> handleSubmit -> values', values);
      const success = await employeesApi.handleLeaves(values);
      if (success) {
        this.cancel({
          title: 'Urejanje dopustov',
          message: 'Sprememba dopustov uspešno izvedena!',
          success: true,
        });
      } else {
        this.cancel({
          title: 'Urejanje dopustov',
          message: 'Sprememba dopustov NI uspešno izvedena!',
          success: false,
        });
      }
    } catch (error) {
      console.log(error);
      this.cancel(null);
    }
  }

  render() {
    const {
      icon,
      title,
      colored,
      header,
      show,
      employeesStore: { employeesNames },
    } = this.props;
    let Icon;

    switch (icon) {
      case 'add':
        Icon = <AddIcon />;
        break;
      case 'edit':
        Icon = <EditIcon />;
        break;
      default:
        break;
    }
    const modalClass = classNames({
      'modal-dialog--colored': colored,
      'modal-dialog--header': header,
    });

    return (
      <div className="w-100 text-right">
        <Modal isOpen={show} toggle={this.toggle} className={`modal-dialog--primary ${modalClass}`}>
          <div className="modal__header">
            <button className="lnr lnr-cross modal__close-btn" onClick={this.toggle} />
            {header ? '' : Icon}
            <h4 className="bold-text  modal__title">{title}</h4>
          </div>
          <div className="modal__body">
            <div className="theme-light">
              <DopustiForm
                onSubmit={this.handleSubmit}
                workers={employeesNames}
                handleCancel={() => this.cancel(null)}
              />
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

LeavesFormModal.propTypes = {
  title: PropTypes.string,
  employeesStore: EmployeesProps.isRequired,
  icon: PropTypes.string.isRequired,
  colored: PropTypes.bool,
  header: PropTypes.bool,
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

LeavesFormModal.defaultProps = {
  title: '',
  colored: false,
  header: false,
};

export default connect(state => ({
  employeesStore: state.employees,
}))(LeavesFormModal);
