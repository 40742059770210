import React from 'react';
import { Field, reduxForm } from 'redux-form';
import CalendarBlankIcon from 'mdi-react/CalendarBlankIcon';

import PropTypes from 'prop-types';
import renderDatePickerField from './form/DatePicker';

const SelectDate = ({ handleSubmit, title }) => (
  <form className="form" onSubmit={handleSubmit}>
    <div className="form__form-group">
      <span className="form__form-group-label">{title}</span>
      <div className="form__form-group-field">
        <Field name="date" component={renderDatePickerField} />
        <div className="form__form-group-icon">
          <CalendarBlankIcon />
        </div>
      </div>
    </div>
  </form>
);

SelectDate.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
};

export default reduxForm({
  form: 'select_date',
  onChange: (values, dispatch, props) => {
    props.submit();
  },
  initialValues: { date: new Date(Date.now()) },
})(SelectDate);
