import React, { PureComponent } from 'react';
import { Collapse } from 'reactstrap';
import PropTypes from 'prop-types';
import MinusIcon from 'mdi-react/MinusIcon';
import PlusIcon from 'mdi-react/PlusIcon';
import ChevronDownIcon from 'mdi-react/ChevronDownIcon';

export default class CollapseComponent extends PureComponent {
  static propTypes = {
    title: PropTypes.string,
    className: PropTypes.string,
    children: PropTypes.element.isRequired,
    style: PropTypes.object,
    defaultStatus: PropTypes.string,
    defaultCollapse: PropTypes.bool,
  };

  static defaultProps = {
    title: '',
    className: '',
    style: {},
    defaultStatus: 'closed',
    defaultCollapse: false,
  };

  constructor(props) {
    super(props);
    this.state = {
      collapse: props.defaultCollapse,
      status: props.defaultStatus,
      icon: <PlusIcon />,
    };
  }

  onEntering = () => {
    this.setState({ status: 'opening', icon: <MinusIcon /> });
  };

  onEntered = () => {
    this.setState({ status: 'opened', icon: <MinusIcon /> });
  };

  onExiting = () => {
    this.setState({ status: 'closing', icon: <PlusIcon /> });
  };

  onExited = () => {
    this.setState({ status: 'closed', icon: <PlusIcon /> });
  };

  toggle = e => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({ collapse: !this.state.collapse });
  };

  render() {
    const { className, title, children, style } = this.props;

    return (
      <div className={`collapse__wrapper ${this.state.status} ${className}`} style={style}>
        <button onClick={this.toggle} className="collapse__title">
          {this.state.icon}
          <p>
            {title}
            <ChevronDownIcon />
          </p>
        </button>
        <Collapse
          isOpen={this.state.collapse}
          className="collapse__content"
          onEntering={this.onEntering}
          onEntered={this.onEntered}
          onExiting={this.onExiting}
          onExited={this.onExited}
        >
          <div>{children}</div>
        </Collapse>
      </div>
    );
  }
}
