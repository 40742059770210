import React from 'react';
import PropTypes from 'prop-types';
import ReactGA from 'react-ga';
import classNames from 'classnames';
import { Modal } from 'reactstrap';
import AddIcon from 'mdi-react/AccountMultiplePlusOutlineIcon';
import EditIcon from 'mdi-react/AccountEditIcon';
import { translate } from 'react-i18next';

import { API_LINK } from 'constants/config';
import { POST } from 'helpers/agent';
import SpremembeDeassignForm from '../../../Spremembe/components/SpremembeDeassignForm';

const API_EDIT_BOARD_STATE = '/b/updateState';

class DeassignModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { modal: false };

    this.toggle = this.toggle.bind(this);
    this.cancel = this.cancel.bind(this);

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleESC = this.handleESC.bind(this);
  }

  componentDidMount() {
    document.addEventListener('keydown', this.handleESC, false);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleESC, false);
  }

  handleESC(event) {
    if (event.keyCode === 27) {
      this.setState({ modal: false });
      this.cancel(null);
    }
  }

  toggle(e) {
    e.stopPropagation();
    if (!this.state.modal) {
      ReactGA.event({
        category: 'Spremembe',
        action: `Odprtje modala Zaključi - ${this.props.editData ? 'Urejanje' : 'Dodajanje'}`,
      });
    }
    this.setState({
      modal: !this.state.modal,
    });
  }

  cancel(data) {
    this.setState({
      modal: false,
    });
    this.props.onClose(data);
  }

  async handleSubmit(values) {
    try {
      const { vehicle, accommodation, orderer, worksite, date, additional } = values;
      console.log('TCL: DeassignModal -> handleSubmit -> values', values);

      const body = JSON.stringify({
        workerId: additional.workerId,
        ordererId: orderer ? { value: null, label: null } : additional.ordererId,
        worksiteId: worksite ? { value: null, label: null } : additional.worksiteId,
        vehicleId: vehicle ? { value: null, label: null } : additional.vehicleId,
        accommodationId: accommodation ? { value: null, label: null } : additional.accommodationId,
        leaves: additional.leaves,
        date,
      });
      const { t } = this.props;
      console.log('body', body);
      const data = await POST(API_LINK + API_EDIT_BOARD_STATE, body);
      if (data.success) {
        this.cancel({
          title: t('messages.changes.title'),
          message: t('messages.changes.success'),
          success: true,
        });
      } else {
        this.cancel({
          title: t('messages.changes.title'),
          message: t('messages.changes.error'),
          success: false,
        });
      }
    } catch (error) {
      console.log(error);
      this.cancel(null);
    }
  }

  render() {
    const { icon, title, colored, header, show, editData } = this.props;
    let Icon;

    switch (icon) {
      case 'add':
        Icon = <AddIcon />;
        break;
      case 'edit':
        Icon = <EditIcon />;
        break;
      default:
        break;
    }
    const modalClass = classNames({
      'modal-dialog--colored': colored,
      'modal-dialog--header': header,
    });

    return (
      <div style={{ width: '100%' }}>
        <Modal isOpen={this.state.modal || show} toggle={this.toggle} className={`modal-dialog--primary ${modalClass}`}>
          <div className="modal__header">
            <button className="lnr lnr-cross modal__close-btn" onClick={this.toggle} />
            {header ? '' : Icon}
            <h4 className="bold-text  modal__title">{title}</h4>
          </div>
          <div className="modal__body">
            <div className="theme-light">
              <SpremembeDeassignForm
                onSubmit={this.handleSubmit}
                handleCancel={() => this.cancel(null)}
                initialValues={editData}
                editData={editData}
              />
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

DeassignModal.propTypes = {
  title: PropTypes.string,
  icon: PropTypes.string.isRequired,
  colored: PropTypes.bool,
  header: PropTypes.bool,
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  editData: PropTypes.object,
  t: PropTypes.func.isRequired,
};

DeassignModal.defaultProps = {
  title: '',
  colored: false,
  header: false,
  editData: null,
};

export default translate('common')(DeassignModal);
