import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { ButtonToolbar, ButtonGroup, Button } from 'reactstrap';
import SelectAnyWeekModal from './AnyWeek/SelectAnyWeekModal';
import { TIMEZONE } from '../../../constants/config';

const getCurrentWeekNumber = () => moment(new Date(Date.now())).isoWeek();
const getSelectedWeekNumber = date => moment(date).isoWeek();
const minusday = date =>
  moment(date)
    .subtract(1, 'day')
    .toDate();

const getAddedWeeksDate = plusWeeks =>
  moment(new Date(Date.now()))
    .isoWeekday(1)
    .add(plusWeeks, 'week')
    .toDate();

const weekAndYearToDate = (week, year) =>
  moment()
    .year(Number(year))
    .week(Number(week))
    .day('Monday')
    .startOf('day')
    .tz(TIMEZONE)
    .toDate();

class SelectWeek extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      selectedWeekNumber: getCurrentWeekNumber(),
      currentScrollPosition: 0,
      openModal: false,
      // selectedWeekDate: getAddedWeeksDate(0),
    };
    this.moveWeek = this.moveWeek.bind(this);
    this.showModal = this.showModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  componentDidMount() {
    this.props.onSelectWeek(getAddedWeeksDate(0));
    this.props.onSelectWeek({
      date: getAddedWeeksDate(0),
      kw: getCurrentWeekNumber(),
      toDate: minusday(getAddedWeeksDate(1)),
    });
  }

  moveWeek(forward) {
    let currScroll = this.state.currentScrollPosition;
    if (forward) currScroll += 1;
    else currScroll -= 1;

    // console.log('CURRENT scroll', currScroll);
    const chosenWeek = getAddedWeeksDate(currScroll);

    // console.log('chosenWeek', chosenWeek);

    const selWeekNum = getSelectedWeekNumber(chosenWeek);

    this.setState({
      currentScrollPosition: currScroll,
      selectedWeekNumber: selWeekNum,
      // selectedWeekDate: chosenWeek,
    });

    this.props.onSelectWeek({
      date: chosenWeek,
      kw: selWeekNum,
      toDate: minusday(getAddedWeeksDate(currScroll + 1)),
    });
  }

  showModal() {
    this.setState({ openModal: true });
  }

  closeModal(data) {
    if (data) {
      if (data.kw && data.year) {
        const date = weekAndYearToDate(data.kw, data.year);

        const toDate = moment(date)
          .add(1, 'week')
          .subtract(1, 'day')
          .tz(TIMEZONE)
          .toDate();

        this.setState({ selectedWeekNumber: data.kw });
        this.props.onSelectWeek({ date, kw: Number(data.kw), toDate });
      }
    }
    this.setState({ openModal: false });
  }

  render() {
    const { selectedWeekNumber, openModal } = this.state;
    const { disabled } = this.props;
    return (
      <ButtonToolbar>
        <ButtonGroup className="btn-group--justified">
          <Button color="primary" disabled={disabled} onClick={() => this.moveWeek(false)}>
            <span className="lnr lnr-arrow-left" style={{ fontWeight: 'bold' }} />
          </Button>
          <Button onClick={this.showModal} style={{ color: '#333333', fontWeight: 'bold' }}>
            {`KW ${selectedWeekNumber}`}
          </Button>
          <Button color="primary" disabled={disabled} onClick={() => this.moveWeek(true)}>
            <span className="lnr lnr-arrow-right" style={{ fontWeight: 'bold' }} />
          </Button>
          <SelectAnyWeekModal
            btn=""
            icon="edit"
            title=""
            hasBtn={false}
            show={openModal}
            onClose={this.closeModal}
            month={1}
            year={2019}
          />
        </ButtonGroup>
      </ButtonToolbar>
    );
  }
}

SelectWeek.propTypes = {
  onSelectWeek: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
};

export default SelectWeek;
