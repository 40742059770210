import React, { Component } from 'react';
import { Card, CardBody, Table } from 'reactstrap';
import PropTypes from 'prop-types';

export default class FixHoursTable extends Component {
  static propTypes = {
    workdays: PropTypes.arrayOf(PropTypes.object),
    onRowSelect: PropTypes.func.isRequired,
  };

  static defaultProps = {
    workdays: [],
  };

  renderBody() {
    const { workdays, onRowSelect } = this.props;
    const reduced = workdays.reduce((res, day) => {
      if (!day.additionalId) {
        const tr = (
          <tr key={day._id} onClick={() => onRowSelect(day._id)}>
            <td>{day.date}</td>
            <td>{day.hours}</td>
            <td>{day.nightHours}</td>
          </tr>
        );
        res.push(tr);
      }
      return res;
    }, []);
    return reduced;
  }

  render() {
    return (
      <Card>
        <CardBody>
          <Table hover className="table--bordered" responsive>
            <thead>
              <tr>
                <th>Datum</th>
                <th>Dnevne Ure</th>
                <th>Nočne ure</th>
              </tr>
            </thead>
            <tbody>{this.renderBody()}</tbody>
          </Table>
        </CardBody>
      </Card>
    );
  }
}
