import React, { Fragment } from 'react';
import { Card, CardBody, Col, Progress } from 'reactstrap';
import CashMultipleIcon from 'mdi-react/CashMultipleIcon';
import { PropTypes } from 'prop-types';
import fix from '../../../../../helpers/floatParser';

const renderCalcs = calcs => {
  return calcs.map(calc => {
    const color = calc.total >= 0 ? { color: 'green', fontSize: 'large' } : { color: 'red', fontSize: 'large' };
    return (
      <Fragment key={`${calc.key}${calc.key}tot`}>
        {/* <h5 key={calc.key} className="dashboard__booking-total-description">
          {`${calc.title}`}
        </h5> */}
        <h5 key={`${calc.key}tot`} style={{ textAlign: 'right' }} className="dashboard__booking-total-description">
          <span style={color}>{`${fix(calc.total)}€`}</span>
        </h5>
        <div className="small-line" />
      </Fragment>
    );
  });
};

const CalcCard = ({ calcs, title }) => {
  //const color = balance >= 0 ? 'blue' : 'red';
  return (
    <Col md={8} xl={8} lg={8} xs={8}>
      <Card>
        <CardBody className="dashboard__booking-card">
          {/* <div className="dashboard__booking-total-container">
            <h5 className={`dashboard__booking-total-title dashboard__booking-total-title--${'blue'}`}>0€</h5>
            <CashMultipleIcon className="dashboard__trend-icon" />
          </div> */}
          <h5>
            <strong>{title}</strong>
          </h5>
          <br />
          {renderCalcs(calcs)}
        </CardBody>
      </Card>
    </Col>
  );
};

CalcCard.propTypes = {
  calcs: PropTypes.arrayOf(PropTypes.object).isRequired,
  title: PropTypes.string.isRequired,
};

CalcCard.defaultProps = {};

export default CalcCard;
