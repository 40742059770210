import { API_LINK } from 'constants/config';
import { GET, POST } from 'helpers/agent';

export default {
  getnames: async () => {
    const data = await GET(`${API_LINK}/e/getnames`);
    if (data.success) {
      return { employeeTitles: data.employees };
    }
    return { employeeTitles: [] };
  },
  getNamesForWorksites: async data => {
    const response = await POST(`${API_LINK}/e/getNamesForWorksites`, JSON.stringify(data));
    if (response.success) {
      return { employeeTitles: response.employees };
    }
    return { employeeTitles: [] };
  },
  getall: async () => {
    const data = await GET(`${API_LINK}/e/getall`);
    if (data.success) {
      return { employees: data.employees };
    }
    return { employees: [] };
  },
  handleLeaves: async data => {
    const response = await POST(`${API_LINK}/e/handleLeaves`, JSON.stringify(data));
    return response.success;
  },
  getWorkerLeaves: async data => {
    const response = await POST(`${API_LINK}/e/getWorkersLeaves`, JSON.stringify(data));

    if (response.success) {
      return { leaves: response.leaves };
    }
    return { leaves: [] };
  },
  getOne: async body => {
    const response = await POST(`${API_LINK}/e/getOneById`, body);
    return response.employee;
  },
  getCurrentState: async body => {
    console.log('TCL: getCurrentState');
    const response = await POST(`${API_LINK}/b/getCurrentState`, JSON.stringify(body));
    if (response.success) {
      console.log('TCL: response', response);
      return response.states[0];
    }
    return null;
  },
  getEmployeeCurrentAssignmentState: async employeeId => {
    const response = await POST(
      `${API_LINK}/employees/getEmployeeCurrentAssignmentState`,
      JSON.stringify({ employeeId }),
    );
    if (response.success) {
      console.log('TCL: response', response);
      return response.employeeCurrState;
    }
    return null;
  },
  getlogs: async employeeId => {
    const response = await POST(`${API_LINK}/employees/getlogs`, JSON.stringify({ employeeId }));
    if (response.success) {
      console.log('TCL: response', response);
      return response.assLogs;
    }
    return null;
  },
};
