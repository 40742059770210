import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';
import DocDropdownFilter from './components/DocDropdownFilter';
import DocOptionsFilter from './components/DocOptionsFilter';
import DocTimeFilter from './components/DocTimeFilter';

class DocumentsExpFilters extends Component {
  static propTypes = {
    //t: PropTypes.func.isRequired,
    defaultValues: PropTypes.object.isRequired,
    onSubmit: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      currentValues: props.defaultValues,
    };
  }

  handleRangeSubmitted = val => {
    const currVals = this.state.currentValues;
    const newValues = { ...currVals, time: { range: val } };
    this.props.onSubmit(newValues);
    this.setState({ currentValues: newValues });
  };

  handleDropdownSubmitted = val => {
    const currVals = this.state.currentValues;
    const newValues = { ...currVals, dropdown: { ...val } };
    this.props.onSubmit(newValues);
    this.setState({ currentValues: newValues });
  };

  handleOptionsSubmitted = val => {
    const currVals = this.state.currentValues;
    const newValues = { ...currVals, options: { ...val } };
    this.props.onSubmit(newValues);
    this.setState({ currentValues: newValues });
  };

  render() {
    const { defaultValues } = this.props;

    return (
      <Row>
        <Col md={12} lg={4}>
          <DocDropdownFilter defaultValues={defaultValues.dropdown} onSubmit={this.handleDropdownSubmitted} />
        </Col>
        <Col md={12} lg={4}>
          <DocOptionsFilter defaultValues={defaultValues.options} onSubmit={this.handleOptionsSubmitted} />
        </Col>
        <Col md={12} lg={4}>
          <DocTimeFilter defaultValues={defaultValues.time} onSubmit={this.handleRangeSubmitted} />
        </Col>
      </Row>
    );
  }
}

export default translate('common')(DocumentsExpFilters);
