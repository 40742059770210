import React from 'react';
import moment from 'moment-timezone';
import shortid from 'shortid';
import { Card, CardBody, Col, Badge, Table, Button } from 'reactstrap';
import PropTypes from 'prop-types';
import { translate } from 'react-i18next';
import translateProfession, { translateVehType } from 'translations/dynamicTranslator';
// import StammDownload from './StammDownload';
import { TIMEZONE } from '../../../constants/config';
// import Modal from '../../../shared/components/Modal';

// const renderWorker = worker => (
//   <Badge style={{ backgroundColor: `${worker.color}`, color: '#646777' }}>{worker.name}</Badge>
// );

const renderHomeWorkers = workers =>
  workers.map(worker => {
    if (!worker.startsFrom) {
      return (
        <p key={worker.id}>
          <Badge style={{ backgroundColor: `${worker.color}`, color: '#646777' }}>{worker.name}</Badge>
        </p>
      );
    }

    return null;
  });

const renderAbroadWorkers = workers =>
  workers.map(worker => {
    if (worker.startsFrom) {
      return (
        <p key={worker.id}>
          <Badge style={{ backgroundColor: `${worker.color}`, color: '#646777' }}>{worker.name}</Badge>
        </p>
      );
    }

    return null;
  });

const renderPlaceholders = placeholders =>
  placeholders.map(placeholder => (
    <p key={shortid.generate()}>
      <Badge style={{ backgroundColor: 'red', color: 'white' }}>
        {translateProfession(placeholder.profession.label || 'NEDOLOČENO', localStorage.getItem('lang') || 'sl')}
      </Badge>
    </p>
  ));

const renderVehicles = vehicles =>
  vehicles.map(vehicle => (
    <div key={vehicle._id} style={{ marginBottom: '10px' }}>
      <Badge style={{ backgroundColor: 'MediumPurple', color: 'white' }}>
        <p style={{ color: 'white' }}>{translateVehType(vehicle.licensePlate, localStorage.getItem('lang') || 'sl')}</p>
        <p style={{ color: 'white' }}>{vehicle.brand}</p>
        <p style={{ color: 'white' }}>{translateVehType(vehicle.type, localStorage.getItem('lang') || 'sl')}</p>
      </Badge>
    </div>
  ));

const renderAccommodations = accommodations =>
  accommodations.map(accommodation => (
    <div key={accommodation._id} style={{ marginBottom: '10px' }}>
      <Badge style={{ backgroundColor: 'DarkCyan', color: 'white' }}>
        <p style={{ color: 'white' }}>{accommodation.title}</p>
        <p style={{ color: 'white' }}>{accommodation.address}</p>
        <p style={{ color: 'white' }}>{`${accommodation.zip} ${accommodation.city}`}</p>
        <p style={{ color: 'white' }}>{accommodation.country}</p>
      </Badge>
    </div>
  ));

const renderTable = (assignments, onRowSelect, onEndAssignment, onDownload, t) =>
  assignments.map(assignment => {
    const ordererTitle = assignment.orderer ? assignment.orderer.title : '';
    const worksiteTitle = assignment.worksite ? assignment.worksite.title : '';
    const style = assignment.staged ? { backgroundColor: 'Pink' } : {};

    /* <StammDownload
      key={shortid.generate()}
      btn="Priloge"
      ow={`${ordererTitle}: ${worksiteTitle}`}
      date={moment(assignment.date)
        .tz(TIMEZONE)
        .format('DD. MM. YYYY')}
      workers={assignment.workers}
    />, */

    const data = {
      ow: `${ordererTitle}: ${worksiteTitle}`,
      date: moment(assignment.date)
        .tz(TIMEZONE)
        .format('DD. MM. YYYY'),
      workers: assignment.workers,
      vehicles: assignment.vehicles,
    };
    const buttonElt = [
      <Button key={shortid.generate()} size="sm" color="primary" onClick={() => onDownload(data)}>
        {t('form.attachments')}
      </Button>,
    ];

    if (assignment.staged) {
      const notice = (
        <strong key={shortid.generate()} style={{ color: 'red' }}>
          {t('board.ow.unconfirmed')}!
        </strong>
      );
      buttonElt.push(<br key={shortid.generate()} />);
      buttonElt.push(<br key={shortid.generate()} />);
      buttonElt.push(notice);
    }

    return (
      <tr
        key={assignment._id}
        id={assignment._id}
        onClick={() => onRowSelect({ id: assignment._id, staged: assignment.staged })}
        style={style}
      >
        <td>
          {moment(assignment.date)
            .tz(TIMEZONE)
            .format('DD. MM. YY')}
        </td>
        <td>{assignment.departureDate}</td>
        <td>{assignment.orderer ? assignment.orderer.title : <div />}</td>
        <td>{assignment.worksite ? assignment.worksite.title : <div />}</td>
        <td>
          {assignment.workers.some(e => e.startsFrom === false) && (
            <p>
              <strong style={{ color: 'gray' }}>
                {t('form.startsFrom')} {t('form.home')}
              </strong>
            </p>
          )}
          {renderHomeWorkers(assignment.workers)}
          {assignment.workers.some(e => e.startsFrom === true) && (
            <p>
              <strong style={{ color: 'gray' }}>
                {t('form.startsFrom')} {t('form.abroad')}
              </strong>
            </p>
          )}
          {renderAbroadWorkers(assignment.workers)}
          {assignment.placeholders && assignment.placeholders.length > 0 && (
            <React.Fragment>
              <p>
                <strong style={{ color: 'gray' }}>{t('form.placeholders')}</strong>
              </p>

              {renderPlaceholders(assignment.placeholders)}
            </React.Fragment>
          )}
        </td>
        {assignment.vehicles ? <td>{renderVehicles(assignment.vehicles)}</td> : <div />}

        {assignment.accommodations ? <td>{renderAccommodations(assignment.accommodations)}</td> : <div />}
        {/* <td>{assignment.accommodations ? assignment.accommodation.title : <div />}</td> */}
        <td>
          <p>{assignment.notes || ''}</p>
        </td>
        {/* eslint-disable */}
        <td onClick={e => e.stopPropagation()}>{buttonElt}</td>
        {/* eslint-enable */}
      </tr>
    );
  });

const NapotitveTable = ({ title, subtitle, assignments, onRowSelect, onEndAssignment, onDownload, t }) => (
  <Col md={12} lg={12} xl={12}>
    <Card>
      <CardBody>
        <div className="card__title">
          <h5 className="bold-text">{title}</h5>
          <h5 className="subhead">{subtitle}</h5>
        </div>
        <Table hover className="table--bordered" responsive>
          <thead>
            <tr>
              <th>{t('form.dateBegin')}</th>
              <th>{t('form.dateLeave')}</th>
              <th>{t('board.ow.orderer')}</th>
              <th>{t('form.worksite')}</th>
              <th>{t('board.ow.worker')}</th>
              <th>{t('board.ow.vehicle')}</th>
              <th>{t('board.ow.accommodation')}</th>
              <th>{t('form.notes')}</th>
              <th>&nbsp;</th>
            </tr>
          </thead>
          <tbody>{renderTable(assignments, onRowSelect, onEndAssignment, onDownload, t)}</tbody>
        </Table>
      </CardBody>
    </Card>
  </Col>
);

NapotitveTable.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  /* assignments: PropTypes.arrayOf(PropTypes.shape({
    _id: PropTypes.string,
    date: PropTypes.date,
    orderer: PropTypes.string,
    worksite: PropTypes.string,
    vehicle: PropTypes.object,
    accommodation: PropTypes.object,
    workers: PropTypes.arrayOf(PropTypes.object),
  })).isRequired, */
  assignments: PropTypes.arrayOf(PropTypes.object).isRequired,
  onRowSelect: PropTypes.func.isRequired,
  onEndAssignment: PropTypes.func.isRequired,
  onDownload: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default translate('common')(NapotitveTable);
