export const SET_COMPANIES = 'SET_COMPANIES';
export const SET_COMPANIES_TITLES_LIST = 'SET_COMPANIES_TITLES_LIST';

export function setCompanies(companies) {
  return {
    type: SET_COMPANIES,
    companies,
  };
}

export function setCompaniesTitlesList(companiesTitles) {
  return {
    type: SET_COMPANIES_TITLES_LIST,
    companiesTitles,
  };
}
