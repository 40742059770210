import React, { Component } from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';
import ReactGA from 'react-ga';
import { Col, Container, Row, ButtonToolbar, Card, CardBody, Progress } from 'reactstrap';
import NotificationSystem from 'rc-notification';
import SelectDropdown from 'shared/components/SelectDropdown';
import { API_LINK } from 'constants/config';
import { POST } from 'helpers/agent';
import { BasicNotification } from 'shared/components/Notification';
import PonudnikiModal from './components/PonudnikiModal';
import PonudnikiTable from './components/PonudnikiTable';
import PonudnikiSheet from './components/PonudnikiSheet';
import getRoles from '../../../constants/roles';
import { AccommodationsProps, AppStatusProps } from '../../../shared/prop-types/ReducerProps';

const API_PROVIDERS_GET_ONE = '/pr/getOneById';

let notificationLU = null;
let notificationRU = null;
let notificationTC = null;

const showNotification = ({ notification, position }) => {
  switch (position) {
    case 'left-up':
      notificationLU.notice({
        content: notification,
        duration: 5,
        closable: true,
        style: { top: 0, left: 240 },
        className: position,
      });
      break;
    case 'right-up':
      notificationRU.notice({
        content: notification,
        duration: 5,
        closable: true,
        style: { top: 0, left: 'calc(100vw - 100%)' },
        className: position,
      });
      break;
    default:
      notificationTC.notice({
        content: notification,
        duration: 5,
        closable: true,
        style: { top: 0, left: 0 },
        className: position,
      });
      break;
  }
};

class PonudnikiPage extends Component {
  static propTypes = {
    accommodationsState: AccommodationsProps.isRequired,
    appStatus: AppStatusProps.isRequired,
    t: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      providers: [],
      providersFull: [],
      titles: [],
      showEdit: false,
      progressNum: 0,
      editProvider: null,
      ROLES: getRoles(),
    };

    this.onRowSelect = this.onRowSelect.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.showNotif = this.showNotif.bind(this);
    this.fetchData = this.fetchData.bind(this);
    this.handleProgress = this.handleProgress.bind(this);
    this.handleDropdownSubmit = this.handleDropdownSubmit.bind(this);
  }

  componentDidMount() {
    NotificationSystem.newInstance({}, n => (notificationLU = n));
    NotificationSystem.newInstance({}, n => (notificationRU = n));
    NotificationSystem.newInstance({}, n => (notificationTC = n));

    const { appStatus } = this.props;
    if (appStatus.requiredDataLoaded) {
      console.log('ACCOMMODATIONS_PROVIDERS -> Data loaded.. continue...');
      this.fetchData();
    }
  }

  componentDidUpdate(prevProps) {
    const { appStatus } = this.props;
    if (appStatus.requiredDataLoaded && !prevProps.appStatus.requiredDataLoaded) {
      console.log('ACCOMMODATIONS_PROVIDERS -> Data finished loading.. continue...');
      this.fetchData();
    }

    if (!appStatus.appUpdating && prevProps.appStatus.appUpdating) {
      console.log('ACCOMMODATIONS_PROVIDERS -> Data finished UPDATING.. refresh table...');
      this.fetchData();
    }
  }

  componentWillUnmount() {
    notificationLU.destroy();
    notificationRU.destroy();
    notificationTC.destroy();
  }

  async onRowSelect(values) {
    const { ROLES } = this.state;
    const { t } = this.props;
    if (ROLES.accommodations && ROLES.accommodations.providers && ROLES.accommodations.providers.readonly) {
      ReactGA.event({
        category: 'Prenočitve - Ponudniki',
        action: `Urejanje Prenočitve - Ponudniki - ${values} - Ni dostopa`,
      });
      this.showNotif({
        title: `${t('misc.forbidden')}!`,
        message: `${t('misc.noright')}!`,
        success: false,
      });
      return;
    }
    const that = this;
    const body = JSON.stringify({ id: values });
    ReactGA.event({
      category: 'Prenočitve - Ponudniki',
      action: `Urejanje Prenočitve - Ponudniki - ${values}`,
    });
    const data = await POST(API_LINK + API_PROVIDERS_GET_ONE, body);
    if (data.success) {
      // console.log('accommodation', data.accommodation);
      that.setState({ editProvider: data.provider, showEdit: true });
    }
  }

  handleProgress(addProgress) {
    const { progressNum } = this.state;
    this.setState({ progressNum: progressNum + addProgress });
    const that = this;
    if (progressNum + addProgress >= 100) {
      setTimeout(() => {
        that.setState({ progressNum: 0 });
      }, 1000);
    }
  }

  fetchData() {
    const { accommodationsState } = this.props;
    this.setState({
      providers: accommodationsState.providers,
      providersFull: accommodationsState.providers,
      titles: accommodationsState.providerTitles,
    });
  }

  showNotif = ({ title, message, success }) => {
    const color = success ? 'success' : 'danger';
    showNotification({
      notification: <BasicNotification color={color} title={title} message={message} />,
      position: 'right-up',
    });
  };

  handleCloseModal(data) {
    if (data) this.showNotif(data);
    this.setState({ showEdit: false });
    if (data) this.fetchData();
  }

  handleDropdownSubmit(values) {
    try {
      if (values.selected_dropdown === null) {
        this.fetchData();
        return;
      }
      ReactGA.event({
        category: 'Prenočitve - Ponudniki',
        action: `Filtriranje - ${values.selected_dropdown.label}`,
      });
      const { providersFull } = this.state;
      const id = values.selected_dropdown.value;
      const filtered = [];
      providersFull.forEach(acm => {
        if (acm._id === id) {
          filtered.push(acm);
        }
      });
      this.setState({ providers: filtered });
    } catch (error) {
      // console.log(error);
    }
  }

  render() {
    const { providers, titles, showEdit, editProvider, progressNum, ROLES } = this.state;
    const { t } = this.props;

    let showBtn = ROLES.full;

    if (!showBtn) {
      showBtn = !(ROLES.accommodations && ROLES.accommodations.providers && ROLES.accommodations.providers.readonly);
    }

    return (
      <Container className="dashboard">
        <Row>
          <Col lg={12}>
            <h3 className="page-title">
              {t('sidebar_content.accommodations.title')} - {t('sidebar_content.accommodations.providers')}
            </h3>
          </Col>
          <Col lg={12}>
            <Row>
              <Col lg={6}>
                <Card>
                  <CardBody>
                    <SelectDropdown
                      title={`${t('vehicles.find')}:`}
                      items={titles}
                      onSubmit={this.handleDropdownSubmit}
                    />
                  </CardBody>
                </Card>
              </Col>
              <Col lg={{ size: 4, offset: 2 }}>
                <ButtonToolbar className="button-toolbar">
                  <PonudnikiModal
                    btn={t('messages.provider.add.title')}
                    icon="add"
                    title={t('messages.provider.add.title')}
                    hasBtn={showBtn}
                    show={false}
                    onClose={this.handleCloseModal}
                  />

                  <PonudnikiSheet
                    btn={t('form.exportExcel')}
                    disabled={!(progressNum === 0 && providers.length > 0)}
                    data={providers}
                  />
                </ButtonToolbar>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          {progressNum > 0 ? (
            <div className="progress-wrap progress-wrap--middle progress--align">
              <Progress animated value={progressNum} />
            </div>
          ) : null}
          <PonudnikiTable
            title={t('sidebar_content.accommodations.providers')}
            providers={providers}
            onRowSelect={this.onRowSelect}
          />
        </Row>

        <PonudnikiModal
          btn={t('messages.provider.edit.title')}
          icon="edit"
          title={t('messages.provider.edit.title')}
          hasBtn={false}
          show={showEdit}
          onClose={this.handleCloseModal}
          editData={editProvider}
        />
      </Container>
    );
  }
}

const wr = connect(state => ({
  accommodationsState: state.accommodations,
  appStatus: state.appStatus,
}))(PonudnikiPage);

export default translate('common')(wr);
