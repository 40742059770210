import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Col } from 'reactstrap';
import ReactLoading from 'react-loading';
import { connect } from 'react-redux';
import { getOws } from '../../../../redux/actions/owActions';
import Board from './Board';
import HomeBoard from './HomeBoard';

class BoardsGrid extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    owsStore: PropTypes.object,
  };

  static defaultProps = {
    owsStore: {},
  };

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(getOws());
  }

  renderBoards() {
    const {
      owsStore: { ows, filtered },
    } = this.props;
    if (!ows || !ows.length) {
      return 'No data';
    }
    const filteredOrAll = filtered || ows;
    return filteredOrAll.map(ow => {
      if (ow.unassigned) {
        return (
          <Col xs={12} key={ow.live._id}>
            <HomeBoard ow={ow} />
          </Col>
        );
      }
      return (
        <Col xs={12} md={12} lg={6} key={ow.live ? ow.live._id : ow.staged._id}>
          <Board ow={ow} />
        </Col>
      );
    });
  }

  render() {
    const {
      owsStore: { loaded },
    } = this.props;
    if (!loaded) {
      return <ReactLoading type="bars" color="gray" height={128} width={128} className="react-loader" />;
    }
    return <Fragment>{this.renderBoards()}</Fragment>;
  }
}

export default connect(state => ({
  owsStore: state.ows,
}))(BoardsGrid);
