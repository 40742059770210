import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { CardTitle, Input } from 'reactstrap';
import { translate } from 'react-i18next';

class LocationRadius extends Component {
  static propTypes = {
    onChange: PropTypes.isRequired,
    t: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      locationRadius: 10,
    };
  }

  handleChange = () => {
    const { locationRadius } = this.state;
    this.props.onChange(locationRadius);
  };

  handleLocationRadius = event => {
    this.setState(
      {
        locationRadius: event.target.value,
      },
      this.handleChange,
    );
  };

  render() {
    const { locationRadius } = this.state;
    const { t } = this.props;
    return (
      <React.Fragment>
        <CardTitle>{t('places.card.distance')} (km)</CardTitle>
        <div className="location-radius">
          <Input className="input" value={locationRadius} onChange={this.handleLocationRadius} />
          <span className="location-radius__metric">KM</span>
        </div>
      </React.Fragment>
    );
  }
}

export default translate('common')(LocationRadius);
