import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter, Redirect } from 'react-router-dom';
import NotificationSystem from 'rc-notification';
import { translate } from 'react-i18next';
import { Col, Card, CardBody } from 'reactstrap';
import { BasicNotification } from '../../shared/components/Notification';
import LogInForm from './components/LogInForm';
import users from '../../api/users';
import { setToken, setTokenFetchError, setUser } from '../../redux/actions/userActions';
import { getItem, setItem } from '../../helpers/cookies';
import { persistor } from '../App/store';

let notification = null;
const EU_SLO = `${process.env.PUBLIC_URL}/img/eu_slo.png`;
const EU_EN = `${process.env.PUBLIC_URL}/img/eu_eng.png`;

const SPS = `${process.env.PUBLIC_URL}/img/sps_logo-2.png`;
const MNST = `${process.env.PUBLIC_URL}/img/mgrt_logo-2.png`;

const showNotification = (title, message) => {
  notification.notice({
    content: <BasicNotification color="danger" title={title} message={message} />,
    duration: 5,
    closable: true,
    style: { top: 0, left: 'calc(100vw - 100%)' },
    className: 'right-up',
  });
};

class LogIn extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      loggedIn: false,
    };
    this.handleLogin = this.handleLogin.bind(this);
  }

  componentDidMount() {
    /* persistor.purge(); */
    NotificationSystem.newInstance({}, n => (notification = n));
    const token = getItem('jwt');
    /* eslint-disable-next-line */
    if (token) this.setState({ loggedIn: true });
  }

  componentWillUnmount() {
    notification.destroy();
  }

  /* wait(time) {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve();
      }, time);
    });
  } */

  async handleLogin({ username, password }) {
    await persistor.purge();
    if (!username || !password) return;
    const data = await users.login(username, password);
    const { t } = this.props;
    if (data.success) {
      this.props.dispatch(setToken(data.token));
      this.props.dispatch(setUser(data.user));
      const that = this;
      setItem('notif', 'show');
      setTimeout(() => {
        that.setState({ loggedIn: true });
      }, 1000);
    } else {
      showNotification(t('login.error.title'), t('login.error.message'));
      this.props.dispatch(setTokenFetchError(data.error));
    }

    // console.log('token', token);
  }

  render() {
    const { loggedIn } = this.state;
    const { t } = this.props;
    if (loggedIn) {
      return <Redirect to="/tabla" />;
    }
    return (
      <React.Fragment>
        <div className="account">
          <div className="account__wrapper">
            <div className="account__card">
              <div className="account__head">
                <h3 className="account__title">
                  <span className="account__logo">
                    {' '}
                    HU
                    <span className="account__logo-accent">RES</span>
                  </span>
                </h3>
                <h4 className="account__subhead subhead">{t('login.subhead')}</h4>
              </div>
              <LogInForm onSubmit={this.handleLogin} />
              <div style={{ display: 'flex', width: '34%' }}>
                <img src={EU_SLO} alt="" style={{ marginTop: '66px' }} />
                <img src={MNST} alt="" style={{ marginTop: '66px' }} />
                <img src={SPS} alt="" style={{ marginTop: '66px' }} />
              </div>
              <div style={{ width: '100%', textAlign: 'center', marginTop: '50px' }}>
                <h5>“Naložbo sofinancirata Republika Slovenija in Evropska unija iz sklada za regionalni razvoj”</h5>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const wr = withRouter(connect()(LogIn));
export default translate('common')(wr);
