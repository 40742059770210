export const CHANGE_APP_LOADING_STAT = 'CHANGE_APP_LOADING_STAT';
export const CHANGE_APP_LOADED_STAT = 'CHANGE_APP_LOADED_STAT';
export const SET_APP_LOADING_STATUS = 'SET_APP_LOADING_STATUS';
export const SET_APP_UPDATING_STATUS = 'SET_APP_UPDATING_STATUS';
export const SET_ROUTER_BLOCK = 'SET_ROUTER_BLOCK';
export const SET_ROUTER_BLOCK_NOTIF = 'SET_ROUTER_BLOCK_NOTIF';

export function changeAppLoadingStat(loading) {
  return {
    type: CHANGE_APP_LOADING_STAT,
    loading,
  };
}

export function changeAppLoadedStat(loaded) {
  return {
    type: CHANGE_APP_LOADED_STAT,
    loaded,
  };
}

export function setAppLoadingStatus(appLoadingStatus) {
  return {
    type: SET_APP_LOADING_STATUS,
    appLoadingStatus,
  };
}

export function setAppUpdatingStatus(appUpdating, appUpdatingStatus) {
  return {
    type: SET_APP_UPDATING_STATUS,
    appUpdatingStatus,
    appUpdating,
  };
}

export function setRouterBlock(blockRouter) {
  return {
    type: SET_ROUTER_BLOCK,
    blockRouter,
  };
}

export function setRouterBlockNotif(showRouterBlockNotif) {
  return {
    type: SET_ROUTER_BLOCK_NOTIF,
    showRouterBlockNotif,
  };
}
