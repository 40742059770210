import React from 'react';
import { Card, CardBody, Col, Table } from 'reactstrap';
import PropTypes from 'prop-types';

const renderTable = (worksitesAll, onRowSelect) => {
  const worksites = [];
  worksitesAll.forEach(w => {
    if (!w.fake) {
      worksites.push(w);
    }
  });
  return worksites.map(worksite => (
    <tr key={worksite._id} id={worksite._id} onClick={() => onRowSelect(worksite._id)}>
      <td>{worksite.title}</td>
      <td>{worksite.address}</td>
      <td>{worksite.zip}</td>
      <td>{worksite.city}</td>
      <td>{worksite.country}</td>
    </tr>
  ));
};

const GradbiscaTable = ({ title, worksites, onRowSelect }) => (
  <Col md={12} lg={12} xl={12}>
    <Card>
      <CardBody>
        <div className="card__title">
          <h5 className="bold-text">{title}</h5>
          {/* <h5 className="subhead">lol</h5> */}
        </div>
        <Table hover className="table--bordered" responsive>
          <thead>
            <tr>
              <th>Naziv</th>
              <th>Naslov</th>
              <th>Poštna št.</th>
              <th>Kraj</th>
              <th>Država</th>
            </tr>
          </thead>
          <tbody>{renderTable(worksites, onRowSelect)}</tbody>
        </Table>
      </CardBody>
    </Card>
  </Col>
);

GradbiscaTable.propTypes = {
  title: PropTypes.string.isRequired,
  worksites: PropTypes.arrayOf(PropTypes.object).isRequired,
  onRowSelect: PropTypes.func.isRequired,
};

export default GradbiscaTable;
