import React from 'react';
import PropTypes from 'prop-types';
import ReactExport from 'react-data-export';
import { Button } from 'reactstrap';
import { translate } from 'react-i18next';

/* eslint-disable */
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
/* eslint-enable */

const DownloadButton = title => (
  <Button size="sm" color="primary" style={{ marginBottom: '10px' }}>
    {title}
  </Button>
);

const generateHeaders = t => {
  const firstPart = [
    {
      title: '#',
    },
    {
      title: t('board.ow.worker'),
    },
    {
      title: t('form.from').toUpperCase(),
    },
    {
      title: t('form.to').toUpperCase(),
    },
    {
      title: ' ',
    },
    {
      title: t('board.filters.worksite'),
    },
    {
      title: t('form.country'),
    },
  ];

  const multiDataSet = [
    {
      columns: firstPart,
      data: [],
    },
  ];
  return multiDataSet;
};

const generateData = dataAll => {
  console.log('TCL: generateData -> dataAll', dataAll);
  const data = [];
  for (let i = 0; i < dataAll.length; i += 1) {
    const { name, surname, registeredAt, unregisteredAt, docsList, owList, assList } = dataAll[i];
    const workerRow = [
      {
        value: Number(`${i + 1}`),
      },
      {
        value: `${surname} ${name}`,
      },
      {
        value: registeredAt,
      },
      {
        value: unregisteredAt === '' ? ' ' : unregisteredAt,
      },
      {
        value: ' ',
      },
      {
        value: ' ',
      },
      {
        value: ' ',
      },
    ];

    data.push(workerRow);

    for (let j = 0; j < docsList.length; j += 1) {
      const doc = docsList[j];
      if (doc.type === 'a1') {
        const a1Row = [
          {
            value: ' ',
          },
          {
            value: ' ',
          },
          {
            value: doc.from,
          },
          {
            value: doc.to,
          },
          {
            value: 'A1',
          },
          {
            value: doc.worksite,
          },
          {
            value: doc.country.toUpperCase(),
          },
        ];
        data.push(a1Row);
      }
    }

    for (let j = 0; j < assList.length; j += 1) {
      const ow = assList[j];
      const assRow = [
        {
          value: ' ',
        },
        {
          value: ' ',
        },
        {
          value: ow.from,
        },
        {
          value: ow.to,
        },
        {
          value: 'Nap.',
        },
        {
          value: `${ow.ordererTitle}: ${ow.worksiteTitle}`,
        },
        {
          value: ' ',
        },
      ];
      data.push(assRow);
    }

    for (let j = 0; j < owList.length; j += 1) {
      const ow = owList[j];
      const owRow = [
        {
          value: ' ',
        },
        {
          value: ' ',
        },
        {
          value: ow.from,
        },
        {
          value: ow.to,
        },
        {
          value: 'Delo',
        },
        {
          value: `${ow.ordererTitle}: ${ow.worksiteTitle}`,
        },
        {
          value: ' ',
        },
      ];
      data.push(owRow);
    }

    data.push([
      {
        value: ' ',
      },
      {
        value: 'PODLAGA',
      },
      {
        value: ' ',
      },
      {
        value: ' ',
      },
      {
        value: ' ',
      },
      {
        value: ' ',
      },
      {
        value: ' ',
      },
    ]);

    for (let j = 0; j < docsList.length; j += 1) {
      const doc = docsList[j];
      if (doc.type === 'insurance') {
        const insRow = [
          {
            value: ' ',
          },
          {
            value: ' ',
          },
          {
            value: doc.from,
          },
          {
            value: doc.to,
          },
          {
            value: doc.num,
          },
          {
            value: ' ',
          },
          {
            value: ' ',
          },
        ];
        data.push(insRow);
      }
    }
  }

  return data;
};
class PayAnaSheet extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { data, btn, t } = this.props;

    const headers = generateHeaders(t);
    headers[0].data = generateData(data);
    return (
      <ExcelFile element={DownloadButton(btn)} filename={t('calcs.analysis')}>
        <ExcelSheet dataSet={headers} name={t('calcs.analysis')} />
      </ExcelFile>
    );
  }
}

PayAnaSheet.propTypes = {
  btn: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  t: PropTypes.func.isRequired,
};

export default translate('common')(PayAnaSheet);
