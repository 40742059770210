import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody, Col, ButtonToolbar, Button } from 'reactstrap';
import { Field, reduxForm, change } from 'redux-form';
import { connect } from 'react-redux';
import CalendarBlankIcon from 'mdi-react/CalendarBlankIcon';
import Modal from 'shared/components/Modal';
import { translate } from 'react-i18next';
import { EmployeesProps } from 'shared/prop-types/ReducerProps';
import validate from './validate';
import renderSelectField from '../../../../shared/components/form/Select';
import renderDatePickerField from '../../../../shared/components/form/DatePicker';
import renderTextField from '../../../../shared/components/form/TextField';
import renderCheckBoxField from '../../../../shared/components/form/CheckBox';
import renderCreatableSelectField from '../../../../shared/components/form/CreatableSelect';
import renderMultiSelectField from '../../../../shared/components/form/MultiSelect';

const ML = {
  marginLeft: '30px',
};

class RezervacijeForm extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      accommodationsFiltered:
        props.initialValues && props.initialValues.providerId
          ? this.filterAccommodations(props.initialValues.providerId.value)
          : props.accommodations,
    };
  }

  filterAccommodations = providerId => {
    const { accommodationsFull, accommodations } = this.props;
    try {
      const filtered = accommodationsFull.filter(a => a.providerId === providerId);
      return filtered.map(f => ({
        value: f._id,
        label: f.address,
      }));
    } catch (e) {
      return accommodations;
    }
  };

  handleProviderChange = val => {
    const filtered = this.filterAccommodations(val.value);
    this.setState({ accommodationsFiltered: filtered });
    //{ value: acm.value, label: acm.label }
    this.props.dispatch(change('rezervacije_2form', 'accommodationId', null));
  };

  render() {
    const {
      handleSubmit,
      handleCancel,
      handleDelete,
      editData,
      pristine,
      companies,
      providers,
      accommodations,
      workersState,
      worksites,
      t,
    } = this.props;

    const { accommodationsFiltered } = this.state;

    return (
      <Col md={12} lg={12}>
        <Card>
          <CardBody>
            <form className="form" onSubmit={handleSubmit}>
              <div className="form__form-group">
                <span className="form__form-group-label">{t('form.company')}</span>
                <div className="form__form-group-field">
                  <Field name="companyId" component={renderSelectField} options={companies} />
                </div>
              </div>

              <div className="form__form-group">
                <span className="form__form-group-label">{t('board.ow.provider')}</span>
                <div className="form__form-group-field">
                  <Field
                    name="providerId"
                    component={renderSelectField}
                    options={providers}
                    onChange={this.handleProviderChange}
                  />
                </div>
              </div>

              <div className="form__form-group">
                <span className="form__form-group-label">
                  {`${t('board.ow.accommodation')} (${t('form.address')})`}
                </span>
                <div className="form__form-group-field">
                  <Field
                    name="accommodationId"
                    component={renderCreatableSelectField}
                    options={accommodationsFiltered}
                  />
                </div>
              </div>

              <div className="form__form-group">
                <span className="form__form-group-label">{t('form.worksite')}</span>
                <div className="form__form-group-field">
                  <Field name="worksiteId" component={renderSelectField} options={worksites} />
                </div>
              </div>

              <div className="form__half">
                <div className="form__form-group">
                  <span className="form__form-group-label">{t('misc.price')}</span>
                  <div className="form__form-group-field">
                    <Field name="price" component={renderTextField} type="text" />
                  </div>
                </div>
              </div>

              <div className="form__half">
                <div className="form__form-group" style={ML}>
                  <span className="form__form-group-label">{t('misc.invoiceNum')}</span>
                  <div className="form__form-group-field">
                    <Field name="invoiceNum" component={renderTextField} type="text" />
                  </div>
                </div>
              </div>

              {/* <div className="form__half">
                <div className="form__form-group" style={ML}>
                  <span className="form__form-group-label">
                    {t('misc.unpaid')}
                  </span>
                  <div className="form__form-group-field">
                    <Field
                      name="unpaid"
                      component={renderTextField}
                      type="text"
                    />
                  </div>
                </div>
              </div> */}

              <div className="form__half">
                <div className="form__form-group">
                  <span className="form__form-group-label">{t('misc.dateFrom')}</span>
                  <div className="form__form-group-field">
                    <Field name="dateFrom" component={renderDatePickerField} />
                    <div className="form__form-group-icon">
                      <CalendarBlankIcon />
                    </div>
                  </div>
                </div>
              </div>

              <div className="form__half">
                <div className="form__form-group" style={ML}>
                  <span className="form__form-group-label">{t('misc.dateTo')}</span>
                  <div className="form__form-group-field">
                    <Field name="dateTo" component={renderDatePickerField} />
                    <div className="form__form-group-icon">
                      <CalendarBlankIcon />
                    </div>
                  </div>
                </div>
              </div>

              <div className="form__half">
                <div className="form__form-group">
                  <span className="form__form-group-label">{t('misc.datePaid')}</span>
                  <div className="form__form-group-field">
                    <Field name="datePaid" component={renderDatePickerField} />
                    <div className="form__form-group-icon">
                      <CalendarBlankIcon />
                    </div>
                  </div>
                </div>
              </div>

              <div className="form__half">
                <div className="form__form-group" style={ML}>
                  <span className="form__form-group-label">{t('misc.persons')}</span>
                  <div className="form__form-group-field">
                    <Field name="persons" component={renderTextField} type="text" />
                  </div>
                </div>
              </div>

              <div className="form__half">
                <div className="form__form-group">
                  <span className="form__form-group-label">{t('misc.priority')}</span>
                  <div className="form__form-group-field">
                    <Field name="priority" component={renderTextField} type="text" />
                  </div>
                </div>
              </div>

              <div className="form__form-group leave-select">
                <span className="form__form-group-label">{t('sidebar_content.workers.title')}</span>
                <div className="form__form-group-field">
                  <Field name="workers" component={renderMultiSelectField} options={workersState.employeesNames} />
                </div>
              </div>

              {/* <div className="form__half"> */}
              <div className="form__form-group">
                <span className="form__form-group-label">{t('form.notes')}</span>
                <div className="form__form-group-field">
                  <Field name="notes" component="textarea" type="text" />
                </div>
              </div>
              {/* </div> */}

              {/* <div className="form__half"> */}
              <div className="form__form-group" style={{ marginTop: '6px' }}>
                <span className="form__form-group-label">&nbsp;</span>
                <div className="form__form-group-field">
                  <Field name="isPaid" component={renderCheckBoxField} label={t('misc.isPaid')} className="colored" />
                </div>
              </div>
              {/* </div> */}

              <ButtonToolbar className="form__button-toolbar">
                {editData ? (
                  <Modal
                    color="danger"
                    title={t('form.warn')}
                    colored
                    btn={t('form.delete')}
                    message={t('messages.reservations.delete.confirm')}
                    onConfirm={() => handleDelete(editData)}
                  />
                ) : null}
                <Button type="button" onClick={handleCancel}>
                  {t('form.cancel')}
                </Button>
                <Button color="primary" type="submit" disabled={pristine}>
                  {editData ? t('form.save') : t('form.add')}
                </Button>
              </ButtonToolbar>
            </form>
          </CardBody>
        </Card>
      </Col>
    );
  }
}

RezervacijeForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
  companies: PropTypes.arrayOf(PropTypes.object).isRequired,
  providers: PropTypes.arrayOf(PropTypes.object).isRequired,
  accommodations: PropTypes.arrayOf(PropTypes.object).isRequired,
  accommodationsFull: PropTypes.arrayOf(PropTypes.object).isRequired,
  worksites: PropTypes.arrayOf(PropTypes.object).isRequired,
  workersState: EmployeesProps.isRequired,
  editData: PropTypes.object,
  pristine: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
  //dispatch: PropTypes.func.isRequired,
};

RezervacijeForm.defaultProps = {
  editData: null,
};

const cn = connect(state => ({
  workersState: state.employees,
}))(RezervacijeForm);

const wr = reduxForm({
  form: 'rezervacije_2form', // a unique identifier for this form
  validate,
})(cn);

export default translate('common')(wr);
