import React from 'react';
import { Card, CardBody, Col, Table } from 'reactstrap';
import PropTypes from 'prop-types';

const renderTable = (orderersAll, onRowSelect) => {
  const orderers = [];
  orderersAll.forEach(o => {
    if (!o.fake) {
      orderers.push(o);
    }
  });
  return orderers.map(orderer => (
    <tr key={orderer._id} id={orderer._id} onClick={() => onRowSelect(orderer._id)}>
      <td>{orderer.title}</td>
      <td>{orderer.address}</td>
      <td>{orderer.zip}</td>
      <td>{orderer.city}</td>
      <td>{orderer.country}</td>
    </tr>
  ));
};

const NarocnikiTable = ({ title, orderers, onRowSelect }) => (
  <Col md={12} lg={12} xl={12}>
    <Card>
      <CardBody>
        <div className="card__title">
          <h5 className="bold-text">{title}</h5>
          {/* <h5 className="subhead">lol</h5> */}
        </div>
        <Table hover className="table--bordered" responsive>
          <thead>
            <tr>
              <th>Naziv</th>
              <th>Naslov</th>
              <th>Poštna št.</th>
              <th>Kraj</th>
              <th>Država</th>
            </tr>
          </thead>
          <tbody>{renderTable(orderers, onRowSelect)}</tbody>
        </Table>
      </CardBody>
    </Card>
  </Col>
);

NarocnikiTable.propTypes = {
  title: PropTypes.string.isRequired,
  orderers: PropTypes.arrayOf(PropTypes.object).isRequired,
  onRowSelect: PropTypes.func.isRequired,
};

export default NarocnikiTable;
