import { SET_WORKSITES, SET_WORKSITES_TITLES, SET_WORKSITES_TITLES_WITH_ADDRESS } from '../actions/worksitesActions';

const initialState = {
  worksites: [],
  titlesWorksites: [],
  worksitesWithAddress: [],
};

export default function(state = initialState, action) {
  const titlesList = [];
  const titlesAddressList = [];
  switch (action.type) {
    case SET_WORKSITES:
      action.worksites.forEach(work => {
        const workData = {
          value: work._id,
          label: work.title,
        };
        const workDataAddr = {
          value: work._id,
          label: `${work.title} - ${work.address}`,
        };

        titlesList.push(workData);
        titlesAddressList.push(workDataAddr);
      });
      return {
        ...state,
        worksites: action.worksites,
        titlesWorksites: titlesList,
        worksitesWithAddress: titlesAddressList,
      };
    case SET_WORKSITES_TITLES:
      return { ...state, titlesWorksites: action.titlesWorksites };
    case SET_WORKSITES_TITLES_WITH_ADDRESS:
      return { ...state, worksitesWithAddress: action.worksitesWithAddress };
    default:
      return state;
  }
}
