import React, { Component, Fragment } from 'react';
import {
  Card,
  CardBody,
  CardHeader,
  Badge,
  Popover,
  PopoverHeader,
  PopoverBody,
  Button,
  UncontrolledTooltip,
} from 'reactstrap';
import { Redirect } from 'react-router-dom';
import EditableTable from 'shared/components/table/EditableTable';
import getDaysInMonth from 'helpers/getDaysInMonth';
import { translate } from 'react-i18next';
import shortid from 'shortid';
import ReactLoading from 'react-loading';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import workhoursApi from 'api/workhours';
import getRoles from 'constants/roles';
import employeesApi from 'api/employees';

import { PencilIcon } from 'mdi-react';
import { connect } from 'react-redux';

import fix, { commify } from 'helpers/floatParser';

import _ from 'lodash';

import { TIMEZONE } from 'constants/config';
import { setRouterBlockNotif, setRouterBlock } from '../../../../redux/actions/appActions';
import { HoursProps, AppStatusProps } from '../../../../shared/prop-types/ReducerProps';
import EmployeesFormModal from '../../../Boards/components/Modals/EmployeesFormModal';

const findAdditional = (day, workdays) => {
  const workday = workdays.find(w => w.date.toString() === `${day.toString()}000`);
  if (workday) {
    if (workday.additionalId) {
      return `(${workday.additionalId})`;
    }
  }
  return '';
};

const findCopy = (day, workdays) => {
  const workday = workdays.find(w => w.date.toString() === `${day.toString()}000`);
  if (workday) {
    if (workday.copy) {
      return workday.copy;
    }
  }
  return false;
};

class Table extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rows: [],
      rowsSticky: [],
      heads: [],
      headsSticky: [],
      indexMap: [],
      evidanceRows: [],
      showSticky: false,
      totalDaySum: 0,
      totalNightSum: 0,
      unsaved: false,
      routeTo: null,
      postingHours: false,
      showClashWarning: false,
      refresh: false,

      employeesFormModalShown: false,
      employeePopoverShown: false,
      employeePopoverTarget: null,
      selectedEmployeeId: null,
      employeeSelectedTab: '1',
      ROLES: getRoles(),
    };

    this.handleWindowBeforeUnload = this.handleWindowBeforeUnload.bind(this);
    this.postHoursAndUpdate = this.postHoursAndUpdate.bind(this);
    this.postDataFromRow = this.postDataFromRow.bind(this);
    this.LetterCodeFormatter = this.LetterCodeFormatter.bind(this);
    this.handleScroll = this.handleScroll.bind(this);

    this.handleLeavePage = this.handleLeavePage.bind(this);
  }

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(setRouterBlockNotif(null));
    dispatch(setRouterBlock(false));
    workhoursApi.clearSchedule();

    this.generateHeads();
    window.addEventListener('scroll', this.handleScroll);
    window.addEventListener('beforeunload', this.handleWindowBeforeUnload);
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.orderer.value !== prevProps.orderer.value ||
      this.props.worksite.value !== prevProps.worksite.value ||
      this.props.date.month !== prevProps.date.month ||
      this.props.date.year !== prevProps.date.year ||
      !_.isEqual(prevProps.hours, this.props.hours)
    ) {
      console.log('COMPONENT UPDEJATAN');
      this.generateHeads();
    }

    const { appStatus, dispatch } = this.props;
    if (appStatus.showRouterBlockNotif && !prevProps.appStatus.showRouterBlockNotif) {
      const saveFirst = window.confirm('Ali želite shraniti ure, predno zapustite to stran?');
      dispatch(setRouterBlockNotif(null));
      this.handleLeavePage(saveFirst, appStatus.showRouterBlockNotif);
    }
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
    window.removeEventListener('beforeunload', this.handleWindowBeforeUnload);
  }

  async onTableUpdate(data) {
    this.postDataFromRow(data);
  }

  getEditableEmployee = async () => {
    const { selectedEmployeeId } = this.state;

    const data = await employeesApi.getOne(JSON.stringify({ id: selectedEmployeeId }));
    return data;
  };

  showEmployeesFormModal = async tabNum => {
    const data = await this.getEditableEmployee();
    this.setState({
      employeesFormModalShown: true,
      editableEmployee: data,
      employeeSelectedTab: tabNum,
    });
  };

  hideEmployeesFormModal = res => {
    if (res && res.success) {
      this.setState({ refresh: true });
    }
    this.setState({ employeesFormModalShown: false });
  };

  handleWindowBeforeUnload = e => {
    if (!this.state.unsaved) return '';
    // Chrome requires returnValue to be set.
    e.preventDefault();
    e.returnValue = 'unloading';

    // return something to trigger a dialog
    return 'Do something';
  };

  handleScroll() {
    if (window.pageYOffset > 358) {
      if (!this.state.showSticky) this.setState({ showSticky: true });
    } else if (this.state.showSticky) this.setState({ showSticky: false });
  }

  isReservedLetterCode = value => {
    if (
      value &&
      (value.toUpperCase() === 'D' ||
        value.toUpperCase() === 'B' ||
        value.toUpperCase() === 'P' ||
        value.toUpperCase() === 'C')
    ) {
      return true;
    }
    return false;
  };

  checkIfOtherOWHoursForWorkerExist(workerId, date, owId, whAllOWs, value) {
    try {
      if (this.isReservedLetterCode(value)) {
        const time = `${date.toString()}000`;
        const workerAllOw = whAllOWs.find(w => w._id === workerId);
        let clashList = workerAllOw
          ? workerAllOw.workdays.filter(
              w => w.owId !== owId && new Date(w.date).getTime().toString() === time && !w.code,
            )
          : [];
        clashList = clashList.filter(cl => cl.hours > 0 || cl.hours < 0 || cl.nightHours > 0 || cl.nightHours < 0);
        if (clashList.length > 0) {
          return true;
        }
      }
    } catch (error) {
      // console.log('TCL: Table -> checkIfOtherOWHoursForWorkerExist -> error', error);
    }
    return false;
  }

  // eslint-disable-next-line react/prop-types
  LetterCodeFormatter({ value, dependentValues }) {
    if (this.isReservedLetterCode(value)) {
      if (this.props) {
        const {
          workers: { whAllOWs, owId },
        } = this.props.hours;

        const isClash = this.checkIfOtherOWHoursForWorkerExist(
          dependentValues.rowData.lastName.key,
          dependentValues.columnInfo.key,
          owId,
          whAllOWs,
          value,
        );

        if (isClash) {
          return <div className="N-letter-code">{value.toUpperCase()}</div>;
        }
      }
      // console.log('TCL: Table -> LetterCodeFormatter -> dependentValues', dependentValues);

      let classname;
      switch (value) {
        case 'D':
        case 'd':
          classname = 'D-letter-code';
          break;
        case 'B':
        case 'b':
          classname = 'B-letter-code';
          break;
        case 'C':
        case 'c':
          classname = 'C-letter-code';
          break;
        case 'P':
        case 'p':
          classname = 'P-letter-code';
          break;
        default:
          classname = '';
          break;
      }
      return <div className={classname}>{value.toUpperCase()}</div>;
    }

    return value;
  }

  async postDataFromRow({ row, updated }) {
    clearTimeout(this.UPDATE_TIMEOUT);
    const {
      workers: { workers, whAllOWs, owId },
    } = this.props.hours;
    const { indexMap } = this.state;
    const { worksite, orderer } = this.props;
    const dailySearch = indexMap.find(indexes => indexes.dailyIndex === row);
    const nightlySearch = indexMap.find(indexes => indexes.nightlyIndex === row);
    const nightly = !!nightlySearch && !dailySearch;
    const searched = nightly ? nightlySearch : dailySearch;
    if (searched) {
      const worker = workers[searched.workerIndex];
      const keyDate = Object.keys(updated)[0];
      const { evidanceRows } = this.state;
      const otherRow = nightly ? row - 1 : row + 1;
      const other = evidanceRows[otherRow][keyDate];
      const updatedRows = evidanceRows.map((updateRow, index) => {
        if (index === row) {
          const clone = Object.assign({}, updateRow);
          clone[keyDate] = updated[keyDate];
          return clone;
        }
        return updateRow;
      });

      this.setState({ evidanceRows: updatedRows });

      const otherHours = other && other.length ? other : 0;
      const hours = nightly
        ? {
            nightHours: updated[keyDate].replace(/ *\([^)]*\) */g, ''),
            hours: otherHours ? otherHours.replace(/ *\([^)]*\) */g, '') : otherHours,
          }
        : {
            hours: updated[keyDate].replace(/ *\([^)]*\) */g, ''),
            nightHours: otherHours ? otherHours.replace(/ *\([^)]*\) */g, '') : otherHours,
          };

      const additionalId = findAdditional(keyDate, worker.workdays);
      const isCopy = findCopy(keyDate, worker.workdays);

      const isClash = this.checkIfOtherOWHoursForWorkerExist(worker._id, keyDate, owId, whAllOWs, hours.hours);
      if (isClash) {
        console.log('TCL: Table -> postDataFromRow -> isClash', isClash);
        hours.hours = 'N';
        hours.nightHours = 'N';
        this.setState({ showClashWarning: true });
      }

      const postData = {
        workerId: worker._id,
        ordererId: orderer.value,
        worksiteId: worksite.value,
        selfReported: false,
        transfer: false,
        copy: isCopy,
        date: keyDate + additionalId,
        ...hours,
      };

      console.log('postData', postData);
      this.setState({ unsaved: true });
      this.props.dispatch(setRouterBlock(true));
      workhoursApi.addhours(postData);
    } else {
      console.log('There was an error with searched');
    }
  }

  async postHoursAndUpdate(noUpdate = false) {
    this.setState({ postingHours: true });
    await workhoursApi.postHours();
    this.props.dispatch(setRouterBlock(false));
    this.props.dispatch(setRouterBlockNotif(null));
    this.setState({ unsaved: false, postingHours: false, refresh: false });
    const { orderer, worksite } = this.props;
    let owString = worksite ? `${worksite.label} - ` : '';
    owString += orderer ? orderer.label : '';
    this.props.onHoursPosted({
      success: true,
      message: `Ure za ${owString} so USPEŠNO shranjene!`,
    });
    if (noUpdate) {
      console.log('DELAM UPDATE');
      this.props.onUpdate();
    }
  }

  async handleLeavePage(saveFirst, route) {
    if (saveFirst) {
      await this.postHoursAndUpdate();
    } else {
      this.props.dispatch(setRouterBlock(false));
      this.props.dispatch(setRouterBlockNotif(null));
    }

    this.setState({ routeTo: route });
  }

  generateHeads() {
    const staticHead = [
      {
        key: 'lastName',
        name: 'Delavec',
        width: 120,
        cellClass: 'white-bcg-row',
      },
    ];
    const {
      date: { month, year },
    } = this.props;

    const daysInMonths = getDaysInMonth(month, year);
    let heads = staticHead.concat(
      daysInMonths.map(day => {
        const edi = rowData => rowData.editable === true;
        const kw = moment(day)
          .tz(TIMEZONE)
          .isoWeek();
        const kwId = `kw${moment(day).unix()}`;
        return {
          key: String(moment(day).unix()),
          name: (
            <Fragment>
              <span id={kwId}>{moment(day).format('ddd')}</span>
              <UncontrolledTooltip placement="left" target={kwId}>
                {`KW ${kw}`}
              </UncontrolledTooltip>
            </Fragment>
          ),
          editable: edi,
          getRowMetaData: (rowData, columnInfo) => ({ rowData, columnInfo }),
          width: 45,
          cellClass: moment(day).day() === 0 || moment(day).day() === 6 ? 'weekend-row' : 'white-bcg-row',
          formatter: this.LetterCodeFormatter,
        };
      }),
    );

    const lastHead = [
      {
        key: 'totalSum',
        name: 'Skup.',
        width: 45,
        cellClass: 'white-bcg-row',
      },
    ];

    heads = heads.concat(lastHead);

    let headsSticky = staticHead.concat(
      daysInMonths.map(day => {
        const kw = moment(day)
          .tz(TIMEZONE)
          .isoWeek();
        const kwId = `kwst${moment(day).unix()}`;
        return {
          key: String(moment(day).unix()),
          name: (
            <Fragment>
              <span id={kwId}>{moment(day).format('ddd')}</span>
              <UncontrolledTooltip placement="left" target={kwId}>
                {`KW ${kw}`}
              </UncontrolledTooltip>
            </Fragment>
          ),
          editable: false,
          width: 45,
          cellClass: moment(day).day() === 0 || moment(day).day() === 6 ? 'weekend-row' : 'white-bcg-row',
        };
      }),
    );

    headsSticky = headsSticky.concat(lastHead);

    this.setState({ heads, headsSticky });
    this.generateRows(heads);
  }

  toggleEmployeesPopover = (target = null, employeeId = null) => {
    this.setState(prevState => ({
      employeePopoverShown: !prevState.employeePopoverShown,
      employeePopoverTarget: target,
      selectedEmployeeId: employeeId,
    }));
  };

  generateRows(heads) {
    const rows = [];
    const rowsSticky = [];
    let totalDaySum = 0;
    let totalNightSum = 0;

    const {
      workers: { workers, owId },
    } = this.props.hours;

    const { ROLES } = this.state;
    const { t } = this.props;

    const initialRow = {};
    heads.forEach(head => {
      if (head.key === 'lastName') {
        initialRow[head.key] = '/';
      } else if (head.key === 'totalSum') {
        initialRow[head.key] = '';
      } else {
        const formated = moment.unix(Number(head.key)).format('D');
        initialRow[head.key] = formated;
      }
    });
    initialRow.editable = false;
    rows.push(initialRow);
    rowsSticky.push(initialRow);

    const indexMap = [];
    workers.forEach((worker, index) => {
      // console.log('TCL: Table -> generateRows -> worker', worker);
      const workerIndexMap = { workerIndex: index };
      const dailyRow = {};
      const nightlyRow = {};

      heads.forEach(head => {
        if (head.key === 'lastName') {
          dailyRow[head.key] = (
            <Fragment>
              <Badge
                style={{
                  backgroundColor: `${worker.color !== '#ffffff' ? worker.color : 'red'}`,
                  color: '#646777',
                  padding: 8,
                  cursor: ROLES.full ? 'pointer' : 'default',
                }}
                key={worker._id}
                id={`EmployeesPopover${worker._id}${owId}`}
                onClick={() => this.toggleEmployeesPopover(`EmployeesPopover${worker._id}${owId}`, worker._id)}
                title={ROLES.full ? t('board.ow.popover.editWorker') : worker.notes || ''}
              >
                {worker.label
                  .trim()
                  .split(' ')
                  .map((part, idx) => {
                    if (idx < worker.label.trim().split(' ').length - 1) {
                      return <div key={shortid.generate()}>{part}</div>;
                    }
                    if (idx === worker.label.trim().split(' ').length - 1) {
                      return (
                        <div key={shortid.generate()}>
                          {part} {ROLES.full && worker.notes && <PencilIcon size="12px" />}
                        </div>
                      );
                    }
                    return null;
                  })}
              </Badge>
              {ROLES.full && worker.notes && (
                <UncontrolledTooltip placement="right" target={`EmployeesPopover${worker._id}${owId}`}>
                  {`${t('board.notes.popoverTitle').toUpperCase()}: ${worker.notes}`}
                </UncontrolledTooltip>
              )}
            </Fragment>
          );
          nightlyRow[head.key] = 'Nočne ure';
        } else if (head.key === 'totalSum' && worker.workdays) {
          try {
            const sumDay = worker.workdays.reduce((a, b) => ({
              dayHours: a.dayHours + b.dayHours,
            }));
            const sumNight = worker.workdays.reduce((a, b) => ({
              nightHours: a.nightHours + b.nightHours,
            }));
            totalDaySum += sumDay.dayHours;
            totalNightSum += sumNight.nightHours;
            dailyRow[head.key] = commify(fix(sumDay.dayHours));
            nightlyRow[head.key] = commify(fix(sumNight.nightHours));
          } catch (e) {
            dailyRow[head.key] = 0;
            nightlyRow[head.key] = 0;
          }
        } else if (worker.workdays) {
          const workday = worker.workdays.find(e => e.date.toString() === `${head.key.toString()}000`);
          if (workday) {
            let workdayDayHours =
              workday.workerDayDiff !== 0 || workday.dayHours ? `${commify(fix(workday.dayHours))}` : '';
            workdayDayHours = workdayDayHours === '' && workday.code ? workday.code : workdayDayHours;
            dailyRow[head.key] = workdayDayHours;

            const workdayNightHours =
              workday.workerNightDiff !== 0 || workday.nightHours ? `${commify(fix(workday.nightHours))}` : '';
            nightlyRow[head.key] = workdayNightHours;
          } else {
            dailyRow[head.key] = '';
            nightlyRow[head.key] = '';
          }
        } else {
          dailyRow[head.key] = '';
          nightlyRow[head.key] = '';
        }
      });
      dailyRow.editable = true;
      nightlyRow.editable = true;

      rows.push(dailyRow);
      workerIndexMap.dailyIndex = rows.length - 1;
      rows.push(nightlyRow);
      workerIndexMap.nightlyIndex = rows.length - 1;
      indexMap.push(workerIndexMap);
    });
    this.forceUpdate();
    this.setState({
      rows,
      rowsSticky,
      indexMap,
      evidanceRows: rows,
      totalDaySum,
      totalNightSum,
    });
  }

  hideWarning = () => {
    this.setState({ showClashWarning: false });
  };

  render() {
    const {
      routeTo,
      employeePopoverShown,
      employeePopoverTarget,
      ROLES,
      employeesFormModalShown,
      editableEmployee,
      employeeSelectedTab,
    } = this.state;

    if (routeTo) {
      return <Redirect to={routeTo} />;
    }

    const { rows, heads, rowsSticky, headsSticky, unsaved, refresh, postingHours, showClashWarning } = this.state;
    const { worksite, orderer, date, t } = this.props;
    if (!heads.length || !rows.length) {
      return '';
    }

    const refreshOnly = !unsaved && refresh;

    const btnMessage = postingHours ? (
      <p>
        {refreshOnly ? 'OSVEŽUJEM' : 'OBDELJUEM URE'}
        <ReactLoading type="spin" color="cyan" height={16} width={16} className="react-loader" />
      </p>
    ) : (
      <span>{refreshOnly ? 'OSVEŽI' : 'POTRDI VNEŠENE URE'}</span>
    );

    // console.log('employeePopoverTarget', employeePopoverTarget);

    const PTvalid = typeof employeePopoverTarget === 'string';

    return (
      <Card>
        <CardHeader>
          {' '}
          {`${worksite ? worksite.label : ''} - ${orderer ? orderer.label : ''} - ${date ? date.month : ''} ${
            date ? date.year : ''
          }`}{' '}
          {showClashWarning && (
            <span className="clash-warning" onClick={this.hideWarning} role="button" title={t('misc.hide')}>
              {t('lettercode.warning')}
            </span>
          )}
          {(unsaved || refresh) && (
            <Button
              className={postingHours ? 'icon icon--right' : ''}
              color={!postingHours ? 'danger' : 'warning'}
              size="sm"
              style={{ marginLeft: '20px' }}
              onClick={() => this.postHoursAndUpdate(true)}
            >
              {btnMessage}
            </Button>
          )}
        </CardHeader>
        <CardBody>
          <EditableTable heads={heads} rows={rows} onUpdate={this.postDataFromRow} />
          {this.state.showSticky && (
            <EditableTable
              heads={headsSticky}
              rows={rowsSticky}
              additionalClass="sticky-header"
              onUpdate={() => null}
            />
          )}
          <h2>Skupaj dnevne: {commify(fix(this.state.totalDaySum))}</h2>
          <h2>Skupaj nočne: {commify(fix(this.state.totalNightSum))}</h2>
          <h2>Skupaj: {commify(fix(this.state.totalDaySum + this.state.totalNightSum))}</h2>

          {employeePopoverTarget && PTvalid && ROLES.full && (
            <Popover
              placement="right"
              isOpen={employeePopoverShown}
              target={employeePopoverTarget}
              toggle={this.toggleEmployeesPopover}
            >
              <PopoverHeader>{t('board.ow.popover.editWorker')}</PopoverHeader>
              <PopoverBody className="board-actions">
                <Button color="primary" className="mr-0 mb-2" onClick={() => this.showEmployeesFormModal('1')}>
                  {t('board.ow.popover.profileWorker')}
                </Button>
                <Button color="primary" className="mr-0 mb-2" onClick={() => this.showEmployeesFormModal('4')}>
                  {t('board.notes.modalTitle')}
                </Button>
              </PopoverBody>
            </Popover>
          )}

          {employeesFormModalShown && (
            <EmployeesFormModal
              title={t('board.ow.popover.editWorker')}
              icon="edit"
              show={employeesFormModalShown}
              onClose={this.hideEmployeesFormModal}
              editData={editableEmployee}
              onDocumentRemoved={() => null}
              initialTab={employeeSelectedTab}
            />
          )}
        </CardBody>
      </Card>
    );
  }
}

Table.propTypes = {
  dispatch: PropTypes.func.isRequired,
  date: PropTypes.object.isRequired,
  orderer: PropTypes.object.isRequired,
  worksite: PropTypes.object.isRequired,
  onUpdate: PropTypes.func.isRequired,
  onHoursPosted: PropTypes.func.isRequired,
  hours: HoursProps.isRequired,
  appStatus: AppStatusProps.isRequired,
  t: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  hours: state.hours,
  appStatus: state.appStatus,
});

const wr = connect(mapStateToProps)(Table);

export default translate('common')(wr);
