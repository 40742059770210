import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import shortid from 'shortid';
import { Card, CardBody, Col, ButtonToolbar, Button } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import employeesApi from 'api/employees';
import { connect } from 'react-redux';
import CalendarBlankIcon from 'mdi-react/CalendarBlankIcon';
import ReactLoading from 'react-loading';
import { translate } from 'react-i18next';
import vehiclesApi from 'api/vehicles';
import Modal from '../../../shared/components/Modal';
import renderDatePickerField from '../../../shared/components/form/DatePicker';
import Select from '../../../shared/components/form/Select';
import PlaceholderField from './PlaceholderField';
import validate from './validate';
import renderCheckBoxField2 from '../../../shared/components/form/CheckBox2';
import { DataListProps } from '../../../shared/prop-types/ReducerProps';
import { TIMEZONE } from '../../../constants/config';

class NapotitveForm extends React.Component {
  constructor(props) {
    super(props);

    let shouldDisable = false;
    if (props.editData) {
      shouldDisable = !props.editData.staged
        ? moment(props.editData.date)
            .tz(TIMEZONE)
            .add(1, 'day')
            .startOf('day')
            .toDate() < new Date(Date.now())
        : false;
    }
    const placeholders = props.editData && props.editData.placeholders ? props.editData.placeholders : [];
    this.state = {
      fieldsCount: props.initialFieldCount,
      removedFieldIds: [],
      disableSave: shouldDisable,
      loading: false,
      placeholders,
      placeholderChanged: false,
    };

    this.renderWorkerFields = this.renderWorkerFields.bind(this);
    this.addField = this.addField.bind(this);
    this.removeField = this.removeField.bind(this);
    this.hijackSubmit = this.hijackSubmit.bind(this);
    this.handleSelectWorker = this.handleSelectWorker.bind(this);

    this.handleSelectVehicle = this.handleSelectVehicle.bind(this);
  }

  /* componentDidMount() {
    this.fetchState();
  }

  async fetchState() {
    const data = await POST(API_LINK + API_EMPLOYEES_STATE, JSON.stringify({}));
    if (data.success) {
      this.setState({ workersState: data.workersState, loading: false });
    }
  } */

  addField() {
    this.setState({ fieldsCount: this.state.fieldsCount + 1 });
  }

  removeField(ctx, fieldNum) {
    const rf = ctx.state.removedFieldIds;
    rf.push(fieldNum);
    ctx.setState({ removedFieldIds: rf });
    ctx.forceUpdate();
  }

  hijackSubmit = (e, commit = false) => {
    e.preventDefault();

    // eslint-disable-next-line
    const { handleSubmit, onSubmit } = this.props;
    const { removedFieldIds, placeholders } = this.state;

    handleSubmit(values => {
      const valuesCopy = JSON.parse(JSON.stringify(values));
      valuesCopy.extra = removedFieldIds;
      valuesCopy.placeholders = placeholders.map(placeholder => ({
        professionId: placeholder.profession.value,
      }));
      valuesCopy.commit = commit;
      onSubmit(valuesCopy);
    })();
  };

  canRemove = () => {
    const { fieldsCount, removedFieldIds, placeholders } = this.state;
    return (
      fieldsCount - removedFieldIds.length > 1 ||
      placeholders.length > 1 ||
      (fieldsCount - removedFieldIds.length > 0 && placeholders.length > 0)
    );
  };

  async handleSelectWorker(e, fieldNum) {
    if (!e.value) {
      return;
    }

    this.setState({ [`workerWarning${fieldNum}`]: '' });
    /*eslint-disable*/
    const {
      form: {
        napotitve_form: { values },
      },
      t,
    } = this.props;
    /* eslint-enable */

    const employeeAssState = await employeesApi.getEmployeeCurrentAssignmentState(e.value);
    if (employeeAssState) {
      const warning = (
        <React.Fragment>
          <span>{`${t('form.warn')}: ${e.surname} ${e.name} ${t('form.isOn')} `}</span>
          <b>{`${employeeAssState.worksiteTitle}!`}</b>
        </React.Fragment>
      );

      // eslint-disable-next-line react/prop-types
      if (values && values.worksiteId === employeeAssState.worksiteId) {
        this.setState({ [`workerWarning${fieldNum}`]: '' });
      } else {
        this.setState({ [`workerWarning${fieldNum}`]: warning });
      }
    } else {
      this.setState({ [`workerWarning${fieldNum}`]: '' });
    }

    /* let found = false;
    workersState.forEach((worker) => {
      if (e.value === worker.workerId) {
        found = true;
        const warning = (
          <React.Fragment>
            <span>{`POZOR: ${e.surname} ${e.name} je trenutno na `}</span>
            <b>{`${worker.worksite}!`}</b>
          </React.Fragment>
        );

        if (values && values.worksiteId === worker.worksiteId) {
          this.setState({ [`workerWarning${fieldNum}`]: '' });
        } else {
          this.setState({ [`workerWarning${fieldNum}`]: warning });
        }
      }
    });

    if (!found) {
      this.setState({ [`workerWarning${fieldNum}`]: '' });
    } */
  }

  async handleSelectVehicle(e, fieldNum) {
    // console.log('TCL: NapotitveForm -> handleSelectVehicle -> e', e);
    /*eslint-disable*/
    const {
      form: {
        napotitve_form: { values },
      },
      t,
    } = this.props;
    /* eslint-enable */

    // eslint-disable-next-line react/prop-types
    const date = values && values.date ? values.date : new Date(Date.now());

    const { driver } = await vehiclesApi.getCurrentDriver({
      vehicleId: e.value,
      date,
    });

    if (driver) {
      if (
        values &&
        values[`workerId${fieldNum}`] &&
        // eslint-disable-next-line react/prop-types
        values[`workerId${fieldNum}`].value === driver.driver._id
      ) {
        this.setState({ [`vehicleWarning${fieldNum}`]: '' });
      } else {
        const warning = (
          <React.Fragment>
            <span>{`${t('form.warn')}! ${e.label.toUpperCase()} ${t('form.isDriving')} `}</span>
            <b>{`${driver.driver.surname.toUpperCase()} ${driver.driver.name}!`}</b>
          </React.Fragment>
        );

        this.setState({ [`vehicleWarning${fieldNum}`]: warning });
      }
    } else {
      this.setState({ [`vehicleWarning${fieldNum}`]: '' });
    }
  }

  addPlacholderField = () => {
    const {
      dataList: { professions },
    } = this.props;
    this.setState(state => {
      const newPlacholder = { profession: professions[0] };
      const placeholders = [...state.placeholders, newPlacholder];
      return {
        placeholders,
        placeholderChanged: true,
      };
    });
  };

  removePlaceholderField = index => {
    const fields = this.state.placeholders.filter((item, i) => index !== i);
    this.setState({
      placeholders: fields,
      placeholderChanged: true,
    });
  };

  switchPlaceholderToWorkerField = index => {
    this.removePlaceholderField(index);
    this.addField();
  };

  handleProfessionChange = (index, selected) => {
    this.setState(state => {
      const temp = state.placeholders;
      temp[index].profession = selected;
      return {
        placeholders: temp,
        placeholderChanged: true,
      };
    });
  };

  renderWorkerFields(workers, accommodations, vehicles) {
    const { fieldsCount, removedFieldIds } = this.state;
    const that = this;
    const fields = [];
    for (let index = 0; index < fieldsCount; index += 1) {
      if (!removedFieldIds.includes(index)) fields.push(index);
    }
    return fields.map(fieldNum => {
      const elements = (
        <React.Fragment>
          <div key={`form-assignments-field-${fieldNum}`} className="form-assignments-fields">
            <div className="mr-ass">
              <Field
                name={`workerId${fieldNum}`}
                component={Select}
                options={workers}
                onChange={e => this.handleSelectWorker(e, fieldNum)}
              />
            </div>
            <div className="mr-ass">
              <Field name={`accommodationId${fieldNum}`} component={Select} options={accommodations} />
            </div>
            <div className="mr-ass">
              <Field
                name={`vehicleId${fieldNum}`}
                component={Select}
                options={vehicles}
                onChange={e => this.handleSelectVehicle(e, fieldNum)}
              />
            </div>
            <div className="last2">
              <Field
                name={`startsFrom${fieldNum}`}
                component={renderCheckBoxField2}
                color="#8865D6"
                className="button"
              />
            </div>
            {this.canRemove() && (
              <div style={{ width: '0px' }}>
                <Button
                  className="form-assignments-remove-worker-btn"
                  size="sm"
                  color="link"
                  onClick={() => that.removeField(that, fieldNum)}
                >
                  X
                </Button>
              </div>
            )}
          </div>
          <div key={shortid.generate()} style={{ textAlign: 'left' }}>
            {this.state[`workerWarning${fieldNum}`] && (
              <React.Fragment>
                <span style={{ color: 'red' }}>{this.state[`workerWarning${fieldNum}`]}</span>
                <br />
              </React.Fragment>
            )}
            <span style={{ color: 'red' }}>{this.state[`vehicleWarning${fieldNum}`]}</span>
          </div>
        </React.Fragment>
      );
      return elements;
    });
  }

  renderPlaceholderFields = () => {
    const { t } = this.props;
    return this.state.placeholders.map((placeholder, index) => (
      <div className="form-assignments-fields mb-1" key={shortid.generate()}>
        <PlaceholderField
          options={this.props.dataList.professions}
          onChange={selected => this.handleProfessionChange(index, selected)}
          name="worker-placeholder-field"
          value={placeholder.profession}
          onDelete={this.onDelete}
        />
        <Button size="sm" className="ml-1 mw-150" onClick={() => this.switchPlaceholderToWorkerField(index)}>
          {t('form.chooseWorker')}
        </Button>
        {this.canRemove() && (
          <div style={{ width: '0px' }}>
            <Button
              className="form-assignments-remove-worker-btn"
              size="sm"
              color="link"
              onClick={() => this.removePlaceholderField(index)}
            >
              X
            </Button>
          </div>
        )}
      </div>
    ));
  };

  render() {
    const {
      handleCancel,
      handleDelete,
      editData,
      orderers,
      worksites,
      workers,
      vehicles,
      accommodations,
      pristine,
      t,
    } = this.props;

    const { removedFieldIds, disableSave, loading, placeholderChanged } = this.state;

    console.log('placeholderChanged', placeholderChanged);

    if (loading) {
      return (
        <Col md={12} lg={12}>
          <Card>
            <CardBody>
              <ReactLoading type="bars" color="gray" height={128} width={128} className="react-loader" />
            </CardBody>
          </Card>
        </Col>
      );
    }

    return (
      <Col md={12} lg={12}>
        <Card>
          <CardBody>
            <form className="form" onSubmit={this.hijackSubmit}>
              <div className="form__form-group">
                <span className="form__form-group-label">{t('form.dateBegin')}</span>
                <div className="form__form-group-field">
                  <Field name="date" component={renderDatePickerField} />
                  <div className="form__form-group-icon">
                    <CalendarBlankIcon />
                  </div>
                </div>
              </div>

              <div className="form__form-group">
                <span className="form__form-group-label">{t('form.dateLeave')}</span>
                <div className="form__form-group-field">
                  <Field name="departureDate" component={renderDatePickerField} />
                  <div className="form__form-group-icon">
                    <CalendarBlankIcon />
                  </div>
                </div>
              </div>

              <div className="form__form-group">
                <span className="form__form-group-label">{t('board.ow.orderer')}</span>
                <div className="form__form-group-field">
                  <Field name="ordererId" component={Select} options={orderers} />
                </div>
              </div>

              <div className="form__form-group">
                <span className="form__form-group-label">{t('form.worksite')}</span>
                <div className="form__form-group-field">
                  <Field name="worksiteId" component={Select} options={worksites} />
                </div>
              </div>

              <div className="form-assignments-titles">
                <div className="mr-ass">
                  <span className="form__form-group-label">{t('board.ow.worker')}</span>
                </div>
                <div className="mr-ass">
                  <span className="form__form-group-label">{t('board.ow.accommodation')}</span>
                </div>
                <div className="mr-ass">
                  <span className="form__form-group-label">{t('board.ow.vehicle')}</span>
                </div>
                <div className="last2">
                  <span className="form__form-group-label">{t('form.startsFrom')}</span>
                </div>
              </div>

              {this.renderWorkerFields(workers, accommodations, vehicles)}

              <div className="form-assignments-add-worker-btn-wrapper">
                <Button className="form-assignments-add-worker-btn" size="sm" color="link" onClick={this.addField}>
                  {t('form.chooseWorker')}
                </Button>
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">{t('form.placeholders')}</span>

                {this.renderPlaceholderFields()}
                <div className="form-assignments-add-worker-btn-wrapper">
                  <Button
                    className="form-assignments-add-worker-btn"
                    size="sm"
                    color="link"
                    onClick={this.addPlacholderField}
                  >
                    {t('form.addPlaceholder')}
                  </Button>
                </div>
              </div>

              <div className="form__form-group">
                <span className="form__form-group-label">{t('form.notes')}</span>
                <div className="form__form-group-field">
                  <Field name="notes" component="textarea" type="text" />
                </div>
              </div>

              <ButtonToolbar className="form__button-toolbar">
                {editData ? (
                  <Modal
                    color="danger"
                    title={`${t('form.warn')}!`}
                    colored
                    btn={t('form.delete')}
                    disabled={disableSave}
                    message="Ste prepričani, da želite izbrisati to napotitev?"
                    onConfirm={() => handleDelete(editData)}
                  />
                ) : null}
                <Button type="button" onClick={handleCancel}>
                  {t('form.cancel')}
                </Button>
                <Button
                  color="primary"
                  type="submit"
                  disabled={(!placeholderChanged && pristine && removedFieldIds.length === 0) || disableSave}
                >
                  {editData ? t('form.save') : t('form.add')}
                </Button>
                {editData && editData.staged && (
                  <Modal
                    color="danger"
                    title={`${t('form.warn')}!`}
                    colored
                    btn={t('form.confirm')}
                    message="Ste prepričani, da želite potrditi to napotitev?"
                    onConfirm={e => this.hijackSubmit(e, true)}
                  />
                )}
              </ButtonToolbar>
              {disableSave && (
                <strong style={{ color: 'red' }}>
                  <br />
                  {t('form.ban')}!
                  <br />
                  {localStorage.getItem('lang') === 'sl' && `SPREMBE SE IZVAJAJO Z GUMBOM "${'UREDI STANJE'}"`}
                </strong>
              )}
            </form>
          </CardBody>
        </Card>
      </Col>
    );
  }
}

NapotitveForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  orderers: PropTypes.arrayOf(PropTypes.object).isRequired,
  worksites: PropTypes.arrayOf(PropTypes.object).isRequired,
  workers: PropTypes.arrayOf(PropTypes.object).isRequired,
  vehicles: PropTypes.arrayOf(PropTypes.object).isRequired,
  accommodations: PropTypes.arrayOf(PropTypes.object).isRequired,
  dataList: DataListProps.isRequired,
  editData: PropTypes.object,
  initialFieldCount: PropTypes.number,
  t: PropTypes.func.isRequired,
};

NapotitveForm.defaultProps = {
  editData: null,
  initialFieldCount: 1,
};

const wr = reduxForm({
  form: 'napotitve_form', // a unique identifier for this form
  validate,
})(
  connect(state => ({
    form: state.form,
    dataList: state.dataList,
  }))(NapotitveForm),
);

export default translate('common')(wr);
