import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import { Col, Card, CardBody, ButtonToolbar, Button } from 'reactstrap';
import Modal from 'shared/components/Modal';
import financeApi from 'api/finance';
import TransactionsTable from '../../Cashbox/components/Tables/TransactionsTable';
import TemplateTransactionsTable from './Tables/TemplateTransactionsTable';

let currEmployeeId = null;
class CompensationForm extends Component {
  static propTypes = {
    employeeId: PropTypes.string.isRequired,
    month: PropTypes.string.isRequired,
    year: PropTypes.string.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      oldTransactions: [],
      templateTransactions: [],
    };
  }

  componentDidMount() {
    currEmployeeId = null;
  }

  componentDidUpdate() {
    if (currEmployeeId !== this.props.employeeId) {
      currEmployeeId = this.props.employeeId;
      this.fetchEmployeeTransactionsAndTemplates();
    }
  }

  componentWillUnmount() {
    currEmployeeId = null;
  }

  fetchEmployeeTransactionsAndTemplates = async () => {
    const { employeeId, month, year } = this.props;

    const { oldTransactions, templateTransactions } = await financeApi.getEmployeeTransactionsForCompensation(
      month,
      year,
      employeeId,
    );
    this.setState({ oldTransactions, templateTransactions });
  };

  renderOldTransactionsTable = () => {
    const { oldTransactions } = this.state;

    if (oldTransactions.length > 0) {
      return (
        <Fragment>
          <h5 style={{ textAlign: 'left' }}>
            <strong>STARE TRANSAKCIJE</strong>
          </h5>
          <br />
          <TransactionsTable transactions={oldTransactions} onRowClick={() => null} />
        </Fragment>
      );
    }

    return null;
  };

  renderTemplateTransactions = () => {
    const { templateTransactions } = this.state;
    if (templateTransactions.length > 0) {
      return (
        <Fragment>
          <h5 style={{ textAlign: 'left' }}>
            <strong>SUKCESIVNE TRANSAKCIJE</strong>
          </h5>
          <br />
          <TemplateTransactionsTable templateTransactions={templateTransactions} />
        </Fragment>
      );
    }
    return null;
  };

  render() {
    return (
      <Col md={12} lg={12}>
        <Card>
          <CardBody>
            {this.renderTemplateTransactions()}
            <br />
            {this.renderOldTransactionsTable()}
          </CardBody>
        </Card>
      </Col>
    );
  }
}

export default translate('common')(CompensationForm);
