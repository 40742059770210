import React from 'react';
import { Card, CardBody, Col, ButtonToolbar, Button } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import renderSelectField from '../../../shared/components/form/Select';
import Modal from '../../../shared/components/Modal';
import validate from './validate';
import renderTextField from '../../../shared/components/form/TextField';
import renderMultiSelectField from '../../../shared/components/form/MultiSelect';

const GradbiscaForm = ({ handleSubmit, handleCancel, handleDelete, countries, editData, pristine, accommodations }) => (
  <Col md={12} lg={12}>
    <Card>
      <CardBody>
        <form className="form" onSubmit={handleSubmit}>
          <div className="form__form-group">
            <span className="form__form-group-label">Naziv</span>
            <div className="form__form-group-field">
              <Field name="title" component={renderTextField} type="text" />
            </div>
          </div>

          <div className="form__form-group">
            <span className="form__form-group-label">Delovni Nalog</span>
            <div className="form__form-group-field">
              <Field name="workOrder" component={renderTextField} type="text" />
            </div>
          </div>

          <div className="form__form-group">
            <span className="form__form-group-label">Dodatne info. za Tablo</span>
            <div className="form__form-group-field">
              <Field name="boardInfo" component={renderTextField} />
            </div>
          </div>

          <div className="form__form-group">
            <span className="form__form-group-label">Naslov</span>
            <div className="form__form-group-field">
              <Field name="address" component={renderTextField} type="text" />
            </div>
          </div>

          <div className="form__form-group">
            <span className="form__form-group-label">Poštna številka</span>
            <div className="form__form-group-field">
              <Field name="zip" component={renderTextField} type="text" />
            </div>
          </div>

          <div className="form__form-group">
            <span className="form__form-group-label">Kraj</span>
            <div className="form__form-group-field">
              <Field name="city" component={renderTextField} type="text" />
            </div>
          </div>

          <div className="form__form-group">
            <span className="form__form-group-label">Država</span>
            <div className="form__form-group-field">
              <Field name="countryId" component={renderSelectField} options={countries} />
            </div>
          </div>

          <div className="form__form-group leave-select">
            <span className="form__form-group-label">Prenočišča</span>
            <div className="form__form-group-field">
              <Field name="accommodations" component={renderMultiSelectField} options={accommodations} />
            </div>
          </div>

          <ButtonToolbar className="form__button-toolbar">
            {editData ? (
              <Modal
                color="danger"
                title="Pozor!"
                colored
                btn="Izbris"
                message={`Ste prepričani da želite izbrisati gradbišče z nazivom ${editData.title}?`}
                onConfirm={() => handleDelete(editData)}
              />
            ) : null}
            <Button type="button" onClick={handleCancel}>
              Prekliči
            </Button>
            <Button color="primary" type="submit" disabled={pristine}>
              {editData ? 'Shrani spremembe' : 'Dodaj'}
            </Button>
          </ButtonToolbar>
        </form>
      </CardBody>
    </Card>
  </Col>
);

GradbiscaForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
  accommodations: PropTypes.arrayOf(PropTypes.object).isRequired,
  countries: PropTypes.arrayOf(PropTypes.object).isRequired,
  editData: PropTypes.object,
  pristine: PropTypes.bool.isRequired,
};

GradbiscaForm.defaultProps = {
  editData: null,
};

export default reduxForm({
  form: 'gradbisca_form', // a unique identifier for this form
  validate,
})(GradbiscaForm);
