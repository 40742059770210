import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import ReactExport from 'react-data-export';
import { Button } from 'reactstrap';
import { TIMEZONE } from '../../../../constants/config';

/* eslint-disable */
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
/* eslint-enable */

const DownloadButton = title => (
  <Button size="sm" color="primary" style={{ marginBottom: '10px', marginLeft: '15px' }}>
    {title}
  </Button>
);

const toMonthDay = date =>
  moment(date)
    .tz(TIMEZONE)
    .date();

const generateHeaders = title => {
  const firstPart = [
    {
      title,
    },
    {
      title: '',
    },
    {
      title: '',
    },
    {
      title: '',
    },
    {
      title: '',
    },
    {
      title: '',
    },
    {
      title: '',
    },
    {
      title: '',
    },
  ];

  const multiDataSet = [
    {
      columns: firstPart,
      data: [],
    },
  ];
  return multiDataSet;
};

const generateData = (dataAll, isVehicle) => {
  console.log('TCL: generateData -> dataAll', dataAll);

  const data = [];

  const emptyRow = [
    {
      value: '',
    },
    {
      value: '',
    },
    {
      value: '',
    },
    {
      value: '',
    },
    {
      value: '',
    },
    {
      value: '',
    },
    {
      value: '',
    },
    {
      value: '',
    },
  ];
  data.push(emptyRow);

  const headers = [
    {
      value: 'Datum Prevzema',
    },
    {
      value: 'Vmesni datum',
    },
    {
      value: 'Vrnil domov (datum)',
    },
    {
      value: isVehicle ? 'Delavec' : 'Vozilo',
    },
    {
      value: 'Kilometri',
    },
    {
      value: 'Znesek',
    },
    {
      value: 'Račun',
    },
    {
      value: 'Opombe',
    },
  ];

  data.push(headers);

  for (let i = 0; i < dataAll.length; i += 1) {
    const rlog = dataAll[i];

    let isFake = rlog.fake;
    const { isSwitch } = rlog;

    if (
      rlog.returnedAt &&
      toMonthDay(rlog.date) === 1 &&
      toMonthDay(rlog.returnedAt) !== 1 &&
      i < dataAll.length - 1 &&
      !isSwitch
    ) {
      isFake = true;
    }

    const taken = isFake ? '...' : rlog.dateString || '/';
    const mid = isFake ? rlog.dateString || '/' : '...';
    const returned = rlog.returnedAtString === 'Invalid date' ? '/' : rlog.returnedAtString;

    const workerRow = [
      {
        value: taken,
      },
      {
        value: rlog.vehicle ? '...' : mid,
      },
      {
        value: returned,
      },
      {
        value: rlog.employee || rlog.vehicle,
      },
      {
        value: rlog.kilometers,
      },
      {
        value: rlog.cost || 0,
      },
      {
        value: rlog.receipt ? 'DA' : 'NE',
      },
      {
        value: rlog.notes,
      },
    ];
    data.push(workerRow);
  }

  return data;
};
class VozilaIzpisiSheet extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { data, btn, title, isVehicle } = this.props;

    const headers = generateHeaders(title);
    headers[0].data = generateData(data, isVehicle);
    return (
      <ExcelFile element={DownloadButton(btn)} filename={title}>
        <ExcelSheet dataSet={headers} name="Arhiv" />
      </ExcelFile>
    );
  }
}

VozilaIzpisiSheet.propTypes = {
  btn: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  title: PropTypes.string.isRequired,
  isVehicle: PropTypes.bool.isRequired,
};

export default VozilaIzpisiSheet;
