import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-i18next';
import getDaysInMonth from 'helpers/getDaysInMonth';
import { Table, Card, CardBody, Badge, ButtonToolbar, Button, UncontrolledTooltip } from 'reactstrap';
import moment from 'moment-timezone';
import shortid from 'shortid';
import { TIMEZONE } from '../../../../constants/config';
import fix, { commify } from '../../../../helpers/floatParser';

const isWeekend = day => {
  const dayNum = new Date(day).getDay();
  return dayNum === 6 || dayNum === 0;
};

const letterCodeColor = code => {
  switch (code) {
    case 'D':
      return '#6aabb8';

    case 'B':
      return '#7c4162';

    case 'C':
      return '#6d3e23';
    case 'P':
      return '#256d23';

    default:
      break;
  }

  return '';
};

class BrankaTable extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.handleScroll = this.handleScroll.bind(this);
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll() {
    if (this.tableHead !== null) {
      if (window.pageYOffset > 220) {
        // console.log('halloo', window.pageYOffset);
        this.tableHead.className = 'sticky';
        this.tableHead2.className = '';
      } else {
        this.tableHead.className = '';
        this.tableHead2.className = 'hidden-head';
      }
    }
  }

  generateColMonthHeads(month, year) {
    const daysInMonths = getDaysInMonth(month, year);
    return daysInMonths.map(day => {
      const weekend = isWeekend(day);
      return <col key={shortid.generate()} className={weekend ? 'weekend-col-table' : ''} />;
    });
  }

  generateMonthHeads(month, year) {
    const daysInMonths = getDaysInMonth(month, year);
    return daysInMonths.map(day => (
      <td key={day.getTime().toString()}>
        <p>
          {moment(day)
            .tz(TIMEZONE)
            .format('ddd')}
        </p>
        <p>
          {moment(day)
            .tz(TIMEZONE)
            .format('D.M.')}
        </p>
      </td>
    ));
  }

  renderWorkdays(workdays) {
    return workdays.map(workday => (
      <td key={shortid.generate()} /* style={workday.code ? { backgroundColor: '#6aabb8' } : {}} */>
        {workday.hours !== 0 && !workday.code ? (
          <strong style={{ color: 'black' }}>{commify(fix(workday.hours))}</strong>
        ) : workday.code ? (
          <Badge
            style={{
              backgroundColor: letterCodeColor(workday.code.toUpperCase()),
              color: '#646777',
            }}
          >
            <strong style={{ color: 'white' }}>{workday.code.toUpperCase()}</strong>
          </Badge>
        ) : (
          <span style={{ color: 'lightgray' }}>{commify(fix(workday.hours))}</span>
        )}
      </td>
    ));
  }

  renderOW(allOW) {
    return <p>{allOW.join('; ')}</p>; // map(ow => <p key={shortid.generate()}>{ow}</p>);
  }

  renderNotes(notes) {
    return notes.map(note => <p key={shortid.generate()}>{note}</p>);
  }

  renderTable(list, t) {
    return list.map((worker, index) => {
      const id = shortid.generate();

      return (
        <tr key={id} id={id} className={index === list.length - 1 ? 'smaller-row' : ''}>
          <td>{index + 1}</td>
          <td>
            <Badge style={{ backgroundColor: `${worker.color}`, color: '#646777' }}>{worker.company}</Badge>
          </td>
          <td>{worker.surname}</td>
          <td>{worker.name}</td>
          <td>
            <p>{worker.registeredAt}</p>
          </td>
          <td>{worker.unregisteredAt && <p>{worker.unregisteredAt}</p>}</td>
          {this.renderWorkdays(worker.workdays)}
          <td>
            <strong style={{ color: 'black' }}>{commify(fix(worker.internalSum))}</strong>
          </td>
          <td>
            <strong style={{ color: 'black' }}>{commify(fix(worker.totalSum))}</strong>
          </td>
          <td>{worker.allOW.length > 0 ? this.renderOW(worker.allOW) : '/'}</td>
          <td>
            {worker.notes.length > 0 ? (
              <ButtonToolbar className="btn-toolbar--center">
                <Button id={`B${id}${index}`} size="sm" color="link" className="accommodations-table-btn">
                  <strong style={{ color: 'red' }}>{t('form.notes')}</strong>
                </Button>
                <UncontrolledTooltip placement="right" target={`B${id}${index}`}>
                  {this.renderNotes(worker.notes)}
                </UncontrolledTooltip>
              </ButtonToolbar>
            ) : (
              '/'
            )}
          </td>
        </tr>
      );
    });
  }

  render() {
    const { data, month, year, t } = this.props;
    return (
      <Card className="mt-2">
        <CardBody>
          <Table className="table--bordered custom-style-branka" striped>
            <colgroup>
              <col />
              <col />
              <col />
              <col />
              <col />
              <col />
              {this.generateColMonthHeads(month, year)}
            </colgroup>
            <thead ref={ref => (this.tableHead = ref)}>
              <tr>
                <td>{t('form.num')}</td>
                <td>{t('form.company')}</td>
                <td>{t('form.surname')}</td>
                <td>{t('form.name')}</td>
                <td>{t('form.from')}</td>
                <td>{t('form.to')}</td>
                {this.generateMonthHeads(month, year)}
                <td>{t('calcs.int')}</td>
                <td>{t('calcs.sum')}</td>
                <td>{t('sidebar_content.worksites')}</td>
                <td>{t('form.notes')}</td>
              </tr>
            </thead>
            <thead ref={ref => (this.tableHead2 = ref)} className="hidden-head">
              <tr>
                <td>{t('form.num')}</td>
                <td>{t('form.company')}</td>
                <td>{t('form.surname')}</td>
                <td>{t('form.name')}</td>
                <td>{t('form.from')}</td>
                <td>{t('form.to')}</td>
                {this.generateMonthHeads(month, year)}
                <td>{t('calcs.int')}</td>
                <td>{t('calcs.sum')}</td>
                <td>{t('sidebar_content.worksites')}</td>
                <td>{t('form.notes')}</td>
              </tr>
            </thead>
            <tbody>{this.renderTable(data, t)}</tbody>
          </Table>
        </CardBody>
      </Card>
    );
  }
}

BrankaTable.propTypes = {
  data: PropTypes.array.isRequired,
  month: PropTypes.number.isRequired,
  year: PropTypes.number.isRequired,
  t: PropTypes.func.isRequired,
};

export default translate('common')(BrankaTable);

/* <ButtonToolbar className="btn-toolbar--center">
                <Button id={`A${id}${index}`} size="sm" color="link" className="accommodations-table-btn">
                  <strong style={{ color: 'red' }}>GRADBIŠČA</strong>
                </Button>
                <UncontrolledTooltip placement="right" target={`A${id}${index}`}>
                  {this.renderOW(worker.allOW)}
                  </UncontrolledTooltip>
                </ButtonToolbar>  */
