import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-i18next';
import { connect } from 'react-redux';
import { Card, CardBody, Table } from 'reactstrap';
import * as i18nIsoCountries from 'i18n-iso-countries';
import HomeBoardTableRow from './HomeBoardTableRow';

i18nIsoCountries.registerLocale(require('i18n-iso-countries/langs/sl.json'));

class HomeBoard extends Component {
  static propTypes = {
    ow: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
  };

  renderTable = (employees, ow) =>
    employees.map(employee => <HomeBoardTableRow key={employee._id} employee={employee} ow={ow} />);

  renderLive = () => {
    const {
      ow: { live },
      t,
    } = this.props;
    if (!live) return '';

    const { employees } = live;
    return (
      <Fragment>
        <div className="card__title">
          <div className="row">
            <div className="col-9 col-sm-12">
              <h5 className="bold-text">{t('board.ow.home')}</h5>
            </div>
          </div>
        </div>
        <div className="tabla-table__wrapper">
          <Table className="table--bordered tabla-table" responsive>
            <thead>
              <tr>
                <th>{t('board.ow.worker')}</th>
              </tr>
            </thead>
            <tbody>{employees && this.renderTable(employees, live)}</tbody>
          </Table>
        </div>
      </Fragment>
    );
  };

  render() {
    return (
      <Card>
        <CardBody>{this.renderLive()}</CardBody>
      </Card>
    );
  }
}

const wr = connect(state => ({
  dataListStore: state.dataList,
}))(HomeBoard);

export default translate('common')(wr);
