import React from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-i18next';
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown, Row, Col, Card, CardBody } from 'reactstrap';
import ChevronDownIcon from 'mdi-react/ChevronDownIcon';
import ReactLoading from 'react-loading';
import { connect } from 'react-redux';
import ReactGA from 'react-ga';
import SelectDropdown from '../../../shared/components/SelectDropdown';
import SelectDate from '../../../shared/components/SelectDate';
import SelectDateMobile from '../../../shared/components/SelectDateMobile';
import { EmployeesProps, WorksitesProps, VehiclesProps } from '../../../shared/prop-types/ReducerProps';

let searchCriteria;

const renderCriteria = (criteria, handleClick) =>
  criteria.map(crit => (
    <DropdownItem key={crit.value} onClick={() => handleClick(crit.value, crit.label)}>
      {crit.label}
    </DropdownItem>
  ));

class CriteriaSelect extends React.PureComponent {
  static propTypes = {
    workersState: EmployeesProps.isRequired,
    worksitesState: WorksitesProps.isRequired,
    vehiclesState: VehiclesProps.isRequired,
    t: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    const { criteriaType, t } = props;
    searchCriteria = [
      { value: 'worksite', label: t('form.worksite') },
      { value: 'worker', label: t('board.ow.worker') },
      { value: 'vehicle', label: t('board.ow.vehicle') },
    ];
    if (criteriaType !== 'BOARDS') {
      searchCriteria.shift();
    }
    console.log('TCL: CriteriaSelect -> constructor -> searchCriteria', searchCriteria);
    this.state = {
      selectedCriteriaId: searchCriteria[0].value,
      selectedCriteriaLabel: searchCriteria[0].label,
      selectedCriteriaItem: null,
      selectedDate: new Date(Date.now()),
      items: [],
      worksitesList: [],
      workersList: [],
      vehiclesList: [],
      doDD1Reset: false,
      loading: false,
    };
    this.handleCriteriaChange = this.handleCriteriaChange.bind(this);
    this.handleCriteriaSubmit = this.handleCriteriaSubmit.bind(this);
    this.handleDateSelection = this.handleDateSelection.bind(this);
    this.fetchData = this.fetchData.bind(this);
  }

  componentDidMount() {
    const { workersState, vehiclesState, worksitesState } = this.props;
    if (
      workersState.employeesNames.length > 0 &&
      vehiclesState.vehicles.length > 0 &&
      worksitesState.worksites.length > 0
    ) {
      console.log('CRITERIA SELECT componentDidMount: LOADED, KLIČEM FETCH DATA!!!!');
      this.fetchData();
    }
  }

  componentDidUpdate(prevProps) {
    const { workersState, vehiclesState, worksitesState } = this.props;
    if (
      workersState.employeesNames.length > 0 &&
      vehiclesState.vehicles.length > 0 &&
      worksitesState.worksites.length > 0
    ) {
      if (
        prevProps.workersState.employeesNames.length === 0 ||
        prevProps.vehiclesState.vehicles.length === 0 ||
        prevProps.worksitesState.worksites.length === 0
      ) {
        console.log('VSE JE ZLODANO, KLIČEM FETCH DATA!!!!');
        this.fetchData();
      }
    }
  }

  fetchData() {
    const { workersState, worksitesState, vehiclesState, criteriaType } = this.props;

    const workList = [];
    worksitesState.worksites.forEach(wrk => {
      const wrkData = {
        value: wrk._id,
        label: wrk.title,
      };
      workList.push(wrkData);
    });

    this.setState({
      worksitesList: workList,
      items: criteriaType === 'BOARDS' ? workList : workersState.employeesNames,
      workersList: workersState.employeesNames,
      vehiclesList: vehiclesState.licenses,
      loading: false,
    });
  }

  async handleCriteriaChange(criteriaId, criteriaLabel) {
    this.setState({
      loading: true,
      selectedCriteriaLabel: criteriaLabel,
      selectedCriteriaId: criteriaId,
    });
    const { worksitesList, workersList, vehiclesList } = this.state;

    if (criteriaId === 'worksite') {
      if (worksitesList.length) {
        this.setState({
          items: worksitesList,
          doDD1Reset: !this.state.doDD1Reset,
          loading: false,
        });
        return;
      }
    }

    if (criteriaId === 'worker') {
      if (workersList.length) {
        this.setState({
          items: workersList,
          doDD1Reset: !this.state.doDD1Reset,
          loading: false,
        });
        return;
      }
    }

    if (criteriaId === 'vehicle') {
      if (vehiclesList.length) {
        this.setState({
          items: vehiclesList,
          doDD1Reset: !this.state.doDD1Reset,
          loading: false,
        });
        return;
      }
    }

    this.setState({
      doDD1Reset: !this.state.doDD1Reset,
    });
  }

  handleCriteriaSubmit(values) {
    const { selectedCriteriaId, selectedDate } = this.state;
    this.logFilterEvent();
    const selectionValue = values.selected_dropdown ? values.selected_dropdown.value : null;
    const data = {
      criteria: {
        id: selectedCriteriaId,
        selectedOptionId: selectionValue,
        selectedOptionLabel:
          values.selected_dropdown && values.selected_dropdown.label ? values.selected_dropdown.label : '',
      },
      date: selectedDate,
    };

    this.setState({ selectedCriteriaItem: selectionValue });

    this.props.onCriteriaSelected(data);
  }

  handleDateSelection(values) {
    this.logFilterEvent();
    const { selectedCriteriaItem, selectedCriteriaId } = this.state;

    const data = {
      criteria: {
        id: selectedCriteriaId,
        selectedOptionId: selectedCriteriaItem,
      },
      date: values.date,
    };

    this.setState({ selectedDate: values.date });

    this.props.onCriteriaSelected(data);
  }

  logFilterEvent() {
    ReactGA.event({
      category: 'Tabla',
      action: 'Filter Click',
    });
  }

  render() {
    const { selectedCriteriaLabel, items, doDD1Reset, loading } = this.state;

    const { criteriaType } = this.props;

    let theDropdown = (
      <SelectDropdown
        key="bb2"
        title={`${selectedCriteriaLabel}:`}
        items={items}
        onSubmit={this.handleCriteriaSubmit}
      />
    );

    if (doDD1Reset) {
      theDropdown = (
        <SelectDropdown
          key="bb1"
          title={`${selectedCriteriaLabel}:`}
          items={items}
          onSubmit={this.handleCriteriaSubmit}
        />
      );
    }

    return (
      <Row>
        <Col lg={6}>
          <Card>
            <CardBody>
              <Row>
                <Col lg={4}>
                  <div className="form__form-group">
                    <span className="form__form-group-label hidden-desktop">Izberi kategorijo iskanja</span>
                    <UncontrolledDropdown className="criteria-select">
                      <DropdownToggle
                        className="icon icon--right criteria-select__dropdown"
                        size="sm"
                        outline
                        onClickCapture={this.logFilterEvent}
                        disabled={loading}
                      >
                        <p>
                          {selectedCriteriaLabel} <ChevronDownIcon className="tabla__mobile-down-icon" />
                        </p>
                      </DropdownToggle>
                      <DropdownMenu className="dropdown__menu">
                        {renderCriteria(searchCriteria, this.handleCriteriaChange)}
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </div>
                </Col>
                <Col lg={8}>
                  {!loading ? theDropdown : <ReactLoading type="bubbles" color="gray" className="react-loader" />}
                </Col>
                {criteriaType === 'BOARDS' && (
                  <Col xs={12} className="d-lg-none">
                    {/* NE SMEMO MET DVEH ISTIH FORM NA ENEM COMPONENTU!! */}
                    <SelectDateMobile title="Datum" onCLick={this.logFilterEvent} onSubmit={this.handleDateSelection} />
                  </Col>
                )}
              </Row>
            </CardBody>
          </Card>
        </Col>
        {criteriaType === 'BOARDS' && (
          <Col lg={6} className="d-none d-lg-block">
            <Card>
              <CardBody>
                <Row>
                  <Col lg={12}>
                    <SelectDate onClick={this.logFilterEvent} title="Datum" onSubmit={this.handleDateSelection} />
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        )}
      </Row>
    );
  }
}

CriteriaSelect.propTypes = {
  onCriteriaSelected: PropTypes.func.isRequired,
  criteriaType: PropTypes.string,
  t: PropTypes.func.isRequired,
};

CriteriaSelect.defaultProps = {
  criteriaType: 'BOARDS',
};

const wr = connect(state => ({
  workersState: state.employees,
  worksitesState: state.worksites,
  vehiclesState: state.vehicles,
}))(CriteriaSelect);

export default translate('common')(wr);
