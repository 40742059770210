import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-i18next';
import { Table, ButtonToolbar, Progress, Button, UncontrolledTooltip } from 'reactstrap';
import financeApi from 'api/finance';
import { connect } from 'react-redux';
import { getItem, setItem } from 'helpers/cookies';
import payload from 'helpers/jwt';
import { TMZFY, onlyUnique } from 'helpers/functions';
import moment from 'moment-timezone';
import { CompaniesProps, EmployeesProps, WorksitesProps } from 'shared/prop-types/ReducerProps';
import { LockIcon, LockOpenIcon, DownloadIcon } from 'mdi-react';
import translateProfession from 'translations/dynamicTranslator';
import Dropdown from '../../../Filters/Dropdown';
import fix, { commify } from '../../../../../helpers/floatParser';
import getRoles from '../../../../../constants/roles';
import constants from '../../../helpers/index';
import regroupWorklistByWorksites from '../../../helpers/regroup';
import { MultiSelectField } from '../../../../../shared/components/form/MultiSelect';
import CheckboxForm from '../../../Filters/CheckboxForm';
import AddonsTableByWorksite from './AddonsTableByWorksite';

const W100 = { width: '100px' };
const largeGray = { color: 'gray', fontSize: 'large' };
const TAB_ID = 'wages-addons';

const {
  AMCompanies,
  MONTHS,
  YEARS,
  SATNICE_FILTER_MONTH,
  SATNICE_FILTER_YEAR,
  SATNICE_FILTER_COMPANY,
  SATNICE_FILTER_COMPANY_NAME,
  handleFocus,
} = constants;
class AddonsTable extends React.Component {
  static propTypes = {
    companiesStore: CompaniesProps.isRequired,
    workersState: EmployeesProps.isRequired,
    worksitesState: WorksitesProps.isRequired,
    t: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      addons: [],
      addonsFull: [],
      selectedMonth: getItem(SATNICE_FILTER_MONTH) || moment(new Date()).month() + 1,
      selectedYear: getItem(SATNICE_FILTER_YEAR) || moment(new Date()).year(),
      selectedCompany: getItem(SATNICE_FILTER_COMPANY) || this.handleProperCompanies()[0].value,
      selectedCompanyName: getItem(SATNICE_FILTER_COMPANY_NAME) || this.handleProperCompanies()[0].label,
      selectedWorkers: [],
      selectedWorksites: [],
      selectedView: 'workers',
      showWorksitesOnly: false,
      showInactiveOnly: false,
      locked: false,
      ROLES: getRoles(),
      progress: 0,
      filterValues: { workers: [], worksites: [] },
    };
  }

  componentDidMount() {
    const { selectedMonth, selectedYear, selectedCompany, selectedView } = this.state;
    this.fetchOWAddons(selectedMonth, selectedYear, selectedCompany, selectedView);
  }

  dismissProgress = () => {
    this.setState({ progress: 100 });
    setTimeout(() => {
      this.setState({ progress: 0 });
    }, 1000);
  };

  handleProperCompanies = () => {
    const {
      companiesStore: { companiesTitles },
    } = this.props;

    const token = getItem('jwt');
    if (token) {
      const { customerId } = payload(token);
      //a.m.
      if (customerId === 'NZ3YJkYx') {
        return companiesTitles.filter(ct => AMCompanies.includes(ct.value));
      }
    }

    return companiesTitles;
  };

  fetchOWAddons = async (month, year, companyId, viewId) => {
    const { ROLES } = this.state;
    this.setState({ progress: 15 });
    const { addons, locked } = await financeApi.getWorklistWithAddons(month, year, companyId, TAB_ID);
    //console.log('TCL: AddonsTable -> fetchOWAddons -> addons', addons);
    const isLocked = (ROLES.satnice && ROLES.satnice.readonly) || locked;

    if (viewId === 'worksites') {
      const regrouped = regroupWorklistByWorksites([addons]);
      //console.log('TCL: AddonsTable -> fetchOWAddons -> regrouped[0].OWs', regrouped[0].OWs);
      this.setState({ addons: regrouped[0].OWs, addonsFull: regrouped[0].OWs, locked: isLocked, selectedView: viewId });
    } else {
      this.setState({ addons: addons.workers, addonsFull: addons.workers, locked: isLocked, selectedView: viewId });
    }
    this.dismissProgress();
  };

  tableInput = (id, value, type, index, indexWorker, className = 'form-control', step = 1) => {
    return (
      <input
        onFocus={e => handleFocus(e)}
        onChange={e => this.handleChange(index, indexWorker, id, e.target.value)}
        onBlur={e => this.handleSubmit(index, indexWorker, id, e.target.value)}
        value={value}
        type={type}
        className={className}
        step={step}
      />
    );
  };

  handleChange = (index, indexWorker, dataType, _value) => {
    let value = _value;
    if (!_value && dataType !== 'notes') value = 0;
    const { addons } = this.state;

    // if (addons[indexWorker].OW[index][dataType].toString() === value.toString()) {
    //   return;
    // }

    let modifiedBy = null;
    const token = getItem('jwt');
    if (token) {
      const { uid, name, surname, username } = payload(token);
      modifiedBy = { uid, name, surname, username, updatedAt: TMZFY(new Date(Date.now())) };
    }

    const newState = addons.map((arrItem, j) => {
      const _arrItem = arrItem;
      if (j === indexWorker) {
        const inner = _arrItem.OW.map((item, i) => {
          if (i === index) {
            return { ...item, [dataType]: value, modifiedBy };
          }
          return item;
        });
        _arrItem.OW = inner;
      }
      return _arrItem;
    });

    this.setState({
      addons: newState,
    });
  };

  handleSubmit = (index, indexWorker, dataType, _value) => {
    let value = _value;
    if (!_value && dataType !== 'notes') value = 0;
    const { addons, selectedMonth, selectedYear } = this.state;
    // if (addons[indexWorker].OW[index][dataType].toString() !== value.toString()) {
    //   console.log('RETURN');
    //   return;
    // }

    const { addon, addonNight, owId, notes } = addons[indexWorker].OW[index];
    if (dataType === 'addon') {
      financeApi.upsertOWAddon(
        owId,
        addons[indexWorker]._id,
        selectedMonth,
        selectedYear,
        value,
        addonNight,
        notes || '',
      );
    } else if (dataType === 'addonNight') {
      financeApi.upsertOWAddon(owId, addons[indexWorker]._id, selectedMonth, selectedYear, addon, value, notes || '');
    }
  };

  handleCompanySelect = (companyId, color, name) => {
    const { selectedMonth, selectedYear, selectedView } = this.state;
    this.setState({ selectedCompany: companyId, selectedCompanyName: name });
    setItem(SATNICE_FILTER_COMPANY, companyId);
    setItem(SATNICE_FILTER_COMPANY_NAME, name);

    this.fetchOWAddons(selectedMonth, selectedYear, companyId, selectedView);
  };

  handleMonthSelect = month => {
    const { selectedCompany, selectedYear, selectedView } = this.state;
    this.setState({ selectedMonth: month });
    setItem(SATNICE_FILTER_MONTH, month);
    this.fetchOWAddons(month, selectedYear, selectedCompany, selectedView);
  };

  handleYearSelect = year => {
    const { selectedCompany, selectedMonth, selectedView } = this.state;
    this.setState({ selectedYear: year });
    setItem(SATNICE_FILTER_YEAR, year);
    this.fetchOWAddons(selectedMonth, year, selectedCompany, selectedView);
  };

  applyFilter = (workers, worksites) => {
    const { addonsFull, selectedView } = this.state;
    this.setState({
      filterValues: {
        workers,
        worksites,
      },
    });
    if (selectedView === 'worksites') return;
    if (workers.length === 0 && worksites.length === 0) {
      this.setState({ addons: addonsFull });
      return;
    }

    let IDs = [];
    if (workers.length > 0) {
      const ids = workers.map(v => v.value);

      IDs = IDs.concat(ids);
    }
    if (worksites.length > 0) {
      const worksiteIds = worksites.map(v => v.value);

      const ids = addonsFull.reduce((list, el) => {
        const exists = el.OW.find(ow => worksiteIds.includes(ow.worksite._id));
        if (exists) {
          list.push(el._id);
        }
        return list;
      }, []);

      IDs = IDs.concat(ids);
    }

    const uniqIds = IDs.filter(onlyUnique);
    const addons = addonsFull.filter(af => uniqIds.includes(af._id));
    this.setState({ addons });
  };

  handleWorkerChange = values => {
    const { selectedWorksites } = this.state;
    this.setState({ selectedWorkers: values });
    this.applyFilter(values, selectedWorksites);
  };

  handleWorksiteChange = values => {
    const { selectedWorkers } = this.state;

    this.setState({ selectedWorksites: values });
    this.applyFilter(selectedWorkers, values);
  };

  handleCheckboxes = values => {
    const { radio_worksites_workers, checkbox_worksites_only, checkbox_inactive_only } = values;
    const { selectedMonth, selectedYear, selectedCompany, selectedView } = this.state;
    if (radio_worksites_workers === '1' && selectedView !== 'worksites') {
      this.fetchOWAddons(selectedMonth, selectedYear, selectedCompany, 'worksites');
    } else if (radio_worksites_workers === '2' && selectedView !== 'workers') {
      this.fetchOWAddons(selectedMonth, selectedYear, selectedCompany, 'workers');
    }

    if (radio_worksites_workers === '1' && checkbox_worksites_only === true) {
      this.setState({ showWorksitesOnly: true });
    } else if (checkbox_worksites_only === false) {
      this.setState({ showWorksitesOnly: false });
    }

    if (radio_worksites_workers === '2' && checkbox_inactive_only === true) {
      this.setState({ showInactiveOnly: true });
    } else if (checkbox_inactive_only === false) {
      this.setState({ showInactiveOnly: false });
    }

    //console.log('TCL: AddonsTable -> handleCheckboxes -> values', values);
  };

  handleLockdown = async () => {
    const { selectedMonth, selectedYear } = this.state;
    await financeApi.setLockdown(selectedMonth, selectedYear, TAB_ID);
    this.setState(prevState => ({
      locked: !prevState.locked,
    }));
  };

  filterAddonsWBeforeDownload = () => {
    const {
      addons,
      filterValues: { workers, worksites },
      showWorksitesOnly,
    } = this.state;
    //console.log('AddonsTable -> showWorksitesOnly', showWorksitesOnly);

    if (workers.length === 0 && worksites.length === 0) {
      return addons;
    }

    let IDs = [];
    if (workers.length > 0) {
      const workersIds = workers.map(v => v.value);

      const ids = addons.reduce((list, el) => {
        const exists = el.workers.find(w => workersIds.includes(w._id));
        if (exists) {
          list.push(el.OW.owId);
        }
        return list;
      }, []);

      IDs = IDs.concat(ids);
    }

    if (worksites.length > 0) {
      const worksiteIds = worksites.map(v => v.value);

      const ids = addons.reduce((list, el) => {
        if (worksiteIds.includes(el.OW.worksite._id)) {
          list.push(el.OW.owId);
        }
        return list;
      }, []);

      IDs = IDs.concat(ids);
    }

    const uniqIds = IDs.filter(onlyUnique);

    return addons.filter(af => uniqIds.includes(af.OW.owId));
  };

  handleDownload = () => {
    const {
      selectedMonth,
      selectedYear,
      selectedCompany,
      selectedCompanyName,

      selectedView,

      showWorksitesOnly,
    } = this.state;

    if (selectedView === 'worksites') {
      const filteredAddons = this.filterAddonsWBeforeDownload();
      //console.log('AddonsTable -> filteredAddons', filteredAddons);

      financeApi.downloadAddonsW(
        selectedMonth,
        selectedYear,
        selectedCompany,
        selectedCompanyName,
        filteredAddons,
        showWorksitesOnly,
      );
      return;
    }

    financeApi.downloadAddons(selectedMonth, selectedYear, selectedCompany, selectedCompanyName);
  };

  calculateNettoForWorker = (satnica, OWs) => {
    return commify(
      fix(
        OWs.reduce(
          (netto, { sum, addon, addonNight }) => {
            const nettoDay = (Number(addon) + satnica) * sum.hoursSum;
            const nettoNight = (Number(addonNight) + satnica) * sum.nightHoursSum;
            const _netto = netto;
            _netto.sum += nettoDay + nettoNight;
            return _netto;
          },
          { sum: 0 },
        ).sum,
      ),
    );
  };

  calculateTotalsForWorker = OWs => {
    return OWs.reduce(
      (tot, { sum }) => {
        const _tot = tot;
        _tot.totalDay += sum.hoursSum;
        _tot.totalNight += sum.nightHoursSum;
        return _tot;
      },
      { totalDay: 0, totalNight: 0 },
    );
  };

  renderLockButton = () => {
    const { locked } = this.state;
    const { t } = this.props;
    return (
      <Fragment>
        <Button id="addon-lock-btn" size="sm" color="danger" className="sat-lock-btn" onClick={this.handleLockdown}>
          {locked ? <LockIcon /> : <LockOpenIcon />}
        </Button>
        <UncontrolledTooltip placement="top" target="addon-lock-btn">
          {!locked ? t('finance.lock') : t('finance.unlock')}
        </UncontrolledTooltip>
      </Fragment>
    );
  };

  renderDownloadButton = () => {
    const { t } = this.props;
    return (
      <Fragment>
        <Button
          id="wage-download-btn"
          size="sm"
          color="success"
          className="wage-download-btn"
          onClick={this.handleDownload}
        >
          <DownloadIcon />
        </Button>
        <UncontrolledTooltip placement="top" target="wage-download-btn">
          {t('form.exportExcel')}
        </UncontrolledTooltip>
      </Fragment>
    );
  };

  renderProgress = () => {
    const { progress } = this.state;
    return progress === 0 ? (
      <div style={{ width: '100%', height: '5px', backgroundColor: '#ECECEC', marginBottom: '30px' }}></div>
    ) : (
      <div className="progress-wrap progress-wrap--blue">
        <Progress value={progress} />
      </div>
    );
  };

  renderMultiSelect = type => {
    const { selectedWorkers, selectedWorksites } = this.state;
    const { workersState, worksitesState, t } = this.props;
    const widthStyle = { width: '400px' };
    //worksites: worksitesState.titlesWorksites,
    if (type === 'workers') {
      return (
        <div style={widthStyle}>
          <div className="display-table">
            <MultiSelectField
              name="addons-workers-filter-search-2"
              onChange={this.handleWorkerChange}
              placeholder={`${t('sidebar_content.workers.title')}`}
              options={workersState.employeesNames}
              value={selectedWorkers}
            />
          </div>
        </div>
      );
    }
    return (
      <div style={widthStyle}>
        <div className="display-table">
          <MultiSelectField
            name="addons-worksites-filter-search-2"
            onChange={this.handleWorksiteChange}
            placeholder={`${t('sidebar_content.worksites')}`}
            options={worksitesState.titlesWorksites}
            value={selectedWorksites}
          />
        </div>
      </div>
    );
  };

  renderDropdown = () => {
    return (
      <div style={{ display: 'inherit', marginBottom: '20px', marginRight: '10px', paddingTop: '1px' }}>
        <Dropdown
          defaultOption={this.state.selectedCompanyName}
          options={this.handleProperCompanies()}
          onItemSelect={this.handleCompanySelect}
        />
        <Dropdown
          defaultOption={this.state.selectedYear}
          options={YEARS.map(m => {
            return { value: m, label: m.toString(), color: 'white' };
          })}
          onItemSelect={this.handleYearSelect}
        />
        <Dropdown
          defaultOption={this.state.selectedMonth}
          options={MONTHS.map(m => {
            return { value: m, label: m.toString(), color: 'white' };
          })}
          onItemSelect={this.handleMonthSelect}
        />
      </div>
    );
  };

  renderModified = modifiedBy => {
    const style = { color: 'lightgray', fontSize: 'smaller' };
    if (!modifiedBy) return <span>/</span>;
    return (
      <Fragment>
        <div>
          <span style={style}>{modifiedBy.name}</span> <span style={style}>{modifiedBy.surname}</span>
        </div>
        <div>
          <span style={style}>{modifiedBy.updatedAt}</span>
        </div>
      </Fragment>
    );
  };

  renderOrdererWorksites = (ordererWorksites, indexWorker) => {
    const { addons, locked } = this.state;
    return ordererWorksites.map((ow, index) => {
      //  hours = commify(fix(ow.sum.hoursSum));
      // if (ow.sum.nightHoursSum > 0) hours += ` / ${commify(fix(ow.sum.nightHoursSum))}`;
      return (
        <tr key={ow.owId}>
          <td />
          <td />
          <td style={{ maxWidth: '200px' }}>
            <p>{ow.worksite.title}</p>
            <p style={{ color: 'lightgray' }}>{ow.orderer.title}</p>
          </td>
          <td>{commify(fix(ow.sum.hoursSum))}</td>
          <td>{commify(fix(ow.sum.nightHoursSum))}</td>
          <td>{commify(fix(ow.sum.hoursSum + ow.sum.nightHoursSum))}</td>
          <td />

          {!locked ? (
            <Fragment>
              <td style={W100}>
                {this.tableInput('addon', addons[indexWorker].OW[index].addon, 'number', index, indexWorker)}
              </td>
              <td style={W100}>
                {this.tableInput('addonNight', addons[indexWorker].OW[index].addonNight, 'number', index, indexWorker)}
              </td>
            </Fragment>
          ) : (
            <Fragment>
              <td>
                <strong style={largeGray}>{ow.addon}</strong>
              </td>
              <td>
                <strong style={largeGray}>{ow.addonNight}</strong>
              </td>
            </Fragment>
          )}
          <td />
          <td>{this.renderModified(ow.modifiedBy)}</td>
        </tr>
      );
    });
  };

  renderAddonTable = () => {
    const { addons, showInactiveOnly } = this.state;
    //console.log('TCL: AddonsTable -> renderAddonTable -> addons', addons);

    return addons.map((row, index) => {
      const { totalDay, totalNight } = this.calculateTotalsForWorker(row.OW);

      let show = true;
      if (showInactiveOnly && !row.inactive) {
        show = false;
      }

      return (
        <Fragment>
          <tr className={show ? '' : 'd-none'} style={{ backgroundColor: 'ghostwhite' }}>
            <td>{`${row.surname.toUpperCase()} ${row.name}`}</td>
            <td>{translateProfession(row.profession, localStorage.getItem('lang') || 'sl')}</td>
            <td />
            <td>{commify(fix(totalDay))}</td>
            <td>{commify(fix(totalNight))}</td>
            <td>{commify(fix(totalDay + totalNight))}</td>
            <td>
              <strong style={largeGray}>{row.satnica}</strong>
            </td>
            <td />
            <td />
            <td>
              <strong style={largeGray}>{this.calculateNettoForWorker(row.satnica, row.OW)}€</strong>
            </td>
            <td />
          </tr>
          {show && this.renderOrdererWorksites(row.OW, index)}
        </Fragment>
      );
    });
  };

  renderTHeadsByWorker = () => {
    const { selectedView } = this.state;
    const { t } = this.props;

    return (
      <tr>
        {selectedView === 'workers' ? (
          <Fragment>
            <th>{t('board.ow.worker')}</th>
            <th>{t('form.profession')}</th>
            <th>{t('board.filters.worksite')}</th>
          </Fragment>
        ) : (
          <Fragment>
            <th>{t('board.filters.worksite')}</th>
            <th>{t('board.ow.worker')}</th>
            <th>{t('form.profession')}</th>
          </Fragment>
        )}
        <th>{t('workerstable.dayHours')}</th>
        <th>{t('workerstable.nightHours')}</th>
        <th>{t('workerstable.sum')}</th>
        <th>{t('finance.satnica')}</th>
        <th>{t('finance.addon')}</th>
        <th>{t('finance.addonNight')}</th>
        <th>{t('finance.nettoWage')}</th>
        <th>{t('finance.modifiedBy')}</th>
      </tr>
    );
  };

  render() {
    const { t } = this.props;
    const {
      ROLES,
      selectedView,
      addons,
      selectedMonth,
      selectedYear,
      showWorksitesOnly,
      filterValues,
      locked,
    } = this.state;
    return (
      <Fragment>
        <ButtonToolbar style={{ position: 'relative' }}>
          {this.renderDropdown()}
          {this.renderMultiSelect('workers')}
          {this.renderMultiSelect('worksites')}

          <div style={{ width: '25%', marginLeft: '10px' }}>
            <CheckboxForm
              onSubmit={this.handleCheckboxes}
              disableWorksitesOnly={selectedView !== 'worksites'}
              disableInactiveOnly={selectedView !== 'workers'}
            />
          </div>

          {(ROLES.super || ROLES.sistem) && this.renderLockButton()}
          {this.renderDownloadButton()}
        </ButtonToolbar>

        {this.renderProgress()}
        {locked && (
          <h4 style={{ color: 'red', textAlign: 'center', marginBottom: '30px' }}>
            <strong>{t('finance.locked').toUpperCase()}</strong>
          </h4>
        )}
        {addons.length > 0 && (
          <Table hover className="table--bordered" responsive>
            <thead>{this.renderTHeadsByWorker()}</thead>
            {selectedView === 'workers' ? (
              <tbody>{this.renderAddonTable()}</tbody>
            ) : (
              <AddonsTableByWorksite
                addons={addons}
                selectedMonth={selectedMonth}
                selectedYear={selectedYear}
                showWorksitesOnly={showWorksitesOnly}
                filter={filterValues}
                locked={locked}
              />
            )}
          </Table>
        )}
      </Fragment>
    );
  }
}

const wr = connect(state => ({
  worksitesState: state.worksites,
  workersState: state.employees,
  companiesStore: state.companies,
}))(AddonsTable);

export default translate('common')(wr);
