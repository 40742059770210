import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactGA from 'react-ga';
import { Col, Container, Row, ButtonToolbar, Card, CardBody, Progress } from 'reactstrap';
import NotificationSystem from 'rc-notification';
import getRoles from 'constants/roles';
import { API_LINK } from '../../constants/config';
import GradbiscaTable from './components/GradbiscaTable';
import { BasicNotification } from '../../shared/components/Notification';
import SelectDropdown from '../../shared/components/SelectDropdown';
import GradbiscaModal from './components/GradbiscaModal';
import { POST } from '../../helpers/agent';
import {
  WorksitesProps,
  DataListProps,
  AppStatusProps,
  AccommodationsProps,
} from '../../shared/prop-types/ReducerProps';

const API_WORKSITES_GET_ONE = '/w/getOneById';

let notificationLU = null;
let notificationRU = null;
let notificationTC = null;

const showNotification = ({ notification, position }) => {
  switch (position) {
    case 'left-up':
      notificationLU.notice({
        content: notification,
        duration: 5,
        closable: true,
        style: { top: 0, left: 240 },
        className: position,
      });
      break;
    case 'right-up':
      notificationRU.notice({
        content: notification,
        duration: 5,
        closable: true,
        style: { top: 0, left: 'calc(100vw - 100%)' },
        className: position,
      });
      break;
    default:
      notificationTC.notice({
        content: notification,
        duration: 5,
        closable: true,
        style: { top: 0, left: 0 },
        className: position,
      });
      break;
  }
};

class GradbiscaPage extends Component {
  static propTypes = {
    worksitesState: WorksitesProps.isRequired,
    dataListState: DataListProps.isRequired,
    accommodationsState: AccommodationsProps.isRequired,
    appStatus: AppStatusProps.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      worksites: [],
      worksitesFull: [],
      titles: [],
      countries: [],
      accommodations: [],
      showEdit: false,
      progressNum: 0,
      editWorksite: null,
      ROLES: getRoles(),
    };

    this.onRowSelect = this.onRowSelect.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.showNotif = this.showNotif.bind(this);
    this.fetchData = this.fetchData.bind(this);
    this.handleProgress = this.handleProgress.bind(this);
    this.handleDropdownSubmit = this.handleDropdownSubmit.bind(this);
  }

  componentDidMount() {
    NotificationSystem.newInstance({}, n => (notificationLU = n));
    NotificationSystem.newInstance({}, n => (notificationRU = n));
    NotificationSystem.newInstance({}, n => (notificationTC = n));

    const { appStatus } = this.props;
    if (appStatus.requiredDataLoaded) {
      console.log('WORKSITES -> Data loaded.. continue...');
      this.fetchData();
    }
  }

  componentDidUpdate(prevProps) {
    const { appStatus } = this.props;
    if (appStatus.requiredDataLoaded && !prevProps.appStatus.requiredDataLoaded) {
      console.log('WORKSITES -> Data finished loading.. continue...');
      this.fetchData();
    }

    if (!appStatus.appUpdating && prevProps.appStatus.appUpdating) {
      console.log('WORKSITES -> Data finished UPDATING.. refresh table...');
      this.fetchData();
    }
  }

  componentWillUnmount() {
    notificationLU.destroy();
    notificationRU.destroy();
    notificationTC.destroy();
  }

  async onRowSelect(values) {
    const { ROLES } = this.state;
    if (ROLES.worksites && ROLES.worksites.readonly) {
      ReactGA.event({
        category: 'Gradbišča',
        action: `Urejanje Gradbišča - ${values} - Ni dostopa`,
      });
      this.showNotif({
        title: 'Ni dostopa!',
        message: 'Nimate pravic za urejanje gradbišč!',
        success: false,
      });
      return;
    }
    const body = JSON.stringify({ id: values });
    ReactGA.event({
      category: 'Gradbišča',
      action: `Urejanje Gradbišča - ${values}`,
    });
    const data = await POST(API_LINK + API_WORKSITES_GET_ONE, body);
    if (data.success) {
      console.log('worksite', data.worksite);
      this.setState({ editWorksite: data.worksite, showEdit: true });
    }
  }

  handleProgress(addProgress) {
    const { progressNum } = this.state;
    this.setState({ progressNum: progressNum + addProgress });
    const that = this;
    if (progressNum + addProgress >= 100) {
      setTimeout(() => {
        that.handleProgress(-(progressNum + addProgress));
      }, 1000);
    }
  }

  fetchData() {
    const { worksitesState, dataListState, accommodationsState } = this.props;

    this.setState({
      worksites: worksitesState.worksites,
      worksitesFull: worksitesState.worksites,
      titles: worksitesState.titlesWorksites,
      accommodations: accommodationsState.accommodationTitles,
      countries: dataListState.countries,
    });
  }

  showNotif = ({ title, message, success }) => {
    const color = success ? 'success' : 'danger';
    showNotification({
      notification: <BasicNotification color={color} title={title} message={message} />,
      position: 'right-up',
    });
  };

  handleCloseModal(data) {
    if (data) this.showNotif(data);
    this.setState({ showEdit: false });
    if (data) this.fetchData();
  }

  handleDropdownSubmit(values) {
    try {
      if (values.selected_dropdown === null) {
        this.fetchData();
        return;
      }

      ReactGA.event({
        category: 'Gradbišča',
        action: `Filtriranje Gradbišča - ${values.selected_dropdown.label}`,
      });
      const { worksitesFull } = this.state;
      const id = values.selected_dropdown.value;
      const filtered = [];
      worksitesFull.forEach(wrk => {
        if (wrk._id === id) {
          filtered.push(wrk);
        }
      });
      this.setState({ worksites: filtered });
    } catch (error) {
      console.log(error);
    }
  }

  render() {
    const { worksites, progressNum, titles, countries, showEdit, editWorksite, accommodations, ROLES } = this.state;
    let showBtn = ROLES.full;

    if (!showBtn) {
      showBtn = !(ROLES.worksites && ROLES.worksites.readonly);
    }
    return (
      <Container className="dashboard">
        <Row>
          <Col lg={12}>
            <h3 className="page-title">Gradbišča</h3>
          </Col>
          <Col lg={12}>
            <Row>
              <Col lg={6}>
                <Card>
                  <CardBody>
                    <SelectDropdown title="Išči gradbišče" items={titles} onSubmit={this.handleDropdownSubmit} />
                  </CardBody>
                </Card>
              </Col>
              <Col lg={{ size: 4, offset: 2 }}>
                <ButtonToolbar className="button-toolbar text-right">
                  <GradbiscaModal
                    btn="Dodaj gradbišče"
                    icon="add"
                    title="Dodaj gradbišče"
                    countries={countries}
                    accommodations={accommodations}
                    hasBtn={showBtn}
                    show={false}
                    onClose={this.handleCloseModal}
                  />
                </ButtonToolbar>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          {progressNum > 0 ? (
            <div className="progress-wrap progress-wrap--middle progress--align">
              <Progress animated value={progressNum} />
            </div>
          ) : null}
          <GradbiscaTable title="Podatki o gradbiščih" worksites={worksites} onRowSelect={this.onRowSelect} />
        </Row>

        <GradbiscaModal
          btn="Uredi gradbišče"
          icon="edit"
          title="Uredi gradbišče"
          countries={countries}
          accommodations={accommodations}
          hasBtn={false}
          show={showEdit}
          onClose={this.handleCloseModal}
          editData={editWorksite}
        />
      </Container>
    );
  }
}

export default connect(state => ({
  worksitesState: state.worksites,
  dataListState: state.dataList,
  accommodationsState: state.accommodations,
  appStatus: state.appStatus,
}))(GradbiscaPage);
