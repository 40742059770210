import React from 'react';
import PropTypes from 'prop-types';
import ReactExport from 'react-data-export';
import { Button } from 'reactstrap';
import fix from 'helpers/floatParser';
import stable from 'stable';
import { translate } from 'react-i18next';
import { CONFIG } from '../../../../../constants/config';

const LEX_COMP_TITLE = (a, b) => CONFIG.COLLATOR.compare(a.title, b.title);

/* eslint-disable */
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
/* eslint-enable */

const DownloadButton = title => (
  <Button size="sm" color="primary" style={{ marginBottom: '10px' }}>
    {title}
  </Button>
);

const generateHeaders = t => {
  const firstPart = [
    {
      title: t('form.company'),
    },
    {
      title: t('board.ow.orderer'),
    },
    {
      title: t('board.filters.worksite'),
    },
    {
      title: t('form.address'),
    },
    {
      title: t('form.zip'),
    },
    {
      title: t('form.city'),
    },
    {
      title: t('form.country'),
    },
    {
      title: t('workerstable.dayHours'),
    },
    {
      title: t('workerstable.nightHours'),
    },
    {
      title: t('workerstable.sum'),
    },
  ];

  const multiDataSet = [
    {
      columns: firstPart,
      data: [],
    },
  ];
  return multiDataSet;
};

const generateData = dataAll => {
  console.log('TCL: generateData -> dataAll', dataAll);
  const lang = localStorage.getItem('lang') || 'sl';
  const data = [];
  for (let i = 0; i < dataAll.worklist.length; i += 1) {
    const { company, orderers } = dataAll.worklist[i];
    const companyRow = [
      {
        value: lang === 'de' && company.name === 'VSA PODJETJA' ? 'ALLES UNTERNEHMEN' : company.name,
      },
      {
        value: ' ',
      },
      {
        value: ' ',
      },
      {
        value: ' ',
      },
      {
        value: ' ',
      },
      {
        value: ' ',
      },
      {
        value: ' ',
      },
      {
        value: Number(fix(dataAll.worklist[i].sumDay)),
      },
      {
        value: Number(fix(dataAll.worklist[i].sumNight)),
      },
      {
        value: Number(fix(dataAll.worklist[i].total)),
      },
    ];
    data.push(companyRow);

    const orderersSorted = stable(orderers, LEX_COMP_TITLE);

    for (let j = 0; j < orderersSorted.length; j += 1) {
      const { worksites } = orderersSorted[j];
      const ordererRow = [
        {
          value: ' ',
        },
        {
          value: orderersSorted[j].title,
        },
        {
          value: ' ',
        },
        {
          value: ' ',
        },
        {
          value: ' ',
        },
        {
          value: ' ',
        },
        {
          value: ' ',
        },
        {
          value: Number(fix(orderersSorted[j].sumDay)),
        },
        {
          value: Number(fix(orderersSorted[j].sumNight)),
        },
        {
          value: Number(fix(orderersSorted[j].total)),
        },
      ];
      data.push(ordererRow);

      const worksitesSorted = stable(worksites, LEX_COMP_TITLE);

      for (let k = 0; k < worksitesSorted.length; k += 1) {
        const { _title, address, zip, city, country, sumDay, sumNight, total } = worksitesSorted[k];
        const worksiteRow = [
          {
            value: ' ',
          },
          {
            value: ' ',
          },
          {
            value: _title,
          },
          {
            value: address,
          },
          {
            value: zip,
          },
          {
            value: city,
          },
          {
            value: country,
          },
          {
            value: Number(fix(sumDay)),
          },
          {
            value: Number(fix(sumNight)),
          },
          {
            value: Number(fix(total)),
          },
        ];
        data.push(worksiteRow);
      }
    }
  }
  return data;
};

class WorklistFullSheet extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { data, btn, t } = this.props;

    const headers = generateHeaders(t);
    headers[0].data = generateData(data);
    return (
      <ExcelFile element={DownloadButton(btn)} filename={t('calcs.worklistfull')}>
        <ExcelSheet dataSet={headers} name={t('calcs.worklistfull')} />
      </ExcelFile>
    );
  }
}

WorklistFullSheet.propTypes = {
  btn: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  t: PropTypes.func.isRequired,
};

export default translate('common')(WorklistFullSheet);
