const CONFIG = {
  server: 'production', // local, demo, production
  https: true,
  oldServer: false,
  months: [
    'Januar',
    'Februar',
    'Marec',
    'April',
    'Maj',
    'Junij',
    'Julij',
    'Avgust',
    'September',
    'Oktober',
    'November',
    'December',
  ],
  months_short: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
  //! ne dajat inDev na false!
  inDev: true,
  states: {
    workers: 'workers',
    companies: 'companies',
    orderers: 'orderers',
    worksites: 'worksites',
    accommodations: 'accommodations',
    providers: 'providers',
    vehicles: 'vehicles',
    ows: 'ows',
  },
};

const port =
  CONFIG.https && (CONFIG.server === 'production' || CONFIG.server === 'web') && !CONFIG.oldServer ? '5044' : '5000';
const protocol =
  CONFIG.https && (CONFIG.server === 'production' || CONFIG.server === 'web') && !CONFIG.oldServer ? 'https' : 'http';
let prodServerIP = CONFIG.oldServer ? '146.148.21.254' : 'app.hures.eu';
let stageServerIP = CONFIG.oldServer ? '35.228.157.70' : 'staging.hures.eu';

const demoServerIP = 'demo.hures.eu';
const webServerIP = 'web.hures.eu';
if (CONFIG.server === 'demo') stageServerIP = demoServerIP;
if (CONFIG.server === 'web') prodServerIP = webServerIP;

function noop() {}

if (CONFIG.server === 'production') {
  console.log = noop;
  console.warn = noop;
  console.error = noop;
}

const API_LINK =
  CONFIG.server === 'local'
    ? `${protocol}://localhost:${port}/api`
    : CONFIG.server === 'staging' || CONFIG.server === 'demo'
    ? `${protocol}://${stageServerIP}:${port}/api` // '${protocol}://${stageServerIP}:5000/api'
    : CONFIG.server === 'production' || CONFIG.server === 'web'
    ? `${protocol}://${prodServerIP}:${port}/api`
    : null;

const SERVER_LINK =
  CONFIG.server === 'local'
    ? `${protocol}://localhost:${port}`
    : CONFIG.server === 'staging' || CONFIG.server === 'demo'
    ? `${protocol}://${stageServerIP}:${port}`
    : CONFIG.server === 'production' || CONFIG.server === 'web'
    ? `${protocol}://${prodServerIP}:${port}`
    : null;

const FRONTEND_LINK =
  CONFIG.server === 'local'
    ? `${protocol}://localhost:3000`
    : CONFIG.server === 'staging' || CONFIG.server === 'demo'
    ? `${protocol}://${stageServerIP}`
    : CONFIG.server === 'production' || CONFIG.server === 'web'
    ? `${protocol}://${prodServerIP}`
    : null;

const PLACES_API_LINK =
  CONFIG.server === 'local'
    ? `${protocol}://localhost:3500`
    : CONFIG.server === 'staging' || CONFIG.server === 'demo'
    ? `${protocol}://demo.hures.eu:3500`
    : CONFIG.server === 'production' || CONFIG.server === 'web'
    ? `${protocol}://${prodServerIP}:3500`
    : null;

const D_FORMAT = 'DD. MM. YY';
const TIMEZONE = 'Europe/Berlin';
const PICKER_DATE_WORKERS = 'PICKER_DATE_WORKERS';
const PICKER_DATE_OW = 'PICKER_DATE_OW';
const PICKER_DATE_IZPIS = 'PICKER_DATE_IZPIS';

// eslint-disable-next-line max-len
const LOGIN_LINK = () => {
  if (CONFIG.server === 'local') return 'http://localhost:3666/user/login';
  if (CONFIG.server === 'demo') return `http://${stageServerIP}:3666/user/login`;
  return `https://${prodServerIP}:3666/user/login`;
};
// (CONFIG.server === 'local' ? 'http://localhost:3666/user/login' : `https://${prodServerIP}:3666/user/login`);

const customSortCompare = (
  aaa,
  bbb,
  alphabet = [
    'a',
    'b',
    'c',
    'č',
    'ć',
    'd',
    'đ',
    'e',
    'f',
    'g',
    'h',
    'i',
    'j',
    'k',
    'l',
    'm',
    'n',
    'o',
    'p',
    'r',
    's',
    'š',
    't',
    'u',
    'v',
    'z',
    'ž',
  ],
) => {
  const a = aaa.toLowerCase();
  const b = bbb.toLowerCase();
  // are the strings equal?
  if (a === b) {
    return 0;
  }

  // we take the shortest string and find the first char that is different
  let indexOfA = -1;
  let indexOfB = -1;
  const len = a.length < b.length ? a.length : b.length;
  for (let i = 0; i < len; i += 1) {
    if (a[i] !== b[i]) {
      indexOfA = alphabet.indexOf(a[i]);
      indexOfB = alphabet.indexOf(b[i]);
      break;
    }
  }

  // if they are not equal, compare the first letters
  //  against the custom sort order
  // const indexOfA = alphabet.indexOf(a[0]);
  const aInList = indexOfA >= 0;

  // const indexOfB = alphabet.indexOf(b[0]);
  const bInList = indexOfB >= 0;

  // if the first letter of neither string is in the list,
  //  compare alphabetically
  if (!aInList && !bInList) {
    return a.localeCompare(b, 'hr');
  }
  if (!aInList) {
    // the first letter of only a is not in the list
    return 1;
  }
  if (!bInList) {
    // the first letter of only b is not in the list
    return -1;
  }
  if (indexOfA === indexOfB) {
    // the first letter of a and b are both in the list
    //  and they are the same
    return a.localeCompare(b, 'hr');
  }
  // the first letters are different; sort by first letter
  return indexOfA - indexOfB;
};

CONFIG.COLLATOR = {
  compare(a, b) {
    return customSortCompare(a, b);
  },
};

const GA_ID =
  CONFIG.server === 'local'
    ? 'UA-143924496-1'
    : CONFIG.server === 'staging'
    ? 'UA-144385265-1'
    : CONFIG.server === 'production'
    ? 'UA-144370321-1'
    : 'UA-144030879-1';

const VIBER_BOT_NAME =
  CONFIG.server === 'local'
    ? 'exampler'
    : CONFIG.server === 'staging'
    ? 'gripbot'
    : CONFIG.server === 'production'
    ? 'huresbot'
    : 'exampler';

exports.CONFIG = CONFIG;
exports.API_LINK = API_LINK;
exports.SERVER_LINK = SERVER_LINK;
exports.FRONTEND_LINK = FRONTEND_LINK;
exports.D_FORMAT = D_FORMAT;
exports.TIMEZONE = TIMEZONE;
exports.PICKER_DATE_WORKERS = PICKER_DATE_WORKERS;
exports.PICKER_DATE_OW = PICKER_DATE_OW;
exports.PICKER_DATE_IZPIS = PICKER_DATE_IZPIS;
exports.GA_ID = GA_ID;
exports.VIBER_BOT_NAME = VIBER_BOT_NAME;
exports.PLACES_API_LINK = PLACES_API_LINK;
exports.LOGIN_LINK = LOGIN_LINK;
