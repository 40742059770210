import React from 'react';
import PropTypes from 'prop-types';

import ReactExport from 'react-data-export';
import { Button } from 'reactstrap';
import { translate } from 'react-i18next';
import { dottify } from '../../../../helpers/floatParser';

/* eslint-disable */
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
/* eslint-enable */

const DownloadButton = title => (
  <Button style={{ marginTop: '2px', marginRight: '5px' }} size="sm" color="primary">
    {title}
  </Button>
);

const generateHeaders = t => {
  const firstPart = [
    {
      title: '#',
    },
    {
      title: t('form.company'),
    },
    {
      title: t('misc.invoiceNum'),
    },
    {
      title: t('board.ow.provider'),
    },
    {
      title: t('misc.termin'),
    },
    {
      title: t('misc.persons'),
    },
    {
      title: t('misc.price'),
    },
    {
      title: t('misc.unpaid'),
    },
    {
      title: t('misc.priority'),
    },
    {
      title: t('misc.datePaid'),
    },
    {
      title: t('form.worksite'),
    },
    {
      title: t('form.notes'),
    },
  ];

  const multiDataSet = [
    {
      columns: firstPart,
      data: [],
    },
  ];
  return multiDataSet;
};

const generateData = (dataAll, sum) => {
  //console.log('TCL: generateData -> dataAll', dataAll);
  const data = [];
  for (let i = 0; i < dataAll.length; i += 1) {
    const reservation = dataAll[i];
    const workerRow = [
      {
        value: Number(`${i + 1}`),
      },
      {
        value: reservation.company,
      },
      {
        value: reservation.invoiceNum,
      },
      {
        value: reservation.provider,
      },
      {
        value: reservation.term,
      },
      {
        value: reservation.persons,
      },
      {
        value: reservation.price ? Number(dottify(reservation.price)) : 0,
      },
      {
        value: reservation.unpaid,
      },
      {
        value: reservation.priority,
      },
      {
        value: reservation.datePaid,
      },
      {
        value: reservation.worksite,
      },
      {
        value: reservation.notes,
      },
    ];
    data.push(workerRow);
  }

  const sumRow = [
    {
      value: 'SUM',
    },
    {
      value: '',
    },
    {
      value: '',
    },
    {
      value: '',
    },
    {
      value: '',
    },
    {
      value: '',
    },
    {
      value: sum,
    },
    {
      value: '',
    },
    {
      value: '',
    },
    {
      value: '',
    },
    {
      value: '',
    },
    {
      value: '',
    },
  ];

  data.push(sumRow);

  return data;
};

class RezervacijeSheet extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { data, btn, t, sum } = this.props;

    const headers = generateHeaders(t);
    headers[0].data = generateData(data, sum);
    return (
      <ExcelFile element={DownloadButton(btn)} filename={t('sidebar_content.accommodations.reservations')}>
        <ExcelSheet dataSet={headers} name={t('sidebar_content.accommodations.reservations')} />
      </ExcelFile>
    );
  }
}

RezervacijeSheet.propTypes = {
  btn: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  sum: PropTypes.number.isRequired,
  t: PropTypes.func.isRequired,
};

export default translate('common')(RezervacijeSheet);
