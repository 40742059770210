import React from 'react';

class Loading extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { dots: '.' };
    this.runDots = this.runDots.bind(this);
  }

  componentDidMount() {
    this._mounted = true;
    this.runDots();
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  interval = null;

  dotString = '.';

  runDots() {
    this.interval = setInterval(() => {
      this.dotString += '.';
      this.setState({ dots: this.dotString });
    }, 300);
  }

  render() {
    return <span style={{ color: 'coral' }}>{this.state.dots}</span>;
  }
}

export default Loading;
