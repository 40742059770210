import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactGA from 'react-ga';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';
import { Col, Container, Row, ButtonToolbar, Card, CardBody, Progress } from 'reactstrap';
import NotificationSystem from 'rc-notification';
import { API_LINK } from '../../../constants/config';
import { POST } from '../../../helpers/agent';
import { BasicNotification } from '../../../shared/components/Notification';
import SelectDropdown from '../../../shared/components/SelectDropdown';
import PrenocitveIzpisiTable from './components/PrenocitveIzpisiTable';
import PrenocitveIzpisiModal from './components/PrenocitveIzpisiModal';
import { AccommodationsProps, AppStatusProps } from '../../../shared/prop-types/ReducerProps';

const API_SLEEPLOGS = '/acm/getsleeplogs';

let notificationLU = null;
let notificationRU = null;
let notificationTC = null;

const showNotification = ({ notification, position }) => {
  switch (position) {
    case 'left-up':
      notificationLU.notice({
        content: notification,
        duration: 5,
        closable: true,
        style: { top: 0, left: 240 },
        className: position,
      });
      break;
    case 'right-up':
      notificationRU.notice({
        content: notification,
        duration: 5,
        closable: true,
        style: { top: 0, left: 'calc(100vw - 100%)' },
        className: position,
      });
      break;
    default:
      notificationTC.notice({
        content: notification,
        duration: 5,
        closable: true,
        style: { top: 0, left: 0 },
        className: position,
      });
      break;
  }
};

class PrenocitveIzpisiPage extends Component {
  static propTypes = {
    accommodationsState: AccommodationsProps.isRequired,
    appStatus: AppStatusProps.isRequired,
    t: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      sleeplogs: [],
      accommodations: [],
      // people: [],
      selectedAccommodation: null,
      showEdit: false,
      progressNum: 0,
      editSleeplog: null,
    };
    this.onRowSelect = this.onRowSelect.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.showNotif = this.showNotif.bind(this);
    this.fetchData = this.fetchData.bind(this);
    this.handleProgress = this.handleProgress.bind(this);
    this.handleDropdownSubmit = this.handleDropdownSubmit.bind(this);
    // this.handleIntervalSubmit = this.handleIntervalSubmit.bind(this);
  }

  componentDidMount() {
    NotificationSystem.newInstance({}, n => (notificationLU = n));
    NotificationSystem.newInstance({}, n => (notificationRU = n));
    NotificationSystem.newInstance({}, n => (notificationTC = n));

    const { appStatus } = this.props;
    if (appStatus.requiredDataLoaded) {
      console.log('ACCOMMODATIONS_HISTO -> Data loaded.. continue...');
      this.fetchData();
    }
  }

  componentDidUpdate(prevProps) {
    const { appStatus } = this.props;
    if (appStatus.requiredDataLoaded && !prevProps.appStatus.requiredDataLoaded) {
      console.log('ACCOMMODATIONS_HISTO -> Data finished loading.. continue...');
      this.fetchData();
    }

    if (!appStatus.appUpdating && prevProps.appStatus.appUpdating) {
      console.log('ACCOMMODATIONS_HISTO -> Data finished UPDATING.. refresh table...');
      this.fetchData();
    }
  }

  componentWillUnmount() {
    notificationLU.destroy();
    notificationRU.destroy();
    notificationTC.destroy();
  }

  async onRowSelect({ sleeperId, name, accommodationId, accommodationTitle, allowEdit }) {
    if (!allowEdit) {
      showNotification({
        notification: (
          <BasicNotification
            color="danger"
            title="Opozorilo!"
            message="Urejanje prenočišč NI omogočeno za prihajajoče datume!"
          />
        ),
        position: 'right-up',
      });
      return;
    }

    const editSleeplog = {
      sleeperId,
      name,
      accommodationId: {
        value: accommodationId,
        label: accommodationTitle,
      },
    };

    this.setState({ editSleeplog, showEdit: true });
  }

  fetchData() {
    const { accommodationsState } = this.props;
    this.setState({ accommodations: accommodationsState.accommodationTitles });
  }

  handleProgress(addProgress) {
    const { progressNum } = this.state;
    this.setState({ progressNum: progressNum + addProgress });
    const that = this;
    if (progressNum + addProgress >= 100) {
      setTimeout(() => {
        that.setState({ progressNum: 0 });
      }, 1000);
    }
  }

  showNotif = ({ title, message, success }) => {
    const color = success ? 'success' : 'danger';
    showNotification({
      notification: <BasicNotification color={color} title={title} message={message} />,
      position: 'right-up',
    });
  };

  handleCloseModal(data) {
    if (data) this.showNotif(data);
    this.setState({ showEdit: false });
    if (data) this.fetchData();
  }

  async handleDropdownSubmit(values) {
    try {
      if (values.selected_dropdown === null) {
        return;
      }

      ReactGA.event({
        category: 'Prenočitve - Izpisi',
        action: `Filtriranje - ${values.selected_dropdown.label}`,
      });
      const accommodationId = values.selected_dropdown.value;
      const data = await POST(API_LINK + API_SLEEPLOGS, JSON.stringify({ accommodationId }));
      if (data.success) {
        this.setState({
          sleeplogs: data.sleeplogs,
          selectedAccommodation: {
            ...values.selected_dropdown,
          },
        });
      }
      // console.log('FILTER:', filtered);
    } catch (error) {
      // console.log(error);
    }
  }

  // handleIntervalSubmit(values) {
  //   try {
  //     if (!values.interval_date) {
  //       selectedStartDate = null;
  //       selectedEndDate = null;
  //       this.fetchData();
  //       return;
  //     }

  //     const { start, end } = values.interval_date;

  //     selectedStartDate = start;
  //     selectedEndDate = end;
  //     this.fetchData();
  //   } catch (error) {
  //     // console.log(error);
  //   }
  // }

  render() {
    const { progressNum, sleeplogs, showEdit, editSleeplog, accommodations, selectedAccommodation } = this.state;
    const { t } = this.props;
    const shouldRenderTable = (sleeplogs && sleeplogs.length > 0) || selectedAccommodation;

    return (
      <Container className="dashboard">
        <Row>
          <Col lg={12}>
            <h3 className="page-title">
              {t('sidebar_content.accommodations.title')} - {t('sidebar_content.accommodations.history')}
            </h3>
          </Col>
          <Col lg={12}>
            <Row>
              <Col lg={6}>
                <Card>
                  <CardBody>
                    <SelectDropdown
                      title={`${t('board.ow.accommodation')}:`}
                      items={accommodations}
                      onSubmit={this.handleDropdownSubmit}
                    />
                  </CardBody>
                </Card>
              </Col>
              {/* <Col lg={4}>
                <Card>
                  <CardBody>
                    <SelectInterval title="Izberi obdobje" onSubmit={this.handleIntervalSubmit} />
                  </CardBody>
                </Card>
              </Col> */}
              <Col lg={6}>
                <ButtonToolbar>
                  <PrenocitveIzpisiModal
                    btn="Uredi prenočišče"
                    icon="edit"
                    title="Uredi prenočišče"
                    accommodations={accommodations}
                    hasBtn={false}
                    show={showEdit}
                    onClose={this.handleCloseModal}
                    editData={editSleeplog}
                  />
                </ButtonToolbar>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          {progressNum > 0 ? (
            <div className="progress-wrap progress-wrap--middle progress--align">
              <Progress animated value={progressNum} />
            </div>
          ) : null}
          {shouldRenderTable && (
            <PrenocitveIzpisiTable
              title={selectedAccommodation ? `${t('misc.archive')}: ${selectedAccommodation.label}` : 'Archiv'}
              sleeplogs={sleeplogs}
              onRowSelect={this.onRowSelect}
            />
          )}
        </Row>
      </Container>
    );
  }
}

const wr = connect(state => ({
  accommodationsState: state.accommodations,
  appStatus: state.appStatus,
}))(PrenocitveIzpisiPage);

export default translate('common')(wr);
