import { API_LINK } from 'constants/config';
import { GET } from 'helpers/agent';

export default {
  getall: async (withAddress = false) => {
    const dataWorksites = await GET(`${API_LINK}/w/getall`);
    if (dataWorksites.success) {
      const titlesList = [];
      const titlesAddressList = [];
      dataWorksites.worksites.forEach(work => {
        const workData = {
          value: work._id,
          label: work.title,
        };
        if (withAddress) {
          workData.label = `${workData.label} - ${work.address}`;
        }
        titlesList.push(workData);

        workData.label = `${workData.label} - ${work.address}`;
        titlesAddressList.push(workData);
      });
      return {
        worksites: dataWorksites.worksites,
        titlesWorksites: titlesList,
        worksitesWithAddress: titlesAddressList,
      };
    }
    return { worksites: [], titlesWorksites: [], worksitesWithAddress: [] };
  },
};
