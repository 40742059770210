import stable from 'stable';
import { CONFIG } from 'constants/config';

const lexCmpTitle = (a, b) => CONFIG.COLLATOR.compare(a.OW.worksite.title, b.OW.worksite.title);

export default function regroupWorklistByWorksites(worklist) {
  // console.log('TCL: FinanceService -> regroupWorklistByWorksites -> worklist', worklist);

  const compsUniqOWs = [];
  const flatten = worklist.reduce((listMain, element) => {
    const { company, total, workers } = element;
    const compsWithUniqOWs = {
      company,
      total,
      uniqOwIds: [],
    };
    const OWsWithWorkers = workers.reduce((list, worker) => {
      const { _id, name, surname, satnica, profession } = worker;
      worker.OW.forEach(ow => {
        if (!compsWithUniqOWs.uniqOwIds.includes(ow.owId)) {
          compsWithUniqOWs.uniqOwIds.push(ow.owId);
        }
        list.push({
          _id,
          name,
          surname,
          profession,
          satnica,
          ...ow,
        });
      });
      return list;
    }, []);

    compsUniqOWs.push(compsWithUniqOWs);

    listMain.push({
      company,
      total,
      OWs: OWsWithWorkers,
    });
    return listMain;
  }, []);
  // console.log('TCL: FinanceService -> regroupWorklistByWorksites -> flatten', flatten);
  // console.log('TCL: FinanceService -> regroupWorklistByWorksites -> compsUniqOWs', compsUniqOWs);

  const regrouped = compsUniqOWs.reduce((list, data) => {
    const { company, total, uniqOwIds } = data;
    const OWWithWorkers = uniqOwIds.reduce((owList, owId) => {
      const companyWithOws = flatten.find(f => f.company._id === company._id);
      const workers = companyWithOws.OWs.filter(o => o.owId === owId);
      const { orderer, worksite, OWAddonOnly } = workers[0];
      const Tsum = workers.reduce(
        (_total, { sum: { hoursSum, nightHoursSum } }) => {
          const __total = _total;
          __total.hoursSum += hoursSum;
          __total.nightHoursSum += nightHoursSum;
          __total.total += hoursSum + nightHoursSum;
          return __total;
        },
        { hoursSum: 0, nightHoursSum: 0, total: 0 },
      );
      owList.push({ OW: { owId, orderer, worksite, total: Tsum, OWAddonOnly }, workers });
      return owList;
    }, []);

    const sorted = stable(OWWithWorkers, lexCmpTitle);

    list.push({
      company,
      total,
      OWs: sorted,
    });

    return list;
  }, []);

  return regrouped;
}
