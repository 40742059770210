import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import fix, { commify } from 'helpers/floatParser';
import { getItem } from 'helpers/cookies';
import financeApi from 'api/finance';
import translateProfession from 'translations/dynamicTranslator';
import payload from '../../../../../helpers/jwt';
import { TMZFY, onlyUnique } from '../../../../../helpers/functions';
import constants from '../../../helpers/index';

const W100 = { width: '100px' };
const largeGray = { color: 'gray', fontSize: 'large' };

const { handleFocus } = constants;

class AddonsTableByWorksite extends React.Component {
  static propTypes = {
    addons: PropTypes.arrayOf(PropTypes.object).isRequired,
    selectedMonth: PropTypes.string.isRequired,
    selectedYear: PropTypes.string.isRequired,
    showWorksitesOnly: PropTypes.bool.isRequired,
    filter: PropTypes.object.isRequired,
    locked: PropTypes.bool.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      addons: props.addons,
      addonsFull: props.addons,
      showWorksitesOnly: props.showWorksitesOnly,
      filterValues: props.filter,
      locked: props.locked,
    };
  }

  tableInput = (id, value, type, index, indexOW, className = 'form-control', step = 1) => {
    return (
      <input
        onFocus={e => handleFocus(e)}
        onChange={e => this.handleChange(index, indexOW, id, e.target.value)}
        onBlur={e => this.handleSubmit(index, indexOW, id, e.target.value)}
        value={value}
        type={type}
        className={className}
        step={step}
      />
    );
  };

  handleChange = (index, indexOW, dataType, _value) => {
    let value = _value;
    if (!_value && dataType !== 'notes') value = 0;
    const { addons } = this.state;

    let modifiedBy = null;
    const token = getItem('jwt');
    if (token) {
      const { uid, name, surname, username } = payload(token);
      modifiedBy = { uid, name, surname, username, updatedAt: TMZFY(new Date(Date.now())) };
    }

    const newState = addons.map((arrItem, j) => {
      const _arrItem = arrItem;
      if (j === indexOW) {
        if (index || index === 0) {
          const inner = _arrItem.workers.map((item, i) => {
            if (i === index) {
              return { ...item, [dataType]: value, modifiedBy, employeeSpecific: true };
            }
            return item;
          });
          _arrItem.workers = inner;
        } else {
          _arrItem.OW.OWAddonOnly = {
            ...arrItem.OW.OWAddonOnly,
            [dataType]: value,
            modifiedBy,
          };

          const inner = _arrItem.workers.map(item => {
            if (!item.employeeSpecific) {
              return { ...item, [dataType]: value, modifiedBy };
            }
            return item;
          });
          _arrItem.workers = inner;
        }
      }
      return _arrItem;
    });

    this.setState({
      addons: newState,
    });
  };

  handleSubmit = (index, indexOW, dataType, _value) => {
    // /*eslint-  disable*/
    //return;

    let value = _value;
    if (!_value && dataType !== 'notes') value = 0;
    const { addons } = this.state;
    const { selectedMonth, selectedYear } = this.props;

    const {
      OW: { owId, OWAddonOnly },
      workers,
    } = addons[indexOW];
    if (index || index === 0) {
      const { addon, addonNight, _id } = workers[index];
      if (dataType === 'addon') {
        financeApi.upsertOWAddon(owId, _id, selectedMonth, selectedYear, value, addonNight, '');
      } else if (dataType === 'addonNight') {
        financeApi.upsertOWAddon(owId, _id, selectedMonth, selectedYear, addon, value, '');
      }
    } else {
      const { addon, addonNight } = OWAddonOnly;
      if (dataType === 'addon') {
        financeApi.upsertOWAddon(owId, null, selectedMonth, selectedYear, value, addonNight, '');
      } else if (dataType === 'addonNight') {
        financeApi.upsertOWAddon(owId, null, selectedMonth, selectedYear, addon, value, '');
      }
    }
  };

  calculateTotalsForOW = workers => {
    return workers.reduce(
      (tot, { sum }) => {
        const _tot = tot;
        _tot.totalDay += sum.hoursSum;
        _tot.totalNight += sum.nightHoursSum;
        return _tot;
      },

      { totalDay: 0, totalNight: 0 },
    );
  };

  calculateNettoForWorker = (satnica, sum, addon, addonNight, comma = true) => {
    const nettoDay = (Number(addon) + satnica) * sum.hoursSum;
    const nettoNight = (Number(addonNight) + satnica) * sum.nightHoursSum;
    if (comma) {
      return commify(fix(nettoDay + nettoNight));
    }
    return fix(nettoDay + nettoNight);
  };

  calcOWTotalNetto = (workers, indexOW) => {
    const { addons } = this.state;
    let total = 0;
    let index = 0;
    workers.forEach(worker => {
      const netto = this.calculateNettoForWorker(
        worker.satnica,
        worker.sum,
        addons[indexOW].workers[index].addon,
        addons[indexOW].workers[index].addonNight,
        false,
      );
      total += netto;
      index += 1;
    });
    //console.log('AddonssTableByWorksite -> total', total);
    return commify(fix(total));
  };

  applyFilter = (workers, worksites) => {
    const { addonsFull } = this.state;
    //console.log('TCL: applyFilter -> addonsFull', addonsFull);

    if (workers.length === 0 && worksites.length === 0) {
      this.setState({ addons: addonsFull });
      return;
    }

    let IDs = [];
    if (workers.length > 0) {
      const workersIds = workers.map(v => v.value);

      const ids = addonsFull.reduce((list, el) => {
        const exists = el.workers.find(w => workersIds.includes(w._id));
        if (exists) {
          list.push(el.OW.owId);
        }
        return list;
      }, []);

      IDs = IDs.concat(ids);
    }

    if (worksites.length > 0) {
      const worksiteIds = worksites.map(v => v.value);

      const ids = addonsFull.reduce((list, el) => {
        if (worksiteIds.includes(el.OW.worksite._id)) {
          list.push(el.OW.owId);
        }
        return list;
      }, []);

      IDs = IDs.concat(ids);
    }

    const uniqIds = IDs.filter(onlyUnique);
    //console.log('TCL: applyFilter -> uniqIds', uniqIds);
    const addons = addonsFull.filter(af => uniqIds.includes(af.OW.owId));
    this.setState({ addons });
  };

  UNSAFE_componentWillUpdate(nextProps, nextState) {
    if (nextProps.showWorksitesOnly !== this.props.showWorksitesOnly) {
      this.setState(prevState => ({
        showWorksitesOnly: !prevState.showWorksitesOnly,
      }));
    }

    if (!_.isEqual(nextProps.filter, this.props.filter)) {
      this.setState({ filterValues: nextProps.filter });
      this.applyFilter(nextProps.filter.workers, nextProps.filter.worksites);
    }

    if (nextProps.locked !== this.props.locked) {
      this.setState(prevState => ({
        locked: !prevState.locked,
      }));
    }
  }

  renderModified = modifiedBy => {
    const style = { color: 'lightgray', fontSize: 'smaller' };
    if (!modifiedBy) return <span>/</span>;
    return (
      <Fragment>
        <div>
          <span style={style}>{modifiedBy.name}</span> <span style={style}>{modifiedBy.surname}</span>
        </div>
        <div>
          <span style={style}>{modifiedBy.updatedAt}</span>
        </div>
      </Fragment>
    );
  };

  renderWorkers = (workers, indexOW) => {
    const { addons, showWorksitesOnly, locked } = this.state;

    return workers.map((worker, index) => {
      return (
        <tr className={showWorksitesOnly ? 'd-none' : ''}>
          <td />
          <td>{`${worker.surname.toUpperCase()} ${worker.name}`}</td>
          <td>{translateProfession(worker.profession, localStorage.getItem('lang') || 'sl')}</td>
          <td>{commify(fix(worker.sum.hoursSum))}</td>
          <td>{commify(fix(worker.sum.nightHoursSum))}</td>
          <td>{commify(fix(worker.sum.hoursSum + worker.sum.nightHoursSum))}</td>
          <td>{commify(fix(worker.satnica))}</td>
          {!locked ? (
            <Fragment>
              <td style={W100}>
                {this.tableInput('addon', addons[indexOW].workers[index].addon, 'number', index, indexOW)}
              </td>
              <td style={W100}>
                {this.tableInput('addonNight', addons[indexOW].workers[index].addonNight, 'number', index, indexOW)}
              </td>
            </Fragment>
          ) : (
            <Fragment>
              <td>
                <strong style={largeGray}>{worker.addon}</strong>
              </td>
              <td>
                <strong style={largeGray}>{worker.addonNight}</strong>
              </td>
            </Fragment>
          )}
          <td>
            <strong style={largeGray}>
              {this.calculateNettoForWorker(
                worker.satnica,
                worker.sum,
                addons[indexOW].workers[index].addon,
                addons[indexOW].workers[index].addonNight,
              )}
              €
            </strong>
          </td>
          <td>{this.renderModified(worker.modifiedBy)}</td>
        </tr>
      );
    });
  };

  renderTable = () => {
    const { addons, locked } = this.state;
    //console.log('TCL: AddonsTableByWorksite -> renderTable -> addons', addons);

    return addons.map((row, index) => {
      const { totalDay, totalNight } = this.calculateTotalsForOW(row.workers);
      return (
        <Fragment>
          <tr style={{ backgroundColor: 'ghostwhite' }}>
            <td style={{ maxWidth: '200px' }}>
              <p>{row.OW.worksite.title}</p>
              <p style={{ color: 'lightgray' }}>{row.OW.orderer.title}</p>
            </td>
            <td />
            <td />
            <td>{commify(fix(totalDay))}</td>
            <td>{commify(fix(totalNight))}</td>
            <td>{commify(fix(totalDay + totalNight))}</td>
            <td></td>
            {!locked ? (
              <Fragment>
                <td style={W100}>
                  {this.tableInput('addon', addons[index].OW.OWAddonOnly.addon, 'number', null, index)}
                </td>
                <td style={W100}>
                  {this.tableInput('addonNight', addons[index].OW.OWAddonOnly.addonNight, 'number', null, index)}
                </td>
              </Fragment>
            ) : (
              <Fragment>
                <td>
                  <strong style={largeGray}>{row.OW.OWAddonOnly.addon}</strong>
                </td>
                <td>
                  <strong style={largeGray}>{row.OW.OWAddonOnly.addonNight}</strong>
                </td>
              </Fragment>
            )}
            <td>
              <strong style={{ color: 'darkgray', fontSize: 'large' }}>
                {this.calcOWTotalNetto(row.workers, index)}€
              </strong>
            </td>
            <td>{this.renderModified(row.OW.OWAddonOnly.modifiedBy)}</td>
          </tr>
          {this.renderWorkers(row.workers, index)}
        </Fragment>
      );
    });
  };

  render() {
    /* const { filterValues } = this.state;
    console.log('TCL: render -> filterValues', filterValues); */
    return <tbody>{this.renderTable()}</tbody>;
  }
}

export default AddonsTableByWorksite;
