const validate = values => {
  const errors = {};

  if (!values.licensePlate) {
    errors.licensePlate = 'Registrska mora biti vnešena!';
  }

  if (!values.brand) {
    errors.brand = 'Znamka mora biti vnešena!';
  }

  if (!values.provider) {
    errors.provider = 'Znamka mora biti vnešena!';
  }

  if (!values.type) {
    errors.type = 'Izberite tip vozila!';
  }

  return errors;
};

export default validate;
