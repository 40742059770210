import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import docsApi from 'api/docs';
import { translate } from 'react-i18next';
import { LinkVariantIcon, DownloadIcon, MagnifyPlusIcon } from 'mdi-react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { UncontrolledTooltip } from 'reactstrap';
import slug from 'slug';

class DocumentVersionsTable extends Component {
  static propTypes = {
    files: PropTypes.array.isRequired,
    onDocumentRemoved: PropTypes.func.isRequired,
    downloadData: PropTypes.object.isRequired,
    onDownload: PropTypes.func.isRequired,
    notif: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
  };

  removeVersion = async file => {
    if (!confirm('Ali ste prepričani?')) {
      return;
    }
    const filePath = file
      .split('/')
      .slice(4)
      .join('/');
    try {
      await docsApi.remove(filePath);
      this.props.onDocumentRemoved(file);
    } catch (err) {
      console.log('there was an error');
    }
  };

  showNotification = () => {
    const { notif, t } = this.props;
    notif.showNotif({
      title: t('misc.copyclipboard'),
      message: t('misc.copysuccess'),
      success: true,
    });
  };

  renderFiles = () => {
    const { files, downloadData, onDownload, t } = this.props;
    const latestFile = files ? files[files.length - 1] : null;
    const ucttID = latestFile ? slug(latestFile) : null;
    const nofileclass = !latestFile ? ' form__nofile' : '';

    return (
      <Fragment>
        <div className={`wide-24${nofileclass}`}>
          {latestFile && (
            <Fragment>
              <CopyToClipboard text={latestFile} onCopy={this.showNotification} id={`${ucttID}ctc`}>
                <LinkVariantIcon />
              </CopyToClipboard>
              <UncontrolledTooltip placement="top" target={`${ucttID}ctc`}>
                {t('misc.copylink')}
              </UncontrolledTooltip>
            </Fragment>
          )}
        </div>
        <div
          className={`wide-24${nofileclass}`}
          role="button"
          onClick={() =>
            latestFile
              ? onDownload(
                  downloadData.type,
                  downloadData.workerId,
                  downloadData.name,
                  downloadData.surname,
                  downloadData.fieldNum,
                )
              : null
          }
        >
          {latestFile && (
            <Fragment>
              <DownloadIcon id={`${ucttID}down`} />
              <UncontrolledTooltip placement="top" target={`${ucttID}down`}>
                {t('form.download')}
              </UncontrolledTooltip>
            </Fragment>
          )}
        </div>
        <div className={`wide-24${nofileclass}`}>
          {latestFile && (
            <Fragment>
              <a
                href={latestFile}
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: '#646777' }}
                id={`${ucttID}preview`}
              >
                <MagnifyPlusIcon />
              </a>
              <UncontrolledTooltip placement="top" target={`${ucttID}preview`}>
                {t('misc.preview')}
              </UncontrolledTooltip>
            </Fragment>
          )}
        </div>
      </Fragment>
    );
    // return files.map(file => (
    //   <tr>
    //     <td>{file}</td>
    //     <td>
    //       <a href={file} target="_blank" rel="noopener noreferrer">
    //         {t('misc.preview')}
    //       </a>
    //     </td>
    //     <td>
    //       <Button
    //         size="sm"
    //         onClick={() =>
    //           onDownload(
    //             downloadData.type,
    //             downloadData.workerId,
    //             downloadData.name,
    //             downloadData.surname,
    //             downloadData.fieldNum,
    //           )
    //         }
    //       >
    //         {t('form.download')}
    //       </Button>
    //     </td>
    //     <td>
    //       <Button size="sm" onClick={() => this.removeVersion(file)}>
    //         {t('form.delete')}
    //       </Button>
    //     </td>
    //   </tr>
    // ));
  };

  render() {
    //const { files } = this.props;

    return (
      <div className="docs-right-btns">{this.renderFiles()}</div>
      // <Table>
      //   <thead>
      //     <tr>
      //       <th>{t('form.name')}</th>
      //       <th>{t('misc.preview')}</th>
      //       <th>{t('form.download')}</th>
      //       <th>{t('form.delete')}</th>
      //     </tr>
      //   </thead>
      //   <tbody>{}</tbody>
      // </Table>
    );
  }
}

export default translate('common')(DocumentVersionsTable);
