import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import { Table, ButtonToolbar, Progress, Button, UncontrolledTooltip } from 'reactstrap';
import moment from 'moment-timezone';
import { CompaniesProps, EmployeesProps } from 'shared/prop-types/ReducerProps';
import { getItem, setItem } from 'helpers/cookies';
import financeApi from 'api/finance';
import { LockIcon, LockOpenIcon } from 'mdi-react';
import getRoles from '../../../../../constants/roles';
import Dropdown from '../../../Filters/Dropdown';
import fix, { commify } from '../../../../../helpers/floatParser';
import constants from '../../../helpers/index';
import payload from '../../../../../helpers/jwt';
import { TMZFY } from '../../../../../helpers/functions';
import { MultiSelectField } from '../../../../../shared/components/form/MultiSelect';

const W100 = { width: '100px' };
const W200 = { width: '200px' };
const largeGray = { color: 'gray', fontSize: 'large' };
const TAB_ID = 'wages-compensations';

const {
  AMCompanies,
  MONTHS,
  YEARS,
  SATNICE_FILTER_MONTH,
  SATNICE_FILTER_YEAR,
  SATNICE_FILTER_COMPANY,
  SATNICE_FILTER_COMPANY_NAME,
  handleFocus,
} = constants;

class CompensationTable extends Component {
  static propTypes = {
    companiesStore: CompaniesProps.isRequired,
    workersState: EmployeesProps.isRequired,
    onRowClick: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      wages: [],
      wagesFull: [],
      selectedMonth: getItem(SATNICE_FILTER_MONTH) || moment(new Date()).month() + 1,
      selectedYear: getItem(SATNICE_FILTER_YEAR) || moment(new Date()).year(),
      selectedCompany: getItem(SATNICE_FILTER_COMPANY) || this.handleProperCompanies()[0].value,
      selectedCompanyName: getItem(SATNICE_FILTER_COMPANY_NAME) || this.handleProperCompanies()[0].label,
      selectedWorkers: [],
      locked: false,
      ROLES: getRoles(),
      progress: 0,
    };
  }

  componentDidMount() {
    const { selectedMonth, selectedYear, selectedCompany, selectedView } = this.state;
    this.fetchWages(selectedMonth, selectedYear, selectedCompany, selectedView);
  }

  dismissProgress = () => {
    this.setState({ progress: 100 });
    setTimeout(() => {
      this.setState({ progress: 0 });
    }, 1000);
  };

  handleProperCompanies = () => {
    const {
      companiesStore: { companiesTitles },
    } = this.props;

    const token = getItem('jwt');
    if (token) {
      const { customerId } = payload(token);
      //a.m.
      if (customerId === 'NZ3YJkYx') {
        return companiesTitles.filter(ct => AMCompanies.includes(ct.value));
      }
    }

    return companiesTitles;
  };

  fetchWages = async (month, year, companyId) => {
    this.setState({ progress: 15 });
    const { wages, locked } = await financeApi.getWagesList(month, year, companyId, TAB_ID);
    this.setState({ wages, wagesFull: wages, locked });
    this.dismissProgress();
  };

  applyFilter = workers => {
    const { wagesFull } = this.state;

    if (workers.length === 0) {
      this.setState({ wages: wagesFull });
      return;
    }

    const ids = workers.map(w => w.value);
    const wages = wagesFull.filter(wf => ids.includes(wf._id));

    this.setState({ wages });
  };

  tableInput = (id, value, type, index, className = 'form-control', step = 1) => {
    const colorClass = type === 'number' && value < 0 ? ' red-text' : '';
    if (type === 'number') {
      return (
        <input
          onFocus={e => handleFocus(e)}
          onChange={e => this.handleChange(index, id, e.target.value)}
          onBlur={e => this.handleSubmit(index, id, e.target.value)}
          value={value}
          type={type}
          className={className + colorClass}
          step={step}
        />
      );
    }
    return (
      <textarea
        rows={1}
        onChange={e => this.handleChange(index, id, e.target.value)}
        onBlur={e => this.handleSubmit(index, id, e.target.value)}
        value={value}
        className={className}
      />
    );
  };

  handleChange = (index, dataType, _value) => {
    const value = _value;
    //if (!_value && dataType !== 'notes') value = 0;

    const { wages } = this.state;

    let modifiedBy = null;
    const token = getItem('jwt');
    if (token) {
      const { uid, name, surname, username } = payload(token);
      modifiedBy = { uid, name, surname, username, updatedAt: TMZFY(new Date(Date.now())) };
    }

    const newState = wages.map((item, i) => {
      if (i === index) {
        return { ...item, [dataType]: value, modifiedBy };
      }
      return item;
    });

    this.setState({
      wages: newState,
    });
  };

  handleSubmit = (index, dataType, _value) => {
    let value = _value;
    if (!_value && dataType !== 'notes') value = 0;
    const { wages, selectedMonth, selectedYear } = this.state;
    const { _id, notes, compensation } = wages[index];

    if (dataType === 'compensation') {
      financeApi.upsertCompensation(_id, selectedMonth, selectedYear, value, notes, TAB_ID);
    } else {
      financeApi.upsertCompensation(_id, selectedMonth, selectedYear, compensation, value, TAB_ID);
    }
  };

  handleCompanySelect = (companyId, color, name) => {
    const { selectedMonth, selectedYear } = this.state;
    this.setState({ selectedCompany: companyId, selectedCompanyName: name });
    setItem(SATNICE_FILTER_COMPANY, companyId);
    setItem(SATNICE_FILTER_COMPANY_NAME, name);
    this.fetchWages(selectedMonth, selectedYear, companyId);
  };

  handleMonthSelect = month => {
    const { selectedCompany, selectedYear } = this.state;
    this.setState({ selectedMonth: month });
    setItem(SATNICE_FILTER_MONTH, month);
    this.fetchWages(month, selectedYear, selectedCompany);
  };

  handleYearSelect = year => {
    const { selectedCompany, selectedMonth } = this.state;
    this.setState({ selectedYear: year });
    setItem(SATNICE_FILTER_YEAR, year);
    this.fetchWages(selectedMonth, year, selectedCompany);
  };

  handleWorkerChange = values => {
    const { selectedWorksites } = this.state;
    this.setState({ selectedWorkers: values });
    this.applyFilter(values, selectedWorksites);
  };

  handleLockdown = async () => {
    const { selectedMonth, selectedYear } = this.state;
    await financeApi.setLockdown(selectedMonth, selectedYear, TAB_ID);

    this.setState(prevState => ({
      locked: !prevState.locked,
    }));
  };

  renderLockButton = () => {
    const { locked } = this.state;
    const { t } = this.props;
    return (
      <Fragment>
        <Button id="sat-lock-btn" size="sm" color="danger" className="sat-lock-btn" onClick={this.handleLockdown}>
          {locked ? <LockIcon /> : <LockOpenIcon />}
        </Button>
        <UncontrolledTooltip placement="top" target="sat-lock-btn">
          {!locked ? t('finance.lock') : t('finance.unlock')}
        </UncontrolledTooltip>
      </Fragment>
    );
  };

  renderProgress = () => {
    const { progress } = this.state;
    return progress === 0 ? (
      <div style={{ width: '100%', height: '5px', backgroundColor: '#ECECEC', marginBottom: '30px' }}></div>
    ) : (
      <div className="progress-wrap progress-wrap--blue">
        <Progress value={progress} />
      </div>
    );
  };

  renderModified = modifiedBy => {
    const style = { color: 'lightgray' };
    if (!modifiedBy) return <span>/</span>;
    if (modifiedBy.uid === 'import')
      return (
        <span>
          <strong style={style}>IMPORT</strong>
        </span>
      );
    return (
      <Fragment>
        <div>
          <span style={style}>{modifiedBy.name}</span> <span style={style}>{modifiedBy.surname}</span>
        </div>
        <div>
          <span style={style}>{modifiedBy.updatedAt}</span>
        </div>
      </Fragment>
    );
  };

  renderMultiSelect = () => {
    const { selectedWorkers, selectedCompany } = this.state;
    const { workersState, t } = this.props;

    const companyWorkers = workersState.employees.filter(e => e.companyId === selectedCompany);
    const companyWorkersIds = companyWorkers.map(c => c.id);
    const employees = workersState.employeesNames.filter(e => companyWorkersIds.includes(e.id));

    const widthStyle = { width: '400px' };
    return (
      <div style={widthStyle}>
        <div className="display-table">
          <MultiSelectField
            name="addons-workers-filter-search-2"
            onChange={this.handleWorkerChange}
            placeholder={`${t('sidebar_content.workers.title')}`}
            options={employees}
            value={selectedWorkers}
          />
        </div>
      </div>
    );
  };

  renderDropdown = () => {
    return (
      <Fragment>
        <Dropdown
          defaultOption={this.state.selectedCompanyName}
          options={this.handleProperCompanies()}
          onItemSelect={this.handleCompanySelect}
        />
        <Dropdown
          defaultOption={this.state.selectedYear}
          options={YEARS.map(m => {
            return { value: m, label: m.toString(), color: 'white' };
          })}
          onItemSelect={this.handleYearSelect}
        />
        <Dropdown
          defaultOption={this.state.selectedMonth}
          options={MONTHS.map(m => {
            return { value: m, label: m.toString(), color: 'white' };
          })}
          onItemSelect={this.handleMonthSelect}
        />
      </Fragment>
    );
  };

  renderTableHeads = () => {
    const { t } = this.props;
    return (
      <thead>
        <tr>
          <th>#</th>
          <th>{t('board.ow.worker')}</th>
          <th>{t('finance.compensation')}</th>
          <th>Saldo ({t('cashbox.confirmed')})</th>
          <th>Saldo ({t('cashbox.unconfirmed')})</th>
          <th>{t('finance.netto')}</th>
          <th>{t('calcs.sum')}</th>
        </tr>
      </thead>
    );
  };

  calcWage = (netto, saldo, compensation) => commify(fix(Number(netto) + Number(saldo) + Number(compensation)));

  renderWagesTable = () => {
    const { wages, locked, selectedMonth, selectedYear } = this.state;
    const { onRowClick } = this.props;
    //console.log('TCL: CompensationTable -> renderWagesTable -> wages', wages);

    return wages.map((employee, index) => {
      const saldoColor = employee.saldo.confirmed < 0 ? 'red' : 'green';
      const saldoConfFixed = fix(employee.saldo.confirmed);
      const saldoUnConfFixed = fix(employee.saldo.unconfirmed);
      const saldoString = Number(saldoConfFixed) > 0 ? `+${saldoConfFixed}` : saldoConfFixed;
      const unconfirmedSaldoString = Number(saldoUnConfFixed) > 0 ? `+${saldoUnConfFixed}` : saldoUnConfFixed;
      const unconfirmedSaldoColor = saldoUnConfFixed < 0 ? 'red' : 'green';
      const rowStyle = employee.unconfirmed ? { backgroundColor: 'pink' } : {};

      return (
        <tr
          key={employee._id}
          style={rowStyle}
          onClick={() =>
            onRowClick({
              employeeId: employee._id,
              month: selectedMonth,
              year: selectedYear,
              surname: employee.surname,
              name: employee.name,
            })
          }
        >
          <td>{index + 1}</td>
          <td>{`${employee.surname} ${employee.name}`}</td>
          {/* {!locked ? (
            <td style={W100}>
            {this.tableInput('compensation', wages[index].compensation, 'number', index, undefined, 50)}
            </td>
            ) : (
              <td>
              <strong style={largeGray}>{employee.compensation}</strong>
              </td>
            )} */}
          <td>
            <strong style={largeGray}>{employee.compensation}€</strong>
          </td>
          <td style={{ color: saldoColor, fontSize: 'large' }}>{saldoString}€</td>
          <td style={{ color: unconfirmedSaldoColor, fontSize: 'large' }}>{unconfirmedSaldoString}€</td>
          <td style={largeGray}>{commify(fix(employee.netto))}€</td>
          <td>
            <strong style={largeGray}>{this.calcWage(employee.netto, saldoConfFixed, employee.compensation)}€</strong>
          </td>
          {/* {!locked ? (
            <td style={W200}>{this.tableInput('notes', wages[index].notes, 'text', index)}</td>
          ) : (
            <td>
              <strong style={largeGray}>{employee.notes}</strong>
            </td>
          )} */}
          {/* <td>{this.renderModified(employee.modifiedBy)}</td> */}
        </tr>
      );
    });
  };

  render() {
    const { t } = this.props;
    const { wages, ROLES, locked } = this.state;
    return (
      <Fragment>
        <ButtonToolbar style={{ position: 'relative' }}>
          {this.renderDropdown()}
          {this.renderMultiSelect('workers')}
          {/* {ROLES.super && this.renderLockButton()} */}
        </ButtonToolbar>
        {this.renderProgress()}
        {locked && (
          <h4 style={{ color: 'red', textAlign: 'center', marginBottom: '30px' }}>
            <strong>{t('finance.locked').toUpperCase()}</strong>
          </h4>
        )}
        {wages.length > 0 && (
          <Table hover className="table--bordered" responsive>
            {this.renderTableHeads()}
            <tbody>{this.renderWagesTable()}</tbody>
          </Table>
        )}
      </Fragment>
    );
  }
}

const wr = connect(state => ({
  workersState: state.employees,
  companiesStore: state.companies,
}))(CompensationTable);

export default translate('common')(wr);
