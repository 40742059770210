import React from 'react';
import PropTypes from 'prop-types';
import { Button, Modal } from 'reactstrap';
import ReactGA from 'react-ga';
import classNames from 'classnames';
import NapotitveForm from './NapotitveForm';
import { API_LINK } from '../../../constants/config';
import { POST } from '../../../helpers/agent';

const API_ADD_ASSIGNMENT = '/a/add';
const API_STAGE_ASSIGNMENT = '/a/stage';
const API_EDIT_STAGE_ASSIGNMENT = '/a/updateStage';
const API_EDIT_ASSIGNMENT = '/a/update';
const API_DELETE_ASSIGNMENT = '/a/delete';
const API_DELETE_STAGE_ASSIGNMENT = '/a/deleteStage';

const parseWorkerFields = fields => {
  const allFields = {};

  allFields._id = fields._id || undefined;
  allFields.date = fields.date || undefined;
  allFields.departureDate = fields.departureDate || undefined;
  allFields.placeholders = fields.placeholders;

  if (fields.ordererId) {
    if (fields.ordererId.value) {
      allFields.ordererId = fields.ordererId.value;
    } else {
      allFields.ordererId = fields.ordererId;
    }
  }

  if (fields.worksiteId) {
    if (fields.worksiteId.value) {
      allFields.worksiteId = fields.worksiteId.value;
    } else {
      allFields.worksiteId = fields.worksiteId;
    }
  }
  allFields.notes = fields.notes || undefined;

  const parsedFields = [];
  for (let index = 0; index < 100; index += 1) {
    const { extra } = fields;
    if (extra.includes(index)) continue;
    const workerData = {
      startsFrom: false,
    };
    let shouldAdd = false;
    if (fields[`workerId${index}`]) {
      workerData.worker = fields[`workerId${index}`].value;
      shouldAdd = true;
    }

    if (fields[`accommodationId${index}`]) {
      workerData.accommodation = fields[`accommodationId${index}`].value;
    }

    if (fields[`vehicleId${index}`]) {
      workerData.vehicle = fields[`vehicleId${index}`].value;
    }

    if (fields[`startsFrom${index}`] !== null) {
      workerData.startsFrom = fields[`startsFrom${index}`];
    }

    if (shouldAdd) parsedFields.push(workerData);
  }

  allFields.workers = parsedFields;

  return allFields;
};

class NapotitveModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
    };

    this.toggle = this.toggle.bind(this);
    this.cancel = this.cancel.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleESC = this.handleESC.bind(this);
  }

  componentDidMount() {
    document.addEventListener('keydown', this.handleESC, false);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleESC, false);
  }

  handleESC(event) {
    if (event.keyCode === 27) {
      this.setState({ modal: false });
      this.cancel(null);
    }
  }

  toggle() {
    if (!this.state.modal) {
      ReactGA.event({
        category: 'Napotitve',
        action: `Odprtje modala za Napotitve - ${this.props.editData ? 'Urejanje' : 'Dodajanje'}`,
      });
    }
    this.setState(prevState => ({
      modal: !prevState.modal,
    }));
  }

  cancel(data) {
    this.setState({
      modal: false,
    });
    this.props.onClose(data);
  }

  async handleDelete(data) {
    const apiLink = data.staged ? API_DELETE_STAGE_ASSIGNMENT : API_DELETE_ASSIGNMENT;
    const successMessage = `Napotitev id: (${data._id}) uspešno izbrisana!`;
    const errorMessage = `Napotitev id: (${data._id}) NI uspešno izbrisana!`;
    try {
      const dataRes = await POST(API_LINK + apiLink, JSON.stringify({ _id: data._id }));
      if (dataRes.success) {
        this.cancel({
          title: 'Izbris napotitve',
          message: successMessage,
          success: true,
        });
      } else {
        this.cancel({
          title: 'Izbris napotitve',
          message: errorMessage,
          success: false,
        });
      }
    } catch (error) {
      this.cancel(null);
    }
  }

  async handleSubmit(values) {
    const body = JSON.stringify(parseWorkerFields(values));

    const { staged, commit } = values;
    console.log('TCL: NapotitveModal -> handleSubmit -> values', values);

    const { icon } = this.props;
    const isEdit = icon !== 'add';
    let apiLink = '';
    if (isEdit && staged) {
      apiLink = API_EDIT_STAGE_ASSIGNMENT;
    } else if (isEdit && !staged) {
      apiLink = API_EDIT_ASSIGNMENT;
    } else {
      apiLink = API_STAGE_ASSIGNMENT;
    }
    let notifTitle = isEdit ? 'Uredi napotitev' : 'Dodaj napotitev';
    let successMessage = isEdit ? 'Napotitev uspešno posodobljena!' : 'Napotitev uspešno dodana!';
    let errorMessage = isEdit ? 'Napotitev ni uspešno posodobljena!' : 'Napotitev ni uspešno dodana!';

    if (staged && commit) {
      apiLink = API_ADD_ASSIGNMENT;
      notifTitle = 'Potrditev napotitve';
      successMessage = 'Napotitev uspešno potrjena!';
      errorMessage = 'Napotitev NI uspešno potrjena!';
    }

    try {
      const data = await POST(API_LINK + apiLink, body);
      if (data.success) {
        this.cancel({
          title: notifTitle,
          message: successMessage,
          success: true,
        });
      } else {
        this.cancel({
          title: notifTitle,
          message: errorMessage,
          success: false,
        });
      }
    } catch (error) {
      console.log(error);
      this.cancel(null);
    }
  }

  render() {
    const {
      btn,
      title,
      colored,
      header,
      hasBtn,
      show,
      editData,
      orderers,
      worksites,
      workers,
      vehicles,
      accommodations,
    } = this.props;

    const modalClass = classNames({
      'modal-dialog--colored': colored,
      'modal-dialog--header': header,
    });

    return (
      <div style={{ width: '100%' }}>
        {hasBtn ? (
          <Button size="sm" color="primary" onClick={this.toggle}>
            {btn}
          </Button>
        ) : null}

        <Modal isOpen={this.state.modal || show} toggle={this.toggle} className={`modal-dialog--primary ${modalClass}`}>
          <div className="modal__header">
            <button className="lnr lnr-cross modal__close-btn" onClick={this.toggle} />

            <h4 className="bold-text  modal__title">{title}</h4>
          </div>
          <div className="modal__body">
            <div className="theme-light">
              {editData ? (
                <NapotitveForm
                  orderers={orderers}
                  worksites={worksites}
                  workers={workers}
                  vehicles={vehicles}
                  accommodations={accommodations}
                  onSubmit={this.handleSubmit}
                  handleCancel={() => this.cancel(null)}
                  handleDelete={this.handleDelete}
                  initialValues={editData}
                  editData={editData}
                  initialFieldCount={editData.workersCount}
                />
              ) : (
                <NapotitveForm
                  orderers={orderers}
                  worksites={worksites}
                  workers={workers}
                  vehicles={vehicles}
                  accommodations={accommodations}
                  onSubmit={this.handleSubmit}
                  handleCancel={() => this.cancel(null)}
                  handleDelete={this.handleDelete}
                />
              )}
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

NapotitveModal.propTypes = {
  title: PropTypes.string,
  orderers: PropTypes.arrayOf(PropTypes.object).isRequired,
  worksites: PropTypes.arrayOf(PropTypes.object).isRequired,
  workers: PropTypes.arrayOf(PropTypes.object).isRequired,
  vehicles: PropTypes.arrayOf(PropTypes.object).isRequired,
  accommodations: PropTypes.arrayOf(PropTypes.object).isRequired,
  icon: PropTypes.string.isRequired,
  colored: PropTypes.bool,
  header: PropTypes.bool,
  btn: PropTypes.string.isRequired,
  hasBtn: PropTypes.bool.isRequired,
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  editData: PropTypes.object,
};

NapotitveModal.defaultProps = {
  title: '',
  colored: false,
  header: false,
  editData: null,
};

export default NapotitveModal;
