export const SET_WORKDAYS = 'SET_WORKDAYS';
export const SET_WORKERS = 'SET_WORKERS';

export function setWokrdays(workdays) {
  return {
    type: SET_WORKDAYS,
    workdays,
  };
}

export function setWorkers(workers) {
  return {
    type: SET_WORKERS,
    workers,
  };
}
