import React from 'react';
import PropTypes from 'prop-types';

import ReactExport from 'react-data-export';
import { Button } from 'reactstrap';
import { translate } from 'react-i18next';

/* eslint-disable */
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
/* eslint-enable */

const DownloadButton = (title, disabled) => (
  <Button size="sm" color="primary" disabled={disabled}>
    {title}
  </Button>
);

const generateHeaders = t => {
  const firstPart = [
    {
      title: '#',
    },
    {
      title: t('misc.title'),
    },
    {
      title: t('form.tel'),
    },
    {
      title: 'Email',
    },
    {
      title: t('misc.contact'),
    },
  ];

  const multiDataSet = [
    {
      columns: firstPart,
      data: [],
    },
  ];
  return multiDataSet;
};

const generateData = dataAll => {
  // console.log('TCL: generateData -> dataAll', dataAll);
  const data = [];
  for (let i = 0; i < dataAll.length; i += 1) {
    const provider = dataAll[i];
    const workerRow = [
      {
        value: Number(`${i + 1}`),
      },
      {
        value: provider.title,
      },
      {
        value: provider.tel,
      },
      {
        value: provider.email,
      },
      {
        value: provider.contact,
      },
    ];
    data.push(workerRow);
  }

  return data;
};

class PonudnikiSheet extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { data, btn, disabled, t } = this.props;

    const headers = generateHeaders(t);
    headers[0].data = generateData(data);
    return (
      <ExcelFile element={DownloadButton(btn, disabled)} filename={t('sidebar_content.accommodations.providers')}>
        <ExcelSheet dataSet={headers} name={t('sidebar_content.accommodations.providers')} />
      </ExcelFile>
    );
  }
}

PonudnikiSheet.propTypes = {
  btn: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  disabled: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
};

export default translate('common')(PonudnikiSheet);
