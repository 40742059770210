import React, { Fragment } from 'react';
import { Card, CardBody, Col, Table, Badge, UncontrolledTooltip } from 'reactstrap';
import PropTypes from 'prop-types';
import { translate } from 'react-i18next';

const mapEmployeesToTooltip = employees => {
  return employees.map(e => {
    return (
      <Fragment>
        <span>{`${e.surname.toUpperCase()} ${e.name}`}</span>
        <br />
      </Fragment>
    );
  });
};

const renderTable = (reservations, onRowSelect) =>
  reservations.map(reservation => (
    <tr
      key={reservation._id}
      id={reservation._id}
      onClick={() => onRowSelect(reservation._id)}
      style={reservation.isPaid ? { backgroundColor: 'gold' } : {}}
    >
      <td>{reservation.counter}</td>
      <td>
        <Badge style={{ backgroundColor: `${reservation.color}`, color: '#646777' }}>{reservation.company}</Badge>
      </td>
      <td>
        <strong>{reservation.invoiceNum}</strong>
      </td>
      <td>{reservation.provider}</td>
      <td>{reservation.accommodation}</td>
      <td>{reservation.term}</td>
      <td>{reservation.persons}</td>
      <td>{reservation.price}</td>
      <td>{reservation.priority}</td>
      <td>{reservation.datePaid}</td>
      <td>{reservation.worksite}</td>
      <td>
        <span id={`tt${reservation._id}`}>
          {reservation.employeeCount} / <strong>{reservation.persons}</strong>
        </span>
        {reservation.employees.length > 0 && (
          <UncontrolledTooltip placement="top" target={`tt${reservation._id}`}>
            {mapEmployeesToTooltip(reservation.employees)}
          </UncontrolledTooltip>
        )}
      </td>
      <td>{reservation.notes}</td>
    </tr>
  ));

const RezervacijeTable = ({ title, subtitle, reservations, onRowSelect, onSelectOptions, initialSelectValue, t }) => (
  <React.Fragment>
    <div>
      <div className="card__title">
        <h5 className="bold-text">{title}</h5>
        <h5 className="subhead">{subtitle}</h5>
      </div>
      <div
        style={{
          float: 'right',
          marginRight: '5px',
          marginBottom: '5px',
          color: 'gray',
        }}
      >
        {t('pagination.show')}
        <select className="select-options" onChange={onSelectOptions} value={initialSelectValue}>
          <option value="10">10</option>
          <option value="30">30</option>
          <option value="50">50</option>
        </select>
        {t('pagination.elements')}
      </div>
    </div>
    <Table hover className="table--bordered" responsive>
      <thead>
        <tr>
          <th />
          <th>{t('form.company')}</th>
          <th>{t('misc.invoiceNum')}</th>
          <th>{t('board.ow.provider')}</th>
          <th>{t('board.ow.accommodation')}</th>
          <th>{t('misc.termin')}</th>
          <th>{t('misc.persons')}</th>
          <th>{t('misc.price')}</th>
          <th>{t('misc.priority')}</th>
          <th>{t('misc.datePaid')}</th>
          <th>{t('form.worksite')}</th>
          <th>{t('form.capacity')}</th>
          <th>{t('form.notes')}</th>
        </tr>
      </thead>
      <tbody>{renderTable(reservations, onRowSelect)}</tbody>
    </Table>
  </React.Fragment>
);

RezervacijeTable.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  reservations: PropTypes.arrayOf(PropTypes.object).isRequired,
  onRowSelect: PropTypes.func.isRequired,
  onSelectOptions: PropTypes.func.isRequired,
  initialSelectValue: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
};

export default translate('common')(RezervacijeTable);
