import { API_LINK } from 'constants/config';
import { POST } from 'helpers/agent';

export default {
  getOne: async data => {
    const response = await POST(`${API_LINK}/pr/getOneById`, JSON.stringify(data));
    return response.provider;
  },
  create: async data => {
    const response = await POST(`${API_LINK}/pr/add`, JSON.stringify(data));
    return response.provider;
  },
};
