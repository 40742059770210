const validate = values => {
  const errors = {};

  if (values.kilometers === null) {
    errors.kilometers = 'To polje mora bit izpolnjeno';
  }

  if (values.receipt === true) {
    if (!values.travelOrderOption) {
      errors.travelOrderOption = 'To polje mora bit izpolnjeno';
    }
  }

  // TODO other errors

  return errors;
};

export default validate;
