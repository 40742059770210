import React, { Component } from 'react';
import { Col, Container, Row, ButtonToolbar, Card, CardBody, Progress } from 'reactstrap';
import NotificationSystem from 'rc-notification';
import ReactGA from 'react-ga';
import companiesApi from 'api/companies';
import { connect } from 'react-redux';
import { BasicNotification } from '../../shared/components/Notification';
import SelectDropdown from '../../shared/components/SelectDropdown';
import PodjetjaTable from './components/PodjetjaTable';
import PodjetjaModal from './components/PodjetjaModal';
import { CompaniesProps, DataListProps, AppStatusProps } from '../../shared/prop-types/ReducerProps';

let notificationLU = null;
let notificationRU = null;
let notificationTC = null;

const showNotification = ({ notification, position }) => {
  switch (position) {
    case 'left-up':
      notificationLU.notice({
        content: notification,
        duration: 5,
        closable: true,
        style: { top: 0, left: 240 },
        className: position,
      });
      break;
    case 'right-up':
      notificationRU.notice({
        content: notification,
        duration: 5,
        closable: true,
        style: { top: 0, left: 'calc(100vw - 100%)' },
        className: position,
      });
      break;
    default:
      notificationTC.notice({
        content: notification,
        duration: 5,
        closable: true,
        style: { top: 0, left: 0 },
        className: position,
      });
      break;
  }
};

class PodjetjaPage extends Component {
  static propTypes = {
    companiesState: CompaniesProps.isRequired,
    dataListState: DataListProps.isRequired,
    appStatus: AppStatusProps.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      companies: [],
      companiesFull: [],
      titles: [],
      countries: [],
      showEdit: false,
      progressNum: 0,
      editCompany: null,
    };

    this.onRowSelect = this.onRowSelect.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.showNotif = this.showNotif.bind(this);
    this.fetchData = this.fetchData.bind(this);
    this.handleProgress = this.handleProgress.bind(this);
    this.handleDropdownSubmit = this.handleDropdownSubmit.bind(this);
  }

  componentDidMount() {
    NotificationSystem.newInstance({}, n => (notificationLU = n));
    NotificationSystem.newInstance({}, n => (notificationRU = n));
    NotificationSystem.newInstance({}, n => (notificationTC = n));

    const { appStatus } = this.props;
    if (appStatus.requiredDataLoaded) {
      console.log('EMPLOYEES -> Data loaded.. continue...');
      this.fetchData();
    }
  }

  componentDidUpdate(prevProps) {
    const { appStatus } = this.props;
    if (appStatus.requiredDataLoaded && !prevProps.appStatus.requiredDataLoaded) {
      console.log('EMPLOYEES -> Data finished loading.. continue...');
      this.fetchData();
    }

    if (!appStatus.appUpdating && prevProps.appStatus.appUpdating) {
      console.log('EMPLOYEES -> Data finished UPDATING.. refresh table...');
      this.fetchData();
    }
  }

  componentWillUnmount() {
    notificationLU.destroy();
    notificationRU.destroy();
    notificationTC.destroy();
  }

  async onRowSelect(id) {
    // console.log('TCL: PodjetjaPage -> onRowSelect -> values', values);
    const { company } = await companiesApi.getone({ _id: id });
    if (company) {
      console.log('TCL: PodjetjaPage -> onRowSelect -> company', company);
      this.setState({ editCompany: company, showEdit: true });
    }
  }

  async fetchData() {
    const { companiesState, dataListState } = this.props;

    this.setState({
      companies: companiesState.companies,
      companiesFull: companiesState.companies,
      titles: companiesState.companiesTitles,
      countries: dataListState.countries,
    });
  }

  handleProgress(addProgress) {
    const { progressNum } = this.state;
    this.setState({ progressNum: progressNum + addProgress });
    const that = this;
    if (progressNum + addProgress >= 100) {
      setTimeout(() => {
        that.setState({ progressNum: 0 });
      }, 1000);
    }
  }

  showNotif = ({ title, message, success }) => {
    const color = success ? 'success' : 'danger';
    showNotification({
      notification: <BasicNotification color={color} title={title} message={message} />,
      position: 'right-up',
    });
  };

  handleCloseModal(data) {
    if (data) this.showNotif(data);
    this.setState({ showEdit: false });
    if (data) this.fetchData();
  }

  handleDropdownSubmit(values) {
    try {
      if (values.selected_dropdown === null) {
        this.fetchData();
        return;
      }
      ReactGA.event({
        category: 'Podjetja',
        action: `Filtriranje - ${values.selected_dropdown.label}`,
      });
      const { companiesFull } = this.state;
      const id = values.selected_dropdown.value;
      const filtered = [];
      companiesFull.forEach(ord => {
        if (ord._id === id) {
          filtered.push(ord);
        }
      });
      this.setState({ companies: filtered });
    } catch (error) {
      // console.log(error);
    }
  }

  render() {
    const { companies, progressNum, titles, countries, showEdit, editCompany } = this.state;

    return (
      <Container className="dashboard">
        <Row>
          <Col lg={12}>
            <h3 className="page-title">Podjetja</h3>
          </Col>
          <Col lg={12}>
            <Row>
              <Col lg={6}>
                <Card>
                  <CardBody>
                    <SelectDropdown title="Išči podjetje" items={titles} onSubmit={this.handleDropdownSubmit} />
                  </CardBody>
                </Card>
              </Col>
              <Col lg={{ size: 4, offset: 2 }}>
                <ButtonToolbar className="button-toolbar text-right">
                  <PodjetjaModal
                    btn="Dodaj podjetje"
                    icon="add"
                    title="Dodaj podjetje"
                    countries={countries}
                    hasBtn
                    show={false}
                    onClose={this.handleCloseModal}
                  />
                </ButtonToolbar>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          {progressNum > 0 ? (
            <div className="progress-wrap progress-wrap--middle progress--align">
              <Progress animated value={progressNum} />
            </div>
          ) : null}
          <PodjetjaTable title="Podatki o podjetjih" companies={companies} onRowSelect={this.onRowSelect} />
        </Row>

        <PodjetjaModal
          btn="Uredi podjetje"
          icon="edit"
          title="Uredi podjetje"
          countries={countries}
          hasBtn={false}
          show={showEdit}
          onClose={this.handleCloseModal}
          editData={editCompany}
        />
      </Container>
    );
  }
}

export default connect(state => ({
  companiesState: state.companies,
  dataListState: state.dataList,
  appStatus: state.appStatus,
}))(PodjetjaPage);
