import React, { Component } from 'react';
import { Row, Col, Card, CardBody } from 'reactstrap';
//import { translate } from 'react-i18next';
import PropTypes from 'prop-types';
import { getItem, setItem } from 'helpers/cookies';
import moment from 'moment-timezone';
import Picker from 'react-month-picker';
import { PICKER_DATE_OW, TIMEZONE } from '../../../../../constants/config';
import RezDropdownFilter from './components/RezDropdownFilter';

const pickerLang = {
  months: ['Jan', 'Feb', 'Mar', 'Apr', 'Maj', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dec'],
  from: 'From',
  to: 'To',
};

class ReservationsFilters extends Component {
  static propTypes = {
    //t: PropTypes.func.isRequired,
    defaultValues: PropTypes.object.isRequired,
    onSubmit: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    const storedDate = getItem(PICKER_DATE_OW);
    let month =
      moment()
        .tz(TIMEZONE)
        .month() + 1;
    let year = moment()
      .tz(TIMEZONE)
      .year();
    if (storedDate) {
      month = Number(storedDate.split('/')[0]);
      year = Number(storedDate.split('/')[1]);
    }
    this.state = {
      currentValues: props.defaultValues,
      monthPickerValue: {
        year,
        month,
      },
    };
  }

  onMonthPickerClick = () => {
    this.monthPickerRef.show();
  };

  makeText = m => {
    if (m && m.year && m.month) return `${pickerLang.months[m.month - 1]}. ${m.year}`;
    return '?';
  };

  handleMonthChange = (year, month) => {
    setItem(PICKER_DATE_OW, `${month}/${year}`);
    this.setState({
      monthPickerValue: { year, month },
    });
    const currVals = this.state.currentValues;
    const newValues = { ...currVals, monthPicker: { year, month } };
    this.props.onSubmit(newValues);
    this.setState({ currentValues: newValues });
  };

  handleAMonthDissmis = (year, month) => {
    if (year && month) {
      setItem(PICKER_DATE_OW, `${month}/${year}`);
      this.setState({
        monthPickerValue: { year, month },
      });
    }
  };

  handleDropdownSubmitted = val => {
    const currVals = this.state.currentValues;
    const newValues = { ...currVals, dropdown: { ...val } };
    this.props.onSubmit(newValues);
    this.setState({ currentValues: newValues });
  };

  handleMonthClear = () => {
    const currVals = this.state.currentValues;
    const newValues = { ...currVals, monthPicker: null };
    this.props.onSubmit(newValues);
    this.setState({ currentValues: newValues });
  };

  render() {
    const { defaultValues } = this.props;
    const { monthPickerValue } = this.state;
    return (
      <Row>
        <Col md={12} lg={8}>
          <RezDropdownFilter defaultValues={defaultValues.dropdown} onSubmit={this.handleDropdownSubmitted} />
        </Col>
        <Col md={12} lg={{ size: 4 /*  offset: 4 */ }} style={{ marginTop: '6rem' }}>
          <Card>
            <CardBody>
              <div className="date-picker">
                <div className="form__form-group-label">Izberi mesec:</div>
                <Picker
                  ref={ref => (this.monthPickerRef = ref)}
                  years={[2017, 2018, 2019, 2020, 2021, 2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030]}
                  value={monthPickerValue}
                  lang={pickerLang.months}
                  onChange={this.handleMonthChange}
                  onDismiss={this.handleAMonthDissmis}
                >
                  <div role="button" tabIndex={0} onKeyDown={this.onMonthPickerClick} onClick={this.onMonthPickerClick}>
                    {this.makeText(monthPickerValue)}
                  </div>
                </Picker>
                <span
                  style={{
                    cursor: 'pointer',
                    textDecoration: 'underline',
                    float: 'right',
                    marginTop: '2px',
                  }}
                  role="button"
                  onClick={this.handleMonthClear}
                >
                  Vsi
                </span>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    );
  }
}

export default ReservationsFilters;
