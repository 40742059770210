import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-i18next';
import { connect } from 'react-redux';
import { Badge, Popover, PopoverHeader, PopoverBody, Button } from 'reactstrap';
import { CompaniesProps } from 'shared/prop-types/ReducerProps';
import getRoles from 'constants/roles';
import employeesApi from 'api/employees';
import EmployeesFormModal from '../Modals/EmployeesFormModal';

const ACTION_TYPES = {
  EMPLOYEE: 1,
  ACCOMMODATION: 2,
  A1: 3,
  VEHICLE: 4,
};

class HomeBoardTableRow extends Component {
  static propTypes = {
    companiesStore: CompaniesProps.isRequired,
    employee: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      employeesFormModalShown: false,
      employeePopoverShown: false,
      employeeSelectedTab: '1',
      ROLES: getRoles(),
    };
  }

  leaveInfo = () => {
    const { employee, t } = this.props;
    return {
      style: employee.onLeave ? { backgroundColor: '#6AABB8', color: 'white' } : null,
      color: employee.onLeave ? { color: 'white' } : {},
      text: employee.onLeave ? `- ${t('board.ow.leave')}` : '',
    };
  };

  currentCompany = () => {
    const { employee, companiesStore } = this.props;
    if (!employee.company) {
      return null;
    }
    return companiesStore.companies.find(comp => employee.company.companyId === comp._id);
  };

  badgeColor = () => {
    const company = this.currentCompany();
    if (!company) {
      return '';
    }
    return company.color;
  };

  toggleEmployeesPopover = () => {
    this.setState(prevState => ({
      employeePopoverShown: !prevState.employeePopoverShown,
    }));
  };

  getEditableEmployee = async () => {
    const { employee } = this.props;

    const data = await employeesApi.getOne(JSON.stringify({ id: employee._id }));
    return data;
  };

  showEmployeesFormModal = async (tabNum = '1') => {
    const data = await this.getEditableEmployee();
    this.setState({
      employeesFormModalShown: true,
      editableEmployee: data,
      employeeSelectedTab: tabNum,
    });
  };

  toggleCollapse = () => {
    if (window.innerWidth > 991) {
      return;
    }
    this.setState(prevState => ({
      collapsed: !prevState.collapsed,
    }));
  };

  hideEmployeesFormModal = () => {
    this.setState({ employeesFormModalShown: false });
  };

  render() {
    const { employee, t } = this.props;
    const { ROLES, employeePopoverShown, employeesFormModalShown, editableEmployee, employeeSelectedTab } = this.state;

    return (
      <tr style={this.leaveInfo().style || {}}>
        <td onClick={this.toggleCollapse} onKeyDown={this.toggleCollapse} role="presentation">
          <Badge
            style={{
              backgroundColor: `${this.badgeColor()}`,
              color: '#646777',
              cursor: 'pointer',
            }}
            id={`EmployeesPopover${employee._id}home`}
            onClick={this.toggleEmployeesPopover}
          >
            {`${employee.surname} ${employee.name} ${this.leaveInfo().text}`}
          </Badge>

          {ROLES.full && (
            <Popover
              placement="right"
              isOpen={employeePopoverShown}
              target={`EmployeesPopover${employee._id}home`}
              toggle={this.toggleEmployeesPopover}
            >
              <PopoverHeader>{t('board.ow.popover.editWorker')}</PopoverHeader>
              <PopoverBody className="board-actions">
                <Button color="primary" className="mr-0 mb-2" onClick={() => this.showEmployeesFormModal('1')}>
                  {t('board.ow.popover.profileWorker')}
                </Button>
                <Button color="primary" className="mr-0 mb-2" onClick={() => this.showEmployeesFormModal('4')}>
                  {t('board.notes.modalTitle')}
                </Button>
              </PopoverBody>
            </Popover>
          )}
        </td>
        {employeesFormModalShown && (
          <EmployeesFormModal
            title={t('board.ow.popover.editWorker')}
            icon="edit"
            show={employeesFormModalShown}
            onClose={this.hideEmployeesFormModal}
            editData={editableEmployee}
            onDocumentRemoved={this.handleDocumentRemoved}
            initialTab={employeeSelectedTab}
          />
        )}
      </tr>
    );
  }
}

const wr = connect(state => ({
  companiesStore: state.companies,
}))(HomeBoardTableRow);

export default translate('common')(wr);
