export const SET_EMPLOYEES = 'SET_EMPLOYEES';
export const SET_EMPLOYEES_NAMES = 'SET_EMPLOYEES_NAMES';

export function setEmployees(employees) {
  return {
    type: SET_EMPLOYEES,
    employees,
  };
}

export function setEmployeesNames(employeesNames) {
  return {
    type: SET_EMPLOYEES_NAMES,
    employeesNames,
  };
}
