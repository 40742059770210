import React from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-i18next';
import { Button, Modal } from 'reactstrap';
import classNames from 'classnames';
import ReactGA from 'react-ga';
import accommodationsApi from 'api/accommodations';
import RezervacijeForm from './RezervacijeForm';

class RezervacijeModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
    };

    this.toggle = this.toggle.bind(this);
    this.cancel = this.cancel.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleESC = this.handleESC.bind(this);
  }

  componentDidMount() {
    document.addEventListener('keydown', this.handleESC, false);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleESC, false);
  }

  handleESC(event) {
    if (event.keyCode === 27) {
      this.setState({ modal: false });
      this.cancel(null);
    }
  }

  toggle() {
    if (!this.state.modal) {
      ReactGA.event({
        category: 'Prenočitve - Rezervacije',
        action: `Odprtje modala za Prenočitve - Rezervacije - ${this.props.editData ? 'Urejanje' : 'Dodajanje'}`,
      });
    }
    this.setState({
      modal: !this.state.modal,
    });
  }

  cancel(data) {
    this.setState({
      modal: false,
    });
    this.props.onClose(data);
  }

  async handleDelete(data) {
    try {
      const { t } = this.props;
      const successMessage = `${t('messages.reservations.delete.success')}`;
      const errorMessage = `${t('messages.reservations.delete.error')}`;
      const success = await accommodationsApi.deleteReservation(data);
      if (success) {
        this.cancel({
          title: t('messages.reservations.delete.title'),
          message: successMessage,
          success: true,
        });
      } else {
        this.cancel({
          title: t('messages.reservations.delete.title'),
          message: errorMessage,
          success: false,
        });
      }
    } catch (error) {
      console.log('TCL: RezervacijeModal -> handleDelete -> error', error);
      this.cancel(null);
    }
  }

  async handleSubmit(values) {
    try {
      console.log('TCL: RezervacijeModal -> handleSubmit -> values', values);
      const { icon, t } = this.props;

      const isEdit = icon !== 'add';
      const notifTitle = isEdit ? t('messages.reservations.edit.title') : t('messages.reservations.add.title');
      const successMessage = isEdit ? t('messages.reservations.edit.success') : t('messages.reservations.add.success');
      const errorMessage = isEdit ? t('messages.reservations.edit.error') : t('messages.reservations.add.error');

      const success = !isEdit
        ? await accommodationsApi.addReservation(values)
        : await accommodationsApi.updateReservation(values);

      if (success) {
        this.cancel({
          title: notifTitle,
          message: successMessage,
          success: true,
        });
      } else {
        this.cancel({
          title: notifTitle,
          message: errorMessage,
          success: false,
        });
      }
    } catch (e) {
      console.log(e);
      this.cancel(null);
    }
  }

  render() {
    const {
      btn,
      title,
      colored,
      header,
      hasBtn,
      show,
      editData,
      companies,
      providers,
      accommodations,
      accommodationsFull,
      worksites,
    } = this.props;

    const modalClass = classNames({
      'modal-dialog--colored': colored,
      'modal-dialog--header': header,
    });

    return (
      <div className="text-right" style={{ width: '100%' }}>
        {hasBtn ? (
          <Button size="sm" color="primary" onClick={this.toggle}>
            {btn}
          </Button>
        ) : null}

        <Modal isOpen={this.state.modal || show} toggle={this.toggle} className={`modal-dialog--primary ${modalClass}`}>
          <div className="modal__header">
            <h4 className="bold-text  modal__title">{title}</h4>
          </div>
          <div className="modal__body">
            <div className="theme-light">
              {editData ? (
                <RezervacijeForm
                  onSubmit={this.handleSubmit}
                  companies={companies}
                  providers={providers}
                  accommodations={accommodations}
                  accommodationsFull={accommodationsFull}
                  worksites={worksites}
                  handleCancel={() => this.cancel(null)}
                  handleDelete={this.handleDelete}
                  initialValues={editData}
                  editData={editData}
                />
              ) : (
                <RezervacijeForm
                  onSubmit={this.handleSubmit}
                  companies={companies}
                  providers={providers}
                  accommodations={accommodations}
                  accommodationsFull={accommodationsFull}
                  worksites={worksites}
                  handleCancel={() => this.cancel(null)}
                  handleDelete={this.handleDelete}
                />
              )}
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

RezervacijeModal.propTypes = {
  title: PropTypes.string,
  companies: PropTypes.arrayOf(PropTypes.object).isRequired,
  providers: PropTypes.arrayOf(PropTypes.object).isRequired,
  accommodations: PropTypes.arrayOf(PropTypes.object).isRequired,
  accommodationsFull: PropTypes.arrayOf(PropTypes.object).isRequired,
  worksites: PropTypes.arrayOf(PropTypes.object).isRequired,
  icon: PropTypes.string.isRequired,
  colored: PropTypes.bool,
  header: PropTypes.bool,
  btn: PropTypes.string.isRequired,
  hasBtn: PropTypes.bool.isRequired,
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  editData: PropTypes.object,
  t: PropTypes.func.isRequired,
};

RezervacijeModal.defaultProps = {
  title: '',
  colored: false,
  header: false,
  editData: null,
};

export default translate('common')(RezervacijeModal);
