import {
  SET_PROFESSIONS_DATA_LIST,
  SET_COUNTRIES_DATA_LIST,
  SET_DOCS_EXP_DATA_LIST,
  SET_TRANSACTION_SOURCES_DATA_LIST,
  SET_TRANSACTION_CATEGORIES_DATA_LIST,
} from '../actions/dataListActions';

const initialState = {
  professions: [],
  countries: [],
  transactionSources: [],
  transactionCategories: [],
  docsExpTimetable: null,
  docTypes: [],
  showAlert: false,
};

export default function(state = initialState, action) {
  switch (action.type) {
    case SET_PROFESSIONS_DATA_LIST:
      return { ...state, professions: action.professions };
    case SET_COUNTRIES_DATA_LIST:
      return { ...state, countries: action.countries };
    case SET_TRANSACTION_SOURCES_DATA_LIST:
      return { ...state, transactionSources: action.transactionSources };
    case SET_TRANSACTION_CATEGORIES_DATA_LIST:
      return { ...state, transactionCategories: action.transactionCategories };
    case SET_DOCS_EXP_DATA_LIST:
      return {
        ...state,
        docsExpTimetable: action.docsData.docsExpTimetable,
        docTypes: action.docsData.docTypes,
        showAlert: action.docsData.showAlert,
      };
    default:
      return state;
  }
}
