import { combineReducers, createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { persistStore, persistReducer } from 'redux-persist';
import { reducer as reduxFormReducer } from 'redux-form';
import storage from 'redux-persist/lib/storage';

import {
  sidebarReducer,
  themeReducer,
  docsReducer,
  hoursReducer,
  userReducer,
  employeesReducer,
  appReducer,
  worksitesReducer,
  vehiclesReducer,
  orderersReducer,
  accommodationsReducer,
  companiesReducer,
  dataListReducer,
  owReducer,
  placesReducer,
} from '../../redux/reducers';

const reducer = combineReducers({
  form: reduxFormReducer, // mounted under "form",
  theme: themeReducer,
  sidebar: sidebarReducer,
  docs: docsReducer,
  hours: hoursReducer,
  user: userReducer,
  employees: employeesReducer,
  appStatus: appReducer,
  worksites: worksitesReducer,
  vehicles: vehiclesReducer,
  orderers: orderersReducer,
  accommodations: accommodationsReducer,
  companies: companiesReducer,
  dataList: dataListReducer,
  ows: owReducer,
  places: placesReducer,
});

const persistConfig = {
  key: 'root',
  storage,
  blacklist: ['form'],
};

const persistedReducer = persistReducer(persistConfig, reducer);

const configureStore = () => {
  const store = createStore(
    persistedReducer,
    compose(applyMiddleware(thunk)),
    // window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(),
  );

  if (module.hot) {
    console.log('"REDUX HOT RELOAD!!');
    // Enable Webpack hot module replacement for reducers
    module.hot.accept('../../redux/reducers', () => {
      // eslint-disable-next-line global-require
      const nextRootReducer = require('../../redux/reducers/index');
      store.replaceReducer(nextRootReducer);
    });
  }

  return store;
};

const store = configureStore();

export default store;
export const persistor = persistStore(store);
