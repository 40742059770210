import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-i18next';
import { Table, Card, CardBody, Badge } from 'reactstrap';
import shortid from 'shortid';
import stable from 'stable';
import { CONFIG } from '../../../../constants/config';
import fix, { commify } from '../../../../helpers/floatParser';

const LEX_COMP_TITLE = (a, b) => CONFIG.COLLATOR.compare(a.title, b.title);
class WorklistFull extends Component {
  renderTableBody() {
    const { data } = this.props;
    console.log('TCL: WorklistFull -> renderTableBody -> data', data);
    const lang = localStorage.getItem('lang') || 'sl';
    return data.worklist.map(element => (
      <Fragment>
        <tr key={shortid.generate()}>
          <td>
            <Badge
              style={{
                backgroundColor: `${element.company.color}`,
                color: '#646777',
              }}
            >
              {lang === 'de' && element.company.name === 'VSA PODJETJA' ? 'ALLES UNTERNEHMEN' : element.company.name}
            </Badge>
          </td>
          <td />
          <td />
          <td>{element.sumDay === 0 && element.sumNight === 0 ? '' : commify(fix(element.sumDay))}</td>
          <td>{element.sumDay === 0 && element.sumNight === 0 ? '' : commify(fix(element.sumNight))}</td>
          <td>{element.total === 0 ? '' : commify(fix(element.total))}</td>
        </tr>
        {this.renderOrderers(element.orderers)}
      </Fragment>
    ));
  }

  renderOrderers(orderers) {
    const orderersSorted = stable(orderers, LEX_COMP_TITLE);
    return orderersSorted.map(orderer => (
      <Fragment>
        <tr key={shortid.generate()}>
          <td />
          <td>{orderer.title}</td>
          <td />
          <td>{commify(fix(orderer.sumDay))}</td>
          <td>{commify(fix(orderer.sumNight))}</td>
          <td>{commify(fix(orderer.total))}</td>
        </tr>
        {this.renderWorksites(orderer.worksites)}
      </Fragment>
    ));
  }

  renderWorksites(worksites) {
    const worksitesSorted = stable(worksites, LEX_COMP_TITLE);
    return worksitesSorted.map(worksite => (
      <tr key={shortid.generate()}>
        <td />
        <td />
        <td>{worksite.title}</td>
        <td>{commify(fix(worksite.sumDay))}</td>
        <td>{commify(fix(worksite.sumNight))}</td>
        <td>{commify(fix(worksite.total))}</td>
      </tr>
    ));
  }

  render() {
    const { data, t } = this.props;
    if (!data) return '';
    return (
      <Card>
        <CardBody>
          <div className="card__title">
            <h5 className="bold-text">{t('calcs.worklistfull')}</h5>
            <h5 style={{ color: 'coral' }}>
              {t('workerstable.sum')}: <strong>{commify(fix(data.totalSum))}</strong>
            </h5>
          </div>
          <Table className="izpis-table">
            <thead>
              <tr>
                <td>{t('form.company')}</td>
                <td>{t('board.ow.orderer')}</td>
                <td>{t('board.filters.worksite')}</td>
                <td>{t('workerstable.dayHours')}</td>
                <td>{t('workerstable.nightHours')}</td>
                <td>{t('workerstable.sum')}</td>
              </tr>
            </thead>
            <tbody>{this.renderTableBody()}</tbody>
          </Table>
        </CardBody>
      </Card>
    );
  }
}

WorklistFull.propTypes = {
  data: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
};

export default translate('common')(WorklistFull);
