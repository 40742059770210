import React from 'react';
import PropTypes from 'prop-types';
import { Button, Modal } from 'reactstrap';

import classNames from 'classnames';
import AddIcon from 'mdi-react/CarSideIcon';
import EditIcon from 'mdi-react/CarIcon';

import { API_LINK } from 'constants/config';

import VozilaIzpisiForm from './VozilaIzpisiForm';
import { POST } from '../../../../helpers/agent';

const API_VEHICLE_EDIT_KM = '/v/updateKM';

class VozilaIzpisiModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { modal: false };

    this.toggle = this.toggle.bind(this);
    this.cancel = this.cancel.bind(this);

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleESC = this.handleESC.bind(this);
  }

  componentDidMount() {
    document.addEventListener('keydown', this.handleESC, false);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleESC, false);
  }

  handleESC(event) {
    if (event.keyCode === 27) {
      this.setState({ modal: false });
      this.cancel(null);
    }
  }

  toggle() {
    this.setState({
      modal: !this.state.modal,
    });
  }

  cancel(data) {
    this.setState({
      modal: false,
    });
    this.props.onClose(data);
  }

  // handleReasonsSubmit = values => {
  //   console.log('VozilaIzpisiModal -> handleReasonsSubmit -> values', values);
  // };

  async handleSubmit(values, reasons) {
    try {
      const { vehicleId, type, workerId, editData } = this.props;

      const payload = {
        ...values,
        reasons,
        type,
      };

      if (vehicleId) {
        payload.vehicleId = vehicleId;
        payload.isNew = !!vehicleId;
      }

      if (workerId) {
        payload.workerId = workerId;
        payload.isNew = !!workerId;
      }

      if (!payload.isNew) {
        payload.oldVehicleId = editData.vehicleId.value;
      }

      const successMessage = 'Vnos uspešno posodobljen!';
      const errorMessage = 'Vnos neuspešno posodobljen!';
      const body = JSON.stringify(payload);
      const data = await POST(API_LINK + API_VEHICLE_EDIT_KM, body);
      if (data.success) {
        this.cancel({
          title: 'Posodobitev vnosa',
          message: successMessage,
          success: true,
        });
      } else {
        this.cancel({
          title: 'Posodobitev vnosa',
          message: errorMessage,
          success: false,
        });
      }
    } catch (error) {
      // this.cancel({ title: 'Posodobitev kilometrov', message: errorMessage, success: false });
      // console.log(error);
      this.cancel(null);
    }
  }

  async handleDelete(values) {
    try {
      const { editData } = this.props;
      console.log('TCL: VozilaIzpisiModal -> handleDelete -> values', values);
      const body = JSON.stringify({
        deleted: true,
        logId: values._id,
        vehicleId: editData.vehicleId,
      });
      const data = await POST(API_LINK + API_VEHICLE_EDIT_KM, body);
      const successMessage = 'Vnos uspešno izbrisan!';
      const errorMessage = 'Vnos neuspešno izbrisan!';
      if (data.success) {
        this.cancel({
          title: 'Izbris vnosa',
          message: successMessage,
          success: true,
        });
      } else {
        this.cancel({
          title: 'Izbris vnosa',
          message: errorMessage,
          success: false,
        });
      }
    } catch (error) {
      this.cancel(null);
    }
  }

  render() {
    const {
      icon,
      btn,
      title,
      colored,
      header,
      hasBtn,
      show,
      editData,
      workers,
      vehicleId,
      vehicles,
      type,
    } = this.props;
    let Icon;

    switch (icon) {
      case 'add':
        Icon = <AddIcon />;
        break;
      case 'edit':
        Icon = <EditIcon />;
        break;
      default:
        break;
    }
    const modalClass = classNames({
      'modal-dialog--colored': colored,
      'modal-dialog--header': header,
    });

    return (
      <div style={{ width: '100%' }}>
        {hasBtn ? (
          <Button className="icon large-button" color="primary" onClick={this.toggle}>
            <p>
              {icon === 'add' ? <AddIcon /> : <EditIcon />}
              {btn}
            </p>
          </Button>
        ) : null}

        <Modal
          isOpen={this.state.modal || show}
          toggle={this.toggle}
          className={`modal-dialog--primary wide ${modalClass}`}
        >
          <div className="modal__header">
            <button className="lnr lnr-cross modal__close-btn" onClick={this.toggle} />
            {header ? '' : Icon}
            <h4 className="bold-text  modal__title">{title}</h4>
          </div>
          <div className="modal__body">
            <div className="theme-light">
              {editData ? (
                <VozilaIzpisiForm
                  onSubmit={this.handleSubmit}
                  //handleReasonsSubmit={this.handleReasonsSubmit}
                  handleCancel={() => this.cancel(null)}
                  handleDelete={this.handleDelete}
                  initialValues={editData}
                  editData={editData}
                  workers={workers}
                  vehicles={vehicles}
                  type={type}
                />
              ) : (
                <VozilaIzpisiForm
                  onSubmit={this.handleSubmit}
                  //handleReasonsSubmit={this.handleReasonsSubmit}
                  handleCancel={() => this.cancel(null)}
                  handleDelete={this.handleDelete}
                  workers={workers}
                  vehicleId={vehicleId}
                  vehicles={vehicles}
                  type={type}
                />
              )}
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

VozilaIzpisiModal.propTypes = {
  title: PropTypes.string,
  icon: PropTypes.string.isRequired,
  colored: PropTypes.bool,
  header: PropTypes.bool,
  btn: PropTypes.string.isRequired,
  hasBtn: PropTypes.bool.isRequired,
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  editData: PropTypes.object,
  workers: PropTypes.arrayOf(PropTypes.object).isRequired,
  vehicles: PropTypes.arrayOf(PropTypes.object).isRequired,
  type: PropTypes.string.isRequired,
  vehicleId: PropTypes.string,
  workerId: PropTypes.string,
};

VozilaIzpisiModal.defaultProps = {
  title: '',
  colored: false,
  header: false,
  editData: null,
  vehicleId: null,
  workerId: null,
};

export default VozilaIzpisiModal;
