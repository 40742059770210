import React, { Component } from 'react';
import { Row, Col, Container, Card, CardBody, ButtonToolbar, Button } from 'reactstrap';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import ReactToPrint from 'react-to-print';
import ReactGA from 'react-ga';
import Picker from 'react-month-picker';
import 'react-month-picker/css/month-picker.css';
import ReactLoading from 'react-loading';
import shortid from 'shortid';
import SelectDropdown from 'shared/components/SelectDropdown';
import SelectDropdown3 from 'shared/components/SelectDropdown3';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';
// import employeesApi from 'api/employees';
import workhoursApi from 'api/workhours';
// import companiesApi from 'api/companies';
import { getItem, setItem } from 'helpers/cookies';

import Worklist from './components/Worklist';
import WorkersTable from './components/WorkersTable';
import WorklistFull from './components/WorklistFull';
import BrankaTable from './components/BrankaTable';
import { PICKER_DATE_IZPIS, TIMEZONE } from '../../../constants/config';
import OWTable from './components/OWTable';
import SingleOWModal from './components/modal/SingleOWModal';
import PayAnaTable from './components/PayAnaTable';
import BrankaSheet from './components/sheets/BrankaSheet';
import OWSheet from './components/sheets/OWSheet';
import WorkersSheet from './components/sheets/WorkersSheet';
import WorklistSheet from './components/sheets/WorklistSheet';
import WorklistFullSheet from './components/sheets/WorklistFullSheet';
import PayAnaSheet from './components/sheets/PayAnaSheet';
import { EmployeesProps, CompaniesProps, AppStatusProps } from '../../../shared/prop-types/ReducerProps';
import Legend from '../../Boards/components/Legend';

const options = t => [
  {
    value: 'workers',
    label: t('calcs.workers'),
  },
  {
    value: 'orderers',
    label: t('calcs.orderers'),
  },
  {
    value: 'worklistfull',
    label: t('calcs.worklistfull'),
  },
  {
    value: 'branka',
    label: t('calcs.branka'),
  },
  {
    value: 'owpairs',
    label: t('calcs.owpairs'),
  },
  {
    value: 'analysis',
    label: t('calcs.analysis'),
  },
];

const pickerLang = {
  months: ['Jan', 'Feb', 'Mar', 'Apr', 'Maj', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dec'],
  from: 'From',
  to: 'To',
};

class Izpis extends Component {
  static propTypes = {
    workersState: EmployeesProps.isRequired,
    companiesState: CompaniesProps.isRequired,
    appStatus: AppStatusProps.isRequired,
    t: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    const storedDate = getItem(PICKER_DATE_IZPIS);
    let month =
      moment()
        .tz(TIMEZONE)
        .month() + 1;
    let year = moment()
      .tz(TIMEZONE)
      .year();
    if (storedDate) {
      month = Number(storedDate.split('/')[0]);
      year = Number(storedDate.split('/')[1]);
    }
    this.state = {
      monthPickerValue: {
        year,
        month,
      },
      selectedType: null,
      /* selectedOrderer: null,
      selectedEmployee: null, */
      everythingLoaded: false,
      allYears: false,
      allMonths: false,
      worklistLoaded: false,
      workersLoaded: false,
      fullWorklistLoaded: false,
      brankalistLoaded: false,
      owpairlistLoaded: false,
      paycheckListLoaded: false,
      workers: [],
      workersAll: [],
      showLoading: false,
      selectedCompany: null,
      selectedCompanyName: '',
      showTypeChangeDropdown: false,
      currentlySelectedType: null,
      showOWModal: false,
      owModalData: {
        orderer: {
          id: '',
          title: '',
        },
        worksite: {
          id: '',
          title: '',
        },
        resetingDropdown: false,
      },
      worklistSheetData: null,

      /* fullworklist: [],
      brankalist: [], */
    };
    this.handleTypeChange = this.handleTypeChange.bind(this);
    this.handleWorklistSubmit = this.handleWorklistSubmit.bind(this);
    this.handleEmployeesSubmit = this.handleEmployeesSubmit.bind(this);
    this.handleCompaniesSubmit = this.handleCompaniesSubmit.bind(this);
    this.monthPickerRef = null;
    this.onMonthPickerClick = this.onMonthPickerClick.bind(this);
    this.handleAllYearsClick = this.handleAllYearsClick.bind(this);
    this.handleAllMonthsClick = this.handleAllMonthsClick.bind(this);
    this.handleMonthChange = this.handleMonthChange.bind(this);
    this.handleAMonthDissmis = this.handleAMonthDissmis.bind(this);
    this.handleOWSelect = this.handleOWSelect.bind(this);
    this.handleOWModalClose = this.handleOWModalClose.bind(this);
  }

  componentDidMount() {
    const { appStatus } = this.props;
    if (appStatus.requiredDataLoaded) {
      console.log('URE_IZPISI -> Data loaded.. continue...');
      this.fetchData();
    }
  }

  componentDidUpdate(prevProps) {
    const { appStatus } = this.props;
    if (appStatus.requiredDataLoaded && !prevProps.appStatus.requiredDataLoaded) {
      console.log('URE_IZPISI -> Data finished loading.. continue...');
      this.fetchData();
    }

    if (!appStatus.appUpdating && prevProps.appStatus.appUpdating) {
      console.log('URE_IZPISI -> Data finished UPDATING.. refresh table...');
      this.fetchData();
    }
  }

  onMonthPickerClick() {
    this.monthPickerRef.show();
  }

  handleTypeChange(event) {
    if (event.selected_dropdown) {
      const selectedType = event.selected_dropdown;
      ReactGA.event({
        category: 'Delovne Ure - Izpis',
        action: `Filter tip izpisa: ${selectedType.label}`,
      });
      this.setState({
        selectedType,
        selectedCompany: null,
        worklistLoaded: true,
        workersLoaded: false,
        fullWorklistLoaded: false,
        brankalistLoaded: false,
        owpairlistLoaded: false,
        paycheckListLoaded: false,
        showLoading: false,
        resetingDropdown: true,
      });
      setTimeout(() => {
        const { monthPickerValue } = this.state;

        this.setState({
          worklistLoaded: false,
          workersLoaded: false,
          fullWorklistLoaded: false,
          brankalistLoaded: false,
          owpairlistLoaded: false,
          paycheckListLoaded: false,
          showLoading: false,
          resetingDropdown: false,
          currentlySelectedType: selectedType.value,
        });

        if (selectedType.value === 'workers') {
          this.setState({ showTypeChangeDropdown: true });
          this.workersSearch(monthPickerValue);
        } else if (selectedType.value === 'orderers') {
          // console.log('delam worklist search');
          this.setState({ showTypeChangeDropdown: true });
          this.worklistSearch(monthPickerValue);
        } else if (selectedType.value === 'branka') {
          this.setState({ showTypeChangeDropdown: true });
          // console.log('delam brankaListSearch');
          this.brankaListSearch(monthPickerValue);
        } else if (selectedType.value === 'owpairs') {
          this.setState({ showTypeChangeDropdown: true });
          this.owPairsListSearch(monthPickerValue);
        } else if (selectedType.value === 'analysis') {
          this.setState({ showTypeChangeDropdown: true });
          this.paycheckListSearch(monthPickerValue);
        } else {
          this.setState({ showTypeChangeDropdown: true });
          this.worklistFullSearch(monthPickerValue);
        }
      }, 100);
    }
  }

  handleMonthChange(year, month) {
    setItem(PICKER_DATE_IZPIS, `${month}/${year}`);
    this.setState({
      monthPickerValue: { year, month },
      allYears: false,
      allMonths: false,
    });

    setTimeout(() => {
      const {
        worklistLoaded,
        workersLoaded,
        fullWorklistLoaded,
        brankalistLoaded,
        monthPickerValue,
        owpairlistLoaded,
        paycheckListLoaded,
      } = this.state;
      if (
        !worklistLoaded &&
        !workersLoaded &&
        !fullWorklistLoaded &&
        !brankalistLoaded &&
        !owpairlistLoaded &&
        !paycheckListLoaded
      ) {
        return;
      }
      this.setState({
        worklistLoaded: false,
        workersLoaded: false,
        fullWorklistLoaded: false,
        brankalistLoaded: false,
        owpairlistLoaded: false,
        paycheckListLoaded: false,
        showLoading: true,
      });
      if (worklistLoaded) {
        this.worklistSearch(monthPickerValue);
      } else if (workersLoaded) {
        this.workersSearch(monthPickerValue);
      } else if (fullWorklistLoaded) {
        this.worklistFullSearch(monthPickerValue);
      } else if (brankalistLoaded) {
        this.brankaListSearch(monthPickerValue);
      } else if (owpairlistLoaded) {
        this.owPairsListSearch(monthPickerValue);
      } else if (paycheckListLoaded) {
        this.paycheckListSearch(monthPickerValue);
      }
    }, 500);
  }

  handleAMonthDissmis(year, month) {
    if (year && month) {
      setItem(PICKER_DATE_IZPIS, `${month}/${year}`);
      this.setState({
        monthPickerValue: { year, month },
        allYears: false,
        allMonths: false,
      });
    }
  }

  handleWorklistSubmit(event) {
    if (event.selected_dropdown_2) {
      const { monthPickerValue } = this.state;
      this.worklistSearch(monthPickerValue);
    }
  }

  async worklistSearch(date) {
    const { allYears, allMonths, selectedCompany } = this.state;
    if (!selectedCompany) return;
    const year = allYears ? 0 : date.year;
    const month = allMonths ? 0 : date.month;
    this.setState({
      showLoading: true,
    });
    const response = await workhoursApi.calculateWorklist({
      year,
      month,
      selectedCompany,
    });
    this.setState(response);
    this.setState({
      worklistLoaded: true,
      workersLoaded: false,
      fullWorklistLoaded: false,
      brankalistLoaded: false,
      owpairlistLoaded: false,
      paycheckListLoaded: false,
      showLoading: false,
    });
  }

  async worklistFullSearch(date) {
    const { allYears, allMonths, selectedCompany } = this.state;
    if (!selectedCompany) return;
    const year = allYears ? 0 : date.year;
    const month = allMonths ? 0 : date.month;
    this.setState({
      showLoading: true,
    });
    const response = await workhoursApi.calculateFullWorklist({
      year,
      month,
      selectedCompany,
    });
    this.setState(response);
    this.setState({
      worklistLoaded: false,
      workersLoaded: false,
      fullWorklistLoaded: true,
      brankalistLoaded: false,
      owpairlistLoaded: false,
      paycheckListLoaded: false,
      showLoading: false,
    });
  }

  async brankaListSearch(date) {
    const { allYears, allMonths, selectedCompany } = this.state;
    if (!selectedCompany) return;
    const year = allYears ? 0 : date.year;
    const month = allMonths ? 0 : date.month;
    this.setState({ showLoading: true });
    const response = await workhoursApi.calculateBrankaList({
      year,
      month,
      selectedCompany,
    });
    this.setState(response);
    this.setState({
      worklistLoaded: false,
      workersLoaded: false,
      fullWorklistLoaded: false,
      brankalistLoaded: true,
      owpairlistLoaded: false,
      paycheckListLoaded: false,
      showLoading: false,
    });
  }

  async owPairsListSearch(date) {
    const { allYears, allMonths, selectedCompany } = this.state;
    if (!selectedCompany) return;
    const year = allYears ? 0 : date.year;
    const month = allMonths ? 0 : date.month;
    this.setState({ showLoading: true });
    const response = await workhoursApi.calculateOWList({
      year,
      month,
      selectedCompany,
    });
    this.setState(response);
    this.setState({
      worklistLoaded: false,
      workersLoaded: false,
      fullWorklistLoaded: false,
      brankalistLoaded: false,
      owpairlistLoaded: true,
      paycheckListLoaded: false,
      showLoading: false,
    });
  }

  async paycheckListSearch(date) {
    const { allYears, allMonths, selectedCompany } = this.state;
    if (!selectedCompany) return;
    const year = allYears ? 0 : date.year;
    const month = allMonths ? 0 : date.month;
    this.setState({ showLoading: true });
    const response = await workhoursApi.calculatePaycheckList({
      year,
      month,
      selectedCompany,
    });
    this.setState(response);
    this.setState({
      worklistLoaded: false,
      workersLoaded: false,
      fullWorklistLoaded: false,
      brankalistLoaded: false,
      owpairlistLoaded: false,
      paycheckListLoaded: true,
      showLoading: false,
    });
  }

  async workersSearch(date, workerId) {
    const { allYears, allMonths } = this.state;
    const year = allYears ? 0 : date.year;
    const month = allMonths ? 0 : date.month;
    this.setState({
      showLoading: true,
    });
    const response = await workhoursApi.calculateWorkerHours({ year, month });
    this.setState(response);
    this.setState({
      workersLoaded: true,
      worklistLoaded: false,
      fullWorklistLoaded: false,
      brankalistLoaded: false,
      owpairlistLoaded: false,
      paycheckListLoaded: false,
      showLoading: false,
    });
  }

  handleEmployeesSubmit(event) {
    console.log('TCL: Izpis -> handleEmployeesSubmit -> event', event);
    if (event.selected_dropdown_2) {
      const selectedEmployee = event.selected_dropdown_2;

      console.log('selectedEmployee', selectedEmployee);
      const filtered = [];
      console.log('TCL: Izpis -> handleEmployeesSubmit -> this.state.workersAll', this.state.workersAll);
      ReactGA.event({
        category: 'Delovne Ure - Izpis',
        action: `Filter Delavcev: ${selectedEmployee.label}`,
      });
      this.state.workersAll.workers.forEach(worker => {
        if (worker._id === selectedEmployee.value) {
          filtered.push(worker);
        }
      });
      if (filtered.length > 0) {
        this.setState({
          workers: { totalSum: filtered[0].sum.totalSum, workers: filtered },
        });
      } else {
        this.setState({ workers: { totalSum: 0, workers: [] } });
      }
    } else {
      this.setState({ workers: this.state.workersAll });
    }
  }

  handleCompaniesSubmit(event) {
    if (event.selected_dropdown_2) {
      this.setState({
        selectedCompany: event.selected_dropdown_2.value,
        selectedCompanyName: event.selected_dropdown_2.label,
        brankalistLoaded: false,
        owpairlistLoaded: false,
        worklistLoaded: false,
        fullWorklistLoaded: false,
        paycheckListLoaded: false,
        showLoading: true,
      });
      ReactGA.event({
        category: 'Delovne Ure - Izpis',
        action: `Filter Podjetij: ${event.selected_dropdown_2.label}`,
      });
      setTimeout(() => {
        const { monthPickerValue, currentlySelectedType } = this.state;
        if (currentlySelectedType === 'branka') {
          this.brankaListSearch(monthPickerValue);
        } else if (currentlySelectedType === 'owpairs') {
          this.owPairsListSearch(monthPickerValue);
        } else if (currentlySelectedType === 'orderers') {
          this.worklistSearch(monthPickerValue);
        } else if (currentlySelectedType === 'worklistfull') {
          this.worklistFullSearch(monthPickerValue);
        } else if (currentlySelectedType === 'analysis') {
          this.paycheckListSearch(monthPickerValue);
        }
      }, 500);
    }
  }

  handleAllYearsClick() {
    this.setState({
      allYears: !this.state.allYears,
    });
  }

  handleAllMonthsClick() {
    this.setState({
      allMonths: !this.state.allMonths,
    });
  }

  async fetchData() {
    const {
      workersState: { employeesNames },
      companiesState: { companiesTitles },
      t,
    } = this.props;
    const filteredComs = [];
    filteredComs.push({
      value: 'all',
      label: t('calcs.allCompanies'),
      color: 'black',
    });
    companiesTitles.forEach(com => {
      filteredComs.push(com);
    });

    this.setState({
      employeeTitles: employeesNames,
      companies: filteredComs,
      everythingLoaded: true,
    });
  }

  makeText(m) {
    const { allYears, allMonths } = this.state;

    const months = allMonths ? 'Vsi mesci' : pickerLang.months[m.month - 1];
    const template = allYears ? 'Vsi mesci in leta' : `${months}. ${m.year}`;
    if (m && m.year && m.month) return template;
    return '?';
  }

  handleOWSelect({ ordererId, worksiteId, ordererTitle, worksiteTitle }) {
    this.setState({
      showOWModal: true,
      owModalData: {
        orderer: {
          id: ordererId,
          title: ordererTitle,
        },
        worksite: {
          id: worksiteId,
          title: worksiteTitle,
        },
      },
    });
  }

  handleOWModalClose() {
    this.setState({ showOWModal: false });
  }

  renderSearchDropdown() {
    const { employeeTitles, selectedType, companies } = this.state;
    const { t } = this.props;
    if (selectedType) {
      if (selectedType.value === 'workers') {
        return (
          <SelectDropdown3
            title={t('form.chooseWorker')}
            items={employeeTitles}
            onSubmit={this.handleEmployeesSubmit}
          />
        );
      }
      if (
        selectedType.value === 'branka' ||
        selectedType.value === 'owpairs' ||
        selectedType.value === 'orderers' ||
        selectedType.value === 'analysis' ||
        selectedType.value === 'worklistfull'
      ) {
        const companiesFull = [];
        companies.forEach(com => {
          if (selectedType.value !== 'analysis') {
            companiesFull.push(com);
          } else if (com.value !== 'all') {
            companiesFull.push(com);
          }
        });

        return (
          <SelectDropdown3
            title={`${t('calcs.chooseCompany')}:`}
            items={companiesFull}
            onSubmit={this.handleCompaniesSubmit}
          />
        );
      }
    }
    return <div>Izberi tip</div>;
  }

  handleWorklistDataChange = worklistSheetData => {
    this.setState({ worklistSheetData });
  };

  render() {
    const {
      monthPickerValue,
      worklist,
      worklistLoaded,
      workersLoaded,
      workers,
      fullworklist,
      fullWorklistLoaded,
      brankalist,
      brankalistLoaded,
      owlist,
      owpairlistLoaded,
      paycheckListLoaded,
      paycheckList,
      showLoading,
      showTypeChangeDropdown,
      showOWModal,
      owModalData,
      resetingDropdown,
      selectedCompanyName,
      everythingLoaded,
      selectedCompany,
    } = this.state;

    const { t } = this.props;

    let BrankaElts = null;
    let WorkersElts = null;
    let WorklistElts = null;
    let WorklistFullElts = null;
    let PayAnaElts = null;

    if (brankalist && brankalist.length && brankalistLoaded) {
      BrankaElts = [
        <BrankaSheet
          key={shortid.generate()}
          data={brankalist}
          btn={t('form.exportExcel')}
          month={monthPickerValue.month}
          year={monthPickerValue.year}
        />,
        <ReactToPrint
          trigger={() => (
            <Button color="warning" size="sm">
              {t('calcs.print')}
            </Button>
          )}
          content={() => this.componentBTRef}
          // eslint-disable-next-line max-len
          pageStyle={
            '@page { size: auto;  margin: 2mm; } @media print { body { -webkit-print-color-adjust: exact !important; }'
          }
        />,
        <BrankaTable
          key={shortid.generate()}
          data={brankalist}
          month={monthPickerValue.month}
          year={monthPickerValue.year}
          ref={el => (this.componentBTRef = el)}
        />,
      ];
    }

    if (workersLoaded) {
      WorkersElts = [
        <WorkersSheet key={shortid.generate()} data={workers} btn={t('form.exportExcel')} />,
        <WorkersTable key={shortid.generate()} title={t('calcs.workers')} workers={workers} />,
      ];
    }

    if (worklist && worklist.worklist.length && worklistLoaded) {
      WorklistElts = [
        <WorklistSheet
          //key={shortid.generate()}
          data={this.state.worklistSheetData || worklist}
          btn={t('form.exportExcel')}
        />,
        <Worklist
          //key={shortid.generate()}
          data={worklist}
          month={Number(monthPickerValue.month)}
          year={Number(monthPickerValue.year)}
          selectedCompany={selectedCompany}
          onWorklistDataChange={this.handleWorklistDataChange}
        />,
      ];
    }

    if (fullworklist && fullworklist.worklist.length && fullWorklistLoaded) {
      WorklistFullElts = [
        <WorklistFullSheet key={shortid.generate()} data={fullworklist} btn={t('form.exportExcel')} />,
        <WorklistFull key={shortid.generate()} data={fullworklist} />,
      ];
    }

    /* eslint-disable */
    if (paycheckList && paycheckListLoaded) {
      PayAnaElts = [
        <PayAnaSheet key={shortid.generate()} data={paycheckList} btn={t('form.exportExcel')} />,
        <ReactToPrint
          trigger={() => (
            <Button color="warning" size="sm" style={{ float: 'left' }}>
              {t('calcs.print')}
            </Button>
          )}
          content={() => this.componentRef}
          pageStyle={`@page { size: auto;  margin: 0mm; } @media print { body { -webkit-print-color-adjust: exact !important; }`}
        />,
        <PayAnaTable
          key={shortid.generate()}
          data={paycheckList}
          title={selectedCompanyName}
          month={monthPickerValue.month}
          year={monthPickerValue.year}
          ref={el => (this.componentRef = el)}
        />,
      ];
    }
    /* eslint-enable */
    return (
      <Container>
        <Row>
          <Col md="2">
            <h3 className="page-title">{t('calcs.title')}</h3>
          </Col>
          {everythingLoaded ? (
            <Col md={showTypeChangeDropdown ? '3' : { offset: 1, size: 3 }}>
              <Card>
                <CardBody>
                  <SelectDropdown
                    onSubmit={this.handleTypeChange}
                    items={options(t)}
                    title={`${t('calcs.calcType')}:`}
                  />
                </CardBody>
              </Card>
            </Col>
          ) : (
            <Col md={6}>
              <ReactLoading type="bubbles" color="gray" height={128} width={128} className="react-loader" />
            </Col>
          )}

          {showTypeChangeDropdown && (
            <Col md="3">
              <Card>
                <CardBody>{!resetingDropdown && this.renderSearchDropdown()}</CardBody>
              </Card>
            </Col>
          )}
          {everythingLoaded && (
            <Col md="4">
              <Card>
                <CardBody>
                  <div className="date-picker">
                    <div className="form__form-group-label">{t('calcs.chooseMonth')}:</div>
                    <Picker
                      ref={ref => (this.monthPickerRef = ref)}
                      years={[2008, 2009, 2010, 2011, 2012, 2014, 2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030]}
                      value={monthPickerValue}
                      lang={pickerLang.months}
                      onChange={this.handleMonthChange}
                      onDismiss={this.handleAMonthDissmis}
                    >
                      <div
                        role="button"
                        tabIndex={0}
                        onKeyDown={this.onMonthPickerClick}
                        onClick={this.onMonthPickerClick}
                      >
                        {this.makeText(monthPickerValue)}
                      </div>
                    </Picker>
                  </div>
                </CardBody>
              </Card>
            </Col>
          )}
        </Row>
        <Row>
          <Col lg={6}>
            <Legend title={t('board.legend')} lettercodes />
          </Col>
        </Row>
        <Row>
          {worklist && worklist.worklist.length && worklistLoaded && <Col md="12">{WorklistElts}</Col>}
          {fullworklist && fullworklist.worklist.length && fullWorklistLoaded && <Col md="12">{WorklistFullElts}</Col>}
          {brankalist && brankalist.length && brankalistLoaded && <Col md="12">{BrankaElts}</Col>}
          {owlist && owpairlistLoaded && (
            <React.Fragment>
              <OWSheet
                key={shortid.generate()}
                data={owlist}
                btn={t('form.exportExcel')}
                month={monthPickerValue.month}
                year={monthPickerValue.year}
              />
              <ReactToPrint
                trigger={() => (
                  <Button color="warning" size="sm">
                    {t('calcs.print')}
                  </Button>
                )}
                content={() => this.componentOWRef}
                pageStyle={
                  // eslint-disable-next-line max-len
                  '@page { size: auto;  margin: 2mm; } .switchToolTip { display: none !important; } .night-switch { display: none !important; } @media print { body { -webkit-print-color-adjust: exact !important; }'
                }
              />
              <OWTable
                key={shortid.generate()}
                data={owlist}
                month={monthPickerValue.month}
                year={monthPickerValue.year}
                onSelectOw={this.handleOWSelect}
                ref={el => (this.componentOWRef = el)}
              />
            </React.Fragment>
          )}
          {paycheckList && paycheckListLoaded && <Col md="12">{PayAnaElts}</Col>}
          {workersLoaded && <Col md="12">{WorkersElts}</Col>}
          {showLoading && <ReactLoading type="bars" color="gray" height={128} width={128} className="react-loader" />}
          {owlist && owpairlistLoaded && (
            <ButtonToolbar>
              <SingleOWModal
                btn=""
                icon="edit"
                title={`${owModalData.orderer.title}: ${owModalData.worksite.title}`}
                hasBtn={false}
                show={showOWModal}
                onClose={this.handleOWModalClose}
                ordererId={owModalData.orderer.id}
                worksiteId={owModalData.worksite.id}
                month={monthPickerValue.month}
                year={monthPickerValue.year}
                selectedCompany={selectedCompany}
                selectedCompanyName={selectedCompanyName}
              />
            </ButtonToolbar>
          )}
        </Row>
      </Container>
    );
  }
}

const wr = connect(state => ({
  workersState: state.employees,
  companiesState: state.companies,
  appStatus: state.appStatus,
}))(Izpis);

export default translate('common')(wr);
