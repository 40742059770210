const validate = values => {
  const errors = {};
  if (!values.providerId) {
    errors.providerId = 'Dieses Feld muss ausgefüllt werden!';
  }
  if (!values.address) {
    errors.address = 'Dieses Feld muss ausgefüllt werden!';
  }
  if (!values.zip) {
    errors.zip = 'Dieses Feld muss ausgefüllt werden!';
  }
  if (!values.city) {
    errors.city = 'Dieses Feld muss ausgefüllt werden!';
  }
  if (!values.countryId) {
    errors.countryId = 'Dieses Feld muss ausgefüllt werden!';
  }

  return errors;
};

export default validate;
