import React from 'react';
import PropTypes from 'prop-types';
import { Button, Modal } from 'reactstrap';

import classNames from 'classnames';
import EditIcon from 'mdi-react/EditIcon';

import { POST } from 'helpers/agent';
import { API_LINK } from 'constants/config';
import QuickDropdownForm from './QuickDropdownForm';

const API_VEHICLE_WORKSITE = '/v/worksiteVehicle';

class QuickDropdownModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { modal: false };

    this.toggle = this.toggle.bind(this);
    this.cancel = this.cancel.bind(this);

    this.handleSubmit = this.handleSubmit.bind(this);

    this.handleESC = this.handleESC.bind(this);
  }

  componentDidMount() {
    document.addEventListener('keydown', this.handleESC, false);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleESC, false);
  }

  handleESC(event) {
    if (event.keyCode === 27) {
      this.setState({ modal: false });
      this.cancel(null);
    }
  }

  toggle() {
    this.setState({
      modal: !this.state.modal,
    });
  }

  cancel(data) {
    this.setState({
      modal: false,
    });
    this.props.onClose(data);
  }

  async handleSubmit(values) {
    try {
      console.log('TCL: QuickDropdownModal -> handleSubmit -> values', values);
      const { vehicleId } = this.props;

      const successMessage = 'Vozilo uspešno dodano na gradbišče!';
      const errorMessage = 'Vozilo neuspešno dodano na gradbišče!';
      const body = JSON.stringify({
        vehicleId,
        worksiteId: values.worksiteId.value,
      });

      const data = await POST(API_LINK + API_VEHICLE_WORKSITE, body);
      if (data.success) {
        this.cancel({
          title: 'Posodobitev stanja',
          message: successMessage,
          success: true,
        });
      } else {
        this.cancel({
          title: 'Posodobitev stanja',
          message: errorMessage,
          success: false,
        });
      }
    } catch (error) {
      // console.log(error);
      this.cancel(null);
    }
  }

  render() {
    const { icon, btn, title, colored, header, hasBtn, show, options } = this.props;
    let Icon;

    if (icon) Icon = <EditIcon />;

    const modalClass = classNames({
      'modal-dialog--colored': colored,
      'modal-dialog--header': header,
    });

    return (
      <div style={{ width: '100%' }}>
        {hasBtn ? (
          <Button className="icon large-button" onClick={this.toggle}>
            <p>
              <EditIcon />
              {btn}
            </p>
          </Button>
        ) : null}

        <Modal isOpen={this.state.modal || show} toggle={this.toggle} className={`modal-dialog--primary ${modalClass}`}>
          <div className="modal__header">
            <button className="lnr lnr-cross modal__close-btn" onClick={this.toggle} />
            {header ? '' : Icon}
            <h4 className="bold-text  modal__title">{title}</h4>
          </div>
          <div className="modal__body">
            <div className="theme-light">
              <QuickDropdownForm
                onSubmit={this.handleSubmit}
                handleCancel={() => this.cancel(null)}
                options={options}
              />
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

QuickDropdownModal.propTypes = {
  title: PropTypes.string,
  icon: PropTypes.string.isRequired,
  colored: PropTypes.bool,
  header: PropTypes.bool,
  btn: PropTypes.string.isRequired,
  hasBtn: PropTypes.bool.isRequired,
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  vehicleId: PropTypes.string,
};

QuickDropdownModal.defaultProps = {
  title: '',
  colored: false,
  header: false,
  vehicleId: null,
};

export default QuickDropdownModal;
