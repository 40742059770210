import React from 'react';
import { Card, CardBody, Col, ButtonToolbar, Button } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import renderSelectField from '../../../../shared/components/form/Select';

const PrenocitveIzpisiFrom = ({ handleSubmit, handleCancel, accommodations, editData, pristine }) => (
  <Col md={12} lg={12}>
    <Card>
      <CardBody>
        <form className="form" onSubmit={handleSubmit}>
          <div className="form__form-group" style={{ display: 'none' }}>
            <span className="form__form-group-label">ID</span>
            <div className="form__form-group-field">
              <Field name="sleeperId" component="input" type="text" />
            </div>
          </div>

          <div className="form__form-group">
            <span className="form__form-group-label">Ime</span>
            <div className="form__form-group-field">
              <Field name="name" component="input" disabled type="text" />
            </div>
          </div>

          <div className="form__form-group">
            <span className="form__form-group-label">Prenočišče</span>
            <div className="form__form-group-field">
              <Field name="accommodationId" component={renderSelectField} options={accommodations} />
            </div>
          </div>

          <ButtonToolbar className="form__button-toolbar">
            <Button type="button" onClick={handleCancel}>
              Prekliči
            </Button>
            <Button color="primary" type="submit" style={pristine}>
              {editData ? 'Shrani spremembe' : 'Dodaj'}
            </Button>
          </ButtonToolbar>
        </form>
      </CardBody>
    </Card>
  </Col>
);

PrenocitveIzpisiFrom.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
  accommodations: PropTypes.arrayOf(PropTypes.object).isRequired,
  editData: PropTypes.object,
  pristine: PropTypes.bool.isRequired,
};

PrenocitveIzpisiFrom.defaultProps = {
  editData: null,
};

export default reduxForm({
  form: 'prenocitve_izpisi_form', // a unique identifier for this form
})(PrenocitveIzpisiFrom);
