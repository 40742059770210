import React from 'react';
import { Card, CardBody, Col, Table, Badge } from 'reactstrap';
import PropTypes from 'prop-types';
import { translate } from 'react-i18next';
import { translateVehType } from 'translations/dynamicTranslator';
// import Modal from '../../../shared/components/Modal';

const renderTable = (vehicles, onRowSelect, onDeassign, t) =>
  vehicles.map(vehicle => {
    let theBadge;
    if (vehicle.worksiteData) {
      theBadge = (
        <Badge style={{ backgroundColor: '#FF7E79', color: 'white' }}>
          {t('form.worksite')} - {vehicle.worksiteData.toUpperCase()}
        </Badge>
      );
    } else if (vehicle.driverData) {
      theBadge = (
        <Badge
          style={{
            backgroundColor: `${vehicle.driverData.driver.color}`,
            color: '#646777',
          }}
        >
          {`${vehicle.driverData.driver.surname.toUpperCase()} ${vehicle.driverData.driver.name} - ${
            vehicle.driverData.driver.company.split(' ')[0]
          }`}
        </Badge>
      );
    } else {
      theBadge = <Badge style={{ backgroundColor: '#79BD8F', color: 'white' }}>{t('form.home')}</Badge>;
    }
    return (
      <tr key={vehicle._id} id={vehicle._id} onClick={() => onRowSelect(vehicle._id)}>
        <td>{vehicle.licensePlate}</td>
        <td>{vehicle.brand}</td>
        <td>{vehicle.provider}</td>
        <td>{translateVehType(vehicle.type, localStorage.getItem('lang') || 'sl')}</td>
        <td>{theBadge}</td>
        {vehicle.driverData && !vehicle.worksiteData ? <td>{vehicle.driverData.date}</td> : <td>/</td>}

        {/* <td>
        <Modal
          color="warning"
          title="Pozor!"
          colored
          disabled={!vehicle.driverData}
          btn="Ne vozi"
          size="sm"
          message={`Ste prepričani da je vozilo z registrsko ${vehicle.licensePlate} že doma?`}
          onConfirm={() => onDeassign(vehicle._id, vehicle.licensePlate)}
        />
      </td> */}
      </tr>
    );
  });

const VozilaTable = ({ title, vehicles, onRowSelect, onDeassign, t }) => (
  <Col md={12} lg={12} xl={12}>
    <Card>
      <CardBody>
        <div className="card__title">
          <h5 className="bold-text">{title}</h5>
          {/* <h5 className="subhead">lol</h5> */}
        </div>
        <Table hover className="table--bordered" responsive>
          <thead>
            <tr>
              <th>{t('vehicles.license')}</th>
              <th>{t('vehicles.brand')}</th>
              <th>{t('vehicles.provider')}</th>
              <th>{t('vehicles.type')}</th>
              <th>{t('vehicles.driver')}</th>
              <th>{t('vehicles.drivesSince')}</th>
              {/* <th>&nbsp;</th> */}
            </tr>
          </thead>
          <tbody>{renderTable(vehicles, onRowSelect, onDeassign, t)}</tbody>
        </Table>
      </CardBody>
    </Card>
  </Col>
);

VozilaTable.propTypes = {
  title: PropTypes.string.isRequired,
  vehicles: PropTypes.arrayOf(PropTypes.object).isRequired,
  onRowSelect: PropTypes.func.isRequired,
  onDeassign: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default translate('common')(VozilaTable);
