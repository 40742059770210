import owsApi from 'api/ows';
import SEARCH_TYPES from 'constants/search-types';

export const SET_OWS = 'SET_OWS';
export const SET_OWS_LOADING = 'SET_OWS_LOADING';
export const SET_NOTE_ON_OW = 'SET_NOTE_ON_OW';
export const SET_FILTERED = 'SET_FILTERED';
export const GET_OWS_ERROR = 'GET_OWS_ERORR';
export const CLEAR_FILTERED = 'CLEAR_FILTERED';

export function setOwsLoading() {
  return {
    type: SET_OWS_LOADING,
  };
}

export function setOws(ows) {
  return {
    type: SET_OWS,
    payload: ows,
  };
}

function getOwsError(err) {
  return {
    type: GET_OWS_ERROR,
    payload: `There was an error while fetching OWs: ${err}`,
  };
}

export function getOws(date = new Date()) {
  return async dispatch => {
    try {
      dispatch(setOwsLoading());
      const { ows } = await owsApi.index(date);
      dispatch(setOws(ows));
    } catch (err) {
      dispatch(getOwsError(err));
    }
  };
}

function setFiltered(filtered) {
  return {
    type: SET_FILTERED,
    payload: filtered,
  };
}

export function clearFiltered() {
  return {
    type: CLEAR_FILTERED,
  };
}

function filterByWorksite(filter) {
  return (dispatch, getState) => {
    const {
      ows: { ows },
    } = getState();
    console.log('value', filter, ows);
    const filteredOws = ows.filter(ow => {
      const liveOrStaged = ow.live || ow.staged;
      const worksiteId = liveOrStaged.worksite ? liveOrStaged.worksite._id : null;
      return worksiteId === filter.value;
    });
    dispatch(setFiltered(filteredOws));
  };
}

function filterByEmployee(filter) {
  return (dispatch, getState) => {
    const {
      ows: { ows },
    } = getState();
    const filteredOws = ows.filter(ow => {
      const liveEmployees = ow.live ? ow.live.employees : [];
      const stagedEmployees = ow.staged ? ow.staged.employees : [];
      const employees = [...liveEmployees, ...stagedEmployees];
      for (let k = 0; k < employees.length; k += 1) {
        const employee = employees[k];
        if (employee._id === filter.value) {
          return true;
        }
      }
      return false;
    });
    dispatch(setFiltered(filteredOws));
  };
}

function filterByVehicle(filter) {
  return (dispatch, getState) => {
    const {
      ows: { ows },
    } = getState();
    const filteredOws = ows.filter(ow => {
      const liveEmployees = ow.live ? ow.live.employees : [];
      const stagedEmployees = ow.staged ? ow.staged.employees : [];
      const employees = [...liveEmployees, ...stagedEmployees];
      for (let k = 0; k < employees.length; k += 1) {
        const employee = employees[k];
        if (employee.vehicle && employee.vehicle._id === filter.value) {
          return true;
        }
      }
      return false;
    });
    dispatch(setFiltered(filteredOws));
  };
}

export function filterOws(filters) {
  return dispatch => {
    const { searchType, value } = filters;
    if (!value) {
      dispatch(clearFiltered());
    } else {
      switch (searchType) {
        case SEARCH_TYPES.WORKSITE:
          dispatch(filterByWorksite(value));
          break;
        case SEARCH_TYPES.EMPLOYEE:
          dispatch(filterByEmployee(value));
          break;
        case SEARCH_TYPES.VEHICLE:
          dispatch(filterByVehicle(value));
          break;
        default:
          dispatch(filterByWorksite(value));
          break;
      }
    }
  };
}

function setNoteOnOws(id, note) {
  return {
    type: SET_NOTE_ON_OW,
    payload: {
      id,
      note,
    },
  };
}

export function updateNote(id, note) {
  return async dispatch => {
    try {
      await owsApi.update(id, note);
      dispatch(setNoteOnOws(id, note));
    } catch (err) {
      console.log('There was an error', err);
    }
  };
}
