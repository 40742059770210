import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, ButtonToolbar, Badge } from 'reactstrap';
import ChevronDownIcon from 'mdi-react/ChevronDownIcon';
import shortid from 'shortid';

class Dropdown extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      options: props.options,
      selectedOption: props.defaultOption || props.options[0].label,
      //additionalOption: props.additionalOption || props.options[0].value,
      selectedColor: props.defaultColor || props.options[0].color,
    };
  }

  mapOptions = () => {
    const { options } = this.state;
    return options.map((o, i) => (
      // eslint-disable-next-line react/no-array-index-key
      <DropdownItem key={o.value + o.label + i} className="dd-companies" onClick={() => this.handleItemClick(o)}>
        <Badge style={{ backgroundColor: o.color, color: '#646777', fontSize: 'large' }}>{o.label}</Badge>
      </DropdownItem>
    ));
  };

  handleItemClick = item => {
    this.setState({ selectedOption: item.label, selectedColor: item.color });
    return this.props.onItemSelect(item.value, item.color, item.label);
  };

  render() {
    const { selectedOption, selectedColor } = this.state;
    return (
      <Fragment>
        <UncontrolledDropdown className="colored">
          <DropdownToggle className="icon icon--right" outline>
            <p>
              {selectedOption} <ChevronDownIcon />
            </p>
          </DropdownToggle>
          <DropdownMenu className="dropdown__menu inline__table">{this.mapOptions()}</DropdownMenu>
        </UncontrolledDropdown>
      </Fragment>
    );
  }
}

Dropdown.propTypes = {
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  onItemSelect: PropTypes.func.isRequired,
  color: PropTypes.string,
  defaultOption: PropTypes.string,
  //additionalOption: PropTypes.string,
  defaultColor: PropTypes.string,
};

Dropdown.defaultProps = {
  color: 'primary',
  defaultOption: null,
  //additionalOption:null,
  defaultColor: null,
};

export default Dropdown;
