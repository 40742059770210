import React, { Component, Fragment } from 'react';
import { Popover, PopoverHeader, PopoverBody, Button } from 'reactstrap';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';
import getRoles from 'constants/roles';
import assignmentsApi from 'api/assignments';
import translateProfession from 'translations/dynamicTranslator';
import AssignmentsFormModal from '../Modals/AssignmentsFormModal';

class PlaceholderTableRow extends Component {
  static propTypes = {
    placeholder: PropTypes.object.isRequired,
    profession: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      assignmentsPopoverShown: false,
      assignmentsFormModalShown: false,
      editableAssignment: null,
      ROLES: getRoles(),
    };
  }

  getEditableAssignment = async () => {
    const { placeholder } = this.props;

    const postData = { id: placeholder.assignmentId };
    const data = await assignmentsApi.getOneStaged(postData);

    return data;
  };

  toggleAssignmentsPopover = () => {
    this.setState({
      assignmentsPopoverShown: !this.state.assignmentsPopoverShown,
    });
  };

  showAssignmentsFormModal = async () => {
    const data = await this.getEditableAssignment();
    this.setState({
      assignmentsFormModalShown: true,
      editableAssignment: data,
    });
  };

  hideAssignmentsFormModal = () => {
    this.setState({
      assignmentsFormModalShown: false,
    });
  };

  render() {
    const { placeholder, profession, t } = this.props;
    const { assignmentsPopoverShown, assignmentsFormModalShown, editableAssignment, ROLES } = this.state;
    return (
      <Fragment>
        <tr className="red-tr" onClick={this.toggleAssignmentsPopover}>
          <td
            onClick={this.toggleAssignmentsPopover}
            id={`Placeholder${placeholder._id}`}
            onKeyDown={this.toggleAssignmentsPopover}
            role="presentation"
          >
            {translateProfession(profession.label, localStorage.getItem('lang') || 'sl')}
          </td>
          <td colSpan="4" />
        </tr>
        {ROLES.full && (
          <Popover
            placement="left"
            isOpen={assignmentsPopoverShown}
            target={`Placeholder${placeholder._id}`}
            toggle={this.toggleAssignmentsPopover}
          >
            <PopoverHeader>{t('board.ow.popover.assignment')}</PopoverHeader>
            <PopoverBody className="board-actions">
              <Button color="primary" className="mr-0 mb-2" onClick={this.showAssignmentsFormModal}>
                {t('board.ow.popover.viewAssignment')}
              </Button>
            </PopoverBody>
          </Popover>
        )}
        {assignmentsFormModalShown && (
          <AssignmentsFormModal
            icon="edit"
            title={t('board.ow.popover.editAssignment')}
            show={assignmentsFormModalShown}
            onClose={this.hideAssignmentsFormModal}
            editData={editableAssignment}
          />
        )}
      </Fragment>
    );
  }
}

export default translate('common')(PlaceholderTableRow);
