import React, { Fragment } from 'react';
import { Card, CardBody, Col, ButtonToolbar, Button, Nav, NavItem, NavLink, UncontrolledTooltip } from 'reactstrap';

import { DownloadIcon, CloseIcon, DeleteIcon, DeleteOutlineIcon, PlusIcon } from 'mdi-react';
import classnames from 'classnames';
import { Field, reduxForm } from 'redux-form';
import CalendarBlankIcon from 'mdi-react/CalendarBlankIcon';
import PropTypes from 'prop-types';
import slug from 'slug';
import CheckIcon from 'mdi-react/CheckIcon';
import { translate } from 'react-i18next';
// import { required } from 'redux-form-validators';
import { DOWNLOAD, BULK_DOWNLOAD } from 'helpers/agent';
import { API_LINK, CONFIG, VIBER_BOT_NAME } from 'constants/config';
import Alert from '../../../shared/components/Alert';
import renderSelectField from '../../../shared/components/form/Select';
import renderDatePickerField from '../../../shared/components/form/DatePicker';
// import Panel from '../../../../shared/components/Panel';
import Modal from '../../../shared/components/Modal';
import validate from './validate';
import renderTextField from '../../../shared/components/form/TextField';
import renderFileInputField from '../../../shared/components/form/FileInput';
import DocumentVersionsTable from '../../../shared/components/DocumentVersionsTable';
import Collapse from '../../../shared/components/Collapse';

const DNONE = {
  display: 'none',
};

const ML = {
  marginLeft: '30px',
};

const EU_PROVIDERS = ['ZZZS', 'Dovera'];

// eslint-disable-next-line prefer-destructuring
let inDev = CONFIG.inDev;

class DelavciForm2 extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      fieldsCount: props.initialFieldCount,
      insuranceFieldsCount: props.initialInsuranceFieldCount,
      companyFieldsCount: props.initialCompanyFieldCount,
      removedFieldIds: [],
      removedInsuranceFieldIds: [],
      removedCompanyFieldIds: [],
      activeTab: props.initialTab,
      showBasic: true,
      showDocs: false,
      showCompany: false,
      showNotes: false,
      showAlert: {},
      alertMsg: '',
      fileCounter: 0,
      nobulk: false,
      blockButtons: false,
    };

    this.addField = this.addField.bind(this);
    this.addInsuranceField = this.addInsuranceField.bind(this);
    this.addCompanyField = this.addCompanyField.bind(this);
    this.removeField = this.removeField.bind(this);
    this.removeInsuranceField = this.removeInsuranceField.bind(this);
    this.removeCompanyField = this.removeCompanyField.bind(this);
    this.hijackSubmit = this.hijackSubmit.bind(this);
    this.toggle = this.toggle.bind(this);
    this.renderTabsField = this.renderTabsField.bind(this);
    this.renderA1Fields = this.renderA1Fields.bind(this);
    this.renderInsuranceFields = this.renderInsuranceFields.bind(this);
    this.renderCompanyFields = this.renderCompanyFields.bind(this);
    this.downloadFile = this.downloadFile.bind(this);
    this.dismissAlert = this.dismissAlert.bind(this);
    this.handleFileInputChange = this.handleFileInputChange.bind(this);
    this.bulkDownload = this.bulkDownload.bind(this);
  }

  componentDidMount() {
    if (this.props.editData) {
      inDev = true;
    } else {
      inDev = false;
    }
  }

  UNSAFE_componentWillUpdate(nextProps, nextState) {
    if (this.state.showBasic && this.state.activeTab !== '1' && nextState.activeTab !== '1') {
      console.log('TOGGLAM');
      this.toggle(nextState.activeTab);
    }
  }

  toggle = tab => {
    // if (this.state.activeTab !== tab) {
    switch (tab) {
      case '2':
        this.setState({
          activeTab: tab,
          showBasic: false,
          showDocs: false,
          showCompany: true,
          showNotes: false,
        });
        break;
      case '3':
        this.setState({
          activeTab: tab,
          showBasic: false,
          showDocs: true,
          showCompany: false,
          showNotes: false,
        });
        break;
      case '4':
        this.setState({
          activeTab: tab,
          showBasic: false,
          showDocs: false,
          showCompany: false,
          showNotes: true,
        });
        break;

      default:
        this.setState({
          activeTab: tab,
          showBasic: true,
          showDocs: false,
          showCompany: false,
          showNotes: false,
        });
        break;
    }
    // }
  };

  addField() {
    this.setState({ fieldsCount: this.state.fieldsCount + 1 });
  }

  addInsuranceField() {
    this.setState({
      insuranceFieldsCount: this.state.insuranceFieldsCount + 1,
    });
  }

  addCompanyField() {
    this.setState({ companyFieldsCount: this.state.companyFieldsCount + 1 });
  }

  removeField(ctx, fieldNum) {
    const rf = ctx.state.removedFieldIds;
    rf.push(fieldNum);
    ctx.setState({ removedFieldIds: rf });
    ctx.forceUpdate();
  }

  removeInsuranceField(ctx, fieldNum) {
    const rf = ctx.state.removedInsuranceFieldIds;
    rf.push(fieldNum);
    ctx.setState({ removedInsuranceFieldIds: rf });
    ctx.forceUpdate();
  }

  removeCompanyField(ctx, fieldNum) {
    const rf = ctx.state.removedCompanyFieldIds;
    rf.push(fieldNum);
    ctx.setState({ removedCompanyFieldIds: rf });
    ctx.forceUpdate();
  }

  viberUrl = () => {
    const { editData } = this.props;
    if (editData) {
      return `viber://pa?chatURI=${VIBER_BOT_NAME}&context=${editData._id}`;
    }
    return '';
  };

  async downloadFile(_type, workerId, name, surname, fieldNum = 0) {
    const { editData, t } = this.props;
    let type = _type;
    let alertType = _type;
    try {
      if (_type === 'a1') {
        let docId;
        if (editData[`a1CountryId${fieldNum}`]) {
          // eslint-disable-next-line prefer-destructuring
          docId = editData[`a1CountryId${fieldNum}`].docId;
        } else if (editData[`a1worksiteId${fieldNum}`]) {
          // eslint-disable-next-line prefer-destructuring
          docId = editData[`a1worksiteId${fieldNum}`].docId;
        }
        if (docId) {
          type += `${docId}`;
        }
        alertType = `${_type}${fieldNum}`;
      }

      if (_type === 'insurance') {
        let docId;
        if (editData[`insuranceNum${fieldNum}`]) {
          // eslint-disable-next-line prefer-destructuring
          docId = editData[`insuranceNum${fieldNum}`].docId;
        }
        if (docId) {
          type += `${docId}`;
        }
        alertType = `${_type}${fieldNum}`;
      }

      const success = await DOWNLOAD(`${API_LINK}/f/download`, type, {
        id: workerId,
        name,
        surname,
      });

      if (!success) {
        this.setState({
          showAlert: {
            [alertType]: true,
          },
          alertMsg: (
            <span>
              {t('form.docNotFound')}! <i style={{ color: 'red' }}>({t('form.docNotUploaded')})</i>
            </span>
          ),
        });
      }
    } catch (error) {
      console.log('TCL: DelavciForm2 -> downloadFile -> error', error);
      this.setState({
        showAlert: {
          [alertType]: true,
        },
        alertMsg: <span>{t('form.docError')}!</span>,
      });
    }
  }

  async bulkDownload(type) {
    try {
      this.dismissAlert();
      const { editData } = this.props;

      if (type === 'WORKER_DOCS') {
        const success = await BULK_DOWNLOAD(
          `${API_LINK}/f/bulkDownload`,
          {
            worker: {
              id: editData._id,
              name: editData.name,
              surname: editData.surname,
            },
          },
          type,
        );

        if (!success) {
          this.setState({ nobulk: true });
        }
      } else if (type === 'CV') {
        const success = await BULK_DOWNLOAD(
          `${API_LINK}`,
          {
            ...editData,
          },
          type,
        );

        if (!success) {
          this.setState({ nobulk: true });
        }
      }
    } catch (error) {
      console.log('TCL: bulkDownload -> error', error);
    }
  }

  dismissAlert() {
    this.setState({
      showAlert: {},
      alertMsg: '',
      nobulk: false,
    });
  }

  handleFileInputChange(e) {
    if (e.file) {
      this.setState({ fileCounter: this.state.fileCounter + 1 });
    } else {
      this.setState({ fileCounter: this.state.fileCounter - 1 });
    }
    console.log('TCL: handleFileInputChange -> e', e);
  }

  hijackSubmit = async (e, upload = false) => {
    e.preventDefault();
    // eslint-disable-next-line
    const { handleSubmit, onSubmit, editData, handleUpload } = this.props;
    const { removedFieldIds, removedInsuranceFieldIds, removedCompanyFieldIds, fileCounter } = this.state;

    this.setState({ blockButtons: true });
    /* let ordId;
    if(editData.ordererId ) */

    handleSubmit(async values => {
      const valuesCopy = JSON.parse(JSON.stringify(values));
      valuesCopy.extra = removedFieldIds;
      valuesCopy.extraIns = removedInsuranceFieldIds;
      valuesCopy.extraCom = removedCompanyFieldIds;
      valuesCopy.extraCom = removedCompanyFieldIds;
      valuesCopy.fileCounter = fileCounter;

      if (!upload) {
        onSubmit(valuesCopy);
      } else {
        await handleUpload(valuesCopy);
        await onSubmit(valuesCopy);
      }
    })();
  };

  renderTabsField(activeTab) {
    const { t } = this.props;
    return (
      <div className="tabs tabs--justify tabs--bordered-bottom" style={{ marginBottom: '20px' }}>
        <div className="tabs__wrap">
          <Nav tabs>
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === '1' })}
                onClick={() => {
                  this.toggle('1');
                }}
              >
                {t('form.basicInfo')}
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === '2' })}
                onClick={() => {
                  this.toggle('2');
                }}
              >
                {t('form.company')}
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === '3' })}
                onClick={() => {
                  this.toggle('3');
                }}
              >
                {t('form.docs')}
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === '4' })}
                onClick={() => {
                  this.toggle('4');
                }}
              >
                {t('board.notes.popoverTitle')}
              </NavLink>
            </NavItem>
          </Nav>
        </div>
      </div>
    );
  }

  renderCompanyFields(companies, showCompany) {
    const { companyFieldsCount, removedCompanyFieldIds } = this.state;
    // const that = this;
    const fields = [];
    for (let index = 0; index < companyFieldsCount; index += 1) {
      if (!removedCompanyFieldIds.includes(index)) fields.push(index);
    }

    return fields.map(fieldNum => (
      <div
        key={`form-assignments-field-${fieldNum}`}
        className="form-assignments-fields"
        style={showCompany ? { marginBottom: '5px' } : DNONE}
      >
        <div className="mr-ass">
          <Field name={`companyId${fieldNum}`} component={renderSelectField} options={companies} />
        </div>
        <div className="mr-ass">
          <Field name={`registeredAt${fieldNum}`} component={renderDatePickerField} />
        </div>
        <div className="last">
          <Field name={`unregisteredAt${fieldNum}`} component={renderDatePickerField} />
        </div>
      </div>
    ));
  }

  renderInsuranceFields(showDocs, AlertElt) {
    const { insuranceFieldsCount, removedInsuranceFieldIds, showAlert } = this.state;
    const { editData, t } = this.props;
    const that = this;
    const fields = [];
    for (let index = 0; index < insuranceFieldsCount; index += 1) {
      if (!removedInsuranceFieldIds.includes(index)) fields.push(index);
    }

    return fields.map(fieldNum => {
      const INSUFIELDS = [
        <Fragment>
          <div key={`form-assignments-field-${fieldNum}`} className="form-flex" style={showDocs ? {} : DNONE}>
            <Field
              name={`insuranceScan${fieldNum}`}
              component={renderFileInputField}
              onChange={e => this.handleFileInputChange(e)}
            />

            <Field
              name={`insuranceNum${fieldNum}`}
              component={renderSelectField}
              options={[
                {
                  value: '001',
                  label: '001',
                },
                {
                  value: '002',
                  label: '002',
                },
              ]}
            />

            <Field name={`insuranceStart${fieldNum}`} component={renderDatePickerField} />

            <Field name={`insuranceEnd${fieldNum}`} component={renderDatePickerField} />

            {insuranceFieldsCount - removedInsuranceFieldIds.length > 1 ? (
              <div
                style={{ marginTop: '2px' }}
                //title={t('misc.remove')}
                id={`removeIns${fieldNum}`}
                role="button"
                onClick={() => that.removeInsuranceField(that, fieldNum)}
              >
                <DeleteOutlineIcon />
                <UncontrolledTooltip placement="top" target={`removeIns${fieldNum}`}>
                  {t('misc.remove')}
                </UncontrolledTooltip>
              </div>
            ) : null}
            {showDocs && editData && (
              <DocumentVersionsTable
                files={editData[`insuranceFile${fieldNum}`]}
                onDocumentRemoved={this.props.onDocumentRemoved}
                downloadData={{
                  type: 'insurance',
                  workerId: editData._id,
                  name: editData.name,
                  surname: editData.surname,
                  fieldNum,
                }}
                onDownload={this.downloadFile}
                notif={this.props.notif}
              />
            )}
          </div>
        </Fragment>,
      ];

      if (showAlert[`insurance${fieldNum}`]) {
        INSUFIELDS.push(AlertElt);
      }

      return INSUFIELDS;
    });
  }

  renderA1Fields(countries, worksites, showDocs, AlertElt) {
    const { fieldsCount, removedFieldIds, showAlert } = this.state;
    const { editData, t } = this.props;
    const that = this;
    // console.log('removedFieldIds', removedFieldIds);
    const fields = [];
    for (let index = 0; index < fieldsCount; index += 1) {
      if (!removedFieldIds.includes(index)) fields.push(index);
    }

    return fields.map(fieldNum => {
      const A1FIELDS = [
        <Fragment>
          <div key={`form-assignments-field-${fieldNum}`} className="form-flex" style={showDocs ? {} : DNONE}>
            <Field
              name={`a1Scan${fieldNum}`}
              component={renderFileInputField}
              onChange={e => this.handleFileInputChange(e)}
            />

            <Field name={`a1CountryId${fieldNum}`} component={renderSelectField} options={countries} />

            <Field name={`a1start${fieldNum}`} component={renderDatePickerField} />

            <Field name={`a1end${fieldNum}`} component={renderDatePickerField} />

            <Field name={`a1worksiteId${fieldNum}`} component={renderSelectField} options={worksites} />

            {fieldsCount - removedFieldIds.length > 1 && (
              <div
                style={{ marginTop: '2px' }}
                //title={t('misc.remove')}
                id={`removeA1${fieldNum}`}
                role="button"
                onClick={() => that.removeField(that, fieldNum)}
              >
                <DeleteOutlineIcon />
                <UncontrolledTooltip placement="top" target={`removeA1${fieldNum}`}>
                  {t('misc.remove')}
                </UncontrolledTooltip>
              </div>
            )}
            {showDocs && editData && (
              <DocumentVersionsTable
                files={editData[`a1File${fieldNum}`]}
                onDocumentRemoved={this.props.onDocumentRemoved}
                downloadData={{
                  type: 'a1',
                  workerId: editData._id,
                  name: editData.name,
                  surname: editData.surname,
                  fieldNum,
                }}
                onDownload={this.downloadFile}
                notif={this.props.notif}
              />
            )}
          </div>
        </Fragment>,
      ];

      if (showAlert[`a1${fieldNum}`]) {
        A1FIELDS.push(AlertElt);
      }

      return A1FIELDS;
    });
  }

  render() {
    const {
      handleCancel,
      handleDelete,
      companies,
      professions,
      countries,
      worksites,
      editData,
      pristine,
      t,
    } = this.props;

    const {
      activeTab,
      showDocs,
      showBasic,
      showNotes,
      showCompany,
      removedFieldIds,
      removedCompanyFieldIds,
      removedInsuranceFieldIds,
      showAlert,
      alertMsg,
      nobulk,
      blockButtons,
    } = this.state;

    let saveBtnText = editData ? t('form.save') : t('form.add');
    if (this.state.fileCounter > 0) {
      saveBtnText = `${t('form.upload')} & ${saveBtnText}`;
    }

    const AlertElt = (
      <Alert color="danger" className="alert--bordered" icon handleDismiss={this.dismissAlert}>
        <p>
          <span className="bold-text">{t('form.docError')}:</span>
          <br />
          {alertMsg}
        </p>
      </Alert>
    );

    const noBulkAlertElt = (
      <Alert color="danger" className="alert--bordered" icon handleDismiss={this.dismissAlert}>
        <p>
          <span className="bold-text">{t('form.docError')}:</span>
          <br />
          {t('form.docNotUploaded')}
        </p>
      </Alert>
    );

    return (
      <Col md={12} lg={12}>
        <Card>
          <CardBody>
            {this.renderTabsField(activeTab)}
            <form className="form" onSubmit={e => this.hijackSubmit(e)}>
              {/* ====================== DOCS ================================ */}
              {/* A1 */}
              <Collapse title="A1" className="with-shadow" style={showDocs ? {} : DNONE}>
                <div className="form-assignments-titles form-dummy-space" style={showDocs ? {} : DNONE}>
                  <div className="mr">
                    <span className="form__form-group-label" />
                  </div>

                  <div className="mr">
                    <span className="form__form-group-label">A1 ({t('form.country')})</span>
                  </div>
                  <div className="mr">
                    <span className="form__form-group-label">A1 ({t('form.begin')})</span>
                  </div>
                  <div className="mr">
                    <span className="form__form-group-label">A1 ({t('form.exp')})</span>
                  </div>
                  <div className="last">
                    <span className="form__form-group-label">A1 ({t('form.worksite')})</span>
                  </div>
                </div>
                {this.renderA1Fields(countries, worksites, showDocs, AlertElt)}
                <div className="form-add-new-field-btn" style={showDocs ? {} : DNONE}>
                  <Button
                    size="sm"
                    style={{
                      width: '145px',
                      fontSize: '12px',
                      borderRadius: '2px',
                    }}
                    onClick={this.addField}
                  >
                    {t('form.add')} A1
                  </Button>
                </div>
              </Collapse>
              {/* A1 */}

              {/* podlaga za zav. */}
              <Collapse title={t('form.ins')} className="with-shadow" style={showDocs ? {} : DNONE}>
                <div className="form-assignments-titles  form-dummy-space" style={showDocs ? {} : DNONE}>
                  <div className="mr-ins">
                    <span className="form__form-group-label" />
                  </div>

                  <div className="mr-ins">
                    <span className="form__form-group-label">
                      {t('form.ins')} ({t('form.base')})
                    </span>
                  </div>
                  <div className="mr-ins">
                    <span className="form__form-group-label">
                      {t('form.insBaseShort')} ({t('form.from')})
                    </span>
                  </div>
                  <div className="last">
                    <span className="form__form-group-label">
                      {t('form.insBaseShort')} ({t('form.to')})
                    </span>
                  </div>
                </div>
                {this.renderInsuranceFields(showDocs, AlertElt)}
                <div className="form-add-new-field-btn" style={showDocs ? {} : DNONE}>
                  <Button
                    size="sm"
                    style={{
                      width: '145px',
                      fontSize: '12px',
                      borderRadius: '2px',
                    }}
                    onClick={this.addInsuranceField}
                  >
                    {t('form.add')} {t('form.insBaseShort')}
                  </Button>
                </div>
              </Collapse>
              {/* podlaga za zav. */}
              {/* EU Kartica */}
              {/* <div
                className="form-single-doc-wrapper"
                style={showDocs ? {} : DNONE}
              > */}
              <Collapse title={t('misc.otherDocs')} className="with-shadow" style={showDocs ? {} : DNONE}>
                <div className="form-assignments-titles form-dummy-space" style={showDocs ? {} : DNONE}>
                  <div className="mr3">
                    <span className="form__form-group-label">EU {t('form.card')}</span>
                  </div>

                  <div className="mr3">
                    <span className="form__form-group-label">EU ({t('form.issuer')})</span>
                  </div>
                  <div className="mr3">
                    <span className="form__form-group-label">EU ({t('form.num')})</span>
                  </div>
                  <div className="mr3">
                    <span className="form__form-group-label">EU ({t('form.begin')})</span>
                  </div>
                  <div className="last">
                    <span className="form__form-group-label">EU ({t('form.exp')})</span>
                  </div>
                </div>
                <div
                  key="form-assignments-field-eu-k"
                  className="form-flex form-other-docs"
                  style={showDocs ? {} : DNONE}
                >
                  <Field name="EUScan" component={renderFileInputField} onChange={e => this.handleFileInputChange(e)} />

                  <Field
                    name="EUProvider"
                    component={renderSelectField}
                    options={[
                      {
                        value: slug(EU_PROVIDERS[0]),
                        label: EU_PROVIDERS[0],
                      },
                      {
                        value: slug(EU_PROVIDERS[1]),
                        label: EU_PROVIDERS[1],
                      },
                    ]}
                  />

                  <Field name="EUNum" component={renderTextField} />

                  <Field name="EUStart" component={renderDatePickerField} />

                  <Field name="EUExp" component={renderDatePickerField} />
                  {showDocs && editData && (
                    <DocumentVersionsTable
                      files={editData.EUFile}
                      onDocumentRemoved={this.props.onDocumentRemoved}
                      downloadData={{
                        type: 'eu',
                        workerId: editData._id,
                        name: editData.name,
                        surname: editData.surname,
                        fieldNum: null,
                      }}
                      onDownload={this.downloadFile}
                      notif={this.props.notif}
                    />
                  )}
                </div>

                {/* </div> */}
                {showAlert.eu && AlertElt}
                {/* EU Kartica */}
                {/* potni list */}
                <div className="form-assignments-titles" style={showDocs ? {} : DNONE}>
                  <div className="mr">
                    <span className="form__form-group-label">{t('form.passport')}</span>
                  </div>

                  <div className="mr">
                    <span className="form__form-group-label">
                      {t('form.passport')} ({t('form.num')})
                    </span>
                  </div>
                  <div className="mr">
                    <span className="form__form-group-label">
                      {t('form.passport')} ({t('form.begin')})
                    </span>
                  </div>
                  <div className="last">
                    <span className="form__form-group-label">
                      {t('form.passport')} ({t('form.exp')})
                    </span>
                  </div>
                </div>
                <div
                  key="form-assignments-field-passport"
                  className="form-flex form-other-docs"
                  style={showDocs ? {} : DNONE}
                >
                  <Field
                    name="passScan"
                    component={renderFileInputField}
                    onChange={e => this.handleFileInputChange(e)}
                  />

                  <Field name="passNum" component={renderTextField} />

                  <Field name="passStart" component={renderDatePickerField} />

                  <Field name="passExp" component={renderDatePickerField} />

                  <div className="form-field-dummy" />

                  {showDocs && editData && (
                    <DocumentVersionsTable
                      files={editData.passportFile}
                      onDocumentRemoved={this.props.onDocumentRemoved}
                      downloadData={{
                        type: 'passport',
                        workerId: editData._id,
                        name: editData.name,
                        surname: editData.surname,
                        fieldNum: null,
                      }}
                      onDownload={this.downloadFile}
                      notif={this.props.notif}
                    />
                  )}
                </div>
                {showAlert.passport && AlertElt}

                {/* potni list */}
                {/* osebna */}
                <div className="form-assignments-titles" style={showDocs ? {} : DNONE}>
                  <div className="mr">
                    <span className="form__form-group-label">{t('form.id')}</span>
                  </div>

                  <div className="mr">
                    <span className="form__form-group-label">
                      {t('form.idShort')} ({t('form.num')})
                    </span>
                  </div>
                  <div className="mr">
                    <span className="form__form-group-label">
                      {t('form.idShort')} ({t('form.begin')})
                    </span>
                  </div>
                  <div className="last">
                    <span className="form__form-group-label">
                      {t('form.idShort')} ({t('form.exp')})
                    </span>
                  </div>
                </div>
                <div
                  key="form-assignments-field-id"
                  className="form-flex form-other-docs"
                  style={showDocs ? {} : DNONE}
                >
                  <Field name="IDScan" component={renderFileInputField} onChange={e => this.handleFileInputChange(e)} />

                  <Field name="IDNum" component={renderTextField} />

                  <Field name="IDStart" component={renderDatePickerField} />

                  <Field name="IDExp" component={renderDatePickerField} />

                  <div className="form-field-dummy" />
                  {showDocs && editData && (
                    <DocumentVersionsTable
                      files={editData.IDFile}
                      onDocumentRemoved={this.props.onDocumentRemoved}
                      downloadData={{
                        type: 'id',
                        workerId: editData._id,
                        name: editData.name,
                        surname: editData.surname,
                        fieldNum: null,
                      }}
                      onDownload={this.downloadFile}
                      notif={this.props.notif}
                    />
                  )}
                </div>
                {showAlert.id && AlertElt}

                {/* osebna */}
                {/* dovoljenje za bivanje */}
                <div className="form-assignments-titles" style={showDocs ? {} : DNONE}>
                  <div className="mr">
                    <span className="form__form-group-label">{t('form.perm')}</span>
                  </div>

                  <div className="mr">
                    <span className="form__form-group-label">
                      {t('form.permShort')} ({t('form.num')})
                    </span>
                  </div>
                  <div className="mr">
                    <span className="form__form-group-label">
                      {t('form.permShort')} ({t('form.begin')})
                    </span>
                  </div>
                  <div className="last">
                    <span className="form__form-group-label">
                      {t('form.permShort')} ({t('form.exp')})
                    </span>
                  </div>
                </div>
                <div
                  key="form-assignments-field-perm"
                  className="form-flex form-other-docs"
                  style={showDocs ? {} : DNONE}
                >
                  <Field
                    name="permScan"
                    component={renderFileInputField}
                    onChange={e => this.handleFileInputChange(e)}
                  />

                  <Field name="permNum" component={renderTextField} />

                  <Field name="permStart" component={renderDatePickerField} />

                  <Field name="permExp" component={renderDatePickerField} />

                  <div className="form-field-dummy" />
                  {showDocs && editData && (
                    <DocumentVersionsTable
                      files={editData.permFile}
                      onDocumentRemoved={this.props.onDocumentRemoved}
                      downloadData={{
                        type: 'perm',
                        workerId: editData._id,
                        name: editData.name,
                        surname: editData.surname,
                        fieldNum: null,
                      }}
                      onDownload={this.downloadFile}
                      notif={this.props.notif}
                    />
                  )}
                </div>
                {showAlert.perm && AlertElt}
                {/* dovoljenje za bivanje */}

                {/* Delovno dovoljenje */}
                <div className="form-assignments-titles" style={showDocs ? {} : DNONE}>
                  <div className="mr">
                    <span className="form__form-group-label">{t('form.dd')}</span>
                  </div>

                  <div className="mr">
                    <span className="form__form-group-label">
                      {t('form.ddShort')} ({t('form.num')})
                    </span>
                  </div>
                  <div className="mr">
                    <span className="form__form-group-label">
                      {t('form.ddShort')} ({t('form.begin')})
                    </span>
                  </div>
                  <div className="last">
                    <span className="form__form-group-label">
                      {t('form.ddShort')} ({t('form.exp')})
                    </span>
                  </div>
                </div>
                <div
                  key="form-assignments-field-dd"
                  className="form-flex form-other-docs"
                  style={showDocs ? {} : DNONE}
                >
                  <Field name="DDScan" component={renderFileInputField} onChange={e => this.handleFileInputChange(e)} />

                  <Field name="DDNum" component={renderTextField} />

                  <Field name="DDStart" component={renderDatePickerField} />

                  <Field name="DDExp" component={renderDatePickerField} />

                  <div className="form-field-dummy" />

                  {showDocs && editData && (
                    <DocumentVersionsTable
                      files={editData.DDFile}
                      onDocumentRemoved={this.props.onDocumentRemoved}
                      downloadData={{
                        type: 'dd',
                        workerId: editData._id,
                        name: editData.name,
                        surname: editData.surname,
                        fieldNum: null,
                      }}
                      onDownload={this.downloadFile}
                      notif={this.props.notif}
                    />
                  )}
                </div>
                {showAlert.dd && AlertElt}
                {/* Delovno dovoljenje */}

                {/* Prijava bivanja */}
                <div className="form-assignments-titles" style={showDocs ? {} : DNONE}>
                  <div className="mr">
                    <span className="form__form-group-label">{t('form.permApp')}</span>
                  </div>

                  <div className="mr">
                    <span className="form__form-group-label">
                      {t('form.permAppShort')} ({t('form.num')})
                    </span>
                  </div>
                  <div className="mr">
                    <span className="form__form-group-label">
                      {t('form.permAppShort')} ({t('form.begin')})
                    </span>
                  </div>
                  <div className="last">
                    <span className="form__form-group-label">
                      {t('form.permAppShort')} ({t('form.exp')})
                    </span>
                  </div>
                </div>
                <div
                  key="form-assignments-field-permApp"
                  className="form-flex form-other-docs"
                  style={showDocs ? {} : DNONE}
                >
                  <Field
                    name="permAppScan"
                    component={renderFileInputField}
                    onChange={e => this.handleFileInputChange(e)}
                  />

                  <Field name="permAppNum" component={renderTextField} />

                  <Field name="permAppStart" component={renderDatePickerField} />

                  <Field name="permAppExp" component={renderDatePickerField} />
                  <div className="form-field-dummy" />
                  {showDocs && editData && (
                    <DocumentVersionsTable
                      files={editData.permAppFile}
                      onDocumentRemoved={this.props.onDocumentRemoved}
                      downloadData={{
                        type: 'permApp',
                        workerId: editData._id,
                        name: editData.name,
                        surname: editData.surname,
                        fieldNum: null,
                      }}
                      onDownload={this.downloadFile}
                      notif={this.props.notif}
                    />
                  )}
                </div>
                {showAlert.permApp && AlertElt}
                {/* Prijava bivanja */}
                {/* Podaljšanje bivanja */}
                <div className="form-assignments-titles" style={showDocs ? {} : DNONE}>
                  <div className="mr">
                    <span className="form__form-group-label">{t('form.permExt')}</span>
                  </div>

                  <div className="mr">
                    <span className="form__form-group-label">
                      {t('form.permExtShort')} ({t('form.num')})
                    </span>
                  </div>
                  <div className="mr">
                    <span className="form__form-group-label">
                      {t('form.permExtShort')} ({t('form.begin')})
                    </span>
                  </div>
                  <div className="last">
                    <span className="form__form-group-label">
                      {t('form.permExtShort')} ({t('form.exp')})
                    </span>
                  </div>
                </div>
                <div
                  key="form-assignments-field-permExt"
                  className="form-flex form-other-docs"
                  style={showDocs ? {} : DNONE}
                >
                  <Field
                    name="permExtScan"
                    component={renderFileInputField}
                    onChange={e => this.handleFileInputChange(e)}
                  />

                  <Field name="permExtNum" component={renderTextField} />

                  <Field name="permExtStart" component={renderDatePickerField} />

                  <Field name="permExtExp" component={renderDatePickerField} />

                  <div className="form-field-dummy" />
                  {showDocs && editData && (
                    <DocumentVersionsTable
                      files={editData.permExtFile}
                      onDocumentRemoved={this.props.onDocumentRemoved}
                      downloadData={{
                        type: 'permExt',
                        workerId: editData._id,
                        name: editData.name,
                        surname: editData.surname,
                        fieldNum: null,
                      }}
                      onDownload={this.downloadFile}
                      notif={this.props.notif}
                    />
                  )}
                </div>
                {showAlert.permExt && AlertElt}
                {/* Podaljšanje bivanja */}

                {/* Zavarovanje tujina */}
                <div className="form-assignments-titles" style={showDocs ? {} : DNONE}>
                  <div className="mr">
                    <span className="form__form-group-label">{t('form.insAbroad')}</span>
                  </div>

                  <div className="mr">
                    <span className="form__form-group-label">
                      {t('form.insAbroadShort')} ({t('form.num')})
                    </span>
                  </div>
                  <div className="mr">
                    <span className="form__form-group-label">
                      {t('form.insAbroadShort')} ({t('form.begin')})
                    </span>
                  </div>
                  <div className="last">
                    <span className="form__form-group-label">
                      {t('form.insAbroadShort')} ({t('form.exp')})
                    </span>
                  </div>
                </div>
                <div
                  key="form-assignments-field-insAbroad"
                  className="form-flex form-other-docs"
                  style={showDocs ? {} : DNONE}
                >
                  <Field
                    name="insAbroadScan"
                    component={renderFileInputField}
                    onChange={e => this.handleFileInputChange(e)}
                  />

                  <Field name="insAbroadNum" component={renderTextField} />

                  <Field name="insAbroadStart" component={renderDatePickerField} />

                  <Field name="insAbroadExp" component={renderDatePickerField} />

                  <div className="form-field-dummy" />

                  {showDocs && editData && (
                    <DocumentVersionsTable
                      files={editData.insAbroadFile}
                      onDocumentRemoved={this.props.onDocumentRemoved}
                      downloadData={{
                        type: 'insAbroad',
                        workerId: editData._id,
                        name: editData.name,
                        surname: editData.surname,
                        fieldNum: null,
                      }}
                      onDownload={this.downloadFile}
                      notif={this.props.notif}
                    />
                  )}
                </div>
                {showAlert.insAbroad && AlertElt}
                {/* Zavarovanje tujina */}
              </Collapse>
              {/* ====================== DOCS ================================ */}

              {/* FIRME. */}
              <div className="form-assignments-titles" style={showCompany ? { marginTop: '5px' } : DNONE}>
                <div className="mr-ass">
                  <span className="form__form-group-label">{t('form.company')}</span>
                </div>
                <div className="mr-ass">
                  <span className="form__form-group-label">{t('form.registeredAt')}</span>
                </div>
                <div className="last">
                  <span className="form__form-group-label">{t('form.registeredTo')}</span>
                </div>
              </div>
              {this.renderCompanyFields(companies, showCompany)}
              <div
                className="form-assignments-add-worker-btn-wrapper"
                style={showCompany ? { marginBottom: '10px' } : DNONE}
              >
                <Button
                  className="form-assignments-add-worker-btn"
                  size="sm"
                  color="link"
                  onClick={this.addCompanyField}
                >
                  {t('form.add')} {t('form.company')}
                </Button>
              </div>
              {/* FIRME. */}

              <div className="form__half">
                <div className="form__form-group" style={showBasic ? {} : DNONE}>
                  <span className="form__form-group-label">{t('form.name')}</span>
                  <div className="form__form-group-field">
                    <Field name="name" component={renderTextField} type="text" />
                  </div>
                </div>
                <div className="form__form-group" style={showBasic ? {} : DNONE}>
                  <span className="form__form-group-label">{t('form.surname')}</span>
                  <div className="form__form-group-field">
                    <Field name="surname" component={renderTextField} type="text" />
                  </div>
                </div>
                <div className="form__form-group" style={showBasic ? {} : DNONE}>
                  <span className="form__form-group-label">{t('form.address')}</span>
                  <div className="form__form-group-field">
                    <Field name="address" component="input" type="text" />
                  </div>
                </div>
                <div className="form__form-group" style={showBasic ? {} : DNONE}>
                  <span className="form__form-group-label">{t('form.zip')}</span>
                  <div className="form__form-group-field">
                    <Field name="zip" component="input" type="text" />
                  </div>
                </div>
                <div className="form__form-group" style={showBasic ? {} : DNONE}>
                  <span className="form__form-group-label">{t('form.city')}</span>
                  <div className="form__form-group-field">
                    <Field name="city" component="input" type="text" />
                  </div>
                </div>
                <div className="form__form-group" style={showBasic ? {} : DNONE}>
                  <span className="form__form-group-label">{t('form.country')}</span>
                  <div className="form__form-group-field">
                    <Field name="countryId" component={renderSelectField} options={countries} />
                  </div>
                </div>
                <div className="form__form-group" style={showBasic ? {} : DNONE}>
                  <span className="form__form-group-label">{t('form.residency')}</span>
                  <div className="form__form-group-field">
                    <Field name="residency" component={renderSelectField} options={countries} />
                  </div>
                </div>
                <div className="form__form-group" style={showBasic ? {} : DNONE}>
                  <span className="form__form-group-label">{t('form.citizenship')}</span>
                  <div className="form__form-group-field">
                    <Field name="citizenship" component={renderSelectField} options={countries} />
                  </div>
                </div>
                <div className="form__form-group" style={showBasic ? {} : DNONE}>
                  <span className="form__form-group-label">{t('form.clothesSize')}</span>
                  <div className="form__form-group-field">
                    <Field name="clothesSize" component="input" type="text" />
                  </div>
                </div>

                {editData && (
                  <div className="form__form-group" style={showBasic ? {} : DNONE}>
                    <span className="form__form-group-label">{t('form.urlActivation')}</span>
                    <div className="form__form-group-field">
                      <input type="text" readOnly="true" name="viber-link" value={this.viberUrl()} />
                      <div className="mt-1 ml-1">{editData.viberUrl ? <CheckIcon /> : <CloseIcon />}</div>
                    </div>
                  </div>
                )}
              </div>
              <div className="form__half">
                <div className="form__form-group" style={showBasic ? ML : DNONE}>
                  <span className="form__form-group-label">{t('form.birthDate')}</span>
                  <div className="form__form-group-field">
                    <Field name="birthDate" component={renderDatePickerField} />
                    <div className="form__form-group-icon">
                      <CalendarBlankIcon />
                    </div>
                  </div>
                </div>

                <div className="form__form-group" style={showBasic ? ML : DNONE}>
                  <span className="form__form-group-label">{t('form.profession')}</span>
                  <div className="form__form-group-field">
                    <Field name="professionId" component={renderSelectField} options={professions} />
                  </div>
                </div>

                <div className="form__form-group" style={showBasic ? ML : DNONE}>
                  <span className="form__form-group-label">{t('form.taxID')}</span>
                  <div className="form__form-group-field">
                    <Field name="taxID" component="input" type="text" />
                  </div>
                </div>
                <div className="form__form-group" style={showBasic ? ML : DNONE}>
                  <span className="form__form-group-label">{t('form.trr')}</span>
                  <div className="form__form-group-field">
                    <Field name="trr" component="input" type="text" />
                  </div>
                </div>

                <div className="form__form-group" style={showBasic ? ML : DNONE}>
                  <span className="form__form-group-label">{t('form.emso')}</span>
                  <div className="form__form-group-field">
                    <Field name="emso" component="input" type="text" />
                  </div>
                </div>

                <div className="form__form-group" style={showBasic ? ML : DNONE}>
                  <span className="form__form-group-label">{t('form.birthPlace')}</span>
                  <div className="form__form-group-field">
                    <Field name="birthPlace" component="input" type="text" />
                  </div>
                </div>

                <div className="form__form-group" style={showBasic ? ML : DNONE}>
                  <span className="form__form-group-label">{t('form.birthCountry')}</span>
                  <div className="form__form-group-field">
                    <Field name="birthCountry" component={renderSelectField} options={countries} />
                  </div>
                </div>

                <div className="form__form-group" style={showBasic ? ML : DNONE}>
                  <span className="form__form-group-label">{t('form.tel')}</span>
                  <div className="form__form-group-field">
                    <Field name="tel" component="input" type="text" />
                  </div>
                </div>

                <div className="form__form-group" style={showBasic ? ML : DNONE}>
                  <span className="form__form-group-label">{t('form.shoeSize')}</span>
                  <div className="form__form-group-field">
                    <Field name="shoeSize" component="input" type="text" />
                  </div>
                </div>

                <div className="form__form-group" style={showBasic ? ML : DNONE}>
                  <span className="form__form-group-label">{t('form.pic')}</span>
                  <div className="form__form-group-field">
                    <Field
                      name="picScan"
                      component={renderFileInputField}
                      onChange={e => this.handleFileInputChange(e)}
                    />
                  </div>
                </div>
              </div>

              <div className="form__form-group" style={showNotes ? {} : DNONE}>
                <span className="form__form-group-label" />
                <div className="form__form-group-field">
                  <Field name="notes" component="textarea" type="text" />
                </div>
              </div>

              {/* <ButtonToolbar
                className="form__button-toolbar"
                style={{ width: '100%' }}
              > */}
              <div className="width-50-prct">
                {editData && this.state.activeTab === '1' && !blockButtons ? (
                  <Modal
                    color="danger"
                    title="Pozor!"
                    colored
                    btn={t('form.delete')}
                    message={`Ste prepričani da želite izbrisati delavca z imenom ${editData.name} 
                    ${editData.surname}?`}
                    onConfirm={() => handleDelete(editData)}
                  />
                ) : null}
                <Button type="button" onClick={handleCancel} style={{ marginBottom: '5px' }} disabled={blockButtons}>
                  {t('form.cancel')}
                </Button>
                <Button
                  color="primary"
                  onClick={e => {
                    this.dismissAlert();
                    if (this.state.fileCounter > 0) {
                      this.hijackSubmit(e, true);
                    } else {
                      this.hijackSubmit(e, false);
                    }
                  }}
                  disabled={
                    (pristine &&
                      this.state.fileCounter === 0 &&
                      removedFieldIds.length === 0 &&
                      removedInsuranceFieldIds.length === 0 &&
                      removedCompanyFieldIds.length === 0) ||
                    blockButtons
                  }
                  style={{ marginBottom: '5px' }}
                >
                  {saveBtnText}
                </Button>
                {/* {showDocs &&
                  editData &&
                  this.state.fileCounter > 0 &&
                  !pristine && (
                    <Button
                      color="warning"
                      size="sm"
                      style={{ marginBottom: '5px' }}
                      onClick={e => {
                        this.dismissAlert();
                        this.hijackSubmit(e, true);
                      }}
                    >
                      {t('form.upload')}
                    </Button>
                  )} */}
              </div>
              {showDocs && editData && editData.hasFiles && (
                <div className="form-download-all-wrapper">
                  <Button
                    //color="link"
                    //size="sm"
                    //className="bulk-download-btn"
                    onClick={() => this.bulkDownload('WORKER_DOCS')}
                    //style={{ color: 'dimgray' }}
                    disabled={blockButtons}
                  >
                    {t('form.download')} {t('form.all')} <DownloadIcon style={{ fill: 'MediumSeaGreen' }} />
                  </Button>
                </div>
              )}
              {showBasic && editData && this.state.activeTab === '1' && !blockButtons && (
                <div className="form-download-all-wrapper">
                  <Button
                    //color="link"
                    //size="sm"
                    //className="bulk-download-btn"
                    onClick={() => this.bulkDownload('CV')}
                    //style={{ color: 'dimgray' }}
                    disabled={blockButtons}
                  >
                    {t('form.exportWord')} <DownloadIcon style={{ fill: 'MediumSeaGreen' }} />
                  </Button>
                </div>
              )}
              {/* </ButtonToolbar> */}

              {nobulk && noBulkAlertElt}
            </form>
          </CardBody>
        </Card>
      </Col>
    );
  }
}

DelavciForm2.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
  handleUpload: PropTypes.func.isRequired,
  editData: PropTypes.object,
  companies: PropTypes.arrayOf(PropTypes.object).isRequired,
  professions: PropTypes.arrayOf(PropTypes.object).isRequired,
  countries: PropTypes.arrayOf(PropTypes.object).isRequired,
  worksites: PropTypes.arrayOf(PropTypes.object).isRequired,
  pristine: PropTypes.bool.isRequired,
  initialFieldCount: PropTypes.number,
  initialInsuranceFieldCount: PropTypes.number,
  onDocumentRemoved: PropTypes.func,
  initialCompanyFieldCount: PropTypes.number,
  initialTab: PropTypes.string,
  notif: PropTypes.object,
  t: PropTypes.func.isRequired,
};

DelavciForm2.defaultProps = {
  editData: null,
  initialInsuranceFieldCount: 1,
  initialFieldCount: 1,
  initialCompanyFieldCount: 1,
  initialTab: '1',
  onDocumentRemoved: null,
  notif: null,
};

const wr = reduxForm({
  form: 'delavci_form_2',
  validate, // a unique identifier for this form
  /* onChange: (values, dispatch, props, previousValues) => {
      // props.submit();
      // props.disableConfirm = false;
      console.log(values);
    }, */

  // initialValues: { registeredAt: new Date(Date.now()) },
})(DelavciForm2);

export default translate('common')(wr);
