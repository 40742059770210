import React, { PureComponent } from 'react';
import { Creatable } from 'react-select';
import PropTypes from 'prop-types';

export class CreatableSelectField extends PureComponent {
  // customStyle = {
  //   container: provided => ({
  //     ...provided,
  //     display: 'inline-block',
  //     minHeight: '1px',
  //     textAlign: 'left',
  //     border: 'none',
  //   }),
  //   control: provided => ({
  //     ...provided,

  //     minHeight: '1px',
  //     height: '32px',
  //   }),
  //   input: provided => ({
  //     ...provided,
  //     minHeight: '1px',
  //   }),
  //   dropdownIndicator: provided => ({
  //     ...provided,
  //     minHeight: '1px',
  //     paddingTop: '0',
  //     paddingBottom: '5',
  //   }),
  //   indicatorSeparator: provided => ({
  //     ...provided,
  //     minHeight: '1px',
  //     height: '17px',
  //   }),

  //   clearIndicator: provided => ({
  //     ...provided,
  //     minHeight: '1px',
  //   }),
  //   valueContainer: provided => ({
  //     ...provided,
  //     minHeight: '1px',
  //     height: '40px',
  //     paddingTop: '0',
  //     paddingBottom: '0',
  //   }),
  //   singleValue: provided => ({
  //     ...provided,
  //     minHeight: '1px',
  //     paddingBottom: '7px',
  //   }),
  // };

  static propTypes = {
    onChange: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    options: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.string,
        label: PropTypes.string,
      }),
    ),
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({
        value: PropTypes.string,
        label: PropTypes.string,
      }),
    ]),
  };

  static defaultProps = {
    placeholder: '',
    options: [],
    value: null,
  };

  handleChange = selectedOption => {
    this.props.onChange(selectedOption);
  };

  render() {
    const { name, placeholder, options } = this.props;

    return (
      <Creatable
        name={name}
        value={this.props.value}
        onChange={this.handleChange}
        options={options}
        isClearable
        className="form__form-group-select"
        placeholder={placeholder}
        //formatCreateLabel={value => <span>What you want here</span>}
        //styles={this.customStyle}
      />
    );
  }
}

const renderCreatableSelectField = props => (
  <div className="form__form-group-input-wrap form__form-group-input-wrap--error-above">
    <CreatableSelectField {...props.input} options={props.options} placeholder={props.placeholder} />
    {props.meta.touched && props.meta.error && <span className="form__form-group-error">{props.meta.error}</span>}
  </div>
);

renderCreatableSelectField.propTypes = {
  input: PropTypes.shape({
    onChange: PropTypes.func,
    name: PropTypes.string,
    value: PropTypes.object,
  }).isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    }),
  ),
  placeholder: PropTypes.string,
};

renderCreatableSelectField.defaultProps = {
  meta: null,
  options: [],
  placeholder: '',
};

export default renderCreatableSelectField;
