import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-i18next';
import moment from 'moment-timezone';
import {
  Table,
  ButtonToolbar,
  Button,
  UncontrolledTooltip,
  Progress,
  Card,
  CardBody,
  Row,
  Col,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Badge,
} from 'reactstrap';
import PlusIcon from 'mdi-react/PlusIcon';
import CheckIcon from 'mdi-react/CheckIcon';
import ChevronDownIcon from 'mdi-react/ChevronDownIcon';
import EyeOffIcon from 'mdi-react/EyeOffIcon';
import { TIMEZONE } from 'constants/config';
import EyeIcon from 'mdi-react/EyeIcon';
import slugify from 'slugify';
import kontoHelper from '../../helpers/index';
import constants from '../../../helpers/index';

const { calculateSaldo, renderProperDate, allowHistoRowRender, isTransactionIgnored } = kontoHelper;
const { DEBOOK_CATEGORIES } = constants;

class Konto extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    transactions: PropTypes.arrayOf(PropTypes.object).isRequired,
    kontoName: PropTypes.string.isRequired,
    kontoType: PropTypes.string.isRequired,
    month: PropTypes.string.isRequired,
    year: PropTypes.string.isRequired,
    onTransactionConfirm: PropTypes.func.isRequired,
    onTransactionIgnore: PropTypes.func.isRequired,
    onRowClick: PropTypes.func.isRequired,
    onDebookClick: PropTypes.func.isRequired,
    kontoId: PropTypes.string.isRequired,
    histoValue: PropTypes.object.isRequired,
  };

  //   static defaultProps = {
  //       kontoType
  //   }

  constructor(props) {
    super(props);
    this.state = {};
  }

  handleDropdownClick = value => {
    const { kontoId, onDebookClick } = this.props;
    onDebookClick(value, kontoId);
  };

  handleIgnoreClick = (transactionId, ignore) => {
    this.props.onTransactionIgnore(transactionId, ignore);
  };

  handleConfirmClick = (value, isTemplate) => {
    this.props.onTransactionConfirm(value, isTemplate);
  };

  mapDropdownOptions = () => {
    return DEBOOK_CATEGORIES.map((o, i) => (
      // eslint-disable-next-line react/no-array-index-key
      <DropdownItem key={o.value + o.label + i} onClick={() => this.handleDropdownClick(o)}>
        {o.label}
      </DropdownItem>
    ));
  };

  renderAddTransactionDropdown = () => {
    return (
      //   <Button size="sm" color="success" className="add-trans-btn">
      //     <PlusIcon />
      //   </Button>
      <UncontrolledDropdown className="konto-dropdown">
        <DropdownToggle className="icon icon--right" color="primary">
          <p>
            <PlusIcon /> <ChevronDownIcon />
          </p>
        </DropdownToggle>
        <DropdownMenu className="dropdown__menu inline__table">{this.mapDropdownOptions()}</DropdownMenu>
      </UncontrolledDropdown>
    );
  };

  getReturnedReason = returned => {
    //const style = {color:""}
    const { t } = this.props;
    if (!returned) return null;
    if (returned === 'receipt')
      return (
        <span>
          <strong>{t('cashbox.returned_receipt').toUpperCase()}</strong>
        </span>
      );
    return (
      <span>
        <strong>{t('cashbox.returned_cash').toUpperCase()}</strong>
      </span>
    );
  };

  renderModified = (modifiedBy, unconfirmed, ignored) => {
    const { t } = this.props;
    const style = unconfirmed ? { color: 'gray' } : { color: 'lightgray' };
    if (!modifiedBy) return <span>/</span>;
    return (
      <Fragment>
        <div>
          <span style={style}>{modifiedBy.name}</span> <span style={style}>{modifiedBy.surname}</span>
        </div>
        <div>
          <span style={style}>{modifiedBy.updatedAt}</span>
        </div>
        {unconfirmed && (
          <div>
            <span>
              <strong style={{ color: 'red' }}>{t('board.ow.unconfirmed')}!</strong>
            </span>
          </div>
        )}
        {ignored && (
          <div>
            <span>
              <strong style={{ color: 'red' }}>{t('cashbox.ignored').toUpperCase()}!</strong>
            </span>
          </div>
        )}
      </Fragment>
    );
  };

  renderTable = () => {
    const { transactions, onRowClick, month, year, histoValue, t } = this.props;

    return transactions.reduce((list, transaction, index) => {
      const { amount, unconfirmed, isTemplate, dateRaw, source, ignore } = transaction;
      const amountString = Number(amount) > 0 ? `+${amount}` : amount;
      const amountColor = Number(amount) >= 0 ? 'green' : 'red';
      const returnedReason = this.getReturnedReason(transaction.returned);
      const ignored = isTransactionIgnored(ignore, month, year);
      let bgrColor = transaction.unconfirmed ? { backgroundColor: 'pink' } : {};
      if (transaction.isCompensation) {
        bgrColor = { backgroundColor: 'azure' };
      }
      if (ignored) {
        bgrColor = { backgroundColor: 'darkgray' };
      }

      const sourceName = source ? source.name : '-';
      const properTransDate = isTemplate ? renderProperDate(dateRaw, month, year, false) : dateRaw;
      if (!allowHistoRowRender(properTransDate, month, year, histoValue)) {
        return list;
      }
      list.push(
        <tr
          key={`konto-t-${transaction._id}`}
          style={bgrColor}
          onClick={e => {
            e.stopPropagation();
            onRowClick(transaction._id, transaction.isTemplate, transaction.isCompensation);
          }}
        >
          <td>{index + 1}</td>
          <td>{isTemplate ? renderProperDate(dateRaw, month, year, true) : transaction.date}</td>
          <td>
            <strong>{sourceName}</strong>
          </td>
          <td>
            <strong style={{ fontSize: 'large', color: amountColor }}>{amountString}</strong>
          </td>
          <td>{transaction.category.name.toUpperCase()}</td>
          <td>
            {returnedReason && (
              <Fragment>
                {returnedReason}
                <br />
              </Fragment>
            )}
            {transaction.type.name.toUpperCase()}
          </td>
          <td>{transaction.vehicle.label}</td>
          <td>{transaction.notes}</td>
          <td>{this.renderModified(transaction.modifiedBy, transaction.unconfirmed, ignored)}</td>
          <td>
            {!transaction.isCompensation && (
              <Fragment>
                {unconfirmed ? (
                  <Fragment>
                    <ButtonToolbar style={{ position: 'relative' }}>
                      <Button
                        id={`confirm-btn-${transaction._id}`}
                        size="sm"
                        color="danger"
                        className="trans-conf-btn"
                        onClick={e => {
                          e.stopPropagation();
                          this.handleConfirmClick(transaction._id, transaction.isTemplate);
                        }}
                      >
                        <CheckIcon />
                      </Button>
                    </ButtonToolbar>
                    <UncontrolledTooltip placement="top" target={`confirm-btn-${transaction._id}`}>
                      {t('cashbox.trans_conf')}
                    </UncontrolledTooltip>
                  </Fragment>
                ) : (
                  <Fragment>
                    <ButtonToolbar style={{ position: 'relative' }}>
                      <Button
                        id={`confirm-btn-${transaction._id}`}
                        size="sm"
                        className="trans-conf-btn"
                        onClick={e => {
                          e.stopPropagation();
                          this.handleIgnoreClick(transaction._id, !ignored);
                        }}
                      >
                        {ignored ? <EyeIcon /> : <EyeOffIcon />}
                      </Button>
                    </ButtonToolbar>
                    <UncontrolledTooltip placement="top" target={`confirm-btn-${transaction._id}`}>
                      {t('cashbox.trans_ignore')}
                    </UncontrolledTooltip>
                  </Fragment>
                )}
              </Fragment>
            )}
          </td>
          {/* <td>
            {!unconfirmed && (
            )}
          </td> */}
        </tr>,
      );

      return list;
    }, []);
  };

  render() {
    const { t, transactions, kontoName, kontoType, month, year } = this.props;
    const { confirmed, unconfirmed, total } = calculateSaldo(
      transactions,
      false,
      month,
      year,
      `utt-${slugify(kontoName)}`,
    );

    return (
      <Row>
        <Col md={12}>
          <Card>
            <ButtonToolbar style={{ position: 'relative' }}>{this.renderAddTransactionDropdown()}</ButtonToolbar>
            <CardBody style={{ backgroundColor: 'whitesmoke' }}>
              <div className="card__title">
                <h4 className="bold-text">
                  {t('docsexp.state')}: {confirmed} / {unconfirmed} -- {total}
                </h4>
                <h5 className="subhead">{kontoName}</h5>
              </div>
              <Table hover className="table--bordered" responsive>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>{t('board.ow.date')}</th>
                    <th>{t('cashbox.source')}</th>
                    <th>{t('cashbox.amount')} (€)</th>
                    <th>{t('cashbox.purpose')}</th>
                    <th>{t('cashbox.trans_type')}</th>
                    <th>{t('board.ow.vehicle')}</th>
                    <th>{t('form.notes')}</th>
                    <th>{t('finance.modifiedBy')}</th>
                    <th></th>
                    {/* <th></th> */}
                  </tr>
                </thead>
                <tbody>{this.renderTable()}</tbody>
              </Table>
            </CardBody>
          </Card>
        </Col>
      </Row>
    );
  }
}

export default translate('common')(Konto);
