import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { translate } from 'react-i18next';
import renderIntervalDatePickerField from './form/IntervalDatePicker';

const SelectInterval = ({ handleSubmit, title, reset, t }) => (
  <form className="form" onSubmit={handleSubmit}>
    <div className="form__form-group">
      <span className="form__form-group-label">{title}</span>
      <div className="form__form-group-field">
        <Field name="interval_date" component={renderIntervalDatePickerField} />
      </div>
      <div
        className="clear-date-btn"
        size="sm"
        color="link"
        onClick={reset}
        onKeyDown={reset}
        role="button"
        tabIndex={0}
      >
        {t('board.filters.clear')}
      </div>
    </div>
  </form>
);

SelectInterval.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  reset: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

SelectInterval.defaultProps = {};

const wr = reduxForm({
  form: 'select_interval', // a unique identifier for this form
  enableReinitialize: true,
  onChange: (values, dispatch, props) => {
    props.submit();
  },
  // initialValues: { selected_date: new Date(Date.now()).toLocaleString('d MMMM, yyyy') },
})(SelectInterval);

export default translate('common')(wr);
