/* eslint-disable max-len */
import { getItem, removeItem } from 'helpers/cookies';
import axios from 'axios';
import FileSaver from 'file-saver';
import removeAccents from 'remove-accents';
import slugify from 'slugify';
import moment from 'moment-timezone';
import payload from 'helpers/jwt';
import { TIMEZONE } from '../constants/config';

function portParser(link) {
  const a = document.createElement('a');
  a.href = link;
  console.log('TRENUTNI PORT', a.port);

  if (a.port.toString() === '3500' || a.port.toString() === '3666') {
    console.log('Link OK!');
    return link;
  }

  const token = getItem('jwt');
  if (!token) {
    console.log('TOKEN MISSING ERROR!!');
    console.log('TCL: portParser -> link', link);
    return link;
  }
  const { port } = payload(token);
  if (!port) {
    console.log('PORT MISSING ERROR!!');
    console.log('TCL: portParser -> link', link);
    return link;
  }

  a.port = port;
  console.log('NOVI HREF', a.href);
  return a.href;
}

export async function POST(_link, body) {
  try {
    const link = portParser(_link);
    const response = await fetch(link, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: getItem('jwt'),
      },
      body,
    });

    // console.log('response', response);
    if (response.status === 403) {
      removeItem('jwt');
      window.location.reload();
      return { success: false };
    }
    const data = await response.json();
    // console.log('data', data);
    return data;
  } catch (error) {
    // console.log(error);
    return null;
  }
}

export async function GET(_link) {
  try {
    const link = portParser(_link);
    const response = await fetch(link, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: getItem('jwt'),
      },
    });
    if (response.status === 403) {
      removeItem('jwt');
      window.location.reload();
      return { success: false };
    }
    const data = await response.json();
    return data;
  } catch (error) {
    // console.log(error);
    return null;
  }
}

export async function PATCH(_link, body) {
  try {
    const link = portParser(_link);
    const response = await fetch(link, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      body,
    });
    const data = await response.json();
    return data;
  } catch (error) {
    // console.log(error);
    return null;
  }
}

export async function UPLOAD(_link, files) {
  try {
    const link = portParser(_link);
    const res = await axios.post(link, files, {
      headers: {
        'Content-Type': undefined,
        Authorization: getItem('jwt'),
      },
    });
    /* .then((res) => {
        // then print response status
        console.log(res.statusText);
      }); */
    return res;
  } catch (error) {
    console.log('TCL: UPLOAD -> error', error);
    return null;
  }
}

export async function DOWNLOAD(_link, type, worker) {
  console.log('TCL: DOWNLOAD -> worker', worker);
  try {
    const link = portParser(_link);
    const token = getItem('jwt');
    const fullUrl = `${link}?token=${token}&type=${type}&id=${worker.id}`;
    const res = await fetch(fullUrl, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: getItem('jwt'),
      },
    });
    const contype = await res.headers.get('content-type');
    let ending;
    switch (contype) {
      case 'image/jpeg':
        ending = '.jpg';
        break;
      case 'image/png':
        ending = '.png';
        break;
      case 'application/pdf':
        ending = '.pdf';
        break;
      default:
        ending = '.jpg';
        break;
    }
    if (res.status === 200) {
      const blob = await res.blob();
      FileSaver.saveAs(
        blob,
        `${removeAccents(worker.surname.toUpperCase())}-${removeAccents(worker.name)}-${type.toUpperCase()}${ending}`,
      );
      return true;
    }

    return false;
  } catch (e) {
    console.log('TCL: DOWNLOAD -> e', e);
  }
}

// type -> WORKER_DOCS za delavčeve dokumente
// type -> ASSIGNMENT_DOCS  za napotitvene dokumente
export async function BULK_DOWNLOAD(_link, data, type) {
  const link = portParser(_link);
  // try {
  const token = getItem('jwt');
  let fullUrl = `${link}?type=${type}&token=${token}`;
  if (type === 'WORKER_DOCS') {
    fullUrl += `&id=${data.worker.id}`;
  } else if (type === 'ASSIGNMENT_DOCS') {
    const response = await POST(`${link}/f/prepFiles`, JSON.stringify({ data, type }));

    if (response.success) {
      fullUrl = `${link}/f/bulkDownload?type=${type}&id=${response.zipfilename}`;
    }
  } else if (type === 'ANEKS_PREMATURE' || type === 'CV') {
    const response = await POST(`${link}/f/prepFiles`, JSON.stringify({ data, type }));
    if (response.success) {
      fullUrl = `${link}/f/bulkDownload?type=${type}&id=${response.filename}&wid=${
        data.worker ? data.worker._id : data._id
      }`;
    }
  } else if (type === 'ADDONS' || type === 'SATNICE' || type === 'ADDONS-W') {
    const response = await POST(`${link}/f/prepFiles`, JSON.stringify({ data, type }));
    if (response.success) {
      fullUrl = `${link}/f/bulkDownload?type=${type}&id=${response.filename}`;
    }
  }

  const res = await fetch(fullUrl, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: getItem('jwt'),
    },
  });
  console.log('TCL: functionBULK_DOWNLOAD -> res.status', res.status);
  if (res.status === 200) {
    const blob = await res.blob();
    switch (type) {
      case 'WORKER_DOCS':
        FileSaver.saveAs(
          blob,
          `${removeAccents(data.worker.surname.toUpperCase())}-${removeAccents(data.worker.name)}-DOKUMENTI.zip`,
        );
        break;
      case 'ASSIGNMENT_DOCS':
        FileSaver.saveAs(blob, `PRILOGE-${slugify(data.ow)}.zip`);
        break;
      case 'ANEKS_PREMATURE':
        FileSaver.saveAs(
          blob,
          `${removeAccents(data.worker.surname.toUpperCase())}-${removeAccents(data.worker.nameOnly)}-${moment(
            data.misc.dateIssued,
          )
            .tz(TIMEZONE)
            .format('DD-MM-YYYY')}-ANEKS.docx`,
        );
        break;
      case 'CV':
        console.log('DOWNLOADAJ CV');
        FileSaver.saveAs(blob, `CV-${removeAccents(data.surname.toUpperCase())}-${removeAccents(data.name)}.docx`);
        break;
      case 'ADDONS':
      case 'ADDONS-W':
      case 'SATNICE':
        console.log(`DOWNLOADAJ PLAČE (${type})`);

        FileSaver.saveAs(
          blob,
          `PLACE-${type}-${removeAccents(data.companyName.toUpperCase())}-${data.month}-${data.year}.xlsx`,
        );
        break;
      default:
        break;
    }

    return true;
  }

  return false;
  /* } catch (error) {
    console.log('TCL: functionBULK_DOWNLOAD -> error', error);
  } */
}

/* export function SSE(link) {
  console.log('TCL: SSE -> link', link);
  const eventSource = new EventSource(link);
  eventSource.onmessage = (e) => {
    console.log('TCL: SSE -> e', e.data);
  };
} */
