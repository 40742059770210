import { getItem } from '../../helpers/cookies';

export const SET_TOKEN = 'SET_TOKEN';
export const SET_USER = 'SET_USER';
export const SET_TOKEN_FETCH_ERROR = 'SET_TOKEN_FETCH_ERROR';
export const LOGOUT = 'LOGOUT';

const initialState = {
  token: getItem('jwt'),
  error: null,
  user: null,
};

export default function(state = initialState, action) {
  switch (action.type) {
    case SET_TOKEN:
      return { ...state, token: action.token, error: null };
    case SET_USER:
      return { ...state, user: action.user, error: null };
    case SET_TOKEN_FETCH_ERROR:
      return { ...state, token: null, error: action.error };
    case LOGOUT:
      return { ...state, token: null, error: null };
    default:
      return state;
  }
}
