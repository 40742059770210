import React, { Component } from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import { Modal, ModalBody, ModalHeader, ModalFooter, FormGroup, Button, Input } from 'reactstrap';
import PropTypes from 'prop-types';
import { updateNote } from 'redux/actions/owActions';

class EditNoteModal extends Component {
  static propTypes = {
    note: PropTypes.string,
    isOpen: PropTypes.bool.isRequired,
    toggle: PropTypes.func.isRequired,
    id: PropTypes.string.isRequired,
    dispatch: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
  };

  static defaultProps = {
    note: '',
  };

  constructor(props) {
    super(props);

    this.state = {
      note: props.note,
    };
  }

  handleNoteChange = e => {
    this.setState({ note: e.target.value });
  };

  handleSave = () => {
    const { id, dispatch } = this.props;
    const { note } = this.state;
    dispatch(updateNote(id, note));
  };

  render() {
    const { toggle, isOpen, t } = this.props;
    const { note } = this.state;
    return (
      <Modal isOpen={isOpen} toggle={toggle}>
        <ModalHeader toggle={toggle}>
          <h4 className="bold-text  modal__title">{t('board.notes.modalTitle')}</h4>
        </ModalHeader>
        <ModalBody>
          <FormGroup>
            <Input type="textarea" name="note" id="noteInpute" value={note} onChange={this.handleNoteChange} />
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={this.handleSave}>
            {t('board.notes.modalButton')}
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export default translate('common')(connect()(EditNoteModal));
