import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-i18next';
import shortid from 'shortid';
import { connect } from 'react-redux';
import { Card, CardBody, Table, Popover, PopoverHeader, PopoverBody, UncontrolledTooltip } from 'reactstrap';
import * as i18nIsoCountries from 'i18n-iso-countries';
import { DataListProps } from 'shared/prop-types/ReducerProps';
import CarIcon from 'mdi-react/CarIcon';
import BoardTableRow from './BoardTableRow';
import PlaceholderTableRow from './PlaceholderTableRow';
import Note from './Note';

i18nIsoCountries.registerLocale(require('i18n-iso-countries/langs/sl.json'));
i18nIsoCountries.registerLocale(require('i18n-iso-countries/langs/de.json'));

class Board extends Component {
  static propTypes = {
    ow: PropTypes.object.isRequired,
    isHome: PropTypes.bool,
    dataListStore: DataListProps.isRequired,
    t: PropTypes.func.isRequired,
  };

  static defaultProps = {
    isHome: false,
  };

  boardInfo = () => {
    const {
      ow: { live, staged },
    } = this.props;
    const worksite = live ? live.worksite : staged.worksite;
    if (!worksite) {
      return '';
    }
    const boardInfo = worksite && worksite.boardInfo ? ` / ${worksite.boardInfo}` : '';
    return `${worksite.address}, ${worksite.zip} ${worksite.city} ${
      worksite.fake
        ? ''
        : i18nIsoCountries.getName(worksite.countryId, localStorage.getItem('lang') || 'sl').toUpperCase()
    }${boardInfo}`;
  };

  renderTable = (employees, ow, isStaged) =>
    employees.reduce((list, employee) => {
      /* if (employee.banned) {
        return list;
      } */
      list.push(
        <BoardTableRow
          key={`${employee._id}${ow._id}${shortid.generate()}`}
          employee={employee}
          ow={ow}
          isStaged={isStaged}
        />,
      );
      return list;
    }, []);

  renderPlaceholders = () => {
    const {
      ow: {
        staged: { placeholders },
      },
      dataListStore: { professions },
    } = this.props;
    if (!placeholders) {
      return '';
    }
    return placeholders.map(placeholder => {
      const profession = professions.find(prof => prof.value === placeholder.professionId);
      return <PlaceholderTableRow placeholder={placeholder} profession={profession} key={placeholder._id} />;
    });
  };

  renderOWVehicles = (vehicles, owId) => {
    const id = `ow-vehicles-${owId}`;
    return (
      <Fragment>
        <CarIcon id={id} color="red" className="ow-vehicle" />
        <UncontrolledTooltip placement="top" target={id}>
          <strong>Vozila na gradbišču</strong>
          <ul>
            {vehicles.map(vehicle => (
              <li>{`${vehicle.licensePlate.toUpperCase()} ${vehicle.brand}`}</li>
            ))}
          </ul>
        </UncontrolledTooltip>
      </Fragment>
    );
  };

  renderLive = () => {
    const {
      ow: { live },
      isHome,
      t,
    } = this.props;
    if (!live) return '';
    //console.log('Board -> renderLive -> live', live);

    const { employees, worksite, orderer, note, vehicles } = live;
    return (
      <Fragment>
        <div className="card__title">
          <div className="row">
            <div className="col-9 col-sm-12">
              <h5 className="bold-text">{`${worksite ? worksite.title : t('board.ow.missing')}`}</h5>
              <h5 className="subhead">
                {!isHome && (
                  <Fragment>
                    {this.boardInfo()}
                    {worksite && worksite.workOrder && (
                      <Fragment>
                        <br /> {t('board.ow.workorder')}: {worksite.workOrder}
                      </Fragment>
                    )}
                    <br />
                    {t('board.ow.orderer')}: {orderer ? orderer.title : t('board.ow.missing')}
                  </Fragment>
                )}
              </h5>
              <Note note={note} id={`Live${live._id}`} owId={live._id} />
              {vehicles && vehicles.length > 0 && this.renderOWVehicles(vehicles, live._id)}
            </div>
            {/* <div className="col-3 hidden-desktop">{this.isCollapsed() ? <DownIcon /> : <UpIcon />}</div> */}
          </div>
        </div>
        <div className="tabla-table__wrapper">
          <Table className="table--bordered tabla-table" responsive>
            <thead>
              <tr>
                <th>{t('board.ow.worker')}</th>
                <th>{t('board.ow.accommodation')}</th>
                <th>{t('board.ow.date')}</th>
                <th>{t('board.ow.vehicle')}</th>
                <th>{t('board.ow.a1Exp')}</th>
              </tr>
            </thead>
            <tbody>{employees && this.renderTable(employees, live, false)}</tbody>
          </Table>
        </div>
      </Fragment>
    );
  };

  renderStaged = () => {
    const {
      ow: { staged, live },
      isHome,
      t,
    } = this.props;
    if (!staged) return '';
    const { orderer, worksite, placeholders, employees, note } = staged;
    return (
      <div className="staged-bg">
        <div className="card__title">
          <div className="row">
            <div className="col-9 col-sm-12">
              {live ? (
                <h5 className="bold-text">{t('board.ow.unconfirmed')}</h5>
              ) : (
                <Fragment>
                  {/* eslint-disable-next-line max-len */}
                  <h5 className="bold-text">
                    {`${worksite ? worksite.title : t('board.ow.missing')} (${t('board.ow.unconfirmed')})`}
                  </h5>
                  <h5 className="subhead">
                    {!isHome && (
                      <Fragment>
                        {this.boardInfo()}
                        {worksite && worksite.workOrder && (
                          <Fragment>
                            <br /> {t('board.ow.workorder')}: {worksite.workOrder}
                          </Fragment>
                        )}
                        <br />
                        {t('board.ow.orderer')}: {orderer ? orderer.title : t('board.ow.missing')}
                      </Fragment>
                    )}
                  </h5>
                  {!live && <Note note={note} id={`Staged${staged._id}`} owId={staged._id} />}
                </Fragment>
              )}
            </div>
          </div>
        </div>
        <div className="tabla-table__wrapper">
          <Table className="table--bordered tabla-table" responsive>
            <thead>
              <tr>
                <th>{t('board.ow.worker')}</th>
                <th>{t('board.ow.accommodation')}</th>
                <th>{t('board.ow.date')}</th>
                <th>{t('board.ow.vehicle')}</th>
                <th>{t('board.ow.a1Exp')}</th>
              </tr>
            </thead>
            <tbody>
              {employees && this.renderTable(employees, staged, true)}
              {placeholders && this.renderPlaceholders()}
            </tbody>
          </Table>
        </div>
      </div>
    );
  };

  render() {
    return (
      <Card>
        <CardBody>
          {this.renderLive()}
          {this.renderStaged()}
        </CardBody>
      </Card>
    );
  }
}

const wr = connect(state => ({
  dataListStore: state.dataList,
}))(Board);

export default translate('common')(wr);
