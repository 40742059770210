import React from 'react';
import { Card, CardBody, Col, Table } from 'reactstrap';
import PropTypes from 'prop-types';
import { translate } from 'react-i18next';

const renderTable = (providers, onRowSelect) =>
  providers.map(provider => (
    <tr key={provider._id} id={provider._id} onClick={() => onRowSelect(provider._id)}>
      <td>{provider.title}</td>
      <td>{provider.tel}</td>
      <td>{provider.email}</td>
      <td>{provider.contact}</td>
    </tr>
  ));

const PonudnikiTable = ({ title, providers, onRowSelect, t }) => (
  <Col md={12} lg={12} xl={12}>
    <Card>
      <CardBody>
        <div className="card__title">
          <h5 className="bold-text">{title}</h5>
          {/* <h5 className="subhead">lol</h5> */}
        </div>
        <Table hover className="table--bordered" responsive>
          <thead>
            <tr>
              <th>{t('misc.title')}</th>
              <th>{t('form.tel')}</th>
              <th>Email</th>
              <th>{t('misc.contact')}</th>
            </tr>
          </thead>
          <tbody>{renderTable(providers, onRowSelect)}</tbody>
        </Table>
      </CardBody>
    </Card>
  </Col>
);

PonudnikiTable.propTypes = {
  title: PropTypes.string.isRequired,
  providers: PropTypes.arrayOf(PropTypes.object).isRequired,
  onRowSelect: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default translate('common')(PonudnikiTable);
