import React, { Component } from 'react';
import {
  Col,
  Container,
  Row,
  ButtonToolbar,
  Card,
  CardBody,
  Progress,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from 'reactstrap';
import PropTypes from 'prop-types';

import NotificationSystem from 'rc-notification';
import { connect } from 'react-redux';
import ChevronDownIcon from 'mdi-react/ChevronDownIcon';
import { translate } from 'react-i18next';
import { API_LINK } from '../../constants/config';
import VozilaTable from './components/VozilaTable';
import { BasicNotification } from '../../shared/components/Notification';
import SelectDropdown from '../../shared/components/SelectDropdown';
import VozilaModal from './components/VozilaModal';
import { POST } from '../../helpers/agent';
import VozilaSheet from './components/VozilaSheet';
import getRoles from '../../constants/roles';
import { VehiclesProps, EmployeesProps, AppStatusProps } from '../../shared/prop-types/ReducerProps';
import { TMZFY } from '../../helpers/functions';

// const API_VEHICLES = '/v/getall';
const API_VEHICLES_GET_ONE = '/v/getOneById';
const API_VEHICLE_DEASSIGN = '/v/deassign';
// const API_EMPLOYEES_NAMES = '/e/getnames';

let notificationLU = null;
let notificationRU = null;
let notificationTC = null;

const showNotification = ({ notification, position }) => {
  switch (position) {
    case 'left-up':
      notificationLU.notice({
        content: notification,
        duration: 5,
        closable: true,
        style: { top: 0, left: 240 },
        className: position,
      });
      break;
    case 'right-up':
      notificationRU.notice({
        content: notification,
        duration: 5,
        closable: true,
        style: { top: 0, left: 'calc(100vw - 100%)' },
        className: position,
      });
      break;
    default:
      notificationTC.notice({
        content: notification,
        duration: 5,
        closable: true,
        style: { top: 0, left: 0 },
        className: position,
      });
      break;
  }
};

const searchCriteria = t => [
  { value: 'license', label: t('vehicles.license') },
  { value: 'provider', label: t('vehicles.provider') },
];
const renderCriteria = (criteria, handleClick) => {
  console.log('TCL: criteria', criteria);

  return criteria.map(crit => (
    /* const lang = localStorage.getItem('lang') || 'sl';
    let theLabel = lang === 'sl' ? crit.label : null;

    if (!theLabel) {
      theLabel = crit.label === {t('vehicle.license')} ? 'Registrska-DE' : 'Dobavitelj-DE';
    } */
    <DropdownItem key={crit.value} onClick={() => handleClick(crit.value, crit.label)}>
      {crit.label}
    </DropdownItem>
  ));
};

class VozilaPage extends Component {
  static propTypes = {
    vehiclesState: VehiclesProps.isRequired,
    workersState: EmployeesProps.isRequired,
    appStatus: AppStatusProps.isRequired,
    t: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      vehicles: [],
      vehiclesFull: [],
      people: [],
      ddItems: [],
      licences: [],
      providers: [],
      showEdit: false,
      progressNum: 0,
      editVehicle: null,
      selectedCriteriaId: searchCriteria(props.t)[0].value,
      selectedCriteriaLabel: searchCriteria(props.t)[0].label,
      ROLES: getRoles(),
    };

    this.onRowSelect = this.onRowSelect.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.showNotif = this.showNotif.bind(this);
    this.fetchData = this.fetchData.bind(this);
    this.handleProgress = this.handleProgress.bind(this);
    this.handleDropdownSubmit = this.handleDropdownSubmit.bind(this);
    this.handleDeassign = this.handleDeassign.bind(this);
    this.handleCriteriaChange = this.handleCriteriaChange.bind(this);
  }

  componentDidMount() {
    this._mounted = true;
    NotificationSystem.newInstance({}, n => (notificationLU = n));
    NotificationSystem.newInstance({}, n => (notificationRU = n));
    NotificationSystem.newInstance({}, n => (notificationTC = n));

    const { appStatus } = this.props;
    if (appStatus.requiredDataLoaded) {
      console.log('VEHICLES -> Data loaded.. continue...');
      this.fetchData();
    }
  }

  componentDidUpdate(prevProps) {
    const { appStatus } = this.props;
    if (appStatus.requiredDataLoaded && !prevProps.appStatus.requiredDataLoaded) {
      console.log('VEHICLES -> Data finished loading.. continue...');
      this.fetchData();
    }

    if (!appStatus.appUpdating && prevProps.appStatus.appUpdating) {
      console.log('VEHICLES -> Data finished UPDATING.. refresh table...');
      this.fetchData();
    }
  }

  componentWillUnmount() {
    this._mounted = false;
    notificationLU.destroy();
    notificationRU.destroy();
    notificationTC.destroy();
  }

  async onRowSelect(values) {
    const { ROLES } = this.state;
    const { t } = this.props;
    if (ROLES.vehicles && ROLES.vehicles.list && ROLES.vehicles.list.readonly) {
      this.showNotif({
        title: `${t('misc.forbidden')}!`,
        message: `${t('misc.noright')}!`,
        success: false,
      });
      return;
    }

    const that = this;
    const body = JSON.stringify({ id: values });
    const data = await POST(API_LINK + API_VEHICLES_GET_ONE, body);
    if (data.success) {
      console.log('vehicle', data.vehicle);
      /* const empl = {
        ...data.employee,
        birthDate: moment(data.employee.birthDate).valueOf(), //format('DD. MM. YYYY'),
      }; */
      // console.log('empl', empl);
      that.setState({ editVehicle: data.vehicle, showEdit: true });
    }
  }

  _mounted = false;

  fetchData() {
    const { vehiclesState, workersState } = this.props;

    const licenseList = [];
    const providersList = [];
    const provs = [];
    vehiclesState.vehicles.forEach(vehi => {
      const vehData = {
        value: vehi._id,
        label: vehi.licensePlate,
      };
      licenseList.push(vehData);

      if (!provs.includes(vehi.provider)) {
        const provData = {
          value: vehi.provider.toLowerCase(),
          label: vehi.provider,
        };
        providersList.push(provData);
        provs.push(vehi.provider);
      }
    });

    this.setState({
      vehicles: vehiclesState.vehicles,
      vehiclesFull: vehiclesState.vehicles,
      licences: licenseList,
      providers: providersList,
      ddItems: licenseList,
      people: workersState.employeesNames,
    });
  }

  handleProgress(addProgress) {
    const { progressNum } = this.state;
    this.setState({ progressNum: progressNum + addProgress });
    const that = this;
    if (progressNum + addProgress >= 100) {
      setTimeout(() => {
        that.handleProgress(-(progressNum + addProgress));
      }, 1000);
    }
  }

  showNotif = ({ title, message, success }) => {
    // const title = isEdit ? 'Uredi delavca' : 'Dodaj delavca';
    /*     const successMessage = isEdit ? `Delavec ${name} uspešno posodobljen!` : `Delavec ${name} uspešno dodan!`;
    const errorMessage = isEdit ? `Delavec ${name} ni uspešno posodobljen!` : `Delavec ${name} ni uspešno dodan!`; */
    const color = success ? 'success' : 'danger';
    showNotification({
      notification: <BasicNotification color={color} title={title} message={message} />,
      position: 'right-up',
    });
  };

  handleCloseModal(data) {
    if (data) this.showNotif(data);
    this.setState({ showEdit: false });
    if (data) this.fetchData();
  }

  handleDropdownSubmit(values) {
    try {
      if (values.selected_dropdown === null) {
        this.fetchData();
        return;
      }
      // console.log('ID:', values.selected_dropdown.value);
      const { vehiclesFull, selectedCriteriaId } = this.state;
      const id = values.selected_dropdown.value;
      const filtered = [];
      vehiclesFull.forEach(veh => {
        if (selectedCriteriaId === 'license') {
          if (veh._id === id) {
            filtered.push(veh);
          }
        } else if (selectedCriteriaId === 'provider') {
          if (veh.provider.toUpperCase() === id.toUpperCase()) {
            filtered.push(veh);
          }
        }
      });
      this.setState({ vehicles: filtered });
      // console.log('FILTER:', filtered);
    } catch (error) {
      // console.log(error);
    }
  }

  async handleDeassign(id, license) {
    const body = JSON.stringify({ id });
    const data = await POST(API_LINK + API_VEHICLE_DEASSIGN, body);
    if (data.success) {
      this.showNotif({
        title: 'Posodobi vozilo',
        message: `Vozilo z registersko ${license} je doma!`,
        success: true,
      });
      // this.setState({ editVehicle: data.vehicle, showEdit: true });
    } else {
      this.showNotif({
        title: 'Posodobi vozilo',
        message: `Vozilo z registersko ${license} ni uspešno posodobljeno!`,
        success: false,
      });
    }

    this.fetchData();
  }

  async handleCriteriaChange(criteriaId, criteriaLabel) {
    if (criteriaId === 'license') {
      this.setState({ ddItems: this.state.licences });
    } else if (criteriaId === 'provider') {
      this.setState({ ddItems: this.state.providers });
    }
    this.setState({
      selectedCriteriaLabel: criteriaLabel,
      selectedCriteriaId: criteriaId,
    });
  }

  getLatestDocData = (docs, type) => {
    const typeDocs = docs.filter(d => !d.deleted && d.type === type);
    if (!typeDocs || typeDocs.length === 0) return {};

    const sorted = typeDocs.sort((a, b) => new Date(b.startsAt).getTime() - new Date(a.startsAt).getTime());
    const latest = sorted.shift();

    if (type === 'tech' || type === 'ins') {
      return {
        num: latest.num,
        start: TMZFY(latest.startsAt),
        end: TMZFY(latest.expAt),
      };
    }

    return {
      first: TMZFY(latest.firstReg),
      start: TMZFY(latest.startsAt),
      end: TMZFY(latest.expAt),
    };
  };

  mapDocsDataForSheetExport = vehicles => {
    return vehicles.map(vehicle => {
      const { docs } = vehicle;
      const tech = null;
      const ins = null;
      const reg = null;
      if (!docs || docs.length === 0) {
        return {
          ...vehicle,
          tech,
          ins,
          reg,
        };
      }

      return {
        ...vehicle,
        tech: this.getLatestDocData(docs, 'tech'),
        ins: this.getLatestDocData(docs, 'ins'),
        reg: this.getLatestDocData(docs, 'reg'),
      };
    });
  };

  render() {
    const { vehicles, progressNum, showEdit, editVehicle, people, selectedCriteriaLabel, ddItems, ROLES } = this.state;
    console.log('render -> vehicles', vehicles);

    const { t } = this.props;

    let showBtn = ROLES.full;

    if (!showBtn) {
      showBtn = !(ROLES.vehicles && ROLES.vehicles.list && ROLES.vehicles.list.readonly);
    }

    return (
      <Container className="dashboard">
        <Row>
          <Col lg={12}>
            <h3 className="page-title">{t('vehicles.title')}</h3>
          </Col>
          <Col lg={12}>
            <Row>
              <Col lg={6}>
                <Card>
                  <CardBody>
                    <Row>
                      <Col lg={4}>
                        <UncontrolledDropdown style={{ marginTop: '23px' }}>
                          <DropdownToggle className="icon icon--right" size="sm" outline>
                            <p>
                              {selectedCriteriaLabel} <ChevronDownIcon />
                            </p>
                          </DropdownToggle>
                          <DropdownMenu className="dropdown__menu">
                            {renderCriteria(searchCriteria(t), this.handleCriteriaChange)}
                          </DropdownMenu>
                        </UncontrolledDropdown>
                      </Col>
                      <Col lg={8}>
                        <SelectDropdown
                          title={t('vehicles.find')}
                          items={ddItems}
                          onSubmit={this.handleDropdownSubmit}
                        />
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col lg={{ size: 4, offset: 2 }}>
                <ButtonToolbar className="button-toolbar">
                  <VozilaModal
                    btn={t('vehicles.add')}
                    icon="add"
                    title={t('vehicles.add')}
                    hasBtn={showBtn}
                    show={false}
                    onClose={this.handleCloseModal}
                    people={people}
                  />
                  <VozilaSheet
                    btn={t('form.exportExcel')}
                    disabled={!(progressNum === 0 && vehicles.length > 0)}
                    data={this.mapDocsDataForSheetExport(vehicles)}
                  />
                </ButtonToolbar>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          {progressNum > 0 ? (
            <div className="progress-wrap progress-wrap--middle progress--align">
              <Progress animated value={progressNum} />
            </div>
          ) : null}
          <VozilaTable
            title={t('vehicles.title')}
            vehicles={vehicles}
            onRowSelect={this.onRowSelect}
            onDeassign={this.handleDeassign}
          />
        </Row>

        <VozilaModal
          btn="Uredi vozilo"
          icon="edit"
          title="Uredi vozilo"
          hasBtn={false}
          show={showEdit}
          onClose={this.handleCloseModal}
          editData={editVehicle}
          people={people}
        />
      </Container>
    );
  }
}

const wr = connect(state => ({
  vehiclesState: state.vehicles,
  workersState: state.employees,
  appStatus: state.appStatus,
}))(VozilaPage);

export default translate('common')(wr);
