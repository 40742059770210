/* eslint-disable react/jsx-indent */
import React, { Component } from 'react';
import Geosuggest from 'react-geosuggest';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import { Form, Label, Input, FormGroup, CardTitle } from 'reactstrap';
import PropTypes from 'prop-types';
import { WorksitesProps } from '../../../../../../shared/prop-types/ReducerProps';
import SelectDropdown from '../../../../../../shared/components/SelectDropdown';

class Location extends Component {
  static propTypes = {
    onChange: PropTypes.func.isRequired,
    worksites: WorksitesProps.isRequired,
    t: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      worksiteSearch: true,
      selectedLocation: null,
      selectedWorksiteId: null,
    };
  }

  handleChange = () => {
    const { worksiteSearch, selectedLocation, selectedWorksiteId } = this.state;
    console.log('worksiteSearch', worksiteSearch, selectedLocation, selectedWorksiteId);
    this.props.onChange(worksiteSearch, selectedWorksiteId, selectedLocation ? selectedLocation.description : null);
  };

  handleSuggestSelect = location => {
    this.setState(
      {
        selectedLocation: location,
      },
      this.handleChange,
    );
  };

  handleOptionChange = changeEvent => {
    this.setState({
      worksiteSearch: changeEvent.target.value === 'true',
    });
  };

  handleDropdownSubmit = values => {
    const id = values.selected_dropdown ? values.selected_dropdown.value : null;
    this.setState(
      {
        selectedWorksiteId: id,
      },
      this.handleChange,
    );
  };

  render() {
    const { worksiteSearch } = this.state;
    const {
      worksites: { titlesWorksites },
      t,
    } = this.props;
    return (
      <div className="location-search">
        <CardTitle>{t('places.filters.locationTitle')}</CardTitle>
        <div className="location-search__wrapper">
          <Form className="location-search__search-select">
            <FormGroup check>
              <Label check>
                <Input
                  type="radio"
                  name="radio1"
                  value="true"
                  checked={!!worksiteSearch}
                  onChange={this.handleOptionChange}
                />
                {t('places.filters.worksiteRadio')}
              </Label>
            </FormGroup>

            <FormGroup check>
              <Label check>
                <Input
                  type="radio"
                  name="radio2"
                  value="false"
                  checked={!worksiteSearch}
                  onChange={this.handleOptionChange}
                />
                {t('places.filters.addressRadio')}
              </Label>
            </FormGroup>
          </Form>
          <div className="location-search__input">
            {worksiteSearch ? (
              <SelectDropdown
                placeholder={t('places.filters.worksitePlaceholder')}
                items={titlesWorksites}
                onSubmit={this.handleDropdownSubmit}
              />
            ) : (
              <Geosuggest
                placeholder={t('places.filters.addressPlaceholder')}
                onSuggestSelect={this.handleSuggestSelect}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default translate('common')(
  connect(({ worksites }) => ({
    worksites,
  }))(Location),
);
