import React, { Component } from 'react';
import PropTypes from 'prop-types';
import getDaysInMonth from 'helpers/getDaysInMonth';
import { Table, Card, CardBody, Button, UncontrolledTooltip } from 'reactstrap';
import { translate } from 'react-i18next';
import moment from 'moment-timezone';
import shortid from 'shortid';
import Switch from 'react-toggle-switch';
import stable from 'stable';
import { TIMEZONE, CONFIG } from '../../../../constants/config';
import fix, { commify } from '../../../../helpers/floatParser';

const isWeekend = day => {
  const dayNum = new Date(day).getDay();
  return dayNum === 6 || dayNum === 0;
};

class OWTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // show day + nigh hours
      switched: true,
    };
    this.handleScroll = this.handleScroll.bind(this);
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll() {
    if (this.tableHead !== null) {
      if (window.pageYOffset > 355) {
        // console.log('halloo', window.pageYOffset);
        this.tableHead.className = 'sticky';
        this.tableHead2.className = '';
      } else {
        this.tableHead.className = '';
        this.tableHead2.className = 'hidden-head';
      }
    }
  }

  toggleSwitch = () => {
    this.setState(prevState => ({
      switched: !prevState.switched,
    }));
  };

  generateColMonthHeads(month, year) {
    const daysInMonths = getDaysInMonth(month, year);
    return daysInMonths.map(day => {
      const weekend = isWeekend(day);
      return <col className={weekend ? 'weekend-col-table' : ''} />;
    });
  }

  generateMonthHeads(month, year) {
    const daysInMonths = getDaysInMonth(month, year);
    return daysInMonths.map(day => (
      <td key={day.getTime().toString()}>
        <p>
          {moment(day)
            .tz(TIMEZONE)
            .format('ddd')}
        </p>
        <p>
          {moment(day)
            .tz(TIMEZONE)
            .format('D.M.')}
        </p>
      </td>
    ));
  }

  renderWorkdays(workdays) {
    const { switched } = this.state;
    return workdays.map(workday => {
      const theHours = !switched
        ? `${fix(workday.daySum)}/${fix(workday.nightSum)}`
        : `${fix(workday.daySum + workday.nightSum)}`;

      return (
        <td key={shortid.generate()}>
          {workday.daySum !== 0 || workday.nightSum !== 0 ? (
            <strong style={{ color: 'black' }}>{commify(theHours)}</strong>
          ) : (
            <span style={{ color: 'lightgray' }}>{!switched ? '0/0' : '0'}</span>
          )}
        </td>
      );
    });
  }

  renderTable(list, onSelectOw) {
    const LEX_COMP_ORDERER_TITLE = (a, b) => CONFIG.COLLATOR.compare(a.ordererTitle, b.ordererTitle);
    const LEX_COMP_WORKSITE_TITLE = (a, b) => CONFIG.COLLATOR.compare(a.worksiteTitle, b.worksiteTitle);
    const worksitesSorted = stable(list, LEX_COMP_WORKSITE_TITLE);
    const orderersSorted = stable(worksitesSorted, LEX_COMP_ORDERER_TITLE);

    const dailySumsIndex = orderersSorted.findIndex(e => e.ordererId === 'sum' && e.worksiteId === 'all');
    if (dailySumsIndex > -1) {
      const dailySums = orderersSorted[dailySumsIndex];
      orderersSorted.splice(dailySumsIndex, 1);
      orderersSorted.push(dailySums);
    }

    return orderersSorted.map((ow, index) => {
      const id = shortid.generate();
      const isLast = index === orderersSorted.length - 1;
      return (
        <tr key={id} id={id} className={isLast ? 'smaller-row' : ''}>
          <td>
            {!isLast ? (
              <Button
                size="sm"
                color="primary"
                outline
                style={{ padding: '3px', marginLeft: '5px' }}
                onClick={() =>
                  onSelectOw({
                    ordererId: ow.ordererId,
                    worksiteId: ow.worksiteId,
                    ordererTitle: ow.ordererTitle,
                    worksiteTitle: ow.worksiteTitle,
                  })
                }
              >
                {index + 1}
              </Button>
            ) : (
              index + 1
            )}
          </td>
          <td>{ow.ordererTitle}</td>
          <td>{ow.worksiteTitle}</td>
          {this.renderWorkdays(ow.workdays)}
          <td>
            <strong style={{ color: 'black' }}>{commify(fix(ow.totalDaySum))}</strong>
          </td>
          <td>
            <strong style={{ color: 'black' }}>{commify(fix(ow.totalNightSum))}</strong>
          </td>
          <td>
            <strong style={{ color: 'black' }}>{commify(fix(ow.totalTransferSum))}</strong>
          </td>
          <td>
            <strong style={{ color: 'black' }}>{commify(fix(ow.totalSum))}</strong>
          </td>
        </tr>
      );
    });
  }

  render() {
    const { data, month, year, onSelectOw, t } = this.props;

    return (
      <Card className="mt-2">
        <CardBody>
          <div className="card__title">
            <h5 style={{ color: 'coral' }}>
              {t('workerstable.sum')} ({t('form.all')}): <strong>{commify(fix(data.total))}</strong>
            </h5>
            <h5 style={{ color: 'black' }}>
              {t('workerstable.sum')} ({t('workerstable.day')}): <strong>{commify(fix(data.totalDay))}</strong>
            </h5>
            <h5 style={{ color: 'black' }}>
              {t('workerstable.sum')} ({t('workerstable.night')}): <strong>{commify(fix(data.totalNight))}</strong>
            </h5>
            <h5 className="subhead" style={{ color: 'black' }}>
              {t('workerstable.sum')} ({t('workerstable.day')} / {t('calcs.transfer')}):{' '}
              <strong>{commify(fix(data.totalTransferDay))}</strong>
            </h5>
            <h5 className="subhead" style={{ color: 'black' }}>
              {t('workerstable.sum')} ({t('workerstable.night')} / {t('calcs.transfer')}):{' '}
              <strong>{commify(fix(data.totalTransferNight))}</strong>
            </h5>
          </div>

          <Switch onClick={this.toggleSwitch} on={this.state.switched} className="night-switch" />
          {/* eslint-disable */}
          <div className="switchToolTip" id="SwitchToolTip" onClick={this.toggleSwitch} />
          {/* eslint-enable */}

          <UncontrolledTooltip placement="top" target="SwitchToolTip">
            {this.state.switched ? 'Prikazujem samo dnevne ure' : 'Prikazujem dnevne in nočne ure'}
          </UncontrolledTooltip>
          <Table className="table--bordered custom-style" striped>
            <colgroup>
              <col />
              <col />
              <col />
              {this.generateColMonthHeads(month, year)}
            </colgroup>
            <thead ref={ref => (this.tableHead = ref)}>
              <tr>
                <td>{t('form.num')}</td>
                <td>{t('board.ow.orderer')}</td>
                <td>{t('board.filters.worksite')}</td>
                {this.generateMonthHeads(month, year)}
                <td>
                  {t('calcs.sum')} ({t('calcs.d')})
                </td>
                <td>
                  {t('calcs.sum')} ({t('calcs.n')})
                </td>
                <td>
                  {t('calcs.sum')} ({t('calcs.p')})
                </td>
                <td>{t('calcs.sum')}</td>
              </tr>
            </thead>
            <thead ref={ref => (this.tableHead2 = ref)} className="hidden-head">
              <tr>
                <td>{t('form.num')}</td>
                <td>{t('board.ow.orderer')}</td>
                <td>{t('board.filters.worksite')}</td>
                {this.generateMonthHeads(month, year)}
                <td>
                  {t('calcs.sum')} ({t('calcs.d')})
                </td>
                <td>
                  {t('calcs.sum')} ({t('calcs.n')})
                </td>
                <td>
                  {t('calcs.sum')} ({t('calcs.p')})
                </td>
                <td>{t('calcs.sum')}</td>
              </tr>
            </thead>
            <tbody>{data.list.length > 0 ? this.renderTable(data.list, onSelectOw) : null}</tbody>
          </Table>
        </CardBody>
      </Card>
    );
  }
}

OWTable.propTypes = {
  data: PropTypes.object.isRequired,
  month: PropTypes.number.isRequired,
  year: PropTypes.number.isRequired,
  onSelectOw: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default translate('common')(OWTable);
