const validate = values => {
  const errors = {};
  if (!values.title) {
    errors.title = 'To polje mora biti izpolnjeno!';
  }
  if (!values.address) {
    errors.address = 'To polje mora biti izpolnjeno!';
  }
  if (!values.zip) {
    errors.zip = 'To polje mora biti izpolnjeno!';
  }
  if (!values.city) {
    errors.city = 'To polje mora biti izpolnjeno!';
  }
  if (!values.countryId) {
    errors.countryId = 'To polje mora biti izpolnjeno!';
  }

  return errors;
};

export default validate;
