import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import ReactExport from 'react-data-export';
import { Button } from 'reactstrap';
import { TIMEZONE } from '../../../constants/config';
import { translateDocName } from '../../../translations/dynamicTranslator';

/* eslint-disable */
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
/* eslint-enable */

const DownloadButton = title => (
  <Button size="sm" color="primary" style={{ marginBottom: '10px', marginLeft: '15px' }}>
    {title}
  </Button>
);

const generateHeaders = title => {
  const firstPart = [
    {
      title,
    },
    {
      title: '',
    },
    {
      title: '',
    },
    {
      title: '',
    },
    {
      title: '',
    },
  ];

  const multiDataSet = [
    {
      columns: firstPart,
      data: [],
    },
  ];
  return multiDataSet;
};

const generateData = dataAll => {
  //console.log('TCL: generateData -> dataAll', dataAll);

  const data = [];

  const emptyRow = [
    {
      value: '',
    },
    {
      value: '',
    },
    {
      value: '',
    },
    {
      value: '',
    },
    {
      value: '',
    },
  ];
  data.push(emptyRow);

  const headers = [
    {
      value: 'Delavec / Vozilo',
    },
    {
      value: 'Tip',
    },
    {
      value: 'Dokument',
    },
    {
      value: 'Potek',
    },
    {
      value: 'Poteče čez (dni)',
    },
  ];

  data.push(headers);

  for (let i = 0; i < dataAll.length; i += 1) {
    const doc = dataAll[i];

    const name = doc.type === 'vehicle' ? doc.vehicle.name : doc.worker.name;
    const type = doc.type === 'vehicle' ? 'Vozilo' : 'Delavec';
    const docName = translateDocName(doc.doc.name, localStorage.getItem('lang') || 'sl');

    const docRow = [
      {
        value: name,
      },
      {
        value: type,
      },
      {
        value: docName,
      },
      {
        value: doc.doc.expAt,
      },
      {
        value: doc.diff,
      },
    ];
    data.push(docRow);
  }

  return data;
};

class DokumentsExpSheet extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { data, btn, title } = this.props;

    const headers = generateHeaders(title);
    headers[0].data = generateData(data);

    return (
      <ExcelFile element={DownloadButton(btn)} filename={title}>
        <ExcelSheet dataSet={headers} name="Potek dokumentov" />
      </ExcelFile>
    );
  }
}

DokumentsExpSheet.propTypes = {
  btn: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  title: PropTypes.string.isRequired,
};

DokumentsExpSheet.defaultProps = {};

export default DokumentsExpSheet;
