import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import ReactGA from 'react-ga';
import { Button, Modal, Progress, Col } from 'reactstrap';
import shortid from 'shortid';
import classNames from 'classnames';
import AddIcon from 'mdi-react/AccountMultiplePlusOutlineIcon';
import EditIcon from 'mdi-react/AccountEditIcon';
import { getItem } from 'helpers/cookies';
import Alert from 'shared/components/Alert';
import { POST, UPLOAD } from 'helpers/agent';
import DelavciForm2 from './DelavciForm2';
import { API_LINK } from '../../../constants/config';
import payload from '../../../helpers/jwt';

const API_ADD_EMPLOYEE = '/e/addnew';
const API_EDIT_EMPLOYEE = '/e/update';
const API_DELETE_EMPLOYEE = '/e/delete';

const FILE_INPUT_FIELDS = [
  {
    value: 'a1Scan',
    type: 'a1',
    msg: 'Nalagam A1...',
  },
  {
    value: 'insuranceScan',
    type: 'insurance',
    msg: 'Nalagam podlago za zavarovanje...',
  },
  {
    value: 'passScan',
    type: 'passport',
    msg: 'Nalagam potni list...',
  },
  {
    value: 'IDScan',
    type: 'id',
    msg: 'Nalagam osebno izkaznico...',
  },
  {
    value: 'EUScan',
    type: 'eu',
    msg: 'Nalagam EU kartico...',
  },
  {
    value: 'permScan',
    type: 'perm',
    msg: 'Nalagam dovoljenje za bivanje...',
  },
  {
    value: 'DDScan',
    type: 'dd',
    msg: 'Nalagam delovno dovoljenje...',
  },
  {
    value: 'permAppScan',
    type: 'permApp',
    msg: 'Nalagam prijava bivanja...',
  },
  {
    value: 'permExtScan',
    type: 'permExt',
    msg: 'Nalagam podaljšanje bivanja...',
  },
  {
    value: 'insAbroadScan',
    type: 'insAbroad',
    msg: 'Nalagam zavarovanje tujina...',
  },
  {
    value: 'picScan',
    type: 'pic',
    msg: 'Nalagam osebno fotografijo...',
  },
];

function timeout(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export default class DelavciModalComponent extends PureComponent {
  static propTypes = {
    title: PropTypes.string,
    companies: PropTypes.arrayOf(PropTypes.object).isRequired,
    professions: PropTypes.arrayOf(PropTypes.object).isRequired,
    countries: PropTypes.arrayOf(PropTypes.object).isRequired,
    worksites: PropTypes.arrayOf(PropTypes.object).isRequired,
    icon: PropTypes.string.isRequired,
    colored: PropTypes.bool,
    header: PropTypes.bool,
    btn: PropTypes.string.isRequired,
    hasBtn: PropTypes.bool.isRequired,
    show: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    editData: PropTypes.object,
    onDataSubmitted: PropTypes.func.isRequired,
  };

  static defaultProps = {
    title: '',
    colored: false,
    header: false,
    editData: null,
  };

  constructor() {
    super();
    this.state = {
      modal: false,
      uploading: false,
      uploadMsg: '',
      uploadPrct: 0,
      showAlert: false,
      uploadedFiles: [],
    };

    this.toggle = this.toggle.bind(this);
    this.cancel = this.cancel.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleESC = this.handleESC.bind(this);
    this.handleUpload = this.handleUpload.bind(this);
    this.dismissAlert = this.dismissAlert.bind(this);
    this.isUploadWaiting = this.isUploadWaiting.bind(this);
    this.parseDocsFields = this.parseDocsFields.bind(this);
  }

  componentDidMount() {
    document.addEventListener('keydown', this.handleESC, false);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleESC, false);
    this.generatedDocIDs = [];
  }

  generatedDocIDs = [];

  handleESC(event) {
    if (event.keyCode === 27) {
      this.setState({ modal: false });
      this.cancel(null);
    }
  }

  toggle() {
    if (!this.state.modal) {
      ReactGA.event({
        category: 'Delavci',
        action: `Odprtje delavci modala ${this.props.editData ? 'Urejanje' : 'Dodajanje'}`,
      });
    }
    this.setState({
      modal: !this.state.modal,
    });
  }

  cancel(data) {
    this.setState({
      modal: false,
    });
    this.props.onClose(data);
  }

  isUploadWaiting(values) {
    const { uploadedFiles } = this.state;
    // console.log('TCL: DelavciModalComponent -> isUploadWaiting -> uploadedFiles', uploadedFiles);
    for (let i = 0; i < FILE_INPUT_FIELDS.length; i += 1) {
      const IF = FILE_INPUT_FIELDS[i];
      if (IF.value === 'a1Scan' || IF.value === 'insuranceScan') {
        for (let j = 0; j < 200; j += 1) {
          if (
            values[`${IF.value}${j}`] &&
            document.getElementById(`${IF.value}${j}`).files.length > 0 &&
            !uploadedFiles.includes(`${IF.value}${j}`)
          ) {
            return true;
          }
        }
      }
      if (values[IF.value] && document.getElementById(IF.value).files.length > 0 && !uploadedFiles.includes(IF.value)) {
        return true;
      }
    }

    // console.log('TCL: DelavciModalComponent -> isUploadWaiting -> uploadedFiles -> return', false);
    return false;
  }

  parseDocsFields(fields) {
    const allFields = {};
    const { uid } = payload(getItem('jwt'));
    console.log('fields', fields);
    // basic data
    allFields._id = fields._id || shortid.generate();
    allFields.name = fields.name || undefined;
    allFields.surname = fields.surname || undefined;
    allFields.birthDate = fields.birthDate || undefined;
    // allFields.insurance = fields.insurance || undefined;
    allFields.address = fields.address || undefined;
    allFields.zip = fields.zip || undefined;
    allFields.city = fields.city || undefined;
    allFields.tel = fields.tel || undefined;
    allFields.clothesSize = fields.clothesSize || undefined;
    allFields.shoeSize = fields.shoeSize || undefined;

    if (fields.countryId) {
      if (fields.countryId.value) {
        allFields.countryId = fields.countryId.value;
      } else {
        allFields.countryId = fields.countryId;
      }
    } else {
      allFields.countryId = null;
    }

    if (fields.residency) {
      if (fields.residency.value) {
        allFields.residency = fields.residency.value;
      } else {
        allFields.residency = fields.residency;
      }
    } else {
      allFields.residency = null;
    }

    if (fields.birthCountry) {
      if (fields.birthCountry.value) {
        allFields.birthCountry = fields.birthCountry.value;
      } else {
        allFields.birthCountry = fields.birthCountry;
      }
    } else {
      allFields.birthCountry = null;
    }

    if (fields.professionId) {
      if (fields.professionId.value) {
        allFields.professionId = fields.professionId.value;
      } else {
        allFields.professionId = fields.professionId;
      }
    } else {
      allFields.professionId = null;
    }

    if (fields.citizenship) {
      if (fields.citizenship.value) {
        allFields.citizenship = fields.citizenship.value;
      } else {
        allFields.citizenship = fields.citizenship;
      }
    } else {
      allFields.citizenship = null;
    }

    allFields.birthPlace = fields.birthPlace || undefined;
    allFields.taxID = fields.taxID || undefined;
    allFields.emso = fields.emso || undefined;
    allFields.trr = fields.trr || undefined;

    // companies data
    allFields.comlogs = [];
    for (let index = 0; index < 200; index += 1) {
      const { extraCom } = fields;
      if (extraCom.includes(index)) continue;

      if (fields[`companyId${index}`]) {
        const clogData = {
          companyId: fields[`companyId${index}`].value,
          modifiedBy: uid,
          createdAt: Date.now(),
          updatedAt: Date.now(),
          // registeredAt: fields[`registeredAt${index}`] ,
        };

        if (fields[`registeredAt${index}`]) {
          clogData.registeredAt = fields[`registeredAt${index}`];
        }

        if (fields[`unregisteredAt${index}`]) {
          clogData.unregisteredAt = fields[`unregisteredAt${index}`];
        }

        allFields.comlogs.push(clogData);
      }
    }

    // docs data
    allFields.docs = [];
    for (let index = 0; index < 200; index += 1) {
      const { extra } = fields;
      if (extra.includes(index)) continue;
      const a1Data = {
        type: 'a1',
        modifiedBy: uid,
        createdAt: Date.now(),
        updatedAt: Date.now(),
      };
      let shouldAdd = false;
      if (fields[`a1CountryId${index}`]) {
        a1Data.countryId = fields[`a1CountryId${index}`].value;
        if (fields[`a1CountryId${index}`].docId) {
          a1Data._id = fields[`a1CountryId${index}`].docId;
        }

        shouldAdd = true;
      }

      if (fields[`a1start${index}`]) {
        a1Data.startsAt = fields[`a1start${index}`];
        shouldAdd = true;
      }

      if (fields[`a1end${index}`]) {
        a1Data.expAt = fields[`a1end${index}`];
        shouldAdd = true;
      }

      if (fields[`a1worksiteId${index}`]) {
        a1Data.worksiteId = fields[`a1worksiteId${index}`].value;
        if (fields[`a1worksiteId${index}`].docId) {
          a1Data._id = fields[`a1worksiteId${index}`].docId;
        }
        shouldAdd = true;
      }

      if (shouldAdd) {
        if (!a1Data._id) {
          const GDID = this.generatedDocIDs.find(gdid => gdid.type === `a1Scan${index}`);
          if (GDID) {
            a1Data._id = GDID.id;
          } else {
            a1Data._id = shortid.generate();
          }
          this.generatedDocIDs.push({
            type: `a1Scan${index}`,
            id: a1Data._id,
          });
        }
        allFields.docs.push(a1Data);
      }
    }

    for (let index = 0; index < 200; index += 1) {
      const { extraIns } = fields;
      if (extraIns.includes(index)) continue;

      const insuranceData = {
        type: 'insurance',
        modifiedBy: uid,
        createdAt: Date.now(),
        updatedAt: Date.now(),
      };

      let shouldAdd = false;

      if (fields[`insuranceNum${index}`]) {
        insuranceData.num = fields[`insuranceNum${index}`].value;
        if (fields[`insuranceNum${index}`].docId) {
          insuranceData._id = fields[`insuranceNum${index}`].docId;
        }
        shouldAdd = true;
      }

      if (fields[`insuranceStart${index}`]) {
        insuranceData.startsAt = fields[`insuranceStart${index}`];
        shouldAdd = true;
      }

      if (fields[`insuranceEnd${index}`]) {
        insuranceData.expAt = fields[`insuranceEnd${index}`];
        shouldAdd = true;
      }

      if (shouldAdd) {
        if (!insuranceData._id) {
          const GDID = this.generatedDocIDs.find(gdid => gdid.type === `insuranceScan${index}`);
          console.log('TCL: GDID', GDID);
          if (GDID) {
            insuranceData._id = GDID.id;
          } else {
            insuranceData._id = shortid.generate();
          }

          this.generatedDocIDs.push({
            type: `insuranceScan${index}`,
            id: insuranceData._id,
          });
        }
        allFields.docs.push(insuranceData);
      }
    }

    if (fields.passNum || fields.passStart || fields.passExp) {
      allFields.docs.push({
        type: 'passport',
        num: fields.passNum,
        startsAt: fields.passStart,
        expAt: fields.passExp,
        modifiedBy: uid,
        createdAt: Date.now(),
        updatedAt: Date.now(),
      });
    }
    if (fields.IDNum || fields.IDStart || fields.IDExp) {
      allFields.docs.push({
        type: 'id',
        num: fields.IDNum,
        startsAt: fields.IDStart,
        expAt: fields.IDExp,
        modifiedBy: uid,
        createdAt: Date.now(),
        updatedAt: Date.now(),
      });
    }
    if (fields.permNum || fields.permStart || fields.permExp) {
      allFields.docs.push({
        type: 'perm',
        num: fields.permNum,
        startsAt: fields.permStart,
        expAt: fields.permExp,
        modifiedBy: uid,
        createdAt: Date.now(),
        updatedAt: Date.now(),
      });
    }
    if (fields.DDNum || fields.DDStart || fields.DDExp) {
      allFields.docs.push({
        type: 'dd',
        num: fields.DDNum,
        startsAt: fields.DDStart,
        expAt: fields.DDExp,
        modifiedBy: uid,
        createdAt: Date.now(),
        updatedAt: Date.now(),
      });
    }
    if (fields.EUNum || fields.EUStart || fields.EUExp || fields.EUProvider) {
      allFields.docs.push({
        type: 'eu',
        num: fields.EUNum,
        startsAt: fields.EUStart,
        expAt: fields.EUExp,
        provider: fields.EUProvider ? fields.EUProvider.label : undefined,
        modifiedBy: uid,
        createdAt: Date.now(),
        updatedAt: Date.now(),
      });
    }
    if (fields.permAppNum || fields.permAppStart || fields.permAppExp) {
      allFields.docs.push({
        type: 'permApp',
        num: fields.permAppNum,
        startsAt: fields.permAppStart,
        expAt: fields.permAppExp,
        modifiedBy: uid,
        createdAt: Date.now(),
        updatedAt: Date.now(),
      });
    }
    if (fields.permExtNum || fields.permExtStart || fields.permExtExp) {
      allFields.docs.push({
        type: 'permExt',
        num: fields.permExtNum,
        startsAt: fields.permExtStart,
        expAt: fields.permExtExp,
        modifiedBy: uid,
        createdAt: Date.now(),
        updatedAt: Date.now(),
      });
    }
    if (fields.insAbroadNum || fields.insAbroadStart || fields.insAbroadExp) {
      allFields.docs.push({
        type: 'insAbroad',
        num: fields.insAbroadNum,
        startsAt: fields.insAbroadStart,
        expAt: fields.insAbroadExp,
        modifiedBy: uid,
        createdAt: Date.now(),
        updatedAt: Date.now(),
      });
    }

    console.log('allFields', allFields);
    return allFields;
  }

  async handleUpload(values) {
    try {
      this.setState({ showAlert: false });
      console.log('UPLOAD OF FILE NEEDED!');
      const filecount = values.fileCounter;
      const timeoutMS = 1000;
      let counter = 1;
      console.log('TCL: DelavciModalComponent -> handleUpload -> filecount', filecount);
      const prctStep = 100 / filecount;

      for (let i = 0; i < FILE_INPUT_FIELDS.length; i += 1) {
        const IF = FILE_INPUT_FIELDS[i];
        // nalaganje A1 in podlag
        if (IF.value === 'a1Scan' || IF.value === 'insuranceScan') {
          for (let j = 0; j < 200; j += 1) {
            if (values[`${IF.value}${j}`] && document.getElementById(`${IF.value}${j}`).files.length > 0) {
              let docId = '';
              console.log('TCL: handleUpload -> docId', docId);
              if (IF.value === 'a1Scan') {
                if (values[`a1CountryId${j}`]) {
                  // eslint-disable-next-line prefer-destructuring
                  docId = values[`a1CountryId${j}`].docId;
                } else if (values[`a1worksiteId${j}`]) {
                  // eslint-disable-next-line prefer-destructuring
                  docId = values[`a1worksiteId${j}`].docId;
                }
              }

              if (IF.value === 'insuranceScan') {
                if (values[`insuranceNum${j}`]) {
                  // eslint-disable-next-line prefer-destructuring
                  docId = values[`insuranceNum${j}`].docId;
                  console.log('TCL: DelavciModalComponent -> handleUpload -> ', values[`insuranceNum${j}`]);
                }
              }

              console.log('TCL: handleUpload -> docId', docId);

              if (docId === '' || !docId) {
                const GDID = this.generatedDocIDs.find(gdid => gdid.type === `${IF.value}${j}`);
                console.log('TCL: handleUpload -> GDID', GDID);
                if (GDID) {
                  docId = GDID.id;
                } else {
                  docId = shortid.generate();
                  this.generatedDocIDs.push({
                    type: `${IF.value}${j}`,
                    id: docId,
                  });
                }
              }
              const TYPE = `${IF.type}${docId}`;
              console.log('TCL: handleUpload -> TYPE', TYPE);
              this.setState({
                uploading: true,
                uploadMsg: `${IF.msg} (${j + 1})`,
              });
              const formData = new FormData();
              formData.append('workerId', this.props.editData._id);
              formData.append('type', TYPE);
              formData.append('scans', document.getElementById(`${IF.value}${j}`).files[0], TYPE);

              // eslint-disable-next-line no-await-in-loop
              await UPLOAD(`${API_LINK}/f/upload`, formData);
              const { uploadedFiles } = this.state;
              uploadedFiles.push(`${IF.value}${j}`);
              this.setState({ uploadPrct: prctStep * counter, uploadedFiles });
              counter += 1;
              // eslint-disable-next-line no-await-in-loop
              await timeout(timeoutMS);
            }
          }
          // nalaganje vsega ostalega
        } else if (values[IF.value] && document.getElementById(IF.value).files.length > 0) {
          const TYPE = IF.type;
          this.setState({ uploading: true, uploadMsg: IF.msg });
          const formData = new FormData();
          formData.append('workerId', this.props.editData._id);
          formData.append('type', TYPE);
          formData.append('scans', document.getElementById(IF.value).files[0], TYPE);
          console.log('TCL: DelavciModalComponent -> handleUpload -> formData', formData);

          // eslint-disable-next-line no-await-in-loop
          await UPLOAD(`${API_LINK}/f/upload`, formData);
          const { uploadedFiles } = this.state;
          uploadedFiles.push(IF.value);
          this.setState({ uploadPrct: prctStep * counter, uploadedFiles });
          counter += 1;
          // eslint-disable-next-line no-await-in-loop
          await timeout(timeoutMS);
        }
      }

      this.setState({ uploading: false, uploadMsg: '', uploadPrct: 0 });
    } catch (error) {
      console.log('TCL: DelavciModalComponent -> handleUpload -> error', error);
    }
  }

  async handleDelete(data) {
    const successMessage = `Delavec ${`${data.name} ${data.surname}`} uspešno izbrisan!`;
    const errorMessage = `Delavec ${`${data.name} ${data.surname}`} NI uspešno izbrisan!`;
    // const that = this;
    try {
      // console.log('zdaj bi izbrisal', data);
      const dataRes = await POST(API_LINK + API_DELETE_EMPLOYEE, JSON.stringify({ _id: data._id }));
      if (dataRes.success) {
        // console.log('Request success: ', data);
        this.cancel({
          title: 'Izbris delavca',
          message: successMessage,
          success: true,
        });
      } else {
        // console.log('Request success: ', data);
        // this.showNotif('error', isEdit, values.name, false);
        this.cancel({
          title: 'Izbris delavca',
          message: errorMessage,
          success: false,
        });
      }
    } catch (error) {
      console.log(error);
      this.cancel(null);
    }
  }

  async handleSubmit(values) {
    if (this.isUploadWaiting(values) && !this.state.showAlert) {
      this.setState({ showAlert: true });
      return;
    }
    this.setState({ showAlert: false });

    const { icon } = this.props;
    const isEdit = icon !== 'add';
    const employeeObject = this.parseDocsFields(values);
    const body = JSON.stringify(employeeObject);

    const apiLink = isEdit ? API_EDIT_EMPLOYEE : API_ADD_EMPLOYEE;
    try {
      const notifTitle = isEdit ? 'Uredi delavca' : 'Dodaj delavca';
      const successMessage = isEdit
        ? `Delavec ${`${values.name} ${values.surname}`} uspešno posodobljen!`
        : `Delavec ${`${values.name} ${values.surname}`} uspešno dodan!`;
      const errorMessage = isEdit
        ? `Delavec ${`${values.name} ${values.surname}`} ni uspešno posodobljen!`
        : `Delavec ${`${values.name} ${values.surname}`} ni uspešno dodan!`;

      const data = await POST(API_LINK + apiLink, body);
      if (data.success) {
        this.cancel({
          title: notifTitle,
          message: successMessage,
          success: true,
        });
        this.props.onDataSubmitted(employeeObject._id);
      } else {
        this.cancel({
          title: notifTitle,
          message: errorMessage,
          success: false,
        });
      }
    } catch (error) {
      console.log(error);
      this.cancel(null);
    }
  }

  dismissAlert() {
    this.setState({
      showAlert: false,
    });
  }

  render() {
    const {
      icon,
      btn,
      title,
      colored,
      header,
      companies,
      professions,
      countries,
      worksites,
      hasBtn,
      show,
      editData,
    } = this.props;

    const { uploadPrct, uploading, uploadMsg, showAlert } = this.state;
    let Icon;

    switch (icon) {
      case 'add':
        Icon = <AddIcon />;
        break;
      case 'edit':
        Icon = <EditIcon />;
        break;
      default:
        break;
    }
    const modalClass = classNames({
      'modal-dialog--colored': colored,
      'modal-dialog--header': header,
    });

    const AlertElt = (
      <Alert
        color="warning"
        className="alert--bordered"
        icon
        handleDismiss={this.dismissAlert}
        width="80%"
        marginTop="-45px"
      >
        <p>
          <span className="bold-text">Prenos datotek na čakanju!</span>
          <br />
          Če želite naložiti izbrane datoteke, najprej kliknite na gumb "Naloži"
        </p>
      </Alert>
    );

    const employeeName = editData ? `: ${editData.surname} ${editData.name}` : '';

    return (
      <div style={{ width: '100%' }}>
        {hasBtn ? (
          <Button size="sm" color="primary" onClick={this.toggle}>
            {btn}
          </Button>
        ) : null}

        <Modal
          isOpen={this.state.modal || show}
          toggle={this.toggle}
          className={`modal-dialog--primary wide ${modalClass}`}
        >
          <div className="modal__header">
            <button className="lnr lnr-cross modal__close-btn" onClick={this.toggle} />
            {header ? '' : Icon}
            <h4 className="bold-text  modal__title">{title + employeeName}</h4>
          </div>
          <div className="modal__body">
            <div className="theme-light">
              {editData ? (
                <DelavciForm2
                  companies={companies}
                  professions={professions}
                  countries={countries}
                  worksites={worksites}
                  onSubmit={this.handleSubmit}
                  handleCancel={() => this.cancel(null)}
                  handleDelete={this.handleDelete}
                  handleUpload={this.handleUpload}
                  initialValues={editData}
                  editData={editData}
                  initialFieldCount={editData.a1Counter}
                  initialInsuranceFieldCount={editData.insuranceCounter}
                  initialCompanyFieldCount={editData.comlogsCounter}
                />
              ) : (
                <DelavciForm2
                  companies={companies}
                  professions={professions}
                  countries={countries}
                  worksites={worksites}
                  onSubmit={this.handleSubmit}
                  handleCancel={() => this.cancel(null)}
                  handleDelete={this.handleDelete}
                  handleUpload={this.handleUpload}
                />
              )}
              {showAlert && AlertElt}
              {uploading && (
                <Col lg={12} style={{ marginTop: '-40px' }}>
                  <div className="progress-wrap progress-wrap--middle progress-wrap--violet progress--align">
                    <Progress animated value={uploadPrct} />
                  </div>
                </Col>
              )}
              {uploading && (
                <Col lg={12} style={{ height: '50px' }}>
                  <span
                    style={{
                      paddingBottom: '30px',
                      height: '50px',
                      color: 'Gray',
                    }}
                  >
                    {uploadMsg}
                  </span>
                </Col>
              )}
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}
