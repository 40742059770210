/* eslint-disable  */
import React, { PureComponent } from 'react';
//import CheckIcon from 'mdi-react/CheckIcon';
//import CloseIcon from 'mdi-react/CloseIcon';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { translate } from 'react-i18next';

class CheckBoxField2 extends PureComponent {
  constructor(props) {
    super(props);
  }
  /* static propTypes = {
    onChange: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]).isRequired,
    label: PropTypes.string,
    defaultChecked: PropTypes.bool,
    disabled: PropTypes.bool,
    className: PropTypes.string,
    color: PropTypes.string,
    t: PropTypes.func.isRequired,
  };

  static defaultProps = {
    label: 'Doma',
    defaultChecked: false,
    disabled: false,
    className: '',
    color: '',
  }; */

  componentDidMount() {
    // this.props.onChange(this.props.defaultChecked);
  }

  render() {
    const { disabled, className, name, value, onChange, label, color, t } = this.props;
    const CheckboxClass = classNames({
      'checkbox-btn': true,
      disabled,
    });

    console.log('CB value', value);

    return (
      <label
        className={`${CheckboxClass} ${className ? ` checkbox-btn--${className}` : ''}`}
        style={{ backgroundColor: color, borderColor: color }}
      >
        <input
          className="checkbox-btn__checkbox"
          type="checkbox"
          id={name}
          name={name}
          onChange={onChange}
          checked={value}
          disabled={disabled}
        />
        {/* <span className="checkbox-btn__checkbox-custom" style={{ backgroundColor: '#8865D6', borderColor: '#8865D6' }}>
          <CheckIcon />
        </span> */}
        {/* {className === 'button' ? (
          <span className="checkbox-btn__label-svg">
            <CheckIcon className="checkbox-btn__label-check" />
            <CloseIcon className="checkbox-btn__label-uncheck" />
          </span>
        ) : (
          ''
        )} */}
        <span className="checkbox-btn__label" style={{ margin: '0 auto', marginTop: '3px' }}>
          {value ? t('form.abroad') : t('form.home')}
        </span>
      </label>
    );
  }
}

const renderCheckBoxField2 = props => (
  <CheckBoxField2
    {...props.input}
    label={props.label}
    defaultChecked={props.defaultChecked}
    disabled={props.disabled}
    className={props.className}
    color={props.color}
    t={props.t}
  />
);

renderCheckBoxField2.propTypes = {
  input: PropTypes.shape({
    onChange: PropTypes.func,
    name: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  }).isRequired,
  label: PropTypes.string,
  defaultChecked: PropTypes.bool,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  color: PropTypes.string,
  t: PropTypes.func.isRequired,
};

renderCheckBoxField2.defaultProps = {
  label: 'Doma',
  defaultChecked: false,
  disabled: false,
  className: '',
  color: '',
};

export default translate('common')(renderCheckBoxField2);
