import moment from 'moment-timezone';
import React, { Component } from 'react';
import _ from 'lodash';
import { Col, Container, Row, ButtonToolbar, Progress } from 'reactstrap';
import { BasicNotification } from 'shared/components/Notification';
import NotificationSystem from 'rc-notification';
import { connect } from 'react-redux';
import Modal from 'shared/components/Modal';
import vehiclesApi from 'api/vehicles';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';
import { API_LINK, TIMEZONE } from '../../../constants/config';
import { POST } from '../../../helpers/agent';
import VozilaIzpisiTable from './components/VozilaIzpisiTable';
import VozilaIzpisiModal from './components/VozilaIzpisiModal';
import VozilaIzpisiSheet from './components/VozilaIzpisiSheet';
import getRoles from '../../../constants/roles';
import { VehiclesProps, AppStatusProps, EmployeesProps, WorksitesProps } from '../../../shared/prop-types/ReducerProps';
import { TMZFY } from '../../../helpers/functions';
import QuickDropdownModal from './components/QuickDDM/QuickDropdownModal';
import CriteriaSelect from '../../Tabla/components/CriteriaSelect';

let notificationLU = null;
let notificationRU = null;
let notificationTC = null;

const showNotification = ({ notification, position }) => {
  switch (position) {
    case 'left-up':
      notificationLU.notice({
        content: notification,
        duration: 5,
        closable: true,
        style: { top: 0, left: 240 },
        className: position,
      });
      break;
    case 'right-up':
      notificationRU.notice({
        content: notification,
        duration: 5,
        closable: true,
        style: { top: 0, left: 'calc(100vw - 100%)' },
        className: position,
      });
      break;
    default:
      notificationTC.notice({
        content: notification,
        duration: 5,
        closable: true,
        style: { top: 0, left: 0 },
        className: position,
      });
      break;
  }
};

/* const toMonth = date =>
  moment(date)
    .tz(TIMEZONE)
    .month(); */

const getMonthDiff = (startDate, endDate) =>
  Math.trunc(moment(new Date(endDate)).diff(new Date(startDate), 'months', true));
const datesEqual = (date1, date2) => new Date(date1).getTime() === new Date(date2).getTime();
/* const isFirstDayInMonth = date =>
  moment(date)
    .tz(TIMEZONE)
    .date() === 1; */

let lastLogHelper;

const appendFakeLogsToLog = (log, nextDate, isLastLog) => {
  //eslint-disable-next-line
  //debugger;
  const fakeLogs = [];
  const monthDiff = !log.returnedAt ? getMonthDiff(log.date, nextDate) : getMonthDiff(log.date, log.returnedAt);

  if (log.returnedAt && new Date(nextDate).getTime() < new Date(log.returnedAt).getTime()) {
    fakeLogs.push(_.cloneDeep(log));
    return fakeLogs;
  }

  let isSwitch = false;

  if (lastLogHelper && lastLogHelper.workerId !== log.employeeId) {
    isSwitch = true;
  }

  lastLogHelper = {
    workerId: log.employeeId,
    monthDiff,
    returnedAt: log.returnedAt,
  };

  let returned;
  if (monthDiff === 0) {
    fakeLogs.push(_.cloneDeep({ ...log, isSwitch }));
  } else {
    const { returnedAt, returnedAtString } = log;
    returned = {
      returnedAt,
      returnedAtString,
    };
    fakeLogs.push({
      ...log,
      /* returnedAt: null,
      returnedAtString: '/', */
      returnedAt: log.returnedAt,
      returnedAtString: TMZFY(log.returnedAt),
      /* orRetAt: log.returnedAt,
      orRetAtString: TMZFY(log.returnedAt), */
      isSwitch,
    });
  }

  let monthDate = moment(log.date)
    .tz(TIMEZONE)
    .startOf('month');
  for (let i = 0; i < monthDiff; i += 1) {
    monthDate = monthDate.add(1, 'month');
    const _date = monthDate.tz(TIMEZONE).toDate();
    if (!datesEqual(_date, nextDate)) {
      const fakelog = {
        ...log,
        _id: `fake-${_date.getTime()}`,
        fake: true,
        date: _date,
        cost: 0,
        dateString: TMZFY(_date),
        notes: '',
        receipt: false,
        /* returnedAt: null,
      returnedAtString: '/', */
        returnedAt: log.returnedAt,
        returnedAtString: TMZFY(log.returnedAt),
        isSwitch: false,
      };
      if (i < monthDiff - 1 || (returned && datesEqual(returned.returnedAt, nextDate))) {
        fakeLogs.push(_.cloneDeep(fakelog));
      } else {
        const { returnedAt, returnedAtString } = returned;
        fakeLogs.push(_.cloneDeep({ ...fakelog, returnedAt, returnedAtString }));
      }
    }
  }

  return fakeLogs;
};

const fullupRidelogs = ridelogs => {
  let complete = [];

  lastLogHelper = null;

  for (let i = 0; i < ridelogs.length; i += 1) {
    const isLastLog = i === ridelogs.length - 1;
    const nextDate = isLastLog ? new Date(Date.now()) : ridelogs[i + 1].date;
    const flogs = appendFakeLogsToLog(_.cloneDeep(ridelogs[i]), nextDate, isLastLog);
    complete = complete.concat(_.cloneDeep(flogs));
  }
  return complete;
};

class VozilaIzpisiPage extends Component {
  static propTypes = {
    vehiclesState: VehiclesProps.isRequired,
    appStatus: AppStatusProps.isRequired,
    workersState: EmployeesProps.isRequired,
    worksitesState: WorksitesProps.isRequired,
    t: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      ridelogs: [],
      vehicles: [],
      workers: [],
      worksites: [],
      vehicleWorksite: null,
      selectedVehicle: null,
      selectedWorker: null,
      progressNum: 0,
      editKM: null,
      showEdit: false,
      ROLES: getRoles(),
    };
    this.handleDropdownSubmit = this.handleDropdownSubmit.bind(this);
    this.fetchData = this.fetchData.bind(this);
    this.handleProgress = this.handleProgress.bind(this);
    this.handleRowSelect = this.handleRowSelect.bind(this);
    this.showNotif = this.showNotif.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.handleRemoveFromWorksite = this.handleRemoveFromWorksite.bind(this);
  }

  componentDidMount() {
    const diff = getMonthDiff(new Date('2019-09-16T22:00:00.000Z'), new Date('2020-01-13T23:00:00.000Z'));
    console.log('TCL: VozilaIzpisiPage -> componentDidMount -> diff', diff);

    NotificationSystem.newInstance({}, n => (notificationLU = n));
    NotificationSystem.newInstance({}, n => (notificationRU = n));
    NotificationSystem.newInstance({}, n => (notificationTC = n));

    const { appStatus } = this.props;
    if (appStatus.requiredDataLoaded) {
      console.log('VEHICLES_HISTORY -> Data loaded.. continue...');
      this.fetchData();
    }
  }

  componentDidUpdate(prevProps) {
    const { appStatus } = this.props;
    if (appStatus.requiredDataLoaded && !prevProps.appStatus.requiredDataLoaded) {
      console.log('VEHICLES_HISTORY -> Data finished loading.. continue...');
      this.fetchData();
    }

    if (!appStatus.appUpdating && prevProps.appStatus.appUpdating) {
      console.log('VEHICLES_HISTORY -> Data finished UPDATING.. refresh table...');
      this.fetchData();
    }
  }

  componentWillUnmount() {
    notificationLU.destroy();
    notificationRU.destroy();
    notificationTC.destroy();
  }

  handleProgress(addProgress) {
    const { progressNum } = this.state;
    this.setState({ progressNum: progressNum + addProgress });
    const that = this;
    if (progressNum + addProgress >= 100) {
      setTimeout(() => {
        that.setState({ progressNum: 0 });
      }, 1000);
    }
  }

  fetchData() {
    const { vehiclesState, workersState, worksitesState } = this.props;
    this.setState({
      vehicles: vehiclesState.licenses,
      workers: workersState.employeesNames,
      worksites: worksitesState.titlesWorksites,
    });
  }

  async handleDropdownSubmit(values) {
    try {
      if (values.criteria.selectedOptionId === null) {
        this.setState({
          ridelogs: [],
          selectedVehicle: null,
          selectedWorker: null,
          vehicleWorksite: null,
        });
        return;
      }
      console.log('TCL: VozilaIzpisiPage -> handleDropdownSubmit -> values', values);

      const data = await vehiclesApi.getridelogs({
        selectedId: values.criteria.selectedOptionId,
        criteria: values.criteria.id,
      });
      console.log('TCL: VozilaIzpisiPage -> handleDropdownSubmit -> data.ridelogsList', data.ridelogsList);
      let completeRidelogs = data.ridelogsList;
      if (data.ridelogsList.length > 0 && values.criteria.id === 'vehicle') {
        completeRidelogs = fullupRidelogs(data.ridelogsList);
      }
      this.setState({
        ridelogs: completeRidelogs.reverse(),
        selectedVehicle:
          values.criteria.id === 'vehicle'
            ? {
                value: values.criteria.selectedOptionId,
                label: values.criteria.selectedOptionLabel,
              }
            : null,
        selectedWorker:
          values.criteria.id === 'worker'
            ? {
                value: values.criteria.selectedOptionId,
                label: values.criteria.selectedOptionLabel,
              }
            : null,
        vehicleWorksite: data.worksite,
      });
    } catch (error) {
      console.log(error);
    }
  }

  showNotif = ({ title, message, success }) => {
    const color = success ? 'success' : 'danger';
    showNotification({
      notification: <BasicNotification color={color} title={title} message={message} />,
      position: 'right-up',
    });
  };

  handleCloseModal(data) {
    if (data) this.showNotif(data);
    this.setState({ showEdit: false });
    if (data) {
      const vals = {
        criteria: {
          id: this.state.selectedVehicle ? 'vehicle' : 'worker',
          selectedOptionId: this.state.selectedVehicle
            ? this.state.selectedVehicle.value
            : this.state.selectedWorker.value,
        },
      };
      this.handleDropdownSubmit(vals);
    }
  }

  handleRowSelect(values) {
    console.log('TCL: handleRowSelect -> values', values);
    const { ROLES } = this.state;
    const { t } = this.props;
    if (ROLES.vehicles && ROLES.vehicles.history && ROLES.vehicles.history.readonly) {
      this.showNotif({
        title: `${t('misc.forbidden')}!`,
        message: `${t('misc.noright')}!`,
        success: false,
      });
      return;
    }
    if (values.employee) {
      this.setState({
        editKM: {
          ...values,
          vehicleId: this.state.selectedVehicle ? this.state.selectedVehicle.value : null,
          workerId: { label: values.employee, value: values.employeeId },
          type: 'vehicle',
        },
        showEdit: true,
      });
    } else {
      this.setState({
        editKM: {
          ...values,
          workerId: this.state.selectedWorker ? this.state.selectedWorker.value : null,
          vehicleId: { label: values.vehicle, value: values.vehicleId },
          type: 'worker',
        },
        showEdit: true,
      });
    }
  }

  async handleRemoveFromWorksite() {
    const API_VEHICLE_WORKSITE = '/v/worksiteVehicle';
    const body = JSON.stringify({
      vehicleId: this.state.selectedVehicle.value,
      worksiteId: null,
    });
    const data = await POST(API_LINK + API_VEHICLE_WORKSITE, body);
    const successMessage = 'Vozilo uspešno odstranjeno z gradbišča!';
    const errorMessage = 'Vozilo neuspešno odstranjeno z gradbišča!';

    if (data.success) {
      this.handleCloseModal({
        title: 'Posodobitev stanja',
        message: successMessage,
        success: true,
      });
    } else {
      this.handleCloseModal({
        title: 'Posodobitev stanja',
        message: errorMessage,
        success: false,
      });
    }
  }

  /*   handleCriteriaSelection = (values) => {
    console.log('TCL: handleCriteriaSelection -> values', values);
  }; */

  render() {
    const {
      ridelogs,
      vehicles,
      progressNum,
      editKM,
      showEdit,
      selectedVehicle,
      selectedWorker,
      ROLES,
      workers,
      worksites,
      vehicleWorksite,
    } = this.state;
    const { t } = this.props;

    const shouldRenderTable = (ridelogs && ridelogs.length > 0) || selectedVehicle || selectedWorker;

    let showBtn = ROLES.full;

    if (!showBtn) {
      showBtn = !(ROLES.vehicles && ROLES.vehicles.history && ROLES.vehicles.history.readonly);
    }

    return (
      <Container className="dashboard">
        <Row>
          <Col lg={12}>
            <h3 className="page-title">
              {t('sidebar_content.vehicles.title')} - {t('sidebar_content.vehicles.history')}
            </h3>
          </Col>
          <Col lg={12}>
            <CriteriaSelect onCriteriaSelected={this.handleDropdownSubmit} criteriaType="VEHICLES_HISTORY" />
            <Row>
              {progressNum === 0 && shouldRenderTable && (
                <React.Fragment>
                  <Col lg={{ size: 2, offset: selectedVehicle ? 8 : 10 }} style={{ marginBottom: '40px' }}>
                    <ButtonToolbar className="button-toolbar text-right">
                      <VozilaIzpisiModal
                        btn="Dodaj vnos"
                        icon="add"
                        title="Dodaj vnos"
                        hasBtn={showBtn}
                        show={false}
                        onClose={this.handleCloseModal}
                        workers={workers}
                        vehicles={vehicles}
                        vehicleId={this.state.selectedVehicle ? this.state.selectedVehicle.value : null}
                        workerId={this.state.selectedWorker ? this.state.selectedWorker.value : null}
                        type={selectedVehicle ? 'vehicle' : 'worker'}
                      />
                    </ButtonToolbar>
                  </Col>
                  {selectedVehicle && (
                    <Col lg={{ size: 2, offset: 0 }} style={{ marginBottom: '40px' }}>
                      <ButtonToolbar className="button-toolbar text-right">
                        <QuickDropdownModal
                          btn="Dodaj vozilo na gradbišče"
                          icon="add"
                          title="Dodaj vozilo na gradbišče"
                          hasBtn={showBtn}
                          show={false}
                          onClose={this.handleCloseModal}
                          options={worksites}
                          vehicleId={this.state.selectedVehicle ? this.state.selectedVehicle.value : ''}
                        />
                      </ButtonToolbar>
                    </Col>
                  )}
                </React.Fragment>
              )}
            </Row>
          </Col>
        </Row>
        <Row>
          {progressNum > 0 ? (
            <div className="progress-wrap progress-wrap--middle progress--align">
              <Progress animated value={progressNum} />
            </div>
          ) : null}
          {progressNum === 0 && ridelogs.length > 0 && (
            <VozilaIzpisiSheet
              btn={t('form.exportExcel')}
              data={ridelogs}
              title={
                selectedVehicle
                  ? `${t('misc.archive')}: ${selectedVehicle.label}`
                  : `${t('misc.archive')}: ${selectedWorker.label}`
              }
              isVehicle={!!selectedVehicle}
            />
          )}
          {shouldRenderTable && (
            <React.Fragment>
              {vehicleWorksite && (
                <React.Fragment>
                  <h3
                    style={{
                      marginLeft: '20px',
                      marginRight: '20px',
                      color: 'coral',
                    }}
                  >
                    POZOR! Vozilo se nahaja na gradbišču: {vehicleWorksite}
                  </h3>

                  <Modal
                    color="danger"
                    title="Pozor!"
                    colored
                    btn="Odstrani z gradbišča"
                    size="sm"
                    message="Ste prepričani, da želite odstraniti vozilo z gradbišča?"
                    onConfirm={this.handleRemoveFromWorksite}
                  />
                </React.Fragment>
              )}

              <VozilaIzpisiTable
                title={
                  selectedVehicle
                    ? `${t('misc.archive')}: ${selectedVehicle.label}`
                    : `${t('misc.archive')}: ${selectedWorker.label}`
                }
                ridelogs={ridelogs}
                onRowSelect={this.handleRowSelect}
                type={selectedVehicle ? 'vehicle' : 'worker'}
              />
            </React.Fragment>
          )}
        </Row>

        <VozilaIzpisiModal
          btn="Uredi zgodovino vozila"
          icon="edit"
          title="Uredi zgodovino vozila"
          hasBtn={false}
          show={showEdit}
          onClose={this.handleCloseModal}
          editData={editKM}
          workers={workers}
          vehicles={vehicles}
          type={selectedVehicle ? 'vehicle' : 'worker'}
        />
      </Container>
    );
  }
}

const wr = connect(state => ({
  vehiclesState: state.vehicles,
  appStatus: state.appStatus,
  workersState: state.employees,
  worksitesState: state.worksites,
}))(VozilaIzpisiPage);

export default translate('common')(wr);
