import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-i18next';
import { connect } from 'react-redux';
import { Row, Col, Card, CardBody, Button } from 'reactstrap';
import NotificationSystem from 'rc-notification';
import { WorksitesProps } from 'shared/prop-types/ReducerProps';
import { BasicNotification } from 'shared/components/Notification';
import { search } from 'redux/actions/placesActions';
import Location from './components/Location';
import EmployeeRange from './components/EmployeeRange';
import LocationRadius from './components/LocationRadius';

let notificationLU = null;
let notificationRU = null;
let notificationTC = null;

const showNotification = ({ notification, position }) => {
  switch (position) {
    case 'left-up':
      notificationLU.notice({
        content: notification,
        duration: 5,
        closable: true,
        style: { top: 0, left: 240 },
        className: position,
      });
      break;
    case 'right-up':
      notificationRU.notice({
        content: notification,
        duration: 5,
        closable: true,
        style: { top: 0, left: 'calc(100vw - 100%)' },
        className: position,
      });
      break;
    default:
      notificationTC.notice({
        content: notification,
        duration: 5,
        closable: true,
        style: { top: 0, left: 0 },
        className: position,
      });
      break;
  }
};

class Filters extends Component {
  static propTypes = {
    worksitesStore: WorksitesProps.isRequired,
    dispatch: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      locationSearchType: true,
      locationSearchWorksite: null,
      locationSearchLocation: null,
      employeesFrom: null,
      employeesTo: null,
      locationRadius: 10,
    };

    this.handleLocationChange = this.handleLocationChange.bind(this);
    this.handleEmployeeRangeChange = this.handleEmployeeRangeChange.bind(this);
    this.handleLocationRadiusChange = this.handleLocationRadiusChange.bind(this);
  }

  componentDidMount() {
    NotificationSystem.newInstance({}, n => (notificationLU = n));
    NotificationSystem.newInstance({}, n => (notificationRU = n));
    NotificationSystem.newInstance({}, n => (notificationTC = n));
  }

  handleLocationChange(searchType, worksite, location) {
    this.setState({
      locationSearchType: searchType,
      locationSearchWorksite: worksite,
      locationSearchLocation: location,
    });
  }

  handleEmployeeRangeChange(from, to) {
    this.setState({
      employeesFrom: from,
      employeesTo: to,
    });
  }

  handleLocationRadiusChange(value) {
    this.setState({
      locationRadius: value,
    });
  }

  validateBody = body => {
    const { t } = this.props;
    const errors = [];
    if (!body.peopleFrom) {
      errors.push(t('places.errors.peopleFromMissing'));
    }
    if (!body.peopleTo) {
      errors.push(t('places.errors.peopleToMissing'));
    }
    if (body.peopleTo < body.peopleFrom) {
      errors.push(t('places.errors.peopleFromBigger'));
    }
    if (!body.radius) {
      errors.push(t('places.errors.radiusTooSmall'));
    }
    if (!body.address) {
      errors.push(t('places.errors.addressMissing'));
    }
    return errors;
  };

  showNotif = ({ title, message, success }) => {
    const color = success ? 'success' : 'danger';
    showNotification({
      notification: <BasicNotification color={color} title={title} message={message} />,
      position: 'right-up',
    });
  };

  handleSearchSubmit = () => {
    const {
      employeesFrom,
      employeesTo,
      locationRadius,
      locationSearchType,
      locationSearchWorksite,
      locationSearchLocation,
    } = this.state;

    const {
      worksitesStore: { worksites },
      dispatch,
    } = this.props;
    const worksite = locationSearchWorksite ? worksites.find(w => w._id === locationSearchWorksite) : null;
    const address =
      locationSearchType && worksite
        ? `${worksite.address}, ${worksite.city}, ${worksite.zip}`
        : locationSearchLocation;
    const body = {
      peopleFrom: !Number.isNaN(employeesFrom) ? Number(employeesFrom) : null,
      peopleTo: !Number.isNaN(employeesTo) ? Number(employeesTo) : null,
      radius: !Number.isNaN(locationRadius) ? Number(locationRadius) : null,
      address,
    };

    const errors = this.validateBody(body);
    console.log('errors', errors);
    if (!errors.length) {
      dispatch(search(body));
    } else {
      this.showNotif({
        title: 'Napaka',
        message: errors.join('\n'),
        success: false,
      });
    }
  };

  render() {
    const { t } = this.props;
    return (
      <Card className="prenocisca-filters">
        <CardBody>
          <Row>
            <Col lg={4} className="mb-sm-2">
              <Location onChange={this.handleLocationChange} />
            </Col>
            <Col lg={4}>
              <EmployeeRange onChange={this.handleEmployeeRangeChange} />
            </Col>
            <Col lg={3}>
              <LocationRadius onChange={this.handleLocationRadiusChange} />
            </Col>
            <Col lg={1} className="vertical-center">
              <Button color="primary" onClick={this.handleSearchSubmit}>
                {t('places.filters.search')}
              </Button>
            </Col>
          </Row>
        </CardBody>
      </Card>
    );
  }
}

export default translate('common')(
  connect(state => ({
    worksitesStore: state.worksites,
  }))(Filters),
);
